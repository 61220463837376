import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  siblingMarkerContainer: {
    position: "absolute",
    left: "4px",
    height: "16px",
    width: "calc(100% - 4px * 2)",
    alignItems: "center",
    display: "flex",
    transition: "opacity .1s ease-in",
    opacity: 0,

    "&:hover": {
      opacity: 1
    }
  },

  siblingMarkerContainerBefore: {
    top: theme.spacing(-1)
  },
  siblingMarkerContainerAfter: {
    bottom: theme.spacing(-1)
  },

  siblingMarker: {
    position: "relative",
    top: "1px",
    borderRadius: "4px",
    backgroundColor: theme.palette.custom.ds.viking.viking500,
    height: "2px",
    flex: 1
  },

  markerIconContainer: {
    boxSizing: "border-box",
    position: "relative",
    top: "1px",
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "16px",
    margin: "auto"
  },

  markerIcon: {
    cursor: "inherit",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.viking.viking500
  },

  childMarkerContainer: {
    position: "absolute",
    top: theme.spacing(1),
    left: 0,
    width: "100%",
    height: `calc(100% - ${theme.spacing(1)} * 2px)`
  },

  forbiddenSiblingMarker: {
    cursor: "not-allowed",
    "& $siblingMarker": {
      backgroundColor: theme.palette.custom.ds.error.error500
    },
    "& $markerIcon": {
      color: theme.palette.custom.ds.error.error500
    }
  }
}));
