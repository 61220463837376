import { makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import { IBaseProps } from "components/_baseProps";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    zIndex: 1299 /*Because the modals are 130+*/,
    display: "flex"
  },
  left: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr900,
    opacity: 0.7,
    flex: 1
  },
  right: {
    backgroundColor: "white",
    flex: 1
  }
}));

interface IProps extends IBaseProps {}

const OverlayPortalComponent = ({ children }: PropsWithChildren<IProps>) => {
  const classes = useStyles();

  return createPortal(
    <div className={classes.container}>
      <div className={classes.left}></div>
      <div className={classes.right}>{children}</div>
    </div>,
    document.body
  );
};

export default OverlayPortalComponent;
