import { ApiClient } from "api/apiClient";
import { IUser } from "models/user";
import { UserTokenError, UnauthorizedError } from "../models/errors";

const BASE_URL = "api/user";

export class UserApi {
  static readonly getUser = async (): Promise<IUser> => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  };

  static readonly getAccessToken = async (
    forceRenewal: boolean = false
  ): Promise<string> => {
    let endpoint = `${BASE_URL}/access_token`;

    if (forceRenewal) {
      endpoint = `${endpoint}?forceRenewal=true`;
    }

    try {
      return await ApiClient.get(endpoint);
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        throw new UserTokenError();
      } else {
        throw error;
      }
    }
  };

  static readonly logoutUser = () => UserApi._logout();

  private static readonly _logout = () => {
    const loginUrl = window.location.origin + "/account/logout";
    window.location.href = loginUrl;
  };
}
