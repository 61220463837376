import classnames from "classnames";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PageElementAction } from "models/pages";
import { DestinationSlotLabel } from "components/siteStructure/pageView/pageElements/destinationSlot/destinationSlotLabel";
import { useContext, useEffect, useState } from "react";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import { translate } from "utils/i18n";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";

interface IProps extends IBaseProps {
  visible: boolean;
  mode: PageElementAction;
  slot: string;
  containerId: string;
  position: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    height: theme.spacing(11),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "height ease 300ms, border-color ease 300ms",
    color: theme.palette.custom.ds.viking.viking500,
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      "& $labelText": {
        color: theme.palette.custom.ds.viking.viking500,
        fontWeight: 500
      },
      "& $circleIcon": {
        backgroundColor: theme.palette.custom.ds.viking.viking500
      }
    },
    "&:active": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      "& $labelText": {
        color: theme.palette.custom.ds.viking.viking500,
        fontWeight: 500
      }
    }
  },
  hidden: {
    opacity: 0,
    visibility: "hidden",
    height: theme.spacing(0),
    pointerEvents: "none",
    border: "unset",
    margin: 0,
    padding: 0
  },
  content: {},
  labelText: {},
  circleIcon: {}
}));

const strings = {
  add: "sitestructure.addmodulehere",
  move: "sitestructure.movemodulehere",
  copy: "sitestructure.copymodulehere"
};

const DESTINATION_SLOT_ID = "destination-slot";

export const DestinationSlot = (props: IProps) => {
  const { className, visible, slot, containerId, position } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    { [classes.hidden]: !visible },
    className
  );

  const [currentMode, setCurrentMode] = useState<string>("none");

  const [hover, setHover] = useState<boolean>(false);

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  useEffect(() => {
    const mode =
      dropContext.dropMode !== "none"
        ? dropContext.dropMode
        : copyContext.copyMode;

    setCurrentMode(mode);
  }, [dropContext.dropMode, copyContext.copyMode]);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (dropContext.dropMode === "add") {
      dropContext.add(containerId, slot, position);
    } else if (dropContext.dropMode === "move") {
      dropContext.move(containerId, slot, position);
    } else if (copyContext.copyMode === "copy") {
      copyContext.copy(containerId, slot, position);
    }
  };

  const clickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
    event.stopPropagation();

    const clickedElementId = event.currentTarget?.activeElement?.id ?? "";
    if (clickedElementId !== DESTINATION_SLOT_ID) {
      dropContext.clear();
      copyContext.clear();
    }
  };

  const label =
    currentMode !== "none" && hover
      ? translate(strings[currentMode], {
          label: ""
        })
      : translate("sitestructure.availableslot");

  return (
    <ClickAwayListener
      onClickAway={clickAway}
      mouseEvent={currentMode !== "none" ? "onClick" : false}
      touchEvent={currentMode !== "none" ? "onTouchEnd" : false}
    >
      <div
        className={rootClassName}
        id={DESTINATION_SLOT_ID}
        onClick={onClick}
        onMouseEnter={() => setHover(!hover)}
        onMouseLeave={() => setHover(!hover)}
      >
        <DestinationSlotLabel
          textClassName={classes.labelText}
          circleIconClassName={classes.circleIcon}
          className={classes.content}
          icon={hover ? "add" : ""}
          label={label}
        />
      </div>
    </ClickAwayListener>
  );
};
