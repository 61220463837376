import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import classNames from "classnames";
import {
  IPathSegment,
  SegmentTranslationCommandLoaders
} from "components/siteStructure/aliases/types";
import RestoreButton from "./RestoreButton";
import TranslatedUrlSegments from "components/siteStructure/aliases/components/urlEntry/TranslatedUrlSegments";
import { translate } from "utils/i18n";

export interface IUrlTranslationInputProps {
  culture: string;
  isDefault: boolean;
  editable?: boolean;
  loaders: SegmentTranslationCommandLoaders;
  ancestors: IPathSegment[];
  leaf: IPathSegment;
  loading: boolean;
  showRestore: boolean;
  onRestore: (isAlias: boolean) => void;
  onUpdate: (culture: string, value: string) => void;
}

export const UrlTranslationInput = (props: IUrlTranslationInputProps) => {
  const classes = useStyles();
  const {
    culture,
    isDefault,
    editable = false,
    loaders,
    leaf,
    ancestors,
    loading,
    showRestore,
    onRestore,
    onUpdate
  } = props;

  return (
    <div className={classes.row}>
      <div className={classes.cell}>
        <Typography className={classes.labelKey} component="label">
          {translate("sitestructure.urlssection.entities.url")}
        </Typography>
      </div>

      <div className={classes.pathCell}>
        <TranslatedUrlSegments segments={ancestors} isDefault={isDefault} />
        {editable ? (
          <div className={classNames(classes.pathSegment, classes.growFactor)}>
            <TextField
              className={classes.textField}
              variant="outlined"
              size="small"
              disabled={
                loaders.isSetTranslationLoading ||
                loaders.isUnsetTranslationLoading
              }
              placeholder={leaf.defaultTranslation}
              value={leaf.translationValue ?? ""}
              onChange={(e) => onUpdate(culture, e.target.value)}
            />

            {showRestore && <RestoreButton onRestore={onRestore} />}
            {loading && (
              <CircularProgress className={classes.restoreButton} size={16} />
            )}
          </div>
        ) : (
          <div className={classes.pathSegment}>
            <Typography className={classes.path} component="label">
              {leaf.defaultTranslation}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default UrlTranslationInput;
