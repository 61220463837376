import { useState } from "react";
import { useNotifications } from "components/hooks";
import { IBaseProps } from "components/_baseProps";
import { useDispatch, useSelector } from "react-redux";
import {
  changeItemStage,
  fetchSiteItems,
  fetchSiteItemsFromRoot,
  removeSiteItem,
  renameSiteItem,
  setLastUpdateInfo,
  setSelectedPageElement,
  unsetLastTriggeredCommandId,
  updateUriSegmentTranslation
} from "redux/actions/siteStructureActions";
import { translate } from "utils/i18n";
import { useHistory } from "react-router-dom";
import { SITE_STRUCTURE_BASE } from "routes/paths";

import { updateSubPath } from "utils/pathUtils";
import {
  ItemNotification,
  ModuleAddedToSlotNotification,
  SiteDirectoryRemovedNotification,
  SiteItemAddedNotification,
  SiteItemModifiedNotification,
  SiteItemMovedNotification,
  SiteItemRenamedNotification,
  SitePageRemovedNotification,
  UriSegmentTranslationSetNotification,
  PageAliasSetNotification,
  PageAliasUnsetNotification,
  ModuleCopiedIntoSlotNotification
} from "@d3-forge/forge-notifications";
import { ISiteItem } from "models/siteItem";
import { RenameSiteItemPayload } from "redux/actions/typings/siteStructureActions";
import { AnyFunction } from "typings";
import { OkModal } from "components/siteStructure/modals/messageModal/okModal";
import { noop } from "utils/typescriptUtils";
import { displayNotification } from "redux/actions/notificationActions";
import {
  selectLastTriggeredCommandId,
  selectRenamedItemPath
} from "redux/selectors/siteStructureSelectors";
import { userService } from "services/userService";
import { fetchUser } from "redux/actions/userActions";
import { aliasWasSet, aliasWasUnset } from "redux/actions/aliasesActions";

interface IProps extends IBaseProps {
  currentNode: ISiteItem;
}

const siteStructureRoot = `${SITE_STRUCTURE_BASE}/~`;

const isCurrentOrParent = (current: ISiteItem, notificationItemId: string) =>
  current.nodeId === notificationItemId ||
  current.parentNodesIds.includes(notificationItemId);

interface IModalState {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
}

export const SiteStructureNotificationListener = (props: IProps) => {
  const { currentNode } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const lastTriggeredCommandId = useSelector(selectLastTriggeredCommandId);

  const renamedItemPath = useSelector(selectRenamedItemPath);

  const [modal, setModal] = useState<IModalState>({
    open: false,
    message: "",
    title: "",
    onClose: noop
  });

  const alertDisruptiveChange = (
    titleStringKey: string,
    messageStringKey: string,
    onConfirm: AnyFunction
  ) => {
    setModal({
      open: true,
      title: translate(titleStringKey),
      message: translate(messageStringKey),
      onClose: () => {
        onConfirm();
        setModal((prev) => ({ ...prev, open: false }));
      }
    });
  };

  useNotifications(
    [
      "SiteDirectoryAddedNotification",
      "SitePageAddedNotification",
      "SiteMenuAddedNotification",
      "SiteDirectoryCopiedNotification",
      "SitePageCopiedNotification",
      "SiteMenuCopiedNotification"
    ],
    (ntf: SiteItemAddedNotification) =>
      dispatch(fetchSiteItemsFromRoot({ path: ntf.fullPath }))
  );

  useNotifications(
    ["SiteDirectoryRemovedNotification"],
    (ntf: SiteDirectoryRemovedNotification) => {
      if (isCurrentOrParent(currentNode, ntf.itemId)) {
        alertDisruptiveChange(
          "sitestructure.parentremoved.title",
          "sitestructure.parentremoved.message",
          () => {
            history.push(`${siteStructureRoot}/`);
            dispatch(removeSiteItem(ntf.itemId));
          }
        );
      } else {
        dispatch(removeSiteItem(ntf.itemId));
      }
    },
    [currentNode]
  );

  useNotifications(
    ["SitePageRemovedNotification"],
    (ntf: SitePageRemovedNotification) => {
      if (currentNode.nodeId === ntf.itemId) {
        alertDisruptiveChange(
          "sitestructure.pageremoved.title",
          "sitestructure.pageremoved.message",
          () => {
            history.push(siteStructureRoot);
            dispatch(removeSiteItem(ntf.itemId));
          }
        );
      } else {
        dispatch(removeSiteItem(ntf.itemId));
      }
    },
    [currentNode]
  );

  useNotifications(
    ["SiteMenuRemovedNotification"],
    (ntf: SitePageRemovedNotification) => {
      if (currentNode.nodeId === ntf.itemId) {
        alertDisruptiveChange(
          "sitestructure.menuremoved.title",
          "sitestructure.menuremoved.message",
          () => {
            history.push(siteStructureRoot);
            dispatch(removeSiteItem(ntf.itemId));
          }
        );
      } else {
        dispatch(removeSiteItem(ntf.itemId));
      }
    },
    [currentNode]
  );

  useNotifications(
    [
      "SiteDirectoryMovedNotification",
      "SitePageMovedNotification",
      "SiteMenuMovedNotification"
    ],
    (ntf: SiteItemMovedNotification) => {
      if (isCurrentOrParent(currentNode, ntf.itemId)) {
        alertDisruptiveChange(
          "sitestructure.itemmoved.title",
          "sitestructure.itemmoved.message",
          () => {
            dispatch(removeSiteItem(ntf.itemId));
            dispatch(
              fetchSiteItemsFromRoot({
                path: ntf.fullPath,
                onSuccess: () =>
                  history.push(`${SITE_STRUCTURE_BASE}/${ntf.fullPath}`)
              })
            );
          }
        );
      } else {
        dispatch(removeSiteItem(ntf.itemId));
        dispatch(
          fetchSiteItemsFromRoot({
            path: ntf.fullPath
          })
        );
      }
    }
  );

  useNotifications(
    [
      "SiteDirectoryRenamedNotification",
      "SitePageRenamedNotification",
      "SiteMenuRenamedNotification"
    ],
    (ntf: SiteItemRenamedNotification) => {
      const renamePayload: RenameSiteItemPayload = {
        itemId: ntf.itemId,
        fullPath: ntf.fullPath,
        newName: ntf.newName
      };
      if (isCurrentOrParent(currentNode, ntf.itemId)) {
        alertDisruptiveChange(
          "sitestructure.itemrenamed.title",
          "sitestructure.itemrenamed.message",
          () => {
            dispatch(renameSiteItem(renamePayload));
            dispatch(fetchSiteItems({ id: ntf.itemId }));

            const redirectPath = updateSubPath(currentNode.path, ntf.fullPath);
            history.push(`${SITE_STRUCTURE_BASE}/${redirectPath}`);
          }
        );
      } else {
        dispatch(renameSiteItem(renamePayload));
        dispatch(fetchSiteItems({ id: ntf.itemId }));
      }
      if (userService.hasSiteItem(renamedItemPath)) {
        dispatch(fetchUser());
      }
    },
    [currentNode]
  );

  useNotifications(
    [
      "UriSegmentTranslationSetNotification",
      "UriSegmentTranslationUnsetNotification"
    ],
    (ntf: UriSegmentTranslationSetNotification) => {
      dispatch(
        updateUriSegmentTranslation({
          nodeId: ntf.itemId,
          culture: ntf.culture,
          value: ntf.value
        })
      );
      dispatch(fetchSiteItems({ id: ntf.itemId }));
      if (currentNode.parentNodesIds.includes(ntf.itemId)) {
        dispatch(
          displayNotification({
            message: "urlspage.translationchanged",
            severity: "info"
          })
        );
      }
    },
    [currentNode]
  );

  useNotifications(
    [
      "DirectoryPublishedNotification",
      "PagePublishedNotification",
      "MenuPublishedNotification"
    ],
    (ntf: ItemNotification) =>
      dispatch(
        changeItemStage({
          itemId: ntf.itemId,
          stage: "Published"
        })
      )
  );

  useNotifications(
    [
      "DirectoryUnpublishedNotification",
      "PageUnpublishedNotification",
      "MenuUnpublishedNotification"
    ],
    (ntf: ItemNotification) =>
      dispatch(
        changeItemStage({
          itemId: ntf.itemId,
          stage: "Unpublished"
        })
      )
  );

  useNotifications(
    [
      "DirectoryReviewedNotification",
      "PageReviewedNotification",
      "MenuReviewedNotification"
    ],
    (ntf: ItemNotification) =>
      dispatch(
        changeItemStage({
          itemId: ntf.itemId,
          stage: "Reviewed"
        })
      )
  );

  useNotifications(
    ["SiteItemModifiedNotification"],
    (ntf: SiteItemModifiedNotification) =>
      dispatch(
        setLastUpdateInfo({
          itemId: ntf.itemId,
          userId: ntf.author.userId,
          firstName: ntf.author.firstName,
          lastName: ntf.author.lastName,
          dateModified: ntf.dateModified
        })
      )
  );

  useNotifications(
    [
      "ModuleAddedToSlotNotification",
      "LayoutAddedToSlotNotification",
      "ModuleCopiedIntoSlotNotification"
    ],
    (ntf: ModuleAddedToSlotNotification | ModuleCopiedIntoSlotNotification) => {
      if (lastTriggeredCommandId === ntf.commandId) {
        dispatch(setSelectedPageElement(ntf.instanceId));
        dispatch(unsetLastTriggeredCommandId());
      }
    }
  );

  useNotifications(
    ["PageAliasSetNotification"],
    (notificationMetadata: PageAliasSetNotification) => {
      dispatch(aliasWasSet({ notificationMetadata }));
    }
  );

  useNotifications(
    ["PageAliasUnsetNotification"],
    (notificationMetadata: PageAliasUnsetNotification) => {
      dispatch(aliasWasUnset({ notificationMetadata }));
    }
  );

  return (
    <>
      <OkModal
        open={modal.open}
        modalTitle={modal?.title}
        modalMessage={modal.message}
        onClose={modal.onClose}
      />
    </>
  );
};
