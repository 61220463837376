import { ApiClient } from "api/apiClient";
import { SiteItems } from "models/siteItem";

const BASE_URL = "api/siteitems";

export class SiteItemsApi {
  static readonly getNodeAndChildren = async (
    id: string
  ): Promise<SiteItems> => {
    const endpoint = `${BASE_URL}?id=${id}`;
    return ApiClient.get(endpoint);
  };

  static readonly getNodeAndChildrenFromRoot = async (
    path: string
  ): Promise<SiteItems> => {
    const query = `path=${path}`;
    const endpoint = `${BASE_URL}/fromroot?${query}`;
    return ApiClient.get(endpoint);
  };
}
