import {
  IHttpConnectionOptions,
  HttpTransportType,
  HubConnectionBuilder,
  HubConnection,
  LogLevel
} from "@microsoft/signalr";
import { filter, keys, map } from "lodash";
import { v4 as uuid } from "uuid";
import urljoin from "url-join";
import { UserApi } from "api/userApi";
import { TraceableNotification } from "@d3-forge/forge-notifications";
import { AnyFunction } from "typings";

interface INotificationSubscriptions {
  [notificationName: string]: {
    [subscriptionId: string]: AnyFunction;
  };
}

export interface INotificationServiceOptions {
  notificationDispatcherBaseUrl: string;
  onClose?: (error?: Error | undefined) => void;
  onReconnecting?: (error?: Error | undefined) => void;
  onReconnected?: (connectionId?: string | undefined) => void;
}

interface IGroupsSubscriptions {
  [group: string]: number;
}

class NotificationService {
  private readonly ADD_GROUP_METHOD_NAME = "AddToGroup";
  private readonly REMOVE_GROUP_METHOD_NAME = "RemoveFromGroup";
  readonly VSM_GROUP_NAME = "VSM";

  private connection: HubConnection | null = null;
  private notificationSubscriptions: INotificationSubscriptions = {};
  private groupsSubscriptions: IGroupsSubscriptions = {};

  start(options: INotificationServiceOptions) {
    const {
      notificationDispatcherBaseUrl,
      onClose,
      onReconnected,
      onReconnecting
    } = options;

    const connectionOptions: IHttpConnectionOptions = {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => UserApi.getAccessToken()
    };

    const notificationHubUrl = urljoin(
      notificationDispatcherBaseUrl,
      "/notificationsHub"
    );

    this.connection = new HubConnectionBuilder()
      .withUrl(notificationHubUrl, connectionOptions)
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    this.connection.on(
      "notificationReceived",
      this.onNotificationReceived.bind(this)
    );

    if (onClose) {
      this.connection.onclose(onClose);
    }

    this.connection.onreconnected(async (connectionId) => {
      await this.handleReconnection();

      if (onReconnected) {
        onReconnected(connectionId);
      }
    });

    if (onReconnecting) {
      this.connection.onreconnecting(onReconnecting);
    }

    return this.connection.start();
  }

  stop() {
    this.checkConnection();
    return this.connection?.stop();
  }

  private async handleReconnection() {
    try {
      await this.resubscribeToGroups();
    } catch (err) {
      console.log("Error while trying to resubscribe to groups", err);
    }
  }

  private async resubscribeToGroups() {
    const subscribedGroups = filter(
      keys(this.groupsSubscriptions),
      (groupName) => this.groupsSubscriptions[groupName] > 0
    );

    const getInvokePromisesForMethod = (method: string) =>
      map(subscribedGroups, (groupName) =>
        this.connection?.invoke(method, groupName)
      );

    const { ADD_GROUP_METHOD_NAME, REMOVE_GROUP_METHOD_NAME } = this;

    //Unsubscribe already subscribed groups
    await Promise.all(getInvokePromisesForMethod(REMOVE_GROUP_METHOD_NAME));
    console.log("Unsubscribe from groups", subscribedGroups.join(" , "));

    //Subscribe to the groups again
    await Promise.all(getInvokePromisesForMethod(ADD_GROUP_METHOD_NAME));
    console.log("Subscribe to groups", subscribedGroups.join(" , "));
  }

  subscribeToGroup(groupName: string) {
    this.checkConnection();

    if (this.groupsSubscriptions[groupName]) {
      this.groupsSubscriptions[groupName]++;
      return;
    }

    this.groupsSubscriptions[groupName] = 1;
    return this.connection?.invoke(this.ADD_GROUP_METHOD_NAME, groupName);
  }

  unsubscribeFromGroup(groupName: string) {
    this.checkConnection();

    if (this.groupsSubscriptions[groupName]) {
      this.groupsSubscriptions[groupName]--;
    }

    if (!this.groupsSubscriptions[groupName]) {
      return this.connection?.invoke(this.REMOVE_GROUP_METHOD_NAME, groupName);
    }
  }

  on<T extends TraceableNotification>(
    notificationName: string,
    callback: (ntf: T) => void
  ): string {
    this.checkConnection();

    const subscriptionId = uuid();

    if (!this.notificationSubscriptions[notificationName]) {
      this.notificationSubscriptions[notificationName] = {};
    }

    this.notificationSubscriptions[notificationName][subscriptionId] = callback;

    return subscriptionId;
  }

  off(notificationName: string, subscriptionId: string) {
    this.checkConnection();

    if (this.notificationSubscriptions[notificationName]) {
      delete this.notificationSubscriptions[notificationName][subscriptionId];
    }
  }

  private onNotificationReceived(notification: TraceableNotification) {
    const callbacks = this.notificationSubscriptions[notification.notification];
    for (const callback in callbacks) {
      callbacks[callback](notification);
    }
  }

  private checkConnection() {
    if (!this.connection) {
      const message = "[NotificationService] connection not started";
      throw new Error(message);
    }
  }
}

export default new NotificationService();
