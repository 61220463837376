import React from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { DefaultModuleIcon } from "components/shared/defaultModuleIcon";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  label: React.ReactNode;
  icon: string;
  textClassName?: string;
  circleIconClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.custom.ds.viking.viking500,
    gap: theme.spacing(1.5)
  },
  circleIcon: {
    backgroundColor: theme.palette.custom.ds.viking.viking300,
    borderRadius: "50%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4)
  },
  text: {
    transition: "color ease 300ms",
    color: theme.palette.custom.ds.viking.viking500,
    userSelect: "none",
    fontSize: theme.typography.pxToRem(13)
  },
  icon: {
    fontSize: theme.spacing(2)
  }
}));

export const DestinationSlotLabel = React.memo((props: IProps) => {
  const { className, textClassName = "", circleIconClassName = "" } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const textClassNames = classnames(classes.text, textClassName);
  const circleIconClassNames = classnames(
    classes.circleIcon,
    circleIconClassName
  );
  const { label, icon } = props;

  return (
    <div className={rootClassName}>
      <span className={circleIconClassNames}>
        {icon ? (
          <UxdIcon className={classes.icon} name={icon} />
        ) : (
          <DefaultModuleIcon />
        )}
      </span>
      <Typography className={textClassNames} variant="body2" component="span">
        {label}
      </Typography>
    </div>
  );
});
