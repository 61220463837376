import { TagsApi } from "api/tagsApi";
import { TagsPickerChip } from "components/siteStructure/pageView/pageSidebar/moduleProperties/tagsPickerProperty/tagsPickerChip";
import { IBaseProps } from "components/_baseProps";
import { NEUTRAL_LANGUAGE_CODE } from "models/systemLanguage";
import { useQuery } from "react-query";

interface IProps extends IBaseProps {
  slug: string;
  onRemoveClick?: (slug: string) => void;
  disabled: boolean;
}

const FIVE_MINUTES = 1000 * 60 * 60 * 5;

export const TagsPickerChipContainer = (props: IProps) => {
  const { className, slug, onRemoveClick, disabled } = props;

  const { data, isLoading, error } = useQuery(
    ["tagsPickerChip", slug],
    () => TagsApi.getTagPickerItems(slug, NEUTRAL_LANGUAGE_CODE),
    {
      staleTime: FIVE_MINUTES,
      retry: false
    }
  );

  const { slug: dataSlug = slug, label = "" } = data ?? {};

  return (
    <TagsPickerChip
      className={className}
      slug={dataSlug}
      label={label}
      loading={isLoading}
      error={getError(error)}
      onRemoveClick={onRemoveClick}
      disabled={disabled}
    />
  );
};

function getError(error: unknown) {
  if (typeof error === "string") {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "object") {
    return error?.toString();
  }

  return null;
}
