import classnames from "classnames";
import { Avatar, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  fromAggregate: string;
  revision: number;
  publish: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.text.content,
    backgroundColor: theme.palette.common.white,
    border: "1px solid",
    borderColor:
      theme.palette.custom.greyscales.bordersAndSeparators.lineDarker,
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: theme.typography.pxToRem(12)
  },
  publish: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custom.stages.published,
    borderColor: theme.palette.custom.stages.published
  },
  hidden: {
    visibility: "hidden"
  }
}));

export const HistoryEntryRevision = (props: IProps) => {
  const { className, revision, publish, fromAggregate } = props;
  const classes = useStyles();

  const rootClassName = classnames(
    classes.root,
    {
      [classes.publish]: publish,
      [classes.hidden]: fromAggregate === "sitestructure"
    },
    className
  );

  return <Avatar className={rootClassName}>v{revision}</Avatar>;
};
