import { MenuItem, ListItemIcon, makeStyles } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  useCopy,
  useCut,
  usePaste
} from "components/siteStructure/header/clipboardHook";
import { ISiteItem, ROOT_ID } from "models/siteItem";
import { SiteNodeTypes } from "models/siteStructure";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { openDeleteModal } from "redux/actions/siteItem/deleteSiteItemActions";
import { openPasteModal } from "redux/actions/siteItem/pasteSiteItemActions";
import { userService } from "services/userService";
import { translate } from "utils/i18n";

interface IProps {
  node: ISiteItem;
  onClickCallback?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.spacing(2),
    color: theme.palette.custom.ds.viking.viking500
  }
}));

interface ItemProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
const Item = React.forwardRef<HTMLLIElement, ItemProps>((itemProps, ref) => {
  const { children, disabled = false, onClick = () => undefined } = itemProps;
  return (
    <MenuItem onClick={onClick} disabled={disabled} ref={ref}>
      {children}
    </MenuItem>
  );
});

export function ClipboardPageAction(props: IProps) {
  const { node, onClickCallback = () => undefined } = props;
  const classes = useStyles();

  const isRootNode = node.nodeId === ROOT_ID;
  const canDelete = userService.hasSiteItemPermissions(node.path);

  const dispatch = useDispatch();
  const [canCopy, copy] = useCopy(node);
  const [canCut, cut] = useCut(node);
  const [canPaste, showConfirmPasteModal, paste] = usePaste(node);

  const onCopy = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClickCallback(event);
    copy();
  };

  const onCut = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClickCallback(event);
    cut();
  };

  const onPaste = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClickCallback(event);

    if (showConfirmPasteModal) {
      dispatch(openPasteModal(node));
      return;
    }

    paste();
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(openDeleteModal(node));
    onClickCallback(event);
  };

  return (
    <>
      <Item onClick={onDeleteClick} disabled={!canDelete || isRootNode}>
        <ListItemIcon>
          <UxdIcon className={classes.icon} name="delete" outline={true} />
        </ListItemIcon>
        {translate("sitestructure.delete")}
      </Item>
      <Item onClick={onCopy} disabled={!canCopy || isRootNode}>
        <ListItemIcon>
          <UxdIcon className={classes.icon} name="content_copy" />
        </ListItemIcon>
        {translate("sitestructure.copy")}
      </Item>
      <Item onClick={onCut} disabled={!canCut || isRootNode}>
        <ListItemIcon>
          <UxdIcon className={classes.icon} name="content_cut" />
        </ListItemIcon>
        {translate("sitestructure.cut")}
      </Item>
      {node.nodeType === SiteNodeTypes.DIRECTORY && (
        <Item onClick={onPaste} disabled={!canPaste}>
          <ListItemIcon>
            <UxdIcon className={classes.icon} name="content_paste" />
          </ListItemIcon>
          {translate("sitestructure.paste")}
        </Item>
      )}
    </>
  );
}
