import { UserApi } from "api/userApi";
import { batch } from "react-redux";
import T from "i18n-react";
import store from "redux/store";
import { setUser } from "redux/actions/userActions";
import {
  fetchModules,
  fetchLayouts,
  fetchTemplates,
  fetchContexts
} from "redux/actions/assetCatalogActions";
import { IUser } from "models/user";
import { fetchLocalization } from "utils/i18n";
import { ConfigurationApi } from "api/configurationApi";
import { IConfiguration } from "models/configuration";
import { setConfiguration } from "redux/actions/appActions";
import notificationService, {
  INotificationServiceOptions
} from "services/notificationService";
import { fetchFrontendSites } from "redux/actions/frontendActions";
import {
  fetchEntitiesDefinitions,
  fetchSystemLanguages,
  fetchTagsDatasources
} from "redux/actions/forgeActions";
import { fetchRoutes } from "redux/actions/variablesActions";
import { fetchAliases } from "redux/actions/aliasesActions";

export async function initialize() {
  const user = await UserApi.getUser();

  // Ensure that the access token is refreshed before calling in parallel FORGE Management API.
  // This is similar to the mechanism implemented on FORGE when Media API is used.
  // First try to refresh the access token and after that the initialization can start.
  await UserApi.getAccessToken();

  dispatchFireAndForgetActions();

  const localization = await fetchLocalization();
  T.setTexts(localization, { MDFlavor: 1 });

  const configuration = await ConfigurationApi.getConfiguration();
  dispatchInitActions(user, configuration);

  const notificationServiceOptions: INotificationServiceOptions = {
    notificationDispatcherBaseUrl: configuration.notificationDispatcherBaseUrl
  };

  await notificationService.start(notificationServiceOptions);
}

function dispatchFireAndForgetActions() {
  const { dispatch } = store;
  batch(() => {
    dispatch(fetchFrontendSites());
    dispatch(fetchModules());
    dispatch(fetchLayouts());
    dispatch(fetchTemplates());
    dispatch(fetchContexts());
    dispatch(fetchSystemLanguages());
    dispatch(fetchEntitiesDefinitions());
    dispatch(fetchTagsDatasources());
    dispatch(fetchRoutes());
    dispatch(fetchAliases());
  });
}

function dispatchInitActions(user: IUser, configuration: IConfiguration) {
  batch(() => {
    store.dispatch(setUser(user));
    store.dispatch(setConfiguration(configuration));
  });
}
