import { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from "@material-ui/lab";
import { ContentPickerTagsInputTextField } from "./contentPickerTagsInputTextField";
import { translate } from "utils/i18n";
import { ContentPickerTagsInputValues } from "./contentPickerTagsInputValues";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { SuggestionListItem } from "components/shared/suggestionListItem";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  values: TagInputItem[];
  suggestions: TagInputItem[];
  onChange: (newValue: TagInputItem[], detail: TagInputItem) => void;
  inputValue: string;
  onInputChange: (newValue: string) => void;
  error: string | null;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  values: {
    height: theme.spacing(7)
  },
  field: {},
  list: {
    padding: 0,
    "& > *": {
      padding: 0,
      '&[aria-selected="true"],&[data-focus="true"],&:active': {
        backgroundColor: theme.palette.custom.mainAccent.lightHover,
        "& *": {
          backgroundColor: theme.palette.custom.mainAccent.lightHover
        }
      }
    }
  },
  listItem: {
    flex: 1
  }
}));

const validationRegex = /\w/gi;

export const ContentPickerTagsInput = (props: IProps) => {
  const {
    className,
    inputValue,
    suggestions,
    error,
    loading,
    onChange,
    onInputChange,
    values
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [open, setOpen] = useState(false);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <ContentPickerTagsInputTextField
      loading={loading}
      error={error ?? false}
      {...params}
    />
  );

  const renderItem = (
    item: TagInputItem,
    state: AutocompleteRenderOptionState
  ) => (
    <SuggestionListItem
      className={classes.listItem}
      key={item.translationId}
      value={item.label}
      search={state.inputValue}
      highlight={item.source !== "internal"}
    />
  );

  const handleInputChange = (_: ChangeEvent<{}>, newValue: string) => {
    if (newValue && !newValue.match(validationRegex)) {
      return;
    }

    onInputChange(newValue);
  };

  const handleOpenSuggestions = (event: ChangeEvent<{}>) => {
    if (error || event.type === "mousedown") {
      return;
    }

    setOpen(true);
  };

  const handleCloseSuggestion = (
    _: ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === "select-option") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (
    _: ChangeEvent<{}>,
    __: TagInputItem[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagInputItem> | undefined
  ) => {
    if (!details || reason !== "select-option") {
      return;
    }

    // Check if selected value was already selected previously
    if (!values.some((v) => v.translationId === details.option.translationId)) {
      const newValues = [...values, details.option];
      onChange(newValues, details.option);
    }

    setOpen(false);
  };

  const handleRemoveTag = (tag: TagInputItem) => {
    const newValues = values.filter(
      (v) => v.translationId !== tag.translationId
    );

    onChange(newValues, tag);
  };

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.tags")} />
      <Autocomplete
        className={classes.field}
        classes={{
          listbox: classes.list
        }}
        multiple={true}
        value={values}
        size="small"
        open={open}
        freeSolo={false}
        options={suggestions}
        noOptionsText={translate("tagspicker.noresults")}
        disableClearable={true}
        loading={loading}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onOpen={handleOpenSuggestions}
        onClose={handleCloseSuggestion}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, val) =>
          option.translationId === val.translationId
        }
        renderOption={renderItem}
        openOnFocus={false}
        renderInput={renderInput}
        includeInputInList={true}
        renderTags={() => null}
        filterSelectedOptions={true}
        filterOptions={(options) => options}
      />
      <ContentPickerTagsInputValues
        className={classes.values}
        tags={values}
        onRemove={handleRemoveTag}
      />
    </div>
  );
};
