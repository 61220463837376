import * as React from "react";

import { TypographyProps } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";

export const text = (value: string, options?: TypographyProps) => (
  <Typography {...options}>{value}</Typography>
);

export const localizedText = (
  stringKey: string,
  options?: TypographyProps,
  interpolatedValues?: {}
) => text(translate(stringKey, interpolatedValues), options);
