import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  Batch,
  PublishDirectory,
  PublishMenu,
  PublishPage
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { PublishSiteItemsPayload } from "redux/actions/typings/siteStructureActions";

const COMMAND_MAP = {
  Page: PublishPage,
  Directory: PublishDirectory,
  Menu: PublishMenu
};

export function* sendPublishSiteItems(action: Action<PublishSiteItemsPayload>) {
  try {
    const { items } = action.payload;

    const commands = items.map(
      (item) =>
        new COMMAND_MAP[item.nodeType]({
          itemId: item.nodeId
        })
    );

    const command = new Batch({ commands });
    const commandAction = issueCommand({
      command,
      errorMessage: "notification.publishitem.error",
      successMessage: "notification.publishitem.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
