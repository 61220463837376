import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { Avatar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { ColorUtils } from "utils/colorUtils";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { UserApi } from "api/userApi";

interface IProps extends IBaseProps {
  userId: string;
  initials: string;
  fullName: string;
  compact?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "2px",
    "&:hover": {
      "& $textLabel": {
        color: theme.palette.common.white
      }
    }
  },
  userAvatar: (props: IProps) => {
    const color = ColorUtils.generateColor(props.userId);
    return {
      zIndex: 1,
      width: "32px",
      height: "32px",
      backgroundColor: color,
      color: theme.palette.getContrastText(color)
    };
  },
  textLabel: {
    color: theme.palette.common.white,
    zIndex: 1,
    "&$active": {
      opacity: 1,
      backgroundColor: "unset"
    },
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.spacing(1)
  },
  compact: {
    width: "100%"
  },
  active: {}
}));

export const UserAvatar = (props: IProps) => {
  const { className, initials, fullName, compact = true } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className, {
    [classes.compact]: compact
  });
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleLogout = () => {
    UserApi.logoutUser();
  };

  return (
    <>
      <IconButton className={rootClassName} onClick={handleAvatarClick}>
        <Avatar className={classes.userAvatar}>{initials}</Avatar>
        {!compact && (
          <Typography className={classes.textLabel} component={"span"}>
            {fullName}
          </Typography>
        )}
      </IconButton>
      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={handleClose}>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
