import {
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { IInheritedAuthorizationGroup } from "models/authorizationGroup";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  authGroups: IInheritedAuthorizationGroup[];
}

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.palette.custom.ds.grayscales.gr800
  },
  list: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(5),
    width: "75vh"
  },
  listItem: {
    backgroundColor: "white",
    marginBottom: "5px",
    height: theme.spacing(7.5),
    paddingLeft: theme.spacing(2.5),
    "& .MuiTypography-body1": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20)
    },
    "& .MuiTypography-body2": {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16)
    }
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500
  }
}));

export function InheritedAuthorizationGroups(props: IProps) {
  const { authGroups } = props;
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" className={classes.text}>
        {translate("sitestructure.permissions.inheritedgroups")}
      </Typography>
      <List className={classes.list} disablePadding>
        {authGroups.map((authGroup, index) => (
          <ListItem className={classes.listItem} key={index}>
            <ListItemText
              primary={authGroup.name}
              secondary={authGroup.description}
            />
            <Tooltip
              title={`${translate(
                "sitestructure.permissions.inheritancetooltip"
              )} ${authGroup.inheritancePath}`}
              placement="top"
              arrow
            >
              <ListItemIcon>
                <UxdIcon name="info_outline" outline className={classes.icon} />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </>
  );
}
