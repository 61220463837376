import { put, call } from "redux-saga/effects";
import { IAliasEntity } from "models/aliases";
import { AliasesApi } from "api/aliasesApi";
import {
  fetchAliasesFailed,
  fetchAliasesSucceded
} from "redux/actions/aliasesActions";

export function* getAliases() {
  try {
    const aliases: IAliasEntity[] = yield call(AliasesApi.getAliases);
    yield put(fetchAliasesSucceded({ aliases }));
  } catch (error) {
    yield put(fetchAliasesFailed(error));
  }
}
