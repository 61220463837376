import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { useContext, useState } from "react";
import { ContentPickerContext } from "components/contentPicker/contentPickerContext";
import { useCancelableImageLoading } from "components/hooks";

interface IProps extends IBaseProps {
  entityTypeCode: string;
  translationId: string;
  selected: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    height: theme.spacing(13),
    width: theme.spacing(13)
  },
  image: {
    clipPath: "circle(52px at center)",
    height: "100%",
    width: "100%"
  },
  tick: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: "50%",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custom.secondaryAccent.selected
  },
  placeholder: {
    position: "absolute",
    bottom: 0,
    right: 0,
    top: 0,
    left: 0,
    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey95,
    opacity: 100,
    borderRadius: "50%",
    transition: theme.transitions.create("opacity")
  },
  transparent: {
    opacity: 0
  }
}));

const getThumbnailUrl = (entityTypeCode: string, translationId: string) =>
  `/api/thumbnail/${entityTypeCode}/${translationId}`;

export const ContentPickerCardThumbnail = (props: IProps) => {
  const { className, entityTypeCode, translationId, selected } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const context = useContext(ContentPickerContext);

  const [src, setSrc] = useState(
    getThumbnailUrl(entityTypeCode, translationId)
  );

  const imgRef = useCancelableImageLoading(src, [context.queryFilter]);

  const [entityType] = entityTypeCode.split(".");

  const [loaded, setLoaded] = useState(false);

  const placeholderClassName = classnames(classes.placeholder, {
    [classes.transparent]: loaded
  });

  return (
    <div className={rootClassName}>
      <img
        ref={imgRef}
        className={classes.image}
        loading="lazy"
        src={src}
        onLoad={() => !loaded && setLoaded(true)}
        onError={() => setSrc(`/thumbs/${entityType}.png`)}
        alt="thumbnail"
      />
      <div className={placeholderClassName}></div>
      {selected && <UxdIcon className={classes.tick} name="check" />}
    </div>
  );
};
