import React from "react";
import classnames from "classnames";
import { makeStyles, TableHead, TableRow, TableCell } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    "& $cell": {
      paddingLeft: theme.spacing(1)
    },
    "& $cell:first-child": {
      paddingLeft: theme.spacing(6)
    }
  },
  cell: {
    lineHeight: 0,
    fontSize: "0.75rem",
    color: theme.palette.custom.ds.grayscales.gr600,
    fontWeight: 400
  }
}));

type TableColumn = {
  id: string;
  label: string;
};

const getColumns = (): TableColumn[] => [
  {
    id: "name",
    label: translate("sitestructure.name")
  },
  {
    id: "lastUpdated",
    label: translate("sitestructure.lastupdated")
  },
  {
    id: "errors",
    label: translate("sitestructure.errors")
  }
];

export const ChildrenListHeader = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const columns = getColumns();

  return (
    <TableHead className={rootClassName}>
      <TableRow>
        {columns.map((col) => (
          <TableCell key={col.id} className={classes.cell} align="left">
            {col.label}
          </TableCell>
        ))}
        <TableCell key="actions" className={classes.cell} align="left" />
      </TableRow>
    </TableHead>
  );
};
