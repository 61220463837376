import { IAuthorizationGroup } from "models/authorizationGroup";
import { createAction } from "redux-actions";

export const loadAuthorizationGroups = createAction(
  `LOAD_AUTHORIZATION_GROUPS`,
  (groups: IAuthorizationGroup[]) => groups
);

export const addAuthorizationGroup = createAction(
  `ADD_AUTHORIZATION_GROUP`,
  (group: IAuthorizationGroup) => group
);

export const removeAuthorizationGroup = createAction(
  `REMOVE_AUTHORIZATION_GROUP`,
  (authGroupId: string) => authGroupId
);

export const updateAuthorizationGroup = createAction(
  `UPDATE_AUTHORIZATION_GROUP`,
  (group: IAuthorizationGroup) => group
);

export const setCreateAuthGroupFlag = createAction(
  `SET_CREATE_AUTH_FLAG`,
  (flag: boolean) => flag
);

export const setAuthorizationGroupUsersUpdatedFlag = createAction(
  `SET_AUTHORIZATION_GROUP_USERS_UPDATED_FLAG`,
  (flag: boolean) => flag
);
