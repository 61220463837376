import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { PublishPage } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";

export function* sendPublishPage(action: Action<string>) {
  try {
    const itemId = action.payload;

    const command = new PublishPage({
      itemId
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.publishpage.error",
      successMessage: "notification.publishpage.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
