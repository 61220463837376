import {
  LastUpdateDateValue,
  ContentPickerContext
} from "components/contentPicker/contentPickerContext";
import { IBaseProps } from "components/_baseProps";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import classnames from "classnames";
import { useContext, useMemo } from "react";
import { translate } from "utils/i18n";
import { TextFieldLabel } from "components/shared/textFieldLabel";

type LastUpdateDateOption = {
  label: string;
  value: LastUpdateDateValue;
};

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0
  }
}));

const lastUpdateDateOptionsFactory = () => {
  const any = translate("contentpicker.any");
  const pastHour = translate("contentpicker.pasthour");
  const pastDay = translate("contentpicker.pastday");
  const pastWeek = translate("contentpicker.pastweek");
  const pastMonth = translate("contentpicker.pastmonth");
  const pastYear = translate("contentpicker.pastyear");

  const options: LastUpdateDateOption[] = [
    {
      label: any,
      value: "(any)"
    },
    {
      label: pastHour,
      value: "past-hour"
    },
    {
      label: pastDay,
      value: "past-day"
    },
    {
      label: pastWeek,
      value: "past-week"
    },
    {
      label: pastMonth,
      value: "past-month"
    },
    {
      label: pastYear,
      value: "past-year"
    }
  ];
  return options;
};

const ContentPickerLastUpdateDateSelect = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { queryFilter, setQueryFilter } = useContext(ContentPickerContext);

  const options = useMemo(() => lastUpdateDateOptionsFactory(), []);

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.lastupdatedate")} />
      <Select
        fullWidth={true}
        margin="dense"
        variant="outlined"
        value={queryFilter.lastUpdateDate}
        onChange={(e) =>
          setQueryFilter(
            "lastUpdateDate",
            e.target.value as LastUpdateDateValue
          )
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ContentPickerLastUpdateDateSelect;
