import React from "react";
import classnames from "classnames";
import {
  makeStyles,
  Button,
  IconButton,
  Tooltip,
  IconButtonProps
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { userService } from "services/userService";
import { UxdIcon } from "components/shared/uxdIcon";

// TODO: Review this component since is becoming too complex for its use case

interface IProps extends IBaseProps, IconButtonProps {
  children?: React.ReactNode;
  icon?: string;
  iconClassName?: string;
  disabled?: boolean;
  permissions?: string[];
  permissionPolicy?: "hide" | "disable";
  tooltip?: string;
  variant?: "text" | "outlined" | "contained";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {}
}));

export const ActionButton = React.forwardRef<HTMLButtonElement, IProps>(
  (props, ref) => {
    const {
      className,
      icon,
      disabled = false,
      permissions = [],
      permissionPolicy = "hide",
      tooltip,
      iconClassName: iconClassNameProp,
      children = <></>,
      variant,
      ...other
    } = props;

    if (variant && icon) {
      console.warn(
        "Variant prop cannot be used in conjunction with Icon prop. Variant will be ignored."
      );
    }

    // Ugly but we need a default...
    const finalVariant = variant ? variant : "text";

    const classes = useStyles();

    const allowed = userService.hasPermissions(...permissions);
    if (!allowed && permissionPolicy === "hide") {
      return <></>;
    }

    const rootClassName = classnames(classes.root, className);
    const iconClassName = classnames(classes.icon, iconClassNameProp);

    const ButtonComponentName: React.ElementType = icon ? IconButton : Button;
    const button = (
      <ButtonComponentName
        className={rootClassName}
        ref={ref}
        disabled={disabled || !allowed}
        variant={finalVariant}
        {...other}
      >
        {children}
        {icon && <UxdIcon className={iconClassName} name={icon} />}
      </ButtonComponentName>
    );

    if (tooltip) {
      return <Tooltip title={tooltip}>{button}</Tooltip>;
    } else {
      return button;
    }
  }
);
