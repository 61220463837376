import { makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { ScrollSpyElem } from "components/shared/scrollspy";
import { IBaseProps } from "components/_baseProps";
import { IMetadataCategory } from "models/metadata";

interface IProps extends IBaseProps {
  item: ScrollSpyElem<IMetadataCategory>;
  active: boolean;
  level: 1 | 2;
  capitalize?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.grayscales.gr700,
    margin: theme.spacing(0.5, 0),
    fontWeight: (props: IProps) => (props.level === 1 ? 500 : 400),
    fontSize: (props: IProps) =>
      props.level === 1
        ? theme.typography.pxToRem(14)
        : theme.typography.pxToRem(12)
  },
  active: {
    color: theme.palette.custom.ds.viking.viking500
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

export function MetadataNavItem(props: IProps) {
  const { item, active, capitalize = true } = props;

  const classes = useStyles(props);

  const rootClassName = classnames(classes.root, {
    [classes.active]: active,
    [classes.capitalize]: capitalize
  });

  return (
    <Typography component={"span"} className={rootClassName}>
      {item.text}
    </Typography>
  );
}
