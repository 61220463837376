import { IFrontendSitesDetail } from "models/domainStates";
import { createAction } from "redux-actions";
import {
  FAILED_ACTION_SUFFIX,
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX
} from "redux/actions/constants";

export const fetchFrontendSites = createAction(
  `FETCH_FRONTEND_SITES${REQUEST_ACTION_SUFFIX}`
);
export const fetchFrontendSitesSucceeded = createAction(
  `FETCH_FRONTEND_SITES${SUCCESS_ACTION_SUFFIX}`,
  (sites: IFrontendSitesDetail[]) => sites
);
export const fetchFrontendSitesFailed = createAction(
  `FETCH_FRONTEND_SITES${FAILED_ACTION_SUFFIX}`
);
