import { MenusApi } from "api/menusApi";
import { map, omit } from "lodash";
import { IMenuSearchResultEntry } from "models/menus";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

const transformMenuEntriesToOptions = (menuEntries: IMenuSearchResultEntry[]) =>
  map(menuEntries, (entry) => ({
    value: entry.path,
    label: entry.path
  }));

function useMenuEntries() {
  const [menuEntries, setMenuEntries] = useState<IMenuSearchResultEntry[]>([]);

  const fetchMenuEntries = useCallback(async () => {
    try {
      const menuSearchResultEntries =
        await MenusApi.getMenuSearchResultEntries();
      setMenuEntries(menuSearchResultEntries);
    } catch (err) {
      console.log(err);
    }
  }, [setMenuEntries]);

  useEffect(() => {
    fetchMenuEntries();
  }, [fetchMenuEntries]);

  return menuEntries;
}

// This is a HOC that is used to provide the options to the actual Autocomplete component
// It is done here in order to separate the concerns from the moduleProperty component to not be aware of its implementation specifically
export const WithMenuEntries: React.FC<PropsWithChildren<any>> = (props) => {
  const ChildComponent = props.children;

  const menuEntries = useMenuEntries();
  const menuEntryOptions = useMemo(
    () => transformMenuEntriesToOptions(menuEntries),
    [menuEntries]
  );

  const passedProps = omit(props, "children");

  return (
    <>
      {React.cloneElement(ChildComponent, {
        ...passedProps,
        options: menuEntryOptions
      })}
    </>
  );
};
