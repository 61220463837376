import { IDomainState } from "models/domainStates";

export const selectFrontendSites = (state: IDomainState) =>
  state.frontend.sites;

export const selectFrontendCultures = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.culture)));

export const selectFrontendPlatforms = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.platform)));

export const selectFrontendEnvironments = (state: IDomainState) =>
  Array.from(new Set(state.frontend.sites.map((x) => x.environment)));
