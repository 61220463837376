import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  allowedValuesValidationMessage: {
    color: theme.palette.custom.ds.error.error500,
    fontSize: theme.typography.pxToRem(13)
  },
  allowedValuesSectionContainer: {
    width: `calc(100% - ${theme.typography.pxToRem(76 * 2 + 14)})`,
    marginLeft: theme.typography.pxToRem(90)
  },
  allowedValuesControlContainer: {
    paddingTop: theme.typography.pxToRem(16)
  },
  allowedValuesHeader: {
    margin: 0,
    padding: 0,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.ds.grayscales.gr700,
    lineHeight: theme.typography.pxToRem(16)
  },
  infoIcon: {
    color: theme.palette.custom.ds.info.info500
  },
  errorIcon: {
    color: theme.palette.custom.ds.error.error500
  },
  checkIcon: {
    color: theme.palette.custom.secondaryAccent.green,
    fontSize: theme.typography.pxToRem(23)
  }
}));
