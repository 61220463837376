import { makeStyles, IconButton, Typography } from "@material-ui/core";
import classnames from "classnames";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  PageElementDefinition,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { SiteItemElementIcon } from "components/siteStructure/siteItemSidebar/siteItemElementIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 2.5),
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.custom.ds.fiord.fiord100
  },
  element: {
    marginLeft: theme.spacing(1),
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "start",
    width: theme.spacing(41.25)
  },
  type: {
    color: theme.palette.custom.icons.noAction,
    textTransform: "uppercase",
    width: theme.spacing(38)
  },
  label: {
    width: "100%",
    fontSize: "0.95rem"
  },
  collapsed: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: theme.spacing(7),
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.custom.ds.fiord.fiord100
  },
  icon: {
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  elementIcon: {
    backgroundColor: theme.palette.custom.ds.viking.viking500
  },
  properties: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    padding: theme.spacing(1, 5),
    width: theme.spacing(45) // 330px
  },
  empty: {
    marginTop: theme.spacing(5)
  }
}));

interface IPageDetailSidebarHeaderProps extends IBaseProps {
  element: SiteItemElement;
  definition: PageElementDefinition;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const SiteItemSidebarHeader = (props: IPageDetailSidebarHeaderProps) => {
  const { className, element, definition, open, onOpen, onClose } = props;
  const classes = useStyles();

  const rootClassName = classnames(classes.root, className);
  const type = translate(`sitestructure.${element.moduleType.toLowerCase()}`);

  if (!open) {
    return <CollapsedPageSidebarHeader onOpen={onOpen} />;
  }

  return (
    <div className={rootClassName}>
      <SiteItemElementIcon
        size="small"
        elementType={element.moduleType}
        pageElementDefinition={definition}
        className={classes.elementIcon}
      />

      <div className={classes.element}>
        <Typography className={classes.type} variant="caption">
          {type}
        </Typography>
        <Typography className={classes.label} variant="h6">
          {element.label || definition.label}
        </Typography>
      </div>

      <IconButton className={classes.icon} onClick={onClose}>
        <UxdIcon name="last_page" />
      </IconButton>
    </div>
  );
};

const CollapsedPageSidebarHeader = (props: { onOpen: () => void }) => {
  const { onOpen } = props;

  const classes = useStyles();

  return (
    <div className={classes.collapsed}>
      <IconButton
        className={classes.icon}
        onClick={onOpen}
        disableRipple={true}
      >
        <UxdIcon name="first_page" />
      </IconButton>
    </div>
  );
};

export default SiteItemSidebarHeader;
