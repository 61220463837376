import classnames from "classnames";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteItemsTableHeader } from "./siteItemsTableHeader";
import { SiteItemsTableRow } from "./siteItemsTableRow";
import { StagedSiteItem } from "models/directories";
import { noop } from "utils/typescriptUtils";
import { useMemo } from "react";

interface IProps extends IBaseProps {
  items: StagedSiteItem[];
  selected?: string[];
  disableRow?: (node: StagedSiteItem) => boolean;
  onSelect?: (node: StagedSiteItem) => void;
  onSelectAll?: (checked: boolean) => void;
  showCheckbox?: boolean | ((current: StagedSiteItem) => boolean);
  highlightRow?: boolean | ((current: StagedSiteItem) => boolean);
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(3)
  }
}));

export const SiteItemsTable = (props: IProps) => {
  const {
    className,
    items,
    showCheckbox,
    highlightRow,
    selected = [],
    onSelect = noop,
    onSelectAll = noop,
    disableRow = () => false
  } = props;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const firstItemPath = items[0].path;
  const rowOffset = useMemo(() => firstItemPath.split("/").length - 2, [
    firstItemPath
  ]);

  return (
    <TableContainer className={rootClassName}>
      <Table stickyHeader>
        <SiteItemsTableHeader
          onSelectAll={onSelectAll}
          checked={items.length === selected.length}
        />
        <TableBody>
          {items.map((item) => (
            <SiteItemsTableRow
              key={item.nodeId}
              item={item}
              disabled={disableRow(item)}
              selected={selected.includes(item.nodeId)}
              onSelect={onSelect}
              indentOffset={rowOffset}
              showCheckbox={showCheckbox}
              highlight={highlightRow}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
