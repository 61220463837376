import { Typography } from "@material-ui/core";
import { PageElement } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { translate } from "utils/i18n";

export const EmptyPageSidebarContent = (props: { element: PageElement }) => {
  const { element } = props;

  return (
    <Typography variant="h6" align="center">
      {translate("sitestructure.noproperties", {
        elementType: translate(element.moduleType.toLowerCase())
      })}
    </Typography>
  );
};
