import { call, put } from "redux-saga/effects";
import {
  fetchFrontendSitesSucceeded,
  fetchFrontendSitesFailed
} from "redux/actions/frontendActions";
import { FrontendApi } from "api/frontendApi";

export function* getFrontendSites() {
  try {
    const sites = yield call(FrontendApi.getSites);
    yield put(fetchFrontendSitesSucceeded(sites));
  } catch (error) {
    yield put(fetchFrontendSitesFailed(error));
  }
}
