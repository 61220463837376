import { ICommand } from "@d3-forge/forge-commands";
import { ApiClient } from "api/apiClient";
import {
  IAuthorizationGroupDetailUpdateRequest,
  IAuthorizationGroup
} from "models/authorizationGroup";
import {
  IAuthorizationGroupUser,
  IAuthorizationGroupUserGuishell,
  IAuthorizationGroupUsersUpdateRequest
} from "models/authorizationGroupUsers";
import { CommandResult } from "models/commandResult";
import { IConfiguration } from "models/configuration";
import { StagedSiteItem } from "models/directories";
import { IVariableContract, IVariableSuggestion } from "models/variables";
import { ForgeEntityDefinition } from "models/forgeEntities";
import { IMetadataAPIResponse } from "models/metadata";
import { ISiteItem, SiteItems } from "models/siteItem";
import { IVersion } from "models/version";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { displayNotification } from "redux/actions/notificationActions";
import {
  SiteAssetType,
  AssetReturnType
} from "models/siteAssets/siteAssetTypes";
import { ILinkRulesEntityList } from "models/linkRules";

export function useCommand(
  name: string,
  options?: {
    errorMessage?: string;
    successMessage?: string;
    onSuccess?: (data: CommandResult) => void;
    onError?: (error: Error) => void;
  }
) {
  const endpoint = "api/command";
  const dispatch = useDispatch();
  return useMutation<CommandResult, Error, ICommand, unknown>(
    name,
    (command: ICommand) => {
      return ApiClient.post(endpoint, command);
    },
    {
      onError(error) {
        dispatch(
          displayNotification({
            message: options?.errorMessage ?? "",
            severity: "error"
          })
        );
        if (options?.onError) {
          options.onError(error);
        }
      },
      onSuccess(data) {
        if (options?.successMessage) {
          dispatch(
            displayNotification({
              message: options?.successMessage,
              severity: "success"
            })
          );
        }

        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      }
    }
  );
}

const VARIABLES_BASE_URL = "api/variables";

export function useAssetCatalog(type: SiteAssetType) {
  const BASE_URL = "api/siteassets";
  const endpoint = `${BASE_URL}/${type}`;

  return useQuery<AssetReturnType<typeof type>, Error>(type, () => {
    return ApiClient.get(endpoint);
  });
}

export function useConfiguration() {
  const BASE_URL = "api/configuration";

  return useQuery<IConfiguration, Error>("configuration", () => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  });
}

export function useDirectoryPendingChange() {
  const BASE_URL = "api/directories";
  return useMutation<StagedSiteItem[], Error, string, unknown>(
    "directory_pendingchanges",
    (id: string) => {
      const endpoint = `${BASE_URL}/${id}/pendingchanges`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useDirectoryPublishedItems() {
  const BASE_URL = "api/directories";
  return useMutation<StagedSiteItem[], Error, string, unknown>(
    "directory_published",
    (id: string) => {
      const endpoint = `${BASE_URL}/${id}/published`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useVersion() {
  const BASE_URL = "api/version";
  return useQuery<IVersion, Error>("version", () => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  });
}

export function useForgeDefinition() {
  const BASE_URL = "api/entities";
  return useQuery<ForgeEntityDefinition[], Error>("forge_definition", () => {
    const endpoint = `${BASE_URL}/definitions`;
    return ApiClient.get(endpoint);
  });
}

export function usePagePreviewUrl() {
  const BASE_URL = "/api/pages";
  return useMutation<
    string,
    unknown,
    {
      path: string;
      culture: string;
      platform: string;
      environment: string;
      context: string;
    },
    unknown
  >(
    "get_page_preview_url",
    ({ path, culture, platform, environment, context }) => {
      const endpoint = `${BASE_URL}/preview/${culture}/${platform}/${environment}/${context}?path=${path}`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useSiteItemNodeAndChildren() {
  const BASE_URL = "api/siteitems";
  return useMutation<SiteItems, Error, string, unknown>(
    "site_item",
    (id: string) => {
      const endpoint = `${BASE_URL}?id=${id}`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useSiteItemSearch() {
  const BASE_URL = "api/siteitems";
  return useMutation<ISiteItem[], Error, string, unknown>(
    "site_item_search",
    (query: string) => {
      const endpoint = `${BASE_URL}/search?query=${query}`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useSiteItemMetadata() {
  const BASE_URL = "api/metadata";
  return useMutation<IMetadataAPIResponse, Error, string, unknown>(
    "site_item_metadata",
    (nodeId: string) => {
      const endpoint = `${BASE_URL}/${nodeId}`;
      return ApiClient.get(endpoint);
    }
  );
}

export const useSiteItemVariableSuggestions = () => {
  return useMutation<IVariableSuggestion[], Error, string, unknown>(
    "site_item_variable_keys",
    (nodeId: string) => {
      const endpoint = `${VARIABLES_BASE_URL}/${nodeId}/keys`;
      return ApiClient.get(endpoint);
    }
  );
};

export const useSiteItemVariables = () => {
  return useMutation<IVariableContract[], Error, string, unknown>(
    "site_item_variables",
    (nodeId: string) => {
      const endpoint = `${VARIABLES_BASE_URL}/${nodeId}`;
      return ApiClient.get(endpoint);
    }
  );
};

export const useSiteItemURIVariables = () => {
  return useMutation<IVariableContract[], Error, string, unknown>(
    "site_item_uri_variables",
    (nodeId: string) => {
      const URI_VARIABLES_URL = `${VARIABLES_BASE_URL}/uri`;
      const endpoint = `${URI_VARIABLES_URL}/${nodeId}`;
      return ApiClient.get(endpoint);
    }
  );
};

export function useAuthGroupList() {
  const BASE_URL = "api/authorization-groups";
  return useQuery<IAuthorizationGroup[], Error>("authgroup-list", () => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  });
}

export function useAddAuthGroup() {
  const BASE_URL = "api/authorization-groups";
  return useMutation<
    IAuthorizationGroup,
    Error,
    Omit<IAuthorizationGroup, "groupId">,
    unknown
  >("authgroup-list", (group: Omit<IAuthorizationGroup, "groupId">) => {
    const endpoint = BASE_URL;
    return ApiClient.post(endpoint, group);
  });
}

export function useUpdateAuthGroup() {
  const BASE_URL = "api/authorization-groups";
  return useMutation<
    IAuthorizationGroup,
    Error,
    Omit<IAuthorizationGroup, "name">,
    unknown
  >("authgroup-list", (group: Omit<IAuthorizationGroup, "name">) => {
    const endpoint = BASE_URL;
    return ApiClient.put(endpoint, group);
  });
}

export function useRemoveAuthGroup() {
  const BASE_URL = "api/authorization-groups";
  return useMutation<IAuthorizationGroup, Error, string, unknown>(
    "authgroup-list",
    (group: string) => {
      const endpoint = `${BASE_URL}/${group}`;
      return ApiClient.delete(endpoint);
    }
  );
}

export function useGetAuthGroupDetail() {
  const BASE_URL = "api/authorization-groups";
  return useMutation<IAuthorizationGroup, Error, string, unknown>(
    "authgroup-detail",
    (group: string) => {
      const endpoint = `${BASE_URL}/${group}`;
      return ApiClient.get(endpoint);
    }
  );
}

export function usePutAuthGroupDetail() {
  const BASE_URL = "api/authorization-groups/elements";
  return useMutation<
    IAuthorizationGroup,
    Error,
    IAuthorizationGroupDetailUpdateRequest,
    unknown
  >("authgroup-detail", (update: IAuthorizationGroupDetailUpdateRequest) => {
    const endpoint = BASE_URL;

    const body: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: update.groupsIds,
      actionType: update.actionType,
      elementKind: update.elementKind,
      values: update.values
    };

    return ApiClient.put(endpoint, body);
  });
}

export function useGetAuthGroupUsersGuishell() {
  const BASE_URL = "api/authorization-groups/users/guishell";
  return useQuery<IAuthorizationGroupUserGuishell[], Error>("users", () => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  });
}

export function useGetAuthGroupUsers() {
  const BASE_URL = "api/authorization-groups/users";
  return useMutation<IAuthorizationGroupUser[], Error, string, unknown>(
    "authgroup-users",
    (authGroupId) => {
      const endpoint = `${BASE_URL}/${authGroupId}`;
      return ApiClient.get(endpoint);
    }
  );
}

export function useUpdateAuthGroupUsers() {
  const BASE_URL = "api/authorization-groups/users";
  return useMutation<
    IAuthorizationGroupUser[],
    Error,
    IAuthorizationGroupUsersUpdateRequest,
    unknown
  >("authgroup-users", (update: IAuthorizationGroupUsersUpdateRequest) => {
    return ApiClient.put(BASE_URL, update);
  });
}

export function useGetLinkRulesList() {
  const BASE_URL = "api/linkrules";
  return useQuery<ILinkRulesEntityList, Error>(
    "link-rules-entities-list",
    () => {
      const endpoint = BASE_URL;

      return ApiClient.get(endpoint);
    }
  );
}
