import { ApiClient } from "api/apiClient";
import { IMenu, IMenuSearchResultEntry } from "models/menus";

const BASE_URL = "/api";

export class MenusApi {
  static readonly getMenu = async (id: string): Promise<IMenu> => {
    const endpoint = `${BASE_URL}/menus/${id}`;
    return ApiClient.get(endpoint);
  };

  static readonly getMenuSearchResultEntries = async (
    query?: string
  ): Promise<IMenuSearchResultEntry[]> => {
    const queryStringPart = query ? `/query=${query}` : "";
    const endpoint = `${BASE_URL}/siteItems/menu/search${queryStringPart}`;
    return ApiClient.get(endpoint);
  };
}
