import { MutableRefObject, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import {
  makeStyles,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useDebounce } from "components/hooks";
import { TextboxLabel } from "components/shared/textboxLabel";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  debounce?: boolean;
  debounceDelay?: number;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  value: string;
  label?: string;
  hint?: string;
  startIcon?: string;
  clearIcon?: boolean;
  placeholder?: string;
  disabled?: boolean;
  startIconClassName?: string;
  pattern?: string;
  invalidPatternMessage?: string;
  isInvalid?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      "& $clearButton$dirty": {
        visibility: "visible"
      }
    }
  },
  input: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& $clearButton$dirty": {
          visibility: "visible"
        }
      }
    }
  },
  clearButton: {
    visibility: "hidden",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  startIcon: {
    color: theme.palette.custom.greyscales.backgrounds.grey60
  },
  endIcon: {
    fontSize: theme.typography.pxToRem(20)
  },
  dirty: {}
}));

export const Textbox = (props: IProps) => {
  const {
    className,
    value,
    label = "",
    hint,
    placeholder = "",
    startIcon,
    clearIcon = true,
    debounce = false,
    debounceDelay = 2500,
    disabled = false,
    onChange = () => undefined,
    onBlur = () => undefined,
    startIconClassName = "",
    pattern = "",
    invalidPatternMessage,
    inputRef,
    isInvalid
  } = props;
  const classes = useStyles();

  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState("");
  const validateRegex = useMemo(() => new RegExp(pattern), [pattern]);

  const rootClassName = classnames(classes.root, className);
  const finalStartIconClassName = classnames(
    classes.startIcon,
    startIconClassName
  );

  const clearButtonClassName = classnames(classes.clearButton, {
    [classes.dirty]: Boolean(localValue)
  });

  const debouncedOnChange = useDebounce(onChange, debounceDelay);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newValue: string) => {
    if (localValue === newValue) {
      return;
    }

    setLocalValue(newValue);

    if (!validateRegex.test(newValue)) {
      debouncedOnChange.cancel();
      setError(invalidPatternMessage || translate("general.invalidinput"));
      return;
    } else if (error) {
      setError("");
    }

    if (debounce) {
      debouncedOnChange(newValue);
    } else {
      onChange(newValue);
    }
  };

  const clear = () => {
    if (localValue) {
      handleChange("");
    }
  };

  const handleBlur = () => {
    if (debounce) {
      debouncedOnChange.flush();
    }
    onBlur();
  };

  const inputProps: { [key: string]: any } = {};
  if (startIcon) {
    inputProps["startAdornment"] = (
      <InputAdornment position="start">
        <UxdIcon className={finalStartIconClassName} name={startIcon} />
      </InputAdornment>
    );
  }

  if (clearIcon) {
    inputProps["endAdornment"] = (
      <InputAdornment position="end">
        <IconButton
          className={clearButtonClassName}
          onClick={clear}
          size="small"
          disableRipple
        >
          <UxdIcon className={classes.endIcon} name="close" />
        </IconButton>
      </InputAdornment>
    );
  }

  if (pattern) {
    inputProps["pattern"] = pattern;
  }

  return (
    <div className={rootClassName}>
      {label && <TextboxLabel value={label} hint={hint} />}
      <TextField
        inputRef={inputRef}
        className={classes.input}
        fullWidth={true}
        value={localValue}
        variant="outlined"
        size="small"
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={inputProps}
        error={Boolean(error) || isInvalid}
        helperText={error}
      />
    </div>
  );
};
