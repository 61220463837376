import { makeStyles, Typography } from "@material-ui/core";
import MetadataPropertyItem from "./metadataPropertyItem";
import { IMetadataProperty, IMetadataPropertyItem } from "models/metadata";
import { translate } from "utils/i18n";
import { ISiteItem } from "models/siteItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectFrontendCultures,
  selectFrontendEnvironments
} from "redux/selectors/frontendSelectors";
import {
  generateMetadataForCommand,
  useMetadataCommand
} from "components/siteStructure/mainDetail/hooks";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { D3Button } from "components/shared/d3Components";
import classNames from "classnames";

interface IProps {
  category: string;
  property: IMetadataProperty;
  siteItem: ISiteItem;
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  label: {
    fontSize: "16px",
    lineHeight: "21px"
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

const isNewItem = (metadata: IMetadataPropertyItem, defaultCulture: string) =>
  metadata.culture === defaultCulture &&
  metadata.environment === "*" &&
  metadata.value === "";

const hasDefaultBeenUpdated = (metadata: IMetadataPropertyItem) =>
  isDefaultItem(metadata) && metadata.value !== "";

const isDuplicateItem = (
  metadata: IMetadataPropertyItem,
  items: IMetadataPropertyItem[]
) =>
  items.some(
    (item) =>
      item.culture.toLowerCase() === metadata.culture.toLowerCase() &&
      item.environment.toLowerCase() === metadata.environment.toLowerCase() &&
      item.id !== metadata.id
  );

// NOTE: string, numbers & bool values are acceptable
const metadataHasValue = (metadata: IMetadataPropertyItem) =>
  metadata.value || metadata.value === false;

const shouldItemUnset = (
  items: IMetadataPropertyItem[],
  modifiedItem: IMetadataPropertyItem,
  metadata: IMetadataPropertyItem
) =>
  items.length !== 0 &&
  metadataHasValue(modifiedItem) &&
  (metadata.value === 0 || metadata.value === "") &&
  modifiedItem &&
  isEqualItem(modifiedItem, metadata);

const isDefaultItem = (item: IMetadataPropertyItem): boolean =>
  item.culture === "*" && item.environment === "*";

const isEqualItem = (
  target: IMetadataPropertyItem,
  metadata: IMetadataPropertyItem
) =>
  target.value !== metadata.value ||
  target.environment !== metadata.environment ||
  target.culture !== metadata.culture;

const sortItems = (items: IMetadataPropertyItem[]) => {
  if (items.length > 1) {
    const defaultItem = items.find(isDefaultItem);
    const sortedItems = [...items];

    sortedItems.splice(sortedItems.indexOf(defaultItem!), 1);
    sortedItems.unshift(defaultItem!);
    return sortedItems;
  }
  return items;
};

export const MetadataProperty = (props: IProps) => {
  const { category, property, siteItem } = props;
  const classes = useStyles();
  const cultures = useSelector(selectFrontendCultures)
    .map((x) => x.toLowerCase())
    .concat(["*"]);

  const environments = useSelector(selectFrontendEnvironments)
    .map((x) => x.toLowerCase())
    .concat(["*"]);

  const { setMetadata, unsetMetadata } = useMetadataCommand(
    siteItem.nodeId,
    siteItem.nodeType
  );

  const [items, setItems] = useState<IMetadataPropertyItem[]>(
    sortItems(property.items)
  );
  const [hasRemovedDefault, setHasRemovedDefault] = useState<boolean>(false);

  useEffect(() => {
    setItems(sortItems(property.items));
  }, [property.items]);

  const getPropertyName = () => {
    if (property.displayName.trim().length > 0) return property.displayName;
    if (property.title.trim().length > 0) return property.title;

    return property.id;
  };

  const capitalizeTitle = () => {
    return Boolean(property.displayName.trim() || property.title.trim());
  };

  const _defaultItem: IMetadataPropertyItem = {
    id: "0",
    culture: "*",
    environment: "*",
    category: category,
    value: undefined,
    definedIn: null
  };

  const handleAddItem = () => {
    const newItem: IMetadataPropertyItem = {
      ..._defaultItem,
      culture: cultures[0],
      id: items.length.toString()
    };

    setItems((prev) => [...prev, newItem]);
  };

  const handleSaveItem = async (metadata: IMetadataPropertyItem) => {
    const modifiedItem = metadata.position
      ? items[metadata.position]
      : items[0];
    const isModified = modifiedItem && isEqualItem(modifiedItem, metadata);

    if (hasDefaultBeenUpdated(metadata)) {
      setHasRemovedDefault(false);
    }

    if (items.length === 0) {
      setItems([metadata]);

      const newMetadata = generateMetadataForCommand(
        metadata,
        property.id,
        category
      );

      await setMetadata(newMetadata);

      return;
    }

    // update state, needed to properly find duplicates
    if (isModified) {
      const currentItems = [...items];

      currentItems.splice(metadata.position!, 1, metadata);

      setItems(currentItems);
    }

    // unset the item when we edit it to an empty value
    if (shouldItemUnset(items, modifiedItem, metadata)) {
      if (isDefaultItem(metadata) && items.length > 1) {
        setHasRemovedDefault(true);
        return;
      }
      const oldMetadata = generateMetadataForCommand(
        modifiedItem,
        property.id,
        category
      );
      await unsetMetadata(oldMetadata);

      return;
    }

    const isDuplicate = isDuplicateItem(metadata, items);

    // do nothing is the item has not changed or there is an error
    if (!isModified || isDuplicate || !metadataHasValue(metadata)) return;

    const isNewlyAdded = isNewItem(modifiedItem, cultures[0]);

    // delete old metadata and create a new one in it's place since
    // forge does not allow to change culture or environment after the first save
    if (!isNewlyAdded && isModified) {
      const oldMetadata = generateMetadataForCommand(
        modifiedItem,
        property.id,
        category
      );
      await unsetMetadata(oldMetadata);
    }

    const newMetadata = generateMetadataForCommand(
      metadata,
      property.id,
      category
    );

    await setMetadata(newMetadata);
  };

  const handleDeleteItem = async (metadata: IMetadataPropertyItem) => {
    const isDuplicate = isDuplicateItem(metadata, items);

    if (metadataHasValue(metadata) && !isDuplicate) {
      const commandMetadata = generateMetadataForCommand(
        metadata,
        property.id,
        category
      );

      await unsetMetadata(commandMetadata);
    }

    setItems((prev) => prev.filter((prevItem) => prevItem.id !== metadata.id));
  };

  const firstItem = items.find(isDefaultItem) ?? _defaultItem;
  const other = items.filter((x) => !isDefaultItem(x));

  const canEdit =
    userService.hasPermissions(PermissionCodes.EditMetadata) ||
    userService.hasOnlySiteItemPermissions(siteItem.path);

  const labelClassName = classNames(classes.label, {
    [classes.capitalize]: capitalizeTitle()
  });

  return (
    <>
      <div className={classes.row}>
        <Typography className={labelClassName}>{getPropertyName()}</Typography>
        <D3Button
          disabled={items.length === 0 || !canEdit}
          icon="add_circle"
          iconPosition="right"
          variant="ghost"
          color="secondary"
          onClick={handleAddItem}
          children={translate("metadata.add_language")}
        />
      </div>

      {/* default metadata item or new item if items is empty */}
      <MetadataPropertyItem
        item={firstItem}
        cultures={cultures}
        environments={environments}
        type={property.type}
        format={property.format}
        enum={property.enum}
        onSave={handleSaveItem}
        onDelete={handleDeleteItem}
        disabled={!canEdit}
        error={
          hasRemovedDefault ? translate("metadata.error.default") : undefined
        }
      />

      {/* culture/environment specific metadata property items */}
      {other.map((item, index) => {
        const isDuplicate = isDuplicateItem(item, items);
        const error = isDuplicate
          ? translate("metadata.error.attribute")
          : undefined;

        return (
          <MetadataPropertyItem
            key={item.id}
            item={item}
            cultures={cultures}
            environments={environments}
            type={property.type}
            format={property.format}
            enum={property.enum}
            onSave={handleSaveItem}
            onDelete={handleDeleteItem}
            error={error}
            disabled={!canEdit}
            position={index + 1}
          />
        );
      })}
    </>
  );
};

export default MetadataProperty;
