import {
  filter,
  find,
  flatMap,
  includes,
  isEmpty,
  map,
  orderBy,
  some,
  uniq,
  uniqueId,
  xorBy
} from "lodash";
import { ID3DropdownOption } from "components/shared/d3Components/d3Dropdown";
import {
  ILinkRuleConditionOperatorType,
  ILinkRuleEntityEntry,
  ILinkRuleEntityMetadata,
  ILinkRuleEntry,
  ILinkRuleProperty,
  ILinkRulesEntityList
} from "models/linkRules";

import {
  getOkValidationResult,
  ICancelEditingPayload,
  ILinkRuleForm,
  ILinkRulePropertyFormEntry,
  IValidationResult
} from "components/linkRules/context/crudManagement";
import { ID3AutoCompleteInputOption } from "components/shared/d3Components/d3AutoCompleteInput";
import { IScrollPayload } from "components/linkRules/searchWidget";
import { LINK_RULE_ENTITY_SIDE_NAV_WIDTH } from "components/linkRules/linkRulesEntityNav/styles";
import { themeSizing } from "theme/deltatreTheme";
import { DASHBOARDS, PERMISSIONS, SITE_STRUCTURE_BASE } from "routes/paths";

export const getHasUnpublishedLinkRule = (entity: ILinkRuleEntityEntry) =>
  some(entity.linkRules, (entry) => !entry.isPublished && !entry.isAbsolute);

export const getLinkRuleEntityMetadataFromEntry = (
  name: string,
  entry: ILinkRuleEntityEntry
): ILinkRuleEntityMetadata => ({
  name,
  displayName: entry.displayName,
  navigationRulesCount: entry.linkRules.length,
  hasUnpublishedLinkRule: getHasUnpublishedLinkRule(entry)
});

export const NEW_NAVIGATION_RULE_ID = "empty";
export const URLS_BLOCKING_ON_LINK_RULE_UNSAVED_CHANGES = [
  DASHBOARDS,
  PERMISSIONS,
  SITE_STRUCTURE_BASE
];

export const OperatorValueMap: Record<ILinkRuleConditionOperatorType, string> =
  {
    EQUALS: "=",

    HAS_VALUE: "has a value",

    NOT_EQUALS: "≠"
  };

export const OperatorTextMap: Record<ILinkRuleConditionOperatorType, string> = {
  EQUALS: "is",
  NOT_EQUALS: "is not",
  HAS_VALUE: "has a value"
};

export const OperatorEncodingMap = new Map<
  number,
  ILinkRuleConditionOperatorType
>()
  .set(1, "EQUALS")
  .set(2, "NOT_EQUALS")
  .set(3, "HAS_VALUE");

export const SEARCH_RESULTS_MIN_LETTERS = 3;

export const getIsHasValueOperator = (operator: number) =>
  OperatorEncodingMap.get(operator) === "HAS_VALUE";

export const getOperatorTextByEncoding = (operator: number) => {
  const operatorValue = OperatorEncodingMap.get(operator);

  if (operatorValue === undefined) {
    return "";
  }

  return OperatorValueMap[operatorValue];
};

export const getOperatorDescriptiveTextByEncoding = (operator: number) => {
  const operatorValue = OperatorEncodingMap.get(operator);

  if (operatorValue === undefined) {
    return "";
  }

  return OperatorTextMap[operatorValue];
};

export const getShouldShowPropertyValue = (operator: number) =>
  OperatorEncodingMap.get(operator) !== "HAS_VALUE";

export const orderLinkRulesByAccuracy = (linkRules: ILinkRuleEntry[]) =>
  orderBy(linkRules, "properties.length", "desc");

export const getTransformedLinkRulesEntityListFromPayload = (
  payloadList: ILinkRulesEntityList
): ILinkRulesEntityList => {
  const keys = Object.keys(payloadList);
  const orderedKeys = orderBy(
    keys,
    (key) => (payloadList[key].displayName || key).toLowerCase(),
    ["asc"]
  );

  const transformedList: ILinkRulesEntityList = orderedKeys.reduce(
    (resultList, key) => {
      const entity = payloadList[key];
      const updatedEntity: ILinkRuleEntityEntry = {
        ...entity,
        displayName: entity.displayName || key,
        linkRules: orderLinkRulesByAccuracy(entity.linkRules)
      };

      return {
        ...resultList,
        [key]: updatedEntity
      };
    },
    {}
  );

  return transformedList;
};

export const getOperatorsDropdownList = () => {
  const operators: ID3DropdownOption[] = [];

  OperatorEncodingMap.forEach((v, k) => {
    operators.push({
      value: k.toString(),
      label: getOperatorDescriptiveTextByEncoding(k)
    });
  });

  return operators;
};

const getPropertyFormId = () => uniqueId("link_rule_property_id_");

export const getUpdatedPropertyValue = (
  property: ILinkRulePropertyFormEntry,
  shouldClearPropertyValueField: boolean
) => (shouldClearPropertyValueField ? undefined : property.value);

export const initLinkRulePropertyFormEntries = (
  properties: ILinkRuleProperty[]
): ILinkRulePropertyFormEntry[] =>
  map(properties, (property) => ({
    id: getPropertyFormId(),
    ...property
  }));

export const initLinkRuleForm = (
  entry: ILinkRuleEntry | undefined
): ILinkRuleForm => ({
  id: entry?.id ?? NEW_NAVIGATION_RULE_ID,
  entityType: entry?.entityType ?? "",
  isNew: entry === undefined,
  properties: initLinkRulePropertyFormEntries(entry?.properties ?? []),
  url: entry?.url ?? "",
  description: entry?.description,
  pageId: entry?.pageId,
  isAbsolute: entry?.isAbsolute === true
});

export const initEmptyPropertyForm = (): ILinkRulePropertyFormEntry => ({
  id: getPropertyFormId(),
  name: "",
  operator: 1,
  value: ""
});
export const getFilteredAttributeOptions = (
  options: ID3AutoCompleteInputOption[],
  { inputValue }
) => {
  return filter(options, (option) =>
    option.label.toLowerCase().includes((inputValue ?? "").toLowerCase())
  );
};

export const getSerializedLinkRuleProperty = (
  property: Partial<ILinkRulePropertyFormEntry>
) => {
  const name = property.name ?? "[null]";
  const operator = property.operator ?? "-1";
  const value = property.value ?? "[null]";

  return [name, operator, value].join();
};

export const getAreLinkRulePropertiesIdentical = (
  linkRuleEntry: ILinkRuleEntry | undefined,
  form: ILinkRuleForm
) => {
  const linkRuleProperties = linkRuleEntry?.properties ?? [];
  const { properties: formProperties } = form;

  if (linkRuleProperties.length !== formProperties.length) {
    return false;
  }

  const areRulesIdentical = isEmpty(
    xorBy(
      linkRuleProperties,
      formProperties as ILinkRuleProperty[],
      getSerializedLinkRuleProperty
    )
  );

  return areRulesIdentical;
};

export const getValidationIsThereDuplicateConditions = (
  form: ILinkRuleForm,
  entityType: string,
  linkRulesEntityList: ILinkRulesEntityList
): IValidationResult => {
  if (entityType === undefined) {
    return getOkValidationResult("DUPLICATE_CONDITIONS");
  }

  const entityEntry = linkRulesEntityList[entityType];
  const { linkRules } = entityEntry ?? { linkRules: [] };

  const otherLinkRules = filter(linkRules, (rule) => rule.id !== form.id);

  const linkRuleWithDuplicateProperties = find(
    otherLinkRules,
    (linkRuleEntry) => getAreLinkRulePropertiesIdentical(linkRuleEntry, form)
  );

  if (linkRuleWithDuplicateProperties === undefined) {
    return getOkValidationResult("DUPLICATE_CONDITIONS");
  }

  return {
    validationKey: "DUPLICATE_CONDITIONS",
    isValid: false,
    payload: {
      linkRuleEntry: linkRuleWithDuplicateProperties
    }
  };
};

export const areThereChangesInLinkRuleProperties = (
  form: ILinkRuleForm,
  linkRuleEntry: ILinkRuleEntry | undefined
) => !getAreLinkRulePropertiesIdentical(linkRuleEntry, form);

export const areFormFieldsChanged = (
  form: ILinkRuleForm,
  linkRuleEntry: ILinkRuleEntry | undefined,
  fields: (keyof ILinkRuleForm)[]
) =>
  fields.some((field) => {
    const formFieldValue = form[field] || undefined;
    const linkRuleEntryFieldValue =
      (linkRuleEntry as any)?.[field] || undefined;

    return formFieldValue !== linkRuleEntryFieldValue;
  });

export const areThereFormChanges = (
  form: ILinkRuleForm,
  linkRuleEntry: ILinkRuleEntry | undefined
) =>
  some([
    areThereChangesInLinkRuleProperties(form, linkRuleEntry),
    areFormFieldsChanged(form, linkRuleEntry, [
      "url",
      "entityType",
      "description",
      "pageId"
    ])
  ]);

export const getAllAttributesPerEntityType = (
  linkRuleEntityList: ILinkRulesEntityList,
  entityType: string | undefined
) => {
  if (entityType === undefined) {
    return [];
  }

  const entityEntry = linkRuleEntityList[entityType];

  const { linkRules } = entityEntry ?? { linkRules: [] };

  return uniq(
    flatMap(linkRules, (linkRuleEntry) => map(linkRuleEntry.properties, "name"))
  );
};

export const getIsThereAnEmptyCondition = (form: ILinkRuleForm) => {
  const { properties } = form;

  const EMPTY_TOKENS = [null, undefined, ""];

  const isThereEmptyCondition = some(
    properties,
    (property) =>
      !property.name ||
      property.operator === undefined ||
      (includes(EMPTY_TOKENS, property.value) &&
        !getIsHasValueOperator(property.operator))
  );

  return isThereEmptyCondition;
};
export const getHighlightedHtmlFromSearchMatch = (
  elementText: string,
  searchValue: string
) => {
  if (searchValue.length < SEARCH_RESULTS_MIN_LETTERS) {
    return elementText;
  }

  const backgroundColor = "#fcba2640";
  const cssStyle = `background-color: ${backgroundColor};  border-radius: 2px; padding: 1px ;`;

  const matchGroupedRegex = new RegExp(`(${searchValue})`, "gi");

  return elementText.replace(
    matchGroupedRegex,
    `<span style="${cssStyle}">$1</span>`
  );
};

export const getHasLinkRuleSearchMatchings = (
  linkRule: ILinkRuleEntry,
  searchValue: string
) => {
  const hasUrlMatch = linkRule.url
    .toLowerCase()
    .includes(searchValue.toLowerCase());

  const hasPropertyMatch = linkRule.properties.some((property) =>
    property.value?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return hasUrlMatch || hasPropertyMatch;
};

export const getFilteredLinkRulesEntityList = (
  linkRulesEntityList: ILinkRulesEntityList,
  searchValue: string
): ILinkRulesEntityList => {
  const filteredLinkRulesEntityList = Object.fromEntries(
    Object.entries(linkRulesEntityList)
      .map(([key, entity]) => {
        const { linkRules } = entity;

        const filteredLinkRules = linkRules.filter((rule) =>
          getHasLinkRuleSearchMatchings(rule, searchValue)
        );

        return [key, { ...entity, linkRules: filteredLinkRules }];
      })
      .filter(([_, entity]) => (entity as any).linkRules.length > 0)
  );

  return filteredLinkRulesEntityList;
};

export const getLinkRulesEntitiesMetadata = (
  linkRulesEntityList: ILinkRulesEntityList
): ILinkRuleEntityMetadata[] => {
  return Object.keys(linkRulesEntityList).map((entityName) =>
    getLinkRuleEntityMetadataFromEntry(
      entityName,
      linkRulesEntityList[entityName]
    )
  );
};

export const getResizableTrackerWrapper = (scrollContainer: Element) => {
  return scrollContainer.children[0]!;
};

export const calculateScrollerOffsetPlaceholderHeight = (
  scrollContainerParent: HTMLElement | null
) => {
  if (scrollContainerParent === null) {
    return 0;
  }

  const resizableTrackerWrapper = getResizableTrackerWrapper(
    scrollContainerParent
  );

  const parentHeight = scrollContainerParent.clientHeight;
  const lastTrackerChildElement = resizableTrackerWrapper.lastChild as Element;
  const lastTrackerChildElementHeight =
    lastTrackerChildElement?.clientHeight ?? 0;

  return Math.max(0, parentHeight - lastTrackerChildElementHeight + 10);
};

export const getScrollPayloadEntries = (
  resizableTrackerWrapper: Element
): IScrollPayload[] => {
  const childrenScrollPayload = Array.from(
    resizableTrackerWrapper.children ?? []
  ).map((element) => {
    const boundingClient = element.getBoundingClientRect();

    return {
      initialPositionY: boundingClient.y,
      key: element.id
    };
  });

  return childrenScrollPayload;
};

export const getOrderedScrollPayloadEntries = (
  scrollPayload: IScrollPayload[],
  offset: number
) => {
  const scrollPositionOffset = scrollPayload[0]?.initialPositionY ?? 0;

  return orderBy(
    map(scrollPayload, (entry) => ({
      key: entry.key,
      initialPositionY: entry.initialPositionY - scrollPositionOffset - offset
    })),
    "initialPositionY",
    "desc"
  );
};

export const getLinkRulesUrlOccurrencesCountMap = (
  linkRulesEntityList: ILinkRulesEntityList
): Record<string, number> => {
  const linkRulesPerEntityType = map(
    Object.values(linkRulesEntityList),
    "linkRules"
  );

  const urlCountPerEntity = flatMap(
    linkRulesPerEntityType,
    (linkRuleEntries) => {
      const idCountMap = map(linkRuleEntries, (entry) => ({
        id: entry.id,
        count: linkRuleEntries.filter((it) => it.url === entry.url).length
      }));

      return idCountMap;
    }
  );

  const result = urlCountPerEntity.reduce(
    (acc, { id, count }) => ({
      ...acc,
      [id]: count
    }),
    {} as Record<string, number>
  );
  return result;
};

export const getIsThereAnEmptyMandatoryField = (form: ILinkRuleForm) => {
  const { entityType, url } = form;

  if (entityType.trim().length === 0 || url.trim().length === 0) {
    return true;
  }

  return false;
};

export const getLinkRuleEntityTypeSuggestionsOptions = (
  entries: ILinkRuleEntityMetadata[]
) => {
  return map(entries, (entry) => ({
    value: entry.name,
    label: entry.displayName
  }));
};

export const extractEntityKeyFromPrefixedEntityString = (
  prefixedEnityKey: string
) => {
  return prefixedEnityKey.split("_").at(-1) ?? "";
};

export const getPrefixedEntityString = (entityKey: string) => {
  return `entity_${entityKey}`;
};

export const getEntityTypeByLinkRuleId = (
  linkRuleEntityList: ILinkRulesEntityList,
  linkRuleId: string
) => {
  const entities = Object.entries(linkRuleEntityList);

  const entityContainingLinkRule = find(entities, ([_, entity]) =>
    some(entity.linkRules, { id: linkRuleId })
  );

  return entityContainingLinkRule?.[0] ?? "";
};

export const navigateToNewlyAddedLinkRuleItem = (
  scrollingElement: HTMLDivElement,
  itemElement: HTMLDivElement
) => {
  scrollingElement.scrollTo({
    top: itemElement.offsetTop - scrollingElement.clientHeight / 3
  });
};

export const getLinkRulesMainDetailCalculatedWidth = (sidebarWidth: number) =>
  `calc(100vw - ${LINK_RULE_ENTITY_SIDE_NAV_WIDTH}px - ${themeSizing.drawer}px - ${sidebarWidth}px)`;

export const onHandleBlockHistoryNavigation = (
  location: Location,
  setCancelEditingPayload: (cancelEditingPayload: ICancelEditingPayload) => void
) => {
  const shouldBlockUrl = includes(
    URLS_BLOCKING_ON_LINK_RULE_UNSAVED_CHANGES,
    location.pathname
  );

  if (shouldBlockUrl) {
    setCancelEditingPayload({
      isActive: true,
      mode: "EXIT_SCREEN",
      pendingUrlToRedirectTo: location.pathname
    });
  }

  return !shouldBlockUrl;
};
