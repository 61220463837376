import React, { useCallback } from "react";
import classnames from "classnames";
import {
  Button,
  makeStyles,
  Snackbar,
  SnackbarContent,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useEventListener } from "components/hooks";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  show: boolean;
  icon: string;
  message: string;
  header?: string;
  iconProps?: any;
  onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: "flex"
  },
  header: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(0, 1)
  },
  messageBody: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14)
  },
  message: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  snackbarContent: {
    flexWrap: "nowrap"
  }
}));

export const ActionSnackbar = (props: IProps) => {
  const {
    className,
    show,
    icon,
    message,
    header,
    iconProps = {},
    onCancel
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  // All the following shit is needed to allow quitting the moving module
  // by pressing the ESC button
  const cancelOnEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        onCancel();
      }
    },
    [onCancel]
  );

  useEventListener("keyup", cancelOnEsc, show);

  const action = (
    <Button color="secondary" size="small" onClick={onCancel}>
      {translate("general.cancel")}
    </Button>
  );

  const finalMessage = (
    <>
      <UxdIcon className={classes.icon} name={icon} {...iconProps} />
      {header === undefined ? (
        message
      ) : (
        <div className={classes.container}>
          <Typography className={classes.header}>{header}</Typography>
          <Typography className={classes.messageBody}>{message}</Typography>
        </div>
      )}
    </>
  );

  return (
    <Snackbar className={rootClassName} open={show}>
      <SnackbarContent
        classes={{
          root: classes.snackbarContent,
          message: classes.message
        }}
        message={finalMessage}
        action={action}
      />
    </Snackbar>
  );
};
