import { Chip, Link, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";

export interface ILinkRuleUrlProps extends IBaseProps {
  url: string;
  highlighted: string;
  onClick: (_: any) => void;
  rulesUsedCount: number;
}

export function LinkRuleUrl(props: Readonly<ILinkRuleUrlProps>) {
  const classes = useStyles();
  const { url, highlighted, onClick, className, rulesUsedCount } = props;
  return (
    <>
      <Typography component="span">
        <Tooltip title={url}>
          <Link component="button" className={className} onClick={onClick}>
            <span dangerouslySetInnerHTML={{ __html: highlighted }} />
          </Link>
        </Tooltip>
      </Typography>
      {rulesUsedCount > 1 && (
        <Chip label={`+${rulesUsedCount}`} className={classes.rulesCount} />
      )}
    </>
  );
}

export default LinkRuleUrl;
