import classnames from "classnames";
import { duration, makeStyles, Modal, Paper, Slide } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { forwardRef, PropsWithChildren, useEffect, useRef } from "react";

interface IProps extends IBaseProps, PropsWithChildren<{}> {
  open: boolean;
  onClose: () => void;
  anchor: "right" | "left";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    zIndex: theme.zIndex.appBar - 1,
    WebkitOverflowScrolling: "touch",
    position: "fixed",
    outline: 0,
    bottom: 0,

    // taken from theme.mixins.toolbar
    top: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      top: 48
    },
    [theme.breakpoints.up("sm")]: {
      top: 64
    }
  },
  left: {
    left: 0,
    right: "auto"
  },
  right: {
    right: 0,
    left: "auto"
  }
}));

const OPPOSITE = {
  right: "left",
  left: "right"
} as const;

const DEFAULT_DURATION = {
  enter: duration.enteringScreen,
  exit: duration.leavingScreen
} as const;

export const HistoryPaper = forwardRef((props: IProps, ref) => {
  const { className, open, onClose, children, anchor = "right" } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    classes.paper,
    classes[anchor],
    className
  );

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
  }, []);

  return (
    <Modal
      ref={ref}
      open={open}
      onClose={onClose}
      BackdropProps={{
        invisible: true
      }}
    >
      <Slide
        in={open}
        direction={OPPOSITE[anchor]}
        timeout={DEFAULT_DURATION}
        appear={mounted.current}
      >
        <Paper className={rootClassName} square elevation={16}>
          {children}
        </Paper>
      </Slide>
    </Modal>
  );
});
