import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  IComponentProperty,
  PickListPropertyInfo
} from "models/componentProperty";
import { FreeTextPickListProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/freeTextPickListProperty";
import { StrictPickListProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/strictPickListProperty";
import { FreeTextPickListDataPathProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/freeTextPickListDataPathProperty";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { StrictPickListDataPathProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/strictPickListDataPathProperty";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  textFieldArea: {
    display: "flex",
    flexDirection: "row",
    "& .MuiAutocomplete-root:has(+.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    }
  }
}));

export const PickListProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    mandatory,
    onChange
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  if (!propertyDefinition.pickList) {
    return <></>;
  }
  const Component = getPickListComponent(propertyDefinition.pickList);

  return (
    <div className={rootClassName}>
      <TextFieldLabel
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        mandatory={propertyDefinition.mandatory}
        disabled={disabled}
      />
      <div className={classes.textFieldArea}>
        <Component
          pickList={propertyDefinition.pickList}
          name={propertyDefinition.name}
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          mandatory={mandatory}
        />
      </div>
    </div>
  );
};

function getPickListComponent(
  pickListDefinition: PickListPropertyInfo
): React.ElementType {
  if (pickListDefinition.dataPath) {
    if (pickListDefinition.strict) {
      return StrictPickListDataPathProperty;
    }
    return FreeTextPickListDataPathProperty;
  }
  if (pickListDefinition.strict) {
    return StrictPickListProperty;
  }
  return FreeTextPickListProperty;
}
