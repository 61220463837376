import { IMenuItem } from "models/menus";

export const MAX_MENU_DEPTH = 4;

export type IMenuItemPositionMetadata = {
  id: string;
  position: number;
  parentId: string | null;
  childrenCount: number;
  level: number;
  depth: number; // starts from 1
};

export type PlacementModePurpose = "CREATE_MENU_ITEM" | "MOVE_MENU_ITEM";
export type PlacementModeSegment = "SIBLING_BEFORE" | "SIBLING_AFTER" | "CHILD";

export type IMenuItemPlacementModeMetadata = {
  purpose: PlacementModePurpose;
  pickedItem: IMenuItem | undefined;
  pickedItemDescendants: string[];
};

export interface MenuBuilderLoaders {
  isDeletingMenuItem: boolean;
  isCreatingMenuItem: boolean;
  isMovingMenuItem: boolean;
}

type PlacementModeSegmentHoverHandle<T extends PlacementModeSegment> = Record<
  T,
  { isHovered: boolean; set: (isHovered: boolean) => void }
>;

export type PlacementModeSegmentHoverHandles =
  PlacementModeSegmentHoverHandle<"CHILD"> &
    PlacementModeSegmentHoverHandle<"SIBLING_BEFORE"> &
    PlacementModeSegmentHoverHandle<"SIBLING_AFTER">;
