import { Typography } from "@material-ui/core";
import { useStyles } from "components/linkRules/linkRulesSidebar/styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

export interface IDuplicateLinkRuleWarningProps {
  showWarning: boolean;
}

export const DuplicateLinkRuleWarning = (
  props: IDuplicateLinkRuleWarningProps
) => {
  const classes = useStyles();
  const { showWarning } = props;

  if (!showWarning) return <></>;

  const title = translate("sitestructure.navigationrules.warning");
  const message = translate(
    "sitestructure.navigationrules.duplicate_navigation_rule_conditions"
  );
  return (
    <Typography className={classes.duplicateRuleWarning} component="div">
      <div className={classes.warningSection}>
        <UxdIcon className={classes.warningIcon} name="warning" />
      </div>
      <div>
        <label className="warning-title">{title}</label>
        <div className="warning-message">{message}</div>
      </div>
    </Typography>
  );
};

export default DuplicateLinkRuleWarning;
