import classnames from "classnames";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  open: boolean;
  revision: number;
  onConfirm?: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const HistoryConfirmRestorePopup = (props: IProps) => {
  const {
    className,
    open,
    revision,
    onConfirm = () => {},
    onClose = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Dialog className={rootClassName} open={open} onClose={onClose}>
      <DialogTitle>
        {translate("sitestructure.confirmrestorepopup.title", { revision })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate("sitestructure.confirmrestorepopup.body")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {translate("sitestructure.confirmrestorepopup.cancel")}
        </Button>
        <Button
          onClick={onConfirm}
          color="secondary"
          variant="contained"
          autoFocus
        >
          {translate("sitestructure.confirmrestorepopup.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
