import {
  TableContainer,
  Table,
  TableBody,
  makeStyles
} from "@material-ui/core";
import { ChildrenListHeader } from "components/siteStructure/directoryView/childrenList/childrenListHeader";
import { SkeletonListRow } from "components/siteStructure/directoryView/childrenList/skeletonListRow";
import { DirectoryDetailHeader } from "components/siteStructure/directoryView/directoryDetailHeader";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  }
}));

export function SkeletonDirectoryDetail({ node }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DirectoryDetailHeader node={node} hasIndex />
      <TableContainer className={classes.root}>
        <Table>
          <ChildrenListHeader />
          <TableBody>
            {_.range(9).map((v) => (
              <SkeletonListRow key={v} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
