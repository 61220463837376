import { call, put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  fetchSiteItemsFromRootSucceded,
  fetchSiteItemsFromRootFailed,
  fetchSiteItemsFromRoot
} from "redux/actions/siteStructureActions";
import { SiteItemsApi } from "api/siteItems";
import { FetchSiteItemsFromRootPayload } from "redux/actions/typings/siteStructureActions";

export function* getSiteItemsFromRoot(
  action: Action<FetchSiteItemsFromRootPayload>
) {
  try {
    const { path, onSuccess } = action.payload;

    const siteItems = yield call(SiteItemsApi.getNodeAndChildrenFromRoot, path);

    yield put(fetchSiteItemsFromRootSucceded(siteItems));

    onSuccess && onSuccess();
  } catch (error) {
    yield put(fetchSiteItemsFromRootFailed(error));
    if ((error as Error)?.message === "Not Found") {
      yield put(fetchSiteItemsFromRoot({ path: "~/" }));
    }
  }
}
