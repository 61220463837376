import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import classnames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: "14px",
    "& label": {
      backgroundColor: theme.palette.common.white
    },
    "& .MuiSelect-root": {
      paddingRight: "24px"
    },
    "& .MuiSelect-root:focus": {
      backgroundColor: "inherit"
    }
  },
  label: {
    margin: theme.spacing(0, 0, 0.5, 0),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  select: {
    height: "100%",
    width: "100%"
  },
  selectIcon: {
    fontSize: "12px",
    color: theme.palette.custom.ds.grayscales.gr500,
    margin: theme.spacing(0, 1)
  }
}));

/**
 * Key-Value pair representing a selectable option
 */
export interface ID3DropdownOption {
  /**
   * Underlying value of the option
   */
  value: string;

  /**
   * Displayed value of the option
   */
  label: string;

  /**
   * Should option be disabled
   */
  disabled?: boolean;
}

/**
 * Props of `D3Dropdown`
 */
export interface ID3DropdownProps extends IBaseProps {
  /**
   * Initial value of the `D3Dropdown`
   */
  value: string;

  /**
   * Label of the dropdown
   */
  label?: string;

  /**
   * List of options of the `D3Dropdown`
   */
  options: ID3DropdownOption[];

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;

  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * Determine if the input is disabled or not
   */
  disabled?: boolean;

  /**
   * The function being executed on option selection
   */
  onSelect?: (selected: ID3DropdownOption) => void;

  /**
   * the CSS class to apply to each option
   */
  optionClassName?: string;

  /**
   * If `true` the arrow icon will be hidden
   */
  hideDropdownIcon?: boolean;

  /**
   * Determines the size of the control
   */
  size?: "medium" | "small";
}

/**
 * Deltatre Dropdown following the SXP Product Design System.
 *
 * @see [Design System](https://www.figma.com/file/mTyXrouZ9B4cHebcPOltDJ/Product-Design-System?node-id=1383%3A12777)
 * @component
 * @param ID3DropdownProps props
 */
export const D3Dropdown = (props: ID3DropdownProps) => {
  const {
    value,
    label,
    options,
    className,
    onSelect,
    placeholder,
    disabled,
    fullWidth = false,
    optionClassName,
    hideDropdownIcon = false,
    size = "small"
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [current, setCurrent] = useState<ID3DropdownOption>(
    toDropdownOption(value)
  );

  const onChange = (value: string) => {
    const selected = options.find((opt) => opt.value === value)!;
    setCurrent(selected);

    if (onSelect) {
      onSelect(selected);
    }
  };

  useEffect(() => {
    setCurrent(toDropdownOption(value));
  }, [value]);

  const isSelected = (option: ID3DropdownOption) =>
    option.value.toLowerCase().trim() === current.value.toLowerCase().trim();

  const icon = (props) =>
    hideDropdownIcon ? (
      <></>
    ) : (
      <UxdIcon className={classes.selectIcon} name="expand_more" {...props} />
    );

  return (
    <FormControl
      variant="outlined"
      className={rootClassName}
      disabled={disabled}
      size={size}
    >
      {label && <Typography className={classes.label}>{label}</Typography>}
      <Select
        className={classes.select}
        fullWidth={fullWidth}
        variant="outlined"
        value={current.value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value as string)}
        IconComponent={(props) => icon(props)}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            className={optionClassName}
            selected={isSelected(option)}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const toDropdownOption = (
  value: string,
  disabled?: boolean
): ID3DropdownOption => ({
  value,
  label: value,
  disabled
});

export default D3Dropdown;
