import { CircularProgress, InputBase, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { useState } from "react";
import { authorizationHeader } from "theme/commonClasses";
import { translate } from "utils/i18n";

const useStyles = makeStyles(() => authorizationHeader);

interface IProps extends IBaseProps {
  onSubmit: (form: { name: string; description: string }) => void;
  loading: boolean;
}

export function CreateHeader(props: IProps) {
  const { onSubmit, loading = false } = props;
  const classes = useStyles();
  const [nameMandatory, setNameMandatory] = useState(false);
  const [descMandatory, setDescMandatory] = useState(false);

  const enterKey = "Enter";

  const [form, setForm] = useState<{ name: string; description: string }>({
    name: "",
    description: ""
  });

  const handleChanges = () => {
    setDescMandatory(form.description === "");
    setNameMandatory(form.name === "");
    if (form.name && form.description) {
      onSubmit(form);
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === enterKey) {
      handleChanges();
    }
  };

  const handleNameChange = (nameValue: string, checkSend: boolean) => {
    const isNameEmpty = nameValue === "";
    setForm((prev) => ({ ...prev, name: nameValue }));
    setNameMandatory(isNameEmpty);

    if (checkSend) {
      handleChanges();
    }
  };

  const handleDescriptionChange = (descValue: string, checkSend: boolean) => {
    const isDescriptionEmpty = descValue === "";
    setForm((prev) => ({ ...prev, description: descValue }));
    setDescMandatory(isDescriptionEmpty);

    if (checkSend) {
      handleChanges();
    }
  };

  const nameClasses = classnames(classes.name, {
    [classes.mandatory]: nameMandatory
  });

  const descClasses = classnames(classes.desc, {
    [classes.mandatory]: descMandatory
  });

  return (
    <header className={classes.root}>
      <div className={classes.formWrapper}>
        <InputBase
          className={nameClasses}
          autoFocus
          onChange={(event) => handleNameChange(event.target.value, false)}
          onBlur={(event) => handleNameChange(event.target.value, true)}
          onKeyDown={handleKeyDown}
          placeholder={translate("permissions.content.create.nameplaceholder")}
        />
        <InputBase
          className={descClasses}
          onChange={(event) =>
            handleDescriptionChange(event.target.value, false)
          }
          onBlur={(event) => handleDescriptionChange(event.target.value, true)}
          onKeyDown={handleKeyDown}
          placeholder={translate(
            "permissions.content.create.descriptionplaceholder"
          )}
        />
      </div>
      {loading && <CircularProgress className={classes.loading} size={24} />}
    </header>
  );
}
