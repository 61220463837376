import CreateView from "components/permissions/create-view";
import { EmptyAuthorizationGroup } from "components/permissions/emptyAuthorizationGroup";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AuthorizationDetail from "components/permissions/detailView";

export function PermissionMainContentRoutes() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <EmptyAuthorizationGroup />
      </Route>
      <Route path={`${path}/create`}>
        <CreateView />
      </Route>
      <Route path={`${path}/:authGroupId`}>
        <AuthorizationDetail />
      </Route>
    </Switch>
  );
}
