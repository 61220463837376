import { call, put } from "redux-saga/effects";
import { EchoApi } from "api/echoApi";
import {
  fetchVersionSucceded,
  fetchVersionFailed
} from "redux/actions/appActions";

export function* getVersion() {
  try {
    const versionResponseContract = yield call(EchoApi.getVersion);
    yield put(fetchVersionSucceded(versionResponseContract.version));
  } catch (error) {
    yield put(fetchVersionFailed(error));
  }
}
