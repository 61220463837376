import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  textFieldArea: {
    display: "flex",
    flexDirection: "row",
    "& .MuiTextField-root:has(+.MuiAutocomplete-root:not([hidden])), & .MuiAutocomplete-root:has(+.MuiAutocomplete-root:not([hidden]))":
      {
        display: "none"
      }
  },
  noVariableSwitch: {
    paddingRight: theme.spacing(4)
  },
  input: {
    "& .MuiOutlinedInput-root": {
      // border: "1px solid black",
      height: theme.spacing(4.8)
    }
  },
  autocompleteInput: {
    "& input": {
      position: "relative",
      top: "-8px",
      left: "-2px"
    }
  },

  autocompletePopperClassname: {
    fontSize: theme.typography.pxToRem(13)
  }
}));
