import { makeStyles } from "@material-ui/core";
import { VariableType } from "utils/variableUtils";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    display: "flex",
    flexDirection: "column"
  },
  lineItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "2px",
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2),
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      "& $hoverableActionButton": {
        display: "flex"
      }
    }
  },
  overrideContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  overrideChip: {
    margin: theme.spacing(0, 0.5, 0, 0),
    padding: theme.spacing(0.25, 0.5),
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.ds.grayscales.gr600,
    "& .MuiChip-label": {
      padding: theme.spacing(0.125, 0.5),
      color: theme.palette.custom.ds.grayscales.gr200
    }
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.typography.pxToRem(13),
    margin: "auto 0"
  },
  nameContainer: {
    gap: theme.typography.pxToRem(4),
    display: "inherit",
    minWidth: "15vw",
    marginRight: theme.spacing(1)
  },
  name: {
    backgroundColor: (props: any) => {
      switch (props.variable.type) {
        case VariableType.URI_VARIABLE.type:
          return theme.palette.custom.ds.variables.urivariable100;
        case VariableType.KEY_VALUE.type:
          return theme.palette.custom.ds.variables.keyvalue100;
        case VariableType.DATA_LIST.type:
          return theme.palette.custom.ds.variables.datalist100;
        case VariableType.DATA_ITEM.type:
          return theme.palette.custom.ds.variables.dataitem100;
      }
    },
    color: (props: any) => {
      switch (props.variable.type) {
        case VariableType.URI_VARIABLE.type:
          return theme.palette.custom.ds.variables.urivariable500;
        case VariableType.KEY_VALUE.type:
          return theme.palette.custom.ds.variables.keyvalue500;
        case VariableType.DATA_LIST.type:
          return theme.palette.custom.ds.variables.datalist500;
        case VariableType.DATA_ITEM.type:
          return theme.palette.custom.ds.variables.dataitem500;
      }
    },
    borderRadius: "4px",
    height: theme.spacing(3),
    display: "grid",
    "& .MuiChip-label": {
      padding: theme.spacing(0, 0.75),
      fontFamily: "'Source Code Pro', monospace"
    }
  },
  infoContainer: {
    flexGrow: 1
  },
  value: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.ds.grayscales.gr800,
    overflowWrap: "anywhere"
  },
  inherited: {
    color: theme.palette.custom.ds.grayscales.gr700,
    overflowWrap: "anywhere"
  },

  primaryLabel: {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: theme.typography.pxToRem(13)
  },
  secondaryLabel: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(12)
  },
  tertiaryLabel: {
    color: theme.palette.custom.ds.grayscales.gr600
  },
  italic: {
    fontStyle: "italic"
  },
  spacer: {
    display: "inline-block",
    width: "5px"
  },
  inheritedPath: {
    color: theme.palette.custom.ds.viking.viking500,
    fontFamily: "inherit",
    fontSize: "inherit"
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: theme.spacing(13)
  },
  hoverableActionButton: {
    display: "none"
  },
  actionbutton: {
    borderRadius: `${theme.spacing(0.25)}px`,
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  actionIcon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.typography.pxToRem(16)
  }
}));
