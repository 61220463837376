import {
  every,
  filter,
  find,
  flatMap,
  includes,
  isEmpty,
  last,
  map,
  omit,
  some,
  xor
} from "lodash";
import { PathTranslationSegment } from "models/urlTranslation";
import { userService } from "services/userService";
import {
  isDynamicSlug,
  splitPathSegments,
  stripParameterDelimitersFromPathSegment,
  isPathSegmentValid,
  PATH_SEPARATOR,
  isDynamicSlugValueValid,
  hasWhitespaceCharacter
} from "utils/pathUtils";
import {
  AliasValidationMap,
  IAliasMetadataForm,
  IAliasMetadataFormUpdate,
  IPathSegment,
  IUrlMetadata,
  IURLMetadataForm,
  IUrlMetadataFormUpdate,
  IAliasValidationEntry,
  AliasValidationResult
} from "components/siteStructure/aliases/types";
import { PageUrlDetail } from "models/pages";
import { IAliasEntity } from "models/aliases";
import { PageAliasSetNotification } from "@d3-forge/forge-notifications";

const DYNAMIC_PARAMETER_VALUES_DELIMITER = ", ";
const DYNAMIC_PARAMETER_VALUES_SEPARATOR_REGEX = /\s*,\s*/g;
const ROOT_PATH = `~`;

const getDefaultAliasMetadataForm = (
  culture: string,
  urlMetadata: IUrlMetadata
): IAliasMetadataForm => ({
  culture,
  value: "",
  rawParams: map(urlMetadata.paramNames, (paramName) => ({
    name: paramName,
    allowedValues: ""
  }))
});

export const createAliasMetadataFormByCulture = (
  culture: string,
  pageUrlDetail: PageUrlDetail | undefined,
  urlMetadata: IUrlMetadata
): IAliasMetadataForm => {
  if (pageUrlDetail === undefined) {
    return getDefaultAliasMetadataForm(culture, urlMetadata);
  }

  if (pageUrlDetail.alias === null) {
    return getDefaultAliasMetadataForm(culture, urlMetadata);
  }

  const { alias } = pageUrlDetail;
  const { params, value } = alias;

  const valueWithoutRoot = value.split("/").slice(1).join("/");

  const stringifiedParams = map(params, (param) => ({
    name: param.name,
    allowedValues: stringifyDynamicParameterAllowedValues(param.allowedValues)
  }));

  return {
    culture,
    rawParams: stringifiedParams,
    value: valueWithoutRoot
  };
};
export const createUrlMetadataFormByCulture = (
  culture: string,
  paths: PathTranslationSegment[]
): IURLMetadataForm => {
  const pathSegments: IPathSegment[] = map(paths, (path) => ({
    translationValue: path[culture.toLowerCase()],
    defaultTranslation: path["*"]
  }));

  return {
    culture,
    pathSegments
  };
};

export const getUpdatedUrlMetadataForm = (
  form: IURLMetadataForm,
  updatedTranslationValue: string | undefined
): IURLMetadataForm => {
  const { pathSegments } = form;
  const leaf = last(pathSegments) as IPathSegment;
  const updatedLeaf: IPathSegment = {
    ...leaf,
    translationValue: updatedTranslationValue
  };

  return {
    ...form,
    pathSegments: [...pathSegments.slice(0, -1), updatedLeaf]
  };
};

export const getUpdatedAliasMetadataForm = (
  form: IAliasMetadataForm,
  key: Exclude<keyof IAliasMetadataForm, "culture">,
  updatedValue: string,
  paramName?: string
): IAliasMetadataForm => {
  const isUpdatingValue = key === "value";

  if (isUpdatingValue) {
    return {
      ...form,
      [key]: updatedValue
    };
  }

  const updatedParams = map(form.rawParams, (param) =>
    param.name !== paramName ? param : { ...param, allowedValues: updatedValue }
  );

  return { ...form, rawParams: updatedParams };
};

export const getUrlMetadataFormUpdates = (
  forms: IURLMetadataForm[],
  paths: PathTranslationSegment[]
): IUrlMetadataFormUpdate[] => {
  const formsWithUpdate = filter(forms, (form) => {
    const { culture, pathSegments } = form;
    const leafSegment = last(pathSegments);
    const originalValue: string | undefined = (
      last(paths) as PathTranslationSegment
    )[culture.toLocaleLowerCase()];

    return (leafSegment?.translationValue || undefined) !== originalValue;
  });

  return map(formsWithUpdate, (form) => ({
    culture: form.culture,
    translationValue: last(form.pathSegments)?.translationValue
  }));
};

const stringifyDynamicParameterAllowedValues = (values: string[]) =>
  values.join(DYNAMIC_PARAMETER_VALUES_DELIMITER);

export const parseDynamicParameterAllowedValues = (
  value: string,
  shouldFormatValue = true
) => {
  const currentValue = shouldFormatValue
    ? formatRawAllowedValuesValue(value)
    : value;

  return currentValue.split(DYNAMIC_PARAMETER_VALUES_DELIMITER);
};

const getHasAliasFormUpdatedParam = (
  form: IAliasMetadataForm,
  urlDetail: PageUrlDetail
) => {
  const formParams = form.rawParams;
  const pageUrlDetailParams = urlDetail.alias?.params || [];

  return some(formParams, (param) => {
    const { name, allowedValues } = param;
    const pageUrlParameter = find(pageUrlDetailParams, { name });

    const strinfifiedUrlAllowedValues = stringifyDynamicParameterAllowedValues(
      pageUrlParameter?.allowedValues || []
    );

    return strinfifiedUrlAllowedValues !== allowedValues;
  });
};

export const getAliasMetadataFormUpdates = (
  forms: IAliasMetadataForm[],
  urlDetails: PageUrlDetail[]
): IAliasMetadataFormUpdate[] => {
  const formsWithUpdate = filter(forms, (form) => {
    const { culture, value } = form;
    const urlDetail = getPageUrlDetailByCulture(culture, urlDetails);

    if (urlDetail === undefined) {
      return false;
    }

    const { alias } = urlDetail;

    const valueWithRoot = alias !== null ? `${ROOT_PATH}/${value}` : value;

    const hasUpdatedValue = (alias?.value ?? "") !== valueWithRoot;
    const hasUpdatedParameter = getHasAliasFormUpdatedParam(form, urlDetail);

    return hasUpdatedValue || hasUpdatedParameter;
  });

  return formsWithUpdate;
};

export const getIsEditTranslationDisabled = (
  leafSegment: IPathSegment,
  permissions: string[],
  currentSiteItemPath: string
) =>
  isDynamicSlug(leafSegment.defaultTranslation) ||
  leafSegment.defaultTranslation === "index" ||
  leafSegment.defaultTranslation === ROOT_PATH ||
  !(
    userService.hasPermissions(...permissions) ||
    userService.hasOnlySiteItemPermissions(currentSiteItemPath)
  );

export const isPathSegmentTranslated = (pathSegment: IPathSegment) =>
  Boolean(pathSegment.translationValue);

export const getAllowedValuesForParamName = (
  form: IAliasMetadataForm,
  paramName: string
) => find(form.rawParams, { name: paramName })?.allowedValues ?? "";

export const getAllowedValuesMap = (
  form: IAliasMetadataForm,
  urlMetadata: IUrlMetadata
): Record<string, string> => {
  const { paramNames } = urlMetadata;

  const aggregator = (result, paramName) => ({
    ...result,
    [paramName]: getAllowedValuesForParamName(form, paramName)
  });

  return paramNames.reduce(aggregator, {});
};

export const getDefaultUrlMetadata = (): IUrlMetadata => ({
  url: "",
  paramNames: []
});

export const getDefaultPageUrlDetail = (pageUrlDetails: PageUrlDetail[]) =>
  find(pageUrlDetails, { culture: "*" });

export const getPageUrlDetailByCulture = (
  culture,
  pageUrlDetails: PageUrlDetail[]
) => find(pageUrlDetails, { culture });

export const getDynamicParameterNames = (url: string) =>
  map(
    filter(splitPathSegments(url), isDynamicSlug),
    stripParameterDelimitersFromPathSegment
  );

export const getUrlMetadata = (
  pageUrlDetails: PageUrlDetail[]
): IUrlMetadata => {
  const defaultDetail = getDefaultPageUrlDetail(pageUrlDetails);

  if (defaultDetail === undefined) {
    return getDefaultUrlMetadata();
  }

  const dynamicParameterNames = getDynamicParameterNames(defaultDetail.url);

  return {
    url: defaultDetail.url,
    paramNames: dynamicParameterNames
  };
};

export const toStaticAlias = (
  pageId: string,
  culture: string,
  value: string,
  path: string
): IAliasEntity => ({
  culture: culture.toLowerCase(),
  pageId,
  value,
  path,
  parameters: []
});

export const getCanSaveAliases = (
  aliasFormMetadataUpdates: IAliasMetadataFormUpdate[],
  urlMetadata: IUrlMetadata,
  pageId: string,
  path: string,
  staticAliases: IAliasEntity[]
) => {
  const validationChecks = flatMap(aliasFormMetadataUpdates, (update) => [
    getValidationHasAliasValidUrl(update.value),
    getValidationIsStaticAliasUnique(
      toStaticAlias(pageId, update.culture, update.value, path),
      staticAliases
    ),
    getValidationHasAliasMatchedDynamicParameters(urlMetadata, update.value),
    ...map(
      update.rawParams,
      (param) =>
        getValidationHasAliasCorrectAllowedValuesFormat(param.allowedValues)
          .isValid
    )
  ]);

  return every(validationChecks);
};

export const getValidationHasAliasMatchedDynamicParameters = (
  urlMetadata: IUrlMetadata,
  aliasValue: string
) => {
  const { paramNames: originalParamNames } = urlMetadata;

  const aliasParamNames = getDynamicParameterNames(aliasValue);

  const isCountTheSame = originalParamNames.length === aliasParamNames.length;

  // if their Exclusive OR is empty, in theory it means they are the same values
  const areNamesTheSame = isEmpty(xor(originalParamNames, aliasParamNames));

  const doParametersMatch = isCountTheSame && areNamesTheSame;

  return doParametersMatch;
};

export const formatRawAllowedValuesValue = (value: string) =>
  value.replace(
    DYNAMIC_PARAMETER_VALUES_SEPARATOR_REGEX,
    DYNAMIC_PARAMETER_VALUES_DELIMITER
  );

export const getValidationHasAliasCorrectAllowedValuesFormat = (
  allowedValues: string
): AliasValidationResult => {
  if (allowedValues === "") {
    return {
      isValid: true
    };
  }

  const parsedValues = parseDynamicParameterAllowedValues(allowedValues);
  const containsEmptyValues = some(parsedValues, isEmpty);
  const invalidValues = filter(
    parsedValues,
    (value) => !isDynamicSlugValueValid(value) && value !== ""
  );

  const checks = [!containsEmptyValues, isEmpty(invalidValues)];
  const isValid = every(checks);

  return {
    isValid,
    payload: {
      invalidValues: map(invalidValues, (value) => ({ value }))
    }
  };
};

export const getValidationHasAliasValidUrl = (aliasValue: string) => {
  if (aliasValue.endsWith(PATH_SEPARATOR)) {
    return false;
  }

  const containsWhitespaceCharacter = hasWhitespaceCharacter(aliasValue);

  if (containsWhitespaceCharacter) {
    return false;
  }

  const pathSegments = splitPathSegments(aliasValue);
  const arePathSegmentsValid = every(pathSegments, isPathSegmentValid);
  return arePathSegmentsValid;
};

export const getValidationIsStaticAliasUnique = (
  aliasToValidate: IAliasEntity,
  staticAliases: IAliasEntity[]
) => {
  const { pageId, culture, value } = aliasToValidate;
  const fullValue = getUrlWithRootPath(value);

  const isAliasDuplicate = some(
    staticAliases,
    (alias) =>
      alias.value === fullValue &&
      (alias.culture === culture || includes([alias.culture, culture], "*")) &&
      alias.pageId !== pageId
  );

  return !isAliasDuplicate;
};

export const getValidationMissingAliasAllowedFields = (
  aliasFormMetadataUpdates: IAliasMetadataFormUpdate[],
  urlMetadata: IUrlMetadata
): AliasValidationMap => {
  if (isEmpty(urlMetadata.paramNames)) {
    return {};
  }

  const result = aliasFormMetadataUpdates.reduce((result, update) => {
    const { culture, rawParams } = update;

    const emptyParamNames = map(
      filter(rawParams, (param) => isEmpty(param.allowedValues)),
      "name"
    );

    if (isEmpty(emptyParamNames)) {
      return result;
    }

    const entry: IAliasValidationEntry = {
      validationKey: "MISSING_ALIAS_ALLOWED_FIELDS",
      payload: {
        paramNames: emptyParamNames
      }
    };

    return { ...result, [culture]: entry };
  }, {} as AliasValidationMap);

  return result;
};

export const getUrlWithRootPath = (url: string) => `${ROOT_PATH}/${url}`;
export const getAliasIdentifier = (alias: Partial<IAliasEntity>) => {
  const { culture, pageId } = alias;
  return `${culture}@${pageId}`;
};

export const getUpdatedUrlMetadataFormsAfterTranslationRestore = (args: {
  paths: PathTranslationSegment[];
  urlMetadataForms: IURLMetadataForm[];
  culture: string;
}) => {
  const { paths, urlMetadataForms, culture } = args;

  const updatedPathTranslationSegment = omit(
    last(paths) as PathTranslationSegment,
    culture.toLocaleLowerCase()
  );

  const updatedPaths = [...paths.slice(0, -1), updatedPathTranslationSegment];

  const formToUpdate = createUrlMetadataFormByCulture(culture, updatedPaths);

  const updatedUrlMetadataForms = map(urlMetadataForms, (form) =>
    form.culture === culture ? formToUpdate : form
  );

  return updatedUrlMetadataForms;
};

export const hasAliasesSet = (aliases: IAliasEntity[], path: string) =>
  some(aliases, (alias) => alias.path.startsWith(path));

export const isStaticAlias = (alias: IAliasEntity | PageAliasSetNotification) =>
  isEmpty(alias.parameters);
