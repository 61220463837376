import { useCallback, useMemo } from "react";
import classnames from "classnames";
import { makeStyles, TypographyProps, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import moment, { Moment } from "moment";

interface IProps extends IBaseProps, TypographyProps {
  date: AllowedDateTypes;
  relativeDateHoursThreshold?: number;
  format?: string;
}

type AllowedDateTypes = string | Date | Moment;

const useStyles = makeStyles((theme) => ({
  root: {}
}));

const DEFAULT_RELATIVE_DATE_HOURS_THRESHOLD = 24;
const DEFAULT_DATE_FORMAT = "DD/MM/YYYY HH:mm";

moment.updateLocale("en", {
  relativeTime: {
    past: (input) => (input === "just now" ? input : input + " ago"),
    future: (input) => (input === "just now" ? input : "in " + input),
    s: "just now"
  }
});

export const LastUpdateDate = (props: IProps) => {
  const {
    className,
    date,
    relativeDateHoursThreshold = DEFAULT_RELATIVE_DATE_HOURS_THRESHOLD,
    format = DEFAULT_DATE_FORMAT,
    variant = "body2",
    gutterBottom = false,
    ...typographyProps
  } = props;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const getUpdatedDateString = useCallback(
    (
      lastUpdateDate: AllowedDateTypes,
      relativeDateHoursThreshold: number,
      format: string
    ) => {
      const momentDate = moment(lastUpdateDate);
      if (momentDate.isBefore("2000-01-01", "year")) {
        return null;
      }

      return moment().diff(momentDate, "hours") < relativeDateHoursThreshold
        ? momentDate.fromNow()
        : momentDate.format(format);
    },
    []
  );

  const updatedDate = useMemo(
    () => getUpdatedDateString(date, relativeDateHoursThreshold, format),
    [date, relativeDateHoursThreshold, format, getUpdatedDateString]
  );

  return (
    <Typography
      className={rootClassName}
      variant={variant}
      gutterBottom={gutterBottom}
      component="span"
      {...typographyProps}
    >
      {updatedDate}
    </Typography>
  );
};
