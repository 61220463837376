import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {},
  menuItemContainer: {
    marginBottom: theme.spacing(2),
    borderRadius: "4px",
    border: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    display: "flex",
    padding: theme.spacing(1.5, 3),
    height: "67px",
    backgroundColor: theme.palette.common.white,
    position: "relative",
    justifyContent: "space-between",
    boxSizing: "border-box",
    transition: "background-color .3s ease-in-out",
    cursor: "pointer",
    "&:hover $toolboxContainer": {
      display: "flex"
    },
    "&:hover $visibilityContainer": {
      display: "none"
    }
  },

  menuItemContainerHoverMode: {
    "&:hover": {
      boxShadow: theme.palette.custom.ds.shadow.s300,
      backgroundColor: theme.palette.custom.ds.grayscales.gr200
    },

    "&:focus": {
      outline: "none"
    }
  },
  menuItemContainerReadOnlyMode: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100
  },
  menuItemContainerSelected: {
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    backgroundColor: `${theme.palette.custom.ds.viking.viking100} !important`,
    "& $itemTitle, & $itemSubTitle": {
      color: `${theme.palette.custom.ds.viking.viking700} !important`
    }
  },

  menuItemContainerMoveMode: {
    border: `2px solid ${theme.palette.custom.ds.viking.viking300} !important`,
    backgroundColor: `${theme.palette.custom.ds.viking.viking100} !important`,
    "& $itemTitle, & $itemSubTitle": {
      color: `${theme.palette.custom.ds.viking.viking700} !important`
    }
  },

  menuItemContainerPlacementMode: {
    border: `1px dashed ${theme.palette.custom.ds.fiord.fiord300}`,
    backgroundColor: "transparent"
  },

  menuItemContainerPlacementChildMode: {
    border: `1px dashed ${theme.palette.custom.ds.viking.viking500} !important`,
    backgroundColor: `${theme.palette.custom.ds.viking.viking100} !important`
  },

  forbiddenChildMarker: {
    backgroundColor: `${theme.palette.custom.ds.error.error100} !important`,
    borderColor: `${theme.palette.custom.ds.error.error500} !important`,
    cursor: "not-allowed"
  },

  menuItemContainerPlacementSiblingBeforeMode: {
    borderTopColor: "transparent"
  },

  menuItemContainerPlacementSiblingAfterMode: {
    borderBottomColor: "transparent"
  },

  movingItemDecendant: {
    opacity: 0.5,
    cursor: "not-allowed"
  },

  menuItemMetadataSection: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  itemTitle: {
    fontSize: theme.typography.pxToRem(16)
  },
  itemSubTitle: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.ds.grayscales.gr700,
    fontWeight: 400
  },
  forbiddenPlacementContainer: {
    color: theme.palette.custom.ds.error.error500
  },

  menuItemRightSection: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    width: "20px",
    height: "20px"
  },
  visibilityIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  toolboxIcon: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.custom.ds.grayscales.gr600
  },
  toolboxIconSelected: {
    color: theme.palette.custom.ds.viking.viking500
  },
  toolboxContainer: {
    display: "none"
  },
  toolboxContainerMovingMode: {
    display: "flex"
  },

  hiddenEntity: {
    display: "none"
  },
  visibilityContainer: {}
}));
