import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2.5, 2.5),
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    border: "2px solid transparent",
    transition: theme.transitions.create("box-shadow"),
    cursor: "pointer",
    "&:hover": {
      boxShadow: theme.palette.custom.ds.shadow.s300
    }
  },
  itemContainerUnpublished: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    "&  $description": {
      color: theme.palette.custom.ds.grayscales.gr500
    },
    "& $operandLeft, & $operator": {
      color: theme.palette.custom.ds.grayscales.gr600
    },
    "& $operandRight": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  },

  section: {},
  sectionRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center"
  },
  propertyList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  propertyItem: {
    display: "inline-flex",
    justifyContent: "end",
    height: "18px",
    "& label": {
      display: "inline-flex",
      alignItems: "center",
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.custom.ds.grayscales.gr800
    }
  },
  deleteButton: {
    paddingRight: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "unset"
    }
  },
  operandLeft: {
    fontSize: theme.typography.pxToRem(13),
    whiteSpace: "nowrap"
  },
  operator: {
    margin: "8px",
    whiteSpace: "nowrap"
  },
  operandRight: {
    fontWeight: 500,
    whiteSpace: "nowrap"
  },
  sectionLeft: {
    display: "flex",
    flexDirection: "column"
  },
  innerLeftSectionTop: {
    marginBottom: theme.spacing(1),
    width: "fit-content"
  },
  innerLeftSectionBottom: {
    display: "flex",
    flexDirection: "column-reverse",
    height: theme.spacing(4)
  },

  unpublishedWarningContainer: {
    borderRadius: "4px",
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: "#fff8e8",
    color: "#d59e21",
    fontStyle: "italic",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(13)
  },
  shrinkedUnpublishedWarningContainer: {
    width: "15vw"
  },
  unpublishedWarningIcon: {
    color: theme.palette.custom.ds.warning.warning500,
    width: theme.typography.pxToRem(18.33),
    height: theme.typography.pxToRem(15.83),
    marginRight: theme.spacing(2),
    position: "relative",
    top: -4
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.custom.ds.grayscales.gr700,
    fontStyle: "italic"
  },
  link: {
    textAlign: "left",
    marginLeft: theme.spacing(1),
    color: theme.palette.custom.ds.viking.viking500,
    fontSize: theme.typography.pxToRem(13),
    fontFamily: theme.typography.fontFamily
  },
  shrinkedLink: {
    width: "14vw",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    position: "relative",
    top: "4px",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  rulesCount: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.custom.ds.viking.viking100,
    color: theme.palette.custom.ds.viking.viking500,
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    height: theme.spacing(2),
    width: theme.spacing(3),
    "& span": {
      padding: 0
    }
  },
  noMarginRight: { marginRight: "0px" },
  itemSelected: {
    border: `2px solid ${theme.palette.custom.ds.viking.viking500}`,
    backgroundColor: theme.palette.custom.ds.viking.viking100
  },

  badge: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.custom.ds.variables.keyvalue500,
    color: "#FFF",
    marginRight: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    textTransform: "uppercase",
    letterSpacing: "1px"
  }
}));
