import React from "react";
import { makeStyles, Menu } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { ClipboardPageAction } from "components/siteStructure/header/clipboardPageAction";
import { RenameSiteItemMenuItem } from "components/siteStructure/modals/renameSiteItemModal/renameSiteItemMenuItem";
import { CreateSiteItemMenuItem } from "components/siteStructure/modals/createSiteItemModal/createSiteItemMenuItem";
import { contextualMenu } from "theme/commonClasses";

const useStyles = makeStyles((theme) => ({
  contextMenu: {
    opacity: 0,
    "&:hover": {}
  },
  contextMenuItemHidden: {
    position: "absolute",
    width: "0px",
    overflow: "hidden"
  },
  contextualMenu
}));

export interface ISiteStructureTreeItemContextualMenuProps extends IBaseProps {
  node: ISiteItem;
  anchorElement: HTMLElement | null;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const SiteStructureTreeItemContextualMenu = (
  props: ISiteStructureTreeItemContextualMenuProps
) => {
  const { node, anchorElement, onClose } = props;
  const classes = useStyles();

  const isRootNode = node.parentNodesIds.length === 0;

  return (
    <>
      <Menu
        className={classes.contextualMenu}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={onClose}
      >
        <CreateSiteItemMenuItem node={node} onClickCallback={onClose} />
        <RenameSiteItemMenuItem
          node={node}
          onClickCallback={onClose}
          disabled={isRootNode}
        />
        <ClipboardPageAction node={node} onClickCallback={onClose} />
      </Menu>
    </>
  );
};

export default SiteStructureTreeItemContextualMenu;
