import React, { useContext } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISlot } from "models/pages";
import { AvailableSlot } from "components/shared/pageElementsDesign/slot/availableSlot";
import DestinationSlot from "./destinationSlot";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";
import { useRenderModule } from "components/siteStructure/pageView/useRenderModule";
import { OrphanContext } from "components/siteStructure/pageView/orphanContext";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import copyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { DestinationSlotArea } from "components/siteStructure/pageView/pageElements/destinationSlot/destinationSlotArea";

interface IProps extends IBaseProps {
  content: ISlot;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0.5)
  },
  slotContent: {
    padding: theme.spacing(1)
  },
  module: {
    minHeight: theme.spacing(11)
  },
  slotName: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.custom.ds.grayscales.gr700,
    margin: theme.spacing(0, 0, 1, 0.5),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  slotWrap: {
    border: `1px dashed ${theme.palette.custom.ds.grayscales.gr400}`,
    borderRadius: theme.spacing(0.5),
    "&.hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  },
  destinationSlotAvailable: {
    "& $slotWrap": {
      borderColor: theme.palette.custom.ds.viking.viking500
    },
    "& $slotName": {
      color: theme.palette.custom.ds.viking.viking500
    },

    "&:hover": {
      "& $slotWrap": {
        backgroundColor: theme.palette.custom.ds.viking.viking100,
        border: `1px dashed ${theme.palette.custom.ds.viking.viking500}`
      }
    },
    "&:has(#destination-slot:active)": {
      "& $slotWrap": {
        backgroundColor: theme.palette.custom.ds.viking.viking100,
        border: `2px solid ${theme.palette.custom.ds.viking.viking300}`
      }
    }
  },
  dropActive: {
    "& $slotWrap": {
      borderColor: theme.palette.custom.ds.viking.viking500
    },
    "& $slotName": {
      color: theme.palette.custom.ds.viking.viking500
    }
  }
}));

function isMovingTopmostModule(
  movedElementId: string,
  topmostModuleId: string
): boolean {
  return movedElementId === topmostModuleId;
}

function isMovingCurrentModule(
  movedElementId: string,
  currentModuleId: string
) {
  return movedElementId === currentModuleId;
}

function isMovingNextModule(movedElementId: string, nextModuleId: string) {
  return movedElementId === nextModuleId;
}

function isBottommostModule(
  currentModuleIndex: number,
  bottomMostModuleIndex: number
) {
  return currentModuleIndex === bottomMostModuleIndex;
}

function isMovingAncestorLayout(
  ancestorLayoutsIds: string[],
  movedElementId: string
) {
  return ancestorLayoutsIds.includes(movedElementId);
}

export const Slot = (props: IProps) => {
  const { className, content, description } = props;
  const classes = useStyles();

  const page = useSelector(selectSelectedPage)!;
  const renderModule = useRenderModule(page.id);
  const orphan = useContext(OrphanContext);

  const ancestorLayoutsIds = useContext(ModuleLayoutContext);

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(copyPageElementContext);
  const movingElementId = dropContext.pageElementIdentifier?.instanceId ?? "";

  const rootClassName = classnames(classes.root, className, "slot", {
    [classes.destinationSlotAvailable]:
      content.modules.length === 0 &&
      ((dropContext.dropMode === "move" &&
        !isMovingAncestorLayout(ancestorLayoutsIds, movingElementId)) ||
        dropContext.dropMode === "add" ||
        copyContext.copyMode === "copy"),
    [classes.dropActive]:
      (dropContext.dropMode === "move" &&
        !isMovingAncestorLayout(ancestorLayoutsIds, movingElementId)) ||
      dropContext.dropMode === "add" ||
      copyContext.copyMode === "copy"
  });

  const mode = getMode();

  const renderEmpty = () => {
    if (
      !mode ||
      (mode === "Move" &&
        isMovingAncestorLayout(ancestorLayoutsIds, movingElementId))
    ) {
      return <AvailableSlot className={classes.module} />;
    } else if (!orphan) {
      return (
        <DestinationSlot
          containerId={ancestorLayoutsIds[ancestorLayoutsIds.length - 1]}
          slot={content.name}
          position={0}
          visible={
            (dropContext.dropMode === "move" &&
              !isMovingAncestorLayout(ancestorLayoutsIds, movingElementId)) ||
            dropContext.dropMode === "add" ||
            copyContext.copyMode === "copy"
          }
          mode={mode}
        />
      );
    }
  };

  const renderDestinationSlotArea = (visibility: boolean, position = 0) => {
    return (
      !orphan &&
      mode && (
        <DestinationSlotArea
          containerId={ancestorLayoutsIds[ancestorLayoutsIds.length - 1]}
          slot={content.name}
          position={position}
          visible={visibility}
          mode={mode}
        />
      )
    );
  };

  const isNotMovingAncestorLayout = !isMovingAncestorLayout(
    ancestorLayoutsIds,
    movingElementId
  );

  return (
    <div className={rootClassName}>
      <Tooltip title={description ?? content.name} placement="bottom-start">
        <Typography className={classes.slotName} component="div">
          {description ?? content.name}
        </Typography>
      </Tooltip>

      <div className={classes.slotWrap}>
        {content.modules.length === 0 ? (
          renderEmpty()
        ) : (
          <>
            {renderDestinationSlotArea(
              (dropContext.dropMode === "move" &&
                isNotMovingAncestorLayout &&
                !isMovingTopmostModule(
                  movingElementId,
                  content.modules[0].instanceId
                )) ||
                dropContext.dropMode === "add" ||
                copyContext.copyMode === "copy"
            )}
            {content.modules.map((module, index) => (
              <React.Fragment key={module.instanceId}>
                {renderModule(module)}
                {renderDestinationSlotArea(
                  ((dropContext.dropMode === "move" &&
                    isNotMovingAncestorLayout &&
                    !isMovingCurrentModule(
                      movingElementId,
                      module.instanceId
                    )) ||
                    dropContext.dropMode === "add" ||
                    copyContext.copyMode === "copy") &&
                    (isBottommostModule(index, content.modules.length - 1) ||
                      !isMovingNextModule(
                        movingElementId,
                        content.modules[index + 1].instanceId
                      )),
                  index + 1
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );

  function getMode() {
    if (dropContext.dropMode === "add") {
      return "Add";
    } else if (dropContext.dropMode === "move") {
      return "Move";
    } else if (copyContext.copyMode === "copy") {
      return "Copy";
    }

    return null;
  }
};
