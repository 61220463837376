import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { SiteNodeIcon } from "components/shared/siteNodeIcon";
import { IconLabel } from "components/shared/iconLabel";
import { ISiteItem } from "models/siteItem";

interface IProps extends IBaseProps {
  node: ISiteItem;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    backgroundColor: theme.palette.custom.brandBlues.d3BlueLighter,
    color: theme.palette.common.white
  },
  nodeTypeIcon: {
    color: theme.palette.custom.icons.blueLight
  },
  label: {
    fontSize: theme.typography.pxToRem(11),
    marginBottom: theme.spacing(1)
  },
  path: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

export const StagingModalHeader = (props: IProps) => {
  const { className, node } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography variant="subtitle1" className={classes.label}>
        {translate("sitestructure.stageditems.selecteddirectory")}
      </Typography>
      <IconLabel
        icon={
          <SiteNodeIcon
            nodeType={node.nodeType}
            contextName={node.contextName}
          />
        }
        text={node.path}
        textVariant="body1"
        TextClassName={classes.path}
        IconClassName={classes.nodeTypeIcon}
      />
    </div>
  );
};
