import { PermissionCodes } from "catalogs/permissionCodes";
import { IUserState } from "models/domainStates";
import { ModuleType } from "models/pages";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import store from "redux/store";

class UserService {
  hasPermissions(...args: string[]) {
    const user = this._getUserState();

    if (this._isSuperUser(user)) {
      return true;
    }

    for (const arg of args) {
      const hasPermission = user.permissions.some(
        (p) => p.toLowerCase() === arg.toLowerCase()
      );

      if (!hasPermission) {
        return false;
      }
    }

    return true;
  }

  hasACLPermissions(
    permissions: string[],
    check: (user: IUserState) => boolean
  ) {
    const user = this._getUserState();

    if (this._isSuperUser(user)) {
      return true;
    }

    if (check(user)) {
      return true;
    }

    for (const arg of permissions) {
      const hasPermission = user.permissions.some(
        (p) => p.toLowerCase() === arg.toLowerCase()
      );

      if (!hasPermission) {
        return false;
      }
    }

    return true;
  }

  hasElementPermission(type: ModuleType | SiteNodeType, key: string) {
    switch (type) {
      case "Layout":
        return this.hasLayoutPermissions(key);
      case "Module":
        return this.hasModulePermissions(key);
      case SiteNodeTypes.DIRECTORY:
      case SiteNodeTypes.PAGE:
      case SiteNodeTypes.MENU:
        return this.hasSiteItemPermissions(key);
      default:
        throw new Error("No valid type");
    }
  }

  hasModulePermissions(moduleKey: string) {
    const user = this._getUserState();

    return (
      this._isSuperUser(user) ||
      user.permissions.includes(PermissionCodes.EditContent) ||
      user.modules.includes(moduleKey)
    );
  }

  hasLayoutPermissions(layoutKey: string) {
    const user = this._getUserState();

    return (
      this._isSuperUser(user) ||
      user.permissions.includes(PermissionCodes.EditContent) ||
      user.layouts.includes(layoutKey)
    );
  }

  hasSiteItemPermissions(path: string) {
    const user = this._getUserState();

    return (
      this._isSuperUser(user) ||
      user.permissions.includes(PermissionCodes.EditSiteStructure) ||
      user.siteItems.some((item) => this._isItemAllowed(item, path))
    );
  }

  hasOnlySiteItemPermissions(path: string) {
    const user = this._getUserState();

    return (
      this._isSuperUser(user) ||
      user.siteItems.some((item) => this._isItemAllowed(item, path))
    );
  }

  hasSiteItemViewPermission(path: string) {
    const user = this._getUserState();

    return (
      this._isSuperUser(user) ||
      user.permissions.includes(PermissionCodes.EditSiteStructure) ||
      user.siteItems.some((item) => item.startsWith(path))
    );
  }

  getHasUserPermissionToEditMenu = (path: string) => {
    return (
      this.hasPermissions(PermissionCodes.EditContent) ||
      this.hasSiteItemPermissions(path)
    );
  };

  hasSiteItem(path: string) {
    const user = this._getUserState();
    return user.siteItems.includes(path);
  }

  getIsUserAllowedToEditNavigationRules = (
    url: string,
    isAbsolute: boolean = false
  ) => {
    return (
      isAbsolute ||
      userService.hasPermissions(PermissionCodes.EditNavigationRules) ||
      userService.hasSiteItemPermissions(url)
    );
  };

  getIsUserAllowedToAddNavigationRules = () =>
    this._getUserState().siteItems?.length > 0 ||
    this.hasPermissions(PermissionCodes.EditNavigationRules);

  private _getUserState(): IUserState {
    const { user } = store.getState();
    return user;
  }

  private _isSuperUser(user: IUserState): boolean {
    const _superUserPermission = PermissionCodes.ManageAdminPanel;
    return user.permissions.some(
      (p) => p.toLowerCase() === _superUserPermission.toLowerCase()
    );
  }

  private _isItemAllowed(userItem: string, path: string): boolean {
    const isFolder = userItem.endsWith("/");

    return isFolder ? path.startsWith(userItem) : userItem === path;
  }
}

export const userService = new UserService();
