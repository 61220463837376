import { createAction } from "redux-actions";
import { ICurrentRouteState } from "models/domainStates";
import {
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX,
  FAILED_ACTION_SUFFIX
} from "redux/actions/constants";
import { IConfiguration } from "models/configuration";

export const fetchVersion = createAction(
  `FETCH_VERSION${REQUEST_ACTION_SUFFIX}`
);
export const fetchVersionSucceded = createAction(
  `FETCH_VERSION${SUCCESS_ACTION_SUFFIX}`,
  (version: string) => version
);
export const fetchVersionFailed = createAction(
  `FETCH_VERSION${FAILED_ACTION_SUFFIX}`
);

export const openDrawer = createAction("OPEN_DRAWER");
export const closeDrawer = createAction("CLOSE_DRAWER");

export const setCurrentRoute = createAction(
  "SET_CURRENT_ROUTE",
  (currentRoute: ICurrentRouteState) => currentRoute
);

export const setConfiguration = createAction(
  "SET_CONFIGURATION",
  (configuration: IConfiguration) => configuration
);
