import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  children?: React.ReactNode;
  index: number | string;
  currentIndex: number | string;
  shadow?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px -4px 4px -4px rgba(0,0,0,0.3)"
  }
}));

export const TabPanel = React.forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const {
      className,
      children,
      currentIndex,
      index,
      shadow = true,
      ...other
    } = props;
    const classes = useStyles();
    const rootClassName = classnames({ [classes.root]: shadow }, className);

    if (index !== currentIndex) {
      return <></>;
    }

    return (
      <div role="tabpanel" className={rootClassName} {...other} ref={ref}>
        {children}
      </div>
    );
  }
);
