import { makeStyles, Tooltip, TooltipProps } from "@material-ui/core";
import { theme } from "theme/theme";

const useStyles = makeStyles<typeof theme>((th) => ({
  tooltip: {
    backgroundColor: th.palette.custom.ds.grayscales.gr900,
    fontSize: th.typography.pxToRem(12),
    fontWeight: 400
  },
  arrow: {
    color: th.palette.custom.ds.grayscales.gr900
  }
}));

export const PreviewToolTip = (props: TooltipProps) => {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
};
