import { Component, ErrorInfo, ReactNode } from "react";
import { ServerUnavailable } from "components/errorPages/serverUnavailable";
import { UnauthorizedError } from "models/errors";
import { redirectToLogin } from "utils/appUtils";
import { SessionExpiredDialog } from "components/sessionExpiredDialog";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  };

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error
    };
  }

  /**
   * Since the code throwing the UnauthorizedError can be asynchronous
   * we need to wire it to the React engine. That's why we listen to an event
   * and set the React component state in the event handler.
   */
  componentDidMount() {
    window.addEventListener("session_expired", this.setSessionExpired);
  }

  componentWillUnmount() {
    window.removeEventListener("session_expired", this.setSessionExpired);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("An error occurred:", error, errorInfo);
    // TODO: log properly
  }

  render() {
    // TODO: Better generic error page
    if (this.state.hasError) {
      return this.state.error instanceof UnauthorizedError ? (
        <SessionExpiredDialog open={true} onClose={redirectToLogin} />
      ) : (
        <ServerUnavailable />
      );
    }

    return this.props.children;
  }

  private setSessionExpired = () =>
    this.setState({
      hasError: true,
      error: new UnauthorizedError() // We don't need the actual error instance here
    });
}
