import { TagsApi } from "api/tagsApi";
import { ExternalTagHierarchy } from "./tagsPicker";
import { ExternalTagSuggestion, TagsDatasource } from "models/forgeEntities";
import { makeCancelablePromise } from "utils/javascriptUtils";
import { useQuery } from "react-query";
import { NEUTRAL_LANGUAGE_CODE } from "models/systemLanguage";
import { useSelector } from "react-redux";
import { selectTagDatasources } from "redux/selectors/forgeSelectors";
import { useEffect, useState } from "react";

export function useTagDatasources(terms: string) {
  const tagDatasources = useSelector(selectTagDatasources);
  const [sources, setSources] = useState<TagsDatasource[]>(tagDatasources);

  useEffect(() => {
    if (!terms) {
      setSources(tagDatasources);
    } else {
      const regex = new RegExp(terms, "i");
      setSources((local) => local.filter((f) => f.code.match(regex)));
    }
  }, [terms, tagDatasources]);

  return sources;
}

export function useInternalTagsQuery(
  terms: string,
  enabled: boolean,
  abortController?: AbortController
) {
  const queryKey = ["tagPicker", terms];
  const queryOptions = {
    enabled
  };

  const queryFn = () =>
    makeCancelablePromise(
      (controller) =>
        TagsApi.getInternalTagSuggestions(NEUTRAL_LANGUAGE_CODE, terms, {
          signal: controller.signal
        }),
      abortController
    );

  return useQuery(queryKey, queryFn, queryOptions);
}

export function useExternalTagsQuery(
  hierarchy: ExternalTagHierarchy,
  terms: string,
  enabled: boolean,
  abortController?: AbortController
) {
  const queryKey = ["tagPicker", ...hierarchy, terms];
  const queryOptions = {
    enabled
  };

  const queryFn = async () => {
    if (hierarchy.length && hierarchy[0]) {
      const [dataSource, ...externalData] = hierarchy;

      if (dataSource.source === "customentity") {
        return makeCancelablePromise(
          (controller) =>
            TagsApi.getCustomEntityTagSuggestion(dataSource.code, terms, {
              signal: controller.signal
            }),
          abortController
        );
      }

      if (dataSource.source === "external") {
        return makeCancelablePromise(
          (controller) =>
            TagsApi.getExternalTagSuggestion(
              dataSource.code,
              externalData.map((d: ExternalTagSuggestion) => d.dataSourceId),
              terms,
              { signal: controller.signal }
            ),
          abortController
        );
      }
    }

    return [];
  };

  return useQuery(queryKey, queryFn, queryOptions);
}
