import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { Accordion } from "components/shared/accordion";
import {
  setLayoutProperty,
  setModuleProperty
} from "redux/actions/siteStructureActions";
import { PageSidebarNoTemplate } from "./pageSidebarNoTemplate";
import ModuleProperty from "components/siteStructure/pageView/pageSidebar/moduleProperties";
import { ISiteItem } from "models/siteItem";
import PageSidebarModuleExtras from "components/siteStructure/pageView/pageSidebar/pageSidebarModuleExtras";
import {
  PageElement,
  PageElementDefinition
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { EmptyPageSidebarContent } from "components/siteStructure/pageView/pageSidebar/emptyPageSidebarContent";

interface IPageDetailSidebarContentProps extends IBaseProps {
  node: ISiteItem;
  element: PageElement;
  definition: PageElementDefinition;
  canEditProperties: boolean;
}

export const PageSidebarContent = (props: IPageDetailSidebarContentProps) => {
  const { node, element, definition, canEditProperties } = props;
  const dispatch = useDispatch();

  const noTemplateId = "No Template";
  const isModule = element.moduleType === "Module";
  const isNoTemplate = definition.id === noTemplateId;
  const pageId = node.nodeId;

  const onChangeFunction = useCallback(
    (name: string, value: string) => {
      if (!definition) return;

      const commonProperties = {
        pageId: pageId,
        name,
        value
      };

      const action = getAction(element, commonProperties);
      return dispatch(action);
    },
    [dispatch, pageId, element, definition]
  );

  const categories = Object.keys(definition.properties);
  const empty = categories.length === 0;
  const useDefaultCategoryLabel =
    categories.length === 1 && categories[0] === "Misc";

  if (isNoTemplate) {
    return <PageSidebarNoTemplate />;
  }

  if (empty) {
    return <EmptyPageSidebarContent element={element} />;
  }

  return (
    <>
      {isModule && (
        <PageSidebarModuleExtras
          element={element}
          pageId={pageId}
          canEditProperties={canEditProperties}
          currentSiteItemPath={node.path}
        />
      )}
      {categories.map((category) => (
        <Accordion
          key={`${category}-${element.instanceId}`}
          label={
            useDefaultCategoryLabel
              ? translate("sitestructure.properties")
              : category
          }
        >
          {definition.properties[category].map((property) => (
            <ModuleProperty
              key={`${element.instanceId}-${property.name}`}
              propertyDefinition={{ ...property, canUseVariables: true }}
              value={element.properties[property.name]}
              disabled={!canEditProperties}
              onChange={onChangeFunction}
            />
          ))}
        </Accordion>
      ))}
    </>
  );
};

const getAction = (
  element: PageElement,
  commonProperties: {
    pageId: string;
    name: string;
    value: string;
  }
) => {
  switch (element.moduleType) {
    case "Module":
      return setModuleProperty({
        moduleInstanceId: element.instanceId,
        ...commonProperties
      });

    case "Template":
    case "Layout":
      return setLayoutProperty({
        layoutInstanceId: element.instanceId,
        layoutKey: {
          id: element.id,
          namespace: element.namespace
        },
        ...commonProperties
      });
  }
};

export default PageSidebarContent;
