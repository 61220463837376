import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback
} from "react";
import { useSelector } from "react-redux";
import { selectLinkRulesEntityList } from "redux/selectors/linkRulesSelectors";
import {
  SEARCH_RESULTS_MIN_LETTERS,
  getFilteredLinkRulesEntityList,
  getLinkRulesEntitiesMetadata,
  getLinkRulesUrlOccurrencesCountMap
} from "components/linkRules/utils";
import { useContextValue } from "components/hooks";
import {
  ILinkRuleEntityMetadata,
  ILinkRulesEntityList
} from "models/linkRules";
import { wait } from "utils/javascriptUtils";

interface SearchManagementContextValue {
  searchValue: string;
  isSearching: boolean;
  setSearchValue: (searchValue: string) => void;
  filteredLinkRulesEntityList: ILinkRulesEntityList;
  filteredLinkRulesEntitiesMetadata: ILinkRuleEntityMetadata[];
  linkRuleUrlCountMap: Record<string, number>;
}

const SearchManagementContext = createContext<
  SearchManagementContextValue | undefined
>(undefined);

export const SearchManagementContextProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const linkRulesEntityList = useSelector(selectLinkRulesEntityList);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const filteredLinkRulesEntityList = useMemo(
    () =>
      searchValue.length < SEARCH_RESULTS_MIN_LETTERS
        ? linkRulesEntityList
        : getFilteredLinkRulesEntityList(linkRulesEntityList, searchValue),
    [linkRulesEntityList, searchValue]
  );

  const filteredLinkRulesEntitiesMetadata = useMemo(
    () => getLinkRulesEntitiesMetadata(filteredLinkRulesEntityList),
    [filteredLinkRulesEntityList]
  );

  const linkRuleUrlCountMap = useMemo(() => {
    return getLinkRulesUrlOccurrencesCountMap(linkRulesEntityList);
  }, [linkRulesEntityList]);

  const onSetSearchValue = useCallback(async (searchValue: string) => {
    setSearchValue(searchValue);

    if (searchValue.length < SEARCH_RESULTS_MIN_LETTERS) {
      return;
    }

    // Simulate a search delay
    setIsSearching(true);
    await wait(400);
    setIsSearching(false);
  }, []);

  const contextValue = useContextValue({
    searchValue,
    setSearchValue: onSetSearchValue,
    isSearching,
    filteredLinkRulesEntityList,
    filteredLinkRulesEntitiesMetadata,
    linkRuleUrlCountMap
  });

  return (
    <SearchManagementContext.Provider value={contextValue}>
      {children}
    </SearchManagementContext.Provider>
  );
};

export const useSearchManagement = (): SearchManagementContextValue => {
  const context = useContext(SearchManagementContext);
  if (!context) {
    throw new Error(
      "useSearchManagement must be used within a SearchManagementProvider"
    );
  }
  return context;
};
