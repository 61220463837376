import { ApiClient } from "api/apiClient";
import { IVersion } from "models/version";

const BASE_URL = "api/version";

export class EchoApi {
  static readonly getVersion = async (): Promise<IVersion> => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  };
}
