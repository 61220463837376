import classnames from "classnames";
import {
  makeStyles,
  Typography,
  TypographyProps,
  TypographyVariant
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { forwardRef } from "react";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  icon: string | React.ReactNode;
  text: string;
  subtext?: string;
  textVariant?: TypographyVariant;
  subtextVariant?: TypographyVariant;
  TextClassName?: string;
  SubtextClassName?: string;
  TextProps?: TypographyProps;
  SubtextProps?: TypographyProps;
  IconClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  mainRow: {
    display: "flex",
    alignItems: "center"
  },
  secondaryRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(4)
  },
  icon: {},
  text: {
    flex: 1,
    paddingLeft: theme.spacing(1)
  },
  subtext: {}
}));

export const IconLabel = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    className,
    icon,
    text,
    subtext,
    TextProps = {},
    SubtextProps = {},
    TextClassName = "",
    SubtextClassName = "",
    IconClassName = "",
    textVariant = "body1",
    subtextVariant = "caption"
  } = props;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const iconClassName = classnames(classes.icon, IconClassName);
  const textClassName = classnames(classes.text, TextClassName);
  const subtextClassName = classnames(classes.subtext, SubtextClassName);

  return (
    <div className={rootClassName} ref={ref}>
      <div className={classes.mainRow}>
        {typeof icon === "string" ? (
          <UxdIcon className={iconClassName} name={icon} />
        ) : (
          <span className={iconClassName}>{icon}</span>
        )}
        <Typography
          className={textClassName}
          variant={textVariant}
          {...TextProps}
        >
          {text}
        </Typography>
      </div>
      {subtext && (
        <div className={classes.secondaryRow}>
          <Typography
            className={subtextClassName}
            variant={subtextVariant}
            {...SubtextProps}
          >
            {subtext}
          </Typography>
        </div>
      )}
    </div>
  );
});
