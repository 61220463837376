import React from "react";
import classnames from "classnames";
import {
  makeStyles,
  Fade,
  CircularProgress,
  CircularProgressProps
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps, CircularProgressProps {
  delay?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const DelayedProgress = (props: IProps) => {
  const { className, delay = 800, ...other } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Fade in={true} style={{ transitionDelay: `${delay}ms` }} unmountOnExit>
      <CircularProgress className={rootClassName} {...other} />
    </Fade>
  );
};
