import { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useDispatch } from "react-redux";
import { map } from "lodash";
import {
  IVariableContract,
  IVariableDetail,
  VariableStatus
} from "models/variables";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  getDefaultVariableItem,
  getVariableStatus,
  VariableModalAction
} from "utils/variableUtils";
import { useStyles } from "./styles";
import { D3Button } from "components/shared/d3Components";
import { loadVariablesModalAction } from "redux/actions/variablesActions";
import { ActionButton } from "components/shared/actionButton";

const replaceAsterisk = (input: string) =>
  input.toLowerCase() === "*" ? translate("metadata.all").toLowerCase() : input;

interface IProps {
  variableDetails: IVariableDetail[];
  variable: IVariableContract;
  hasEditPermissions: boolean;
  onDeleteTranslation: (variableDetail: IVariableDetail) => void;
}

type ValueType = string | null | undefined;

interface ItemMetadata {
  id: string;
  name: string;
  value: ValueType;
  parentValue: ValueType;
  path: string | undefined;
  icon: string;
  variableDetail: IVariableDetail;
  type: VariableStatus;
}

const transformVariableDetailToItemMetadata = (
  detail: IVariableDetail
): ItemMetadata => {
  const type = getVariableStatus(detail);

  const iconName = type === "inherited" ? "keyboard_return" : "my_location";

  const { culture, environment } = detail;

  const id = [culture, environment].join(".");
  const name = map([culture, environment], replaceAsterisk).join(".");

  return {
    id,
    name,
    value: detail.value ?? detail.definedIn?.value,
    parentValue: detail.definedIn?.value,
    path: detail.definedIn?.path,
    icon: iconName,
    variableDetail: detail,
    type
  };
};

const getCanAddTranslation = (variable: IVariableContract): boolean => {
  const defaultItem = getDefaultVariableItem(variable);
  if (defaultItem === undefined) {
    return false;
  }

  const type = getVariableStatus(defaultItem);

  return type !== "inherited";
};

const LanguagesOverridesSection = (props: IProps) => {
  const {
    variable,
    variableDetails,
    hasEditPermissions: hasEditablePermissions,
    onDeleteTranslation: onDelete
  } = props;
  const dispatch = useDispatch();

  const classes = useStyles(props);

  const items = useMemo(
    () => map(variableDetails, transformVariableDetailToItemMetadata),
    [variableDetails]
  );

  const onAddTranslation = () => {
    dispatch(
      loadVariablesModalAction({
        action: VariableModalAction.CREATE,
        type: variable.type,
        variable,
        isTranslateMode: true
      })
    );
  };

  const onEditTranslation = (variableDetail: IVariableDetail) => {
    dispatch(
      loadVariablesModalAction({
        action: VariableModalAction.EDIT,
        type: variable.type,
        variable,
        isTranslateMode: true,
        variableDetail
      })
    );
  };

  const onDeleteTranslation = (variableDetail: IVariableDetail) => {
    onDelete(variableDetail);
  };

  const shouldShowAddLanguage = useMemo(
    () => hasEditablePermissions && getCanAddTranslation(variable),
    [hasEditablePermissions, variable]
  );

  const title = variableDetails.length
    ? "sitestructure.variables.languages_overrides.title_has_overrides"
    : "sitestructure.variables.languages_overrides.title_no_overrides";

  const editActionTooltip =
    "sitestructure.variables.languages_overrides.tooltip.edit_translation";

  const deleteActionTooltip = "sitestructure.variables.delete.tooltip";

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <label className={classes.title}>{translate(title)}</label>
        {shouldShowAddLanguage && (
          <D3Button
            className={classes.addLanguageBtn}
            icon="add_circle"
            iconPosition="left"
            variant="ghost"
            color="secondary"
            onClick={onAddTranslation}
            children={translate(
              "sitestructure.variables.languages_overrides.add_language"
            )}
          />
        )}
      </header>
      <div className={classes.listContainer}>
        {items.map((item) => (
          <div className={classes.item} key={item.id}>
            <Typography className={classes.itemLeftSide} component="div">
              <UxdIcon className={classes.icon} name={item.icon} />
              <label className={classes.itemName}>{item.name}</label>
            </Typography>
            <Typography className={classes.itemCenter}>
              <label>{item.value}</label>
            </Typography>
            {hasEditablePermissions && item.type !== "inherited" && (
              <div className={classes.itemActions}>
                <ActionButton
                  icon="edit"
                  tooltip={translate(editActionTooltip)}
                  size="small"
                  className={classes.actionButton}
                  iconClassName={classes.icon}
                  onClick={() => onEditTranslation(item.variableDetail)}
                />
                <ActionButton
                  icon="delete"
                  tooltip={translate(deleteActionTooltip)}
                  size="small"
                  className={classes.actionButton}
                  iconClassName={classes.icon}
                  onClick={() => onDeleteTranslation(item.variableDetail)}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguagesOverridesSection;
