import { put, select } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  MoveSiteDirectory,
  MoveSiteMenu,
  MoveSitePage
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CopySiteItemPayload } from "redux/actions/typings/siteStructureActions";
import { makeSelectSiteItemById } from "redux/selectors/siteStructureSelectors";
import { ISiteItem } from "models/siteItem";
import {
  getDestinationItem,
  getUniqueName
} from "redux/sagas/helpers/siteItemHelpers";

const COMMAND_MAP = {
  Page: MoveSitePage,
  Directory: MoveSiteDirectory,
  Menu: MoveSiteMenu
};

export function* sendMoveSiteItem(action: Action<CopySiteItemPayload>) {
  try {
    const { sourceId, destinationId } = action.payload;

    const siteItemSelector = makeSelectSiteItemById();
    const movedItem: ISiteItem = yield select(siteItemSelector, sourceId);
    const destinationItem: ISiteItem = yield getDestinationItem(
      destinationId,
      siteItemSelector
    );

    const destinationName: string = yield getUniqueName(
      destinationItem,
      movedItem.label,
      siteItemSelector
    );

    const command = new COMMAND_MAP[movedItem.nodeType]({
      sourceId,
      destinationName,
      destinationPath: destinationItem.path
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.moveitems.error",
      successMessage: "notification.moveitems.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
