import ContentView from "components/siteStructure/content";
import MainDetailView from "components/siteStructure/mainDetail";
import PermissionsView from "components/siteStructure/permissions";
import { PermissionCodes } from "catalogs/permissionCodes";
import { VariablesView } from "components/siteStructure/variables/variablesView";
import { SiteNodeType } from "models/siteStructure";

export const CONTENT_VIEW = "content";
export const MAIN_DETAIL_VIEW = "main-detail";
export const URLS_VIEW = "urls";
export const PERMISSIONS_VIEW = "permissions";
export const VARIABLES_VIEW = "variables";

export type AvailableTabs =
  | "content"
  | "main-detail"
  | "urls"
  | "permissions"
  | "variables";

export const siteItemTabs = [
  {
    name: "tabs.content",
    icon: "dashboard",
    tab: CONTENT_VIEW,
    component: ContentView,
    permission: PermissionCodes.ViewContent
  },
  {
    name: "tabs.maindetail",
    icon: "dashboard",
    tab: MAIN_DETAIL_VIEW,
    component: MainDetailView,
    permission: PermissionCodes.ViewMetadata
  },
  {
    name: "tabs.permissions",
    icon: "dashboard",
    tab: PERMISSIONS_VIEW,
    component: PermissionsView,
    permission: PermissionCodes.ViewPermissions
  },
  {
    name: "tabs.variables",
    icon: "dashboard",
    tab: VARIABLES_VIEW,
    component: VariablesView,
    permission: PermissionCodes.ViewVariables
  }
];

export const TabListBySiteItemTypeMap: Record<SiteNodeType, AvailableTabs[]> = {
  Directory: ["content", "main-detail", "permissions", "variables"],
  Page: ["content", "main-detail", "permissions", "variables"],
  Menu: ["content", "permissions"]
};
