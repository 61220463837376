import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PickListPropertyInfo } from "models/componentProperty";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { translate } from "utils/i18n";
import { IPickListValue } from "models/pageElementProperty";
import { PickListApi } from "api/pickListApi";
import { theme } from "theme/theme";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  pickList: PickListPropertyInfo;
  name: string;
  value: string | null;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  displayName: {
    fontWeight: "bold"
  },
  value: {
    fontSize: "small",
    marginLeft: theme.spacing(1)
  }
}));

export async function getPicklistItems(
  dataPath: string,
  valueField: string,
  descriptionField: string
) {
  try {
    const result = await PickListApi.getPickListItems(
      dataPath,
      valueField,
      descriptionField
    );

    return result;
  } catch (err) {
    console.error("Some error happened");
    return [];
  }
}

export const FreeTextPickListDataPathProperty = (props: IProps) => {
  const {
    className,
    pickList,
    name,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { dataPath, valueField, descriptionField } = pickList;

  const [pickListLocalValue, setPickListLocalValue] =
    useState<IPickListValue | null>(null);

  const [pickListValues, setPickListValues] = useState<IPickListValue[]>([]);

  const syncPicklistValues = useCallback(async () => {
    const pickListItems = await getPicklistItems(
      dataPath,
      valueField,
      descriptionField
    );

    const picklistValues: IPickListValue[] = pickListItems.map((v) => ({
      value: v.value,
      displayName: v.description,
      inputValue: null
    }));

    let localValueItem = picklistValues.find((i) => i.value === value);

    if (!localValueItem && value) {
      localValueItem = {
        value: value,
        displayName: value,
        inputValue: null
      };
    }

    setPickListValues(picklistValues);
    setPickListLocalValue(localValueItem || null);
  }, [dataPath, descriptionField, value, valueField]);

  useEffect(() => {
    syncPicklistValues();
  }, [syncPicklistValues]);

  const filter = createFilterOptions<IPickListValue>();

  const handleSelect = (
    event: React.ChangeEvent<{}>,
    newValue: IPickListValue | string | null
  ) => {
    let selectedValue: string | null = null;
    if (typeof newValue === "string") {
      selectedValue = newValue;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      selectedValue = newValue.inputValue;
    } else {
      selectedValue = newValue ? newValue.value : null;
    }

    if (pickListLocalValue?.value === selectedValue) {
      return;
    }

    let localValueItem = pickListValues.find((i) => i.value === selectedValue);
    if (!localValueItem && selectedValue) {
      localValueItem = {
        value: selectedValue,
        displayName: selectedValue,
        inputValue: null
      };
    }
    setPickListLocalValue(localValueItem || null);

    onChange(name, selectedValue || "");
  };

  const clearValueOnVariableSwitch = () => {
    setPickListLocalValue(null);
    onChange(name, pickListLocalValue?.value ?? "");
  };

  return (
    <>
      <Autocomplete
        className={rootClassName}
        options={pickListValues}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            className={mandatory.showMessage ? mandatory.className : ""}
            placeholder={translate("picklist.typeaheadsuggestion")}
            variant="outlined"
            helperText={mandatory.showMessage ? mandatory.message : ""}
          />
        )}
        disabled={disabled}
        value={pickListLocalValue}
        freeSolo
        onChange={handleSelect}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              displayName: `${translate("picklist.add")} "${
                params.inputValue
              }"`,
              value: params.inputValue
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          //Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.displayName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        renderOption={(option) => option.displayName}
      />
      <VariableSwitch
        propertyName={name}
        propertyValue={value ?? ""}
        disabled={disabled}
        onChange={onChange}
        onSwitch={clearValueOnVariableSwitch}
        mandatory={mandatory}
      />
    </>
  );
};
