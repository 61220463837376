import { SiteItemModal } from "components/siteStructure/modals/siteItemModal";
import { useSelector, useDispatch } from "react-redux";
import { selectRenameSiteItem } from "redux/selectors/siteItem/renameSiteItemSelector";
import { closeRenameModal } from "redux/actions/siteItem/renameSiteItem";
import { setRenameSiteItem } from "redux/actions/siteStructureActions";
import { SiteNodeActions, SiteNodeType } from "models/siteStructure";

export function RenameSiteItemModal() {
  const { open, node } = useSelector(selectRenameSiteItem);
  const dispatch = useDispatch();

  if (!node) {
    return null;
  }

  const onCancel = () => {
    dispatch(closeRenameModal());
  };

  const onConfirm = (type: SiteNodeType, name: string) => {
    dispatch(
      setRenameSiteItem({
        itemId: node.nodeId,
        newName: name,
        itemType: type,
        fullPath: node.path
      })
    );

    onCancel();
  };

  return (
    <SiteItemModal
      open={open}
      parentId={node.parentNodesIds.at(-1) || node.nodeId}
      parentPath={node.path.replace(node.label, "")}
      siteItemType={node.nodeType}
      defaultValue={node.label}
      onConfirm={onConfirm}
      onCancel={onCancel}
      type={node.nodeType}
      action={SiteNodeActions.RENAME}
    />
  );
}
