import { IBaseProps } from "components/_baseProps";
import { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { translate } from "utils/i18n";
import { isValidVariableFormat } from "utils/variableUtils";
import D3AutoCompleteInput, {
  ID3AutoCompleteInputOption,
  toAutoCompleteInputOption
} from "components/shared/d3Components/d3AutoCompleteInput";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      padding: theme.spacing(0.3125)
    }
  },
  error: {
    outline: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.valencia.valencia500
    }`
  },
  errorMessage: {
    margin: theme.spacing(0.5, 0),
    color: theme.palette.custom.ds.valencia.valencia500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontStyle: "italic"
  }
}));

export interface IMetadataVariableInputProps extends IBaseProps {
  value: string;
  variables: string[];
  onSubmit?: (variable: string) => void;
}

export const MetadataVariableInput = (props: IMetadataVariableInputProps) => {
  const { variables, value, onSubmit } = props;
  const classes = useStyles();

  const [error, setError] = useState<boolean>(false);

  const rootClassName = classnames(classes.root, {
    [classes.error]: error
  });

  const onVariableSelectOrSubmit = (option: ID3AutoCompleteInputOption) => {
    const isValid = isValidVariableFormat(option.value);
    if (onSubmit && isValid) {
      onSubmit(option.value);
    }

    setError(!isValid);
  };

  const placeholder = translate("metadata.variables.placeholder");
  const current: ID3AutoCompleteInputOption = toAutoCompleteInputOption(value);
  const options = variables.map((x: string) => toAutoCompleteInputOption(x));

  return (
    <span>
      <D3AutoCompleteInput
        className={rootClassName}
        value={current}
        options={options}
        placeholder={placeholder}
        onSubmit={onVariableSelectOrSubmit}
      />
      {error && (
        <Typography className={classes.errorMessage}>
          {translate("metadata.error.variable.format")}
        </Typography>
      )}
    </span>
  );
};

export default MetadataVariableInput;
