import { reducerFactory } from "./coreReducer";

import { Action } from "redux-actions";
import { setUser } from "redux/actions/userActions";
import { IUserState } from "models/domainStates";

export const initialState: IUserState = {
  id: "",
  firstName: "",
  lastName: "",
  permissions: [],
  modules: [],
  layouts: [],
  siteItems: []
};

const reactions = {
  [setUser.toString()]: (state: IUserState, action: Action<IUserState>) => {
    state.id = action.payload.id;
    state.firstName = action.payload.firstName;
    state.lastName = action.payload.lastName;
    state.permissions = action.payload.permissions;
    state.modules = action.payload.modules || [];
    state.layouts = action.payload.layouts || [];
    state.siteItems = action.payload.siteItems || [];
  }
};

export default reducerFactory(initialState, reactions);
