import { Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

import { translate } from "utils/i18n";

const InfoTooltip = ({ className }: IBaseProps) => (
  <div className={className}>
    <h4>{translate("sitestructure.urlssection.entities.url")}</h4>
    <Typography component="p">
      {translate("sitestructure.urlssection.url_description")}
    </Typography>
    <h4>Alias</h4>
    <Typography component="p">
      {translate("sitestructure.urlssection.alias_description")}
    </Typography>
  </div>
);

export default InfoTooltip;
