import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useSiteItemNodeAndChildren } from "api/queryHooks";
import ErrorPage from "components/errorPages";
import { usePreviewUrlRequest, useSideBarHook } from "./hooks";
import { Device } from "./previewBar/previewBar";
import PreviewBar from "./previewBar";
import PreviewContent from "./previewContent";
import PreviewSkeleton from "./previewSkeleton";
import PreviewSnackbar from "./PreviewSnackbar";
import PreviewPageNotificationListener from "./previewPageNotificationListener";
import { translate } from "utils/i18n";
import { noop } from "utils/typescriptUtils";
import { useSelector } from "react-redux";
import { selectFrontendSites } from "redux/selectors/frontendSelectors";

interface ISize {
  width: number | string;
  height: number | string;
}

const sizes: { [key in Device]: ISize } = {
  desktop: {
    width: "100%",
    height: "100%"
  },
  tablet: {
    width: "820px",
    height: "1080px"
  },
  mobile: {
    width: "390px",
    height: "844px"
  }
};

type Actions = "noop" | "refresh" | "close";

const actions: { [key in Actions]: () => void } = {
  close: () => window.close(),
  refresh: () => window.location.reload(),
  noop: noop
};

const useStyles = makeStyles((theme) => ({
  previewContent: {
    paddingTop: (props: { device: Device }) =>
      props.device === "desktop" ? theme.spacing(0) : theme.spacing(4)
  }
}));

export function PreviewPage() {
  const request = useSiteItemNodeAndChildren();
  const { node } = useParams<{ node: string }>();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [action, setAction] = useState<Actions>("noop");
  const [btnText, setBtnText] = useState<string>("");

  const {
    device,
    setDevice,
    culture,
    setCulture,
    landscape,
    setLandscape,
    availableCultures
  } = useSideBarHook();

  useEffect(
    () => {
      request.mutate(node);
    },
    // eslint-disable-next-line
    [node]
  );

  const promptForRefresh = () => {
    setMessage(translate("preview.refresh_message"));
    setOpen(true);
    setBtnText(translate("preview.refresh_button_text"));
    setAction("refresh");
  };

  const promptCloseTab = () => {
    setMessage(translate("preview.close_tab_message"));
    setOpen(true);
    setBtnText(translate("preview.close_tab_button_text"));
    setAction("close");
  };

  const notifyPublishStatusUpdate = () => {
    // NOTE: low priority notification, skip if already showing high priority notification (refresh or close)
    if (open) return;

    setMessage(translate("preview.status_change_message"));
    setOpen(true);
    setBtnText("");
    setAction("noop");
  };

  const siteItem = request.data && request.data[node];
  const availableSites = useSelector(selectFrontendSites);

  const previewUrl = usePreviewUrlRequest(siteItem, culture, availableSites);

  const classes = useStyles({ device });

  if (request.isError || previewUrl.isError) {
    return <ErrorPage error="not found" />;
  }

  const previewIsReady = !request.isLoading && !previewUrl.isLoading;

  return (
    <>
      <PreviewBar
        loading={request.isLoading}
        onDeviceChange={setDevice}
        onLandscapeChange={setLandscape}
        onCultureChange={setCulture}
        siteItem={siteItem}
        device={device}
        cultures={availableCultures}
        previewUrl={previewUrl.data || ""}
      />
      {!previewIsReady && <PreviewSkeleton />}
      {previewIsReady && (
        <PreviewContent
          className={classes.previewContent}
          src={previewUrl.data || ""}
          width={sizes[device].width}
          height={sizes[device].height}
          landscape={landscape}
        />
      )}

      {previewIsReady && siteItem && (
        <>
          <PreviewPageNotificationListener
            siteItem={siteItem}
            onEntityUpdated={promptForRefresh}
            onEntityRenamed={promptForRefresh}
            onEntityMoved={promptForRefresh}
            onEntityRemoved={promptCloseTab}
            onPagePublished={notifyPublishStatusUpdate}
            onPageUnpublished={notifyPublishStatusUpdate}
            onPageReviewed={notifyPublishStatusUpdate}
          />

          <PreviewSnackbar
            open={open}
            onClose={() => setOpen(false)}
            onClick={actions[action]}
            btnText={btnText}
            message={message}
            severity="info"
          />
        </>
      )}
    </>
  );
}
