import React, { useState } from "react";
import classnames from "classnames";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useDispatch } from "react-redux";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { userService } from "services/userService";
import { ISiteItem } from "models/siteItem";
import { openCreateModal } from "redux/actions/siteItem/createSiteItemActions";
import { D3Button } from "components/shared/d3Components";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";

interface IProps extends IBaseProps {
  node: ISiteItem;
  hasIndex: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(3, 4, 0),
    gap: theme.spacing(1),
    height: theme.spacing(5)
  },
  buttonFocus: {},
  menu: {
    "& .MuiMenu-paper": {
      borderRadius: "3px",
      width: "160px",
      boxShadow: theme.palette.custom.greyscales.shadows.sh300
    },
    "& .MuiList-padding": {
      padding: theme.spacing(0.5, 1)
    }
  },
  menuItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(0.5, 0),
    borderRadius: "2px"
  },
  menuItemIcon: {
    paddingLeft: theme.spacing(1.5),
    color: theme.palette.custom.ds.viking.viking500
  },
  menuItemIconGlyph: {
    fontSize: theme.typography.pxToRem(20)
  },
  menuItemLabel: {
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24)
    }
  },
  disabled: {
    opacity: 0.4
  },
  divider: {
    backgroundColor:
      theme.palette.custom.greyscales.bordersAndSeparators.lineLighter
  }
}));

export const DirectoryDetailHeader = (props: IProps) => {
  const { className, node, hasIndex } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasPermissions = userService.hasSiteItemPermissions(node.path);

  const handleNewClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleCreateIndex = () => createNewSiteItem(SiteNodeTypes.PAGE, true);
  const handleNewSectionClick = () =>
    createNewSiteItem(SiteNodeTypes.DIRECTORY);
  const handleNewPageClick = () => createNewSiteItem(SiteNodeTypes.PAGE);
  const handleNewMenuClick = () => createNewSiteItem(SiteNodeTypes.MENU);

  const createNewSiteItem = (type: SiteNodeType, isIndex?: boolean) => {
    closeMenu();
    dispatch(openCreateModal({ node, type, index: isIndex }));
  };

  return (
    <div className={rootClassName}>
      <div className={classes.header}>
        <D3Button
          variant="outlined"
          onClick={handleNewClick}
          disabled={!hasPermissions}
          icon="add_circle"
        >
          {translate("sitestructure.new_button")}
        </D3Button>

        {!hasIndex && (
          <D3Button
            variant="ghost"
            onClick={handleCreateIndex}
            disabled={!hasPermissions}
            icon="description"
          >
            {translate("sitestructure.add_index_page")}
          </D3Button>
        )}
      </div>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        className={classes.menu}
        elevation={0}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={handleNewSectionClick}
          disabled={!hasPermissions}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <UxdIcon className={classes.menuItemIconGlyph} name="folder" />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemLabel}
            primary={translate("sitestructure.section")}
          />
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={handleNewPageClick}
          disabled={!hasPermissions}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <UxdIcon
              className={classes.menuItemIconGlyph}
              name="description"
              outline={false}
            />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemLabel}
            primary={translate("sitestructure.page")}
          />
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          className={classes.menuItem}
          onClick={handleNewMenuClick}
          disabled={!hasPermissions}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <UxdIcon
              className={classes.menuItemIconGlyph}
              name="menu"
              outline={false}
            />
          </ListItemIcon>
          <ListItemText
            className={classes.menuItemLabel}
            primary={translate("sitestructure.menu")}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
