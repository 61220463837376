import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { translate } from "utils/i18n";
import { List, ListItemIcon, Tooltip, Typography } from "@material-ui/core";
import { useGetLinkRulesList } from "api/queryHooks";
import LinkRuleEntityItem from "../linkRulesEntityListItem";
import LinkRulesEntityLoading from "../linkRulesEntityListLoading";
import { loadLinkRulesEntityList } from "redux/actions/linkRulesActions";

import { useStyles } from "./styles";
import { useLinkRulesScrollingManagementContext } from "components/linkRules/context/scrollManagement";
import { useSearchManagement } from "components/linkRules/context/searchManagement";
import { UxdIcon } from "components/shared/uxdIcon";

const LinkRulesEntityNav = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const request = useGetLinkRulesList();
  const { filteredLinkRulesEntitiesMetadata } = useSearchManagement();
  const { currentSelectedEntityKey } = useLinkRulesScrollingManagementContext();

  useEffect(() => {
    dispatch(loadLinkRulesEntityList(request.data ?? {}));
  }, [request.data, dispatch]);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {translate("sitestructure.navigationrules.title")}
        <Tooltip
          title={translate("sitestructure.navigationrules.tooltip")}
          placement="top"
          arrow
        >
          <ListItemIcon>
            <UxdIcon name="info_outline" outline className={classes.icon} />
          </ListItemIcon>
        </Tooltip>
      </Typography>

      <div className={classes.listContainer}>
        {request.isLoading && <LinkRulesEntityLoading />}

        {!request.isLoading && (
          <List component="nav" className={classes.list}>
            {filteredLinkRulesEntitiesMetadata.map((item) => (
              <LinkRuleEntityItem
                key={item.name}
                linkRuleEntityMetadata={item}
                entitySlug={item.name}
                isSelected={item.name === currentSelectedEntityKey}
              />
            ))}
          </List>
        )}
      </div>
    </div>
  );
};

export default LinkRulesEntityNav;
