import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  footerLink: {
    display: "flex",
    flexDirection: "row",
    marginRight: "auto",
    gap: theme.spacing(1.5),
    alignItems: "center"
  },
  hidden: {
    display: "none !important"
  },
  icon: {
    color: theme.palette.custom.ds.info.info500
  },
  name: {
    color: theme.palette.custom.ds.grayscales.gr700
  },
  value: {
    fontWeight: 500
  }
}));
