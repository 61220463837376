import { IRequestMonitorState } from "models/domainStates";
import {
  setRequest,
  RequestMonitorActionPayload,
  setSuccessRequest,
  setFailedRequest
} from "redux/actions/requestMonitorActions";
import { Action } from "redux-actions";
import { reducerFactory } from "redux/reducers/coreReducer";

export const initialState: IRequestMonitorState = {};

const reactions = {
  [setRequest.toString()]: (
    state: IRequestMonitorState,
    action: Action<RequestMonitorActionPayload>
  ) => {
    const { requestName, requestState } = action.payload;
    state[requestName] = {
      ...state[requestName],
      [requestState.requestPayload]: {
        status: requestState.status
      }
    };
  },
  [setSuccessRequest.toString()]: (
    state: IRequestMonitorState,
    action: Action<RequestMonitorActionPayload>
  ) => {
    const { requestName, requestState } = action.payload;
    delete state[requestName][requestState.requestPayload];
  },
  [setFailedRequest.toString()]: (
    state: IRequestMonitorState,
    action: Action<RequestMonitorActionPayload>
  ) => {
    const { requestName, requestState } = action.payload;
    state[requestName] = {
      ...state[requestName],
      [requestState.requestPayload]: {
        status: requestState.status,
        error: requestState.error
      }
    };
  }
};

export default reducerFactory(initialState, reactions);
