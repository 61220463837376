import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  icon: {}
}));

const WebIcon = (props: IProps) => {
  const classes = useStyles();

  const classnames = classNames(classes.icon, props.className);

  return (
    <div className={classnames}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99301 0.333008C3.31301 0.333008 0.333008 3.31967 0.333008 6.99967C0.333008 10.6797 3.31301 13.6663 6.99301 13.6663C10.6797 13.6663 13.6663 10.6797 13.6663 6.99967C13.6663 3.31967 10.6797 0.333008 6.99301 0.333008ZM11.613 4.33301H9.64634C9.43301 3.49967 9.12634 2.69967 8.72634 1.95967C9.95301 2.37967 10.973 3.23301 11.613 4.33301ZM6.99967 1.69301C7.55301 2.49301 7.98634 3.37967 8.27301 4.33301H5.72634C6.01301 3.37967 6.44634 2.49301 6.99967 1.69301ZM1.83967 8.33301C1.73301 7.90634 1.66634 7.45967 1.66634 6.99967C1.66634 6.53967 1.73301 6.09301 1.83967 5.66634H4.09301C4.03967 6.10634 3.99967 6.54634 3.99967 6.99967C3.99967 7.45301 4.03967 7.89301 4.09301 8.33301H1.83967ZM2.38634 9.66634H4.35301C4.56634 10.4997 4.87301 11.2997 5.27301 12.0397C4.04634 11.6197 3.02634 10.773 2.38634 9.66634ZM4.35301 4.33301H2.38634C3.02634 3.22634 4.04634 2.37967 5.27301 1.95967C4.87301 2.69967 4.56634 3.49967 4.35301 4.33301ZM6.99967 12.3063C6.44634 11.5063 6.01301 10.6197 5.72634 9.66634H8.27301C7.98634 10.6197 7.55301 11.5063 6.99967 12.3063ZM8.55968 8.33301H5.43967C5.37967 7.89301 5.33301 7.45301 5.33301 6.99967C5.33301 6.54634 5.37967 6.09967 5.43967 5.66634H8.55968C8.61967 6.09967 8.66634 6.54634 8.66634 6.99967C8.66634 7.45301 8.61967 7.89301 8.55968 8.33301ZM8.72634 12.0397C9.12634 11.2997 9.43301 10.4997 9.64634 9.66634H11.613C10.973 10.7663 9.95301 11.6197 8.72634 12.0397ZM9.90634 8.33301C9.95967 7.89301 9.99967 7.45301 9.99967 6.99967C9.99967 6.54634 9.95967 6.10634 9.90634 5.66634H12.1597C12.2663 6.09301 12.333 6.53967 12.333 6.99967C12.333 7.45967 12.2663 7.90634 12.1597 8.33301H9.90634Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default WebIcon;
