import { ISiteItem } from "models/siteItem";
import { Action } from "redux-actions";
import {
  closeDeleteModal,
  openDeleteModal
} from "redux/actions/siteItem/deleteSiteItemActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export interface IDeleteSiteItemState {
  open: boolean;
  node: ISiteItem | null;
}

export const initialState: IDeleteSiteItemState = {
  open: false,
  node: null
};

const reactions = {
  [openDeleteModal.toString()]: (
    state: IDeleteSiteItemState,
    action: Action<ISiteItem>
  ) => {
    state.open = true;
    state.node = action.payload;

    return state;
  },

  [closeDeleteModal.toString()]: (state: IDeleteSiteItemState) => {
    state.open = false;
    state.node = null;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
