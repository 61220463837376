import classnames from "classnames";
import { makeStyles, Button } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { DialogBase } from "components/shared/dialogBase";
import { translate } from "utils/i18n";
import { StagingSiteItemsContent } from "./stagingSiteItemsContent";
import { ISiteItem } from "models/siteItem";
import { useContext, useMemo } from "react";
import { StagingContext } from "components/contexts/siteItemsStagingContext";

interface IProps extends IBaseProps {
  open: boolean;
  node: ISiteItem;
  action: "Publish" | "Unpublish";
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: 1,
    minHeight: 0
  }
}));

export const StagingSiteItemsModal = (props: IProps) => {
  const { className, open, node, action, onClose } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const stagingContext = useContext(StagingContext);

  const handleClose = () => {
    stagingContext.unselectAll();
    onClose();
  };

  const handleClick = () => {
    stagingContext.confirm(action);
    onClose();
  };

  const title = useMemo(() => translate(`sitestructure.${action}`), [action]);
  const buttonLabel =
    stagingContext.selected.length === 1
      ? title
      : translate(`sitestructure.stageditems.${action}button`, {
          count: stagingContext.selected.length
        });

  return (
    <DialogBase
      className={rootClassName}
      title={title}
      open={open}
      onClose={handleClose}
      disablePadding={true}
      ContentClassName={classes.dialogContent}
      actions={
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClick}
          disabled={stagingContext.selected.length === 0}
        >
          {buttonLabel}
        </Button>
      }
    >
      <StagingSiteItemsContent
        className={classes.content}
        node={node}
        action={action}
      />
    </DialogBase>
  );
};
