import { useEffect } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  fetchMenu,
  unsetSelectedMenuItem
} from "redux/actions/siteStructureActions";

import { ISiteItem } from "models/siteItem";
import { useRequestStatus } from "components/hooks";
import { SkeletonMenuDetail } from "components/siteStructure/menuView/components/skeletonMenuDetail";
import ErrorPage from "components/errorPages";
import EmptyMenu from "components/siteStructure/menuView/components/emptyMenu";
import MenuItemsContainer from "components/siteStructure/menuView/components/menuItemsContainer";
import {
  MenuBuilderContextProvider,
  useMenuBuilderContext
} from "components/siteStructure/menuView/context";
import SiteItemSidebar from "components/siteStructure/siteItemSidebar/siteItemSidebar";
import { IMenuSidebar } from "models/menus";
import AddMenuItemButton from "components/siteStructure/menuView/components/addMenuItemButton";

interface IProps extends IBaseProps {
  node: ISiteItem;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex"
  },
  main: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
    paddingRight: theme.spacing(3)
  }
}));

const MenuDetailComponent = (props: IProps) => {
  const { className, node } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isPlacementModeActive, hasUserPermissionToEditMenu, menu } =
    useMenuBuilderContext();

  const rootClassName = classnames(classes.root, className);

  useEffect(() => {
    dispatch(fetchMenu(node.nodeId));
    dispatch(unsetSelectedMenuItem());
  }, [dispatch, node.nodeId]);

  const request = useRequestStatus(fetchMenu, node.nodeId);

  const shouldRenderSkeleton = !menu || menu.id !== node.nodeId;

  if (shouldRenderSkeleton) {
    return <SkeletonMenuDetail />;
  }

  if (request.error) {
    return <ErrorPage error="internal server error" />;
  }

  const shouldRenderEmptyMenu = menu.items.length === 0;
  const shouldRenderAddMenuItemButton =
    hasUserPermissionToEditMenu && !isPlacementModeActive;

  const menuSidebar: IMenuSidebar = {
    key: menu.id,
    label: menu.name,
    moduleType: "Menu",
    ...menu
  };

  return (
    <div className={rootClassName}>
      <div className={classes.main}>
        {shouldRenderEmptyMenu ? (
          <EmptyMenu />
        ) : (
          <MenuItemsContainer menuItems={menu.items} />
        )}
        {shouldRenderAddMenuItemButton && <AddMenuItemButton />}
      </div>
      <SiteItemSidebar node={node} element={menuSidebar} />
    </div>
  );
};

export const MenuDetail = (props: IProps) => (
  <MenuBuilderContextProvider>
    <MenuDetailComponent {...props} />
  </MenuBuilderContextProvider>
);
