import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import internalServerErrorImage from "images/500.svg";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2)
  },
  image: {
    height: "100%",
    width: "100%"
  }
}));

export const InternalServerError = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <img
        className={classes.image}
        src={internalServerErrorImage}
        alt="Internal Server Error"
      ></img>
    </div>
  );
};
