import classnames from "classnames";
import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps, AutocompleteRenderInputParams {
  loading: boolean;
  error: string | boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: theme.palette.custom.mainAccent.red
  },
  startIcon: {
    color: theme.palette.custom.greyscales.backgrounds.grey60
  }
}));

export const ContentPickerContextInputTextField = (props: IProps) => {
  const { className, loading, error, ...params } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <TextField
      {...params}
      className={rootClassName}
      variant="outlined"
      helperText={error ? <span className={classes.error}>{error}</span> : ""}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            {params.InputProps.startAdornment}
            <InputAdornment position="start">
              <UxdIcon className={classes.startIcon} name="search" />
            </InputAdornment>
          </>
        ),
        endAdornment: (
          <>
            {params.InputProps.endAdornment}
            {loading && <CircularProgress size={24} />}
          </>
        )
      }}
      inputProps={{
        ...params.inputProps,
        pattern: "\\w"
      }}
    />
  );
};
