import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { UpdateInfo } from "models/siteStructure";
import { LastUpdateDate } from "components/shared/lastUpdateDate";

interface IProps extends IBaseProps {
  updateInfo: UpdateInfo;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center"
  },
  date: {
    fontSize: "0.85rem",
    whiteSpace: "nowrap"
  },
  user: {
    color: theme.palette.custom.ds.grayscales.gr700,
    lineHeight: 1.5
  }
}));

export const LastUpdatedInfo = (props: IProps) => {
  const { className, updateInfo } = props;
  const { lastUpdateDate, lastUpdateUser } = updateInfo;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <LastUpdateDate className={classes.date} date={lastUpdateDate} />
      <Typography
        className={classes.user}
        variant="caption"
        gutterBottom={false}
      >
        {lastUpdateUser && `${translate("sitestructure.by")} ${lastUpdateUser}`}
      </Typography>
    </div>
  );
};
