import { useContext } from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { ActionButton } from "components/shared/actionButton";
import { PermissionCodes } from "catalogs/permissionCodes";
import { ChangeTemplateContext } from "components/contexts/changeTemplateContext";
import {
  AssetType,
  AssetTypes
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";

interface IProps extends IBaseProps {
  type: AssetType;
  assetName?: string | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.error.main,
    height: "100%",
    margin: theme.spacing(0, 3),
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2)
  },
  icon: {
    fontSize: theme.spacing(9)
  },
  label: {}
}));

export const PageSidebarAssetNotFound = (props: IProps) => {
  const { className, type, assetName } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const openChangeTemplateDialog = useContext(ChangeTemplateContext);
  const assetType = translate(`sitestructure.${type.toLowerCase()}`);

  const callToAction = (
    <ActionButton
      variant="outlined"
      permissions={[PermissionCodes.EditContent]}
      onClick={(e) => {
        e.preventDefault();
        openChangeTemplateDialog();
      }}
    >
      {translate("sitestructure.changetemplate")}
    </ActionButton>
  );

  return (
    <div className={rootClassName}>
      <UxdIcon className={classes.icon} name="info" outline={false} />
      <Typography className={classes.label} variant="body2" align="center">
        {translate("sitestructure.assetnotfound", {
          elementType: assetType
            ? `${assetName || ""} ${assetType}`
            : translate("sitestructure.asset")
        })}
      </Typography>
      {type === AssetTypes.TEMPLATE && callToAction}
    </div>
  );
};
