import {
  ComponentPropertyTypeName,
  IComponentProperty
} from "models/componentProperty";
import { translate } from "utils/i18n";

interface IComponentPropertyBuilderObject {
  name: string;
  typeName?: ComponentPropertyTypeName;
  category?: string;
  mandatory?: boolean;
  canUseVariables?: boolean;
  showHint?: boolean;
}

export function buildComponentProperty(
  item: IComponentPropertyBuilderObject
): IComponentProperty {
  return {
    name: item.name,
    typeName: item.typeName ?? "String",
    category: item.category ?? "",
    displayName: translate(
      `sitestructure.menu_sidebar.${item.name.toLocaleLowerCase()}`
    ),
    mandatory: item.mandatory ?? false,
    canUseVariables: item.canUseVariables ?? true,
    description: item.showHint
      ? translate(
          `sitestructure.menu_sidebar.descriptions.${item.name.toLocaleLowerCase()}`
        )
      : undefined
  };
}

function buildMainComponentProperty(
  item: IComponentPropertyBuilderObject
): IComponentProperty {
  item.category = "main_properties";
  return buildComponentProperty(item);
}

function buildVisualComponentProperty(
  item: IComponentPropertyBuilderObject
): IComponentProperty {
  item.category = "visual_properties";
  return buildComponentProperty(item);
}

export function buildItemProperties(): IComponentProperty[] {
  return [
    // Main Properties
    buildMainComponentProperty({ name: "label", mandatory: true }),
    buildMainComponentProperty({
      name: "id",
      mandatory: true,
      canUseVariables: false
    }),
    buildMainComponentProperty({ name: "customProperties", showHint: true }),
    buildMainComponentProperty({ name: "link" }),
    buildMainComponentProperty({
      name: "opensInNewTab",
      typeName: "Boolean",
      canUseVariables: false
    }),
    buildMainComponentProperty({ name: "visible", typeName: "Boolean" }),

    // Visual Properties
    buildVisualComponentProperty({ name: "icon" }),
    buildVisualComponentProperty({ name: "tooltip" })
  ];
}
