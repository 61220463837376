import classnames from "classnames";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeStage, SiteNodeTypes } from "models/siteStructure";
import { SiteNodeIcon } from "components/shared/siteNodeIcon";
import { separatePathAndLeaf } from "utils/pathUtils";
import { StageIcon } from "components/shared/stageIcon";
import { ForgeEntityStage } from "models/forgeEntities";
import { useEffect, useMemo, useState } from "react";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  path: string;
  stage: SiteNodeStage;
  contextName: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0, 1),
    "&:hover $copyPathContainer": {
      display: "block"
    }
  },
  leftContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(3, 1, 0)
  },
  rightContainer: {
    height: "100%",
    paddingTop: theme.spacing(3)
  },
  copyPathContainer: {
    display: "none",
    height: "100%",
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr500,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  menuIcon: {
    margin: theme.spacing(0, 1),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  label: {
    color: theme.palette.custom.ds.grayscales.gr900,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24)
  },
  path: {
    bottom: theme.spacing(1),
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.custom.ds.grayscales.gr600,
    lineHeight: theme.typography.pxToRem(16),
    marginTop: theme.spacing(0.5),
    wordWrap: "break-word",
    wordBreak: "break-word"
  },
  stageIcon: {
    fontSize: "8px"
  }
}));

export const MenuInfo = (props: IProps) => {
  const { className, path, stage, contextName } = props;

  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);
  const [open, setOpen] = useState(false);

  const [subpath, leaf] = useMemo(() => separatePathAndLeaf(path), [path]);

  useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        setOpen(false);
      }, 400);
    }

    return () => clearTimeout(timeout);
  }, [open]);

  const copyPath = () => {
    navigator.clipboard.writeText(path);
    setOpen(true);
  };

  return (
    <div className={rootClassName}>
      <div className={classes.leftContainer}>
        <StageIcon
          className={classes.stageIcon}
          stage={stage.toLowerCase() as ForgeEntityStage}
        />
        <SiteNodeIcon
          className={classes.menuIcon}
          nodeType={SiteNodeTypes.MENU}
          size={3}
          contextName={contextName}
        />
      </div>
      <div className={classes.rightContainer}>
        <Typography variant="body1" className={classes.label}>
          {leaf}
        </Typography>
        <Typography variant="subtitle2" className={classes.path}>
          {subpath}
        </Typography>
      </div>
      <Tooltip
        title={translate("sitestructure.copypathsuccessfully")}
        open={open}
      >
        <IconButton
          className={classes.copyPathContainer}
          disableRipple
          onClick={copyPath}
        >
          <UxdIcon name="content_copy" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
