import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ICellContent, IFixedSlot, IRowList, ISlot } from "models/pages";
import { FixedSlot } from "components/shared/pageElementsDesign/slot/fixedSlot";
import { RowList } from "./cellContent_rowList";
import { Slot } from "./cellContent_slot";
import {
  ILayoutDefinition,
  ILayoutRow
} from "models/siteAssets/layoutDefinition";
import { useMemo } from "react";

interface SlotNameAndDescription {
  name: string;
  description?: string;
}

interface IComponentMetadataConfigMap {
  props: any;
  component: React.ElementType;
}

const getLayoutSlotsNameAndDescription = (
  myRows: ILayoutRow[]
): SlotNameAndDescription[] => {
  const cols = myRows.flatMap((x) => x.columns);

  const slotNamesDescriptions = cols
    .flatMap((col) => [
      {
        name: col.name,
        description: col.description
      },
      ...getLayoutSlotsNameAndDescription(col.rows)
    ])
    .filter((entry) => entry.name !== null);

  return slotNamesDescriptions;
};

interface IProps extends IBaseProps {
  content: ICellContent;
  width: number;
  layout?: ILayoutDefinition;
}

const useStyles = makeStyles(() => ({
  root: (props: IProps) => ({
    width: `${props.width}%`,
    display: "flex",
    flexDirection: "column"
  })
}));

export const CellContent = (props: IProps) => {
  const { className, content, layout } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);

  const layoutSlotsNamesAndDescriptions = useMemo(
    () => getLayoutSlotsNameAndDescription(layout?.design.rows ?? []),
    [layout?.design.rows]
  );

  const ComponentMetadataConfigMap: Record<
    string,
    () => IComponentMetadataConfigMap
  > = useMemo(
    () => ({
      FixedSlot: () => {
        const fixedSlot = content as IFixedSlot;
        const description = layoutSlotsNamesAndDescriptions.find(
          (item) => item.name === fixedSlot.name
        )?.description;

        return {
          component: FixedSlot,
          props: {
            name: description || fixedSlot.name
          }
        };
      },
      RowList: () => {
        const rowList = content as IRowList;

        return {
          component: RowList,
          props: {
            rows: rowList.rows,
            layout
          }
        };
      },
      Slot: () => {
        const slot = content as ISlot;
        const description = layoutSlotsNamesAndDescriptions.find(
          (item) => item.name === slot.name
        )?.description;

        return {
          component: Slot,
          props: {
            content,
            description: description || slot.name
          }
        };
      }
    }),
    [layout, content, layoutSlotsNamesAndDescriptions]
  );

  const componentMetadataConfig = useMemo(
    () => ComponentMetadataConfigMap[content.contentType](),
    [ComponentMetadataConfigMap, content.contentType]
  );

  const ContentTypeComponentToRender = componentMetadataConfig.component;

  return (
    <div className={rootClassName}>
      <ContentTypeComponentToRender {...componentMetadataConfig.props} />{" "}
    </div>
  );
};
