import { ISiteItem } from "models/siteItem";
import {
  SiteItemModifiedNotification,
  PagePublishedNotification,
  DirectoryPublishedNotification,
  PageReviewedNotification,
  DirectoryReviewedNotification,
  PageUnpublishedNotification,
  DirectoryUnpublishedNotification,
  SitePageRemovedNotification,
  SiteDirectoryRemovedNotification,
  SitePageMovedNotification,
  SiteDirectoryMovedNotification,
  SitePageRenamedNotification,
  SiteDirectoryRenamedNotification,
  RolledBackNotification
} from "@d3-forge/forge-notifications";
import { useNotificationGroup, useNotifications } from "components/hooks";
import notificationService from "services/notificationService";

interface IProps {
  siteItem: ISiteItem;
  onEntityUpdated?: () => void;
  onEntityRemoved?: () => void;
  onEntityMoved?: () => void;
  onEntityRenamed?: () => void;
  onPagePublished?: () => void;
  onPageUnpublished?: () => void;
  onPageReviewed?: () => void;
  onDirectoryPublished?: () => void;
  onDirectoryUnpublished?: () => void;
  onDirectoryReviewed?: () => void;
}

const isCurrent = (current: ISiteItem, notificationItemId: string) =>
  current.nodeId === notificationItemId;

const isCurrentOrParent = (current: ISiteItem, notificationItemId: string) =>
  isCurrent(current, notificationItemId) ||
  current.parentNodesIds.includes(notificationItemId);

export const PreviewPageNotificationListener = (props: IProps) => {
  const { siteItem } = props;

  useNotificationGroup(notificationService.VSM_GROUP_NAME);

  useNotifications(
    ["SiteDirectoryRemovedNotification", "SitePageRemovedNotification"],
    (ntf: SiteDirectoryRemovedNotification | SitePageRemovedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onEntityRemoved?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["SiteDirectoryMovedNotification", "SitePageMovedNotification"],
    (ntf: SiteDirectoryMovedNotification | SitePageMovedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onEntityMoved?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["SiteDirectoryRenamedNotification", "SitePageRenamedNotification"],
    (ntf: SiteDirectoryRenamedNotification | SitePageRenamedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onEntityRenamed?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["DirectoryPublishedNotification"],
    (ntf: DirectoryPublishedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onDirectoryPublished?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["PagePublishedNotification"],
    (ntf: PagePublishedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onPagePublished?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["DirectoryUnpublishedNotification"],
    (ntf: DirectoryUnpublishedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onDirectoryUnpublished?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["PageUnpublishedNotification"],
    (ntf: PageUnpublishedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onPageUnpublished?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["DirectoryReviewedNotification"],
    (ntf: DirectoryReviewedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onDirectoryReviewed?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["PageReviewedNotification"],
    (ntf: PageReviewedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onPageReviewed?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["SiteItemModifiedNotification"],
    (ntf: SiteItemModifiedNotification) => {
      if (isCurrentOrParent(siteItem, ntf.itemId)) {
        props.onEntityUpdated?.call(this);
      }
    },
    [siteItem]
  );

  useNotifications(
    ["RolledBackNotification"],
    (ntf: RolledBackNotification) => {
      if (isCurrent(siteItem, ntf.aggregateId)) {
        props.onEntityUpdated?.call(this);
      }
    },
    [siteItem]
  );

  return <></>;
};

export default PreviewPageNotificationListener;
