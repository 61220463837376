import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  groupHeaderTransparencySkipper: {
    boxShadow: theme.palette.custom.ds.shadow.s400,
    backgroundColor: "white",
    position: "sticky",
    top: "-8px",
    zIndex: 10,
    margin: 0
  },
  groupHeaderContainer: {
    height: "34px",
    padding: theme.spacing(0, 1, 0, 1),
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(13)
  },
  groupChildren: {
    padding: 0,
    paddingLeft: theme.spacing(3)
  },
  groupLabel: {
    paddingLeft: theme.spacing(0.5),
    position: "relative",
    top: "1px",
    fontSize: theme.typography.pxToRem(14)
  },
  chipLabel: {
    fontSize: theme.typography.pxToRem(9)
  }
}));
