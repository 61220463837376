import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3),
    position: "relative"
  },
  title: {
    paddingBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(32)
  }
}));
