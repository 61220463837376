import { useCallback } from "react";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { ILinkRuleProperty } from "models/linkRules";
import {
  getHighlightedHtmlFromSearchMatch,
  getOperatorTextByEncoding,
  getShouldShowPropertyValue
} from "components/linkRules/utils";
import { useStyles } from "./styles";

export interface ILinkRulePropertyProps {
  property: ILinkRuleProperty;
  searchValue: string;
}
export function LinkRuleProperty(props: Readonly<ILinkRulePropertyProps>) {
  const classes = useStyles();
  const { property, searchValue } = props;

  const getHighlightedPropertyValueHtml = useCallback(
    (propertyValue: string | null) => {
      return propertyValue !== null
        ? getHighlightedHtmlFromSearchMatch(propertyValue, searchValue)
        : "null";
    },
    [searchValue]
  );

  const operator = getOperatorTextByEncoding(property.operator);
  const shouldShow = getShouldShowPropertyValue(property.operator);

  const operatorClass = classNames(classes.operator, {
    [classes.noMarginRight]: !getShouldShowPropertyValue(property.operator)
  });

  return (
    <div className={classes.propertyItem}>
      <Typography component="label" className={classes.operandLeft}>
        {property.name}
      </Typography>
      <Typography component="label" className={operatorClass}>
        {operator}
      </Typography>
      {shouldShow && (
        <Typography
          component="label"
          className={classes.operandRight}
          dangerouslySetInnerHTML={{
            __html: getHighlightedPropertyValueHtml(property.value)
          }}
        />
      )}
    </div>
  );
}

export default LinkRuleProperty;
