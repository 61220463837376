import React, { useState } from "react";
import classnames from "classnames";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  TextField
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeStage } from "models/siteStructure";
import { ISiteItem } from "models/siteItem";
import { SiteNodeInfo } from "components/shared/siteNodeInfo";
import { UxdIcon } from "components/shared/uxdIcon";
import { userService } from "services/userService";
import { contextualMenu } from "theme/commonClasses";
import SiteStructureTreeItemContextualMenu from "components/siteStructure/tree/siteStructureTreeItemContextualMenu";

interface IProps extends IBaseProps {
  label: string;
  node: ISiteItem;
  stage: SiteNodeStage;
  isLoading: boolean;
  isTreeItemSelected: boolean;
  isLinkPicker?: boolean;
}

const useStyles = makeStyles((theme) => ({
  hiddenTextboxFocusHandle: {
    width: "0px",
    height: "0px",
    outline: "none",
    position: "absolute"
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover button": {
      opacity: 1
    }
  },
  progress: {
    position: "absolute"
  },
  nodeIcon: {
    color: "inherit"
  },
  labelAction: {
    color: theme.palette.custom.ds.grayscales.gr500,
    position: "sticky",
    right: 0,
    padding: theme.spacing(0.5)
  },
  contextMenu: {
    opacity: 0,
    "&:hover": {}
  },
  spinner: {
    color: theme.palette.custom.ds.grayscales.gr500
  },
  disabled: {
    opacity: 0.4
  },
  parent: {
    opacity: 1
  },
  contextMenuItemHidden: {
    position: "absolute",
    width: "0px",
    overflow: "hidden"
  },
  linkPickerPadding: {
    margin: theme.spacing(0.25, 0)
  },
  contextualMenu
}));

export const SiteStructureTreeItemLabel = React.memo((props: IProps) => {
  const {
    className,
    label,
    node,
    stage,
    isLoading,
    isTreeItemSelected,
    isLinkPicker = false
  } = props;
  const classes = useStyles();

  const hasPermissions = userService.hasSiteItemPermissions(node.path);

  const isParentOfPermissionedItem = userService.hasSiteItemViewPermission(
    node.path
  );

  const rootClassName = classnames(classes.root, className, {
    [classes.disabled]: !hasPermissions,
    [classes.parent]: isParentOfPermissionedItem
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const openContextualMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const contextClassName = classnames(
    classes.labelAction,
    classes.contextMenu,
    {
      [classes.contextMenuItemHidden]: isLoading && anchorEl !== null
    }
  );
  const spinnerClassName = classnames(classes.labelAction);

  return (
    <div className={rootClassName}>
      <TextField
        className={classes.hiddenTextboxFocusHandle}
        autoFocus={isTreeItemSelected}
      />
      <SiteNodeInfo
        size="small"
        nodeType={node.nodeType}
        stage={stage}
        label={label}
        nodeIconClassName={classes.nodeIcon}
        contextName={node.contextName}
        className={isLinkPicker ? classes.linkPickerPadding : undefined}
      />
      {(!isLoading || anchorEl !== null) && !isLinkPicker && (
        <IconButton
          size="small"
          className={contextClassName}
          onClick={openContextualMenu}
        >
          <UxdIcon name="more_vert" />
        </IconButton>
      )}
      {isLoading && (
        <div className={spinnerClassName}>
          <CircularProgress className={classes.spinner} size={12} />
        </div>
      )}
      <SiteStructureTreeItemContextualMenu
        node={node}
        anchorElement={anchorEl}
        onClose={onMenuClose}
      />
    </div>
  );
});
