import { ApiClient } from "api/apiClient";
import { IPage, PageUrlDetail } from "models/pages";

const BASE_URL = "/api/pages";

export class PagesApi {
  static readonly getPage = async (id: string): Promise<IPage> => {
    const endpoint = `${BASE_URL}/${id}`;
    return ApiClient.get(endpoint);
  };

  static readonly getPreviewUrl = async (
    path: string,
    culture: string,
    platform: string,
    environment: string,
    context: string
  ) => {
    const endpoint = `${BASE_URL}/preview/${culture}/${platform}/${environment}/${context}?path=${path}`;
    return ApiClient.get(endpoint);
  };

  static readonly getViewUrl = async (
    path: string,
    culture: string,
    platform: string,
    environment: string,
    context: string
  ) => {
    const endpoint = `${BASE_URL}/view/${culture}/${platform}/${environment}/${context}?path=${path}`;
    return ApiClient.get(endpoint);
  };

  static readonly getSiteItemUrls = async (
    pagePath: string
  ): Promise<PageUrlDetail[]> => {
    const query = `itemPath=${pagePath}`;
    const endpoint = `${BASE_URL}/urls?${query}`;
    return ApiClient.get(endpoint);
  };
}
