import classnames from "classnames";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useContext, useMemo } from "react";
import { ContentPickerContext } from "./contentPickerContext";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0
  }
}));

type OrderByValue = {
  name: string;
  value: string;
};

export const ContentPickerOrderBySelect = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const context = useContext(ContentPickerContext);

  const orderByValues: OrderByValue[] = useMemo(
    () => [
      { name: translate("contentpicker.systemdefault"), value: "(default)" },
      {
        name: translate("contentpicker.latestupdated"),
        value: "LastUpdateDate"
      },
      {
        name: translate("contentpicker.latestcontentdate"),
        value: "ContentDate"
      },
      { name: translate("contentpicker.latestcreated"), value: "CreationDate" }
    ],
    []
  );

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.orderby")} />
      <Select
        fullWidth={true}
        margin="dense"
        variant="outlined"
        value={context.queryFilter.sortBy}
        onChange={(e) => {
          context.setQueryFilter("sortBy", e.target.value as string);
        }}
      >
        {orderByValues.map((l) => (
          <MenuItem key={l.value} value={l.value}>
            {l.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
