import classnames from "classnames";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ContentPickerCardStage } from "./contentPickerCardStage";
import { ForgeEntity } from "models/forgeEntities";
import { ContentPickerCardThumbnail } from "components/contentPicker/contentPickerCardThumbnail";
import { translate } from "utils/i18n";
import { LastUpdateDate } from "components/shared/lastUpdateDate";
import { makeSelectSystemLanguageMap } from "redux/selectors/forgeSelectors";
import { useMemoSelector } from "components/hooks";
import ContentPickerNavigateToDetailButton from "./contentPickerNavigateToDetailButton";

interface IProps extends IBaseProps {
  entity: ForgeEntity;
  selected: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: theme.spacing(32),
    width: theme.spacing(24),
    cursor: "pointer",

    "&$selected": {
      border: `1px solid ${theme.palette.custom.secondaryAccent.selected}`,
      backgroundColor: theme.palette.custom.other.lightCyan
    }
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: theme.spacing(20),
    justifyContent: "space-evenly"
  },
  stage: {
    width: theme.spacing(3) + theme.spacing(0.5)
  },
  thumbnail: {
    margin: theme.spacing(2)
  },
  navigateButtonContainer: {
    width: theme.spacing(3) + theme.spacing(0.5)
  },
  twoRowsText: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",

    width: "100%",
    textAlign: "center"
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "14px",
    fontWeight: 500,
    textAlign: "center"
  },
  slug: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: "13px",
    fontWeight: 300,
    textAlign: "center"
  },
  language: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: "10px",
    fontWeight: 500,
    margin: theme.spacing(0.25, 0),
    textAlign: "center",
    textTransform: "capitalize"
  },
  lastUpdate: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: "12px",
    fontWeight: 300,
    textAlign: "center"
  },
  selected: {}
}));

export const ContentPickerCard = (props: IProps) => {
  const { className, entity, selected } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    { [classes.selected]: selected },
    className
  );

  const systemLanguageMap = useMemoSelector(makeSelectSystemLanguageMap);

  const titleClassname = classnames(classes.twoRowsText, classes.title);
  const slugClassname = classnames(classes.twoRowsText, classes.slug);

  const title = (
    <Typography className={titleClassname} variant="body1" component="span">
      {entity.title || translate("contentpicker.notitle")}
    </Typography>
  );

  const slug = (
    <Typography className={slugClassname} variant="body1" component="span">
      {entity.slug || translate("contentpicker.noslug")}
    </Typography>
  );

  const culture = (
    <Typography className={classes.language} variant="body1" component="span">
      {systemLanguageMap[entity.culture.toLowerCase()]}
    </Typography>
  );

  const lastUpdate = (
    <Typography className={classes.lastUpdate} variant="body1" component="span">
      {translate("contentpicker.lastupdate", {
        date: (
          <LastUpdateDate
            className={classes.lastUpdate}
            date={entity.updateDate}
          />
        ),
        user: entity.updateUser
      })}
    </Typography>
  );

  return (
    <Paper className={rootClassName} variant="outlined">
      <div className={classes.row}>
        <ContentPickerCardStage
          className={classes.stage}
          stage={entity.stage}
        />

        <ContentPickerCardThumbnail
          className={classes.thumbnail}
          entityTypeCode={entity.entityTypeCode}
          translationId={entity.translationId}
          selected={selected}
        />

        <ContentPickerNavigateToDetailButton
          className={classes.navigateButtonContainer}
          entityDetailPageAbsoluteUrl={entity.entityDetailPageAbsoluteUrl}
        />
      </div>

      <div className={classes.summary}>
        {title}
        {slug}
        {culture}
        {lastUpdate}
      </div>
    </Paper>
  );
};
