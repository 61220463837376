import { makeStyles, Typography } from "@material-ui/core";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectGuishellBackofficeUrl } from "redux/selectors/appSelectors";
import { theme } from "theme/theme";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *:first-child": {
      zIndex: 1
    }
  }
}));

export const GuishellDrawer = memo(
  ({ large, textClassName }: { large?: boolean; textClassName?: string }) => {
    const Component: any = "guishell-drawer";
    const url = useSelector(selectGuishellBackofficeUrl);
    const classes = useStyles();

    const Content = () => (
      <>
        <Component
          url={url}
          icon-color={theme.palette.custom.ds.viking.viking700}
        ></Component>
        {large && (
          <Typography component={"span"} className={textClassName ?? ""}>
            My Applications
          </Typography>
        )}
        <span className="MuiTouchRipple-root"></span>
      </>
    );

    return (
      <>
        {!large && (
          <span className={classes.root}>
            <Content />
          </span>
        )}
        {large && <Content />}
      </>
    );
  }
);
