import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import IconButton from "@material-ui/core/IconButton";
import { UxdIcon } from "components/shared/uxdIcon";
import { MouseEvent } from "react";
import { translate } from "utils/i18n";
import Tooltip from "@material-ui/core/Tooltip";

interface IProps extends IBaseProps {
  entityDetailPageAbsoluteUrl: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  navigateToDetailButton: {
    marginTop: theme.spacing(0.25)
  },
  navigateToDetailIcon: {
    fontSize: theme.typography.pxToRem(18)
  }
}));

const ContentPickerNavigateToDetailButton = (props: IProps) => {
  const { className, entityDetailPageAbsoluteUrl } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const tooltipText = translate("contentpicker.navigatetodetail");

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    window.open(entityDetailPageAbsoluteUrl, "_blank");
  };

  return (
    <div className={rootClassName}>
      <Tooltip title={tooltipText}>
        <IconButton
          aria-label="navigate to detail"
          size="small"
          onClick={handleClick}
          className={classes.navigateToDetailButton}
        >
          <UxdIcon
            name="open_in_new"
            className={classes.navigateToDetailIcon}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ContentPickerNavigateToDetailButton;
