import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { LayoutTab } from "components/permissions/detailView/pageElementPermissions/layoutTab";
import { ModuleTab } from "components/permissions/detailView/pageElementPermissions/moduleTab";
import { PageTab } from "components/permissions/detailView/pageElementPermissions/pageTab";
import { TabPanel } from "components/shared/tabPanel";
import { IBaseProps } from "components/_baseProps";
import { IAuthorizationGroup } from "models/authorizationGroup";
import { useState } from "react";
import { translate } from "utils/i18n";
import { tabHeader, tabButton } from "theme/commonClasses";
import { useAuthorizationGroupUsersSelectContext } from "components/permissions/detailView/context/useAuthorizationGroupUsersSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(55) // 440px
  },
  tabHeader: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    paddingTop: theme.spacing(3),
    ...tabHeader
  },
  tabButton,
  tabNotSelected: {},
  tabPanel: {
    margin: theme.spacing(2, 3)
  }
}));

function a11yProps(index: any) {
  return {
    id: `permission-tab-${index}`,
    disableRipple: true,
    "aria-controls": `permission-tabpanel-${index}`
  };
}

interface IProps extends IBaseProps {
  authorizationGroup: IAuthorizationGroup;
}

export function AuthorizationTabs(props: IProps) {
  const { authorizationGroup } = props;
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  const { clearChecked: resetAuthorizationGroupUsersSelected } =
    useAuthorizationGroupUsersSelectContext();

  const handleChange = (_event, newValue: number) => {
    setTab(newValue);
    resetAuthorizationGroupUsersSelected();
  };

  return (
    <section className={classes.root}>
      <Tabs value={tab} onChange={handleChange} className={classes.tabHeader}>
        <Tab
          className={classes.tabButton}
          label={translate("permissions.content.detail.moduletab")}
          {...a11yProps(0)}
        />
        <Tab
          className={classes.tabButton}
          label={translate("permissions.content.detail.layouttab")}
          {...a11yProps(1)}
        />
        <Tab
          className={classes.tabButton}
          label={translate("permissions.content.detail.pagetab.tabname")}
          {...a11yProps(2)}
        />
      </Tabs>
      <TabPanel
        className={classes.tabPanel}
        shadow={false}
        currentIndex={tab}
        index={0}
      >
        <ModuleTab authorizationGroup={authorizationGroup} />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        shadow={false}
        currentIndex={tab}
        index={1}
      >
        <LayoutTab authorizationGroup={authorizationGroup} />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        shadow={false}
        currentIndex={tab}
        index={2}
      >
        <PageTab siteItems={authorizationGroup.siteItems} />
      </TabPanel>
    </section>
  );
}
