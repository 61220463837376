import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { Textbox } from "components/shared/textbox";
import { useContext } from "react";
import { ContentPickerContext } from "./contentPickerContext";
import { translate } from "utils/i18n";
import { ContentPickerLanguageSelect } from "./contentPickerLanguageSelect";
import { ContentPickerOrderBySelect } from "./contentPickerOrderBySelect";
import { useState } from "react";
import ContentPickerLastUpdateDateSelect from "./contentPickerLastUpdateDateSelect";
import ContentPickerSlugTextBox from "./contentPickerSlugTextBox";
import ContentPickerStageSelect from "./contentPickerStageSelect";
import { ContentPickerToggleAdvancedFilterButton } from "./contentPickerToggleAdvancedFilterButton";
import ContentPickerTagsInput from "./contentPickerTagsInput";
import ContentPickerContextInput from "./contentPickerContextInput";
import { TextboxLabel } from "components/shared/textboxLabel";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "50% 3fr 1fr",
    justifyItems: "stretch",
    alignItems: "stretch",
    padding: theme.spacing(2, 1),

    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey97,
    height: theme.spacing(7),
    transition: theme.transitions.create("height"),
    overflow: "hidden",
    "&$open": {
      height: theme.spacing(26)
    }
  },
  row: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    gap: theme.spacing(2)
  },
  gridColumn: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    margin: theme.spacing(0, 1)
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  open: {}
}));

const DEFAULT_DEBOUNCE = 1000;

export const ContentPickerFilters = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();

  const context = useContext(ContentPickerContext);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const openClass = classnames({ [classes.open]: showAdvancedFilters });
  const rootClassName = classnames(classes.root, openClass, className);

  return (
    <div className={rootClassName}>
      <div className={classes.gridColumn}>
        <Textbox
          label="&nbsp;" // A bit ugly, but it leaves the exact needed space
          value={context.queryFilter.terms || ""}
          debounce={true}
          debounceDelay={DEFAULT_DEBOUNCE}
          onChange={(value) => context.setQueryFilter("terms", value)}
          startIcon="search"
          placeholder={translate("contentpicker.filterbykeyword")}
        />
        <ContentPickerTagsInput
          values={context.queryFilter.tags}
          onChange={(values) => context.setQueryFilter("tags", values)}
        />
      </div>
      <div className={classes.gridColumn}>
        <div className={classes.row}>
          <ContentPickerLanguageSelect />
          <ContentPickerOrderBySelect />
        </div>

        <ContentPickerSlugTextBox
          debounceDelayMilliseconds={DEFAULT_DEBOUNCE}
        />
        <ContentPickerContextInput
          value={context.queryFilter.context}
          onChange={(tag) => context.setQueryFilter("context", tag)}
        />
      </div>
      <div className={classes.gridColumn}>
        <div className={classes.flexColumn}>
          {/* A bit ugly, but it leaves the exact needed space */}
          <TextboxLabel value="&nbsp;" />
          <ContentPickerToggleAdvancedFilterButton
            open={showAdvancedFilters}
            onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          />
        </div>
        <ContentPickerLastUpdateDateSelect />
        <ContentPickerStageSelect />
      </div>
    </div>
  );
};
