import {
  AppBar,
  CircularProgress,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import { DeviceSelector } from "components/previewPage/previewBar/deviceSelector";
import { CultureSelect } from "components/previewPage/previewBar/cultureSelect";
import { Logo } from "components/shared/logo";
import { StageIcon } from "components/shared/stageIcon";
import { IBaseProps } from "components/_baseProps";
import { ForgeEntityStage } from "models/forgeEntities";
import { ISiteItem } from "models/siteItem";
import { translate } from "utils/i18n";
import { SiteNodeStage } from "models/siteStructure";
import { useEffect, useState } from "react";
import PreviewPageNotificationListener from "components/previewPage/previewPageNotificationListener";
import { OpenViewPage } from "components/previewPage/previewBar/openViewPage";
import { LandscapeSelect } from "components/previewPage/previewBar/landscapeSelect";

export type Device = "desktop" | "tablet" | "mobile";
interface IProps extends IBaseProps {
  loading: boolean;
  siteItem?: ISiteItem;
  onDeviceChange: (device: Device) => void;
  onLandscapeChange?: () => void;
  device: Device;
  onCultureChange: (culture: string) => void;
  cultures: string[];
  previewUrl?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    maxHeight: "64px",
    minHeight: "48px",
    justifyContent: "space-between",
    background: theme.palette.custom.ds.grayscales.gr900
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    minWidth: "200px"
  },
  title: {
    fontSize: "14px",
    lineHeight: "20px"
  },
  pageInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    minWidth: "200px"
  },
  spinner: {},
  toolsContainer: {
    display: "flex",
    minWidth: "200px",
    justifyContent: "flex-end"
  }
}));

export function PreviewBar(props: IProps) {
  const {
    loading,
    siteItem,
    device,
    onDeviceChange,
    previewUrl,
    onLandscapeChange = () => undefined
  } = props;

  const [status, setStatus] = useState<SiteNodeStage | undefined>();

  useEffect(() => {
    if (siteItem) setStatus(siteItem.status);
  }, [siteItem]);

  const classes = useStyles();

  return (
    <>
      {siteItem && (
        <PreviewPageNotificationListener
          siteItem={siteItem}
          onPagePublished={() => setStatus("Published")}
          onPageUnpublished={() => setStatus("Unpublished")}
          onPageReviewed={() => setStatus("Reviewed")}
        />
      )}

      <AppBar position="sticky">
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <Logo variant="icon" />
            <Typography className={classes.title}>
              {translate("preview.previewTitle")}
            </Typography>
          </div>
          {loading && (
            <div>
              <CircularProgress
                size={24}
                color={"secondary"}
                className={classes.spinner}
              />
            </div>
          )}
          {!loading && (
            <div className={classes.pageInfoContainer}>
              <>
                {status && (
                  <StageIcon stage={status as ForgeEntityStage} size="small" />
                )}
                <Typography className={classes.title}>
                  {siteItem?.path}
                </Typography>
              </>
            </div>
          )}

          <div className={classes.toolsContainer}>
            {device !== "desktop" && (
              <LandscapeSelect onLandscapeChange={onLandscapeChange} />
            )}
            <DeviceSelector device={device} onDeviceChange={onDeviceChange} />
            <CultureSelect
              cultures={props.cultures}
              setCulture={props.onCultureChange}
            />
            <OpenViewPage previewUrl={previewUrl}></OpenViewPage>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default PreviewBar;
