import { IconButton, Typography, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { D3Dropdown } from "components/shared/d3Components";
import {
  ID3DropdownOption,
  toDropdownOption
} from "components/shared/d3Components/d3Dropdown";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  IVariableSortConfig,
  VariableSortingOrder,
  VariableSortingType
} from "models/variables";
import { useCallback, useEffect, useState } from "react";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  label: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20)
  },
  select: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    borderRadius: `${theme.spacing(0.25)}px 0 0 ${theme.spacing(0.25)}px`,
    "& .MuiSelect-root": {
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(24),
      border: "none",
      "&:focus": {
        border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
      }
    }
  },
  option: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.75, 0.5),
    "&.Mui-selected": {
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.custom.ds.viking.viking100
      }
    },
    "&.MuiListItem-root.Mui-selected::before": {
      marginRight: theme.spacing(1),
      color: theme.palette.custom.ds.viking.viking500,
      content: "'✔'"
    }
  },
  button: {
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    borderLeft: "none",
    borderRadius: `0 ${theme.spacing(0.25)}px ${theme.spacing(0.25)}px 0`,
    backgroundColor: theme.palette.common.white,
    fontWeight: 500
  },
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  }
}));

// HACK: cannot generate an array from a union => do it manually
const types: VariableSortingType[] = ["Alphabetical", "Position"];
const options = types.map((type) => toDropdownOption(type));

export interface IVariableSortingProps extends IBaseProps {
  onSortingChange: (sorting: IVariableSortConfig) => void;
  sortConfig: IVariableSortConfig;
}

export const VariablesSorting = (props: IVariableSortingProps) => {
  const { sortConfig, onSortingChange, className } = props;
  const classes = useStyles();

  const [type, setType] = useState<VariableSortingType>(sortConfig.type);
  const [order, setOrder] = useState<VariableSortingOrder>(sortConfig.order);

  const icon = order === "Ascending" ? "arrow_upward" : "arrow_downward";

  const toggleSortOrder = useCallback(
    (): VariableSortingOrder =>
      order === "Ascending" ? "Descending" : "Ascending",
    [order]
  );

  useEffect(() => {
    onSortingChange({
      order,
      type
    });
  }, [onSortingChange, order, type]);

  const onOrderButtonClick = useCallback(
    () => setOrder(toggleSortOrder()),
    [toggleSortOrder]
  );

  const onTypeSelect = useCallback(
    (selected: ID3DropdownOption) =>
      setType(selected.value as VariableSortingType),
    []
  );

  return (
    <div className={className}>
      <Typography className={classes.label}>
        {translate("sitestructure.variables.labels.sorting")}
      </Typography>
      <div className={classes.root}>
        <D3Dropdown
          className={classnames(classes.select, classes.hover)}
          optionClassName={classnames(classes.option, classes.hover)}
          value={type}
          options={options}
          onSelect={onTypeSelect}
          fullWidth
          hideDropdownIcon
        />

        <IconButton
          disableRipple
          className={classnames(classes.button, classes.hover)}
          onClick={onOrderButtonClick}
          size="small"
        >
          <UxdIcon name={icon} />
        </IconButton>
      </div>
    </div>
  );
};

export default VariablesSorting;
