import { makeStyles, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import emptyFolder from "images/empty_permissions_section.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
  },
  image: {
    width: "340px",
    height: "256px",
    marginBottom: theme.spacing(8),
    display: "block",
    background: `url(${emptyFolder})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    mixBlendMode: "multiply",
    lineHeight: "20em",
    textAlign: "center",
    backgroundRepeat: "no-repeat"
  },
  title: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr800,
    paddingBottom: theme.spacing(3)
  },
  subtitle: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr600,
    width: "337px"
  }
}));

export const EmptyAuthorizationGroup = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.image}></div>
      <Typography className={classes.title} variant="h3">
        {translate("permissions.content.empty.title")}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {translate("permissions.content.empty.subtitle")}
      </Typography>
    </div>
  );
};
