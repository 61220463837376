import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    padding: theme.spacing(3, 4)
  },
  rightSection: {
    display: "flex",
    marginLeft: "auto",
    gap: theme.spacing(1)
  },
  hidden: {
    display: "none"
  }
}));
