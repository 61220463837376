import {
  every,
  filter,
  find,
  groupBy,
  includes,
  invoke,
  isEmpty,
  keys,
  map,
  orderBy,
  reduce,
  some,
  uniqBy
} from "lodash";

import {
  IStatusGroupedVariables,
  ITypedVariables,
  IVariableContract,
  IVariableDetail,
  IVariableFilter,
  IVariableModalAction,
  IVariableRouteSuggestion,
  IVariableType,
  VariableStatus,
  IVariableSortConfig,
  VariableSortingType,
  IStandardVariableDetail,
  IURIVariableDetail
} from "models/variables";

import { translate } from "utils/i18n";

// ^[a-z_] => must start with lowercase character or underscore
// [a-z0-9_]*$ => can be followed by any number of characters, numbers & underscores
export const VARIABLE_REGEX = /^[a-z_][a-z0-9_]*$/g;
export const VARIABLE_PREFIX = "@";

// can start & end with _
// no only numbers
// cannot start with number

export type VariableTypeKey =
  | "UriVariable"
  | "KeyValue"
  | "DataList"
  | "DataItem";

export const VariableTypeSortOrder: Record<VariableTypeKey, number> = {
  UriVariable: 1,
  KeyValue: 2,
  DataItem: 3,
  DataList: 4
};

export const VariableTypeEnum: Record<VariableTypeKey, VariableTypeKey> = {
  UriVariable: "UriVariable",
  KeyValue: "KeyValue",
  DataItem: "DataItem",
  DataList: "DataList"
};

export class VariableType {
  static readonly URI_VARIABLE: IVariableType = {
    type: "UriVariable",
    code: "URI"
  };
  static readonly KEY_VALUE: IVariableType = {
    type: "KeyValue",
    code: "KV"
  };
  static readonly DATA_LIST: IVariableType = {
    type: "DataList",
    code: "LST"
  };
  static readonly DATA_ITEM: IVariableType = {
    type: "DataItem",
    code: "ITM"
  };
}

export const VariableTypeCodeEnum: Record<VariableTypeKey, string> = {
  KeyValue: VariableType.KEY_VALUE.code,
  DataItem: VariableType.DATA_ITEM.code,
  DataList: VariableType.DATA_LIST.code,
  UriVariable: VariableType.URI_VARIABLE.code
};

export class KeyValueVariableType {
  static readonly STRING = "System.String";
  static readonly INT = "System.Int32";
  static readonly DATETIME = "System.DateTime";
  static readonly BOOLEAN = "System.Boolean";
  static readonly GUID = "System.Guid";
  static readonly DECIMAL = "System.Decimal";
  static readonly DOUBLE = "System.Double";
}

export enum VariableInputs {
  TEXT,
  INTEGER,
  DATETIME,
  BOOLEAN,
  GUID
}

export type VariableModalActions = "none" | "create" | "edit" | "overwrite";

export const VariableModalAction: Record<
  Uppercase<VariableModalActions>,
  VariableModalActions
> = {
  NONE: "none",
  CREATE: "create",
  EDIT: "edit",
  OVERWRITE: "overwrite"
};

export const isValidVariableFormat = (variable?: string) => {
  const trimmed = variable?.trim() ?? "";
  if (trimmed.length === 0) {
    return false;
  }

  if (!trimmed.startsWith(VARIABLE_PREFIX)) {
    return false;
  }

  const name = trimmed.substring(VARIABLE_PREFIX.length);
  const match = name.match(VARIABLE_REGEX);

  return match != null;
};

export const getAvailableVariableTypesForInput = (
  inputType?: VariableInputs
) => {
  const availableTypes: string[] = [];

  switch (inputType) {
    case VariableInputs.INTEGER: {
      availableTypes.push(KeyValueVariableType.INT);
      break;
    }
    case VariableInputs.BOOLEAN: {
      availableTypes.push(KeyValueVariableType.BOOLEAN);
      break;
    }
  }

  return availableTypes;
};

export const toVariableKey = (
  name: string,
  culture: string,
  environment: string
) => `${name}.${culture}.*.${environment}`;

export const getVariableStatus = (detail: IVariableDetail): VariableStatus => {
  const isStandardDetail = detail.detailType === "STANDARD";
  const isURIDetail = detail.detailType === "URI";

  const hasInheritedValue = isStandardDetail
    ? detail.definedIn !== null && detail.definedIn.value !== null
    : detail.definedIn !== null;

  if (isURIDetail) {
    return hasInheritedValue ? "inherited" : "local";
  }

  const hasLocalValue = isStandardDetail
    ? detail.value !== null
    : !isEmpty(detail.allowedValues);

  if (hasLocalValue && hasInheritedValue) {
    return "overwriting";
  }

  if (hasLocalValue && !hasInheritedValue) {
    return "local";
  }

  return "inherited";
};

export const shouldShowVariable = (
  variable: IVariableContract,
  filter: IVariableFilter
): boolean => {
  const detail = variable.items.find(
    (detail) => detail.culture === "*" && detail.environment === "*"
  );

  if (!detail) {
    return false;
  }

  const status = getVariableStatus(detail);

  if (status === "local" && filter.local) return true;
  if (status === "inherited" && filter.inherited) return true;
  if (status === "overwriting" && filter.overwriting) return true;

  return false;
};

export const getDefaultVariableItem = (
  variable: IVariableContract
): IVariableDetail | undefined => {
  const [culture, environment] = ["*", "*"];
  return find(variable.items, { culture, environment });
};

export const getNonDefaultVariableItems = (variable: IVariableContract) => {
  const [culture, environment] = ["*", "*"];

  return filter(
    variable.items,
    (item) => item.culture !== culture || item.environment !== environment
  );
};

export function categorizeVariables(
  variables: IVariableContract[]
): ITypedVariables[] {
  const types = [
    VariableType.URI_VARIABLE,
    VariableType.KEY_VALUE,
    VariableType.DATA_ITEM,
    VariableType.DATA_LIST
  ];

  const variablesByType = groupBy(variables, "type");
  const toTyped = (type: IVariableType): ITypedVariables => ({
    type,
    variables: variablesByType[type.type] || [] // list must exist to show each type section
  });

  return types.map((type) => toTyped(type)).flat();
}

export const resolveInheritedVariableField = (
  item: IVariableDetail | undefined,
  field: keyof IVariableDetail,
  defaultValue?: any
) => {
  const currentLevel = item && item[field];
  const inherited = item && item.definedIn && item.definedIn[field];

  return currentLevel ?? inherited ?? defaultValue;
};

export const resolveInheritableVariableDetailFieldByStatus = (
  variableDetail: IVariableDetail,
  field: keyof IVariableDetail,
  status: VariableStatus
) => {
  const isInherited = status === "inherited";

  if (isInherited) {
    const definedInPayload = variableDetail.definedIn || {};
    return definedInPayload[field];
  }

  return variableDetail[field];
};

export const getVariableModalOperation = ({
  action
}: IVariableModalAction): { isCreate: boolean; isEdit: boolean } => ({
  isCreate: action === VariableModalAction.CREATE,
  isEdit:
    action === VariableModalAction.EDIT ||
    action === VariableModalAction.OVERWRITE
});

export const getAreAllValuesFilled = (values: string[]) =>
  every(values, (value) => value?.length > 0);

export const getIsVariableNameUnique = (
  variables: IVariableContract[],
  name: string
) => !some(variables, { key: name });

export const getIsCultureEnvironmentCombinationUnique = (
  variable: IVariableContract,
  culture: string,
  environment: string
) =>
  !some(
    filter(variable.items, (item) => getVariableStatus(item) !== "inherited"),
    { culture, environment }
  );

export const getTranslatedModalTitle = (
  modalAction: IVariableModalAction,
  variableType: VariableTypeKey
) => {
  const { action, isTranslateMode } = modalAction;

  const translateModeActionMap = {
    [VariableModalAction.CREATE]:
      "sitestructure.variables.labels.addtranslation",
    [VariableModalAction.EDIT]:
      "sitestructure.variables.languages_overrides.tooltip.edit_translation"
  };

  if (isTranslateMode) {
    return translate(translateModeActionMap[action] ?? "");
  }

  const labelsByTypeMap: Record<VariableTypeKey, string> = {
    UriVariable: "urivariable",
    KeyValue: "addkeyvalue",
    DataItem: "adddataitem",
    DataList: "adddatalist"
  };

  const actionMap = {
    [VariableModalAction.CREATE]: `sitestructure.variables.labels.${labelsByTypeMap[variableType]}`,
    [VariableModalAction.EDIT]: "sitestructure.variables.edit.title",
    [VariableModalAction.OVERWRITE]: "sitestructure.variables.overwrite.title"
  };

  return translate(actionMap[action] ?? "");
};

export const getDataPathSuggestionsOptions = (
  routes: IVariableRouteSuggestion[]
) =>
  orderBy(
    map(uniqBy(routes, "path"), ({ path }) => ({
      value: path,
      label: path
    })),
    "label"
  );

export const getEntityTypeSuggestionsOptions = (
  routes: IVariableRouteSuggestion[]
) =>
  orderBy(
    map(uniqBy(routes, "entityType"), ({ entityType, friendlyName }) => ({
      value: entityType,
      label: friendlyName
    })),
    "label"
  );

export const getCurrentDataPathOption = (
  routes: IVariableRouteSuggestion[],
  path: string
) => {
  const selectedRoute = find(routes, { path });
  const option = {
    value: selectedRoute?.path ?? path,
    label: selectedRoute?.path ?? path
  };

  return option;
};

export const getCurrentEntityTypeOption = (
  routes: IVariableRouteSuggestion[],
  entityType: string
) => {
  const selectedRoute = find(routes, { entityType }) ?? {
    entityType,
    friendlyName: entityType
  };
  const option = {
    value: selectedRoute.entityType,
    label: selectedRoute.friendlyName
  };

  return option;
};

export function groupVariablesByStatus(
  contracts: IVariableContract[]
): IStatusGroupedVariables {
  const variablesWithDefaultDetail = contracts.filter(getDefaultVariableItem);
  const inherited = variablesWithDefaultDetail.filter(
    (variable) =>
      getVariableStatus(getDefaultVariableItem(variable)!) === "inherited"
  );

  const overwriting = variablesWithDefaultDetail.filter(
    (variable) =>
      getVariableStatus(getDefaultVariableItem(variable)!) === "overwriting"
  );

  const local = variablesWithDefaultDetail.filter(
    (variable) =>
      getVariableStatus(getDefaultVariableItem(variable)!) === "local"
  );

  return {
    inherited,
    local,
    overwriting
  };
}

export function orderVariables(
  variables: ITypedVariables[],
  sortConfig: IVariableSortConfig
): ITypedVariables[] {
  // apply sorting for each variable type separately
  return variables.map((typed) => ({
    variables: orderVariableContracts(typed.variables, sortConfig),
    type: typed.type
  }));
}

export function orderVariableContracts(
  contracts: IVariableContract[],
  sortConfig: IVariableSortConfig
): IVariableContract[] {
  const { type, order } = sortConfig;
  const currentSortOrder = order === "Descending" ? "desc" : "asc";

  const OrderByVariableStatusConfig: Record<VariableStatus, number> = {
    local: 1,
    overwriting: 2,
    inherited: 3
  };

  const SortConfig: Record<
    VariableSortingType,
    (contracts: IVariableContract[]) => IVariableContract[]
  > = {
    Alphabetical: (contracts) => orderBy(contracts, "key", currentSortOrder),
    Position: (contracts) => {
      const groupedContractsByStatus = groupBy(contracts, (contract) =>
        getVariableStatus(getDefaultVariableItem(contract)!)
      );

      const orderedKeys = orderBy(
        keys(groupedContractsByStatus),
        (key) => OrderByVariableStatusConfig[key],
        currentSortOrder
      );

      // Per requirement we always order alphabetically in ascending order
      // when ordering by Position

      const reducerFn = (
        allContracts: IVariableContract[],
        contractKey: string
      ) => {
        const contractsForKey = orderBy(
          groupedContractsByStatus[contractKey],
          "key",
          "asc"
        );
        return [...allContracts, ...contractsForKey];
      };

      const orderedContracts = reduce<string, IVariableContract[]>(
        orderedKeys,
        reducerFn,
        []
      );

      return orderedContracts;
    }
  };

  const sorted = invoke(SortConfig, type, contracts);

  return sorted;
}

export const getDataPathSuggestions = (routes: IVariableRouteSuggestion[]) =>
  routes.map((route) => route.path).sort();

export const getEntityTypeForPath = (
  routes: IVariableRouteSuggestion[],
  path: string
) => routes.find((r) => r.path === path)?.entityType ?? "";

export const getFormValue = (eventChangeParam: any) => {
  const target = eventChangeParam?.target;

  if (target) {
    if (target.value) return target.value;
    if (target.innerText) return target.innerText;
    return "";
  }

  return eventChangeParam;
};

export const getIsStandardVariableByVariableType = (
  variableType: VariableTypeKey
) =>
  includes(
    ["KeyValue", "DataItem", "DataList"] as VariableTypeKey[],
    variableType
  );

export const getIsURIVariableByVariableType = (variableType: VariableTypeKey) =>
  variableType === "UriVariable";

const provideVariableDetailWithMissingProperties = (
  incompleteVariableDetail: Partial<IVariableDetail>,
  variableType: VariableTypeKey
) => {
  const isStandardDetail = getIsStandardVariableByVariableType(variableType);

  if (isStandardDetail) {
    const fullVariableDetail: IStandardVariableDetail = {
      culture: incompleteVariableDetail.culture!!,
      environment: incompleteVariableDetail.environment!!,
      detailType: "STANDARD",
      allowRequestOverride: incompleteVariableDetail.allowRequestOverride!!,
      definedIn: incompleteVariableDetail.definedIn ?? null,
      typeFullName: incompleteVariableDetail.typeFullName ?? null,
      value: incompleteVariableDetail.value ?? null
    };

    return fullVariableDetail as IVariableDetail;
  }

  const fullVariableDetail: IURIVariableDetail = {
    culture: "*",
    environment: "*",
    detailType: "URI",
    allowedValues: incompleteVariableDetail.allowedValues ?? [],
    sampleValue: incompleteVariableDetail.sampleValue ?? null,
    allowedDataPathValue: incompleteVariableDetail.allowedDataPathValue ?? null,
    definedIn: incompleteVariableDetail.definedIn ?? null
  };

  return fullVariableDetail as IVariableDetail;
};

export const transformVariableDetailWithType = (
  variables: IVariableContract[]
): IVariableContract[] => {
  return variables.map(({ key, type, items }) => ({
    key,
    type,
    items: items.map((item) =>
      provideVariableDetailWithMissingProperties(item, type as VariableTypeKey)
    )
  }));
};
