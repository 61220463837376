import { call, put } from "redux-saga/effects";
import {
  fetchSystemLanguagesSucceded,
  fetchSystemLanguagesFailed
} from "redux/actions/forgeActions";
import { SystemLanguageApi } from "api/systemLanguages";

export function* getSystemLanguages() {
  try {
    const languages = yield call(SystemLanguageApi.getLanguages);
    yield put(fetchSystemLanguagesSucceded(languages));
  } catch (error) {
    yield put(fetchSystemLanguagesFailed(error));
  }
}
