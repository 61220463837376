import { useMemo } from "react";
import moment from "moment";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteItemHistory } from "models/history";
import { Accordion } from "components/shared/accordion";
import { HistoryEntry } from "components/siteStructure/history/historyEntry";
import { HistoryGroupHeader } from "components/siteStructure/history/historyGroupHeader";
import { SiteNodeType } from "models/siteStructure";

interface IProps extends IBaseProps {
  history: SiteItemHistory;
  nodeId: string;
  nodeType: SiteNodeType;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const HistoryContent = (props: IProps) => {
  const { className, history, nodeId, nodeType } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const items = useMemo(
    () =>
      Object.keys(history.entries).map((datetime, dateIndex) => {
        const entries = history.entries[datetime];
        const label = moment(datetime).format("dddd, DD MMMM YYYY");

        let atLeastOnePublish = false;
        const historyEntries = entries.map((entry, entryIndex) => {
          if (entry.verb === "publish") {
            atLeastOnePublish = true;
          }

          const isCurrent = dateIndex === 0 && entryIndex === 0;
          return (
            <HistoryEntry
              key={entry.id}
              nodeId={nodeId}
              nodeType={nodeType}
              entry={entry}
              isCurrentRevision={isCurrent}
            />
          );
        });

        return (
          <Accordion
            key={`${label}-${historyEntries.length}`}
            label={
              <HistoryGroupHeader text={label} publish={atLeastOnePublish} />
            }
            showDivider={false}
            defaultExpanded={dateIndex === 0}
          >
            {historyEntries}
          </Accordion>
        );
      }),
    [history.entries, nodeId, nodeType]
  );

  return <div className={rootClassName}>{items}</div>;
};
