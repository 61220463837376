import { Chip, Typography } from "@material-ui/core";
import { useStyles } from "components/linkRules/linkRulesSidebar/styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

interface IProps {
  isNewItem: boolean;
  title: string;
}

export const LinkRulesSidebarHeader = (props: IProps) => {
  const { isNewItem, title } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>
        {isNewItem && (
          <Chip
            label={translate("general.new")}
            size="small"
            className={classes.newChip}
          />
        )}
        {!isNewItem && <UxdIcon name="edit" className={classes.headerIcon} />}
        <Typography>{title}</Typography>
      </div>
      <Typography className={classes.hint}>
        {translate("navigationrules.sidebar.hint")}
      </Typography>
    </>
  );
};
