import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import app from "redux/reducers/appReducer";
import apiNotification from "redux/reducers/apiNotificationReducer";
import requestMonitor from "redux/reducers/requestMonitorReducer";
import siteStructure from "redux/reducers/siteStructureReducer";
import assetCatalog from "redux/reducers/assetCatalogReducer";
import command from "redux/reducers/commandReducer";
import rootSaga from "redux/sagas/rootSaga";
import user from "redux/reducers/userReducer";
import frontend from "redux/reducers/frontendReducer";
import forge from "redux/reducers/forgeReducer";
import renameModal from "redux/reducers/siteItem/renameSiteItemReducer";
import createModal from "redux/reducers/siteItem/createSiteItemReducer";
import deleteModal from "redux/reducers/siteItem/deleteSiteItemReducer";
import pasteModal from "redux/reducers/siteItem/pasteSiteItemReducer";
import authorizationGroups from "redux/reducers/authorizationGroupsReducer";
import linkRules from "redux/reducers/linkRulesReducer";

import variables from "redux/reducers/variablesReducer";
import aliases from "redux/reducers/aliasesReducer";

const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = applyMiddleware(sagaMiddleware);

const rootReducer = combineReducers({
  app: app || (() => ({})), // https://stackoverflow.com/questions/43375079/redux-warning-only-appearing-in-tests
  user,
  apiNotification,
  requestMonitor,
  siteStructure,
  assetCatalog,
  command,
  frontend,
  forge,
  renameModal,
  createModal,
  deleteModal,
  pasteModal,
  authorizationGroups,
  linkRules,
  variables,
  aliases
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(storeEnhancers));

sagaMiddleware.run(rootSaga);

export default store;
