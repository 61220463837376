import { createAction } from "redux-actions";
import {
  CreateCustomEntityTagPayload,
  CreateExternalTagPayload,
  SetTagsPickerPropertyPayload
} from "./typings/tagsActions";

export const setTagsPickerProperty = createAction(
  "SET_TAGS_PICKER_PROPERTY",
  (payload: SetTagsPickerPropertyPayload) => payload
);

export const createExternalTag = createAction(
  "CREATE_EXTERNAL_TAG",
  (payload: CreateExternalTagPayload) => payload
);

export const createCustomEntityTag = createAction(
  "CREATE_CUSTOM_ENTITY_TAG",
  (payload: CreateCustomEntityTagPayload) => payload
);
