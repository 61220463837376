import classnames from "classnames";
import { Divider, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { HistoryPaper } from "components/siteStructure/history/historyPaper";
import { HistoryContainer } from "components/siteStructure/history/historyContainer";
import { HistoryHeader } from "components/siteStructure/history/historyHeader";
import { SiteNodeType } from "models/siteStructure";

interface IProps extends IBaseProps {
  nodeId: string;
  nodeType: SiteNodeType;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "50%"
  },
  scrollable: {
    display: "flex",
    flex: 1,
    overflowY: "scroll"
  },
  content: {
    flex: 1
  }
}));

export const History = (props: IProps) => {
  const { className, nodeId, nodeType, open, onClose } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <HistoryPaper
      className={rootClassName}
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <HistoryHeader onCloseClick={onClose} />
      <Divider />
      <div className={classes.scrollable}>
        <HistoryContainer
          className={classes.content}
          nodeId={nodeId}
          nodeType={nodeType}
        />
      </div>
    </HistoryPaper>
  );
};
