import classnames from "classnames";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { useContext } from "react";
import { ContentPickerContext } from "components/contentPicker/contentPickerContext";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: theme.typography.pxToRem(200),
    color: theme.palette.custom.greyscales.backgrounds.grey93
  },
  text: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(32)
  },
  subtext: {
    margin: theme.spacing(1),
    color: theme.palette.custom.greyscales.backgrounds.grey60,
    fontSize: theme.typography.pxToRem(16)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export const ContentPickerEmptyState = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const context = useContext(ContentPickerContext);

  const clearFilters = () => {
    context.clearAllFilters();
  };

  return (
    <div className={rootClassName}>
      <UxdIcon className={classes.icon} name="help_outline" />
      <Typography className={classes.text}>
        {translate("contentpicker.emptystate.text")}
      </Typography>
      <Typography className={classes.subtext}>
        {translate("contentpicker.emptystate.subtext")}
      </Typography>
      <Button
        className={classes.button}
        variant="outlined"
        color="secondary"
        onClick={clearFilters}
      >
        {translate("contentpicker.clearfilters")}
      </Button>
    </div>
  );
};
