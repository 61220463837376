import { IDomainState } from "models/domainStates";
import { createSelector } from "reselect";

export const selectAuthorizationGroupList = (state: IDomainState) =>
  state.authorizationGroups.authorizationGroups;

export const selectCreateAuthorizationGroupFlag = (state: IDomainState) =>
  state.authorizationGroups.createStatus;

export const selectAuthorizationGroup = () =>
  createSelector(
    selectAuthorizationGroupList,
    (_: IDomainState, groupId: string) => groupId,
    (authorizationGroups, groupId) =>
      authorizationGroups.find((group) => group.groupId === groupId)
  );

export const selectAuthorizationGroupUsersUpdatedFlag = (state: IDomainState) =>
  state.authorizationGroups.usersUpdated;
