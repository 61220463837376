import { AssetCatalogApi } from "api/assetCatalog";
import {
  fetchTemplatesSucceded,
  fetchTemplatesFailed
} from "redux/actions/assetCatalogActions";
import { put, call } from "redux-saga/effects";

export function* getTemplates() {
  try {
    const templates = yield call(AssetCatalogApi.getTemplates);
    yield put(fetchTemplatesSucceded(templates));
  } catch (error) {
    yield put(fetchTemplatesFailed(error));
  }
}
