import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { NotFound } from "./notFound";
import { InternalServerError } from "./internalServerError";
import { Forbidden } from "./forbidden";

interface IProps extends IBaseProps {
  error: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1
  }
}));

export const ErrorPage = (props: IProps) => {
  const { className, error } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const errorPage = () => {
    switch (error.toLowerCase()) {
      case "not found":
        return <NotFound className={rootClassName} />;
      case "forbidden":
        return <Forbidden className={rootClassName} />;
      case "internal server error":
        return <InternalServerError className={rootClassName} />;
      default:
        return <InternalServerError className={rootClassName} />;
    }
  };

  return errorPage();
};
