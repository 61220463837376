import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { NoPendingChangesMessage } from "./noPendingChangesMessage";
import { PendingChangesMessage } from "./pendingChangesMessage";
import { AllUnpublishedMessage } from "./allUnpublishedMessage";
import { UnpublishChildrenMessage } from "./unpublishChildrenMessage";
import { DirectoriesApi } from "api/directoriesApi";
import { StagedSiteItem } from "models/directories";
import SiteItemsTable from "components/shared/siteItemsTable";
import { StagingModalHeader } from "components/shared/stagingModalHeader";
import { StagingContext } from "components/contexts/siteItemsStagingContext";

interface IProps extends IBaseProps {
  node: ISiteItem;
  action: "Publish" | "Unpublish";
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: theme.spacing(40),
    flex: 1,
    padding: theme.spacing(3)
  },
  progress: {
    alignSelf: "center",
    margin: "auto"
  },
  items: {
    flex: 1,
    marginTop: theme.spacing(4)
  }
}));

export const StagingSiteItemsContent = (props: IProps) => {
  const { className, node, action } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const stagingContext = useContext(StagingContext);

  const [items, setItems] = useState<StagedSiteItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getItems =
      action === "Publish"
        ? DirectoriesApi.getPendingChanges
        : DirectoriesApi.getPublishedItems;

    getItems(node.nodeId).then((responseItems) => {
      setItems(responseItems);
      setLoading(false);
    });
  }, [node.nodeId, action]);

  // When unpublish items starts all selected
  useEffect(() => {
    if (action === "Unpublish") {
      const selectableItems = items.filter((i) => i.status !== "Unpublished");
      stagingContext.selectMany(selectableItems);
    }
  }, [items, action]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = useCallback(
    (selectedNode: StagedSiteItem) => stagingContext.toggle(selectedNode),
    [stagingContext]
  );

  const handleSelectAll = useCallback(
    (checked: boolean) =>
      checked ? stagingContext.selectMany(items) : stagingContext.unselectAll(),
    [items, stagingContext]
  );

  const canPerformActionOnto = useCallback(
    (current: StagedSiteItem) =>
      action === "Publish"
        ? current.status !== "Published"
        : current.status !== "Unpublished",
    [action]
  );

  const shouldDisableRow = useCallback(
    (currentNode: StagedSiteItem) => {
      const isStagedItem = currentNode.nodeId === stagingContext.stagedId;
      return isStagedItem || !canPerformActionOnto(currentNode);
    },
    [stagingContext.stagedId, canPerformActionOnto]
  );

  const shouldHighlightRow = useCallback(
    (currentNode: StagedSiteItem) =>
      currentNode.nodeId === stagingContext.stagedId,
    [stagingContext.stagedId]
  );

  const Message =
    action === "Publish" ? NoPendingChangesMessage : AllUnpublishedMessage;
  const Warning =
    action === "Publish" ? PendingChangesMessage : UnpublishChildrenMessage;

  const hasOtherStagedItems = useMemo(() => {
    const unwantedStage = action === "Publish" ? "Published" : "Unpublished";
    const stagedItems = items.filter((item) => item.status !== unwantedStage);

    return stagedItems.length > 1;
  }, [items, action]);

  return (
    <div className={rootClassName}>
      <StagingModalHeader node={node} />

      <div className={classes.content}>
        {loading && <CircularProgress className={classes.progress} />}
        {!loading && !hasOtherStagedItems && <Message />}
        {!loading && hasOtherStagedItems && (
          <>
            <Warning />
            <SiteItemsTable
              className={classes.items}
              items={items}
              disableRow={shouldDisableRow}
              showCheckbox={canPerformActionOnto}
              selected={stagingContext.selected}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              highlightRow={shouldHighlightRow}
            />
          </>
        )}
      </div>
    </div>
  );
};
