import { Chip, Typography } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { translate } from "utils/i18n";
import { D3AutoCompleteInput, D3Modal } from "components/shared/d3Components";
import { Textbox } from "components/shared/textbox";
import { IBaseProps } from "components/_baseProps";
import {
  IDataListVariableBody,
  IVariableModalAction,
  ValidationResult
} from "models/variables";
import { toVariableContract } from "components/siteStructure/variables/hooks";
import { VariableContract } from "@d3-forge/forge-commands";
import classNames from "classnames";

import {
  toVariableKey,
  VARIABLE_REGEX,
  VariableType,
  VariableTypeEnum,
  getVariableModalOperation,
  resolveInheritedVariableField,
  getAreAllValuesFilled,
  getTranslatedModalTitle,
  getDataPathSuggestionsOptions,
  getEntityTypeSuggestionsOptions,
  getEntityTypeForPath,
  getCurrentEntityTypeOption,
  getCurrentDataPathOption
} from "utils/variableUtils";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import {
  selectModalAction,
  selectRoutesSuggestions
} from "redux/selectors/variablesSelectors";

import { TextboxLabel } from "components/shared/textboxLabel";
import { filterAutocompleteOptions } from "utils/autocompleteUtils";

interface IProps extends IBaseProps {
  sendVariableCommand: (contract: VariableContract) => void;
  onCloseModal: () => void;
  getModalValidations: (
    name: string,
    culture: string,
    environment: string,
    action: string
  ) => ValidationResult;
}

interface IDataListVariableForm {
  name: string;
  path: string;
  entityType: string;
  environment: string;
  culture: string;
}

const defaultForm: IDataListVariableForm = {
  name: "",
  path: "",
  entityType: "",
  environment: "*",
  culture: "*"
};

const initForm = (modalAction: IVariableModalAction): IDataListVariableForm => {
  const { variable, variableDetail, isTranslateMode } = modalAction;

  const { isCreate } = getVariableModalOperation(modalAction);

  const form: IDataListVariableForm = {
    ...defaultForm,
    name: variable?.key ?? defaultForm.name,
    culture: variableDetail?.culture ?? defaultForm.culture,
    environment: variableDetail?.environment ?? defaultForm.environment,
    path: resolveInheritedVariableField(
      variableDetail,
      "value",
      defaultForm.path
    ),
    entityType: resolveInheritedVariableField(
      variableDetail,
      "typeFullName",
      defaultForm.entityType
    )
  };

  // We invalidate the culture/env in translation mode
  if (isTranslateMode && isCreate) {
    form.culture = "";
    form.environment = "";
  }

  return form;
};

export const DataListVariableModal = (props: IProps) => {
  const { getModalValidations, className, onCloseModal, sendVariableCommand } =
    props;

  const classes = useStyles({ variableType: VariableTypeEnum.DataList });
  const modalAction = useSelector(selectModalAction);
  const [form, setForm] = useState<IDataListVariableForm>(
    initForm(modalAction)
  );

  const [isUnique, setIsUnique] = useState<boolean>(true);

  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] =
    useState<boolean>(false);

  const [
    isCultureEnvironmentCombinationAvailable,
    setIsCultureEnvironmentCombinationAvailable
  ] = useState<boolean>(true);

  const currentType = VariableType.DATA_LIST;
  const routes = useSelector(selectRoutesSuggestions);

  const { action, isTranslateMode } = modalAction;
  const { isCreate, isEdit } = getVariableModalOperation(modalAction);

  const entityTypeSuggestionsOptions = useMemo(
    () => getEntityTypeSuggestionsOptions(routes),
    [routes]
  );

  const dataPathSuggestionsOptions = useMemo(
    () => getDataPathSuggestionsOptions(routes),
    [routes]
  );

  const currentEntityTypeOption = useMemo(
    () => getCurrentEntityTypeOption(routes, form.entityType),
    [routes, form.entityType]
  );

  const currentDataPathOption = useMemo(
    () => getCurrentDataPathOption(routes, form.path),
    [routes, form.path]
  );

  function updateFormField(key: keyof IDataListVariableForm) {
    function updateForm(value: any) {
      setForm((current) => ({ ...current, [key]: value }));
    }

    return updateForm;
  }

  const validate = useCallback(() => {
    const { name, path, culture, environment, entityType } = form;
    const requiredFields = [name, path, entityType, culture, environment];

    const required = getAreAllValuesFilled(requiredFields);

    const { isUnique, isCultureEnvironmentCombinationAvailable } =
      getModalValidations(name, culture, environment, action);

    setIsUnique(isUnique);
    setAreRequiredFieldsFilled(required);
    setIsCultureEnvironmentCombinationAvailable(
      isCultureEnvironmentCombinationAvailable
    );
  }, [form, getModalValidations, action]);

  const confirmLabel = isCreate
    ? translate("sitestructure.variables.labels.confirm")
    : translate("general.save");

  const titleText = getTranslatedModalTitle(modalAction, "DataList");
  const title = (
    <div className={classes.title}>
      <Chip label={currentType.code} size="small" className={classes.chip} />
      <Typography className={classes.titleText}>{titleText}</Typography>
    </div>
  );

  const onConfirm = useCallback(() => {
    const { name, path, culture, environment, entityType } = form;

    const key = toVariableKey(name, culture, environment);
    const body: IDataListVariableBody = {
      Path: path,
      EntityType: entityType
    };

    const contract = toVariableContract(key, currentType, body);

    sendVariableCommand(contract);
    onCloseModal();
  }, [form, currentType, sendVariableCommand, onCloseModal]);

  const onPathSelection = useCallback(() => {
    const { value } = currentDataPathOption;

    if (form.entityType.length !== 0) {
      return;
    }

    const entityType = getEntityTypeForPath(routes, value);

    if (entityType) {
      setForm((current) => ({ ...current, entityType }));
    }
  }, [currentDataPathOption, form, routes]);

  useEffect(() => validate(), [validate]);
  useEffect(() => onPathSelection(), [currentDataPathOption, onPathSelection]);

  return (
    <D3Modal
      className={className}
      open={true}
      modalTitle={title}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      confirmLabel={confirmLabel}
      disabled={
        !isUnique ||
        !areRequiredFieldsFilled ||
        !isCultureEnvironmentCombinationAvailable
      }
    >
      <div className={classes.input}>
        <Textbox
          label={translate("sitestructure.variables.labels.name")}
          className={classes.name}
          value={form.name}
          onChange={updateFormField("name")}
          onBlur={validate}
          disabled={isTranslateMode || isEdit}
          clearIcon={!isEdit}
          pattern={VARIABLE_REGEX.source}
          invalidPatternMessage={translate(
            "sitestructure.variables.add.errors.invalidname"
          )}
        />
        {!isUnique && (
          <Typography className={classes.error}>
            {translate("sitestructure.variables.add.errors.uniquename")}
          </Typography>
        )}
      </div>

      {isTranslateMode && (
        <div>
          <div className={classes.stack}>
            <Textbox
              className={classes.input}
              label={translate("sitestructure.variables.labels.culture")}
              value={form.culture}
              onChange={updateFormField("culture")}
              onBlur={validate}
              isInvalid={!isCultureEnvironmentCombinationAvailable}
              disabled={isEdit}
              clearIcon={!isEdit}
            />

            <Textbox
              className={classes.input}
              label={translate("sitestructure.variables.labels.environment")}
              value={form.environment}
              onChange={updateFormField("environment")}
              onBlur={validate}
              isInvalid={!isCultureEnvironmentCombinationAvailable}
              disabled={isEdit}
              clearIcon={!isEdit}
            />
          </div>
          {!isCultureEnvironmentCombinationAvailable && (
            <Typography
              className={classNames(classes.error, classes.errorPositioner)}
            >
              {translate(
                "sitestructure.variables.add.errors.invalid_culture_environment_combo"
              )}
            </Typography>
          )}
        </div>
      )}

      <TextboxLabel value={translate("sitestructure.variables.labels.path")} />
      <D3AutoCompleteInput
        options={dataPathSuggestionsOptions}
        value={currentDataPathOption}
        className={classNames(classes.input, classes.autocompleteInput)}
        placeholder=""
        fullWidth={true}
        onSubmit={validate}
        onInputChange={(option) => updateFormField("path")(option.value)}
        filterOptions={(options) =>
          filterAutocompleteOptions(options, currentDataPathOption.label)
        }
      />

      <TextboxLabel
        value={translate("sitestructure.variables.labels.entitytype")}
      />
      <D3AutoCompleteInput
        options={entityTypeSuggestionsOptions}
        value={currentEntityTypeOption}
        className={classNames(classes.input, classes.autocompleteInput)}
        placeholder=""
        fullWidth={true}
        onSubmit={validate}
        onInputChange={(option) => updateFormField("entityType")(option.value)}
        filterOptions={(options) =>
          filterAutocompleteOptions(options, currentEntityTypeOption.label)
        }
      />
    </D3Modal>
  );
};

export default DataListVariableModal;
