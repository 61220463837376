import classnames from "classnames";
import { Avatar, Button, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useContext } from "react";
import { ContentPickerContext } from "components/contentPicker/contentPickerContext";

interface IProps extends IBaseProps {
  open: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    padding: theme.spacing(0.75, 2),
    "&$highlight": {
      borderColor: theme.palette.custom.mainAccent.blue
    }
  },
  avatar: {
    margin: theme.spacing(0, 1),
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custom.mainAccent.blue,
    transition: theme.transitions.create("opacity"),
    "&$hidden": {
      opacity: 0
    }
  },
  icon: {
    transition: theme.transitions.create("transform"),
    marginLeft: "unset",
    "&$open": {
      transform: "rotate(-180deg)"
    }
  },
  highlight: {},
  open: {},
  hidden: {}
}));

export const ContentPickerToggleAdvancedFilterButton = (props: IProps) => {
  const { className, open, onClick } = props;
  const classes = useStyles();

  const { advancedFiltersCount } = useContext(ContentPickerContext);

  const rootClassName = classnames(
    classes.root,
    {
      [classes.open]: open,
      [classes.highlight]: !open && advancedFiltersCount > 0
    },
    className
  );
  const iconClassName = classnames(classes.icon, {
    [classes.open]: open
  });
  const avatarClassName = classnames(classes.avatar, {
    [classes.hidden]: open || advancedFiltersCount === 0
  });

  return (
    <Button
      variant="outlined"
      className={rootClassName}
      endIcon={<UxdIcon className={iconClassName} name={"expand_more"} />}
      onClick={onClick}
    >
      {translate("contentpicker.filters")}
      <Avatar className={avatarClassName}>{advancedFiltersCount}</Avatar>
    </Button>
  );
};
