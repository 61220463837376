import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { useSlidebarResize } from "components/hooks";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  children: React.ReactNode;
  open: boolean;
  onToggle: () => void;
  handlePosition: "left" | "right";
  handleText?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  content: {
    display: "flex",
    overflow: "auto",
    transition: "width 0.3s ease-out",
    willChange: "auto",
    "-webkit-box-orient": "vertical",
    "-webkit-box-direction": "normal",
    maxWidth: "60vw",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "50vw"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "30vw"
    }
  },
  collapsed: {
    width: "0 !important"
  },
  handleIcon: {
    fontSize: theme.typography.pxToRem(24),
    margin: theme.spacing(1.5, 0),
    color: theme.palette.custom.ds.grayscales.gr700,
    height: theme.typography.pxToRem(24)
  },
  rotate180: {
    transform: "rotate(180deg)"
  },
  handle: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    display: "flex",
    justifyContent: "start",
    width: theme.spacing(3),
    padding: theme.spacing(1, 0.5),
    cursor: "pointer"
  },
  handleText: {
    lineHeight: 1.4,
    pointerEvents: "none",
    color: theme.palette.custom.ds.grayscales.gr700
  },
  dragSection: {
    width: "100%",
    flex: "1 1 50%",
    cursor: "col-resize"
  },
  toggleSection: {
    flex: "1 1 20%",
    display: "flex",
    marginTop: theme.spacing(1),
    flexDirection: "column"
  },
  dragIcon: {
    fontSize: theme.typography.pxToRem(24),
    margin: theme.spacing("auto", 0),
    color: theme.palette.custom.ds.grayscales.gr500,
    height: theme.typography.pxToRem(24)
  }
}));

export const CollapsiblePanel = (props: IProps) => {
  const { className, children, open, onToggle, handlePosition } = props;
  const classes = useStyles(props);
  const [sidebar, resizer] = useSlidebarResize(onToggle);

  const contentClassName = classnames(
    classes.content,
    {
      [classes.collapsed]: !open
    },
    className
  );

  const handleIconClassName = classnames(classes.handleIcon, {
    [classes.rotate180]: !open
  });

  const handleIconName = handlePosition === "left" ? "last_page" : "first_page";
  const tooltip = translate("sitestructure.toggle_section_tooltip");

  const handle = (
    <div className={classes.handle} ref={resizer}>
      <span className={classes.toggleSection} title={tooltip}>
        <UxdIcon className={handleIconClassName} name={handleIconName} />
        <UxdIcon className={classes.dragIcon} name="drag_indicator" />
      </span>
      <span className={classes.dragSection} />
    </div>
  );

  return (
    <div className={classes.root}>
      {handlePosition === "left" && handle}
      <div ref={sidebar} className={contentClassName}>
        {children}
      </div>
      {handlePosition === "right" && handle}
    </div>
  );
};
