import {
  fetchVersionSucceded,
  fetchVersionFailed,
  setCurrentRoute,
  openDrawer,
  closeDrawer,
  setConfiguration
} from "redux/actions/appActions";
import { reducerFactory } from "./coreReducer";
import { IAppState, ICurrentRouteState } from "models/domainStates";
import { Action } from "redux-actions";
import { dashboardRoute } from "routes/routes";
import { IConfiguration } from "models/configuration";

export const initialState: IAppState = {
  version: "",
  drawerOpen: false,
  currentRoute: {
    name: dashboardRoute.name,
    path: dashboardRoute.path
  },
  configuration: {
    notificationDispatcherBaseUrl: "",
    guishellBackofficeUrl: ""
  }
};

const reactions = {
  [fetchVersionSucceded.toString()]: (
    state: IAppState,
    action: Action<string>
  ) => {
    state.version = action.payload;
  },
  [fetchVersionFailed.toString()]: (state: IAppState) => {
    state.version = "not available";
  },
  [openDrawer.toString()]: (state: IAppState) => {
    state.drawerOpen = true;
  },
  [closeDrawer.toString()]: (state: IAppState) => {
    state.drawerOpen = false;
  },
  [setCurrentRoute.toString()]: (
    state: IAppState,
    action: Action<ICurrentRouteState>
  ) => {
    state.currentRoute = action.payload;
  },
  [setConfiguration.toString()]: (
    state: IAppState,
    action: Action<IConfiguration>
  ) => {
    state.configuration = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
