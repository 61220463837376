import { filter } from "lodash";
import { IVariableState } from "models/domainStates";
import {
  IVariableContract,
  IVariableRouteSuggestion,
  VariableDetailType
} from "models/variables";
import { Action } from "redux-actions";
import {
  AddPageVariablePayload,
  LoadVariablesModalActionPayload,
  LoadPageVariablesPayload,
  LoadVariableSuggestionsPayload
} from "redux/actions/typings/variableActions";
import {
  addPageVariable,
  loadVariablesModalAction,
  loadPageVariables,
  loadVariableSuggestions,
  fetchRoutesSucceded
} from "redux/actions/variablesActions";
import { reducerFactory } from "redux/reducers/coreReducer";
import {
  getIsStandardVariableByVariableType,
  getIsURIVariableByVariableType,
  VariableModalAction,
  VariableTypeKey
} from "utils/variableUtils";

export const initialState: IVariableState = {
  suggestions: [],
  contracts: [],
  modalAction: { action: VariableModalAction.NONE, type: "" },
  routes: []
};

const reactions = {
  [loadPageVariables.toString()]: (
    state: IVariableState,
    action: Action<LoadPageVariablesPayload>
  ) => {
    const { detailType, variables } = action.payload;
    const { contracts } = state;

    const FilterPredicateMethodMapByType: Record<
      VariableDetailType,
      (contract: IVariableContract) => boolean
    > = {
      URI: (contract) =>
        getIsStandardVariableByVariableType(contract.type as VariableTypeKey),
      STANDARD: (contract) =>
        getIsURIVariableByVariableType(contract.type as VariableTypeKey)
    };

    const filteredCurrentContracts = filter(
      contracts,
      FilterPredicateMethodMapByType[detailType]
    );

    state.contracts = [...filteredCurrentContracts, ...variables];

    return state;
  },
  [addPageVariable.toString()]: (
    state: IVariableState,
    action: Action<AddPageVariablePayload>
  ) => {
    state.contracts = [...state.contracts, ...action.payload.variables];

    return state;
  },
  [loadVariableSuggestions.toString()]: (
    state: IVariableState,
    action: Action<LoadVariableSuggestionsPayload>
  ) => {
    state.suggestions = [...action.payload.variables];

    return state;
  },
  [loadVariablesModalAction.toString()]: (
    state: IVariableState,
    action: Action<LoadVariablesModalActionPayload>
  ) => {
    state.modalAction = action.payload;

    return state;
  },
  [fetchRoutesSucceded.toString()]: (
    state: IVariableState,
    action: Action<IVariableRouteSuggestion[]>
  ) => {
    state.routes = action.payload;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
