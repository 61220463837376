import { call, put } from "redux-saga/effects";
import { UserApi } from "api/userApi";
import { setUser } from "redux/actions/userActions";

export function* getUserData() {
  try {
    const userData = yield call(UserApi.getUser);

    yield put(setUser(userData));
  } catch (error) {
    console.error(error);
  }
}
