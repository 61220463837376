import LinkRuleCondition from "components/linkRules/linkRuleConditions/linkRuleCondition";
import { D3Button } from "components/shared/d3Components";
import { translate } from "utils/i18n";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";

interface LinkRuleConditionsProps {
  disabled?: boolean;
}

export default function LinkRuleConditions({
  disabled = false
}: Readonly<LinkRuleConditionsProps>) {
  const { form, onAddProperty } = useLinkRulesCrudManagementContext();

  const { properties } = form;

  return (
    <div>
      {properties.map((property) => (
        <LinkRuleCondition
          key={property.id}
          property={property}
          disabled={disabled}
        />
      ))}
      <D3Button
        variant="ghost"
        color="secondary"
        icon="add_circle"
        onClick={onAddProperty}
        disabled={disabled}
      >
        {translate("navigationrules.sidebar.add_condition")}
      </D3Button>
    </div>
  );
}
