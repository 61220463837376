import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

// ICON SET:
// https://materialdesignicons.com/

interface IProps extends IBaseProps {
  name: string;
  rotate?: 45 | 90 | 135 | 180 | 225 | 270 | 315;
  flip?: "horizontal" | "vertical";
  spin?: boolean;
  outline?: boolean;
  hidden?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    lineHeight: 1,
    "&:before": {
      display: "block"
    }
  },
  "@global": {
    "@keyframes spin": {
      from: {
        transform: "rotate(0deg)"
      },
      to: {
        transform: "rotate(-360deg)"
      }
    }
  },
  spin: (props: IProps) => {
    if (props.spin) {
      return {
        animation: "1s cubic-bezier(.75, .2, .5, .8) infinite spin"
      };
    }
    return {};
  },

  rotate: (props: IProps) => {
    return {
      transform: `rotate(${props.rotate || 0}deg)`
    };
  },
  flip: (props: IProps) => {
    if (!props.flip) {
      return {};
    }
    return {
      transform: `rotate${props.flip === "horizontal" ? "X" : "Y"}(180deg)`
    };
  },
  hidden: (props: IProps) => {
    if (props.hidden) {
      return {
        display: "none"
      };
    }
    return {};
  }
}));

const ICON_SET_NAME = "material-icons";
const PREFIX = `${ICON_SET_NAME}-`;
const OUTLINE_SUFFIX = "outlined";

export const UxdIcon = React.forwardRef<HTMLSpanElement, IProps>(
  (props, ref) => {
    const {
      className,
      name,
      rotate,
      flip,
      spin,
      outline = true,
      hidden = false
    } = props;

    if (flip && rotate) {
      console.error(
        "[UxdIcon] 'flip' and 'rotate' cannot be used on the same element at the same time"
      );
    }

    const classes = useStyles(props);

    const mainClass = `${PREFIX}${outline ? OUTLINE_SUFFIX : ""}`;

    const rootClassName = classnames(
      classes.root,
      mainClass,
      ICON_SET_NAME,
      {
        [classes.rotate]: Boolean(rotate),
        [classes.flip]: Boolean(flip),
        [classes.spin]: Boolean(spin),
        [classes.hidden]: Boolean(hidden)
      },
      className
    );

    return (
      <span ref={ref} className={rootClassName}>
        {name}
      </span>
    );
  }
);

// Why forwardRef?
// Material-UI tooltip needs to access to the reference of the child element
// More details: https://material-ui.com/guides/composition/#caveat-with-refs
