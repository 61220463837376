import { Fragment, useMemo } from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { StageIcon } from "components/shared/stageIcon";
import { ForgeEntityStage } from "models/forgeEntities";

interface IProps extends IBaseProps {
  search: string;
  value: string;
  stage?: ForgeEntityStage;
  isFolder: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0.25, 0)
  },
  text: {
    fontSize: theme.typography.pxToRem(13)
  },
  icon: {
    color: theme.palette.custom.greyscales.backgrounds.grey64,
    fontSize: theme.typography.pxToRem(20),
    marginLeft: theme.spacing(1)
  },
  background: {
    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey97
  },
  stageIcon: {
    marginRight: theme.spacing(1)
  }
}));

export const TagsPickerSuggestionItem = (props: IProps) => {
  const { className, search, stage = "published", value, isFolder } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    {
      [classes.background]: isFolder
    },
    className
  );

  const highlightRegex = useMemo(
    () => new RegExp(`(${search})`, "i"),
    [search]
  );
  const segments = value.split(highlightRegex);

  const formatted = segments.map((s, index) => (
    <Fragment key={`${s}-${index}`}>
      {index % 2 === 0 ? s : <strong>{s}</strong>}
    </Fragment>
  ));

  return (
    <span className={rootClassName}>
      {!isFolder && stage !== "published" && (
        <StageIcon className={classes.stageIcon} stage={stage} size="small" />
      )}
      <Typography className={classes.text}>{formatted}</Typography>
      {isFolder && <UxdIcon className={classes.icon} name="chevron_right" />}
    </span>
  );
};
