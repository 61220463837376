import { find, first, isEmpty, map, some } from "lodash";
import {
  IURLMetadataForm,
  IUrlMetadataFormUpdate
} from "components/siteStructure/aliases/types";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  createUrlMetadataFormByCulture,
  getUpdatedUrlMetadataForm,
  getUpdatedUrlMetadataFormsAfterTranslationRestore,
  getUrlMetadataFormUpdates
} from "components/siteStructure/aliases/utils";
import { useTranslationUrlHierarchy } from "components/siteStructure/aliases/hooks";
import { ISiteItem } from "models/siteItem";
import {
  ICommand,
  SetUriSegmentTranslation,
  UnsetUriSegmentTranslation
} from "@d3-forge/forge-commands";
import { splitPathSegments } from "utils/pathUtils";

interface ConfigProps {
  path: string;
  currentSiteItem: ISiteItem;
  allCultures: string[];
  isOverlayPortalOpened: boolean;
  unsetTranslate: any;
  saveSettings: any;
}

export interface IUrlTranslationManagement {
  urlMetadataForms: IURLMetadataForm[];
  urlMetadataFormUpdates: IUrlMetadataFormUpdate[];
  canSaveTranslations: boolean;
  getIsEntryLoading: (culture: string) => boolean;
  getHasUpdateForCulture: (culture: string) => boolean;
  onUpdateUrlTranslation: (culture: string, value: string) => void;
  onUnsetTranslation: (culture: string) => void;
  getSaveTranslationCommands: () => ICommand[];
}

interface UrlTranslationFormSyncMetadata {
  isSynced: boolean;
  currentPath: string;
}

const initUrlTranslationFormSyncMetadata = (
  path: string
): UrlTranslationFormSyncMetadata => ({
  currentPath: path,
  isSynced: false
});

export function useUrlTranslations({
  path,
  currentSiteItem,
  allCultures,
  unsetTranslate,
  saveSettings,
  isOverlayPortalOpened
}: ConfigProps): IUrlTranslationManagement {
  const paths = useTranslationUrlHierarchy(currentSiteItem);
  const [urlMetadataForms, setUrlMetadataForms] = useState<IURLMetadataForm[]>(
    []
  );

  const [formSyncMetadata, setFormSyncMetadata] =
    useState<UrlTranslationFormSyncMetadata>(
      initUrlTranslationFormSyncMetadata(currentSiteItem.path)
    );

  const [unsettingTranslationCulture, setUnsettingTranslationCulture] =
    useState<string | undefined>(undefined);

  const isUnsetTranslationLoading = unsetTranslate.isLoading;
  const isSetTranslationLoading = saveSettings.isLoading;

  const urlMetadataFormUpdates = useMemo(
    () => getUrlMetadataFormUpdates(urlMetadataForms, paths),
    [urlMetadataForms, paths]
  );

  const onUpdateUrlTranslation = useCallback(
    (culture: string, value: string) => {
      setUrlMetadataForms((current) =>
        map(current, (form) =>
          form.culture === culture
            ? getUpdatedUrlMetadataForm(form, value)
            : form
        )
      );
    },
    [setUrlMetadataForms]
  );

  const getIsEntryLoading = useCallback(
    (culture: string) => {
      const isEntrySettingTranslation =
        some(urlMetadataFormUpdates, { culture }) && isSetTranslationLoading;

      const isEntryUnsettingTranslation =
        unsettingTranslationCulture === culture && isUnsetTranslationLoading;

      return isEntrySettingTranslation || isEntryUnsettingTranslation || false;
    },
    [
      urlMetadataFormUpdates,
      unsettingTranslationCulture,
      isSetTranslationLoading,
      isUnsetTranslationLoading
    ]
  );

  const getHasUpdateForCulture = useCallback(
    (culture: string) => some(urlMetadataFormUpdates, { culture }),
    [urlMetadataFormUpdates]
  );

  const getSaveTranslationCommands = useCallback(
    () =>
      map(
        urlMetadataFormUpdates,
        (batchedUpdate) =>
          new SetUriSegmentTranslation({
            itemId: currentSiteItem.nodeId,
            culture: batchedUpdate.culture,
            value: String(batchedUpdate.translationValue)
          })
      ),
    [urlMetadataFormUpdates, currentSiteItem]
  );

  const onUnsetTranslation = useCallback(
    async (culture: string) => {
      //just being defensive here

      if (culture === "*") {
        return;
      }
      const form = find(urlMetadataForms, { culture });

      if (form === undefined) {
        return;
      }

      const command = new UnsetUriSegmentTranslation({
        itemId: currentSiteItem.nodeId,
        culture: form.culture
      });

      setUnsettingTranslationCulture(culture);
      await unsetTranslate.mutateAsync(command);
      setUnsettingTranslationCulture(undefined);

      const updatedUrlMetadataForms =
        getUpdatedUrlMetadataFormsAfterTranslationRestore({
          paths,
          urlMetadataForms,
          culture: form.culture
        });

      setUrlMetadataForms(updatedUrlMetadataForms);
    },
    [
      urlMetadataForms,
      setUnsettingTranslationCulture,
      currentSiteItem,
      unsetTranslate,
      paths
    ]
  );

  const canSaveTranslations = useMemo(() => {
    const someEmpty = urlMetadataFormUpdates.some(
      (x) => x.translationValue?.trim().length === 0
    );

    return !someEmpty;
  }, [urlMetadataFormUpdates]);

  useEffect(() => {
    if (formSyncMetadata.isSynced) {
      return;
    }

    const urlMetadataForms = map(allCultures, (culture) =>
      createUrlMetadataFormByCulture(culture, paths)
    );

    const arePathsLoading = first(paths)?.["*"].includes("Loading");
    const isCorrectPathsMetadataProvided =
      paths.length === splitPathSegments(path).length;

    const shouldMarkFormsAsSynced =
      !isEmpty(allCultures) &&
      !arePathsLoading &&
      isCorrectPathsMetadataProvided;

    setUrlMetadataForms(urlMetadataForms);

    if (shouldMarkFormsAsSynced) {
      setFormSyncMetadata((current) => ({ ...current, isSynced: true }));
    }
  }, [allCultures, paths, formSyncMetadata, path]);

  useEffect(() => {
    setFormSyncMetadata(initUrlTranslationFormSyncMetadata(path));
    setUrlMetadataForms([]);
  }, [path]);

  return {
    urlMetadataForms,
    urlMetadataFormUpdates,
    canSaveTranslations,
    getHasUpdateForCulture,
    getIsEntryLoading,
    onUnsetTranslation,
    onUpdateUrlTranslation,
    getSaveTranslationCommands
  };
}
