import { ISiteItem } from "models/siteItem";
import { Action } from "redux-actions";
import {
  closeRenameModal,
  openRenameModal
} from "redux/actions/siteItem/renameSiteItem";
import { reducerFactory } from "redux/reducers/coreReducer";

export interface IRenameSiteItemState {
  open: boolean;
  node: ISiteItem | null;
}

export const initialState: IRenameSiteItemState = {
  open: false,
  node: null
};

const reactions = {
  [openRenameModal.toString()]: (
    state: IRenameSiteItemState,
    action: Action<ISiteItem>
  ) => {
    state.open = true;
    state.node = action.payload;

    return state;
  },

  [closeRenameModal.toString()]: (state: IRenameSiteItemState) => {
    state.open = false;
    state.node = null;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
