import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    "@global": {
      "@keyframes popup_container": {
        "0%": {
          transform: "translateX(50vw)"
        },
        "100%": {
          transform: "translateX(0vw)"
        }
      }
    },
    animation: "0.2s ease-in 1 $popup_container"
  },
  header: {
    backgroundColor: "white",
    height: theme.typography.pxToRem(72),
    display: "flex",
    alignItems: "center",
    padding: "0px 32px",
    justifyContent: "space-between"
  },
  body: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    flex: 1,
    overflowY: "auto"
  },
  settingsIcon: {
    width: "19.45px",
    height: "20px",
    pointerEvents: "none"
  },
  headerLeft: {
    display: "flex",
    position: "relative",
    top: "-3px"
  },
  headerRight: {
    display: "flex"
  },
  titleText: {
    padding: "0px 18px",
    height: theme.typography.pxToRem(24),
    position: "relative",
    top: -2,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr900
  },
  closeIcon: {
    color: theme.palette.custom.ds.grayscales.gr600,
    cursor: "pointer",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.custom.ds.grayscales.gr700
    }
  },
  infoContainer: {
    borderRadius: "4px",
    padding: "0px 8px",
    userSelect: "none",
    border: "1px solid transparent",
    display: "flex",
    position: "relative",
    alignItems: "center",
    transition: "border-color .3s ease-out",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #f1f1f1"
    }
  },
  infoIcon: {
    width: theme.typography.pxToRem(16.67),
    height: theme.typography.pxToRem(16.67),
    color: theme.palette.custom.ds.info.info500,
    display: "flex",
    alignItems: "center"
  },
  infoDescription: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(12),
    padding: "0px 12px",
    paddingRight: "2rem",
    fontStyle: "italic"
  },
  infoTooltipContainer: {
    width: "206px",
    padding: "8px 8px",
    color: theme.palette.custom.ds.grayscales.gr300,
    "& h4": {
      margin: 0,
      padding: 0
    },
    "& p": {
      marginBottom: "8px",
      fontSize: theme.typography.pxToRem(12)
    }
  },
  footer: {
    position: "relative",
    padding: "23px 32px",
    boxSizing: "border-box",
    borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    backgroundColor: "white",
    height: theme.typography.pxToRem(88),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@global": {
      "@keyframes popup_footer": {
        "0%": {
          transform: "translateY(80px)"
        },
        "100%": {
          transform: "translateY(0px)"
        }
      }
    },
    animation: "0.2s ease-out 1 $popup_footer"
  },
  warningSection: {
    flex: 1,
    display: "flex",
    alignItems: "center"
  },
  warningIcon: {
    color: theme.palette.custom.ds.warning.warning500
  },
  errorTheme: {
    color: theme.palette.custom.ds.error.error500 + " !important"
  },
  warningSectionLeft: {
    width: "40px"
  },
  warningSectionRight: {
    "& small": {
      color: theme.palette.custom.ds.grayscales.gr700,
      fontSize: theme.typography.pxToRem(14)
    }
  },
  warningSectionDescription: {
    lineHeight: "24px",
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500
  },
  actionsSection: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    gap: "1rem"
  },
  saveButton: {}
}));
