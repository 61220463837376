import { ILinkRulesEntityList } from "models/linkRules";
import { createAction } from "redux-actions";

export const loadLinkRulesEntityList = createAction(
  `LOAD_LINK_RULES_ENTITY_LIST`,
  (entities: ILinkRulesEntityList) => entities
);

export const setSelectedLinkRuleId = createAction(
  "SET_SELECTED_LINK_RULE_ID",
  (id: string | null) => id
);
