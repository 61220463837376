import { ScrollSpyElem } from "components/shared/scrollspy";
import MetadataCategory from "./metadataCategory";
import { IBaseProps } from "components/_baseProps";
import { IMetadataCategory } from "models/metadata";
import { ISiteItem } from "models/siteItem";
import { Divider, makeStyles } from "@material-ui/core";
import React from "react";

interface IProps extends IBaseProps {
  categories: ScrollSpyElem<IMetadataCategory>[];
  siteItem: ISiteItem;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(5, 0)
  }
}));

export function MetadataSection(props: IProps) {
  const { categories, siteItem } = props;
  const classes = useStyles();

  return (
    <div className={props.className}>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <MetadataCategory category={category} siteItem={siteItem} />

          {index < categories.length - 1 && (
            <Divider className={classes.divider} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
