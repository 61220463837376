import { useRemoveAuthGroup } from "api/queryHooks";
import { useDispatch, useSelector, batch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  removeAuthorizationGroup,
  setCreateAuthGroupFlag
} from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { selectCreateAuthorizationGroupFlag } from "redux/selectors/authorizationGroupSelectors";
import { PERMISSIONS } from "routes/paths";
import { useState } from "react";

export const CREATE_URL = `${PERMISSIONS}/create`;

export function useCreateAuth() {
  const history = useHistory();
  const dispatch = useDispatch();

  const addNew = useSelector(selectCreateAuthorizationGroupFlag);

  const handleAddNew = () => {
    dispatch(setCreateAuthGroupFlag(true));
    history.replace(CREATE_URL);
  };

  return {
    isAddNewStatus: addNew,
    handleAddNew
  };
}

export function useRemoveAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const request = useRemoveAuthGroup();
  const [removeDialog, setRemoveDialog] = useState<boolean>(false);
  const [removeAuthId, setRemoveAuthId] = useState<string>("");
  const route = useRouteMatch<{ authGroupId: string }>(
    `${PERMISSIONS}/:authGroupId`
  );

  const handleClose = () => {
    setRemoveDialog(false);
  };

  const handleRemove = (e: React.MouseEvent, groupId: string) => {
    e.preventDefault();
    setRemoveDialog(true);
    setRemoveAuthId(groupId);
  };

  const handleDelete = () => {
    setRemoveDialog(false);

    request.mutate(removeAuthId, {
      onSuccess: () => {
        route?.params.authGroupId === removeAuthId ??
          history.replace(PERMISSIONS);
        batch(() => {
          dispatch(removeAuthorizationGroup(removeAuthId));
          dispatch(
            displayNotification({
              message: "notification.removeauthgroup.success",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        displayNotification({
          message: "notification.removeauthgroup.error",
          severity: "error"
        });
      }
    });
  };

  return {
    removeDialog,
    handleRemove,
    handleClose,
    handleDelete
  };
}
