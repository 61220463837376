import { makeStyles, Typography } from "@material-ui/core";
import { PermissionCodes } from "catalogs/permissionCodes";
import { ChangeTemplateContext } from "components/contexts/changeTemplateContext";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import { D3Button } from "components/shared/d3Components";
import { IBaseProps } from "components/_baseProps";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import {
  openSidebar,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";
import { userService } from "services/userService";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  template: ITemplateDefinition | undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: theme.spacing(5),
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginLeft: "auto"
  },
  templateName: {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  actions: {
    marginRight: theme.spacing(2)
  }
}));

export const TemplateActionsBar = (props: IProps) => {
  const { template } = props;
  const classes = useStyles();
  const hasTemplate = template?.id !== "No Template";
  const openChangeTemplateDialog = useContext(ChangeTemplateContext);
  const currentSiteItemPath = useContext(CurrentSiteItemContext);

  const hasViewPermission = userService.hasPermissions(
    PermissionCodes.ViewContent
  );
  const isAllowed =
    userService.hasPermissions(PermissionCodes.EditContent) ||
    userService.hasSiteItemPermissions(currentSiteItemPath);

  const dispatch = useDispatch();

  const dispatchOpenSidebar = () => {
    dispatch(openSidebar());
    dispatch(unsetSelectedPageElement());
  };

  return (
    <div className={classes.root}>
      {hasViewPermission && (
        <>
          <Typography
            className={classes.templateName}
            variant="body2"
            component="span"
          >
            {translate("sitestructure.template")}:
          </Typography>
          <D3Button
            variant="ghost"
            color="secondary"
            icon="edit"
            onClick={openChangeTemplateDialog}
            children={template?.label}
            size="small"
            iconPosition="right"
            disabled={!isAllowed}
            className={classes.actions}
          />

          {hasTemplate && (
            <D3Button
              variant="ghost"
              color="secondary"
              icon="settings"
              onClick={dispatchOpenSidebar}
              children={translate("sitestructure.properties")}
              size="small"
              iconPosition="right"
              disabled={!isAllowed}
              className={classes.actions}
            />
          )}
        </>
      )}
    </div>
  );
};
