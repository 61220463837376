import { Action } from "redux-actions";
import { IApiNotificationState } from "models/domainStates";
import {
  closeApiNotificationSnackbar,
  displayNotification
} from "redux/actions/notificationActions";

export const initialState: IApiNotificationState = {
  message: "",
  show: false,
  severity: "error"
};

export default function reducer(
  state: IApiNotificationState | undefined,
  action: Action<any>
) {
  let nextState = state || initialState;
  switch (action.type) {
    case displayNotification(action.payload).type:
      return {
        ...nextState,
        message: action.payload.message,
        show: true,
        severity: action.payload.severity || "error"
      };
    case closeApiNotificationSnackbar().type:
      return { ...initialState };
    default:
      if (action.error) {
        return {
          ...nextState,
          message: action.payload.message,
          show: true,
          severity: action.payload.severity || "error"
        };
      }

      return nextState;
  }
}
