import { PagesApi } from "api/pagesApi";
import { find, isEmpty, map, some } from "lodash";
import {
  AliasOperationType,
  AliasValidationMap,
  IAliasMetadataForm,
  IUrlMetadata,
  IAliasMetadataFormUpdate,
  AliasValidationKey,
  AliasFormMetadata
} from "components/siteStructure/aliases/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PageUrlDetail } from "models/pages";

import {
  IUnsetPageAliasBody,
  SetPageAlias,
  UnsetPageAlias,
  ICommand
} from "@d3-forge/forge-commands";

import {
  createAliasMetadataFormByCulture,
  getAliasMetadataFormUpdates,
  getCanSaveAliases,
  getPageUrlDetailByCulture,
  getUpdatedAliasMetadataForm,
  getUrlMetadata,
  getUrlWithRootPath,
  getValidationHasAliasCorrectAllowedValuesFormat,
  getValidationHasAliasMatchedDynamicParameters,
  getValidationHasAliasValidUrl,
  getValidationIsStaticAliasUnique,
  getValidationMissingAliasAllowedFields,
  parseDynamicParameterAllowedValues,
  toStaticAlias
} from "components/siteStructure/aliases/utils";
import { ISiteItem } from "models/siteItem";
import { useSelector } from "react-redux";
import { selectStaticAliases } from "redux/selectors/aliasesSelectors";

interface ConfigProps {
  path: string;
  currentSiteItem: ISiteItem;
  allCultures: string[];
  isOverlayPortalOpened: boolean;
  isSavePromptOpened: boolean;
  canUseAliases: boolean;
  setIsSavePromptOpened: (val: boolean) => void;
}

export interface IAliasManagement {
  pageUrlDetails: PageUrlDetail[];
  urlMetadata: IUrlMetadata;
  aliasValueValidationMap: AliasValidationMap;
  paramsValidationMap: AliasValidationMap;
  aliasFormMetadataUpdates: IAliasMetadataFormUpdate[];
  promptValidations: AliasValidationMap;
  getPromptValidationForCulture: (
    culture: string,
    payload: any
  ) => AliasValidationKey | undefined;
  getAliasMetadataFormByCulture: (culture: string) => IAliasMetadataForm;
  getSaveAliasesCommands: () => ICommand[];
  getUnsetAliasCommand: (culture: string) => ICommand;
  getHasUpdateForCulture: (culture: string) => boolean;
  canSaveAliases: boolean;
  onUpdateAliasValue: (culture: string, value: string) => void;
  onBlurUiOperation: (
    operation: AliasOperationType,
    form: IAliasMetadataForm,
    paramName?: string
  ) => void;
  onUpdateAliasParam: (
    culture: string,
    paramName: string,
    value: string
  ) => void;
  onSaveSettingsSuccess: () => void;
  onUnsetAliasSuccess: (command: UnsetPageAlias) => void;
}

const initAliasFormSyncMetadata = (path: string): AliasFormMetadata => ({
  currentPath: path,
  areSynced: false
});

export function useAliases({
  path,
  allCultures,
  isOverlayPortalOpened,
  isSavePromptOpened,
  canUseAliases,
  currentSiteItem,
  setIsSavePromptOpened
}: ConfigProps): IAliasManagement {
  const [aliasMetadataForms, setAliasMetadataForms] = useState<
    IAliasMetadataForm[]
  >([]);
  const [pageUrlDetails, setPageUrlDetails] = useState<PageUrlDetail[]>([]);
  const [formSyncMetadata, setFormSyncMetadata] = useState<AliasFormMetadata>(
    initAliasFormSyncMetadata(path)
  );

  const [aliasValueValidationMap, setAliasValueValidationMap] =
    useState<AliasValidationMap>({});

  const [paramsValidationMap, setParamsValidationMap] =
    useState<AliasValidationMap>({});

  const staticAliases = useSelector(selectStaticAliases);

  const urlMetadata = useMemo(
    () => getUrlMetadata(pageUrlDetails),
    [pageUrlDetails]
  );

  const aliasFormMetadataUpdates = useMemo(
    () => getAliasMetadataFormUpdates(aliasMetadataForms, pageUrlDetails),
    [aliasMetadataForms, pageUrlDetails]
  );

  const clearValidationForCulture = useCallback(
    (culture: string, operation: AliasOperationType) => {
      const setter =
        operation === "ALIAS_VALUE"
          ? setAliasValueValidationMap
          : setParamsValidationMap;

      setter((current) => ({
        ...current,
        [culture]: undefined
      }));
    },
    [setAliasValueValidationMap, setParamsValidationMap]
  );

  const onUpdateReaction = useCallback(
    (culture: string, operation: AliasOperationType) => {
      clearValidationForCulture(culture, operation);
      setIsSavePromptOpened(false);
    },
    [setIsSavePromptOpened, clearValidationForCulture]
  );

  const onUpdateAliasValue = useCallback(
    (culture: string, value: string) => {
      onUpdateReaction(culture, "ALIAS_VALUE");

      setAliasMetadataForms((current) =>
        map(current, (form) =>
          form.culture === culture
            ? getUpdatedAliasMetadataForm(form, "value", value)
            : form
        )
      );
    },
    [setAliasMetadataForms, onUpdateReaction]
  );

  const onUpdateAliasParam = useCallback(
    (culture: string, paramName: string, value: string) => {
      onUpdateReaction(culture, "PARAM_INSERT");

      setAliasMetadataForms((current) =>
        map(current, (form) =>
          form.culture === culture
            ? getUpdatedAliasMetadataForm(form, "rawParams", value, paramName)
            : form
        )
      );
    },
    [setAliasMetadataForms, onUpdateReaction]
  );

  const getAliasMetadataFormByCulture = useCallback(
    (culture: string) =>
      find(aliasMetadataForms, { culture }) as IAliasMetadataForm,
    [aliasMetadataForms]
  );

  const onBlurUiOperation = useCallback(
    (
      operation: AliasOperationType,
      form: IAliasMetadataForm,
      paramName?: string
    ) => {
      const { culture, value } = form;

      if (value === "") {
        return;
      }

      if (operation === "PARAM_INSERT") {
        const paramValue =
          find(form.rawParams, { name: paramName })?.allowedValues ?? "";

        const { isValid, payload } =
          getValidationHasAliasCorrectAllowedValuesFormat(paramValue);

        if (!isValid) {
          setParamsValidationMap((current) => ({
            ...current,
            [culture]: {
              validationKey: "ALIAS_ALLOWED_FIELDS_INCORRECT_FORMAT",
              payload: {
                paramName,
                ...payload
              }
            }
          }));
        }

        return;
      }

      const validationEntryConditions = [
        {
          condition: !getValidationHasAliasValidUrl(value),
          validationKey: "URL_NOT_VALID"
        },
        {
          condition: !getValidationHasAliasMatchedDynamicParameters(
            urlMetadata,
            value
          ),
          validationKey: "DYNAMIC_PARAMETERS_MISMATCH"
        },
        {
          condition:
            isEmpty(urlMetadata.paramNames) &&
            !getValidationIsStaticAliasUnique(
              toStaticAlias(
                currentSiteItem.nodeId,
                culture,
                value,
                currentSiteItem.path
              ),
              staticAliases
            ),
          validationKey: "ALIAS_NOT_UNIQUE"
        }
      ];

      const entryCondition = find(validationEntryConditions, "condition");

      if (entryCondition === undefined) {
        return;
      }

      setAliasValueValidationMap((current) => ({
        ...current,
        [culture]: {
          validationKey: entryCondition.validationKey as AliasValidationKey
        }
      }));
    },
    [
      urlMetadata,
      setAliasValueValidationMap,
      setParamsValidationMap,
      currentSiteItem.nodeId,
      staticAliases,
      currentSiteItem.path
    ]
  );

  const getSaveAliasesCommands = useCallback(
    () =>
      map(
        aliasFormMetadataUpdates,
        (batchedUpdate) =>
          new SetPageAlias({
            cultures: [batchedUpdate.culture],
            pageId: currentSiteItem.nodeId,
            parameters: map(batchedUpdate.rawParams, (param) => ({
              name: param.name,
              allowedValues: parseDynamicParameterAllowedValues(
                param.allowedValues
              )
            })),
            value: getUrlWithRootPath(batchedUpdate.value)
          })
      ),
    [aliasFormMetadataUpdates, currentSiteItem.nodeId]
  );

  const getUnsetAliasCommand = useCallback(
    (culture: string) =>
      new UnsetPageAlias({
        cultures: [culture],
        pageId: currentSiteItem.nodeId
      }),
    [currentSiteItem]
  );

  const canSaveAliases = useMemo(
    () =>
      getCanSaveAliases(
        aliasFormMetadataUpdates,
        urlMetadata,
        currentSiteItem.nodeId,
        currentSiteItem.path,
        staticAliases
      ),
    [
      aliasFormMetadataUpdates,
      urlMetadata,
      staticAliases,
      currentSiteItem.nodeId,
      currentSiteItem.path
    ]
  );

  const promptValidations: AliasValidationMap = useMemo(() => {
    if (!isSavePromptOpened) {
      return {};
    }
    const validations = getValidationMissingAliasAllowedFields(
      aliasFormMetadataUpdates,
      urlMetadata
    );

    return validations;
  }, [aliasFormMetadataUpdates, urlMetadata, isSavePromptOpened]);

  const getPromptValidationForCulture = useCallback(
    (culture: string, payload: any) => {
      const promptValidationForCulture = promptValidations[culture];

      if (!isSavePromptOpened) {
        return undefined;
      }

      if (promptValidationForCulture === undefined) {
        return undefined;
      }

      if (
        promptValidationForCulture.validationKey ===
        "MISSING_ALIAS_ALLOWED_FIELDS"
      ) {
        const { paramName } = payload;
        const { paramNames } = promptValidationForCulture.payload;

        return (paramNames || []).includes(paramName)
          ? "MISSING_ALIAS_ALLOWED_FIELDS"
          : undefined;
      }
    },
    [promptValidations, isSavePromptOpened]
  );

  const getHasUpdateForCulture = useCallback(
    (culture: string) => some(aliasFormMetadataUpdates, { culture }),
    [aliasFormMetadataUpdates]
  );

  const onUnsetAliasSuccess = (command: UnsetPageAlias) => {
    const culture = (command.bodyObject as IUnsetPageAliasBody).cultures[0];
    const updatedPageUrlDetails = map(pageUrlDetails, (detail) => ({
      ...detail,
      alias: detail.culture === culture ? null : detail.alias
    }));

    const formToUpdate = createAliasMetadataFormByCulture(
      culture,
      find(updatedPageUrlDetails, { culture }) as PageUrlDetail,
      urlMetadata
    );

    const updatedAliasMetadataForms = map(aliasMetadataForms, (form) =>
      form.culture === culture ? formToUpdate : form
    );

    setPageUrlDetails(updatedPageUrlDetails);
    setAliasMetadataForms(updatedAliasMetadataForms);
  };

  useEffect(() => {
    if (!canUseAliases) {
      return;
    }

    PagesApi.getSiteItemUrls(path).then((result) => {
      setPageUrlDetails(result);
    });

    return () => {};
  }, [path, canUseAliases]);

  useEffect(() => {
    if (formSyncMetadata.areSynced) {
      return;
    }

    const forms = map(allCultures, (culture) =>
      createAliasMetadataFormByCulture(
        culture,
        getPageUrlDetailByCulture(culture, pageUrlDetails),
        urlMetadata
      )
    );

    const shouldMarkFormsAsSynced =
      !isEmpty(allCultures) &&
      !isEmpty(pageUrlDetails) &&
      urlMetadata.url === path;

    setAliasMetadataForms(forms);

    if (shouldMarkFormsAsSynced) {
      setFormSyncMetadata((current) => ({ ...current, areSynced: true }));
    }
  }, [allCultures, pageUrlDetails, urlMetadata, formSyncMetadata, path]);

  useEffect(() => {
    setAliasValueValidationMap({});
    setParamsValidationMap({});
  }, [path]);

  const onSaveSettingsSuccess = () => {
    PagesApi.getSiteItemUrls(path).then((result) => {
      setPageUrlDetails(result);
    });
  };

  useEffect(() => {
    setFormSyncMetadata(initAliasFormSyncMetadata(path));
    setAliasMetadataForms([]);
    setPageUrlDetails([]);
  }, [path]);

  return {
    getAliasMetadataFormByCulture,
    pageUrlDetails,
    aliasFormMetadataUpdates,
    urlMetadata,
    aliasValueValidationMap,
    paramsValidationMap,
    onUpdateAliasValue,
    onUpdateAliasParam,
    onBlurUiOperation,
    canSaveAliases,
    promptValidations,
    getPromptValidationForCulture,
    getSaveAliasesCommands,
    getHasUpdateForCulture,
    getUnsetAliasCommand,
    onSaveSettingsSuccess,
    onUnsetAliasSuccess
  };
}
