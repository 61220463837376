import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  src: string;
  landscape?: boolean;
  height?: number | string;
  width?: number | string;
}

const useStyles = makeStyles((theme) => ({
  iframe: {
    border: "none"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "calc(100vh - 48px)",
    backgroundColor: theme.palette.custom.ds.grayscales.gr200
  }
}));

export function PreviewContent(props: IProps) {
  const { src, width = "100%", height = "100%", landscape = false } = props;

  const classes = useStyles();

  return (
    <div className={classnames(classes.container, props.className)}>
      <iframe
        src={src}
        title={src}
        width={!landscape ? width : height}
        height={!landscape ? height : width}
        className={classes.iframe}
      ></iframe>
    </div>
  );
}
