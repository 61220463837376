import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { fetchPage } from "redux/actions/siteStructureActions";
import { SetLayoutProperties } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { SetLayoutPropertyPayload } from "redux/actions/typings/siteStructureActions";

export function* editLayoutProperty(action: Action<SetLayoutPropertyPayload>) {
  try {
    const { pageId, layoutInstanceId, layoutKey, name, value } = action.payload;

    const command = new SetLayoutProperties({
      pageId,
      layoutInstanceId,
      layoutKey,
      properties: {
        [name]: value || "#NOSET#"
      }
    });

    const commandAction = issueCommand({
      command,
      onFailureAction: fetchPage(pageId),
      errorMessage: "notification.editlayout.error",
      successMessage: "notification.editlayout.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
