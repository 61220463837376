import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    transition: "all .3s ease-in-out",
    color: theme.palette.custom.ds.grayscales.gr800,
    "&:hover": {
      transition: "all .3s ease-in-out",
      backgroundColor: "transparent",
      "&:before": {
        content: '""',
        top: 0,
        left: 0,
        width: theme.spacing(0.5),
        height: "100%",
        position: "absolute",
        backgroundColor: theme.palette.custom.ds.viking.viking500
      },
      "& .MuiListItemIcon-root": {
        display: "inline-flex",
        margin: "auto"
      }
    },
    "&$selected $itemText, &:hover $itemText": {
      color: `${theme.palette.custom.ds.viking.viking500} !important`
    },
    "& .MuiListItemIcon-root": {
      display: "none",
      minWidth: "0px"
    }
  },
  itemLinkContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  label: {
    fontSize: theme.typography.pxToRem(14)
  },
  itemText: {
    color: theme.palette.custom.ds.grayscales.gr800,
    "& span": {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  textRight: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.custom.ds.grayscales.gr600,
    position: "relative",
    top: 2
  },

  row: { width: "100%", display: "flex", justifyContent: "space-between" },
  selected: {},
  create: {},
  unpublishedWarningIcon: {
    color: theme.palette.custom.ds.warning.warning500,
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    position: "relative",
    top: 7,
    fontSize: theme.typography.pxToRem(16)
  }
}));
