import React, { ReactNode } from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  assetType?: string;
  callToAction?: ReactNode;
  assetName?: string | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${theme.palette.custom.ds.error.error500}`,
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.error.error700,
    minHeight: theme.spacing(6),
    background: theme.palette.custom.ds.error.error100,
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      boxShadow: `0px 4px 16px ${theme.palette.custom.greyscales.shadows.sh500}`,
      "& $buttonContainer": {
        opacity: 1
      }
    }
  },
  buttonContainer: {
    position: "absolute",
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    opacity: 0,
    transition: "opacity .1s ease-out"
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    fontSize: 12,
    gap: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr600,
    "& strong": {
      color: theme.palette.custom.ds.error.error700,
      fontWeight: 400,
      fontSize: 14
    }
  },
  messageIcon: {
    color: theme.palette.custom.ds.error.error700,
    fontSize: theme.typography.pxToRem(36)
  },
  messageTypography: {}
}));

export const AssetNotFound = (props: IProps) => {
  const { className, callToAction, assetType, assetName } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography className={classes.messageContainer}>
        <UxdIcon name="info" outline={false} className={classes.messageIcon} />
        <span>
          {translate("sitestructure.assetnotfound", {
            elementType: assetType
              ? `${assetName || ""} ${assetType}`
              : translate("sitestructure.asset")
          })}
        </span>
      </Typography>
      {callToAction && (
        <div className={classes.buttonContainer}>{callToAction}</div>
      )}
    </div>
  );
};
