import { makeStyles, Typography, Link, Collapse } from "@material-ui/core";
import classnames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { throttle } from "lodash";
import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { noop } from "utils/typescriptUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  contents: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1.5)
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
    width: "85%"
  },
  item: {
    "& span": {
      display: "inline-flex",
      alignItems: "center"
    },

    "&$active $icon": {
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  secondaryItem: {
    paddingLeft: theme.spacing(1),
    lineHeight: theme.spacing(2) + "px"
  },
  active: {},
  section: {
    height: "100vh"
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
    marginLeft: theme.spacing(1)
  },
  iconCollapse: {
    color: theme.palette.custom.ds.viking.viking500
  }
}));

const makeUnique = (
  hash: string,
  unique: { [key: string]: boolean },
  i = 1
) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text: string, unique: { [key: string]: boolean } = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, "")
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, "-")
        .replace(/-+/g, "-")
    ),
    unique
  );
};

function useThrottledOnScroll(
  container: RefObject<HTMLElement | null>,
  callback: () => void,
  delay: number
) {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  );

  useEffect(() => {
    const elem = container.current;
    if (throttledCallback === noop || !elem || !elem.parentElement)
      return undefined;
    elem.parentElement.addEventListener("scroll", throttledCallback);

    return () => {
      if (elem && elem.parentElement)
        elem.parentElement.removeEventListener("scroll", throttledCallback);
    };
  }, [throttledCallback, container]);
}

export type ScrollSpyElem<E> = {
  text: string;
  icon?: string;
  node: RefObject<HTMLDivElement>;
  hash?: string;
  parentHash?: string;
  children: ScrollSpyElem<E>[];
  capitalize?: boolean;
} & E;

interface IProps<E> extends IBaseProps {
  elements: ScrollSpyElem<E>[];
  container: RefObject<HTMLElement | null>;
  children: (props: {
    items: ScrollSpyElem<E>[];
    currentActive: string;
  }) => ReactNode;
  linkItem: (props: {
    item: ScrollSpyElem<E>;
    active: boolean;
    level: 1 | 2;
    capitalize?: boolean;
  }) => ReactNode;
  navClassName?: string;
  ulClassName?: string;
  liClassName?: string;
}

export function Scrollspy<E>(props: IProps<E>) {
  const { elements, container, linkItem, children } = props;

  const classes = useStyles();

  const rootClassName = classnames(classes.root, props.className);
  const navClassName = classnames(
    classes.ul,
    props.ulClassName,
    props.navClassName
  );
  const ulClassName = classnames(classes.ul, props.ulClassName);
  const liClassName = classnames(classes.item, props.liClassName);

  const formatHash: (elem: ScrollSpyElem<E>) => ScrollSpyElem<E> = (
    elem: ScrollSpyElem<E>
  ) => {
    const hash = textToHash(elem.text);
    return {
      ...elem,
      hash,
      children:
        elem.children.length > 0
          ? elem.children.map((ch) => ({ ...formatHash(ch), parentHash: hash }))
          : []
    };
  };

  let itemsServer = elements.map(formatHash);
  const [collapseItems, setCollapseItem] = useState<{ [key: string]: boolean }>(
    {}
  );
  const itemsClientRef = useRef<ScrollSpyElem<E>[]>([]);
  const allItemsClientRef = useRef<ScrollSpyElem<E>[]>([]);
  const [activeState, setActiveState] = useState("");
  const defaultCollpase = useMemo(
    () =>
      itemsServer.reduce(
        (old, val) => ({
          ...old,
          [val.hash || val.text]: false
        }),
        {}
      ),
    [itemsServer]
  );
  useEffect(() => {
    itemsClientRef.current = itemsServer;
    allItemsClientRef.current = itemsServer
      .map((elem) => [elem, ...elem.children])
      .flat();
  }, [itemsServer]);

  const clickedRef = useRef(false);
  const unsetClickedRef = useRef<NodeJS.Timeout>();
  const findActiveIndex = useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (
      clickedRef.current ||
      !container.current ||
      !container.current.parentElement
    ) {
      return;
    }

    let active: ScrollSpyElem<E> | undefined;
    for (let i = allItemsClientRef.current.length - 1; i >= 0; i -= 1) {
      const item = allItemsClientRef.current[i];

      if (
        item.node.current &&
        item.node.current.offsetTop - 80 <
          container.current.parentElement.scrollTop +
            container.current.parentElement.clientHeight / 4
      ) {
        active = item;
        break;
      }
    }
    if (active && activeState !== active.hash) {
      setActiveState(active.hash || "");
      setCollapseItem({
        ...defaultCollpase,
        [active?.parentHash || active?.hash || ""]: true
      });
    }
  }, [activeState, container, defaultCollpase]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(
    container,
    itemsServer.length > 0 ? findActiveIndex : () => undefined,
    166
  );

  const handleClick = (hash: string) => (event) => {
    event.preventDefault();
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);
    if (activeState !== hash) {
      setActiveState(hash);
    }
    const elem = allItemsClientRef.current.find((e) => e.hash === hash);
    if (!elem) {
      return;
    }
    if (!elem.parentHash) {
      if (collapseItems[hash]) {
        setActiveState("");
      }
      setCollapseItem({ ...defaultCollpase, [hash]: !collapseItems[hash] });
    }
    elem.node.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current || (0 as unknown as NodeJS.Timeout));
    },
    []
  );

  const itemLink = (item: ScrollSpyElem<E>, secondary: boolean = false) => (
    <Link
      color={activeState === item.hash ? "textPrimary" : "textSecondary"}
      href={`#${item.hash}`}
      onClick={handleClick(item.hash || "")}
      underline="none"
      className={classnames(
        classes.item,
        { [classes.secondaryItem]: secondary },
        activeState === item.hash ? classes.active : undefined
      )}
    >
      <span>
        {linkItem({
          item,
          active: activeState === item.hash,
          level: secondary ? 2 : 1,
          capitalize: item.capitalize
        })}
        {item.children.length > 0 && (
          <UxdIcon
            className={classnames(classes.icon, {
              [classes.iconCollapse]: collapseItems[item.hash || item.text]
            })}
            name={
              collapseItems[item.hash || item.text]
                ? "expand_more"
                : "expand_less"
            }
          />
        )}
      </span>
    </Link>
  );

  return (
    <>
      <nav className={rootClassName}>
        {itemsServer.length > 0 && (
          <Typography component="ul" className={navClassName}>
            {itemsServer.map((item2) => (
              <li key={item2.text} className={liClassName}>
                {itemLink(item2)}
                {item2.children.length > 0 && (
                  <>
                    <Collapse
                      in={collapseItems[item2.hash || item2.text] || false}
                    >
                      <ul className={ulClassName}>
                        {item2.children.map((item3) => (
                          <li key={item3.text} className={liClassName}>
                            {itemLink(item3, true)}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                )}
              </li>
            ))}
          </Typography>
        )}
      </nav>
      {children({ items: itemsServer, currentActive: activeState })}
    </>
  );
}
