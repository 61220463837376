import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey64
  }
}));

export const DesignContent = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return <div className={rootClassName}></div>;
};
