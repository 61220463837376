import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { userService } from "services/userService";
import { HistoryConfirmRestorePopup } from "components/siteStructure/history/historyConfirmRestorePopup";
import { useDispatch } from "react-redux";
import { rollback } from "redux/actions/siteStructureActions";
import { SiteNodeType } from "models/siteStructure";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";

interface IProps extends IBaseProps {
  nodeId: string;
  revision: number;
  disableRestore: boolean;
  aggregateType: SiteNodeType;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const HistoryEntryContextualMenu = (props: IProps) => {
  const { className, revision, nodeId, disableRestore, aggregateType } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const dispatch = useDispatch();
  const dispatchRollback = useCallback(
    () =>
      dispatch(
        rollback({
          aggregateId: nodeId,
          revision,
          aggregateType: aggregateType
        })
      ),
    [nodeId, revision, dispatch, aggregateType]
  );

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const currentSiteItemPath = useContext(CurrentSiteItemContext);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => setAnchorElement(null);

  const canRestore =
    !disableRestore && userService.hasSiteItemPermissions(currentSiteItemPath);

  const handleRestoreClick = () => {
    setConfirmOpen(true);
    closeMenu();
  };

  const closeConfirmPopup = () => {
    setConfirmOpen(false);
  };

  const handleConfirmRestore = () => {
    dispatchRollback();
    closeConfirmPopup();
  };

  return (
    <>
      <IconButton className={rootClassName} size="small" onClick={openMenu}>
        <UxdIcon name="more_vert" />
      </IconButton>
      <Menu
        className={rootClassName}
        open={Boolean(anchorElement)}
        anchorEl={anchorElement}
        onClose={closeMenu}
      >
        <MenuItem disabled={!canRestore} onClick={handleRestoreClick}>
          {translate("sitestructure.restore")}
        </MenuItem>
      </Menu>
      <HistoryConfirmRestorePopup
        open={confirmOpen}
        revision={revision}
        onConfirm={handleConfirmRestore}
        onClose={closeConfirmPopup}
      />
    </>
  );
};
