import React from "react";
import { IBaseProps } from "components/_baseProps";
import { Route, RouteComponentProps } from "react-router-dom";
import { userService } from "services/userService";
import ErrorPage from "components/errorPages";
import AppContainer from "components/appContainer";

interface IProps extends IBaseProps {
  path: string;
  permissions: string[];
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export const ProtectedRoute = (props: IProps) => {
  const { path, permissions, component } = props;

  const Component = userService.hasPermissions(...permissions)
    ? component
    : () => (
        <AppContainer>
          <ErrorPage error="forbidden" />
        </AppContainer>
      );

  return <Route path={path} component={Component} />;
};
