import { useMemo } from "react";
import classnames from "classnames";
import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { RoundCheckbox } from "components/shared/roundCheckbox";
import { SiteNodeInfo } from "components/shared/siteNodeInfo";
import { LastUpdatedInfo } from "components/shared/lastUpdatedInfo";
import { StagedSiteItem } from "models/directories";
import { noop } from "utils/typescriptUtils";

interface IProps extends IBaseProps {
  item: StagedSiteItem;
  indentOffset: number;
  onSelect?: (node: StagedSiteItem) => void;
  selected?: boolean;
  disabled?: boolean;
  showCheckbox?: boolean | ((current: StagedSiteItem) => boolean);
  highlight?: boolean | ((current: StagedSiteItem) => boolean);
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "&$selected": {
      backgroundColor: theme.palette.custom.greyscales.backgrounds.grey98
    },
    "&$disabled": {
      pointerEvents: "none",
      cursor: "not-allowed"
    }
  },
  pathCell: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12)
  },
  nodeIcon: {
    "&$highlight": {
      color: theme.palette.custom.secondaryAccent.tiffany
    }
  },
  highlight: {},
  selected: {},
  disabled: {}
}));

const INDENT_FACTOR = {
  Directory: 2,
  Page: 1,
  Menu: 1
};

const MULTIPLIER = 4 * 3; // 4px is the unit

export const SiteItemsTableRow = (props: IProps) => {
  const {
    className,
    item,
    indentOffset,
    selected = false,
    disabled = false,
    onSelect = noop,
    showCheckbox = false,
    highlight = false
  } = props;
  const classes = useStyles();

  const rootClassName = classnames(
    classes.root,
    {
      [classes.selected]: selected,
      [classes.disabled]: disabled
    },
    className
  );

  const nodeIconClass = classnames(classes.nodeIcon, {
    [classes.highlight]:
      typeof highlight === "function" ? highlight(item) : highlight
  });

  const selectRow = () => {
    onSelect(item);
  };

  const indent = useMemo(() => {
    if (item.path === "~/") {
      return MULTIPLIER;
    }

    const segmentsCount = item.path.split("/").length;
    const indentSize =
      segmentsCount + 1 - INDENT_FACTOR[item.nodeType] - indentOffset;

    return indentSize * MULTIPLIER;
  }, [indentOffset, item.path, item.nodeType]);

  return (
    <TableRow className={rootClassName} onClick={selectRow}>
      <TableCell padding="checkbox">
        {(typeof showCheckbox === "function"
          ? showCheckbox(item)
          : showCheckbox) && (
          <RoundCheckbox
            onChange={selectRow}
            checked={selected}
            disabled={disabled}
          />
        )}
      </TableCell>
      <TableCell align="left" style={{ paddingLeft: indent }}>
        <SiteNodeInfo
          label={item.label}
          nodeType={item.nodeType}
          stage={item.status}
          size="small"
          nodeIconClassName={nodeIconClass}
          contextName={item.contextName}
        />
      </TableCell>
      <TableCell className={classes.pathCell} align="left">
        {item.path}
      </TableCell>
      <TableCell align="left">
        <LastUpdatedInfo updateInfo={item.updateInfo} />
      </TableCell>
    </TableRow>
  );
};
