import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import classNames from "classnames";
import { IPathSegment } from "components/siteStructure/aliases/types";
import { isPathSegmentTranslated } from "components/siteStructure/aliases/utils";

export interface ITranslatedUrlSegmentProps {
  segments: IPathSegment[];
  isDefault: boolean;
}
export const TranslatedUrlSegments = (props: ITranslatedUrlSegmentProps) => {
  const classes = useStyles();
  const { segments, isDefault } = props;

  return (
    <>
      {segments.map((segment) => (
        <div
          key={`path-segment-${segment.defaultTranslation}`}
          className={classes.pathSegment}
        >
          <Typography
            className={classNames(classes.path, {
              [classes.translatedPathHighlight]:
                !isDefault && isPathSegmentTranslated(segment)
            })}
            component="label"
          >
            {segment.translationValue ?? segment.defaultTranslation}
          </Typography>
          <Typography
            key={`path-forwardslash-${segment.defaultTranslation}`}
            className={classes.path}
            component="label"
          >
            /
          </Typography>
        </div>
      ))}
    </>
  );
};

export default TranslatedUrlSegments;
