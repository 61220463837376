import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const ContentPickerLoadError = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Typography className={rootClassName} variant="h6" align="center">
      {translate("contentpicker.loaderror")}
    </Typography>
  );
};
