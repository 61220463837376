import { connect } from "react-redux";
import { IDomainState } from "models/domainStates";
import { closeApiNotificationSnackbar } from "redux/actions/notificationActions";
import { IBaseProps } from "components/_baseProps";
import Snackbar from "@material-ui/core/Snackbar";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import { translate } from "utils/i18n";
import { NotificationMessage } from "models/notificationMessage";
import { useMemo } from "react";
import _ from "lodash";

interface IProps extends IBaseProps {
  message: NotificationMessage;
  show: boolean;
  severity: AlertProps["severity"];
  dispatchCloseApiNotificationSnackbar: () => void;
}

const ApiNotificationSnackbarComponent = (props: IProps) => {
  const { message, show, severity, dispatchCloseApiNotificationSnackbar } =
    props;

  const messageToShow = useMemo(() => {
    const getKey = (msg: NotificationMessage) => {
      const key = _.isString(msg) ? msg : msg.key;

      if (key.toLowerCase() === "not found") {
        return "sitestructure.errornotfound";
      }

      return key;
    };

    if (message) {
      const key = getKey(message);

      const translatedMessage = translate(
        key,
        _.isObject(message) ? message : undefined
      );

      // Check if the key exist in translate exist, if not use default message
      if (translatedMessage === key) {
        return severity === "error"
          ? translate("notification.apierror", { message })
          : translate("notification.apisuccess");
      }

      return translatedMessage;
    }
    return "";
  }, [message, severity]);

  if (!messageToShow) {
    return null;
  }

  return (
    <Snackbar
      open={show}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClose={() => dispatchCloseApiNotificationSnackbar()}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={() => dispatchCloseApiNotificationSnackbar()}
      >
        {messageToShow}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = ({ apiNotification }: IDomainState) => ({
  message: apiNotification.message,
  show: apiNotification.show,
  severity: apiNotification.severity
});

const mapDispatchToProps = {
  dispatchCloseApiNotificationSnackbar: closeApiNotificationSnackbar
};

export const ApiNotificationSnackbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiNotificationSnackbarComponent);
