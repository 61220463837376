import { ApiClient } from "api/apiClient";
import { IVariableRouteSuggestion } from "models/variables";

const BASE_URL = "api/variables";

export class VariablesApi {
  static readonly getRoutes = async (): Promise<IVariableRouteSuggestion[]> => {
    const endpoint = `${BASE_URL}/routes`;
    return ApiClient.get(endpoint);
  };
}
