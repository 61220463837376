import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  Button,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Divider,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { userService } from "services/userService";
import { ISiteItem } from "models/siteItem";
import StagingSiteItemsModal from "components/siteStructure/modals/stagingSiteItemsModal";
import {
  publishMenu,
  publishPage,
  unpublishMenu,
  unpublishPage
} from "redux/actions/siteStructureActions";
import { StagingContextProvider } from "components/contexts/siteItemsStagingContext";
import { SiteNodeTypes, StageAction } from "models/siteStructure";
import { ClipboardPageAction } from "components/siteStructure/header/clipboardPageAction";
import { RenameSiteItemMenuItem } from "components/siteStructure/modals/renameSiteItemModal/renameSiteItemMenuItem";
import { PermissionCodes } from "catalogs/permissionCodes";
import { selectPageRequiredModuleWarningFlag } from "redux/selectors/siteStructureSelectors";
import { D3Button, D3Modal } from "components/shared/d3Components";
import { contextualMenu } from "theme/commonClasses";

interface IConfirmModalState {
  open: boolean;
  title: string;
  body: string;
}
interface IProps extends IBaseProps {
  node: ISiteItem;
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: "unset",
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr700,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  unpublishIcon: {
    fontSize: theme.spacing(2),
    color: theme.palette.custom.stages.unpublished
  },
  active: {
    color: theme.palette.custom.ds.viking.viking500
  },
  contextualMenu
}));

export const PageActionsBtn = (props: IProps) => {
  const { node } = props;
  const classes = useStyles();
  const pageRequiredModuleWarning: boolean = useSelector(
    selectPageRequiredModuleWarningFlag
  );

  const dispatch = useDispatch();

  const [confirmModal, setConfirmModal] = useState<IConfirmModalState>({
    open: false,
    title: "",
    body: ""
  });

  const [stage, setStage] = useState<StageAction>("Publish");

  const [stagingModalOpen, setStagingModalOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  const changeStage = (stageAction: StageAction) => {
    setStage(stageAction);

    if (node.nodeType === SiteNodeTypes.DIRECTORY) {
      setStagingModalOpen(true);
    } else {
      const type = node.nodeType.toLowerCase();
      const act = stageAction.toLowerCase();

      const showRequiredFieldsMessage = pageRequiredModuleWarning;

      const modalBody = translate(
        showRequiredFieldsMessage
          ? `general.requiredfieldsconfirmation.message.${act}`
          : `sitestructure.${act}${type}.confirmtext`,
        { path: node.path }
      );

      const modalTitle = translate(
        showRequiredFieldsMessage
          ? "general.requiredfieldsconfirmation.title"
          : "general.confirmationrequired"
      );

      setConfirmModal({
        open: true,
        title: modalTitle,
        body: modalBody
      });
    }
  };

  const getAction = () => {
    const isMenu = node.nodeType === SiteNodeTypes.MENU;

    if (stage === "Publish") return isMenu ? publishMenu : publishPage;

    return isMenu ? unpublishMenu : unpublishPage;
  };

  const onCancel = () => setConfirmModal({ open: false, title: "", body: "" });
  const onConfirm = () => {
    const action = getAction();

    console.table({ data: stagingModalOpen, action: action(node.nodeId) });

    dispatch(action(node.nodeId));
    setConfirmModal({ open: false, title: "", body: "" });
  };

  const publish = () => changeStage("Publish");
  const unpublish = () => changeStage("Unpublish");

  const canUnpublish =
    (userService.hasPermissions(PermissionCodes.Unpublish) ||
      userService.hasSiteItemPermissions(node.path)) &&
    node.status !== "Unpublished";

  const canPublish =
    userService.hasPermissions(PermissionCodes.Publish) ||
    userService.hasSiteItemPermissions(node.path);

  const menuButtonClassName = classnames(classes.menuButton, {
    [classes.active]: anchorEl
  });

  return (
    <>
      <Button className={menuButtonClassName} size="small" onClick={menuClick}>
        <UxdIcon name="more_vert" />
      </Button>
      {node.status !== "Published" && canPublish && (
        <D3Button
          icon="upload"
          onClick={publish}
          children={translate("sitestructure.publish")}
        />
      )}
      <Menu
        className={classes.contextualMenu}
        open={Boolean(anchorEl)}
        onClick={menuClose}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        keepMounted
        onClose={menuClose}
      >
        <MenuItem onClick={unpublish} disabled={!canUnpublish}>
          <ListItemIcon>
            <UxdIcon className={classes.unpublishIcon} name="unpublished" />
          </ListItemIcon>
          {translate("sitestructure.unpublish")}
        </MenuItem>
        <Divider />
        <RenameSiteItemMenuItem
          node={node}
          onClickCallback={menuClose}
          disabled={node.parentNodesIds.length === 0}
        />
        <ClipboardPageAction node={node} onClickCallback={() => menuClose()} />
      </Menu>

      <StagingContextProvider stagedId={node.nodeId}>
        <StagingSiteItemsModal
          open={stagingModalOpen}
          action={stage}
          onClose={() => setStagingModalOpen(false)}
          node={node}
        />
      </StagingContextProvider>
      <D3Modal
        open={confirmModal.open}
        modalTitle={confirmModal.title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmLabel={translate("general.ok")}
      >
        <Typography>{confirmModal.body}</Typography>
      </D3Modal>
    </>
  );
};
