import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {},
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(-360deg)"
    }
  },
  switch: {
    transition: "color 0.5s ease-out",
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(0.5),
    margin: `${theme.spacing(1)}px 0 auto ${theme.spacing(1)}px`,
    color: theme.palette.custom.ds.grayscales.gr500,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.info.info100,
      color: theme.palette.custom.ds.grayscales.gr600
    },
    "&:active": {
      backgroundColor: theme.palette.custom.ds.info.info500,
      color: theme.palette.common.white
    },
    "& .MuiIconButton-label .material-icons": {
      fontSize: "16px"
    },
    "&$checked": {
      color: theme.palette.custom.ds.info.info500,
      "&:hover": {
        backgroundColor: theme.palette.custom.ds.info.info100
      },
      "&:active": {
        backgroundColor: theme.palette.custom.ds.info.info500,
        color: theme.palette.common.white
      }
    },
    "&$disabled": {
      visibility: "hidden"
    }
  },
  switchSpinning: {
    border: "2px solid transparent",
    pointerEvents: "none"
  },

  input: {
    "&:not($disabled) .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.custom.ds.info.info100,
      "& input": {
        fontFamily: "'Source Code Pro', monospace"
      },
      "&:not(:hover):not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: (props: any) => {
          if (props.disabled) return null;
          if (props.mandatory?.showMessage)
            return theme.palette.custom.ds.warning.warning500;
          return theme.palette.custom.ds.info.info300;
        }
      }
    }
  },
  disabled: {},
  checked: {}
}));
