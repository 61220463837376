import { call, put } from "redux-saga/effects";
import {
  fetchEntitiesDefinitionsSucceded,
  fetchEntitiesDefinitionsFailed
} from "redux/actions/forgeActions";
import { ForgeEntitiesApi } from "api/forgeEntitiesApi";

export function* getEntitiesDefinitions() {
  try {
    const definitions = yield call(ForgeEntitiesApi.getDefinitions);
    yield put(fetchEntitiesDefinitionsSucceded(definitions));
  } catch (error) {
    yield put(fetchEntitiesDefinitionsFailed(error));
  }
}
