import classnames from "classnames";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useContext } from "react";
import { ContentPickerContext } from "./contentPickerContext";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0
  }
}));

export const ContentPickerLanguageSelect = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const context = useContext(ContentPickerContext);

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.language")} />
      <Select
        fullWidth={true}
        margin="dense"
        variant="outlined"
        value={context.queryFilter.language}
        onChange={(e) => {
          context.setQueryFilter("language", e.target.value as string);
        }}
      >
        {context.languages.map((l) => (
          <MenuItem key={l.code} value={l.code}>
            {l.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
