export const PATH_SEPARATOR = "/";
const PATH_SEGMENT_VALID_REGEX =
  /^(?:[a-z0-9_-]*\{(?<param_name>[a-z_][a-z0-9_]*)\}[a-z0-9_-]*|[a-z0-9_-]+)$/;

const DYNAMIC_PARAMETER_VALUE_REGEX = /^[a-z0-9_-]+$/;
const WHITESPACE_REGEX = /\s+/;

const ABSOLUTE_REGEX = new RegExp(
  /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/ // NOSONAR
);
const RELATIVE_REGEX = new RegExp(
  /^(\/)(([\w-]*\{[a-zA-Z_]\w*\}[\w-]*)|([\w-/]+))+$/ // NOSONAR
);

export function normalizePath(path: string): string {
  if (!path) {
    return "";
  }

  const trimmedPath = path.trim();
  const normalizedPath = trimmedPath.endsWith(PATH_SEPARATOR)
    ? trimmedPath.slice(0, -1)
    : trimmedPath;

  return normalizedPath;
}

export function splitPathSegments(path: string) {
  const segments = normalizePath(path).split(PATH_SEPARATOR);
  return segments;
}

export function getAllSubPaths(path: string): string[] {
  if (!path) {
    return [];
  }

  const normalizedPath = normalizePath(path);
  const subPaths: string[] = [];
  for (
    let i = normalizedPath.indexOf(PATH_SEPARATOR);
    i >= 0;
    i = normalizedPath.indexOf(PATH_SEPARATOR, i + 1)
  ) {
    subPaths.push(normalizedPath.substring(0, i));
  }

  subPaths.push(normalizedPath);
  return subPaths;
}

export function separatePathAndLeaf(path: string): [string, string] {
  if (!path) {
    return ["", ""];
  }

  const segments = splitPathSegments(path);
  const leaf = segments.pop() ?? "";

  return [segments.join(PATH_SEPARATOR), leaf];
}

export function updateSubPath(fullPath: string, newSubPath: string) {
  if (!fullPath) {
    return "";
  }

  if (!newSubPath) {
    return fullPath;
  }

  const newSubPathSegments = splitPathSegments(newSubPath);
  const fullPathSegments = fullPath.split(PATH_SEPARATOR);

  if (newSubPathSegments.length > fullPathSegments.length) {
    throw new Error("'newSubPath' is not a subpath of 'fullPath'");
  }

  for (let i = 0; i < newSubPathSegments.length; i++) {
    fullPathSegments[i] = newSubPathSegments[i];
  }

  const newFullPath = fullPathSegments.join(PATH_SEPARATOR);
  return newFullPath;
}

export function isDynamicSlug(slug: string) {
  return slug.startsWith("{") && slug.endsWith("}");
}

export function stripParameterDelimitersFromPathSegment(slug: string) {
  return slug.replace(/[{}]/g, "");
}

export const isPathSegmentValid = (pathSegment: string) =>
  PATH_SEGMENT_VALID_REGEX.test(pathSegment);

export const isDynamicSlugValueValid = (value: string) =>
  DYNAMIC_PARAMETER_VALUE_REGEX.test(value);

export const hasWhitespaceCharacter = (value: string) => {
  return WHITESPACE_REGEX.test(value);
};

export const isValidUrl = (
  url: string,
  isAbsolutePathValidation: boolean = true,
  isRelativePathValidation: boolean = true
) => {
  if (!url || url.startsWith("@")) {
    return true;
  }

  const isValidAbsolutePath =
    isAbsolutePathValidation && ABSOLUTE_REGEX.test(url);
  const isValidRelativePath =
    isRelativePathValidation && RELATIVE_REGEX.test(url);

  if (isValidAbsolutePath || isValidRelativePath) {
    return true;
  }

  return false;
};
