import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "../../../_baseProps";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { IVariableFilter } from "../../../../models/variables";
import { translate } from "../../../../utils/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content"
  },
  label: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20)
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      fill: theme.palette.custom.ds.viking.viking500
    }
  }
}));

interface IProps extends IBaseProps {
  onFilterChange: (filter: IVariableFilter) => void;
}

export const VariableFilter = (props: IProps) => {
  const { onFilterChange, className } = props;

  const classes = useStyles();
  const rootClass = classnames(classes.root, className);

  const [local, setLocal] = useState<boolean>(true);
  const [inherited, setInherited] = useState<boolean>(true);
  const [overwriting, setOverwriting] = useState<boolean>(true);

  useEffect(
    () =>
      onFilterChange({
        local: local,
        inherited: inherited,
        overwriting: overwriting
      }),
    [local, inherited, overwriting, onFilterChange]
  );

  const onInheritedChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, selected: boolean) =>
      setInherited(selected),
    []
  );

  const onLocalChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, selected: boolean): void =>
      setLocal(selected),
    []
  );

  const onOverwrittenChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, selected: boolean): void =>
      setOverwriting(selected),
    []
  );

  return (
    <div className={rootClass}>
      <Typography className={classes.label}>
        {translate("sitestructure.variables.labels.filter")}
      </Typography>

      <FormControlLabel
        className={classes.checkboxLabel}
        label={translate("sitestructure.variables.status.inherited")}
        labelPlacement="end"
        control={
          <Checkbox
            className={classes.checkbox}
            checked={inherited}
            onChange={onInheritedChange}
          />
        }
      />
      <FormControlLabel
        className={classes.checkboxLabel}
        label={translate("sitestructure.variables.status.local")}
        labelPlacement="end"
        control={
          <Checkbox
            className={classes.checkbox}
            checked={local}
            onChange={onLocalChange}
          />
        }
      />
      <FormControlLabel
        className={classes.checkboxLabel}
        label={translate("sitestructure.variables.status.overwritten")}
        labelPlacement="end"
        control={
          <Checkbox
            className={classes.checkbox}
            checked={overwriting}
            onChange={onOverwrittenChange}
          />
        }
      />
    </div>
  );
};

export default VariableFilter;
