import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IconLabel } from "components/shared/iconLabel";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.custom.secondaryAccent.yellow
  },
  label: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13)
  },
  subLabel: {
    color: theme.palette.text.secondary
  }
}));

export const PendingChangesMessage = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <IconLabel
      className={rootClassName}
      icon="warning"
      text={translate("sitestructure.stageditems.pendingchanges")}
      subtext={translate("sitestructure.stageditems.pendingchangesdisclaimer")}
      TextClassName={classes.label}
      SubtextClassName={classes.subLabel}
      IconClassName={classes.icon}
    />
  );
};
