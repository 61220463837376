import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PickListPropertyInfo } from "models/componentProperty";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { translate } from "utils/i18n";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  pickList: PickListPropertyInfo;
  name: string;
  value: string | null;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const StrictPickListProperty = (props: IProps) => {
  const {
    className,
    pickList,
    name,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [localValue, setLocalValue] = useState(value);

  const [pickListValues, setPickListValues] = useState<string[]>([]);

  useEffect(() => {
    const values: string[] = pickList.values.map((v) => v.value);
    setPickListValues(values);
  }, [pickList.values]);

  const handleSelect = (
    event: React.ChangeEvent<{}>,
    newValue: string | null,
    reason: AutocompleteChangeReason
  ) => {
    if (localValue === newValue) {
      return;
    }
    setLocalValue(newValue);
    onChange(name, newValue || "");
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue(null);
    onChange(name, localValue ?? "");
  };

  return (
    <>
      <Autocomplete
        className={rootClassName}
        options={pickListValues}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            className={mandatory.showMessage ? mandatory.className : ""}
            placeholder={translate("picklist.typeaheadsuggestion")}
            variant="outlined"
            helperText={mandatory.showMessage ? mandatory.message : ""}
          />
        )}
        disabled={disabled}
        onChange={handleSelect}
        value={localValue}
        fullWidth
      />
      <VariableSwitch
        propertyName={name}
        propertyValue={localValue ?? ""}
        disabled={disabled}
        onChange={onChange}
        onSwitch={clearValueOnVariableSwitch}
        mandatory={mandatory}
      />
    </>
  );
};
