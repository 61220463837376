import { RolledBackNotification } from "@d3-forge/forge-notifications";
import { makeStyles } from "@material-ui/core";
import { useNotifications } from "components/hooks";
import { Scrollspy, ScrollSpyElem } from "components/shared/scrollspy";
import { ScrollspyLoading } from "components/shared/scrollspyLoading";
import { useMetadata } from "components/siteStructure/mainDetail/hooks";
import { MetadataNavItem } from "components/siteStructure/mainDetail/metadataNav";
import { MetadataSection } from "components/siteStructure/mainDetail/metadataSection";
import { IBaseProps } from "components/_baseProps";
import { IMetadataCategory } from "models/metadata";
import { ISiteItem } from "models/siteItem";
import { createRef, useCallback, useMemo, useRef } from "react";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  main: {},
  navSection: {
    position: "fixed",
    width: "15vw",
    overflowY: "auto",
    marginBottom: theme.spacing(2.5),
    height: "90vh",
    "@media (max-height: 1440px)": {
      height: "88vh"
    },
    "@media (max-height: 1200px)": {
      height: "85vh"
    },
    "@media (max-height: 900px)": {
      height: "82vh"
    },
    "@media (max-height: 800px)": {
      height: "79vh"
    },
    "@media (max-height: 600px)": {
      height: "75vh"
    }
  },
  catSection: {
    marginLeft: "15vw",
    [theme.breakpoints.up(1440)]: {
      minWidth: "50vw"
    },
    width: "80%",
    paddingBottom: "80vh",
    "@media (max-height: 1440px)": {
      paddingBottom: "75vh"
    },
    "@media (max-height: 1200px)": {
      paddingBottom: "70vh"
    },
    "@media (max-height: 900px)": {
      paddingBottom: "65vh"
    },
    "@media (max-height: 800px)": {
      paddingBottom: "58vh"
    },
    "@media (max-height: 600px)": {
      paddingBottom: "50vh"
    }
  },
  liStyle: {
    margin: theme.spacing(0.5, 0),
    fontSize: theme.typography.pxToRem(12)
  }
}));

export function MainDetailView(props: IProps) {
  const { isLoading, currentSiteItem } = props;
  const classes = useStyles();
  const container = useRef<HTMLDivElement | null>(null);

  const {
    metadata,
    isLoading: metadataIsLoading,
    refreshMetadata
  } = useMetadata(currentSiteItem);

  const format = useCallback(
    (elem) => ({
      ...elem,
      text: elem.displayName || elem.title || elem.id,
      node: createRef(),
      children: elem.properties ? elem.properties.map(format) : [],
      capitalize: Boolean(elem.displayName || elem.title)
    }),
    []
  );

  const formattedMetadata: ScrollSpyElem<IMetadataCategory>[] = useMemo(
    () => (metadata ?? []).map(format),
    [metadata, format]
  );

  useNotifications(
    ["RolledBackNotification"],
    (ntf: RolledBackNotification) => {
      refreshMetadata(ntf.aggregateId);
    }
  );

  if (isLoading || metadataIsLoading) {
    return <ScrollspyLoading />;
  }

  return (
    <div className={classes.root} ref={container}>
      <Scrollspy
        container={container}
        elements={formattedMetadata}
        linkItem={(linkProps) => <MetadataNavItem {...linkProps} />}
        navClassName={classes.navSection}
        liClassName={classes.liStyle}
      >
        {({ items }) => (
          <MetadataSection
            className={classes.catSection}
            categories={items}
            siteItem={currentSiteItem}
          />
        )}
      </Scrollspy>
    </div>
  );
}
