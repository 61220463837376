import { usePutAuthGroupDetail } from "api/queryHooks";
import {
  IAuthorizationGroup,
  IAuthorizationGroupDetailUpdateRequest
} from "models/authorizationGroup";
import { batch, useDispatch } from "react-redux";
import { displayNotification } from "redux/actions/notificationActions";
import {
  addSiteItemAuthGroups,
  removeSiteItemAuthGroups
} from "redux/actions/siteStructureActions";
import { getAuthGroupsFromString } from "utils/authorizationGroupUtils";

export function useLocalAuthGroups(nodeId: string) {
  const dispatch = useDispatch();
  const mutation = usePutAuthGroupDetail();

  const handleConfirm = (requestObj: IAuthorizationGroupDetailUpdateRequest) =>
    mutation.mutate(requestObj, {
      onSuccess: (data: IAuthorizationGroup) => {
        const addedGroupIds = getAuthGroupsFromString(data.groupId);
        batch(() => {
          dispatch(
            addSiteItemAuthGroups({
              nodeId: nodeId,
              authorizationGroups: addedGroupIds
            })
          );
          dispatch(
            displayNotification({
              message: "sitestructure.permissions.addgroupsuccess",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message: "sitestructure.permissions.addgroupfailure",
            severity: "error"
          })
        );
      }
    });

  const handleRemove = (requestObj: IAuthorizationGroupDetailUpdateRequest) =>
    mutation.mutate(requestObj, {
      onSuccess: (data: IAuthorizationGroup) => {
        const removedAuthGroups = getAuthGroupsFromString(data.groupId);
        batch(() => {
          dispatch(
            removeSiteItemAuthGroups({
              nodeId: nodeId,
              authorizationGroups: removedAuthGroups
            })
          );
          dispatch(
            displayNotification({
              message: "sitestructure.permissions.removegroupsuccess",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message: "sitestructure.permissions.removegroupfailure",
            severity: "error"
          })
        );
      }
    });

  return {
    handleConfirm,
    handleRemove
  };
}
