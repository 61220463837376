import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { VariableContract } from "@d3-forge/forge-commands";
import { UseMutationResult } from "react-query";
import { map, reduce, invoke } from "lodash";

import { useSiteItemURIVariables, useSiteItemVariables } from "api/queryHooks";
import { Scrollspy } from "components/shared/scrollspy";
import {
  KeyValueVariableModal,
  DataItemVariableModal,
  DataListVariableModal
} from "components/siteStructure/variables/modals";

import { ScrollspyLoading } from "components/shared/scrollspyLoading";
import AddVariableFab from "components/siteStructure/variables/view/addVariableFab";
import { VariableNavItem } from "components/siteStructure/variables/view/variableNavItem";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import {
  IVariableContract,
  IVariableFilter,
  IVariableModalAction,
  IVariableSortConfig,
  IVariableType,
  ValidationResult,
  VariableDetailType
} from "models/variables";
import {
  loadPageVariables,
  loadVariablesModalAction
} from "redux/actions/variablesActions";
import {
  selectModalAction,
  selectPageVariables
} from "redux/selectors/variablesSelectors";
import { translate } from "utils/i18n";
import {
  categorizeVariables,
  orderVariables,
  getIsCultureEnvironmentCombinationUnique,
  getIsVariableNameUnique,
  shouldShowVariable,
  VariableModalAction,
  VariableType,
  VariableTypeKey,
  transformVariableDetailWithType
} from "utils/variableUtils";
import { VariableTypeList } from "components/siteStructure/variables/view/variableTypeList";
import { useNotifications } from "../../hooks";
import {
  DirectoryVariableAddedNotification,
  DirectoryVariableDeletedNotification,
  DirectoryVariableUpdatedNotification,
  PageVariableAddedNotification,
  PageVariableDeletedNotification,
  PageVariableUpdatedNotification,
  RolledBackNotification
} from "@d3-forge/forge-notifications";
import VariableFilter from "./view/variableFilter";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { useVariableCommands } from "components/siteStructure/variables/hooks";
import VariablesSorting from "components/siteStructure/variables/view/variablesSorting";

type VariablesRequest = UseMutationResult<
  IVariableContract[],
  Error,
  string,
  unknown
>;

type PageVariableNotification =
  | PageVariableAddedNotification
  | PageVariableUpdatedNotification
  | PageVariableDeletedNotification;

type DirectoryVariableNotification =
  | DirectoryVariableAddedNotification
  | DirectoryVariableUpdatedNotification
  | DirectoryVariableDeletedNotification;

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
  isLoading: boolean;
}

const getShouldRenderModal = (modalAction: IVariableModalAction) => {
  const { action, type } = modalAction;

  const variableTypes = map(
    [VariableType.KEY_VALUE, VariableType.DATA_ITEM, VariableType.DATA_LIST],
    "type"
  );

  const statesMap = reduce(
    variableTypes,
    (result, currentType) => ({
      ...result,
      [currentType]: action !== "none" && currentType === type
    }),
    {} as Record<string, boolean>
  );

  return statesMap;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  main: {},
  nav: {
    position: "fixed",
    width: "15vw",
    height: "fit-content",
    overflowY: "auto",
    marginBottom: theme.spacing(2.5)
  },
  section: {
    marginLeft: "15vw",
    [theme.breakpoints.up(1440)]: {
      minWidth: "50vw"
    },
    width: "80%"
  },
  liStyle: {
    margin: theme.spacing(0.5, 0),
    fontSize: theme.typography.pxToRem(12)
  },
  fab: {},
  filter: {
    marginBottom: theme.spacing(3.5) // 28px
  }
}));

const DEFAULT_SORT_CONFIG: IVariableSortConfig = {
  type: "Position",
  order: "Ascending"
};

export function VariablesView(props: IProps) {
  const { isLoading, currentSiteItem } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const { addVariable, updateVariable, overwriteVariable } =
    useVariableCommands(currentSiteItem);
  const container = useRef<HTMLDivElement | null>(null);

  const modalAction = useSelector(selectModalAction);
  const standardVariablesRequest = useSiteItemVariables();
  const uriVariablesRequest = useSiteItemURIVariables();

  const variables = useSelector(selectPageVariables);

  const { action } = modalAction;

  const sendVariableCommand = useCallback(
    (contract: VariableContract) => {
      const actionMap = {
        [VariableModalAction.CREATE]: addVariable,
        [VariableModalAction.EDIT]: updateVariable,
        [VariableModalAction.OVERWRITE]: overwriteVariable
      };

      invoke(actionMap, action, contract);
    },
    [action, addVariable, updateVariable, overwriteVariable]
  );

  const openModal = useCallback(
    (type) => {
      dispatch(
        loadVariablesModalAction({
          action: VariableModalAction.CREATE,
          type
        })
      );
    },
    [dispatch]
  );

  const [filter, setFilter] = useState<IVariableFilter>({
    inherited: true,
    local: true,
    overwriting: true
  });

  const [sortConfig, setSorting] =
    useState<IVariableSortConfig>(DEFAULT_SORT_CONFIG);

  const format = useCallback(
    (elem) => ({
      ...elem,
      text: translate(`sitestructure.variables.name.${elem.type.type}`),
      node: createRef(),
      children: [],
      capitalize: true
    }),
    []
  );

  useEffect(() => {
    standardVariablesRequest.mutate(currentSiteItem.nodeId);
    uriVariablesRequest.mutate(currentSiteItem.nodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSiteItem.nodeId]);

  const handleOnLoadVariables = useCallback(
    (variableRequest: Partial<VariablesRequest>, type: VariableDetailType) => {
      const transformedVariables = transformVariableDetailWithType(
        variableRequest.data || []
      );

      if (variableRequest.isSuccess) {
        dispatch(
          loadPageVariables({
            variables: transformedVariables,
            detailType: type
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    handleOnLoadVariables(
      {
        data: uriVariablesRequest.data,
        isSuccess: uriVariablesRequest.isSuccess
      },
      "URI"
    );
  }, [
    uriVariablesRequest.data,
    uriVariablesRequest.isSuccess,
    handleOnLoadVariables
  ]);

  useEffect(() => {
    handleOnLoadVariables(
      {
        data: standardVariablesRequest.data,
        isSuccess: standardVariablesRequest.isSuccess
      },
      "STANDARD"
    );
  }, [
    standardVariablesRequest.data,
    standardVariablesRequest.isSuccess,
    handleOnLoadVariables
  ]);

  useNotifications(
    [
      "PageVariableAddedNotification",
      "DirectoryVariableAddedNotification",
      "PageVariableUpdatedNotification",
      "DirectoryVariableUpdatedNotification",
      "PageVariableDeletedNotification",
      "DirectoryVariableDeletedNotification"
    ],
    (ntf: PageVariableNotification | DirectoryVariableNotification) => {
      if (ntf.itemId === currentSiteItem.nodeId) {
        // force refresh once the command has been handled
        standardVariablesRequest.mutate(currentSiteItem.nodeId);
        uriVariablesRequest.mutate(currentSiteItem.nodeId);
      }
    }
  );

  useNotifications(
    ["RolledBackNotification"],
    (ntf: RolledBackNotification) => {
      if (ntf.aggregateId === currentSiteItem.nodeId) {
        standardVariablesRequest.mutate(ntf.aggregateId);
        uriVariablesRequest.mutate(ntf.aggregateId);
      }
    }
  );

  const canEdit =
    userService.hasPermissions(PermissionCodes.EditVariables) ||
    userService.hasSiteItemPermissions(currentSiteItem.path);

  const onToggleModal = useCallback(
    (variableType: IVariableType) => {
      const typeKey = variableType.type as VariableTypeKey;
      openModal(typeKey);
    },
    [openModal]
  );

  const onCloseModal = useCallback(() => {
    dispatch(
      loadVariablesModalAction({
        action: VariableModalAction.NONE,
        type: "none",
        isTranslateMode: false,
        variable: undefined,
        variableDetail: undefined
      })
    );
  }, [dispatch]);

  const getModalValidations = useCallback(
    (
      name: string,
      culture: string,
      environment: string,
      action: string
    ): ValidationResult => {
      const validationResult: ValidationResult = {
        isUnique: false,
        isCultureEnvironmentCombinationAvailable: false
      };

      const { variable, isTranslateMode } = modalAction;

      if (action !== VariableModalAction.CREATE) {
        validationResult.isUnique = true;
        validationResult.isCultureEnvironmentCombinationAvailable = true;

        return validationResult;
      }

      const isUnique =
        isTranslateMode || getIsVariableNameUnique(variables, name);

      const isCultureEnvironmentCombinationAvailable =
        !isTranslateMode ||
        (Boolean(variable) &&
          getIsCultureEnvironmentCombinationUnique(
            variable as IVariableContract,
            culture,
            environment
          ));

      return {
        ...validationResult,
        isUnique,
        isCultureEnvironmentCombinationAvailable
      };
    },
    [variables, modalAction]
  );

  const formattedVariables = useMemo(() => {
    const filtered = variables.filter((variable) =>
      shouldShowVariable(variable, filter)
    );

    const categorized = categorizeVariables(filtered);
    return orderVariables(categorized, sortConfig).map(format);
  }, [variables, sortConfig, filter, format]);

  const linkItem = useCallback(
    (linkProps) => <VariableNavItem {...linkProps} />,
    []
  );

  if (
    isLoading ||
    uriVariablesRequest.isLoading ||
    standardVariablesRequest.isLoading
  ) {
    return <ScrollspyLoading />;
  }

  // HACK: nav item is hidden inside scrollspy => use selector to obtain element
  // get element rect to determine position of filter div
  const nav = document.querySelector(`.${classes.nav}`);
  const rect = nav?.getBoundingClientRect();
  const optionsDivPosition = (rect?.bottom ?? 0) + 48;

  const optionsContainerStyle: React.CSSProperties = {
    position: "absolute",
    top: `${optionsDivPosition}px`
  };

  const shouldRenderModal = getShouldRenderModal(modalAction);

  return (
    <div className={classes.root} ref={container}>
      <div style={optionsContainerStyle}>
        <VariableFilter onFilterChange={setFilter} className={classes.filter} />
        <VariablesSorting
          sortConfig={sortConfig}
          onSortingChange={setSorting}
        />
      </div>
      <Scrollspy
        container={container}
        elements={formattedVariables}
        linkItem={linkItem}
        navClassName={classes.nav}
        liClassName={classes.liStyle}
      >
        {({ items }) => (
          <div className={classes.section}>
            {items.map((variables) => (
              <VariableTypeList
                key={variables.hash}
                variables={variables}
                siteItem={currentSiteItem}
              />
            ))}
          </div>
        )}
      </Scrollspy>

      {canEdit && (
        <AddVariableFab className={classes.fab} onClick={onToggleModal} />
      )}

      {shouldRenderModal[VariableType.KEY_VALUE.type] && (
        <KeyValueVariableModal
          sendVariableCommand={sendVariableCommand}
          onCloseModal={onCloseModal}
          getModalValidations={getModalValidations}
        />
      )}
      {shouldRenderModal[VariableType.DATA_ITEM.type] && (
        <DataItemVariableModal
          sendVariableCommand={sendVariableCommand}
          onCloseModal={onCloseModal}
          getModalValidations={getModalValidations}
        />
      )}
      {shouldRenderModal[VariableType.DATA_LIST.type] && (
        <DataListVariableModal
          sendVariableCommand={sendVariableCommand}
          onCloseModal={onCloseModal}
          getModalValidations={getModalValidations}
        />
      )}
    </div>
  );
}

export default VariablesView;
