import { Action } from "redux-actions";
import { getTransformedLinkRulesEntityListFromPayload } from "components/linkRules/utils";
import { ILinkRulesState } from "models/domainStates";
import { ILinkRulesEntityList } from "models/linkRules";
import {
  loadLinkRulesEntityList,
  setSelectedLinkRuleId
} from "redux/actions/linkRulesActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export const initialState: ILinkRulesState = {
  linkRulesEntityList: {},
  selectedLinkRuleId: null,
  linkRulesUrlOccurrencesCount: {}
};

const reactions = {
  [loadLinkRulesEntityList.toString()]: (
    state: ILinkRulesState,
    action: Action<ILinkRulesEntityList>
  ) => {
    const transformedLinkRulesEntityList =
      getTransformedLinkRulesEntityListFromPayload(action.payload);

    state.linkRulesEntityList = transformedLinkRulesEntityList;
  },
  [setSelectedLinkRuleId.toString()]: (
    state: ILinkRulesState,
    action: Action<string>
  ) => {
    state.selectedLinkRuleId = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
