import { makeStyles } from "@material-ui/core";
import { ModuleSkeleton } from "components/skeletons";
import range from "lodash/range";

const useStyles = makeStyles((theme) => ({
  skeletonContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey97,
    padding: theme.spacing(3),
    gap: theme.spacing(2)
  },
  skeletonRow: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 1)
  }
}));

export function SkeletonMenuDetail() {
  const classes = useStyles();
  return (
    <div className={classes.skeletonContainer}>
      {range(9).map((v) => (
        <ModuleSkeleton key={v} className={classes.skeletonRow} size={6} />
      ))}
    </div>
  );
}
