import {
  Typography,
  TextField,
  makeStyles,
  capitalize
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import classnames from "classnames";
import { useMemoSelector } from "components/hooks";
import { D3Modal } from "components/shared/d3Components/d3Modal";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { validateName } from "components/siteStructure/modals/createSiteItemModal/createSiteItemUtils";
import { IBaseProps } from "components/_baseProps";
import {
  SiteNodeAction,
  SiteNodeActions,
  SiteNodeType,
  SiteNodeTypes
} from "models/siteStructure";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectContexts,
  selectDefaultContext
} from "redux/selectors/assetCatalogSelectors";
import { makeSelectSiteItemChildren } from "redux/selectors/siteStructureSelectors";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  open: boolean;
  parentId: string;
  parentPath: string;
  siteItemType: SiteNodeType;
  defaultValue?: string;
  onConfirm?: (type: SiteNodeType, name: string, contextName: string) => void;
  onCancel?: () => void;
  type: SiteNodeType;
  action: SiteNodeAction;
  index?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  path: {
    fontSize: theme.typography.pxToRem(14)
  },
  fieldlabel: {
    marginTop: theme.spacing(2)
  },
  noErrorTextField: {
    marginBottom: theme.spacing(3)
  }
}));

export function SiteItemModal(props: IProps) {
  const {
    className,
    open,
    parentId,
    parentPath,
    siteItemType,
    defaultValue,
    onConfirm = () => undefined,
    onCancel = () => undefined,
    type,
    action,
    index = false
  } = props;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const contexts = useSelector(selectContexts);
  const pageTypes = contexts.map((context) => context.contextName);
  const defaultPageType = useSelector(selectDefaultContext);
  const initialSiteItemName = index ? "index" : defaultValue || "";

  const [inputValue, setInputValue] = useState(initialSiteItemName);
  const [pageType, setPageType] = useState(defaultPageType);

  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>();

  const modalTitle = translate(
    `sitestructure.siteitemmodal.${action}${type}`.toLowerCase()
  );
  const siblings = useMemoSelector(makeSelectSiteItemChildren, parentId);
  const formattedParentPath = useMemo(
    () => parentPath.replace("~", "~ (root)").replace(/\//g, " / "),
    [parentPath]
  );

  const getConfirmLabel = () => {
    switch (action) {
      case SiteNodeActions.CREATE:
        return translate("general.create");
      case SiteNodeActions.RENAME:
        return translate("general.rename");
      default:
        return translate("general.confirm");
    }
  };

  const handleConfirm = () => {
    const validationMessage = validateName(inputValue, siblings);
    validationMessage
      ? setError(translate(validationMessage))
      : onConfirm(siteItemType, inputValue, pageType);

    setInputValue("");
  };

  const handleCancel = () => {
    clearFields();
    onCancel();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);

    const validationMessage = validateName(value, siblings);
    validationMessage ? setError(translate(validationMessage)) : setError("");
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleConfirm();
    }
  };

  const handlePageTypeChange = (event, newValue) => setPageType(newValue);

  const currentPath = useMemo(() => {
    if (!inputValue) {
      return formattedParentPath;
    }

    return `${formattedParentPath} ${inputValue} ${
      siteItemType === SiteNodeTypes.DIRECTORY ? " /" : ""
    }`;
  }, [siteItemType, formattedParentPath, inputValue]);

  const clearFields = () => {
    setInputValue("");
    setPageType(defaultPageType);
  };

  return (
    <D3Modal
      className={rootClassName}
      open={open}
      modalTitle={modalTitle}
      cancelLabel={translate("general.cancel")}
      disabled={Boolean(error)}
      confirmLabel={getConfirmLabel()}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    >
      <TextFieldLabel value={translate("general.path")} />
      <Typography className={classes.path} gutterBottom={true}>
        {currentPath}
      </Typography>

      {type === SiteNodeTypes.PAGE && action !== SiteNodeActions.RENAME && (
        <>
          <TextFieldLabel
            className={classes.fieldlabel}
            value={translate("sitestructure.siteitemmodal.pageType")}
          />
          <Autocomplete
            options={pageTypes}
            getOptionLabel={capitalize}
            size="small"
            disableClearable
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            value={pageType}
            onChange={handlePageTypeChange}
          />
        </>
      )}

      <TextFieldLabel
        className={classes.fieldlabel}
        value={translate("general.name")}
      />
      <TextField
        inputRef={inputRef}
        className={error ? "" : classes.noErrorTextField}
        size="small"
        autoFocus={true}
        fullWidth={true}
        inputProps={{ value: inputValue }}
        value={inputValue}
        error={Boolean(error)}
        helperText={error}
        variant="outlined"
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        disabled={index}
      />
    </D3Modal>
  );
}
