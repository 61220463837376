import {
  ListItemProps,
  ListItem,
  ListItemText,
  makeStyles,
  ListItemIcon,
  Tooltip
} from "@material-ui/core";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { useRouteMatch } from "react-router-dom";
import { translate } from "utils/i18n";

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button disableRipple component="a" {...props} />;
}

interface IProps extends IBaseProps {
  authId: string;
  name: string;
  description?: string;
  selected?: boolean;
  create?: boolean;
  canRemove?: boolean;
  handleRemove?: (event: React.MouseEvent, groupId: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(1, 1.5, 1, 2),
    margin: theme.spacing(0.5, 0, 0),
    transition: "all .3s ease-in-out",
    color: theme.palette.custom.ds.grayscales.gr800,
    "& .MuiListItemText-root": {
      margin: 0
    },
    "&:hover": {
      transition: "all .3s ease-in-out",
      backgroundColor: theme.palette.custom.ds.grayscales.gr200,
      "& .MuiListItemIcon-root": {
        display: "inline"
        /*margin: "auto"*/
      }
    },
    "&$selected, &$create": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      "&:before": {
        content: '""',
        top: 0,
        left: 0,
        width: theme.spacing(0.5),
        height: "100%",
        position: "absolute",
        backgroundColor: theme.palette.custom.ds.viking.viking500
      }
    },
    "&$create": {
      color: theme.palette.custom.ds.grayscales.gr600
    },
    "& .MuiListItemIcon-root": {
      display: "none",
      minWidth: "0px",
      position: "absolute",
      right: "10px",
      top: "30%"
    }
  },
  itemLinkContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  row: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    justifyContent: "space-between"
  },
  selected: {},
  create: {},
  itemTitle: {
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      marginBottom: theme.spacing(0.5)
    }
  },
  itemDescription: {
    "& .MuiListItemText-secondary": {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(19),
      color: theme.palette.custom.ds.grayscales.gr600
    }
  }
}));

export function AuthGroupListItem(props: Readonly<IProps>) {
  let { url } = useRouteMatch();
  const { authId, name, className, canRemove, handleRemove, description } =
    props;

  const classes = useStyles();

  const rootClassName = classNames(classes.root, className, {
    [classes.selected]: props.selected,
    [classes.create]: props.create
  });

  return (
    <ListItemLink className={rootClassName} href={`#${url}/${authId}`}>
      <div className={classes.itemLinkContainer}>
        <div className={classes.row}>
          <ListItemText primary={name} className={classes.itemTitle} />
          {canRemove && (
            <Tooltip
              placement="top"
              arrow
              title={translate("permissions.list.removetooltip")}
            >
              <ListItemIcon onClick={(event) => handleRemove?.(event, authId)}>
                <UxdIcon outline={true} name={"delete_outline"} />
              </ListItemIcon>
            </Tooltip>
          )}
        </div>
        {description !== undefined && (
          <div className={classes.row}>
            <ListItemText
              secondary={description}
              className={classes.itemDescription}
            />
          </div>
        )}
      </div>
    </ListItemLink>
  );
}
