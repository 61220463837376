import React, { useContext, createContext } from "react";

import {
  IMultiSelectResult,
  useMultiSelect
} from "components/shared/multiSelect/hooks";
import { useContextValue } from "components/hooks";

export interface AuthorizationGroupUsersSelectContextValue
  extends IMultiSelectResult {}

export const MenuBuilderContext = createContext(
  {} as AuthorizationGroupUsersSelectContextValue
);

export const AuthorizationGroupUsersSelectContextProvider: React.FC = ({
  children
}) => {
  const multiSelectResult = useMultiSelect();

  const contextValue =
    useContextValue<AuthorizationGroupUsersSelectContextValue>(
      multiSelectResult
    );

  return (
    <MenuBuilderContext.Provider value={contextValue}>
      {children}
    </MenuBuilderContext.Provider>
  );
};

export const useAuthorizationGroupUsersSelectContext =
  (): AuthorizationGroupUsersSelectContextValue => {
    const context = useContext(MenuBuilderContext);
    return context;
  };
