import { Avatar, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import React from "react";

interface IProps extends IBaseProps {
  initials: string;
}

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: theme.typography.pxToRem(32),
    height: theme.typography.pxToRem(32),
    backgroundColor: theme.palette.custom.ds.fiord.fiord300,
    color: theme.palette.custom.ds.fiord.fiord700,
    fontSize: theme.typography.pxToRem(14)
  }
}));

export const UserAvatar = React.memo((props: IProps) => {
  const { initials } = props;
  const classes = useStyles(props);

  return <Avatar className={classes.userAvatar}>{initials}</Avatar>;
});
