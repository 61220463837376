import { useMemo } from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import MenuItemsList from "components/siteStructure/menuView/components/menuItemsList";
import { ActionSnackbar } from "components/shared/actionSnackbar";
import { useMenuBuilderContext } from "components/siteStructure/menuView/context";

import {
  getMenuItemsCount,
  getMenuItemsCountTranslationKey,
  getSnackbarPropsByPurpose
} from "components/siteStructure/menuView/utils";
import { IMenuItem } from "models/menus";

interface IProps extends IBaseProps {
  menuItems: IMenuItem[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    paddingBottom: theme.spacing(4)
  },
  snackBar: {
    "& strong": {
      margin: theme.spacing(0, 1)
    },
    transition: "opacity .3s ease-in"
  },
  snackBarLoading: {
    opacity: 0.9
  },
  header: {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: theme.typography.pxToRem(14),
    height: "40px",
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    position: "relative",
    top: theme.spacing(1)
  }
}));

const MenuItemsContainer = (props: IProps) => {
  const { className, menuItems } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const {
    placementModeMetadata,
    isPlacementModeActive,
    loaders,
    onCancelPlacementMode
  } = useMenuBuilderContext();

  const menuItemsCountTranslationKey =
    getMenuItemsCountTranslationKey(menuItems);

  const menuItemsCount = useMemo(
    () => getMenuItemsCount(menuItems),
    [menuItems]
  );

  const snackBarClassnames = classnames(classes.snackBar, {
    [classes.snackBarLoading]: loaders.isCreatingMenuItem
  });

  const snackbarProps = useMemo(
    () => getSnackbarPropsByPurpose(placementModeMetadata?.purpose, loaders),
    [loaders, placementModeMetadata]
  );

  return (
    <div className={rootClassName}>
      <Typography className={classes.header}>
        {translate(menuItemsCountTranslationKey, {
          quantity: menuItemsCount
        })}
      </Typography>
      <MenuItemsList menuItems={menuItems} level={0} />
      <ActionSnackbar
        className={snackBarClassnames}
        show={isPlacementModeActive}
        onCancel={onCancelPlacementMode}
        {...snackbarProps}
      />
    </div>
  );
};

export default MenuItemsContainer;
