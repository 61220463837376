import classnames from "classnames";
import { makeStyles, Tooltip } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { ForgeEntityStage } from "models/forgeEntities";

interface IProps extends IBaseProps {
  stage: ForgeEntityStage;
  size?: "small" | "large";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  small: {
    fontSize: theme.spacing(1)
  },
  large: {
    fontSize: theme.spacing(1.5)
  },
  published: {
    color: theme.palette.custom.stages.published
  },
  reviewed: {
    color: theme.palette.custom.stages.reviewed
  },
  unpublished: {
    color: theme.palette.custom.stages.unpublished
  }
}));

export const StageIcon = (props: IProps) => {
  const { className, stage, size = "large" } = props;
  const classes = useStyles();

  const lowerizedStage = stage.toLowerCase();

  const rootClassName = classnames(
    classes.root,
    classes[lowerizedStage],
    classes[size],
    className
  );

  return (
    <Tooltip title={translate(`visibilitystatuses.${lowerizedStage}`)}>
      <UxdIcon className={rootClassName} name="circle" outline={false} />
    </Tooltip>
  );
};
