import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import LinkRulesEntityNav from "components/linkRules/linkRulesEntityNav";
import { IBaseProps } from "components/_baseProps";
import { setCurrentRoute } from "redux/actions/appActions";
import { linkRulesRoute } from "routes/routes";
import LinkRuleDetail from "components/linkRules/linkRuleDetail";
import { LinkRulesScrollingBehaviorContextProvider } from "components/linkRules/context/scrollManagement";
import { LinkRulesCrudManagementContextProvider } from "components/linkRules/context/crudManagement";

import { selectIsLinkRulesSidebarOpen } from "redux/selectors/linkRulesSelectors";
import LinkRulesSidebar from "components/linkRules/linkRulesSidebar";
import { SearchManagementContextProvider } from "components/linkRules/context/searchManagement";
import { useNotificationGroup } from "components/hooks";
import notificationService from "services/notificationService";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: "100%"
  },
  mainContent: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.custom.ds.grayscales.gr200
  }
}));

const LinkRules = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();

  useNotificationGroup(notificationService.VSM_GROUP_NAME);

  const isSidebarOpen = useSelector(selectIsLinkRulesSidebarOpen);

  useEffect(() => {
    dispatch(
      setCurrentRoute({
        name: linkRulesRoute.name,
        path: linkRulesRoute.path
      })
    );
  }, [dispatch]);

  return (
    <SearchManagementContextProvider>
      <LinkRulesScrollingBehaviorContextProvider>
        <div className={rootClassName}>
          <LinkRulesEntityNav />
          <LinkRulesCrudManagementContextProvider>
            <Fragment>
              <div className={classes.mainContent}>
                <LinkRuleDetail />
              </div>
              {isSidebarOpen && <LinkRulesSidebar />}
            </Fragment>
          </LinkRulesCrudManagementContextProvider>
        </div>
      </LinkRulesScrollingBehaviorContextProvider>
    </SearchManagementContextProvider>
  );
};

export default LinkRules;
