import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import classnames from "classnames";

interface IProps extends IBaseProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  dialogTitle: {
    textTransform: "capitalize"
  }
}));

export const UserTokenErrorDialog = (props: IProps) => {
  const { open, onClose, className } = props;
  const classes = useStyles();
  const dialogTitleClassName = classnames(classes.dialogTitle, className);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={dialogTitleClassName}>
        user authentication error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          An error has occurred with the user authentication, you will be logged
          out from the system. After that, try to login again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go to logout</Button>
      </DialogActions>
    </Dialog>
  );
};
