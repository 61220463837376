import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  linkPicker: {
    "& .MuiInputBase-input": {
      // input
      cursor: "pointer !important"
    },
    "& .MuiInputBase-root": {
      // adornments
      cursor: "pointer !important"
    }
  },
  searchIcon: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  statusIcon: {
    padding: theme.spacing(0, 1)
  }
}));
