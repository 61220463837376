import { useCallback, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, Typography, IconButton, Tooltip } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IModule } from "models/pages";
import { PageElementIcon } from "./pageElementIcon";
import { AssetNotFound } from "./assetNotFound";
import { makeIsSelectedPageElement } from "redux/selectors/siteStructureSelectors";
import { translate } from "utils/i18n";
import { ActionButton } from "components/shared/actionButton";
import { useMemoSelector } from "components/hooks";
import { makeSelectAssetsDefinition } from "redux/selectors/assetCatalogSelectors";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { UxdIcon } from "components/shared/uxdIcon";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { ModulePropertiesPreview } from "components/siteStructure/pageView/pageElements/modulePropertiesPreview";
import { PermissionCodes } from "catalogs/permissionCodes";
import { useDispatch } from "react-redux";
import { setPageRequiredModuleWarning } from "redux/actions/siteStructureActions";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import ModuleActions from "components/siteStructure/pageView/pageElements/moduleActions";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";

interface IProps extends IBaseProps {
  module: IModule;
  orphan?: boolean;
  isDisabled?: boolean;
  onSelect: (element: IModule) => void;
  onMove: (element: IModule) => void;
  onDelete: (element: IModule) => void;
  onCopy: (element: IModule) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.custom.ds.fiord.fiord300}`,
    padding: theme.spacing(3),
    cursor: "pointer",
    overflow: "hidden",
    margin: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("box-shadow"),
    "&:not($noHover):hover:not(:active)": {
      boxShadow: theme.palette.custom.ds.shadow.s300,
      "& $buttonContainer": {
        visibility: "visible",
        width: "100%"
      }
    },
    "&$noHover$selected": {
      "& $buttonContainer": {
        width: "100%"
      }
    },
    "&:active": {
      border: `2px solid ${theme.palette.custom.ds.viking.viking300}`,
      padding: theme.spacing(3) - 1,
      boxShadow: "unset",
      "& $buttonContainer": {
        visibility: "visible",
        width: "100%"
      },
      "& $buttonContainer > button": {
        paddingRight: theme.spacing(1.5) - 1
      },
      "& $actionRow": {
        top: theme.spacing(2) - 1
      }
    },
    "&$selected": {
      "& $buttonContainer > button": {
        paddingRight: theme.spacing(1.5) - 1
      },
      "& $actionRow": {
        top: theme.spacing(2) - 1
      }
    }
  },
  moduleData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  moduleIdContainer: {
    display: "flex",
    alignItems: "center",
    gap: ".2em"
  },
  moduleId: {
    color: theme.palette.custom.ds.grayscales.gr600,
    display: "block",
    fontSize: "0.625rem",
    "&$disabled": {
      color: theme.palette.custom.ds.grayscales.gr500
    }
  },
  moduleLabel: {
    fontSize: "0.875rem",
    color: theme.palette.custom.ds.grayscales.gr800,
    fontWeight: 400,
    "&$disabled": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  },
  notFound: {
    flex: 1
  },
  requiredIcon: {
    color: theme.palette.custom.icons.noAction,
    fontSize: theme.typography.pxToRem(12),
    opacity: 1,
    padding: theme.spacing(1.5)
  },
  buttonContainer: {
    visibility: "hidden",
    width: 0,
    fontSize: "1rem"
  },
  button: {
    color: theme.palette.custom.icons.noAction,
    "&:hover": {
      color: theme.palette.custom.ds.viking.viking500,
      backgroundColor: "transparent"
    },
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    fontSize: theme.typography.pxToRem(12)
  },
  orphan: {
    borderColor: theme.palette.custom.ds.warning.warning500,
    backgroundColor: theme.palette.custom.ds.warning.warning100,
    "& $moduleLabel": {
      color: theme.palette.custom.ds.warning.warning700
    },

    "&:active": {
      borderColor: theme.palette.custom.ds.viking.viking300
    },
    "&$selected": {
      border: `2px solid ${theme.palette.custom.ds.warning.warning700}`
    }
  },
  orphanIcon: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.custom.ds.warning.warning500
  },
  selectedDefault: {
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    backgroundColor: theme.palette.custom.ds.viking.viking100,
    padding: theme.spacing(3) - 1,
    "& $buttonContainer": {
      top: theme.spacing(0.5) - 1,
      right: theme.spacing(0.5) - 1
    },
    "& $moduleLabel": {
      color: theme.palette.custom.ds.viking.viking700
    }
  },

  selected: {},
  visible: {
    visibility: "visible"
  },

  actionRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacing(2),
    top: theme.spacing(2),
    right: theme.spacing(0),
    position: "absolute"
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1)
  },
  actionIcon: {
    fontSize: "1rem"
  },
  contextMenuItem: {
    fontSize: "0.8rem"
  },
  contextMenuItemIcon: {
    fontSize: "1rem",
    color: theme.palette.custom.ds.viking.viking500,
    margin: theme.spacing(0, 1)
  },
  icon: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord500,
    "&$orphanIcon": {
      backgroundColor: theme.palette.custom.ds.warning.warning500
    },
    "&$selected": {
      backgroundColor: theme.palette.custom.ds.viking.viking500
    },
    "&$disabled": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr600
    }
  },
  noHover: {
    cursor: "default",
    "&:not($selectedDefault)": {
      marginBottom: theme.spacing(0.5) + 1,
      marginTop: theme.spacing(0.5) + 1
    }
  },
  warning: {
    color: theme.palette.custom.ds.warning.warning500,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    "&$disabled": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  },
  locked: {
    color: theme.palette.custom.ds.grayscales.gr700,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18)
  },
  disabled: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    borderColor: theme.palette.custom.ds.fiord.fiord300
  }
}));

function customModuleId(module: IModuleDefinition) {
  switch (module.id) {
    case "RichTextEditor":
      return "Text";
    case "GraphicAssetDashboardModule":
      return "Image";
    default:
      return module.id;
  }
}

export const Module = (props: IProps) => {
  const {
    className,
    module,
    orphan = false,
    isDisabled = false,
    onSelect,
    onMove,
    onDelete,
    onCopy
  } = props;
  const classes = useStyles();
  const [showWarningFlag, setShowWarningFlag] = useState(false);
  const dispatch = useDispatch();

  const isSelected = useMemoSelector(
    makeIsSelectedPageElement,
    module.instanceId
  );

  const showDisabledStyle = isDisabled && !isSelected;

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);
  const layoutContext = useContext(ModuleLayoutContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const moduleDefinition: IModuleDefinition | undefined = useMemoSelector(
    makeSelectAssetsDefinition,
    AssetTypes.MODULE,
    module.key
  );

  const showWarning = useCallback(() => {
    if (moduleDefinition === undefined) {
      return false;
    }

    const propertiesCategories = Object.keys(moduleDefinition.properties || {});
    const mandatoryProperties = propertiesCategories.flatMap((category) => {
      return moduleDefinition?.properties[category]?.filter(
        (x) => x.mandatory && !module.properties[x.name]
      );
    });

    return mandatoryProperties?.length > 0;
  }, [moduleDefinition, module.properties]);

  useEffect(() => {
    const flag = showWarning();
    dispatch(setPageRequiredModuleWarning({ [module.id]: flag }));
    setShowWarningFlag(flag);
  }, [module.properties, module.id, showWarning, setShowWarningFlag, dispatch]);

  if (moduleDefinition === undefined) {
    return (
      <AssetNotFound
        className={classes.notFound}
        assetType={translate("sitestructure.module")}
        assetName={module.key}
        callToAction={
          <ActionButton
            icon="delete_outline"
            permissions={[PermissionCodes.EditContent]}
            tooltip={translate("sitestructure.removemodule")}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              onDelete(module);
            }}
          />
        }
      />
    );
  }

  const rootClassName = classnames(classes.root, className, {
    [classes.orphan]: orphan && !isSelected && layoutContext.length === 1,
    [classes.selected]: isSelected,
    [classes.disabled]: showDisabledStyle,
    [classes.selectedDefault]: isSelected,
    [classes.noHover]:
      dropContext.dropMode === "move" ||
      dropContext.dropMode === "add" ||
      copyContext.copyMode === "copy"
  });

  const buttonContainerClassName = classnames(classes.buttonContainer, {
    [classes.visible]:
      (dropContext.dropMode === "move" || copyContext.copyMode === "copy") &&
      (dropContext.pageElementIdentifier?.instanceId === module.instanceId ||
        copyContext.pageElementIdentifier?.instanceId === module.instanceId)
  });

  const elementIconClassName = classnames(classes.icon, {
    [classes.orphanIcon]: orphan,
    [classes.selected]: isSelected,
    [classes.disabled]: showDisabledStyle
  });

  const moduleLabelClassName = classnames(classes.moduleLabel, {
    [classes.disabled]: showDisabledStyle
  });

  const moduleIdClassName = classnames(classes.moduleId, {
    [classes.disabled]: showDisabledStyle
  });

  const warningClassName = classnames(classes.warning, {
    [classes.disabled]: showDisabledStyle
  });

  let modulePropertiesVariation = "default";
  if (orphan && !isSelected) {
    modulePropertiesVariation = "orphan";
  } else if (isSelected) {
    modulePropertiesVariation = "selected";
  } else if (showDisabledStyle) {
    modulePropertiesVariation = "disabled";
  }

  const lockIcon = <UxdIcon name="lock" className={classes.locked} />;
  const warnIcon = <UxdIcon name="warning" className={warningClassName} />;

  const moduleIcon = () => {
    const showIcon = isDisabled || showWarningFlag;
    if (!showIcon) return <></>;

    const icon = isDisabled ? lockIcon : warnIcon;
    const tooltip = isDisabled
      ? translate("sitestructure.lockedmodule")
      : translate("sitestructure.moduleproperties.required.tooltip");

    return (
      <Tooltip title={tooltip}>
        <span>{icon}</span>
      </Tooltip>
    );
  };

  const icon = moduleIcon();

  return (
    <div className={rootClassName} onClick={() => onSelect(module)}>
      <div className={classes.actionRow}>
        {icon}
        <div className={buttonContainerClassName}>
          <IconButton className={classes.button} onClick={handleClick}>
            <UxdIcon name="more_vert" />
          </IconButton>
          <ModuleActions
            module={module}
            anchorEl={anchorEl}
            handleClose={handleClose}
            onSelect={onSelect}
            onCopy={onCopy}
            onMove={onMove}
            onDelete={onDelete}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className={classes.infoWrapper}>
        <PageElementIcon
          size="small"
          elementType={module.moduleType}
          pageElementDefinition={moduleDefinition}
          className={elementIconClassName}
        />
        <div className={classes.moduleData}>
          <div className={classes.moduleIdContainer}>
            <Typography
              className={moduleIdClassName}
              variant="caption"
              noWrap={true}
            >
              {customModuleId(moduleDefinition)}
            </Typography>
          </div>
          <Typography
            className={moduleLabelClassName}
            variant="h6"
            noWrap={true}
          >
            {module.label || moduleDefinition.label}
          </Typography>
        </div>
      </div>
      <ModulePropertiesPreview
        moduleDefinition={moduleDefinition}
        module={module}
        moduleVariation={modulePropertiesVariation}
      ></ModulePropertiesPreview>
    </div>
  );
};
