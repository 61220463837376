import { AuthorizationHeader } from "components/permissions/detailView/authorizationHeader";
import AuthorizationTabs from "components/permissions/detailView/pageElementPermissions";
import AuthorizationUserList from "components/permissions/detailView/userList";
import { useParams } from "react-router-dom";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useGetAuthGroupDetail } from "api/queryHooks";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthorizationGroup } from "redux/actions/authorizationGroupsActions";
import ErrorPage from "components/errorPages";
import { themeSizing } from "theme/deltatreTheme";
import { AuthorizationGroupUsersSelectContextProvider } from "components/permissions/detailView/context/useAuthorizationGroupUsersSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100% - ${theme.spacing(11)}px)`
  },
  mainSection: {
    display: "grid",
    height: "100%",
    gridTemplateColumns: `3fr ${themeSizing.rightPanel}px`,
    "& > section": {
      position: "relative"
    }
  }
}));

export function AuthorizationDetail() {
  const { authGroupId } = useParams<{ authGroupId: string }>();
  const classes = useStyles();
  const request = useGetAuthGroupDetail();
  const dispatch = useDispatch();

  useEffect(() => {
    request.mutate(authGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authGroupId]);

  const groups = useSelector(selectAuthorizationGroupList);
  const authorizationGroup = groups.find((x) => x.groupId === authGroupId);

  useEffect(() => {
    if (request.data && request.isSuccess) {
      dispatch(updateAuthorizationGroup(request.data));
    }
  }, [request.data, request.isSuccess, dispatch]);

  if (request.isError) {
    return <ErrorPage error="not found" />;
  }

  if (!authorizationGroup) {
    return null;
  }

  return (
    <AuthorizationGroupUsersSelectContextProvider>
      <div className={classes.root}>
        <AuthorizationHeader authorizationGroup={authorizationGroup} />
        <main className={classes.mainSection}>
          <AuthorizationUserList authorizationGroup={authorizationGroup} />
          <AuthorizationTabs authorizationGroup={authorizationGroup} />
        </main>
      </div>
    </AuthorizationGroupUsersSelectContextProvider>
  );
}
