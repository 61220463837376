import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import Preview from "components/siteStructure/template/preview";
import { Hint } from "components/shared/hint";

interface IProps extends IBaseProps {
  layoutDefinition: ILayoutDefinition;
  showHighlight: boolean;
  highlightRegex: RegExp;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, "auto"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
  },
  layoutPreview: {
    height: theme.spacing(8),
    width: theme.spacing(13),
    border: `1px solid ${theme.palette.custom.greyscales.bordersAndSeparators.lineDarker}`
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    width: "100%",
    justifyContent: "space-between",
    gap: theme.spacing(1)
  },
  labelText: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(0.5)
  },
  hint: {
    "&$hide": {
      visibility: "hidden"
    }
  },
  hide: {}
}));

export const LayoutTile = (props: IProps) => {
  const { className, layoutDefinition, showHighlight, highlightRegex } = props;
  const { design, label, description } = layoutDefinition;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const labelParts = label.split(highlightRegex);

  const hintClassName = classnames(classes.hint, {
    [classes.hide]: !description
  });

  return (
    <div className={rootClassName} role="button">
      <Preview className={classes.layoutPreview} design={design} />
      <div className={classes.labelContainer}>
        <Typography variant="subtitle2" className={classes.labelText}>
          {labelParts.map((part, i) =>
            showHighlight && highlightRegex.test(part) ? (
              <mark key={`${part}-${i}`}>{part}</mark>
            ) : (
              part
            )
          )}
        </Typography>
        {description && (
          <Hint
            className={hintClassName}
            hint={description ?? ""}
            noSpacing={true}
          />
        )}
      </div>
    </div>
  );
};
