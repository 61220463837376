export const PermissionCodes = {
  ViewSiteStructure: "ViewSiteStructure",
  EditSiteStructure: "EditSiteStructure",
  Publish: "Publish",
  Unpublish: "Unpublish",
  ViewContent: "ViewContent",
  EditContent: "EditContent",
  ViewMetadata: "ViewMetadata",
  EditMetadata: "EditMetadata",
  ViewUrlTranslations: "ViewUrlTranslations",
  EditUrlTranslations: "EditUrlTranslations",
  ViewVariables: "ViewVariables",
  EditVariables: "EditVariables",
  ViewPermissions: "ViewPermissions",
  EditPermissions: "EditPermissions",
  ViewNavigationRules: "ViewNavigationRules",
  EditNavigationRules: "EditNavigationRules",
  ViewAuthorizationGroups: "ViewAuthorizationGroups",
  ManageAuthorizationGroups: "ManageAuthorizationGroups",
  ViewAdminPanel: "ViewAdminPanel",
  ManageAdminPanel: "ManageAdminPanel"
};
