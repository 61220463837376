import { IconButton, Tooltip } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";

export interface IRestoreButtonProps {
  onRestore: (isAlias: boolean) => void;
  alias?: boolean;
}
export const RestoreButton = (props: IRestoreButtonProps) => {
  const classes = useStyles();
  const { onRestore, alias: isAlias = false } = props;

  return (
    <div className={classNames(classes.cell, classes.restoreCell)}>
      <Tooltip title={translate("sitestructure.restore")}>
        <IconButton
          className={classes.restoreButton}
          aria-label="restore"
          onClick={() => onRestore(isAlias)}
        >
          <UxdIcon name="settings_backup_restore" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default RestoreButton;
