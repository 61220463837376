import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  version: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const Version = (props: IProps) => {
  const { className, version } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography variant="body2" color="initial">
        {translate("home.version")}
        {version}
      </Typography>
    </div>
  );
};
