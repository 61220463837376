import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { IMenuItem } from "models/menus";
import { UxdIcon } from "components/shared/uxdIcon";
import { includes } from "lodash";

import { useStyles } from "./styles";

import {
  PlacementModeSegment,
  PlacementModeSegmentHoverHandles
} from "components/siteStructure/menuView/types";
import { useMenuBuilderContext } from "components/siteStructure/menuView/context";
import { useCallback, useMemo } from "react";
import {
  getIsMenuItemMovingItemDescendant,
  getIsMenuItemSelectedForMoving
} from "components/siteStructure/menuView/utils";

interface IProps extends IBaseProps {
  hoverHandles: PlacementModeSegmentHoverHandles;
  menuItem: IMenuItem;
  forbiddenPlacementSegments: PlacementModeSegment[];
}

const PlacementModeMarkers = (props: IProps) => {
  const { hoverHandles, menuItem, forbiddenPlacementSegments } = props;
  const classes = useStyles();

  const { placementModeMetadata, onMenuItemPlaced } = useMenuBuilderContext();

  const isMenuItemSelectedForMoving = getIsMenuItemSelectedForMoving(
    placementModeMetadata,
    menuItem.id
  );

  const isMenuItemMovingItemDescendant = getIsMenuItemMovingItemDescendant(
    placementModeMetadata,
    menuItem.id
  );

  const isPlacementSegmentForbidden: Record<PlacementModeSegment, boolean> =
    useMemo(
      () => ({
        CHILD: includes(forbiddenPlacementSegments, "CHILD"),
        SIBLING_BEFORE: includes(forbiddenPlacementSegments, "SIBLING_BEFORE"),
        SIBLING_AFTER: includes(forbiddenPlacementSegments, "SIBLING_AFTER")
      }),
      [forbiddenPlacementSegments]
    );

  const siblingMarkerContainerBefore = classnames(
    classes.siblingMarkerContainer,
    classes.siblingMarkerContainerBefore,
    {
      [classes.forbiddenSiblingMarker]:
        isPlacementSegmentForbidden.SIBLING_BEFORE
    }
  );

  const siblingMarkerContainerAfter = classnames(
    classes.siblingMarkerContainer,
    classes.siblingMarkerContainerAfter,
    {
      [classes.forbiddenSiblingMarker]:
        isPlacementSegmentForbidden.SIBLING_AFTER
    }
  );

  const handleMarkerClick = useCallback(
    (event: any, placementSegment: PlacementModeSegment) => {
      event.stopPropagation();

      if (isPlacementSegmentForbidden[placementSegment]) {
        return false;
      }

      onMenuItemPlaced(placementSegment, menuItem.id);
    },
    [menuItem, onMenuItemPlaced, isPlacementSegmentForbidden]
  );

  const shouldRenderChildMarkerContainer =
    !isMenuItemSelectedForMoving && !isMenuItemMovingItemDescendant;
  const shouldRenderSiblingsMarkerContainer =
    !isMenuItemSelectedForMoving && !isMenuItemMovingItemDescendant;

  return (
    <>
      {shouldRenderChildMarkerContainer && (
        <div
          className={classes.childMarkerContainer}
          onMouseEnter={() => hoverHandles.CHILD.set(true)}
          onMouseLeave={() => hoverHandles.CHILD.set(false)}
          onClick={(e) => handleMarkerClick(e, "CHILD")}
        ></div>
      )}
      {shouldRenderSiblingsMarkerContainer && (
        <>
          <div
            className={siblingMarkerContainerBefore}
            onMouseEnter={() => hoverHandles.SIBLING_BEFORE.set(true)}
            onMouseLeave={() => hoverHandles.SIBLING_BEFORE.set(false)}
            onClick={(e) => handleMarkerClick(e, "SIBLING_BEFORE")}
          >
            <div className={classes.siblingMarker} />
            <div className={classes.markerIconContainer}>
              <UxdIcon className={classes.markerIcon} name="add_circle" />
            </div>
            <div className={classes.siblingMarker} />
          </div>

          <div
            className={siblingMarkerContainerAfter}
            onMouseEnter={() => hoverHandles.SIBLING_AFTER.set(true)}
            onMouseLeave={() => hoverHandles.SIBLING_AFTER.set(false)}
            onClick={(e) => handleMarkerClick(e, "SIBLING_AFTER")}
          >
            <div className={classes.siblingMarker} />
            <div className={classes.markerIconContainer}>
              <UxdIcon className={classes.markerIcon} name="add_circle" />
            </div>
            <div className={classes.siblingMarker} />
          </div>
        </>
      )}
    </>
  );
};

export default PlacementModeMarkers;
