import { makeStyles } from "@material-ui/core";
import {
  SiteNodeActions,
  SiteNodeType,
  SiteNodeTypes
} from "models/siteStructure";
import { SiteItemModal } from "components/siteStructure/modals/siteItemModal";
import { SITE_STRUCTURE_BASE } from "routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import urljoin from "url-join";
import {
  addDirectory,
  addMenu,
  addPage
} from "redux/actions/siteStructureActions";
import { useCallback } from "react";
import { selectCreateSiteItem } from "redux/selectors/siteItem/createSiteItemSelector";
import { closeCreateModal } from "redux/actions/siteItem/createSiteItemActions";

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const CreateSiteItemModal = () => {
  const { open, node, type, index } = useSelector(selectCreateSiteItem);

  const dispatch = useDispatch();
  const history = useHistory();

  const createSiteItem = (
    itemType: SiteNodeType,
    name: string,
    siteAssetsContextName: string
  ) => {
    if (!node) return;

    const path = urljoin(node.path, name);
    const navigate = () => history.push(`${SITE_STRUCTURE_BASE}/${path}`);

    switch (itemType) {
      case SiteNodeTypes.DIRECTORY:
        dispatch(addDirectory({ path, onSuccess: navigate }));
        break;
      case SiteNodeTypes.PAGE:
        dispatch(addPage({ path, siteAssetsContextName, onSuccess: navigate }));
        break;
      case SiteNodeTypes.MENU:
        dispatch(addMenu({ path, onSuccess: navigate }));
        break;
    }

    onCancel();
  };

  const onCancel = useCallback(() => dispatch(closeCreateModal()), [dispatch]);

  const onConfirm = useCallback(createSiteItem, [
    onCancel,
    dispatch,
    history,
    node
  ]);

  const classes = useStyles();

  if (!node) {
    return null;
  }

  return (
    <SiteItemModal
      className={classes.root}
      open={open}
      parentId={node.nodeId}
      parentPath={node.path}
      siteItemType={type}
      onConfirm={onConfirm}
      onCancel={onCancel}
      type={type}
      action={SiteNodeActions.CREATE}
      index={index}
    />
  );
};
