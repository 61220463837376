import { TextField, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useDebouncedState } from "components/hooks";
import { useStyles } from "components/linkRules/linkPicker/linkPickerModal/externalLinkContent/styles";
import { translate } from "utils/i18n";
import { isValidUrl } from "utils/pathUtils";
import { useEffect, useRef } from "react";

interface IProps extends IBaseProps {
  disabled: boolean;
  value: string;
  onChange: (fieldValue: string, isValid: boolean) => void;
}

export function ExternalLinkContent(props: Readonly<IProps>) {
  const { disabled, value, onChange } = props;
  const classes = useStyles();
  const textFieldRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line
  const [errorMessage, debouncedErrorMessage, setErrorMessage] = // NOSONAR
    useDebouncedState("", 800);
  const invalidUrlMessage = translate(
    "sitestructure.moduleproperties.errors.invalid_url"
  );

  const handleValueChange = (fieldValue: string) => {
    const isValid = isValidUrl(fieldValue, true, false);
    setErrorMessage(isValid ? "" : invalidUrlMessage);

    onChange(fieldValue, isValid);
  };

  useEffect(() => {
    if (!disabled && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [disabled]);

  return (
    <div className={classes.content}>
      <Typography variant="caption" className={classes.description}>
        {translate("navigationrules.sidebar.link_picker.external.description")}
      </Typography>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        disabled={disabled}
        value={value}
        onChange={(e) => handleValueChange(e.target.value)}
        error={debouncedErrorMessage !== ""}
        helperText={debouncedErrorMessage}
        inputRef={textFieldRef}
      />
    </div>
  );
}
