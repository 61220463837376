import { IconButton, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import {
  D3AutoCompleteInput,
  ID3AutoCompleteInputOption
} from "components/shared/d3Components/d3AutoCompleteInput";
import { UxdIcon } from "components/shared/uxdIcon";
import MetadataDefinitionDisplay from "components/siteStructure/mainDetail/metadataDefinitionDisplay";
import MetadataInput from "components/siteStructure/mainDetail/inputs";
import {
  IMetadataPropertyItem,
  MetadataType,
  MetadataValue
} from "models/metadata";
import { useState } from "react";
import { translate } from "utils/i18n";

interface IProps {
  item: IMetadataPropertyItem;
  type: MetadataType;
  format: string;
  enum?: string[];
  cultures: string[];
  environments: string[];
  onSave?: (metadata: IMetadataPropertyItem) => void;
  onDelete?: (metadata: IMetadataPropertyItem) => void;
  error?: string;
  disabled: boolean;
  position?: number;
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  propertyItemContainer: {
    marginTop: theme.spacing(3)
  },
  cultureLabel: {
    padding: theme.spacing(0.5)
  },
  definition: {
    marginTop: theme.spacing(0.5)
  },
  definitionLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr700
  },
  definitionValue: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.viking.viking500,
    paddingLeft: theme.spacing(0.5)
  },
  overwriteValue: {
    color: theme.palette.custom.ds.grayscales.gr600
  },
  buttons: {
    width: theme.spacing(7),
    justifyContent: "center",
    "&:hover .MuiIconButton-root": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px"
    }
  },
  buttonIcon: {
    color: theme.palette.custom.ds.grayscales.gr600
  },
  propertyValueDefault: {
    "& .MuiInputBase-root": {
      padding: theme.spacing(1.5, 2)
    },
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0)
    }
  },
  propertyValue: {
    "& .MuiInputBase-root": {
      padding: theme.spacing(0.95, 1.6)
    },
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0)
    }
  },
  autoComplete: {
    width: theme.spacing(14),
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0, 1),
      "& .MuiAutocomplete-input": {
        padding: theme.spacing(0.95, 0.5)
      }
    }
  },
  autoCompletePopper: {
    "& li": {
      padding: theme.spacing(1)
    }
  },
  grow: { flexGrow: 1 },
  hide: { visibility: "hidden" },
  prefixContainer: {
    gap: theme.spacing(2.5),
    marginRight: theme.spacing(2.5)
  },
  error: {
    color: theme.palette.custom.ds.valencia.valencia500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontStyle: "italic"
  }
}));

const getDefaultValue = (type: MetadataType) => {
  switch (type) {
    case "boolean":
      return false;
    case "number":
      return 0;
    case "string":
      return "";
  }
};

const getValueByType = (item: any): MetadataValue | undefined =>
  item?.value ?? undefined;

const transformValue = (
  value: MetadataValue | undefined,
  type: MetadataType
) => {
  // NOTE: forge does not accept empty strings or null as values. Use sane defaults as fallback
  const isEmptyValue = !value || String(value).length === 0;
  const submitted = isEmptyValue ? getDefaultValue(type) : value;
  return submitted;
};

export const MetadataPropertyItem = (props: IProps) => {
  const {
    item,
    type,
    format,
    error,
    cultures,
    environments,
    disabled,
    position
  } = props;
  const classes = useStyles();

  const [culture, setCulture] = useState<string>(item.culture || "*");
  const [environment, setEnvironment] = useState<string>(
    item.environment || "*"
  );
  const [metadataValue, setMetadataValue] = useState<MetadataValue | undefined>(
    getValueByType(item)
  );

  const inheritedMetadataValue: MetadataValue | undefined = getValueByType(
    item.definedIn
  );

  const isDefaultCulture = culture === "*";
  const isDefaultEnvironment = environment === "*";

  const isDefault = isDefaultCulture && isDefaultEnvironment;

  const hasParentDefinition = item.definedIn !== null;
  const isOverriding = hasParentDefinition && metadataValue !== undefined;

  const shouldShowDeleteButton =
    !isDefault && !disabled && !isOverriding && !hasParentDefinition;

  const shouldShowRestoreButton = isOverriding && !disabled;

  const handleCultureSubmit = (option: ID3AutoCompleteInputOption) => {
    const selected = option.value;

    setCulture(selected);
    handleSave(selected, environment, metadataValue as MetadataValue);
  };

  const handleEnvironmentSubmit = (option: ID3AutoCompleteInputOption) => {
    const selected = option.value;

    setEnvironment(selected);
    handleSave(culture, selected, metadataValue as MetadataValue);
  };

  const handleValueSubmit = (value: MetadataValue | undefined) => {
    setMetadataValue(value ?? undefined);

    const transformedValue = transformValue(value, type);
    handleSave(culture, environment, transformedValue);
  };

  const handleRestore = () => {
    if (props.error) return;

    // NOTE: the forge api will not accept empty strings, send null as no value

    const transformedValue = transformValue(undefined, type);

    setMetadataValue(undefined);
    handleSave(culture, environment, transformedValue);
  };

  const handleSave = (
    cultureToUpdate: string,
    environmentToUpdate: string,
    value: MetadataValue
  ) => {
    const metadataItem = {
      ...item,
      value,
      culture: cultureToUpdate,
      environment: environmentToUpdate,
      position
    };

    props.onSave?.call(this, metadataItem);
  };

  const handleDelete = () => {
    const metadataItem = {
      ...item,
      value: metadataValue,
      culture,
      environment
    };
    props.onDelete?.call(this, metadataItem);
  };

  const prefixContainer = classnames(classes.row, classes.prefixContainer);

  const propertyInput = classnames(
    !isDefault ? classes.propertyValue : classes.propertyValueDefault,
    classes.grow
  );

  // do not permit selection of default culture AND default environment case since it always exists
  const availableCultures = isDefaultEnvironment
    ? cultures.filter((lang) => lang !== "*")
    : cultures;

  const availableEnvironments = isDefaultCulture
    ? environments.filter((env) => env !== "*")
    : environments;

  const replaceAsterisk = (input: string) =>
    input.toLowerCase() === "*" ? translate("metadata.all") : input;

  const toAutoCompleteOption = (value: string): ID3AutoCompleteInputOption => ({
    value,
    label: replaceAsterisk(value)
  });

  const cultureOptions = availableCultures.map(toAutoCompleteOption);
  const environmentOptions = availableEnvironments.map(toAutoCompleteOption);

  const _type = format === "date-time" ? format : type;

  const renderedMetadataValue = metadataValue ?? inheritedMetadataValue;

  return (
    <div className={classes.propertyItemContainer}>
      <div className={classes.row}>
        {!isDefault && (
          <div className={prefixContainer}>
            <D3AutoCompleteInput
              options={cultureOptions}
              value={toAutoCompleteOption(culture)}
              onSubmit={handleCultureSubmit}
              className={classes.autoComplete}
              placeholder={translate("metadata.culture")}
              disabled={disabled}
            />
            <D3AutoCompleteInput
              options={environmentOptions}
              value={toAutoCompleteOption(environment)}
              onSubmit={handleEnvironmentSubmit}
              className={classes.autoComplete}
              placeholder={translate("metadata.environment")}
              disabled={disabled}
            />
          </div>
        )}

        <MetadataInput
          className={propertyInput}
          placeholder={translate("metadata.input_placeholder")}
          value={renderedMetadataValue}
          enum={props.enum}
          onSubmit={handleValueSubmit}
          type={_type}
          disabled={disabled}
        />

        <div className={classnames(classes.row, classes.buttons)}>
          {shouldShowDeleteButton && (
            <IconButton onClick={handleDelete} disableRipple>
              <UxdIcon className={classes.buttonIcon} name="delete_outline" />
            </IconButton>
          )}

          {shouldShowRestoreButton && (
            <IconButton onClick={handleRestore} disableRipple>
              <UxdIcon className={classes.buttonIcon} name="autorenew" />
            </IconButton>
          )}
        </div>
      </div>

      {error && <Typography className={classes.error}>{error}</Typography>}
      <MetadataDefinitionDisplay item={item} isOverriding={isOverriding} />
    </div>
  );
};

export default MetadataPropertyItem;
