import { Chip, makeStyles, Typography } from "@material-ui/core";
import { ScrollSpyElem } from "components/shared/scrollspy";
import { ISiteItem } from "models/siteItem";
import { IBaseProps } from "components/_baseProps";
import {
  ITypedVariables,
  IVariableContract,
  IVariableDetail
} from "models/variables";
import { translate } from "utils/i18n";
import { VariableItem } from "components/siteStructure/variables/view/variableItem";
import { getDefaultVariableItem, VariableType } from "utils/variableUtils";
import { useCallback, useMemo, useState } from "react";
import { D3Modal } from "components/shared/d3Components";
import { useVariableCommands } from "components/siteStructure/variables/hooks";
import { useSelector } from "react-redux";
import { selectPageVariables } from "redux/selectors/variablesSelectors";
import { find } from "lodash";

interface IProps extends IBaseProps {
  variables: ScrollSpyElem<ITypedVariables>;
  siteItem: ISiteItem;
}

interface IModalState {
  variableKey: string | undefined;
  currentModal: "delete" | "restore" | undefined;
}

const defaultModalState: IModalState = {
  variableKey: undefined,
  currentModal: undefined
};

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5)
  },
  header: {
    display: "flex",
    gap: "10px"
  },
  chip: {
    color: theme.palette.custom.ds.grayscales.gr200,
    margin: "auto 0",
    height: theme.spacing(2.5),
    backgroundColor: (props: IProps) => {
      switch (props.variables.type.type) {
        case VariableType.URI_VARIABLE.type:
          return theme.palette.custom.ds.variables.urivariable500;
        case VariableType.KEY_VALUE.type:
          return theme.palette.custom.ds.variables.keyvalue500;
        case VariableType.DATA_LIST.type:
          return theme.palette.custom.ds.variables.datalist500;
        case VariableType.DATA_ITEM.type:
          return theme.palette.custom.ds.variables.dataitem500;
      }
    },
    "& .MuiChip-labelSmall": {
      padding: theme.spacing(0.25, 1),
      fontSize: theme.typography.pxToRem(12)
    }
  },
  description: {
    color: theme.palette.custom.ds.grayscales.gr600,
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14)
  },
  definedIn: {
    margin: theme.spacing(0.5)
  },
  modalParagraph: {
    marginBottom: theme.spacing(0.5)
  },
  restoreModal: {
    "& .MuiPaper-root": {
      width: theme.typography.pxToRem(444)
    },
    "& .MuiDialogContent-root .MuiTypography-root": {
      lineHeight: theme.typography.pxToRem(24),
      fontSize: theme.typography.pxToRem(14),
      color: "#000000"
    },
    "& .MuiDialogContent-root": {
      height: theme.typography.pxToRem(120)
    },
    "& .MuiDialogActions-root": {
      borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
      height: theme.typography.pxToRem(23)
    }
  }
}));

function getSelectedVariableItem(
  variables: IVariableContract[],
  modalState: IModalState
): IVariableDetail | undefined {
  const { currentModal, variableKey } = modalState;

  if (currentModal !== "restore" || variableKey === undefined) {
    return;
  }

  const variableContractKey = modalState.variableKey?.split(".")[0];

  const currentVariable = find(variables, { key: variableContractKey });

  if (currentVariable === undefined) {
    return undefined;
  }

  return getDefaultVariableItem(currentVariable);
}

export function VariableTypeList(props: IProps) {
  const { variables, siteItem } = props;
  const classes = useStyles(props);
  const allVariables = useSelector(selectPageVariables);
  const [modalState, setModalState] = useState<IModalState>(defaultModalState);
  const { deleteVariable } = useVariableCommands(siteItem);

  const variableToRestore = useMemo(
    () => getSelectedVariableItem(allVariables, modalState),
    [modalState, allVariables]
  );

  const title = translate(
    `sitestructure.variables.title.${variables.type.type}`
  );

  const description = translate(
    `sitestructure.variables.description.${variables.type.type}`
  );

  const isDeleting: boolean =
    modalState.currentModal === "delete" &&
    modalState.variableKey !== undefined;

  const isRestoring: boolean =
    modalState.currentModal === "restore" &&
    modalState.variableKey !== undefined;

  const onDelete = useCallback(
    (variableKey: string) =>
      setModalState({
        variableKey,
        currentModal: "delete"
      }),
    []
  );

  const onRestore = useCallback(
    (variableKey: string) =>
      setModalState({
        variableKey,
        currentModal: "restore"
      }),
    []
  );

  const onModalCancel = useCallback(() => setModalState(defaultModalState), []);

  const onDeleteConfirm = useCallback(() => {
    deleteVariable(modalState.variableKey!);
    setModalState(defaultModalState);
  }, [deleteVariable, modalState.variableKey]);

  const onRestoreConfirm = useCallback(() => {
    deleteVariable(modalState.variableKey!);
    setModalState(defaultModalState);
  }, [deleteVariable, modalState.variableKey]);

  const restoreModalDefinedInMessage = translate(
    "sitestructure.variables.definedlocation",
    {
      path: variableToRestore?.definedIn?.path || ""
    }
  ).toLocaleLowerCase();

  return (
    <div className={classes.root} key={variables.text} ref={variables.node}>
      <div className={classes.header}>
        <Chip
          label={variables.type.code}
          className={classes.chip}
          size="small"
        />
        <Typography variant="h5">{title}</Typography>
      </div>
      <Typography
        variant="caption"
        component="p"
        className={classes.description}
      >
        {description}
      </Typography>

      {variables.variables.map((variable) => (
        <VariableItem
          key={variable.key}
          variable={variable}
          siteItem={siteItem}
          onDelete={onDelete}
          onRestore={onRestore}
        />
      ))}
      <D3Modal
        open={isDeleting}
        modalTitle={translate("sitestructure.variables.delete.title")}
        onConfirm={onDeleteConfirm}
        onCancel={onModalCancel}
        confirmLabel={translate("sitestructure.variables.delete.confirm")}
        cancelLabel={translate("sitestructure.variables.delete.cancel")}
      >
        <Typography>
          {translate("sitestructure.variables.delete.confirmmessage")}
        </Typography>
      </D3Modal>

      <D3Modal
        className={classes.restoreModal}
        open={isRestoring}
        modalTitle={translate("sitestructure.variables.restore.title")}
        onConfirm={onRestoreConfirm}
        onCancel={onModalCancel}
        confirmLabel={translate("sitestructure.variables.restore.confirm")}
        cancelLabel={translate("sitestructure.variables.restore.cancel")}
      >
        <Typography className={classes.modalParagraph}>
          {translate(
            "sitestructure.variables.restore.confirmmessage.are_you_sure"
          )}
        </Typography>
        <Typography className={classes.modalParagraph}>
          {translate(
            "sitestructure.variables.restore.confirmmessage.will_be_replaced"
          )}
        </Typography>
        <Typography className={classes.modalParagraph}>
          "{variableToRestore?.definedIn?.value}"
          <span className={classes.definedIn}>
            {restoreModalDefinedInMessage}
          </span>
        </Typography>
      </D3Modal>
    </div>
  );
}
