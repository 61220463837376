import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  condition: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    "&:hover $removeIconContainer": {
      display: "flex"
    }
  },
  attribute: {
    width: theme.spacing(21)
  },
  operator: {
    width: theme.spacing(10)
  },
  rowEndContainer: { flex: 1, display: "flex", alignItems: "end" },
  valueFieldContainer: { flex: 1 },
  value: {
    width: "100%"
  },
  removeIconContainer: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    display: "none",

    justifyContent: "center",
    alignItems: "center",
    "& span": {
      paddingLeft: "40% !important"
    }
  },
  removeIcon: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr600
  }
}));
