import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { usePersistedState } from "components/hooks";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import React, { useEffect, useState } from "react";
import { theme } from "theme/theme";
import { translate } from "utils/i18n";
import { PreviewToolTip } from "components/previewPage/previewBar/previewToolTip";

interface IProps extends IBaseProps {
  cultures: string[];
  setCulture: (culture: string) => void;
}

const useStyles = makeStyles<typeof theme>((th) => ({
  root: {},
  buttonTitle: {
    color: th.palette.common.white,
    textTransform: "initial",
    fontSize: th.typography.pxToRem(14)
  },
  icon: {
    color: th.palette.custom.ds.viking.viking500
  },
  arrow: {
    color: th.palette.custom.ds.grayscales.gr500
  },
  menu: {
    "& .MuiMenu-paper": {
      backgroundColor: th.palette.custom.ds.grayscales.gr900,
      borderRadius: "0",
      width: "180px"
    },
    "& .MuiList-padding": {
      padding: theme.spacing(1, 0.5)
    }
  },
  menuItem: {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.25),
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr800
    },
    display: "flex",
    columnGap: theme.spacing(2),
    height: theme.spacing(4.5),
    justifyContent: "space-between"
  },
  checkIcon: {
    "&.material-icons": {
      fonSize: "1rem",
      width: theme.spacing(4)
    }
  },
  button: {
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr800
    }
  }
}));

export function CultureSelect(props: IProps) {
  const { cultures, setCulture } = props;

  const [culture, setInternalCulture] = usePersistedState<string>(
    "sitestructure.previewCulture",
    cultures[0]
  );

  const classes = useStyles();

  useEffect(() => {
    setCulture(culture);
    menuClose();
  }, [culture, setCulture]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PreviewToolTip title={translate("preview.tooltips.switchlanguage")}>
        <Button onClick={menuClick} className={classes.button}>
          <Typography className={classes.buttonTitle}>{culture}</Typography>
          <UxdIcon className={classes.arrow} name="expand_more" />
        </Button>
      </PreviewToolTip>
      <Menu
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={menuClose}
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{
          vertical: 46,
          horizontal: "right"
        }}
      >
        {cultures.map((cul) => (
          <LanguageMenuItem
            key={cul}
            culture={cul}
            selected={culture === cul}
            onClick={setInternalCulture}
          />
        ))}
      </Menu>
    </>
  );
}

interface ILanguageMenuItemProps extends IBaseProps {
  culture: string;
  selected: boolean;
  onClick: (culture: string) => void;
}

const LanguageMenuItem = React.forwardRef<
  HTMLLIElement,
  ILanguageMenuItemProps
>((props, ref) => {
  const classes = useStyles();

  const { culture, selected, onClick } = props;
  const checkIconClasses = classNames(classes.icon, classes.checkIcon);

  return (
    <MenuItem
      className={classes.menuItem}
      onClick={() => onClick(culture)}
      ref={ref}
    >
      <Typography variant="body2">{culture}</Typography>
      <div>
        {selected && (
          <UxdIcon className={checkIconClasses} name="check" outline={false} />
        )}
      </div>
    </MenuItem>
  );
});
