import { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState
} from "@material-ui/lab";
import { ContentPickerContextInputTextField } from "./contentPickerContextInputTextField";
import { translate } from "utils/i18n";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { SuggestionListItem } from "components/shared/suggestionListItem";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  value: TagInputItem | null;
  suggestions: TagInputItem[];
  onChange: (newValue: TagInputItem | null) => void;
  inputValue: string;
  onInputChange: (newValue: string) => void;
  error: string | null;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  values: {
    height: theme.spacing(7)
  },
  field: {},
  list: {
    padding: 0,
    "& > *": {
      padding: 0,
      '&[aria-selected="true"],&[data-focus="true"],&:active': {
        backgroundColor: theme.palette.custom.mainAccent.lightHover,
        "& *": {
          backgroundColor: theme.palette.custom.mainAccent.lightHover
        }
      }
    }
  },
  listItem: {
    flex: 1
  }
}));

const validationRegex = /\w/gi;

export const ContentPickerContextInput = (props: IProps) => {
  const {
    className,
    inputValue,
    suggestions,
    error,
    loading,
    onChange,
    onInputChange,
    value
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [open, setOpen] = useState(false);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <ContentPickerContextInputTextField
      loading={loading}
      error={error ?? false}
      {...params}
    />
  );

  const renderItem = (
    item: TagInputItem,
    state: AutocompleteRenderOptionState
  ) => (
    <SuggestionListItem
      className={classes.listItem}
      key={item.translationId}
      value={item.label}
      search={state.inputValue}
      highlight={item.source !== "internal"}
    />
  );

  const handleInputChange = (_: ChangeEvent<{}>, newValue: string) => {
    if (newValue && !newValue.match(validationRegex)) {
      return;
    }

    onInputChange(newValue);
  };

  const handleOpenSuggestions = (event: ChangeEvent<{}>) => {
    if (error || event.type === "mousedown") {
      return;
    }

    setOpen(true);
  };

  const handleCloseSuggestion = () => setOpen(false);

  const handleChange = (
    _: ChangeEvent<{}>,
    __: TagInputItem | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<TagInputItem> | undefined
  ) => {
    if (!details || reason === "clear") {
      onChange(null);
      return;
    }

    if (reason !== "select-option") {
      return;
    }

    // Check if selected value was already selected previously
    if (value?.translationId !== details.option.translationId) {
      onChange(details.option);
    }

    setOpen(false);
  };

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.context")} />
      <Autocomplete
        className={classes.field}
        classes={{
          listbox: classes.list
        }}
        multiple={false}
        value={value}
        size="small"
        open={open}
        freeSolo={false}
        disableClearable={false}
        disabled={false}
        options={suggestions}
        noOptionsText={translate("tagspicker.noresults")}
        loading={loading}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onOpen={handleOpenSuggestions}
        onClose={handleCloseSuggestion}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, val) =>
          option.translationId === val.translationId
        }
        renderOption={renderItem}
        openOnFocus={false}
        renderInput={renderInput}
        popupIcon={null}
        includeInputInList={true}
        filterSelectedOptions={true}
        filterOptions={(options) => options}
      />
    </div>
  );
};
