import { createSelector } from "reselect";
import { NEUTRAL_LANGUAGE } from "models/systemLanguage";
import { IDomainState } from "models/domainStates";

export const selectSystemLanguages = (state: IDomainState) =>
  state.forge.systemLanguages;

export const makeSelectSystemLanguageMap = () =>
  createSelector(selectSystemLanguages, (languages) =>
    Object.fromEntries(languages.map((l) => [l.code.toLowerCase(), l.name]))
  );

export const makeSelectSystemLanguageByCode = () =>
  createSelector(
    selectSystemLanguages,
    (_: IDomainState, code: string) => code,
    (languages, code) =>
      languages.find((l) => l.code === code) || NEUTRAL_LANGUAGE
  );

export const selectEntitiesDefinitions = (state: IDomainState) =>
  state.forge.entitiesDefinitions;
export const makeSelectEntityDefinition = () =>
  createSelector(
    selectEntitiesDefinitions,
    (_: IDomainState, typeCode: string) => typeCode,
    (definitions, typeCode) => definitions.find((d) => d.typeCode === typeCode)
  );

export const selectTagDatasources = (state: IDomainState) =>
  state.forge.tagsDatasource;
