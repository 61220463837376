import { ApiClient } from "api/apiClient";
import { PickListItem } from "models/componentProperty";

const BASE_URL = "api/picklist";

export class PickListApi {
  static readonly getPickListItems = async (
    dataPath: string,
    valueField: string,
    descriptionField: string
  ): Promise<PickListItem[]> => {
    const query = [
      `dataPath=${dataPath}`,
      `valueField=${valueField}`,
      `descriptionField=${descriptionField}`
    ].join("&");

    const endpoint = `${BASE_URL}?${query}`;

    return ApiClient.get(endpoint);
  };
}
