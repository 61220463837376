import React, { ReactNode } from "react";
import classnames from "classnames";
import {
  Dialog,
  DialogContent,
  DialogProps,
  makeStyles
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { DialogHeader } from "components/shared/dialogHeader";
import { DialogFooter } from "components/shared/dialogFooter";

interface IProps extends IBaseProps, DialogProps {
  title: string;
  open: boolean;
  actions?: ReactNode;
  footer?: ReactNode;
  disablePadding?: boolean;
  onClose?: () => void;
  ContentClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "2px"
  },
  disablePadding: {
    padding: 0
  },
  footer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    minWidth: 0
  }
}));

export const DialogBase = (props: IProps) => {
  const {
    className,
    title,
    open,
    children,
    actions,
    footer,
    fullWidth = true,
    maxWidth = "lg",
    disablePadding = false,
    onClose = () => {},
    ContentClassName = "",
    ...other
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const contentClassName = classnames(
    {
      [classes.disablePadding]: disablePadding
    },
    ContentClassName
  );

  return (
    <Dialog
      className={rootClassName}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      {...other}
    >
      <DialogHeader title={title} onClose={onClose} />
      <DialogContent className={contentClassName} dividers>
        {children}
      </DialogContent>
      <DialogFooter caption={footer} actions={actions} />
    </Dialog>
  );
};
