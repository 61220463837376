import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { find } from "lodash";
import { Typography } from "@material-ui/core";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import LinkPicker from "components/linkRules/linkPicker";
import LinkRuleConditions from "components/linkRules/linkRuleConditions";
import LinkRuleDescription from "components/linkRules/linkRuleDescription";
import LinkRulesSidebarFooter from "components/linkRules/linkRulesSidebar/linkRulesSidebarFooter";
import { LinkRulesSidebarHeader } from "components/linkRules/linkRulesSidebar/linkRulesSidebarHeader";
import { useStyles } from "components/linkRules/linkRulesSidebar/styles";
import {
  getFilteredAttributeOptions,
  getLinkRuleEntityTypeSuggestionsOptions
} from "components/linkRules/utils";
import { D3AutoCompleteInput, D3Modal } from "components/shared/d3Components";
import {
  ID3AutoCompleteInputOption,
  toAutoCompleteInputOption
} from "components/shared/d3Components/d3AutoCompleteInput";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import {
  selectIsLinkRulesSidebarOpen,
  selectLinkRulesEntitiesMetadata
} from "redux/selectors/linkRulesSelectors";
import { translate } from "utils/i18n";
import DuplicateLinkRuleWarning from "./DuplicateLinkRuleWarning";
import { useLinkRulesScrollingManagementContext } from "components/linkRules/context/scrollManagement";
import { userService } from "services/userService";

const CancelDialogTranslationKeyPrefix =
  "navigationrules.sidebar.dialog.cancel";

export default function LinkRulesSidebar() {
  const classes = useStyles();
  const isSidebarOpen = useSelector(selectIsLinkRulesSidebarOpen);
  const linkRulesEntityMetadata = useSelector(selectLinkRulesEntitiesMetadata);

  const {
    validationResult,
    shouldShowCancelConfirmDialog,
    onExitEditCancel,
    onExitEditConfirm,
    isNewItem,
    form,
    onUpdateFormField
  } = useLinkRulesCrudManagementContext();

  const {
    sidebarContainerRef,
    setSidebarWidth,
    onResizeSidebarContainerChange
  } = useLinkRulesScrollingManagementContext();

  const sidebarClassName = classNames(classes.sidebar, {
    [classes.collapsed]: !isSidebarOpen
  });

  const entityTypeSuggestionsOptions = useMemo(
    () => getLinkRuleEntityTypeSuggestionsOptions(linkRulesEntityMetadata),
    [linkRulesEntityMetadata]
  );

  const selectedEntityTypeOption = useMemo(
    () =>
      find(entityTypeSuggestionsOptions, {
        value: form.entityType
      }) ?? toAutoCompleteInputOption(form.entityType),
    [entityTypeSuggestionsOptions, form.entityType]
  );

  const entityTypeName = selectedEntityTypeOption.label;

  const title = isNewItem
    ? translate("navigationrules.new_navigation_rule")
    : translate("navigationrules.sidebar.title", { entityTypeName });

  const shouldShowValidationResultForDuplicateConditions =
    validationResult?.validationKey === "DUPLICATE_CONDITIONS";

  const handleEntityTypeChange = (option: ID3AutoCompleteInputOption) =>
    onUpdateFormField("entityType", option.value);

  const userCanEdit = userService.getIsUserAllowedToEditNavigationRules(
    form.url,
    form.isAbsolute
  );

  useEffect(() => {
    if (sidebarContainerRef.current !== null) {
      const sidebarContainer = sidebarContainerRef.current;

      const resizeObserver = new ResizeObserver(onResizeSidebarContainerChange);
      resizeObserver.observe(sidebarContainer);

      return () => {
        setSidebarWidth(0);
        resizeObserver.disconnect();
      };
    }
  }, [setSidebarWidth, onResizeSidebarContainerChange, sidebarContainerRef]);

  const filteredEntityTypeSuggestionsOptions = useMemo(
    () =>
      getFilteredAttributeOptions(entityTypeSuggestionsOptions, {
        inputValue: form.entityType
      }),
    [entityTypeSuggestionsOptions, form.entityType]
  );

  return (
    <div className={sidebarClassName} ref={sidebarContainerRef}>
      <div className={classes.header}>
        <LinkRulesSidebarHeader title={title} isNewItem={isNewItem} />
        <DuplicateLinkRuleWarning
          showWarning={shouldShowValidationResultForDuplicateConditions}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.field}>
          <TextFieldLabel
            value={translate("navigationrules.sidebar.entity")}
            disabled={!isNewItem}
            mandatory={isNewItem}
          />
          <D3AutoCompleteInput
            options={filteredEntityTypeSuggestionsOptions}
            fullWidth
            disabled={!isNewItem}
            value={selectedEntityTypeOption}
            onSubmit={handleEntityTypeChange}
            onInputChange={handleEntityTypeChange}
          />
        </div>
        <div className={classes.field}>
          <Typography variant="overline" className={classes.sectionTitle}>
            {translate("navigationrules.sidebar.conditions")}
          </Typography>
          <LinkRuleConditions disabled={!userCanEdit && !isNewItem} />
        </div>
        <div className={classes.field}>
          <Typography variant="overline" className={classes.sectionTitle}>
            {translate("navigationrules.sidebar.navigate_to")}
          </Typography>
          <TextFieldLabel
            value={translate("navigationrules.sidebar.page")}
            mandatory={isNewItem}
          />
          <LinkPicker
            disabled={!userCanEdit && !isNewItem}
            key={form.url}
            pageId={form.pageId}
            path={form.url}
          />
        </div>
        <div className={classes.field}>
          <LinkRuleDescription disabled={!userCanEdit && !isNewItem} />
        </div>
      </div>
      <LinkRulesSidebarFooter />

      <D3Modal
        open={shouldShowCancelConfirmDialog}
        modalTitle={translate(
          `${CancelDialogTranslationKeyPrefix}.${
            isNewItem ? "creation_title" : "title"
          }`
        )}
        confirmLabel={translate(`${CancelDialogTranslationKeyPrefix}.confirm`)}
        cancelLabel={translate(`${CancelDialogTranslationKeyPrefix}.cancel`)}
        onConfirm={onExitEditConfirm}
        onCancel={onExitEditCancel}
      >
        <Typography>
          {translate(`${CancelDialogTranslationKeyPrefix}.message`)}
        </Typography>
      </D3Modal>
    </div>
  );
}
