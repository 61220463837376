import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "components/linkRules/linkPicker/linkPickerModal/linkFooterSection/styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  value: string;
}

export function LinkFooterSection(props: Readonly<IProps>) {
  const { value } = props;
  const classes = useStyles();

  const footerLinkClassNames = classNames(classes.footerLink, {
    [classes.hidden]: value === ""
  });

  return (
    <div className={footerLinkClassNames}>
      <UxdIcon name="link" className={classes.icon} />
      <Typography variant="body2" className={classes.name}>
        {translate("navigationrules.sidebar.link_picker.footer.link")}
      </Typography>
      <Typography variant="body2" className={classes.value}>
        {value}
      </Typography>
    </div>
  );
}
