import classnames from "classnames";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeStage, SiteNodeType } from "models/siteStructure";
import { SiteNodeIcon } from "./siteNodeIcon";
import { StageIcon } from "components/shared/stageIcon";
import { ForgeEntityStage } from "models/forgeEntities";
import HTMLReactParser from "html-react-parser";

interface IProps extends IBaseProps {
  size: "small" | "large";
  label: string;
  nodeType: SiteNodeType;
  stage: SiteNodeStage;
  nodeIconClassName?: string;
  showRootAlias?: boolean;
  parseLabelToHtml?: boolean;
  contextName: string;
  isAlias?: boolean;
  isSearchResult?: boolean;
  wrapUrlText?: boolean;
}

const evaluatePadding = (props: IProps, theme: Theme) => {
  if (props.size === "small") {
    return props.isSearchResult
      ? theme.spacing(0.75, 0, 0.25)
      : theme.spacing(0.5, 0);
  }
  return theme.spacing(2);
};

const useStyles = makeStyles((theme) => ({
  root: (props: IProps) => ({
    display: "flex",
    alignItems: "center",
    padding: evaluatePadding(props, theme)
  }),
  nodeTypeIcon: {
    margin: theme.spacing(0, 1),
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.spacing(2)
  },
  text: (props: IProps) => ({
    color: props.isAlias
      ? theme.palette.custom.ds.grayscales.gr700
      : theme.palette.custom.ds.grayscales.gr900,
    flexGrow: 1,
    overflow: "hidden"
  }),
  Published: {
    color: "#18a55c"
  },
  Reviewed: {
    color: "#ff9800"
  },
  Unpublished: {
    color: "#f56954"
  },
  noWrap: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export const SiteNodeInfo = (props: IProps) => {
  const {
    className,
    nodeIconClassName,
    label,
    nodeType,
    stage,
    size,
    showRootAlias = false,
    parseLabelToHtml = false,
    isAlias = false,
    contextName,
    wrapUrlText = false
  } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);
  const nodeTypeIconClassName = classnames(
    classes.nodeTypeIcon,
    nodeIconClassName,
    "nodeTypeIcon"
  );
  const textClassName = classnames(classes.text, {
    [classes.noWrap]: !wrapUrlText
  });

  const typographyVariant = size === "small" ? "body2" : "h6";
  const nodeIconSize = size === "small" ? 3 : 4;

  const finalLabel = showRootAlias && label === "~" ? `${label} (root)` : label;

  return (
    <div className={rootClassName}>
      <StageIcon stage={stage.toLowerCase() as ForgeEntityStage} size={size} />
      <SiteNodeIcon
        className={nodeTypeIconClassName}
        nodeType={nodeType}
        size={nodeIconSize}
        contextName={contextName}
        isAlias={isAlias}
      />
      <Typography variant={typographyVariant} className={textClassName}>
        {parseLabelToHtml ? HTMLReactParser(finalLabel) : finalLabel}
      </Typography>
    </div>
  );
};
