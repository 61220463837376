import classnames from "classnames";
import { Checkbox, CheckboxProps, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps, CheckboxProps {}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const RoundCheckbox = (props: IProps) => {
  const { className, ...other } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Checkbox
      className={rootClassName}
      icon={<UxdIcon name="circle" />}
      checkedIcon={<UxdIcon name="check_circle" />}
      indeterminateIcon={<UxdIcon name="radio_button_checked" />}
      {...other}
    />
  );
};
