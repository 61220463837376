import { ApiClient } from "api/apiClient";
import { SiteItemHistory } from "models/history";
import { SiteNodeType } from "models/siteStructure";

const BASE_URL = "api/history";

export class HistoryApi {
  static readonly getHistory = async (
    id: string,
    type: SiteNodeType
  ): Promise<SiteItemHistory> => {
    const endpoint = `${BASE_URL}/${type}/${id}`;
    return ApiClient.get(endpoint);
  };
}
