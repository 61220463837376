import { ContentPickerQueryFilter } from "components/contentPicker/contentPickerContext";
import { userService } from "services/userService";
import { toQueryStringDateTimeRange } from "utils/lastUpdateDateFilterUtils";

export function getFilterQuery(queryFilter: ContentPickerQueryFilter) {
  const parts: string[] = [];

  if (queryFilter.terms) {
    parts.push(`terms=${queryFilter.terms}`);
  }

  if (queryFilter.language && queryFilter.language !== "(any)") {
    parts.push(`language=${queryFilter.language}`);
  }

  if (queryFilter.sortBy && queryFilter.sortBy !== "(default)") {
    parts.push(`sortBy=${queryFilter.sortBy}`);
  }

  if (queryFilter.slug) {
    parts.push(`slug=${queryFilter.slug}`);
  }

  if (queryFilter.lastUpdateDate && queryFilter.lastUpdateDate !== "(any)") {
    const dateTimeRange = toQueryStringDateTimeRange(
      queryFilter.lastUpdateDate
    );
    parts.push(`lastUpdateDate=${dateTimeRange}`);
  }

  if (queryFilter.stage && queryFilter.stage !== "(any)") {
    parts.push(`stage=${queryFilter.stage}`);
  }

  if (queryFilter.tags.length > 0) {
    parts.push(`tag=${queryFilter.tags.map((t) => t.slug).join(";")}`);
  }

  if (queryFilter.context) {
    parts.push(`context=${queryFilter.context.slug}`);
  }

  const queryString = parts.join("&");
  return queryString;
}

export function isUserAllowedToEntity(entityTypeCode: string) {
  const permissions = getRequiredPermissionsForEntity(entityTypeCode);
  return userService.hasPermissions(...permissions);
}

function getRequiredPermissionsForEntity(entityTypeCode: string) {
  const [type, code] = entityTypeCode.split(".");
  const basicAccess = "wcm.basicaccess";
  const viewPermission = `wcm.view${getEntityTypePlural(type)}`;
  const canOperateOnPermission = `canoperateon.wcm.${code || type}`;

  return [basicAccess, viewPermission, canOperateOnPermission];
}

export function getEntityTypePlural(entityType: string) {
  switch (entityType) {
    case "story":
      return "stories";
    case "photo":
      return "photos";
    case "album":
      return "albums";
    case "document":
      return "documents";
    case "selection":
      return "selections";
    case "customentity":
      return "customentities";
    default:
      return `${entityType}s`;
  }
}
