import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { Hint } from "components/shared/hint";

interface IProps extends IBaseProps {
  value: string;
  hint?: string;
  mandatory?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  text: {
    fontWeight: 400,
    color: theme.palette.custom.ds.grayscales.gr800
  },
  disabled: {
    opacity: 0.5
  }
}));

export const TextFieldLabel = (props: IProps) => {
  const { className, value, hint, mandatory = false, disabled = false } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const textClassName = classnames(classes.text, {
    [classes.disabled]: disabled
  });
  const mandatoryIndicator = mandatory ? "(*) " : "";

  const hintComponent = hint ? <Hint hint={hint} /> : <></>;

  return (
    <div className={rootClassName}>
      <Typography className={textClassName} variant="caption">
        {mandatoryIndicator}
        {value}
      </Typography>
      {hintComponent}
    </div>
  );
};
