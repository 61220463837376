import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import emptyFolder from "images/empty_folder.svg";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    fontSize: theme.spacing(8)
  },
  image: {
    width: "322px",
    height: "266px",
    margin: theme.spacing(10, 0, 8),
    display: "block",
    background: `url(${emptyFolder})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    mixBlendMode: "multiply",
    lineHeight: "20em",
    textAlign: "center",
    backgroundRepeat: "no-repeat"
  },
  text: {
    pointerEvents: "none",
    userSelect: "none",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr800,
    margin: theme.spacing(0, 0, 3)
  },
  textSubTitle: {
    pointerEvents: "none",
    userSelect: "none",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr600,
    width: "289px"
  }
}));

export const EmptyDirectory = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.image}></div>
      <Typography className={classes.text} variant="h3">
        {translate("sitestructure.emptydirectory")}
      </Typography>
      <Typography className={classes.textSubTitle} variant="subtitle1">
        {translate("sitestructure.emptydirectorysubtitle")}
      </Typography>
    </div>
  );
};
