import { ListItemIcon, makeStyles, MenuItem } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import { ISiteItem } from "models/siteItem";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { openRenameModal } from "redux/actions/siteItem/renameSiteItem";
import { userService } from "services/userService";
import { translate } from "utils/i18n";

interface IProps {
  node: ISiteItem;
  onOpenModal?: () => void;
  onClickCallback?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
}
const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.spacing(2),
    color: theme.palette.custom.ds.viking.viking500
  }
}));

interface ItemProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}
const Item = React.forwardRef<HTMLLIElement, ItemProps>((itemProps, ref) => {
  const { children, disabled = false, onClick = () => undefined } = itemProps;
  return (
    <MenuItem onClick={onClick} disabled={disabled} ref={ref}>
      {children}
    </MenuItem>
  );
});

export function RenameSiteItemMenuItem(props: IProps) {
  const classes = useStyles();
  const { node, onClickCallback, disabled = false } = props;
  const dispatch = useDispatch();

  const openModal = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    dispatch(openRenameModal(node));
    if (onClickCallback) {
      onClickCallback(event);
    }
  };

  const disable = disabled || !userService.hasSiteItemPermissions(node.path);

  return (
    <>
      <Item onClick={openModal} disabled={disable}>
        <ListItemIcon>
          <UxdIcon
            className={classes.icon}
            name="drive_file_rename_outline"
            outline={false}
          />
        </ListItemIcon>
        {translate("sitestructure.rename")}
      </Item>
    </>
  );
}
