import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  RenameSiteDirectory,
  RenameSiteMenu,
  RenameSitePage
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { RenameSiteItemPayload } from "redux/actions/typings/siteStructureActions";
import { renameSiteItem } from "redux/actions/siteStructureActions";
import { SiteNodeTypes } from "models/siteStructure";

const COMMAND_MAP = {
  Page: RenameSitePage,
  Directory: RenameSiteDirectory,
  Menu: RenameSiteMenu
};

export function* sendRenameSiteItem(action: Action<RenameSiteItemPayload>) {
  try {
    const { itemId, newName, itemType } = action.payload;
    const handledItemType = itemType ?? SiteNodeTypes.PAGE;
    const messagePrefix = `notification.rename${handledItemType.toLowerCase()}`;

    const command = new COMMAND_MAP[handledItemType]({
      [`${handledItemType.toLowerCase()}Id`]: itemId,
      name: newName
    });

    const commandAction = issueCommand({
      command,
      errorMessage: `${messagePrefix}.error`,
      successMessage: `${messagePrefix}.success`,
      toggleNotification: true
    });

    yield put(commandAction);
    yield put(renameSiteItem(action.payload));
  } catch (error) {
    console.error(error);
  }
}
