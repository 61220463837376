import { ApiClient } from "api/apiClient";
import { IAliasEntity } from "models/aliases";

const BASE_URL = "/api/aliases";

export class AliasesApi {
  static readonly getAliases = async (): Promise<IAliasEntity[]> => {
    const endpoint = `${BASE_URL}?aliasKind=All`;
    return ApiClient.get(endpoint);
  };
}
