import { makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { ScrollSpyElem } from "components/shared/scrollspy";
import { IBaseProps } from "components/_baseProps";
import { ITypedVariables } from "models/variables";

interface IProps extends IBaseProps {
  item: ScrollSpyElem<ITypedVariables>;
  active: boolean;
  capitalize?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.grayscales.gr700,
    margin: theme.spacing(0.5, 0),
    fontSize: theme.typography.pxToRem(14)
  },
  active: {
    color: theme.palette.custom.ds.viking.viking500
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

export function VariableNavItem(props: IProps) {
  const { item, active, capitalize = true } = props;
  const classes = useStyles(props);

  const rootClassName = classnames(classes.root, {
    [classes.active]: active,
    [classes.capitalize]: capitalize
  });

  return (
    <Typography component={"span"} className={rootClassName}>
      {item.text}
    </Typography>
  );
}
