import { useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { IBaseProps } from "components/_baseProps";

import { setCurrentRoute, fetchVersion } from "redux/actions/appActions";
import { dashboardRoute } from "routes/routes";
import { ICurrentRouteState, IDomainState } from "models/domainStates";
import { translate } from "utils/i18n";
import { Logo } from "components/shared/logo";
import { Version } from "./version";

interface IProps extends IBaseProps {
  version: string;
  dispatchFetchVersion: () => void;
  dispatchSetCurrentRoute: (routeState: ICurrentRouteState) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4)
  },
  welcome: {
    padding: theme.spacing(1),
    fontSize: "14px"
  },
  logo: {
    width: "100%",
    maxWidth: "306px",
    height: "auto",
    margin: "auto"
  },
  version: {
    bottom: 0,
    position: "absolute",
    left: theme.spacing(8),
    right: 0,
    padding: theme.spacing(2)
  }
}));

const DashboardComponent = (props: IProps) => {
  const { className, dispatchSetCurrentRoute, dispatchFetchVersion, version } =
    props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  useEffect(() => {
    dispatchSetCurrentRoute({
      name: dashboardRoute.name,
      path: dashboardRoute.path
    });
  }, [dispatchSetCurrentRoute]);

  useEffect(() => {
    dispatchFetchVersion();
  }, [dispatchFetchVersion]);

  return (
    <div className={rootClassName}>
      <Typography className={classes.welcome}>
        {translate("home.welcome")}
      </Typography>
      <Logo className={classes.logo} variant="doubleline-blue" />
      {version && <Version className={classes.version} version={version} />}
    </div>
  );
};

const mapStateToProps = ({ app }: IDomainState) => ({
  version: app.version
});

const mapDispatchToProps = {
  dispatchFetchVersion: fetchVersion,
  dispatchSetCurrentRoute: setCurrentRoute
};

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
