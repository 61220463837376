import { AssetCatalogApi } from "api/assetCatalog";
import { put, call } from "redux-saga/effects";
import {
  fetchModulesFailed,
  fetchModulesSucceded
} from "redux/actions/assetCatalogActions";

export function* getModules() {
  try {
    const modules = yield call(AssetCatalogApi.getModules);
    yield put(fetchModulesSucceded(modules));
  } catch (error) {
    yield put(fetchModulesFailed(error));
  }
}
