import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import {
  AliasOperationType,
  IAliasMetadataForm,
  IAliasValidationEntry
} from "components/siteStructure/aliases/types";
import RestoreButton from "./RestoreButton";
import FieldValidationMessage from "components/siteStructure/aliases/components/FieldValidationMessage";
import AllowedValuesContainer from "components/siteStructure/aliases/components/allowedValuesContainer";
import classNames from "classnames";

export interface IUrlAliasInputProps {
  culture: string;
  form: IAliasMetadataForm;
  loading: boolean;
  showParameters: boolean;
  showRestore: boolean;
  validation?: IAliasValidationEntry;
  hidden: boolean;
  onUpdate: (culture: string, value: string) => void;
  onRestore: (isAlias: boolean) => void;
  onBlur: (
    operation: AliasOperationType,
    form: IAliasMetadataForm,
    paramName?: string
  ) => void;
}

export const UrlAliasInput = (props: IUrlAliasInputProps) => {
  const classes = useStyles();
  const {
    form,
    validation,
    culture,
    loading,
    showRestore,
    showParameters,
    hidden,
    onBlur,
    onUpdate,
    onRestore
  } = props;

  if (hidden) return <></>;

  return (
    <>
      <div className={classes.row}>
        <div className={classes.cell}>
          <Typography className={classes.labelKey} component="label">
            {translate("sitestructure.urlssection.entities.alias")}
          </Typography>
        </div>
        <div className={classNames(classes.cell, classes.growFactor)}>
          <Typography className={classes.path} component="label">
            ~/
          </Typography>

          <div className={classNames(classes.pathSegment, classes.growFactor)}>
            <TextField
              fullWidth
              placeholder={translate(
                "sitestructure.urlssection.type_short_url"
              )}
              onBlur={() => onBlur("ALIAS_VALUE", form)}
              value={form.value}
              variant="outlined"
              onChange={(e) => onUpdate(culture, e.target.value)}
              size="small"
              className={classes.textField}
            />
            {showRestore && <RestoreButton alias onRestore={onRestore} />}
            {loading && (
              <CircularProgress className={classes.restoreButton} size={16} />
            )}
          </div>
        </div>
      </div>

      <FieldValidationMessage validation={validation} />
      {showParameters && (
        <div className={classes.row}>
          <AllowedValuesContainer aliasForm={form} />
        </div>
      )}
    </>
  );
};

export default UrlAliasInput;
