import { assign, merge } from "lodash";
import { Action } from "redux-actions";
import {
  fetchPageSucceded,
  setSelectedPageElement,
  unsetSelectedPageElement,
  unsetSelectedNode,
  openSidebar,
  closeSidebar,
  setModuleProperty,
  setLayoutProperty,
  fetchSiteItemsSucceded,
  fetchSiteItemsFromRootSucceded,
  renameSiteItem,
  removeSiteItem,
  setLastUpdateInfo,
  changeItemStage,
  openHistory,
  closeHistory,
  setModuleLabel,
  setModuleImportance,
  reloadPage,
  changeTabView,
  setMetadataSiteItem,
  addMetadataToSiteItem,
  removeMetadataFromSiteItem,
  updateUriSegmentTranslation,
  setPageRequiredModuleWarning,
  resetPageRequiredModuleWarning,
  addSiteItemAuthGroups,
  removeSiteItemAuthGroups,
  setRenameSiteItem,
  fetchMenuSucceded,
  setSelectedMenuItem,
  unsetSelectedMenuItem,
  setLastTriggeredCommandId,
  unsetLastTriggeredCommandId
} from "redux/actions/siteStructureActions";
import { reducerFactory } from "./coreReducer";
import { ISiteStructureState } from "models/domainStates";
import { IPage, IModule, ILayout } from "models/pages";
import {
  AddMetadataSiteItemPayload,
  AddSiteItemAuthGroupsPayload,
  ChangeItemStagePayload,
  FetchSiteItemsSuccededPayload,
  RemoveSiteItemAuthGroupsPayload,
  RenameSiteItemPayload,
  SetLastUpdateInfoPayload,
  SetLayoutPropertyPayload,
  SetMetadataSiteItemPayload,
  SetModuleImportancePayload,
  SetModuleLabelPayload,
  SetModulePropertyPayload,
  SiteMetadataWthID,
  UpdateUriSegmentTranslation
} from "redux/actions/typings/siteStructureActions";
import {
  getPageElement,
  pageElementExists,
  setPageElementProperty
} from "services/pageService";
import { INITIAL_SITE_ITEMS, ISiteItem, SiteItems } from "models/siteItem";
import { updateSubPath } from "utils/pathUtils";
import { AvailableTabs, CONTENT_VIEW } from "routes/tabs-routes";
import { IMetadataProperty } from "models/metadata";
import { IMenu, IMenuItem } from "models/menus";

function updateChildrenPath(
  siteItems: SiteItems,
  parent: ISiteItem,
  newSubPath: string
) {
  for (const childId of parent.children) {
    const child = siteItems[childId];
    child.path = updateSubPath(child.path, newSubPath);

    updateChildrenPath(siteItems, child, newSubPath);
  }
}

function removeAllChildren(siteItems: SiteItems, node: ISiteItem) {
  for (const childId of node.children) {
    removeAllChildren(siteItems, siteItems[childId]);
    delete siteItems[childId];
  }
  node.children = [];
}

function forEachMetadata(
  siteItem: ISiteItem,
  metadata: SiteMetadataWthID[],
  callback: (
    met: SiteMetadataWthID,
    property: IMetadataProperty | undefined
  ) => void
) {
  for (const met of metadata) {
    const { name: property, category } = met;
    const itemCategory = siteItem.metadata?.find((cat) => cat.id === category);
    const itemProperty = itemCategory?.properties.find(
      (prop) => prop.id === property
    );

    callback(met, itemProperty);
  }
}

export const initialState: ISiteStructureState = {
  siteItems: INITIAL_SITE_ITEMS,
  selectedNode: null,
  selectedPageElement: null,
  sidebarOpen: true,
  historyOpen: false,
  reloadPage: false,
  renamedItemPath: "",
  selectedTab: CONTENT_VIEW,
  selectedMenuItem: null,
  pageRequiredModuleWarning: {},
  lastTriggeredCommandId: null
};

const reactions = {
  [fetchSiteItemsSucceded.toString()]: (
    state: ISiteStructureState,
    action: Action<FetchSiteItemsSuccededPayload>
  ) => {
    const { items, shouldMerge } = action.payload;
    if (shouldMerge) {
      merge(state.siteItems, items);
    } else {
      assign(state.siteItems, items);
    }
  },

  [fetchSiteItemsFromRootSucceded.toString()]: (
    state: ISiteStructureState,
    action: Action<Map<string, ISiteItem>>
  ) => {
    merge(state.siteItems, action.payload);
  },

  [fetchPageSucceded.toString()]: (
    state: ISiteStructureState,
    action: Action<IPage>
  ) => {
    const { payload: page } = action;
    const { selectedNode, selectedPageElement } = state;

    // unselect the module/layout only if is not a refresh of the same page
    if (selectedPageElement) {
      const clearSelection =
        selectedNode?.id !== page.id ||
        !pageElementExists(page, selectedPageElement);

      if (clearSelection) {
        state.selectedPageElement = null;
      }
    }

    state.selectedNode = page;
  },

  [setSelectedPageElement.toString()]: (
    state: ISiteStructureState,
    action: Action<IModule | ILayout | string>
  ) => {
    state.selectedPageElement =
      typeof action.payload === "string"
        ? action.payload
        : action.payload.instanceId;
    state.sidebarOpen = true;
  },
  [unsetSelectedPageElement.toString()]: (state: ISiteStructureState) => {
    state.selectedPageElement = null;
  },
  [unsetSelectedNode.toString()]: (state: ISiteStructureState) => {
    state.selectedNode = null;
  },
  [openSidebar.toString()]: (state: ISiteStructureState) => {
    state.sidebarOpen = true;
  },
  [closeSidebar.toString()]: (state: ISiteStructureState) => {
    state.sidebarOpen = false;
  },
  [setModuleProperty.toString()]: (
    state: ISiteStructureState,
    action: Action<SetModulePropertyPayload>
  ) => {
    const selectedNode = state.selectedNode as IPage;

    if (!selectedNode) {
      return state;
    }

    const { moduleInstanceId, name, value } = action.payload;
    setPageElementProperty(selectedNode, moduleInstanceId, name, value);
  },
  [setLayoutProperty.toString()]: (
    state: ISiteStructureState,
    action: Action<SetLayoutPropertyPayload>
  ) => {
    const selectedNode = state.selectedNode as IPage;

    if (!selectedNode) {
      return state;
    }

    const { layoutInstanceId, name, value } = action.payload;
    setPageElementProperty(selectedNode, layoutInstanceId, name, value);
  },
  [renameSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<RenameSiteItemPayload>
  ) => {
    const { itemId, fullPath, newName } = action.payload;

    const item = state.siteItems[itemId];
    if (!item) {
      return state;
    }

    item.path = fullPath;
    item.label = newName;

    updateChildrenPath(state.siteItems, item, fullPath);
  },
  [setRenameSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<RenameSiteItemPayload>
  ) => {
    const { fullPath } = action.payload;
    state.renamedItemPath = fullPath;
  },
  [removeSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<string>
  ) => {
    const itemId = action.payload;
    if (!state.siteItems[itemId]) {
      return state;
    }

    // remove from parent
    const parentsIds = state.siteItems[itemId].parentNodesIds;
    if (parentsIds.length > 0) {
      const parentDirectoryId = parentsIds[parentsIds.length - 1];
      const parent = state.siteItems[parentDirectoryId];
      parent.children.splice(parent.children.indexOf(itemId), 1);
    }

    // remove from map
    removeAllChildren(state.siteItems, state.siteItems[itemId]);

    // remove site item
    delete state.siteItems[itemId];
  },
  [setLastUpdateInfo.toString()]: (
    state: ISiteStructureState,
    action: Action<SetLastUpdateInfoPayload>
  ) => {
    const { itemId, userId, firstName, lastName, dateModified } =
      action.payload;

    const item = state.siteItems[itemId];
    if (!state.siteItems[itemId]) {
      return state;
    }

    item.updateInfo.lastUpdateUserId = userId;
    item.updateInfo.lastUpdateUser = `${firstName} ${lastName}`;
    item.updateInfo.lastUpdateDate = dateModified;
  },
  [changeItemStage.toString()]: (
    state: ISiteStructureState,
    action: Action<ChangeItemStagePayload>
  ) => {
    const { itemId, stage } = action.payload;
    const item = state.siteItems[itemId];
    if (!state.siteItems[itemId]) {
      return state;
    }

    item.status = stage;
  },
  [openHistory.toString()]: (state: ISiteStructureState) => {
    state.historyOpen = true;
  },
  [closeHistory.toString()]: (state: ISiteStructureState) => {
    state.historyOpen = false;
  },
  [setModuleLabel.toString()]: (
    state: ISiteStructureState,
    action: Action<SetModuleLabelPayload>
  ) => {
    const { moduleInstanceId, label } = action.payload;
    const selectedNode = state.selectedNode as IPage;

    if (!selectedNode) {
      return state;
    }

    const module = getPageElement(selectedNode, moduleInstanceId);
    if (!module || module.moduleType !== "Module") {
      return;
    }

    module.label = label;
  },

  [setModuleImportance.toString()]: (
    state: ISiteStructureState,
    action: Action<SetModuleImportancePayload>
  ) => {
    const { moduleInstanceId, importance } = action.payload;
    const selectedNode = state.selectedNode as IPage;

    if (!selectedNode) {
      return state;
    }

    const module = getPageElement(selectedNode, moduleInstanceId);
    if (!module || module.moduleType !== "Module") {
      return;
    }

    module.importance = importance;
  },

  [reloadPage.toString()]: (state: ISiteStructureState) => {
    state.reloadPage = !state.reloadPage;

    return state;
  },

  [changeTabView.toString()]: (
    state: ISiteStructureState,
    action: Action<ChangeItemStagePayload>
  ) => {
    const tab = action.payload;
    state.selectedTab = tab as unknown as AvailableTabs;

    return state;
  },

  [setMetadataSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<SetMetadataSiteItemPayload>
  ) => {
    const { nodeId, categories } = action.payload;
    const item = state.siteItems[nodeId];
    if (!item) {
      return state;
    }

    item.metadata = categories;
  },

  [addMetadataToSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<AddMetadataSiteItemPayload>
  ) => {
    const { nodeId, metadata } = action.payload;
    const siteItem = state.siteItems[nodeId];
    if (!siteItem || !siteItem.metadata || !metadata) {
      return state;
    }

    forEachMetadata(siteItem, metadata, (met, itemProperty) => {
      const alreadyExist = itemProperty?.items.find(
        (item) => item.id === met.id
      );
      if (alreadyExist) {
        Object.assign(alreadyExist, {
          culture: met.culture || "*",
          environment: met.environment || "*",
          value: met.value
        });
      } else {
        // All new metadata
        itemProperty?.items.push({
          culture: met.culture || "*",
          environment: met.environment || "*",
          value: met.value,
          category: met.category,
          definedIn: null,
          id: met.id
        });
      }
    });
  },

  [removeMetadataFromSiteItem.toString()]: (
    state: ISiteStructureState,
    action: Action<AddMetadataSiteItemPayload>
  ) => {
    const { nodeId, metadata } = action.payload;
    const siteItem = state.siteItems[nodeId];
    if (!siteItem || !siteItem.metadata || !metadata) {
      return state;
    }

    forEachMetadata(siteItem, metadata, (met, itemProperty) => {
      if (itemProperty?.items) {
        itemProperty.items = itemProperty?.items.filter(
          (item) => item.id !== met.id
        );
      }
    });
  },

  [updateUriSegmentTranslation.toString()]: (
    state: ISiteStructureState,
    action: Action<UpdateUriSegmentTranslation>
  ) => {
    const { nodeId, culture, value } = action.payload;

    const siteItem = state.siteItems[nodeId];
    if (!siteItem) {
      return state;
    }

    if (!siteItem.uriSegmentTranslations) {
      siteItem.uriSegmentTranslations = {
        "*": siteItem.label,
        [culture]: ""
      };
    }

    if (value) {
      siteItem.uriSegmentTranslations[culture] = value;
    } else {
      delete siteItem.uriSegmentTranslations[culture];
    }
  },

  [setPageRequiredModuleWarning.toString()]: (
    state: ISiteStructureState,
    action: Action<Record<string, boolean>>
  ) => {
    state.pageRequiredModuleWarning = Object.assign(
      {},
      state.pageRequiredModuleWarning,
      action.payload
    );
  },

  [resetPageRequiredModuleWarning.toString()]: (state: ISiteStructureState) => {
    state.pageRequiredModuleWarning = {};
  },

  [addSiteItemAuthGroups.toString()]: (
    state: ISiteStructureState,
    action: Action<AddSiteItemAuthGroupsPayload>
  ) => {
    const { nodeId, authorizationGroups } = action.payload;
    const item = state.siteItems[nodeId];

    item.authorizationGroups = [
      ...(item.authorizationGroups || ""),
      ...authorizationGroups
    ];
  },

  [removeSiteItemAuthGroups.toString()]: (
    state: ISiteStructureState,
    action: Action<RemoveSiteItemAuthGroupsPayload>
  ) => {
    const { nodeId, authorizationGroups } = action.payload;
    const item = state.siteItems[nodeId];

    item.authorizationGroups = item.authorizationGroups?.filter(
      (localAuthGroups) => !authorizationGroups.includes(localAuthGroups)
    );
  },

  [fetchMenuSucceded.toString()]: (
    state: ISiteStructureState,
    action: Action<IMenu>
  ) => {
    const { payload: menu } = action;

    state.selectedNode = menu;
  },

  [setSelectedMenuItem.toString()]: (
    state: ISiteStructureState,
    action: Action<IMenuItem>
  ) => {
    state.selectedMenuItem = action.payload;
    state.sidebarOpen = true;
  },
  [unsetSelectedMenuItem.toString()]: (state: ISiteStructureState) => {
    state.selectedMenuItem = null;
  },

  [setLastTriggeredCommandId.toString()]: (
    state: ISiteStructureState,
    action: Action<string | null>
  ) => {
    state.lastTriggeredCommandId = action.payload;
  },
  [unsetLastTriggeredCommandId.toString()]: (state: ISiteStructureState) => {
    state.lastTriggeredCommandId = null;
  }
};

export default reducerFactory(initialState, reactions);
