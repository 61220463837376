import { makeStyles, TableRow, TableCell } from "@material-ui/core";
import classnames from "classnames";
import { DirectorySkeleton, RectangleSkeleton } from "components/skeletons";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "none"
  },
  menuCell: {
    paddingRight: theme.spacing(0)
  },
  rowCut: {
    opacity: "0.6"
  }
}));

export const SkeletonListRow = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();

  const rootClassName = classnames(classes.root, className);

  return (
    <TableRow className={rootClassName} role="button">
      <TableCell align="left">
        <DirectorySkeleton />
      </TableCell>
      <TableCell align="left">
        <RectangleSkeleton />
      </TableCell>
      <TableCell />
      <TableCell className={classes.menuCell} align="right"></TableCell>
    </TableRow>
  );
};
