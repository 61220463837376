import React from "react";
import classNames from "classnames";
import { Chip, Typography } from "@material-ui/core";
import { AutocompleteRenderGroupParams } from "@material-ui/lab";
import { IBaseProps } from "components/_baseProps";

import { VariableType, VariableTypeKey } from "utils/variableUtils";
import { useStyles as useVariableItemStyles } from "components/siteStructure/variables/view/variableItem/styles";
import { useStyles as useVariableTypeListStyles } from "components/siteStructure/variables/view/variableTypeList";
import { useStyles } from "./styles";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  params: AutocompleteRenderGroupParams;
}

const VariableCodeToTypeMap: Record<string, VariableTypeKey> = {
  [VariableType.URI_VARIABLE.code]: "UriVariable",
  [VariableType.KEY_VALUE.code]: "KeyValue",
  [VariableType.DATA_LIST.code]: "DataList",
  [VariableType.DATA_ITEM.code]: "DataItem"
};

const CodeToTranslationKeyMap: Record<string, string> = {
  [VariableType.URI_VARIABLE.code]: "urivariable",
  [VariableType.KEY_VALUE.code]: "keyvalue",
  [VariableType.DATA_ITEM.code]: "dataitem",
  [VariableType.DATA_LIST.code]: "datalist"
};

const AutocompleteGroup = (props: IProps) => {
  const { params } = props;
  const variableItemConfig = {
    variable: { type: VariableCodeToTypeMap[params.group] }
  };

  const variableChipConfig = {
    variables: { type: { type: VariableCodeToTypeMap[params.group] } }
  };

  const classes = useStyles(props);
  const variableTypeClasses = useVariableItemStyles(variableItemConfig);
  const variableCodeClasses = useVariableTypeListStyles(
    variableChipConfig as any
  );

  const chipClassnames = classNames(variableCodeClasses.chip);

  const groupHeaderClassnames = classNames(
    classes.groupHeaderContainer,
    variableTypeClasses.name
  );

  const translationKeyForType = CodeToTranslationKeyMap[params.group];

  const groupNameTranslated = translate(
    `sitestructure.variables.name.${translationKeyForType}`
  );

  return (
    <li>
      <div className={classes.groupHeaderTransparencySkipper}>
        <Typography className={groupHeaderClassnames} variant="h5">
          <Chip
            className={chipClassnames}
            label={
              <Typography className={classes.chipLabel}>
                {params.group}
              </Typography>
            }
          />
          <label className={classes.groupLabel}>{groupNameTranslated}</label>
        </Typography>
      </div>
      <ul className={classes.groupChildren}>{params.children}</ul>
    </li>
  );
};
export default AutocompleteGroup;
