import { IDomainState } from "models/domainStates";

export const selectPageVariables = (state: IDomainState) =>
  state.variables.contracts;

export const selectVariableSuggestions = (state: IDomainState) =>
  state.variables.suggestions;

export const selectModalAction = (state: IDomainState) =>
  state.variables.modalAction;

export const selectRoutesSuggestions = (state: IDomainState) =>
  state.variables.routes;

export const selectCommandState = (state: IDomainState) => state.command;
