import { Theme, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1, 4),
      overflow: "hidden"
    },
    "& .MuiPaper-root": {
      height: theme.spacing(75), // 600px
      maxWidth: theme.spacing(55.5), // 444px
      overflow: "hidden"
    }
  },
  item: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.25),
    padding: theme.spacing(3, 1),
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20)
    },
    border: `1px solid transparent`,
    transition: "all .3s ease-in-out"
  },
  separator: {
    borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`
  },
  itemSelected: {
    transition: "all .3s ease-in-out",
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    borderTop: `1px solid ${theme.palette.custom.ds.viking.viking500}`
  },
  list: {
    overflowY: "auto",
    overflowX: "hidden",
    height: `calc(100% - ${theme.spacing(7)})`
  },
  search: {
    position: "sticky",
    margin: theme.spacing(1, 0),
    top: 0
  }
}));
