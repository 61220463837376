import { ICommand } from "@d3-forge/forge-commands";
import { Action, createAction } from "redux-actions";
import { CommandStatus } from "models/commandStatus";
import { NotificationMessage } from "models/notificationMessage";

export type SendCommandActionPayload = {
  command: ICommand;
  onSuccessAction?: Action<any>;
  onFailureAction?: Action<any>;
  successMessage?: NotificationMessage;
  errorMessage?: NotificationMessage;
  toggleNotification?: boolean;
};

export const issueCommand = createAction(
  "ISSUE_COMMAND",
  (payload: SendCommandActionPayload) => ({
    toggleNotification: false,
    ...payload
  })
);

export const setCommandStatus = createAction(
  "SET_COMMAND_STATUS",
  (status: CommandStatus) => status
);
