import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useDebouncedState } from "components/hooks";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import { ExternalLinkContent } from "components/linkRules/linkPicker/linkPickerModal/externalLinkContent";
import { InternalLinkContent } from "components/linkRules/linkPicker/linkPickerModal/internalLinkContent";
import { LinkFooterSection } from "components/linkRules/linkPicker/linkPickerModal/linkFooterSection";
import { useStyles } from "components/linkRules/linkPicker/linkPickerModal/styles";
import { D3Modal } from "components/shared/d3Components";
import { useState } from "react";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  isModalOpen: boolean;
  value: string;
  handleModalClose: () => void;
  onChange: (path: string, id?: string) => void;
}

class LinkTypes {
  static readonly Internal = "internal";
  static readonly External = "external";
}

export const LinkPickerModal = (props: Readonly<IProps>) => {
  const { isModalOpen, value, handleModalClose, onChange } = props;
  const classes = useStyles();
  const { isNewItem } = useLinkRulesCrudManagementContext();

  const [pageId, setPageId] = useState<string | undefined>();
  const [linkValue, debouncedLinkValue, setLinkValue] = useDebouncedState(
    value,
    800
  );

  const initialLinkType =
    value === "" || value.startsWith("~")
      ? LinkTypes.Internal
      : LinkTypes.External;
  const [selectedLinkType, setSelectedLinkType] = useState(initialLinkType);

  const isInternalLink = selectedLinkType === LinkTypes.Internal;
  const initialInternalLinkValue =
    !isInternalLink || value === "" ? "~" : value;
  const initialExternalLinkValue =
    isInternalLink || value === "" ? "https://" : value;

  const [internalLinkValue, setInternalLinkValue] = useState(
    initialInternalLinkValue
  );
  const [externalLinkValue, setExternalLinkValue] = useState(
    initialExternalLinkValue
  );

  const switchSelectedLinkType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedLinkType(event.target.value);
    setLinkValue("");
  };

  const onExternalValueChanged = (linkValue: string, isValid: boolean) => {
    setExternalLinkValue(linkValue);
    setLinkValue(isValid ? linkValue : "");
    setPageId(undefined);
  };

  const onInternalValueChanged = (id: string, path: string) => {
    setInternalLinkValue(path);
    setLinkValue(path);
    setPageId(id);
  };

  const handleConfirm = () => {
    onChange(linkValue, pageId);
    handleModalClose();
  };

  const handleCancel = () => {
    setLinkValue(value);
    setPageId(undefined);
    setInternalLinkValue(initialInternalLinkValue);
    setExternalLinkValue(initialExternalLinkValue);
    handleModalClose();
  };

  return (
    <D3Modal
      open={isModalOpen}
      maxWidth="md"
      className={classes.dialog}
      modalTitle={translate("navigationrules.sidebar.link_picker.title")}
      confirmLabel={translate(
        "navigationrules.sidebar.link_picker.footer.confirm"
      )}
      disabled={linkValue === ""}
      additionalFooterElement={
        <LinkFooterSection
          value={isInternalLink ? linkValue : debouncedLinkValue}
        />
      }
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    >
      <RadioGroup
        value={selectedLinkType}
        row
        onChange={switchSelectedLinkType}
        className={classes.content}
      >
        <div>
          <FormControlLabel
            control={<Radio disableRipple />}
            value={LinkTypes.Internal}
            label={translate(
              "navigationrules.sidebar.link_picker.internal.title"
            )}
            disabled={!isNewItem && !isInternalLink}
          />
          <InternalLinkContent
            hidden={!isInternalLink}
            value={internalLinkValue}
            onChange={onInternalValueChanged}
          />
        </div>
        <div>
          <FormControlLabel
            control={<Radio disableRipple />}
            value={LinkTypes.External}
            label={translate(
              "navigationrules.sidebar.link_picker.external.title"
            )}
            disabled={!isNewItem && isInternalLink}
          />
          <ExternalLinkContent
            disabled={isInternalLink}
            value={externalLinkValue}
            onChange={onExternalValueChanged}
          />
        </div>
      </RadioGroup>
    </D3Modal>
  );
};
