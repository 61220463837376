import React from "react";
import { Link } from "react-router-dom";
import { IBaseProps } from "components/_baseProps";
import { makeStyles, ListItem, Tooltip, ListItemText } from "@material-ui/core";
import classnames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  icon: string;
  compact: boolean;
  text: string;
  path: string;
  active: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.viking.viking700,
    height: "48px",
    padding: theme.spacing(0, 3),
    transition: "all .3s ease-in-out",
    fontSize: "18px",
    WebkitTapHighlightColor: "transparent",
    backgroundColor: "transparent",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    opacity: 0.6,
    "&:hover, &:focus": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      "& $text": {
        zIndex: 1,
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.1,
        backgroundColor: theme.palette.common.black
      }
    },
    "&:active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      }
    },
    "&$active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.3,
        backgroundColor: theme.palette.common.black
      }
    }
  },
  text: {
    paddingLeft: theme.spacing(2),
    color: theme.palette.common.white,
    zIndex: 1,
    "&$active": {
      opacity: 1,
      backgroundColor: "unset"
    },
    "& span": {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  icon: {},
  active: {}, // pseudo-class for active state
  compact: {} // pseudo-class for compact state
}));

export const DrawerEntry = (props: IProps) => {
  const { className, icon, compact, path, text, active, onClick } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className, {
    [classes.compact]: compact,
    [classes.active]: active
  });

  const iconClassName = classnames(classes.icon, {
    [classes.active]: active
  });

  const textClassName = classnames(classes.text, {
    [classes.active]: active
  });

  return (
    <Tooltip
      title={text}
      placement="right"
      disableFocusListener={!compact}
      disableHoverListener={!compact}
      disableTouchListener={!compact}
    >
      <ListItem
        button
        className={rootClassName}
        component={Link}
        to={path}
        onClick={onClick}
      >
        <UxdIcon className={iconClassName} name={icon} />
        {!compact && <ListItemText className={textClassName} primary={text} />}
      </ListItem>
    </Tooltip>
  );
};
