import { reducerFactory } from "./coreReducer";
import { Action } from "redux-actions";
import { IAssetCatalogState } from "models/domainStates";
import {
  fetchModulesSucceded,
  fetchLayoutsSucceded,
  fetchTemplatesSucceded,
  fetchContextsSucceded
} from "redux/actions/assetCatalogActions";
import { IContextModules } from "models/siteAssets/moduleDefinition";
import { IContextLayouts } from "models/siteAssets/layoutDefinition";
import { IContextTemplates } from "models/siteAssets/templateDefinition";
import { IContextDefinition } from "models/siteAssets/contextDefinition";

export const initialState: IAssetCatalogState = {
  modules: {},
  layouts: {},
  templates: {},
  contexts: []
};

const reactions = {
  [fetchModulesSucceded.toString()]: (
    state: IAssetCatalogState,
    action: Action<IContextModules>
  ) => {
    state.modules = action.payload;
  },
  [fetchLayoutsSucceded.toString()]: (
    state: IAssetCatalogState,
    action: Action<IContextLayouts>
  ) => {
    state.layouts = action.payload;
  },
  [fetchTemplatesSucceded.toString()]: (
    state: IAssetCatalogState,
    action: Action<IContextTemplates>
  ) => {
    // Temporary change No Template label to Blank Template
    state.templates = action.payload;
  },
  [fetchContextsSucceded.toString()]: (
    state: IAssetCatalogState,
    action: Action<IContextDefinition[]>
  ) => {
    state.contexts = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
