import { ISiteItem } from "models/siteItem";

const validationRegex = new RegExp(
  /^((([\w-]*\{[a-zA-Z_]\w*\}[\w-]*)|([\w-]+)))+$/
);

export const hasSiblingsWithSameName = (
  siblings: ISiteItem[],
  name: string
): boolean => {
  const lowercaseLabel = name.toLowerCase();
  return siblings.some((c) => c.label.toLowerCase() === lowercaseLabel);
};

export const validateName = (name: string, siblings: ISiteItem[]): string => {
  if (!validationRegex.test(name)) {
    return "errorhints.invaliditemname";
  }

  if (hasSiblingsWithSameName(siblings, name)) {
    return "errorhints.itemsamename";
  }

  return "";
};
