import { reducerFactory } from "./coreReducer";

import { Action } from "redux-actions";
import { fetchFrontendSitesSucceeded } from "redux/actions/frontendActions";
import { IFrontendSitesDetail, IFrontendState } from "models/domainStates";

export const initialState: IFrontendState = {
  sites: []
};

const reactions = {
  [fetchFrontendSitesSucceeded.toString()]: (
    state: IFrontendState,
    action: Action<IFrontendSitesDetail[]>
  ) => {
    state.sites = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
