import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { UnPublishDirectory } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";

export function* sendUnpublishDirectory(action: Action<string>) {
  try {
    const itemId = action.payload;

    const command = new UnPublishDirectory({
      itemId
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.unpublishdirectory.error",
      successMessage: "notification.unpublishdirectory.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
