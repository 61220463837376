import { Button, makeStyles } from "@material-ui/core";
import { PreviewToolTip } from "components/previewPage/previewBar/previewToolTip";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.custom.ds.viking.viking500
  }
}));

export const LandscapeSelect = ({ onLandscapeChange }) => {
  const classes = useStyles();

  return (
    <PreviewToolTip title={translate("preview.tooltips.rotatescreen")}>
      <Button className={classes.icon} onClick={() => onLandscapeChange()}>
        <UxdIcon name="screen_rotation" />
      </Button>
    </PreviewToolTip>
  );
};
