import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 1, 0, 4)
  },
  description: {
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr600,
    paddingBottom: theme.spacing(2),
    display: "block"
  }
}));
