import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { D3AddFab } from "components/shared/d3Components";
import { IBaseProps } from "components/_baseProps";
import { VariableType } from "utils/variableUtils";
import { IVariableType } from "models/variables";
import AddVariableMenuItem from "components/siteStructure/variables/view/addVariableMenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(4),
    bottom: theme.spacing(5)
  }
}));

interface IProps extends IBaseProps {
  onClick: (variableType: IVariableType) => void;
}

const MenuItemsConfig = [
  {
    variableType: VariableType.KEY_VALUE
  },
  {
    variableType: VariableType.DATA_ITEM
  },
  {
    variableType: VariableType.DATA_LIST
  }
];

export const AddVariableFab = (props: IProps) => {
  const { onClick, className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const onMenuItemClick = (variableType: IVariableType) =>
    onClick(variableType);

  return (
    <D3AddFab className={rootClassName}>
      {MenuItemsConfig.map((item) => (
        <AddVariableMenuItem
          key={item.variableType.type}
          type={item.variableType}
          onClick={() => onMenuItemClick(item.variableType)}
        />
      ))}
    </D3AddFab>
  );
};

export default AddVariableFab;
