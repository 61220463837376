import { makeStyles, Typography } from "@material-ui/core";
import LinkRuleEntryItem from "components/linkRules/linkRuleEntryItem";

import { ILinkRuleEntityEntry } from "models/linkRules";

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    boxSizing: "border-box",
    padding: theme.spacing(2, 4)
  },
  groupHeader: {
    height: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  groupTitle: {
    color: theme.palette.custom.ds.grayscales.gr900,
    fontSize: theme.typography.pxToRem(18)
  },
  groupList: {}
}));

interface IProps {
  entityKey: string;
  linkRuleEntry: ILinkRuleEntityEntry;
}

export default function LinkRuleEntityGroup(props: Readonly<IProps>) {
  const classes = useStyles();
  const { linkRuleEntry, entityKey } = props;
  const { linkRules } = linkRuleEntry;

  return (
    <div className={classes.groupContainer} id={entityKey}>
      <div className="container-content">
        <div className={classes.groupHeader}>
          <Typography className={classes.groupTitle}>
            {linkRuleEntry.displayName}
          </Typography>
        </div>
        <div className={classes.groupList}>
          {linkRules.map((linkRuleEntry) => (
            <LinkRuleEntryItem
              key={linkRuleEntry.id}
              linkRuleEntry={linkRuleEntry}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
