import classnames from "classnames";
import { makeStyles, TableCell, TableHead, TableRow } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { RoundCheckbox } from "components/shared/roundCheckbox";
import { noop } from "utils/typescriptUtils";
import { useCallback } from "react";

interface IProps extends IBaseProps {
  checked: boolean;
  onSelectAll?: (selected: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cell: {
    paddingLeft: theme.spacing(1),
    textAlign: "left",
    whiteSpace: "nowrap"
  },
  nameCell: {
    paddingLeft: theme.spacing(3)
  }
}));

export const SiteItemsTableHeader = (props: IProps) => {
  const { className, checked, onSelectAll = noop } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const nameCellClassName = classnames(classes.cell, classes.nameCell);

  const handleChange = useCallback(
    (_: any, isChecked: boolean) => onSelectAll(isChecked),
    [onSelectAll]
  );

  return (
    <TableHead>
      <TableRow className={rootClassName}>
        <TableCell padding="checkbox">
          <RoundCheckbox checked={checked} onChange={handleChange} />
        </TableCell>
        <TableCell className={nameCellClassName}>
          {translate("sitestructure.name")}
        </TableCell>
        <TableCell className={classes.cell}>
          {translate("sitestructure.path")}
        </TableCell>
        <TableCell className={classes.cell}>
          {translate("sitestructure.lastupdated")}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
