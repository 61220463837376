import React from "react";
import classnames from "classnames";
import {
  makeStyles,
  Accordion as MuiAccordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  label: string | React.ReactNode;
  children: React.ReactNode;
  showDivider?: boolean;
  defaultExpanded?: boolean;
}

const useAccordionOverrides = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    "&:before": {
      display: "none !important"
    },
    "&$expanded": {
      margin: theme.spacing(0),
      "&:first-child": {
        marginTop: theme.spacing(0)
      },
      "&:last-child": {
        marginBottom: theme.spacing(0)
      }
    },
    "&$expanded + &": {
      "&:before": {
        display: "block"
      }
    }
  },
  expanded: {}
}));

const useAccordionSummaryOverrides = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(7),
    borderBottom: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    backgroundColor: theme.palette.common.white,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      boxShadow: `${theme.spacing(0, 0.125, 0.5)} ${
        theme.palette.custom.greyscales.shadows.sh500 // FIX: shadow non visible on hover
      }`
    },
    "&$expanded": {
      backgroundColor: theme.palette.custom.viking.viking100,
      minHeight: theme.spacing(7)
    }
  },
  expandIcon: {
    color: theme.palette.custom.viking.viking500,
    "& span": {
      fontSize: theme.spacing(3)
    }
  },
  content: {
    margin: theme.spacing(0),
    marginLeft: theme.spacing(1),
    "&$expanded": {
      margin: theme.spacing(0),
      marginLeft: theme.spacing(1)
    }
  },
  expanded: {}
}));

const useStyles = makeStyles((theme) => ({
  root: {},
  hideDivider: {
    "&:before": {
      display: "none !important"
    }
  },
  label: {
    fontSize: theme.typography.pxToRem(14)
  },
  content: {
    flexDirection: "column"
  },
  warning: {
    color: theme.palette.custom.ds.warning.warning500,
    margin: "auto 0 auto auto",
    fontSize: theme.typography.pxToRem(18)
  }
}));

export const Accordion = (props: IProps) => {
  const {
    className,
    children,
    label,
    showDivider = true,
    defaultExpanded = true
  } = props;
  const classes = useStyles();
  const accordionClasses = useAccordionOverrides();
  const accordionSummaryClasses = useAccordionSummaryOverrides();
  const rootClassName = classnames(
    classes.root,
    { [classes.hideDivider]: !showDivider },
    className
  );

  const isEmptyAndMandatory = (property) => {
    return (
      property?.props?.propertyDefinition?.mandatory && !property?.props?.value
    );
  };

  const showWarningFlag = () => {
    let mandatoryPropertiesWithoutValue = [{}];
    if (children) {
      if (React.isValidElement(children))
        // is only one element and not an array
        return isEmptyAndMandatory(children);

      const properties = Object.values(children);
      mandatoryPropertiesWithoutValue = properties.filter((x) =>
        isEmptyAndMandatory(x)
      );
    }

    return mandatoryPropertiesWithoutValue.length > 0;
  };

  return (
    <MuiAccordion
      className={rootClassName}
      classes={accordionClasses}
      square={true}
      elevation={0}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        classes={accordionSummaryClasses}
        expandIcon={<UxdIcon name="expand_more" />}
      >
        {typeof label === "string" ? (
          <Typography className={classes.label} variant="h6">
            {label}
          </Typography>
        ) : (
          label
        )}
        <UxdIcon
          name="warning"
          className={classes.warning}
          hidden={!showWarningFlag()}
        />
      </AccordionSummary>
      <AccordionDetails className={classes.content}>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};
