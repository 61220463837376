import React, { useState } from "react";
import classnames from "classnames";
import { IconButton, makeStyles, Menu } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { ISiteItem } from "models/siteItem";
import { ClipboardPageAction } from "components/siteStructure/header/clipboardPageAction";
import { RenameSiteItemMenuItem } from "components/siteStructure/modals/renameSiteItemModal/renameSiteItemMenuItem";

interface IProps extends IBaseProps {
  node: ISiteItem;
  colorVariant?: "light" | "accent";
}

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    "&$light": {
      color: theme.palette.custom.ds.grayscales.gr500
    },
    "&$accent": {
      color: theme.palette.custom.icons.blueAccent
    },
    margin: "auto",
    minWidth: "unset",
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr500,
    "&:hover": {
      backgroundColor: "transparent",
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  light: {},
  accent: {}
}));

export const ContextualMenu = (props: IProps) => {
  const { className, node } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={rootClassName}>
      <IconButton
        className={classes.menuButton}
        onClick={handleClick}
        disableRipple
      >
        <UxdIcon name="more_vert" />
      </IconButton>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <RenameSiteItemMenuItem
          node={node}
          onClickCallback={handleClose}
          disabled={node.parentNodesIds.length === 0}
        />
        <ClipboardPageAction node={node} onClickCallback={handleClose} />
      </Menu>
    </div>
  );
};
