import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { translate } from "utils/i18n";

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const SessionExpiredDialog = (props: IProps) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate("general.sessionexpired.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate("general.sessionexpired.body")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate("general.gotologin")}</Button>
      </DialogActions>
    </Dialog>
  );
};
