import { ISiteItem } from "models/siteItem";
import { SiteNodeType } from "models/siteStructure";
import { createAction } from "redux-actions";

export const openCreateModal = createAction(
  "OPEN_CREATE_SITE_ITEM",
  (payload: { node: ISiteItem; type: SiteNodeType; index?: boolean }) => payload
);

export const closeCreateModal = createAction("CLOSE_CREATE_SITE_ITEM");
