import { ChangeEvent, useState } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { useDebounce } from "components/hooks";
import { D3Switch } from "components/shared/d3Components/d3Switch";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";
import { VariableInputs } from "utils/variableUtils";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value?: boolean | string;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiSwitch-root:has(~.MuiAutocomplete-root:not([hidden]))": {
      display: "none"
    },
    "& .MuiAutocomplete-root:not([hidden])": {
      flex: "50%"
    }
  },
  label: {
    flex: 1,
    "&:has(~.MuiAutocomplete-root:not([hidden]))": {
      flex: "100%"
    }
  }
}));

export const BooleanProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [localChecked, setLocalChecked] = useState(
    typeof value == "boolean" ? value : false
  );
  const debouncedOnChange = useDebounce(onChange, 500);

  const handleChange = (_: ChangeEvent, checked: boolean) => {
    setLocalChecked(checked);
    debouncedOnChange(propertyDefinition.name, `${checked}`);
  };

  const clearValueOnVariableSwitch = () => {
    setLocalChecked(false);
    debouncedOnChange(propertyDefinition.name, localChecked.toString());
  };

  return (
    <div className={rootClassName}>
      <D3Switch
        checked={localChecked}
        onChange={handleChange}
        disabled={disabled}
      />
      <TextFieldLabel
        className={classes.label}
        value={propertyDefinition.displayName || propertyDefinition.name}
        hint={propertyDefinition.description}
        disabled={disabled}
      />
      {(propertyDefinition.canUseVariables ?? true) && (
        <VariableSwitch
          propertyName={propertyDefinition.name}
          propertyValue={value?.toString()}
          disabled={disabled}
          onChange={onChange}
          onSwitch={clearValueOnVariableSwitch}
          inputType={VariableInputs.BOOLEAN}
        />
      )}
    </div>
  );
};
