import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { DialogProvider } from "muibox";
import { IBaseProps } from "components/_baseProps";
import { makeStyles } from "@material-ui/core";
import { ApiNotificationSnackbar } from "components/shared/apiNotificationSnackbar";
import { IDomainState, ICurrentRouteState } from "models/domainStates";
import { openDrawer, closeDrawer } from "redux/actions/appActions";
import { AppDrawer, MiniDrawer } from "./appDrawer";
import { CommandStatus } from "models/commandStatus";
import { getFullName, getInitials } from "utils/nameUtils";

interface IProps extends IBaseProps {
  children: React.ReactNode;
  currentRoute: ICurrentRouteState;
  commandStatus: CommandStatus;
  drawerOpen: boolean;
  dispatchOpenDrawer: () => void;
  dispatchCloseDrawer: () => void;
  userData: {
    id: string;
    initials: string;
    fullName: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  mainArea: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    background: theme.palette.custom.greyscales.backgrounds.grey99,
    overflow: "hidden"
  },
  miniDrawer: {
    height: "100%"
  }
}));

const AppContainerComponent = (props: IProps) => {
  const {
    className,
    children,
    currentRoute,
    userData,
    commandStatus,
    drawerOpen,
    dispatchOpenDrawer,
    dispatchCloseDrawer
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <DialogProvider>
        <AppDrawer
          currentPath={currentRoute.path}
          open={drawerOpen}
          onClose={() => dispatchCloseDrawer()}
          userData={userData}
          commandStatus={commandStatus}
        />
        <div className={classes.mainArea}>
          <MiniDrawer
            className={classes.miniDrawer}
            currentPath={currentRoute.path}
            userData={userData}
            commandStatus={commandStatus}
            onMenuClick={() => dispatchOpenDrawer()}
          />
          {children}
          <ApiNotificationSnackbar />
        </div>
      </DialogProvider>
    </div>
  );
};

const mapStateToProps = ({ app, user, command }: IDomainState) => ({
  drawerOpen: app.drawerOpen,
  currentRoute: app.currentRoute,
  commandStatus: command.status,
  userData: {
    id: user.id,
    initials: getInitials(user.firstName, user.lastName),
    fullName: getFullName(user.firstName, user.lastName)
  }
});

const mapDispatchToProps = {
  dispatchOpenDrawer: openDrawer,
  dispatchCloseDrawer: closeDrawer
};

export const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainerComponent);
