import some from "lodash/some";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { translate } from "utils/i18n";
import { ActionButton } from "components/shared/actionButton";
import { IOverrideSectionController } from "components/siteStructure/variables/view/variableItem";
import { IBaseProps } from "components/_baseProps";
import { IVariableContract, VariableStatus } from "models/variables";
import TranslationsActionButton from "components/siteStructure/variables/view/translationsActionButton";

import {
  getIsStandardVariableByVariableType,
  getIsURIVariableByVariableType,
  getNonDefaultVariableItems,
  getVariableStatus,
  VariableTypeKey
} from "utils/variableUtils";

const hasVariableLocallyDefinedTranslations = (variable: IVariableContract) =>
  some(
    getNonDefaultVariableItems(variable),
    (item) => getVariableStatus(item) !== "inherited"
  );

const getCanRestoreVariable = (
  variable: IVariableContract,
  status: VariableStatus
): boolean => {
  const isURIVariable = getIsURIVariableByVariableType(
    variable.type as VariableTypeKey
  );

  if (isURIVariable) {
    return false;
  }

  const hasLocallyDefinedTranslations =
    hasVariableLocallyDefinedTranslations(variable);

  return status === "overwriting" && !hasLocallyDefinedTranslations;
};

const getCanDeleteVariable = (
  variable: IVariableContract,
  status: VariableStatus
): boolean => {
  const isURIVariable = getIsURIVariableByVariableType(
    variable.type as VariableTypeKey
  );

  if (isURIVariable) {
    return false;
  }

  const hasLocallyDefinedTranslations =
    hasVariableLocallyDefinedTranslations(variable);

  return status === "local" && !hasLocallyDefinedTranslations;
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: theme.spacing(0.25),
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(0.25)
  },
  warningIcon: {
    color: theme.palette.custom.ds.warning.warning500,
    fontSize: theme.typography.pxToRem(16),
    margin: "auto 0"
  }
}));

interface IVariableItemActionsProps extends IBaseProps {
  status: VariableStatus;
  hasEditPermissions: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onRestore: () => void;
  overrideSectionController: IOverrideSectionController;
  hoverableClassname: string;
  variable: IVariableContract;
  isSampleValueDefined: boolean;
}

export const VariableItemActions = (props: IVariableItemActionsProps) => {
  const {
    status,
    className,
    overrideSectionController,
    hoverableClassname,
    variable,
    hasEditPermissions,
    isSampleValueDefined,
    onEdit,
    onDelete,
    onRestore
  } = props;
  const classes = useStyles();

  const isStandardVariable = getIsStandardVariableByVariableType(
    variable.type as VariableTypeKey
  );

  const isURIVariable = getIsURIVariableByVariableType(
    variable.type as VariableTypeKey
  );

  const local = status === "local";
  const overwritten = status === "overwriting";

  const shouldShowDeleteButton =
    hasEditPermissions && getCanDeleteVariable(variable, status);
  const shouldShowEditButton = hasEditPermissions && isStandardVariable;
  const shouldShowRestoreButton =
    hasEditPermissions && getCanRestoreVariable(variable, status);

  const shouldShowTranslationsButton = isStandardVariable;
  const shouldShowSampleValueWarningIcon =
    isURIVariable && !isSampleValueDefined;

  const editable = local || overwritten;

  const editTooltip = editable
    ? translate("sitestructure.variables.edit.title")
    : translate("sitestructure.variables.overwrite.title");

  const deleteTooltip = translate("sitestructure.variables.delete.tooltip");
  const restoreTooltip = translate("sitestructure.variables.restore.tooltip");
  const sampleValueTooltip = translate(
    "sitestructure.variables.no_sample_value_defined"
  );

  const hoverableActionButtonClasses = classNames(
    classes.button,
    hoverableClassname
  );

  return (
    <div className={className}>
      {shouldShowEditButton && (
        <ActionButton
          icon="edit"
          tooltip={editTooltip}
          size="small"
          className={hoverableActionButtonClasses}
          iconClassName={classes.icon}
          onClick={onEdit}
        />
      )}

      {shouldShowDeleteButton && (
        <ActionButton
          icon="delete"
          tooltip={deleteTooltip}
          size="small"
          className={hoverableActionButtonClasses}
          iconClassName={classes.icon}
          onClick={onDelete}
        />
      )}

      {shouldShowRestoreButton && (
        <ActionButton
          icon="autorenew"
          tooltip={restoreTooltip}
          size="small"
          className={hoverableActionButtonClasses}
          iconClassName={classes.icon}
          onClick={onRestore}
        />
      )}

      {shouldShowSampleValueWarningIcon && (
        <ActionButton
          icon="warning"
          tooltip={sampleValueTooltip}
          size="small"
          className={classes.button}
          iconClassName={classes.warningIcon}
        />
      )}

      {shouldShowTranslationsButton && (
        <TranslationsActionButton
          overrideSectionController={overrideSectionController}
        ></TranslationsActionButton>
      )}
    </div>
  );
};

export default VariableItemActions;
