import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeStage, SiteNodeTypes } from "models/siteStructure";
import { SiteNodeIcon } from "components/shared/siteNodeIcon";
import { StageIcon } from "components/shared/stageIcon";
import { ForgeEntityStage } from "models/forgeEntities";

interface IProps extends IBaseProps {
  path: string;
  stage: SiteNodeStage;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(0, 1)
  },
  leftContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    padding: theme.spacing(3, 1, 0)
  },
  pageIcon: {
    margin: theme.spacing(0, 1),
    color: theme.palette.custom.icons.noAction
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: "1.2rem",
    wordWrap: "break-word",
    wordBreak: "break-word",
    paddingTop: theme.spacing(3)
  },
  stageIcon: {
    fontSize: "8px"
  }
}));

export const DirectoryInfo = (props: IProps) => {
  const { className, path, stage } = props;

  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.leftContainer}>
        <StageIcon
          className={classes.stageIcon}
          stage={stage.toLowerCase() as ForgeEntityStage}
        />
        <SiteNodeIcon
          className={classes.pageIcon}
          nodeType={SiteNodeTypes.DIRECTORY}
          size={3}
        />
      </div>
      <Typography variant="body1" className={classes.label}>
        {path}
      </Typography>
    </div>
  );
};
