import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import { IComponentProperty } from "models/componentProperty";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { IModule } from "models/pages";

interface IProps {
  moduleDefinition: IModuleDefinition;
  module: IModule;
  moduleVariation: string;
}

interface ModulePropertyPreview {
  name: string;
  value: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  propertyWrapper: {
    marginTop: theme.spacing(2)
  },
  propertyLabel: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.custom.ds.grayscales.gr600,
    lineHeight: "20px",
    marginRight: theme.spacing(1),
    "&$disabled": {
      color: theme.palette.custom.ds.grayscales.gr500
    }
  },
  propertyValue: {
    fontSize: theme.typography.pxToRem(10),
    lineHeight: "20px"
  },
  propertyValueDefault: {
    color: theme.palette.custom.ds.grayscales.gr800
  },
  propertyValueSelected: {
    color: theme.palette.custom.ds.viking.viking700
  },
  propertyValueOrphan: {
    color: theme.palette.custom.ds.warning.warning700
  },
  propertyValueDisabled: {
    color: theme.palette.custom.ds.grayscales.gr600
  },
  disabled: {}
}));

export const ModulePropertiesPreview = (props: IProps) => {
  const { moduleDefinition, module, moduleVariation } = props;

  const classes = useStyles();
  const showDisabledStyle = moduleVariation === "disabled";

  const propertyValueClass = classnames(classes.propertyValue, {
    [classes.propertyValueDefault]: moduleVariation === "default",
    [classes.propertyValueSelected]: moduleVariation === "selected",
    [classes.propertyValueOrphan]: moduleVariation === "orphan",
    [classes.propertyValueDisabled]: showDisabledStyle
  });

  const propertyLabelClassName = classnames(classes.propertyLabel, {
    [classes.disabled]: showDisabledStyle
  });

  const modulePreviewProperties = Object.values(moduleDefinition.properties)
    .flat()
    .filter((prop) => prop.showInModule);

  const displayValue = (input: string) => {
    if (input === "true") return "On";
    if (input === "false") return "Off";

    return input;
  };

  const properties = modulePreviewProperties
    .map((prop: IComponentProperty) => {
      return {
        name: prop.displayName || prop.name,
        value: module.properties[prop.name]
      } as ModulePropertyPreview;
    })
    .filter((o) => o.value)
    .slice(0, 5);

  if (properties.length > 0) {
    return (
      <div className={classes.propertyWrapper}>
        {properties.map((prop) => {
          const propertyName = prop.name;
          const propertyValue = prop.value;

          return (
            <div key={propertyName}>
              <Typography
                className={propertyLabelClassName}
                noWrap={true}
                component="span"
              >
                {propertyName}
              </Typography>

              <Typography
                className={propertyValueClass}
                noWrap={true}
                component="span"
              >
                {displayValue(propertyValue)}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  }
  return <></>;
};
