import { IAuthorizationGroup } from "models/authorizationGroup";
import { IAuthorizationGroupState } from "models/domainStates";
import { Action } from "redux-actions";
import {
  addAuthorizationGroup,
  loadAuthorizationGroups,
  removeAuthorizationGroup,
  setAuthorizationGroupUsersUpdatedFlag,
  setCreateAuthGroupFlag,
  updateAuthorizationGroup
} from "redux/actions/authorizationGroupsActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export const initialState: IAuthorizationGroupState = {
  authorizationGroups: [],
  createStatus: false,
  usersUpdated: false
};

const reactions = {
  [loadAuthorizationGroups.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<IAuthorizationGroup[]>
  ) => {
    state.authorizationGroups = action.payload;
  },
  [addAuthorizationGroup.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<IAuthorizationGroup>
  ) => {
    state.authorizationGroups.push(action.payload);
  },
  [removeAuthorizationGroup.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<string>
  ) => {
    state.authorizationGroups = state.authorizationGroups.filter(
      (group) => group.groupId !== action.payload
    );
  },
  [updateAuthorizationGroup.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<IAuthorizationGroup>
  ) => {
    const index = state.authorizationGroups.findIndex(
      (group) => group.groupId === action.payload.groupId
    );

    state.authorizationGroups[index] = {
      ...state.authorizationGroups[index],
      ...action.payload
    };
  },

  [setCreateAuthGroupFlag.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<boolean>
  ) => {
    state.createStatus = action.payload;
  },

  [setAuthorizationGroupUsersUpdatedFlag.toString()]: (
    state: IAuthorizationGroupState,
    action: Action<boolean>
  ) => {
    state.usersUpdated = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
