import { isEmpty } from "lodash";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IComponentProperty } from "models/componentProperty";
import { BooleanProperty } from "./booleanProperty";
import { IntegerProperty } from "./integerProperty";
import { StringProperty } from "./stringProperty";
import { MultilineTextProperty } from "./multilineTextProperty";
import { PickListProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/pickListProperty";
import { ContentPickerProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/contentPickerProperty";
import { TagsPickerProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/tagsPickerProperty/tagsPickerProperty";
import { AutocompleteStringProperty } from "components/siteStructure/pageView/pageSidebar/moduleProperties/autocompleteStringProperty";

import { translate } from "utils/i18n";
import { VARIABLE_PREFIX } from "utils/variableUtils";

import { WithMenuEntries } from "components/siteStructure/pageView/pageSidebar/moduleProperties/useMenuSearchEntries";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  propertyDefinition: IComponentProperty;
  value: string;
  disabled?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0)
  },
  mandatory: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.custom.ds.warning.warning500
    }
  }
}));

export const ModuleProperty = (props: IProps) => {
  const {
    className,
    propertyDefinition,
    value,
    disabled = false,
    errorMessage = "",
    onChange
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const mandatory = {
    className: classes.mandatory,
    message: translate("sitestructure.moduleproperties.required.message"),
    showMessage: propertyDefinition.mandatory && !value
  };

  const getVariableValueIfExists = () => {
    if (
      propertyDefinition.canUseVariables &&
      value?.startsWith(VARIABLE_PREFIX)
    )
      return value;
    return undefined;
  };

  const renderProperty = () => {
    switch (propertyDefinition.typeName) {
      case "Boolean":
        return (
          <BooleanProperty
            className={rootClassName}
            propertyDefinition={propertyDefinition}
            value={
              getVariableValueIfExists() ??
              (value?.toString() ?? "false").toLowerCase() === "true"
            }
            onChange={onChange}
            disabled={disabled}
          />
        );
      case "Int32":
        const intValue = parseInt(value);
        return (
          <IntegerProperty
            className={rootClassName}
            propertyDefinition={propertyDefinition}
            value={isNaN(intValue) ? getVariableValueIfExists() : intValue}
            onChange={onChange}
            disabled={disabled}
            mandatory={mandatory}
          />
        );
      case "CmsMenuUrl":
        return (
          <WithMenuEntries>
            <AutocompleteStringProperty
              className={rootClassName}
              value={value || ""}
              onChange={onChange}
              disabled={disabled}
              mandatory={mandatory}
              errorMessage={errorMessage}
              options={[]}
              propertyDefinition={{
                ...propertyDefinition,
                canUseVariables: true
              }}
            />
          </WithMenuEntries>
        );
      case "CmsHtml":
      case "CmsMarkdown":
      case "CmsText":
        return (
          <MultilineTextProperty
            className={rootClassName}
            propertyDefinition={propertyDefinition}
            value={value || ""}
            onChange={onChange}
            disabled={disabled}
            mandatory={mandatory}
          />
        );
    }

    const [shouldRenderPickList, shouldRenderContentPicker] = [
      propertyDefinition.pickList,
      propertyDefinition.contentPicker
    ].map((field) => !isEmpty(field));

    if (shouldRenderPickList) {
      return (
        <PickListProperty
          className={rootClassName}
          propertyDefinition={propertyDefinition}
          value={value ?? null}
          onChange={onChange}
          disabled={disabled}
          mandatory={mandatory}
        />
      );
    }

    if (shouldRenderContentPicker) {
      if (propertyDefinition.contentPicker?.entityType === "tag") {
        return (
          <TagsPickerProperty
            className={rootClassName}
            propertyDefinition={propertyDefinition}
            value={value ?? ""}
            onChange={onChange}
            disabled={disabled}
            mandatory={mandatory}
          />
        );
      }

      return (
        <ContentPickerProperty
          className={rootClassName}
          propertyDefinition={propertyDefinition}
          value={value ?? ""}
          onChange={onChange}
          disabled={disabled}
          mandatory={mandatory}
        />
      );
    }

    return (
      <StringProperty
        className={rootClassName}
        propertyDefinition={propertyDefinition}
        value={value || ""}
        onChange={onChange}
        // the OR condition will be removed when all types will be editable
        disabled={disabled || propertyDefinition.typeName !== "String"}
        mandatory={mandatory}
        errorMessage={errorMessage}
      />
    );
  };

  return renderProperty();
};
