import { InputAdornment, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import { useLinkPicker } from "components/linkRules/linkPicker/hooks";
import { LinkPickerModal } from "components/linkRules/linkPicker/linkPickerModal";
import { useStyles } from "components/linkRules/linkPicker/styles";
import { StageIcon } from "components/shared/stageIcon";
import { UxdIcon } from "components/shared/uxdIcon";
import { ForgeEntityStage } from "models/forgeEntities";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSiteItems } from "redux/actions/siteStructureActions";
import { selectSiteItems } from "redux/selectors/siteStructureSelectors";
import { userService } from "services/userService";

interface IProps extends IBaseProps {
  pageId?: string;
  path?: string;
  disabled: boolean;
}

export default function LinkPicker(props: Readonly<IProps>) {
  const { pageId = "", path = "", disabled } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageId) {
      dispatch(fetchSiteItems({ id: pageId }));
    }
  }, [pageId, dispatch]);

  const siteItems = useSelector(selectSiteItems);
  const siteItem = siteItems[pageId];

  const { isModalOpen, handleModalOpen, handleModalClose } = useLinkPicker();
  const { onUpdateFormField } = useLinkRulesCrudManagementContext();

  const onChange = (path: string, id?: string) => {
    onUpdateFormField("url", path);
    onUpdateFormField("pageId", id);
  };

  const isUserAllowedToEditNavigationRules =
    userService.getIsUserAllowedToEditNavigationRules(path);

  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        className={classes.linkPicker}
        value={path}
        disabled={disabled}
        onClick={handleModalOpen}
        InputProps={{
          readOnly: true,
          startAdornment: siteItem && siteItem.status !== "Published" && (
            <InputAdornment position="start">
              <StageIcon
                stage={siteItem.status.toLowerCase() as ForgeEntityStage}
                size="small"
                className={classes.statusIcon}
              />
            </InputAdornment>
          ),
          endAdornment: isUserAllowedToEditNavigationRules && (
            <InputAdornment position="end">
              <UxdIcon name="search" className={classes.searchIcon} />
            </InputAdornment>
          )
        }}
      />
      <LinkPickerModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        value={path}
        onChange={onChange}
      />
    </>
  );
}
