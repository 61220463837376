import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { fetchPage } from "redux/actions/siteStructureActions";
import { SetModuleLabel } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { SetModuleLabelPayload } from "redux/actions/typings/siteStructureActions";

export function* sendSetModuleLabel(action: Action<SetModuleLabelPayload>) {
  try {
    const { pageId, moduleInstanceId, label } = action.payload;

    const command = new SetModuleLabel({
      pageId,
      moduleInstanceId,
      label
    });

    const commandAction = issueCommand({
      command,
      onFailureAction: fetchPage(pageId),
      errorMessage: "notification.setmodulelabel.error",
      successMessage: "notification.setmodulelabel.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
