import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import singleLineWhite from "images/pagebuilder_horizontal_singleline_white.svg";
import doubleLineBlue from "images/pagebuilder_horizontal_doubleline_blue.svg";
import icon from "images/pagebuilder_icon.svg";
import React from "react";

interface IProps extends IBaseProps {
  variant: "singleline-white" | "doubleline-blue" | "icon";
}

const logos = {
  "singleline-white": singleLineWhite,
  "doubleline-blue": doubleLineBlue,
  icon: icon
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    pointerEvents: "none",
    userSelect: "none"
  }
}));

export const Logo = React.memo((props: IProps) => {
  const { className, variant } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const logo = logos[variant];

  return <img className={rootClassName} src={logo} alt="PageBuilder" />;
});
