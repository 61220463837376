import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ContentPickerTagsInputChip } from "./contentPickerTagsInputChip";
import { translate } from "utils/i18n";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  tags: TagInputItem[];
  onRemove: (tag: TagInputItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    overflowY: "auto"
  },
  notags: {
    userSelect: "none",
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "italic",
    color: theme.palette.text.disabled
  }
}));

export const ContentPickerTagsInputValues = (props: IProps) => {
  const { className, tags, onRemove } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      {tags.length ? (
        tags.map((tag) => (
          <ContentPickerTagsInputChip
            key={tag.translationId}
            tag={tag}
            onRemoveClick={onRemove}
          />
        ))
      ) : (
        <Typography className={classes.notags}>
          {translate("contentpicker.notagsselected")}
        </Typography>
      )}
    </div>
  );
};
