import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { Fragment, useMemo } from "react";

interface IProps extends IBaseProps {
  search: string;
  value: string;
  highlight: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0.25, 0),
    fontSize: theme.typography.pxToRem(13)
  },
  highlight: {
    color: theme.palette.custom.mainAccent.blue
  }
}));

export const SuggestionListItem = (props: IProps) => {
  const { className, search, value, highlight } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    {
      [classes.highlight]: highlight
    },
    className
  );

  const highlightRegex = useMemo(
    () => new RegExp(`(${search})`, "i"),
    [search]
  );

  const segments = value.split(highlightRegex);
  const formatted = segments.map((s, index) => (
    <Fragment key={`${s}-${index}`}>
      {index % 2 === 0 ? s : <strong>{s}</strong>}
    </Fragment>
  ));

  return <Typography className={rootClassName}>{formatted}</Typography>;
};
