import { differenceBy, map, unionBy } from "lodash";
import { Action } from "redux-actions";
import {
  aliasWasSet,
  aliasWasUnset,
  fetchAliasesSucceded
} from "redux/actions/aliasesActions";
import { reducerFactory } from "./coreReducer";
import { IAliasesState } from "models/domainStates";
import { IAliasEntity } from "models/aliases";
import { getAliasIdentifier } from "components/siteStructure/aliases/utils";
import {
  AliasWasSetPayload,
  AliasWasUnsetPayload,
  FetchAliasesSuccededPayload
} from "redux/actions/typings/aliasesActions";

export const initialState: IAliasesState = {
  aliases: []
};

const reactions = {
  [fetchAliasesSucceded.toString()]: (
    state: IAliasesState,
    action: Action<FetchAliasesSuccededPayload>
  ) => {
    const {
      payload: { aliases }
    } = action;

    state.aliases = [...aliases];
  },

  [aliasWasSet.toString()]: (
    state: IAliasesState,
    action: Action<AliasWasSetPayload>
  ) => {
    const {
      payload: { notificationMetadata }
    } = action;

    const { aliases } = state;
    const { pageId, cultures, value, path, parameters } = notificationMetadata;

    const aliasEntitiesFromNotification: IAliasEntity[] = map(
      cultures,
      (culture) => ({
        culture,
        pageId,
        value,
        path,
        parameters
      })
    );

    const updatedAliases = unionBy(
      aliasEntitiesFromNotification,
      aliases,
      getAliasIdentifier
    );

    state.aliases = [...updatedAliases];

    return state;
  },

  [aliasWasUnset.toString()]: (
    state: IAliasesState,
    action: Action<AliasWasUnsetPayload>
  ) => {
    const {
      payload: { notificationMetadata }
    } = action;

    const { pageId, cultures } = notificationMetadata;
    const { aliases } = state;

    const aliasEntitiesFromNotification: Partial<IAliasEntity>[] = map(
      cultures,
      (culture) => ({
        culture,
        pageId
      })
    );

    const updatedAliases = differenceBy(
      aliases,
      aliasEntitiesFromNotification,
      getAliasIdentifier
    );

    state.aliases = [...updatedAliases];
  }
};

export default reducerFactory(initialState, reactions);
