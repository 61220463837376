import {
  CreateCSSProperties,
  CSSProperties
} from "@material-ui/core/styles/withStyles";
import { theme } from "./theme";

/**
 * This file allows you to define CSS classes that can be used within all components.
 *
 * The purpose is to avoid duplication of code as much as possible and to simulate to some extent
 * the "include" behavior of languages such as SCSS; defining the classes here and exporting them
 * individually also guarantees the benefit of tree-shaking.
 *
 * With respect to the stylization possibilities within components, it was decided here to avoid
 * the possibility of being able to define property values with a function that takes component
 * props precisely to make these classes completely unrelated to the components.
 *
 * Unfortunately, these classes can only be used in conjunction with MUI4's makeStyles and cannot
 * be used directly in the component's JSX.
 *
 * Example of use:
 * 1. Define the class inside the file `commonClasses.ts`
 * `
 *  export const commonClassName: CSS = {
 *      ... CSS Properties ...
 * }
 * `
 * Note: set the type of the variable to CSS allow the autocompletion of the CSS properties
 *
 * 2. Inside a component, import the class
 * `import { commonClassName } from "theme/commonClasses"`
 *
 * 3. Include the common class inside the MUI makeStyles
 * `
 *  const useStyles = makeStyles((theme) => ({
 *      myClass: {
 *          ...commonClassName,
 *          prop: value, // Allow you to override the prop
 *          prop2: value,
 *      },
 *      // Otherwise
 *      mySecondClass: commonClassName // inherits all the properties
 *  }))
 * `
 *
 * And that's it folks!
 */

type CSS = CSSProperties | CreateCSSProperties<{}>;

export const tabMultiSelectList: CSS = {
  height: `calc(70vh - ${theme.spacing(20)}px)`,
  overflowY: "auto"
};

export const tabHeader: CSS = {
  minHeight: 0,
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.custom.ds.viking.viking500
  },
  "& $tabButton:hover + .MuiTabs-indicator": {
    backgroundColor: theme.palette.custom.ds.viking.viking300
  },
  paddingLeft: theme.spacing(0.975)
};

export const tabButton: CSS = {
  height: theme.spacing(4),
  minHeight: 0,
  minWidth: "auto",
  padding: theme.spacing(0, 0, 0.5, 0),
  textTransform: "none",
  color: theme.palette.custom.ds.grayscales.gr600,
  fontWeight: 400,
  lineHeight: "20.4px",
  margin: theme.spacing(0, 2),
  borderBottom: `2px solid transparent`,
  "&.Mui-selected": {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontWeight: 400
  },
  "&:hover": {
    borderBottomColor: theme.palette.custom.ds.viking.viking300,
    color: theme.palette.custom.ds.grayscales.gr800
  }
};

export const authorizationHeader: Record<string, CSS> = {
  root: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(11),
    display: "flex",
    padding: theme.spacing(2, 3),
    boxSizing: "border-box",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  name: {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(30),
    "&::placeholder": {
      color: theme.palette.custom.ds.grayscales.gr600
    },
    padding: theme.spacing(0, 0, 1),
    "& input": {
      padding: "0"
    }
  },
  desc: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19),
    "&::placeholder": {
      color: theme.palette.custom.ds.grayscales.gr600
    }
  },
  mandatory: {
    "& input[placeholder]": {
      color: theme.palette.custom.ds.error.error500,
      "&::placeholder": {
        opacity: 1
      }
    }
  },
  loading: {
    color: theme.palette.custom.ds.grayscales.gr600
  }
};

export const contextualMenu: Record<string, CSS> = {
  "& .MuiMenu-paper": {
    boxShadow: theme.palette.custom.ds.shadow.s300
  },
  "& .MuiMenuItem-root": {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.custom.ds.grayscales.gr800,

    "& .MuiListItemIcon-root .material-icons": {
      fontSize: theme.typography.pxToRem(20)
    }
  }
};

export const searchInputChooseElement: Record<string, CSS> = {
  root: {
    display: "flex",
    flexDirection: "column"
  },
  searchInput: {
    margin: theme.spacing(0, 1),
    width: "97%",
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0, 1),
      height: theme.spacing(4),
      "&:placeholder": {
        color: theme.palette.custom.ds.grayscales.gr600
      }
    }
  },
  searchInputIcon: {
    color: theme.palette.custom.ds.grayscales.gr500
  },
  tile: {
    cursor: "pointer",
    padding: theme.spacing(1, 1) + " !important",
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr200
    }
  },
  grid: {
    margin: "0 !important",
    padding: theme.spacing(0, 1, 2)
  },
  noResults: {
    height: "100%"
  },
  scrollable: {
    flex: 1,
    overflowY: "auto",
    margin: theme.spacing(2, 0)
  }
};
