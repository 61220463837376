import T from "i18n-react";
import { ApiClient } from "api/apiClient";
import { FallbackIfUnknown } from "utils/typescriptUtils";

const DEFAULT_LANGUAGE = "en-US";

export async function fetchLocalization(): Promise<any> {
  const localization = await ApiClient.get(`/i18n/${DEFAULT_LANGUAGE}.json`);
  return localization;
}

export function translate<T>(
  key: string,
  values?: { [prop: string]: T }
): FallbackIfUnknown<T, string> {
  const translated = T.translate(
    key.toLowerCase(),
    values
  ) as FallbackIfUnknown<T, string>;
  return translated;
}
