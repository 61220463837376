import { useEffect, useMemo, useState } from "react";
import { useMemoSelector } from "components/hooks";
import { ISiteItem } from "models/siteItem";
import {
  TranslationPathItem,
  PathTranslationSegment
} from "models/urlTranslation";
import {
  makeSelectHierarchySiteItems,
  makeSelectSiblingsSiteItems
} from "redux/selectors/siteStructureSelectors";
import { translate } from "utils/i18n";
import { hasDuplicateSiblingUrlTranslations } from "utils/urlTranslationUtils";

export function useTranslationUrlHierarchy(
  item: ISiteItem
): PathTranslationSegment[] {
  const allItems = useMemoSelector(makeSelectHierarchySiteItems, item.nodeId);
  const pathTranslationSegments = useMemo(
    () =>
      allItems.map((siteItem) => ({
        ...(siteItem.uriSegmentTranslations || {}),
        "*": siteItem.label
      })),
    [allItems]
  );
  return pathTranslationSegments;
}

export function useTranslationError(
  nodeId: string,
  leaf: TranslationPathItem | undefined,
  culture = "*"
): [string | boolean, React.Dispatch<React.SetStateAction<string | boolean>>] {
  const [error, setError] = useState<string | boolean>(false);
  const siblings = useMemoSelector(makeSelectSiblingsSiteItems, nodeId);

  useEffect(() => {
    const hasError = leaf
      ? hasDuplicateSiblingUrlTranslations(leaf, culture, siblings)
      : false;

    const err = hasError
      ? translate("urlspage.translationalreadyusederror")
      : false;

    setError(err);
  }, [siblings, culture, leaf]);

  return [error, setError];
}
