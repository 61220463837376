import { createAction } from "redux-actions";
import {
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX,
  FAILED_ACTION_SUFFIX
} from "redux/actions/constants";
import { IContextModules } from "models/siteAssets/moduleDefinition";
import { IContextLayouts } from "models/siteAssets/layoutDefinition";
import { IContextTemplates } from "models/siteAssets/templateDefinition";
import { IContextDefinition } from "models/siteAssets/contextDefinition";

// MODULES
export const fetchModules = createAction(
  `FETCH_MODULES${REQUEST_ACTION_SUFFIX}`
);

export const fetchModulesSucceded = createAction(
  `FETCH_MODULES${SUCCESS_ACTION_SUFFIX}`,
  (modules: IContextModules) => modules
);

export const fetchModulesFailed = createAction(
  `FETCH_MODULES${FAILED_ACTION_SUFFIX}`
);

// LAYOUTS
export const fetchLayouts = createAction(
  `FETCH_LAYOUTS${REQUEST_ACTION_SUFFIX}`
);

export const fetchLayoutsSucceded = createAction(
  `FETCH_LAYOUTS${SUCCESS_ACTION_SUFFIX}`,
  (layouts: IContextLayouts) => layouts
);

export const fetchLayoutsFailed = createAction(
  `FETCH_LAYOUTS${FAILED_ACTION_SUFFIX}`
);

// TEMPLATES
export const fetchTemplates = createAction(
  `FETCH_TEMPLATES${REQUEST_ACTION_SUFFIX}`
);

export const fetchTemplatesSucceded = createAction(
  `FETCH_TEMPLATES${SUCCESS_ACTION_SUFFIX}`,
  (templates: IContextTemplates) => templates
);

export const fetchTemplatesFailed = createAction(
  `FETCH_TEMPLATES${FAILED_ACTION_SUFFIX}`
);

// CONTEXTS
export const fetchContexts = createAction(
  `FETCH_CONTEXTS${REQUEST_ACTION_SUFFIX}`
);

export const fetchContextsSucceded = createAction(
  `FETCH_CONTEXTS${SUCCESS_ACTION_SUFFIX}`,
  (contexts: IContextDefinition[]) => contexts
);

export const fetchContextsFailed = createAction(
  `FETCH_CONTEXTS${FAILED_ACTION_SUFFIX}`
);
