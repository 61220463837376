import { Chip, Typography } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { D3Dropdown, D3Modal } from "components/shared/d3Components";
import { ID3DropdownOption } from "components/shared/d3Components/d3Dropdown";
import { Textbox } from "components/shared/textbox";
import { toVariableContract } from "components/siteStructure/variables/hooks";
import { IBaseProps } from "components/_baseProps";
import {
  IKeyValueVariableBody,
  IVariableModalAction,
  ValidationResult
} from "models/variables";
import { translate } from "utils/i18n";
import {
  getAreAllValuesFilled,
  getTranslatedModalTitle,
  getVariableModalOperation,
  KeyValueVariableType as VariableValueType,
  resolveInheritedVariableField,
  toVariableKey,
  VARIABLE_REGEX,
  VariableType,
  VariableTypeEnum
} from "utils/variableUtils";

import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectModalAction } from "redux/selectors/variablesSelectors";
import { VariableContract } from "@d3-forge/forge-commands";
import classNames from "classnames";

const types: ID3DropdownOption[] = [
  {
    label: "String",
    value: VariableValueType.STRING
  },
  {
    label: "Date",
    value: VariableValueType.DATETIME
  },
  {
    label: "Number",
    value: VariableValueType.DOUBLE
  },
  {
    label: "Guid",
    value: VariableValueType.GUID
  },
  {
    label: "Bool",
    value: VariableValueType.BOOLEAN
  }
];

interface IKeyValueVariableForm {
  name: string;
  value: string;
  type: string;
  override: boolean;
  culture: string;
  environment: string;
}

const defaultForm: IKeyValueVariableForm = {
  name: "",
  value: "",
  type: VariableValueType.STRING,
  culture: "*",
  environment: "*",
  override: false
};

interface IProps extends IBaseProps {
  sendVariableCommand: (contract: VariableContract) => void;
  onCloseModal: () => void;
  getModalValidations: (
    name: string,
    culture: string,
    environment: string,
    action: string
  ) => ValidationResult;
}

const initForm = (modalAction: IVariableModalAction): IKeyValueVariableForm => {
  const { variable, variableDetail, isTranslateMode } = modalAction;

  const { isCreate } = getVariableModalOperation(modalAction);

  const form: IKeyValueVariableForm = {
    ...defaultForm,
    name: variable?.key ?? defaultForm.name,
    culture: variableDetail?.culture ?? defaultForm.culture,
    environment: variableDetail?.environment ?? defaultForm.environment,
    type: resolveInheritedVariableField(
      variableDetail,
      "typeFullName",
      defaultForm.type
    ),
    value: resolveInheritedVariableField(
      variableDetail,
      "value",
      defaultForm.value
    ),
    override: resolveInheritedVariableField(
      variableDetail,
      "allowRequestOverride",
      defaultForm.override
    )
  };

  // We invalidate the culture/env in translation mode
  if (isTranslateMode && isCreate) {
    form.culture = "";
    form.environment = "";
  }

  return form;
};

export const KeyValueVariableModal = (props: IProps) => {
  const { getModalValidations, onCloseModal, className, sendVariableCommand } =
    props;

  const classes = useStyles({ variableType: VariableTypeEnum.KeyValue });
  const modalAction = useSelector(selectModalAction);
  const [form, setForm] = useState<IKeyValueVariableForm>(
    initForm(modalAction)
  );
  const [isUnique, setIsUnique] = useState<boolean>(true);
  const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] =
    useState<boolean>(false);

  const [
    isCultureEnvironmentCombinationAvailable,
    setIsCultureEnvironmentCombinationAvailable
  ] = useState<boolean>(true);
  const currentType = VariableType.KEY_VALUE;

  const { action, isTranslateMode } = modalAction;

  const { isCreate, isEdit } = getVariableModalOperation(modalAction);

  function updateFormField(key: keyof IKeyValueVariableForm) {
    function updateForm(value: any) {
      setForm((current) => ({ ...current, [key]: value }));
    }

    return updateForm;
  }

  const validate = useCallback(() => {
    const requiredFields = [
      form.name,
      form.value,
      form.type,
      form.culture,
      form.environment
    ];

    const required = getAreAllValuesFilled(requiredFields);

    const { isUnique, isCultureEnvironmentCombinationAvailable } =
      getModalValidations(form.name, form.culture, form.environment, action);

    setIsUnique(isUnique);
    setAreRequiredFieldsFilled(required);
    setIsCultureEnvironmentCombinationAvailable(
      isCultureEnvironmentCombinationAvailable
    );
  }, [
    action,
    form.culture,
    form.environment,
    form.name,
    form.type,
    form.value,
    getModalValidations
  ]);

  useEffect(() => validate(), [validate]);

  const confirmLabel = isCreate
    ? translate("sitestructure.variables.labels.confirm")
    : translate("general.save");

  const titleText = getTranslatedModalTitle(modalAction, "KeyValue");
  const title = (
    <div className={classes.title}>
      <Chip label={currentType.code} size="small" className={classes.chip} />
      <Typography className={classes.titleText}>{titleText}</Typography>
    </div>
  );

  const onConfirm = useCallback(() => {
    const key = toVariableKey(form.name, form.culture, form.environment);
    const body: IKeyValueVariableBody = {
      Value: form.value,
      TypeFullName: form.type,
      AllowRequestOverride: form.override
    };

    const contract = toVariableContract(key, currentType, body);

    sendVariableCommand(contract);
    onCloseModal();
  }, [
    form.name,
    form.culture,
    form.environment,
    form.value,
    form.type,
    form.override,
    currentType,
    sendVariableCommand,
    onCloseModal
  ]);

  const onDropdownSelect = useCallback(
    (selected: ID3DropdownOption) =>
      setForm({
        ...form,
        type: selected.value
      }),
    [form]
  );

  return (
    <D3Modal
      className={className}
      open={true}
      modalTitle={title}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      confirmLabel={confirmLabel}
      disabled={
        !isUnique ||
        !areRequiredFieldsFilled ||
        !isCultureEnvironmentCombinationAvailable
      }
    >
      <div className={classes.input}>
        <Textbox
          label={translate("sitestructure.variables.labels.name")}
          className={classes.name}
          value={form.name}
          onChange={updateFormField("name")}
          onBlur={validate}
          disabled={isTranslateMode || isEdit}
          clearIcon={!isEdit}
          pattern={VARIABLE_REGEX.source}
          invalidPatternMessage={translate(
            "sitestructure.variables.add.errors.invalidname"
          )}
        />
        {isCreate && !isUnique && (
          <Typography className={classes.error}>
            {translate("sitestructure.variables.add.errors.uniquename")}
          </Typography>
        )}
      </div>

      {isTranslateMode && (
        <div>
          <div className={classes.stack}>
            <Textbox
              className={classes.input}
              label={translate("sitestructure.variables.labels.culture")}
              value={form.culture}
              onChange={updateFormField("culture")}
              onBlur={validate}
              isInvalid={!isCultureEnvironmentCombinationAvailable}
              disabled={isEdit}
              clearIcon={!isEdit}
            />

            <Textbox
              className={classes.input}
              label={translate("sitestructure.variables.labels.environment")}
              value={form.environment}
              onChange={updateFormField("environment")}
              onBlur={validate}
              isInvalid={!isCultureEnvironmentCombinationAvailable}
              disabled={isEdit}
              clearIcon={!isEdit}
            />
          </div>
          {!isCultureEnvironmentCombinationAvailable && (
            <Typography
              className={classNames(classes.error, classes.errorPositioner)}
            >
              {translate(
                "sitestructure.variables.add.errors.invalid_culture_environment_combo"
              )}
            </Typography>
          )}
        </div>
      )}

      <Textbox
        label={translate("sitestructure.variables.labels.value")}
        className={classes.input}
        value={form.value}
        onChange={updateFormField("value")}
        onBlur={validate}
      />

      <D3Dropdown
        label={translate("sitestructure.variables.labels.type")}
        value={form.type}
        options={types}
        onSelect={onDropdownSelect}
        className={classes.input}
        fullWidth
      />
    </D3Modal>
  );
};

export default KeyValueVariableModal;
