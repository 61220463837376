import {
  useCallback,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  useEffect,
  KeyboardEventHandler,
  KeyboardEvent
} from "react";
import { IconButton, Typography } from "@material-ui/core";
import classNames from "classnames";
import { translate } from "utils/i18n";
import { StageIcon } from "components/shared/stageIcon";
import { ForgeEntityStage } from "models/forgeEntities";
import { ILinkRuleEntry } from "models/linkRules";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  getHighlightedHtmlFromSearchMatch,
  navigateToNewlyAddedLinkRuleItem
} from "components/linkRules/utils";

import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import {
  selectIsLinkRulesSidebarOpen,
  selectSelectedLinkRuleId
} from "redux/selectors/linkRulesSelectors";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import { useSearchManagement } from "components/linkRules/context/searchManagement";
import { useLinkRulesScrollingManagementContext } from "components/linkRules/context/scrollManagement";
import LinkRuleUrl from "components/linkRules/linkRuleEntryItem/LinkRuleUrl";
import UnpublishedWarning from "components/linkRules/linkRuleEntryItem/UnpublishedWarning";
import LinkRuleProperty from "components/linkRules/linkRuleEntryItem/LinkRuleProperty";
import { D3Modal } from "components/shared/d3Components";
import { userService } from "services/userService";

interface IProps {
  linkRuleEntry: ILinkRuleEntry;
}

export default function LinkRuleEntryItem(props: Readonly<IProps>) {
  const ModalTranslationKeyPrefix = "navigationrules.delete.modal";

  const classes = useStyles();
  const { linkRuleEntry } = props;
  const { url, isAbsolute, isPublished, properties, id } = linkRuleEntry;
  const { searchValue, linkRuleUrlCountMap } = useSearchManagement();
  const containerRef = useRef<HTMLDivElement>(null);

  const { scrollingSectionRef } = useLinkRulesScrollingManagementContext();
  const isLinkRuleUnpublished = !isAbsolute && !isPublished;
  const { newLinkRulePayload, onSelectNavigationRuleItem, onDelete } =
    useLinkRulesCrudManagementContext();

  const selectedLinkRuleId = useSelector(selectSelectedLinkRuleId);
  const isLinkRulesSidebarOpen = useSelector(selectIsLinkRulesSidebarOpen);

  const linkClassnames = classNames(classes.link, {
    [classes.shrinkedLink]: isLinkRulesSidebarOpen
  });

  const isNewlyAddedLinkRule = useMemo(
    () => newLinkRulePayload?.entryId === linkRuleEntry.id,
    [newLinkRulePayload?.entryId, linkRuleEntry.id]
  );

  const itemContainerClassnames = classNames(classes.itemContainer, {
    [classes.itemContainerUnpublished]: isLinkRuleUnpublished,
    [classes.itemSelected]:
      linkRuleEntry.id === selectedLinkRuleId ||
      (isNewlyAddedLinkRule && newLinkRulePayload?.shouldApplyHighlight)
  });

  const linkRuleStage: ForgeEntityStage | undefined = useMemo(() => {
    if (isAbsolute) {
      return undefined;
    }

    return isPublished ? "published" : "unpublished";
  }, [isPublished, isAbsolute]);

  const shouldShowStageIcon = isLinkRuleUnpublished;
  const areTherePropertiesDefined = properties.length > 0;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);

  const onDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowModal(true);
  };

  const onDeleteConfirm = () => {
    onDelete(linkRuleEntry.id, isAbsolute);
    setShowModal(false);
  };

  const onDeleteCancel = () => setShowModal(false);

  // link rule item hover handlers
  const onMouseEnter = () => setShowButton(true);
  const onMouseLeave = () => setShowButton(false);

  const onHandleUrlClick = useCallback(
    (e: any) => {
      e.stopPropagation();

      if (isAbsolute) {
        window.open(url, "_blank");
        return;
      }

      const siteStructureLink = `${window.location.origin}/#/sitestructure/${url}?tab=content`;
      window.open(siteStructureLink, "_blank");
    },
    [url, isAbsolute]
  );

  const onLinkRuleClick = () => {
    onSelectNavigationRuleItem(linkRuleEntry.id);
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (
    event: KeyboardEvent<HTMLDivElement>
  ) => event.key === "Enter" && onLinkRuleClick();

  const highlightedUrlHtml = useMemo(
    () => getHighlightedHtmlFromSearchMatch(url, searchValue),
    [url, searchValue]
  );

  // this method combines the permission checks and the allowed items inferred from the authorization groups
  const userCanDelete = userService.getIsUserAllowedToEditNavigationRules(
    url,
    isAbsolute
  );

  const shouldShowNewBadge = isNewlyAddedLinkRule;

  useEffect(() => {
    const shouldNavigateToNewlyAddedLinkRuleItem =
      isNewlyAddedLinkRule &&
      newLinkRulePayload?.shouldApplyHighlight &&
      scrollingSectionRef.current !== null &&
      containerRef.current !== null;

    if (!shouldNavigateToNewlyAddedLinkRuleItem) {
      return;
    }

    navigateToNewlyAddedLinkRuleItem(
      scrollingSectionRef.current,
      containerRef.current
    );
  }, [
    scrollingSectionRef,
    newLinkRulePayload?.shouldApplyHighlight,
    isNewlyAddedLinkRule
  ]);

  const usedNumber = linkRuleUrlCountMap[id] || 0;

  return (
    <div
      ref={containerRef}
      className={itemContainerClassnames}
      onClick={onLinkRuleClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classNames(classes.section, classes.sectionLeft)}>
        <div className={classes.innerLeftSectionTop}>
          {shouldShowNewBadge && (
            <Typography component="label" className={classes.badge}>
              {translate("general.new").toUpperCase()}
            </Typography>
          )}
          {shouldShowStageIcon && (
            <StageIcon stage={linkRuleStage!!} size="small" />
          )}
          <UxdIcon className={classes.icon} name="description" />
          <LinkRuleUrl
            url={url}
            highlighted={highlightedUrlHtml}
            className={linkClassnames}
            onClick={onHandleUrlClick}
            rulesUsedCount={usedNumber}
          />
        </div>
        <UnpublishedWarning
          isLinkRuleUnpublished={isLinkRuleUnpublished}
          isLinkRulesSidebarOpen={isLinkRulesSidebarOpen}
        />
        <div className={classes.innerLeftSectionBottom}>
          <Typography className={classes.description}>
            {linkRuleEntry.description}
          </Typography>
        </div>
      </div>
      <div className={classes.sectionRight}>
        <div className={classNames(classes.section, classes.propertyList)}>
          {properties.map((property) => (
            <LinkRuleProperty
              key={`${property.name}_${property.value}`}
              property={property}
              searchValue={searchValue}
            />
          ))}
          {!areTherePropertiesDefined && (
            <Typography className={classes.operandLeft}>
              {translate("contentpicker.any").toLowerCase()}
            </Typography>
          )}
        </div>
        {userCanDelete && showButton && (
          <IconButton
            onClick={onDeleteClick}
            className={classes.deleteButton}
            hidden={true}
            disableRipple
          >
            <UxdIcon name="delete_outline" />
          </IconButton>
        )}
      </div>

      <D3Modal
        open={showModal}
        modalTitle={translate(`${ModalTranslationKeyPrefix}.title`)}
        confirmLabel={translate(`${ModalTranslationKeyPrefix}.confirm`)}
        cancelLabel={translate(`${ModalTranslationKeyPrefix}.cancel`)}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
      >
        <Typography>
          {translate(`${ModalTranslationKeyPrefix}.message`)}
        </Typography>
      </D3Modal>
    </div>
  );
}
