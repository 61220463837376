import { makeStyles, Theme, Typography, List } from "@material-ui/core";
import { usePutAuthGroupDetail } from "api/queryHooks";
import classnames from "classnames";
import {
  D3Button,
  D3Modal,
  D3MultiSelectListModal,
  D3SearchBox
} from "components/shared/d3Components";
import { useMultiSelect } from "components/shared/multiSelect/hooks";
import MultiSelectListItem from "components/shared/multiSelect/multiselectListItem";
import { UxdIcon } from "components/shared/uxdIcon";
import {
  IAuthorizationGroup,
  IAuthorizationGroupDetailUpdateRequest
} from "models/authorizationGroup";
import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { updateAuthorizationGroup } from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { selectLayoutCatalog } from "redux/selectors/assetCatalogSelectors";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { IDomainState } from "models/domainStates";
import { useAuthorizationGroupUsersSelectContext } from "components/permissions/detailView/context/useAuthorizationGroupUsersSelect";
import { useStyles as useModuleTabStyles } from "./moduleTab";
export const useStyles = makeStyles((theme: Theme) => ({
  list: {
    overflow: "auto",
    // NOTE: 104px of space for the remove snackbar, 220px to offset the tab header
    height: `calc(100vh - ${theme.spacing(27.5)}px - ${theme.spacing(13)}px)`
  },
  item: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(0.25),
    transition: "all .3s ease-in-out",
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20)
    },
    border: "solid 1px transparent"
  },
  separator: {
    borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`
  },
  itemSelected: {
    transition: "all .3s ease-in-out",
    border: `solid 1px ${theme.palette.custom.ds.viking.viking500}`
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  magnifier: { color: theme.palette.custom.ds.grayscales.gr500 },
  icon: {
    "& path": {
      fill: theme.palette.custom.ds.fiord.fiord900
    }
  },
  removeModal: {
    "& .MuiDialog-paper": {
      width: theme.spacing(52.5) // 420px
    }
  },
  hideSnackbar: {
    display: "none"
  }
}));

interface IProps extends IBaseProps {
  authorizationGroup: IAuthorizationGroup;
}

export function LayoutTab(props: IProps) {
  const { authorizationGroup } = props;
  const classes = useStyles();
  const moduleTabStyles = useModuleTabStyles();

  const dispatch = useDispatch();
  const { checkedList, handleClick, clearChecked, MultiListSnackbar } =
    useMultiSelect();

  const { clearChecked: resetAuthorizationGroupUsersSelected } =
    useAuthorizationGroupUsersSelectContext();

  const mutation = usePutAuthGroupDetail();

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const allLayouts: ILayoutDefinition[] = Object.values(
    useSelector((state: IDomainState) => selectLayoutCatalog(state, ""))
  );
  const existingLayouts = allLayouts.filter(
    (layout) => authorizationGroup.layouts?.includes(layout.key) ?? false
  );

  const getSelectableLayouts = (): ILayoutDefinition[] => {
    const existingLayoutKeys = existingLayouts.map((layout) =>
      layout.key.toLowerCase()
    );

    return allLayouts.filter(
      (layout: ILayoutDefinition) =>
        !existingLayoutKeys.includes(layout.key.toLowerCase())
    );
  };

  const isSelected = (key: string) => checkedList.includes(key);

  const onClick = () => {
    resetAuthorizationGroupUsersSelected();
    setAddModalOpen(true);
  };
  const onCancel = () => {
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };

  const onAdd = (layoutsToAdd: string[]) => {
    const update: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: [authorizationGroup.groupId],
      actionType: "update",
      elementKind: "layouts",
      values: layoutsToAdd
    };

    mutation.mutate(update, {
      onSuccess: (data: IAuthorizationGroup) => {
        const addedLayouts = data.layouts ?? [];
        const currentLayouts = authorizationGroup.layouts ?? [];

        const updatedAuthGroup = {
          ...authorizationGroup,
          layouts: [...currentLayouts, ...addedLayouts]
        };

        batch(() => {
          dispatch(updateAuthorizationGroup(updatedAuthGroup));
          dispatch(
            displayNotification({
              message: "sitestructure.permissions.addlayout_success",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message: "sitestructure.permissions.addlayout_failure",
            severity: "error"
          })
        );
      }
    });

    clearChecked();
    setAddModalOpen(false);
  };

  const onRemove = () => {
    const update: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: [authorizationGroup.groupId],
      actionType: "delete",
      elementKind: "layouts",
      values: checkedList
    };

    mutation.mutate(update, {
      onSuccess: (data: IAuthorizationGroup) => {
        const removesLayouts = data.layouts ?? [];
        const currentLayouts = authorizationGroup.layouts ?? [];

        const updatedAuthGroup = {
          ...authorizationGroup,
          layouts: currentLayouts.filter((x) => !removesLayouts.includes(x))
        };

        batch(() => {
          dispatch(updateAuthorizationGroup(updatedAuthGroup));
          dispatch(
            displayNotification({
              message: "sitestructure.permissions.removelayout_success",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message: "sitestructure.permissions.removelayout_failure",
            severity: "error"
          })
        );
      }
    });

    clearChecked();
    setRemoveModalOpen(false);
  };
  const onSearchTextChange = (value: string) => setQuery(value.toLowerCase());
  const filteredLayouts =
    query.trim().length === 0
      ? existingLayouts
      : existingLayouts.filter((x) => x.label.toLowerCase().includes(query));

  const icon = <UxdIcon name="web" />;

  useEffect(() => {
    resetAuthorizationGroupUsersSelected();
  }, [checkedList, resetAuthorizationGroupUsersSelected]);

  return (
    <>
      <div className={classes.header}>
        <D3SearchBox
          onTextChange={onSearchTextChange}
          collapse
          iconClassname={moduleTabStyles.searchIcon}
          placeholder={translate("sitestructure.search.inputplaceholder")}
        />
        <D3Button
          variant="ghost"
          color="secondary"
          icon="add_circle"
          onClick={onClick}
          onFocusCapture={(e) => e.target.blur()} // avoid focus returning after modal close
        >
          {translate("sitestructure.permissions.addlayouts")}
        </D3Button>
      </div>
      <List className={classes.list}>
        {filteredLayouts.map((layout, index) => {
          const selected = isSelected(layout.key);
          return (
            <MultiSelectListItem
              className={classnames(
                classes.item,
                {
                  [classes.separator]: index !== 0
                },
                {
                  [classes.itemSelected]: selected
                }
              )}
              key={index}
              id={layout.key}
              content={layout.label}
              handleClick={handleClick}
              selected={selected}
              leftIcon={icon}
            />
          );
        })}
      </List>

      <MultiListSnackbar
        singleSelectionText={translate(
          "sitestructure.permissions.selected_singular"
        )}
        multiSelectionText={translate(
          "sitestructure.permissions.selected_plural"
        )}
        handleRemove={() => setRemoveModalOpen(true)}
        className={classnames({ [classes.hideSnackbar]: removeModalOpen })}
      />

      {removeModalOpen && (
        <D3Modal
          className={classes.removeModal}
          open={removeModalOpen}
          modalTitle={translate("sitestructure.permissions.removelayout_title")}
          onCancel={onCancel}
          onConfirm={onRemove}
          cancelLabel={translate(
            "sitestructure.permissions.remove_cancel_button"
          )}
          confirmLabel={translate(
            "sitestructure.permissions.remove_confirm_button"
          )}
        >
          <Typography>
            {translate("sitestructure.permissions.removelayout_confirm")}
          </Typography>
        </D3Modal>
      )}

      {addModalOpen && (
        <D3MultiSelectListModal
          itemIcon={icon}
          title={translate("sitestructure.permissions.selectlayouts")}
          placeholder={translate(
            "sitestructure.permissions.addlayout_search_placeholder"
          )}
          items={getSelectableLayouts()}
          onConfirm={onAdd}
          onCancel={onCancel}
          confirmLabel={{
            verb: translate("general.add"),
            plural: translate("sitestructure.layouts"),
            singular: translate("sitestructure.layout")
          }}
        />
      )}
    </>
  );
}
