import { IBaseProps } from "components/_baseProps";
import { useAbortController, useDebouncedState } from "components/hooks";
import { ContentPickerTagsInput } from "./contentPickerTagsInput";
import { translate } from "utils/i18n";
import { useTagsTypeaheadQuery } from "../hooks";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  values: TagInputItem[];
  onChange?: (newValues: TagInputItem[], itemDetail: TagInputItem) => void;
}

export const ContentPickerTagsInputContainer = (props: IProps) => {
  const { values, className, onChange = () => {} } = props;

  const [terms, debouncedTerms, setTerms] = useDebouncedState("", 1000);
  const abortController = useAbortController([terms]);
  const {
    data: suggestions = [],
    isLoading: queryLoading,
    error: queryError
  } = useTagsTypeaheadQuery(debouncedTerms, abortController);

  const handleInputChange = (newValue: string) => setTerms(newValue);

  return (
    <ContentPickerTagsInput
      className={className}
      inputValue={terms}
      suggestions={suggestions}
      onChange={onChange}
      onInputChange={handleInputChange}
      values={values}
      error={
        queryError
          ? typeof queryError === "string"
            ? queryError
            : translate("contentpicker.tagloaderror")
          : ""
      }
      loading={queryLoading}
    />
  );
};
