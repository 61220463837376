import { ApiClient } from "api/apiClient";
import { SystemLanguage } from "models/systemLanguage";

const BASE_URL = "api/systemlanguages";

export class SystemLanguageApi {
  static readonly getLanguages = async (): Promise<SystemLanguage[]> => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  };
}
