import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  checked?: boolean;
}

const useStyles = makeStyles((theme) => ({
  unchecked: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  checked: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.custom.ds.viking.viking500
  }
}));

const CheckIcon = (props: IProps) => {
  const { checked = false, className } = props;
  const classes = useStyles();

  if (checked)
    return (
      <UxdIcon
        className={classNames(classes.checked, className)}
        name="check_circle"
      />
    );

  return (
    <UxdIcon
      outline
      className={classes.unchecked}
      name="radio_button_unchecked"
    />
  );
};

export default CheckIcon;
