import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import classNames from "classnames";
import CheckIcon from "components/shared/checkIcon";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  id: string;
  content: string;
  secondaryContent?: string;
  selected?: boolean;
  disabled?: boolean;
  disableRipple?: boolean;
  tooltipContent?: string;
  leftIcon?: React.ReactNode;
  handleClick: (event: React.MouseEvent, id: string) => void | (() => void);
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(1.25, 1.25, 1.25, 2),
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr200,
      "& .MuiCheckbox-root": {
        display: "inline-flex",
        "&:hover": {
          backgroundColor: "initial"
        }
      }
    },
    "&$selected": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      "& .MuiCheckbox-root": {
        display: "inline-flex"
      }
    },
    "&$disabled": {
      opacity: "0.4"
    },
    "& .MuiCheckbox-root": {
      display: "none"
    }
  },
  selected: {},
  disabled: {},
  leftIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: theme.spacing(1.5)
  },
  text: {
    "& .MuiTypography-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  }
}));

/**
 * @component
 * A generic MultiSelectListItem component.
 * The handleClick functionality can be found in components/shared/multiSelect/hooks.tsx
 *
 * @example
 * <List>
 *  {items.map({id,username}, index) => <MultiselectListItem key={index} id={id} content={username}/>}
 * </List>
 */
const MultiSelectListItem = (props: IProps) => {
  const {
    id,
    content,
    secondaryContent,
    disabled,
    disableRipple = true,
    selected,
    handleClick,
    tooltipContent = "",
    leftIcon,
    className
  } = props;

  const classes = useStyles();
  const rootClassName = classNames(classes.root, className, {
    [classes.selected]: selected,
    [classes.disabled]: disabled
  });

  return (
    <Tooltip
      title={tooltipContent}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      placement="top"
      arrow
    >
      <span>
        <ListItem
          disableRipple={disableRipple}
          key={id}
          className={rootClassName}
          disabled={disabled}
          button
          onClick={(event: React.MouseEvent) => handleClick(event, id)}
        >
          <div className={classes.leftIcon}>{leftIcon}</div>
          <ListItemText
            className={classes.text}
            id={id}
            primary={content}
            secondary={secondaryContent}
          />
          <ListItemIcon>
            <Checkbox
              disableRipple
              checked={selected}
              disabled={disabled}
              icon={<CheckIcon checked={false} />}
              checkedIcon={<CheckIcon checked={true} />}
              edge="end"
            />
          </ListItemIcon>
        </ListItem>
      </span>
    </Tooltip>
  );
};

export default MultiSelectListItem;
