import { useEffect, useMemo, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { List, makeStyles, Typography } from "@material-ui/core";
import { useGetAuthGroupUsers, useUpdateAuthGroupUsers } from "api/queryHooks";
import classNames from "classnames";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { AuthGroupListLoading } from "components/permissions/authorizationGroupNav/authGroupListLoading";
import { useAuthorizationGroupUsersSelectContext } from "components/permissions/detailView/context/useAuthorizationGroupUsersSelect";
import { AddAuthorizationGroupUser } from "components/permissions/detailView/userList/addAuthorizationGroupUser";
import { EmptyUsers } from "components/permissions/detailView/userList/emptyUsers";
import { UserAvatar } from "components/permissions/detailView/userList/userAvatar";
import { D3Modal, D3SearchBox } from "components/shared/d3Components";
import MultiSelectListItem from "components/shared/multiSelect/multiselectListItem";
import { IBaseProps } from "components/_baseProps";
import { IAuthorizationGroup } from "models/authorizationGroup";
import { IAuthorizationGroupUsersUpdateRequest } from "models/authorizationGroupUsers";

import { setAuthorizationGroupUsersUpdatedFlag } from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { selectAuthorizationGroupUsersUpdatedFlag } from "redux/selectors/authorizationGroupSelectors";
import { translate } from "utils/i18n";
import { getFullName, getInitials } from "utils/nameUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 2, 2)
  },
  results: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr800,
    paddingLeft: theme.spacing(1)
  },
  list: {
    overflowY: "auto",
    height: `calc(100vh - ${theme.spacing(23)}px)`,
    paddingRight: theme.spacing(4)
  },
  item: {
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(0.5, 1),
    height: theme.spacing(8),
    borderRadius: "2px",
    transition: "all .3s ease-in-out",
    "&:hover": {
      boxShadow: theme.palette.custom.ds.shadow.s300,
      transition: "all .3s ease-in-out"
    },
    "& .MuiTypography-body1": {
      fontSize: theme.typography.pxToRem(14)
    },
    border: "solid 1px transparent"
  },
  lastItem: {
    marginBottom: theme.spacing(13)
  },
  itemSelected: {
    transition: "all .3s ease-in-out",
    border: `solid 1px ${theme.palette.custom.ds.viking.viking500}`
  },
  removeSnackbar: {
    "& .MuiSnackbar-root": {
      bottom: theme.spacing(0.3),
      left: `calc(50% - ${theme.spacing(1)}px)`,
      width: `calc(100% + ${theme.spacing(2)}px - ${theme.spacing(2)}px * 2)`
    }
  },
  hideSnackbar: {
    display: "none"
  },
  header: {
    paddingRight: theme.spacing(0.4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  noresult: {
    textAlign: "center",
    margin: theme.spacing(4)
  }
}));

interface IProps extends IBaseProps {
  authorizationGroup: IAuthorizationGroup;
}

type UserListItem = {
  key: string;
  fullName: string;
  initials: string;
  selected: boolean;
};

export function AuthorizationUserList(props: IProps) {
  const { authorizationGroup } = props;
  const classes = useStyles();

  const [users, setUsers] = useState<UserListItem[]>([]);
  const [filterUsers, setFilterUsers] = useState<UserListItem[]>([]);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const dispatch = useDispatch();
  const mutation = useUpdateAuthGroupUsers();
  const usersUpdated = useSelector(selectAuthorizationGroupUsersUpdatedFlag);

  const request = useGetAuthGroupUsers();

  const { checkedList, handleClick, clearChecked, MultiListSnackbar } =
    useAuthorizationGroupUsersSelectContext();

  const isSelected = (key: string) => checkedList.includes(key);

  useEffect(() => {
    request.mutate(authorizationGroup.groupId);

    if (usersUpdated) dispatch(setAuthorizationGroupUsersUpdatedFlag(false));
  }, [authorizationGroup.groupId, usersUpdated, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let formattedUsers = Array.from(request.data ?? [], (user) => {
      return {
        key: user.key,
        fullName: getFullName(user.firstName, user.lastName),
        initials: getInitials(user.firstName, user.lastName),
        selected: false
      };
    });
    formattedUsers = sortBy(formattedUsers, "fullName");
    setUsers(formattedUsers);
    setFilterUsers(formattedUsers);
  }, [request.data]);

  const onRemove = () => {
    const update: IAuthorizationGroupUsersUpdateRequest = {
      authorizationGroups: [authorizationGroup.groupId],
      actionType: "delete",
      userIds: checkedList
    };

    mutation.mutate(update, {
      onSuccess: () => {
        batch(() => {
          dispatch(setAuthorizationGroupUsersUpdatedFlag(true));
          dispatch(
            displayNotification({
              message:
                "permissions.content.detail.users.remove.confirmation.success",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message:
              "permissions.content.detail.users.remove.confirmation.error",
            severity: "error"
          })
        );
      }
    });

    clearChecked();
    setRemoveModalOpen(false);
  };

  const handleSearch = (filter: string) => {
    const filterLowerCase = filter.toLowerCase();
    setFilterUsers(
      users.filter(({ fullName }) =>
        fullName.toLowerCase().includes(filterLowerCase)
      )
    );
  };

  const removeSnackbarClassNames = classNames(classes.removeSnackbar, {
    [classes.hideSnackbar]: removeModalOpen
  });

  const usersInAuthorizationGroup = useMemo(() => map(users, "key"), [users]);

  return (
    <section className={classes.root}>
      {request.isLoading && <AuthGroupListLoading />}
      {!request.isLoading && !users?.length && <EmptyUsers />}
      {!request.isLoading && users?.length > 0 && (
        <div>
          <div className={classes.header}>
            <Typography variant="h5" className={classes.results}>
              {translate("permissions.content.detail.users.list.results", {
                numberOfResults: filterUsers.length
              })}
            </Typography>
            <D3SearchBox
              onTextChange={handleSearch}
              placeholder={translate("sitestructure.search.inputplaceholder")}
              collapse
            />
          </div>
          <List className={classes.list}>
            {filterUsers.map((user, index) => {
              const selected = isSelected(user.key);
              const itemClass = classNames(
                classes.item,
                {
                  [classes.lastItem]: index + 1 === filterUsers.length
                },
                {
                  [classes.itemSelected]: selected
                }
              );
              return (
                <MultiSelectListItem
                  className={itemClass}
                  key={user.key}
                  id={user.key}
                  content={user.fullName}
                  handleClick={handleClick}
                  selected={selected}
                  leftIcon={<UserAvatar initials={user.initials} />}
                />
              );
            })}
          </List>
        </div>
      )}
      {!request.isLoading && filterUsers.length <= 0 && (
        <Typography className={classes.noresult}>
          {translate("permissions.content.detail.users.list.noresults")}
        </Typography>
      )}
      <AddAuthorizationGroupUser
        authorizationGroup={authorizationGroup}
        usersInAuthorizationGroup={usersInAuthorizationGroup}
      />

      <MultiListSnackbar
        singleSelectionText={translate(
          "permissions.content.detail.users.remove.snackbarmessage.singular"
        )}
        multiSelectionText={translate(
          "permissions.content.detail.users.remove.snackbarmessage.plural"
        )}
        handleRemove={() => setRemoveModalOpen(true)}
        className={removeSnackbarClassNames}
      />
      <D3Modal
        open={removeModalOpen}
        modalTitle={translate(
          "permissions.content.detail.users.remove.modal.title"
        )}
        onCancel={() => setRemoveModalOpen(false)}
        onConfirm={onRemove}
        cancelLabel={translate(
          "permissions.content.detail.users.remove.modal.buttons.cancel"
        )}
        confirmLabel={translate(
          "permissions.content.detail.users.remove.modal.buttons.confirm"
        )}
      >
        <Typography>
          {translate("permissions.content.detail.users.remove.modal.content")}
        </Typography>
      </D3Modal>
    </section>
  );
}
