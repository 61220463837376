import { put } from "redux-saga/effects";
import { Action, createAction } from "redux-actions";
import { issueCommand } from "redux/actions/commandActions";
import { CreateCustomEntityTagPayload } from "redux/actions/typings/tagsActions";
import {
  CreateCustomEntityTag,
  ICreateCustomEntityTagBody
} from "@d3-forge/forge-commands";
import uuid from "utils/uuid";

export function* sendCreateCustomEntityTag(
  action: Action<CreateCustomEntityTagPayload>
) {
  try {
    const {
      dataSourceName,
      dataSourceId,
      extradata,
      title,
      label,
      slug,
      actionId,
      translationId
    } = action.payload;

    const aggregateId = uuid.generate();
    const body: ICreateCustomEntityTagBody = {
      entityCode: dataSourceName,
      entityTranslationId: dataSourceId,
      extradata: extradata ? extradata : {},
      title,
      label,
      slug,
      tagId: aggregateId,
      translationId: translationId
    };

    const createCustomEntityTagCommand = new CreateCustomEntityTag(body);

    const commandSuccess = createAction(
      "CREATE_CUSTOM_ENTITY_TAG_SUCCEDED_" + actionId
    );

    const commandAction = issueCommand({
      command: createCustomEntityTagCommand,
      onSuccessAction: commandSuccess(),
      errorMessage: "notification.createentitytag.error",
      successMessage: "notification.createentitytag.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
