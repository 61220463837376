import {
  AssetType,
  AssetTypes,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { IAssetCatalogState, IDomainState } from "models/domainStates";
import { createSelector } from "reselect";

// Contexts

export const selectContexts = (state: IDomainState) =>
  state.assetCatalog.contexts;

export const selectDefaultContext = (state: IDomainState) => {
  const contexts = state.assetCatalog.contexts;
  const defaultContext =
    contexts.find((c) => c.default)?.contextName ??
    contexts.map((context) => context.contextName)[0];
  return defaultContext;
};

// Modules

export const selectModules = (state: IDomainState) =>
  state.assetCatalog.modules;

export const selectModuleCatalog = (state: IDomainState, context?: string) => {
  if (context) return state.assetCatalog.modules[context];
  return getAllAssets(state, AssetTypes.MODULE);
};

// Layouts

export const selectContextLayouts = (state: IDomainState) =>
  state.assetCatalog.layouts;

export const selectLayoutCatalog = (state: IDomainState, context?: string) => {
  if (context) return state.assetCatalog.layouts[context];
  return getAllAssets(state, AssetTypes.LAYOUT);
};

// Templates

export const selectContextTemplates = (state: IDomainState) =>
  state.assetCatalog.templates;

export const selectTemplatesCatalog = (
  state: IDomainState,
  context?: string
) => {
  if (context) return state.assetCatalog.templates[context];
  return getAllAssets(state, AssetTypes.TEMPLATE);
};

const getAllAssets = (state: IDomainState, type: AssetType) => {
  const catalog = state.assetCatalog;
  let assets = {};

  Array.prototype.forEach.call(catalog.contexts, (element) => {
    const contextAssets = getContextAssetElements(
      catalog,
      type,
      element.contextName
    );
    Object.assign(assets, contextAssets);
  });

  return assets;
};

const getContextAssetElements = (
  catalog: IAssetCatalogState,
  type: AssetType,
  contextName: string
) => {
  switch (type) {
    case AssetTypes.TEMPLATE:
      return catalog.templates[contextName];
    case AssetTypes.LAYOUT:
      return catalog.layouts[contextName];
    case AssetTypes.MODULE:
      return catalog.modules[contextName];
  }
};

export const makeSelectAssetsDefinition = () =>
  createSelector(
    getAllAssets,
    (state: IDomainState, assetType: AssetType, key: string) => key,
    (contextElements, key) => contextElements[key]
  );

export const makeSelectPageElementDefinition = () =>
  createSelector(
    (state: IDomainState) => state,
    (
      state: IDomainState,
      pageElement: Pick<SiteItemElement, "moduleType" | "key">
    ) => pageElement,
    (state, element) => {
      return getAllAssets(state, element.moduleType)[element.key];
    }
  );
