import {
  ICell,
  ILayout,
  IModule,
  IModuleBase,
  IPage,
  IRow,
  IRowList,
  ISlot
} from "models/pages";

export function getPageElement(page: IPage, instanceId: string) {
  const pageElement = page.template.rows
    .flatMap(getModulesInRow)
    .find((m) => m.instanceId === instanceId);

  // Can be in page but orphan
  if (!pageElement) {
    const orphan = page.orphans
      .flatMap(getNestedModules)
      .find((o) => o.instanceId === instanceId);

    return orphan;
  }

  return pageElement;
}

export function pageElementExists(page: IPage, instanceId: string) {
  const pageElement = getPageElement(page, instanceId);
  return !!pageElement;
}

export function setPageElementProperty(
  page: IPage,
  instanceId: string,
  property: string,
  value: string
) {
  const module = getPageElement(page, instanceId);
  if (!module) {
    return;
  }

  module.properties[property] = value;
}

function getModulesInRow(row: IRow): Array<IModule | ILayout> {
  return row.cells.flatMap(getModulesInCell);
}

function getNestedModules(module: IModuleBase): Array<IModule | ILayout> {
  if (module.moduleType === "Module") {
    return [module as IModule];
  }
  const layout = module as ILayout;
  return layout.rows.flatMap(getModulesInRow).concat([layout]);
}

function getModulesInCell(cell: ICell): Array<IModule | ILayout> {
  const { contentType } = cell.content;
  switch (contentType) {
    case "RowList":
      const rowList = cell.content as IRowList;
      return rowList.rows.flatMap(getModulesInRow);
    case "Slot":
      const slot = cell.content as ISlot;
      return slot.modules.flatMap(getNestedModules);
    default:
      return [];
  }
}
