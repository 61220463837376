import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IconLabel } from "components/shared/iconLabel";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.custom.secondaryAccent.green
  },
  label: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13)
  }
}));

export const NoPendingChangesMessage = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <IconLabel
      className={rootClassName}
      icon="done_all"
      text={translate("sitestructure.stageditems.nopendingchanges")}
      textVariant="body1"
      TextClassName={classes.label}
      IconClassName={classes.icon}
    />
  );
};
