import { makeStyles, Typography } from "@material-ui/core";
import MetadataProperty from "./metadataProperty";
import { IMetadataCategory } from "models/metadata";
import { ScrollSpyElem } from "components/shared/scrollspy";
import { ISiteItem } from "models/siteItem";

interface IMetadataCategoryProps {
  category: ScrollSpyElem<IMetadataCategory>;
  siteItem: ISiteItem;
}

const useStyles = makeStyles((theme) => ({
  propertyContainer: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
    borderRadius: "4px",
    backgroundColor: theme.palette.common.white
  },
  category: {
    scrollMargin: "40px"
  }
}));

export const MetadataCategory = (props: IMetadataCategoryProps) => {
  const { category, siteItem } = props;
  const classes = useStyles();

  return (
    <div className={classes.category} key={category.text} ref={category.node}>
      <Typography variant="h5">{category.text}</Typography>
      {category.properties.map((property, index) => {
        return (
          <div
            key={index}
            ref={category.children[index].node}
            className={classes.propertyContainer}
          >
            <MetadataProperty
              property={property}
              siteItem={siteItem}
              category={category.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MetadataCategory;
