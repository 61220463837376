import { ForgeEntityDefinition } from "models/forgeEntities";
import { SystemLanguage } from "models/systemLanguage";
import { createAction } from "redux-actions";
import {
  FAILED_ACTION_SUFFIX,
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX
} from "redux/actions/constants";

export const fetchEntitiesDefinitions = createAction(
  `FETCH_ENTITIES_DEFINITIONS${REQUEST_ACTION_SUFFIX}`
);
export const fetchEntitiesDefinitionsSucceded = createAction(
  `FETCH_ENTITIES_DEFINITIONS${SUCCESS_ACTION_SUFFIX}`,
  (definitions: ForgeEntityDefinition[]) => definitions
);
export const fetchEntitiesDefinitionsFailed = createAction(
  `FETCH_ENTITIES_DEFINITIONS${FAILED_ACTION_SUFFIX}`
);

export const fetchSystemLanguages = createAction(
  `FETCH_SYSTEM_LANGUAGES${REQUEST_ACTION_SUFFIX}`
);
export const fetchSystemLanguagesSucceded = createAction(
  `FETCH_SYSTEM_LANGUAGES${SUCCESS_ACTION_SUFFIX}`,
  (languages: SystemLanguage[]) => languages
);
export const fetchSystemLanguagesFailed = createAction(
  `FETCH_SYSTEM_LANGUAGES${FAILED_ACTION_SUFFIX}`
);

export const fetchTagsDatasources = createAction(
  `FETCH_TAGS_DATASOURCES${REQUEST_ACTION_SUFFIX}`
);
export const fetchTagsDatasourcesSucceded = createAction(
  `FETCH_TAGS_DATASOURCES${SUCCESS_ACTION_SUFFIX}`
);
export const fetchTagsDatasourcesFailed = createAction(
  `FETCH_TAGS_DATASOURCES${FAILED_ACTION_SUFFIX}`
);
