import { makeStyles } from "@material-ui/core";
import { RectangleSkeleton } from "components/skeletons";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 3, 0, 1)
  }
}));

export function AuthGroupListLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {_.range(8).map((index) => (
        <RectangleSkeleton key={index} size={10} />
      ))}
    </div>
  );
}
