import { DropPageElementSnackbar } from "./dropPageElementSnackbar";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch } from "react-redux";

import {
  addLayout,
  addModule,
  moveModule,
  moveLayout,
  copyModule
} from "redux/actions/siteStructureActions";

const NOTHING_SELECTED_ERROR_MESSAGE =
  "[DropPageElementContext] Need to select a page element with a specific mode before dropping";
const MISSING_INSTANCEID_ERROR_MESSAGE =
  "[DropPageElementContext] Missing instance id of the selected element";
const MANDATORY_INSTANCEID_FOR_MOVE_ERROR_MESSAGE =
  "[DropPageElementContext] InstanceId must be provided to start a move action";

export type DropMode = "none" | "add" | "move" | "copy";

export type ElementIdentifier = {
  instanceId?: string;
  key: string;
  type: "Module" | "Layout";
};

const DEFAULT_IDENTIFIER: ElementIdentifier = {
  key: "",
  type: "Module"
};

type DropPageElementContextShape = {
  dropMode: DropMode;
  pageElementIdentifier: ElementIdentifier;
  select: (dropMode: DropMode, identifier: ElementIdentifier) => void;
  add: (parentId: string, slot: string, position: number) => void;
  move: (targetId: string, slot: string, position: number) => void;
  copy: (layoutId: string, slot: string, position: number) => void;
  clear: () => void;
};

export const DropPageElementContext =
  createContext<DropPageElementContextShape>({
    dropMode: "add",
    pageElementIdentifier: DEFAULT_IDENTIFIER,
    select: () => undefined,
    add: () => undefined,
    move: () => undefined,
    copy: () => undefined,
    clear: () => undefined
  });

interface Props {
  pageId: string;
}

export const DropPageElementContextProvider = (
  props: PropsWithChildren<Props>
) => {
  const dispatch = useDispatch();

  const { children, pageId } = props;

  const [elementIdentifier, setElementIdentifier] =
    useState<ElementIdentifier>(DEFAULT_IDENTIFIER);
  const [sourcePageId, setSourcePageId] = useState<string | null>(null);

  const [dropMode, setDropMode] = useState<DropMode>("none");

  const clear = useCallback(() => {
    setDropMode("none");
    setElementIdentifier(DEFAULT_IDENTIFIER);
    setSourcePageId(null);
  }, []);
  const select = useCallback(
    (mode: DropMode, identifier: ElementIdentifier) => {
      if (mode === "move" && !identifier.instanceId) {
        throw new Error(MANDATORY_INSTANCEID_FOR_MOVE_ERROR_MESSAGE);
      }
      setDropMode(mode);
      setElementIdentifier(identifier);
      setSourcePageId(pageId);
    },
    [pageId]
  );

  const add = useCallback(
    (parentId: string, slot: string, position: number) => {
      if (!checkSelected(dropMode, elementIdentifier.key)) {
        return;
      }

      addPageElement(
        dispatch,
        pageId,
        parentId,
        slot,
        elementIdentifier,
        position,
        clear
      );
    },
    [clear, dispatch, dropMode, pageId, elementIdentifier]
  );

  const move = useCallback(
    (targetId: string, slot: string, position: number) => {
      const { key, type, instanceId } = elementIdentifier;

      if (!instanceId) {
        console.error(MISSING_INSTANCEID_ERROR_MESSAGE);
        return;
      }
      if (!checkSelected(dropMode, key)) {
        return;
      }

      movePageElement({
        dispatch,
        pageId,
        instanceId,
        targetId,
        targetSlot: slot,
        targetPosition: position,
        pageElementType: type,
        callback: clear
      });
    },
    [clear, dispatch, dropMode, elementIdentifier, pageId]
  );

  const copy = useCallback(
    (layoutId, slot, position) => {
      const { key, type, instanceId } = elementIdentifier;
      if (!instanceId) {
        console.error(MISSING_INSTANCEID_ERROR_MESSAGE);
        return;
      }
      if (!checkSelected(dropMode, key)) {
        return;
      }

      if (type !== "Module") {
        return;
      }

      dispatch(
        copyModule({
          sourcePageId: sourcePageId || pageId,
          sourceInstanceId: instanceId,
          targetPageId: pageId,
          targetInstanceId: layoutId,
          targetSlot: slot,
          targetPosition: position
        })
      );

      clear();
    },
    [clear, dispatch, dropMode, elementIdentifier, pageId, sourcePageId]
  );

  // cleanup on page change
  useEffect(() => {
    if (dropMode !== "none") {
      return;
    }

    // This shoudn't be done for copy module across different page
    return clear();
  }, [dropMode, pageId, clear]);

  const contextValue: DropPageElementContextShape = useMemo(
    () => ({
      dropMode,
      pageElementIdentifier: elementIdentifier,
      select,
      add,
      move,
      copy,
      clear
    }),
    [add, move, copy, clear, select, elementIdentifier, dropMode]
  );

  return (
    <DropPageElementContext.Provider value={contextValue}>
      {children}
      <DropPageElementSnackbar
        mode={dropMode}
        elementType={elementIdentifier.type}
        onCancel={clear}
      />
    </DropPageElementContext.Provider>
  );
};

const actions = {
  add: {
    Module: addModule,
    Layout: addLayout
  },
  move: {
    Module: moveModule,
    Layout: moveLayout
  }
};

function checkSelected(dropMode: DropMode, key: string) {
  if (dropMode === "none" || !key) {
    console.error(NOTHING_SELECTED_ERROR_MESSAGE);
    return false;
  }
  return true;
}

function addPageElement(
  dispatch: Function,
  pageId: string,
  parentId: string,
  slot: string,
  identifier: ElementIdentifier,
  position: number,
  callback: Function
) {
  const { key, type } = identifier;
  const [id, namespace] = key.split("|");

  const namespaceKey = {
    id,
    namespace
  };
  const commonPayload = {
    pageId,
    parentInstanceId: parentId,
    slot,
    position
  };

  if (type === "Module") {
    const payload = {
      ...commonPayload,
      moduleKey: namespaceKey
    };
    dispatch(actions.add.Module(payload));
  } else {
    const payload = {
      ...commonPayload,
      layoutKey: namespaceKey
    };
    dispatch(actions.add.Layout(payload));
  }

  callback();
}

function movePageElement({
  dispatch,
  pageId,
  instanceId,
  targetId,
  targetSlot,
  targetPosition,
  pageElementType,
  callback
}: {
  dispatch: Function;
  pageId: string;
  instanceId: string;
  targetId: string;
  targetSlot: string;
  targetPosition: number;
  pageElementType: "Module" | "Layout";
  callback: Function;
}) {
  const payload = {
    pageId,
    instanceId,
    target: {
      layoutId: targetId,
      slot: targetSlot,
      position: targetPosition
    }
  };

  const action = actions.move[pageElementType];
  dispatch(action(payload));
  callback();
}
