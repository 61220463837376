import moment from "moment";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { HistoryEntryRevision } from "components/siteStructure/history/historyEntryRevision";
import { SiteItemHistoryEntry } from "models/history";
import { useMemo } from "react";
import { HistoryEntryText } from "components/siteStructure/history/historyEntryText";
import { HistoryEntryContextualMenu } from "components/siteStructure/history/historyEntryContextualMenu";
import { SiteNodeType } from "models/siteStructure";

interface IProps extends IBaseProps {
  nodeId: string;
  nodeType: SiteNodeType;
  entry: SiteItemHistoryEntry;
  isCurrentRevision: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 1),
    borderBottom: `1px solid ${theme.palette.custom.greyscales.bordersAndSeparators.lineLighter}`
  },
  time: {
    fontSize: theme.typography.pxToRem(12),
    margin: theme.spacing(0, 2),
    color: theme.palette.custom.text.caption
  },
  text: {
    flex: 1,
    marginRight: theme.spacing(0.5)
  },
  publish: {
    backgroundColor: theme.palette.custom.stages.publishedLight
  }
}));

export const HistoryEntry = (props: IProps) => {
  const { className, entry, nodeId, isCurrentRevision, nodeType } = props;
  const classes = useStyles();

  const { date, revision, verb, fromAggregate } = entry;
  const isPublish = verb === "publish";

  const rootClassName = classnames(
    classes.root,
    { [classes.publish]: isPublish },
    className
  );

  // .format() convert also in local time
  const time = useMemo(() => moment(date).format("HH:mm"), [date]);

  return (
    <div className={rootClassName}>
      <HistoryEntryRevision
        revision={revision}
        publish={isPublish}
        fromAggregate={fromAggregate}
      />
      <Typography className={classes.time} variant="body2">
        {time}
      </Typography>
      <HistoryEntryText className={classes.text} entry={entry} />
      <HistoryEntryContextualMenu
        nodeId={nodeId}
        revision={revision}
        disableRestore={isCurrentRevision || fromAggregate === "sitestructure"}
        aggregateType={nodeType}
      />
    </div>
  );
};
