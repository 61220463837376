import classnames from "classnames";
import { DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ActionButton } from "components/shared/actionButton";

interface IProps extends IBaseProps {
  title: string;
  onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(9),
    boxSizing: "border-box",
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    border: 0
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr900,
    lineHeight: "24px",
    fontWeight: 400
  },
  closeIcon: {
    color: theme.palette.custom.ds.grayscales.gr600,
    fontSize: theme.typography.pxToRem(20)
  },
  button: {
    "&:hover": {
      borderRadius: "2px",
      backgroundColor: "transparent"
    }
  }
}));

export const DialogHeader = (props: IProps) => {
  const { className, title, onClose = () => {} } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <DialogTitle className={rootClassName} disableTypography>
      <Typography className={classes.title} variant="subtitle2">
        {title}
      </Typography>
      <ActionButton
        className={classes.button}
        iconClassName={classes.closeIcon}
        size="small"
        icon="close"
        disableRipple
        onClick={onClose}
      />
    </DialogTitle>
  );
};
