import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PickListPropertyInfo } from "models/componentProperty";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { translate } from "utils/i18n";
import { IPickListValue } from "models/pageElementProperty";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";
import { getPicklistItems } from "components/siteStructure/pageView/pageSidebar/moduleProperties/freeTextPickListDataPathProperty";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  pickList: PickListPropertyInfo;
  name: string;
  value: string | null;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const StrictPickListDataPathProperty = (props: IProps) => {
  const {
    className,
    pickList,
    name,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { dataPath, valueField, descriptionField } = pickList;

  const [pickListLocalValue, setPickListLocalValue] =
    useState<IPickListValue | null>(null);

  const [pickListValues, setPickListValues] = useState<IPickListValue[]>([]);

  const syncPicklistValues = useCallback(async () => {
    const pickListItems = await getPicklistItems(
      dataPath,
      valueField,
      descriptionField
    );

    const pickListValues: IPickListValue[] = pickListItems.map((v) => ({
      value: v.value,
      displayName: v.description,
      inputValue: null
    }));

    const localValueItem = pickListValues.find((i) => i.value === value);

    setPickListValues(pickListValues);
    setPickListLocalValue(localValueItem || null);
  }, [dataPath, descriptionField, valueField, value]);

  useEffect(() => {
    syncPicklistValues();
  }, [syncPicklistValues]);

  const filterOption = createFilterOptions<IPickListValue>({
    stringify: (option) => option.displayName
  });

  const handleSelect = (
    event: React.ChangeEvent<{}>,
    newValue: IPickListValue | string | null
  ) => {
    let selectedValue: string | null = null;
    if (typeof newValue === "string") {
      selectedValue = newValue;
    } else {
      selectedValue = newValue ? newValue.value : null;
    }

    if (pickListLocalValue?.value === selectedValue) {
      return;
    }

    const localValueItem = pickListValues.find(
      (i) => i.value === selectedValue
    );
    setPickListLocalValue(localValueItem || null);

    onChange(name, selectedValue || "");
  };

  const clearValueOnVariableSwitch = () => {
    setPickListLocalValue(null);
    onChange(name, pickListLocalValue?.value ?? "");
  };

  return (
    <>
      <Autocomplete
        className={rootClassName}
        options={pickListValues}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            className={mandatory.showMessage ? mandatory.className : ""}
            placeholder={translate("picklist.typeaheadsuggestion")}
            variant="outlined"
            helperText={mandatory.showMessage ? mandatory.message : ""}
          />
        )}
        disabled={disabled}
        onChange={handleSelect}
        value={pickListLocalValue}
        getOptionLabel={(option) => {
          //Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Regular option
          return option.displayName || "";
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.displayName}
        filterOptions={filterOption}
        fullWidth
      />
      <VariableSwitch
        propertyName={name}
        propertyValue={value ?? ""}
        disabled={disabled}
        onChange={onChange}
        onSwitch={clearValueOnVariableSwitch}
        mandatory={mandatory}
      />
    </>
  );
};
