import {
  SetMenuDescription,
  DeleteMenuItem,
  IDeleteMenuItemBody,
  AddMenuItem,
  UpdateMenuItem,
  MoveMenuItem,
  IMoveMenuItemBody
} from "@d3-forge/forge-commands";
import { useCommand } from "api/queryHooks";
import { MenuBuilderLoaders } from "components/siteStructure/menuView/types";
import {
  constructMenuItemBody,
  buildItemCommandProperties,
  createNewMenuItemObject
} from "components/siteStructure/menuView/utils";
import { IMenu, IMenuItem } from "models/menus";
import { useCallback, useMemo } from "react";

interface Props {
  menu: IMenu | null;
  refetchMenu?: () => void;
}

export const useMenuCommands = ({ menu, refetchMenu }: Props) => {
  const setDescriptionCommand = useCommand("set-menu-description", {
    errorMessage: "notification.menu.set_description.error",
    successMessage: "notification.menu.set_description.success"
  });
  const updateMenuItemCommand = useCommand("update-menu-item", {
    errorMessage: "notification.menu.update_item.error",
    successMessage: "notification.menu.update_item.success"
  });

  const deleteMenuItemCommand = useCommand("delete-menu-item", {
    errorMessage: "sitestructure.menu_items.modal.delete.error",
    successMessage: "sitestructure.menu_items.modal.delete.success"
  });

  const addMenuItemCommand = useCommand("add-menu-item", {
    errorMessage: "sitestructure.menu_items.add.error",
    successMessage: "sitestructure.menu_items.add.success"
  });

  const moveMenuItemCommand = useCommand("move-menu-item", {
    errorMessage: "sitestructure.menu_items.move.error",
    successMessage: "sitestructure.menu_items.move.success"
  });

  const setMenuDescription = async (value: string) => {
    if (menu === null) {
      return;
    }

    const { id: menuId } = menu;
    const command = new SetMenuDescription({
      menuId: menuId,
      description: value,
      itemId: menuId // the field is mandatory and can't be removed in the commands, so we need to send it like this
    });

    try {
      const result = await setDescriptionCommand.mutateAsync(command);

      if (!result.success) throw new Error(result.message);
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateMenuItem = useCallback(
    async (position: number, parentId?: string) => {
      if (menu === null || refetchMenu === undefined) {
        return;
      }

      const newMenuItem = createNewMenuItemObject();
      const menuItemBody = constructMenuItemBody(
        newMenuItem,
        menu.id,
        position,
        parentId
      );

      const command = new AddMenuItem(menuItemBody);

      try {
        const result = await addMenuItemCommand.mutateAsync(command);

        if (!result.success) {
          throw new Error(result.message);
        }

        refetchMenu();

        return newMenuItem;
      } catch (err) {
        console.log("Error", err);
      }
    },
    [menu, addMenuItemCommand, refetchMenu]
  );

  const onDeleteMenuItem = useCallback(
    async (menuItemId: string) => {
      if (menu === null || refetchMenu === undefined) {
        return;
      }

      const commandBody: IDeleteMenuItemBody = {
        menuId: menu.id,
        menuItemId
      };

      const command = new DeleteMenuItem(commandBody);

      try {
        const result = await deleteMenuItemCommand.mutateAsync(command);

        if (!result.success) {
          throw new Error(result.message);
        }

        refetchMenu();
      } catch (err) {
        console.log("Error", err);
      }
    },
    [menu, refetchMenu, deleteMenuItemCommand]
  );

  const onMoveMenuItem = useCallback(
    async (pickedItem: IMenuItem, position: number, parentId?: string) => {
      if (menu === null || refetchMenu === undefined) {
        return;
      }

      const menuItemBody: IMoveMenuItemBody = {
        menuId: menu.id,
        id: pickedItem.id,
        position,
        parentId
      };

      const command = new MoveMenuItem(menuItemBody);

      try {
        const result = await moveMenuItemCommand.mutateAsync(command);

        if (!result.success) {
          throw new Error(result.message);
        }

        refetchMenu();

        return pickedItem;
      } catch (err) {
        console.log("Error", err);
      }
    },
    [menu, moveMenuItemCommand, refetchMenu]
  );

  const loaders: MenuBuilderLoaders = useMemo(
    () => ({
      isDeletingMenuItem: deleteMenuItemCommand.isLoading,
      isCreatingMenuItem: addMenuItemCommand.isLoading,
      isMovingMenuItem: moveMenuItemCommand.isLoading
    }),
    [
      deleteMenuItemCommand.isLoading,
      addMenuItemCommand.isLoading,
      moveMenuItemCommand.isLoading
    ]
  );

  const updateMenuItem = useCallback(
    async (itemId: string, updatedItemInfo: IMenuItem) => {
      if (menu === null || refetchMenu === undefined) {
        return;
      }

      const command = new UpdateMenuItem({
        menuId: menu.id,
        menuItemId: itemId,
        newMenuItemId: updatedItemInfo.id,
        properties: buildItemCommandProperties(updatedItemInfo)
      });

      try {
        const result = await updateMenuItemCommand.mutateAsync(command);

        if (!result.success) throw new Error(result.message);

        refetchMenu();
      } catch (err) {
        console.log(err);
      }
    },
    [menu, refetchMenu, updateMenuItemCommand]
  );

  return {
    loaders,
    setMenuDescription,
    onCreateMenuItem,
    updateMenuItem,
    onMoveMenuItem,
    onDeleteMenuItem
  };
};
