import D3Dropdown, {
  ID3DropdownOption,
  toDropdownOption
} from "components/shared/d3Components/d3Dropdown";
import { useStyles } from "components/siteStructure/mainDetail/inputs/metadataInput";
import MetadataVariableInput from "components/siteStructure/mainDetail/inputs/metadataVariableInput";
import { IBaseProps } from "components/_baseProps";
import { useState } from "react";
import { isValidVariableFormat } from "utils/variableUtils";

interface IEnumMetadataInput extends IBaseProps {
  value: string;
  options: ID3DropdownOption[];
  disabled?: boolean;
  onVariableSubmit: (variable: string) => void;
  onEnumSubmit: (selected: ID3DropdownOption) => void;
  onTypeSwitch: () => void;
}

const EnumTypeOptions = {
  Value: toDropdownOption("Value"),
  Variable: toDropdownOption("Variable"),
  NoValue: toDropdownOption("NoValue")
};

export const EnumMetadataInput = (props: IEnumMetadataInput) => {
  const {
    className,
    value,
    options: enumOptions = [],
    onEnumSubmit,
    onVariableSubmit,
    onTypeSwitch,
    disabled
  } = props;

  const classes = useStyles();
  const [useVariable, setUseVariable] = useState<boolean>(
    isValidVariableFormat(value ?? "")
  );

  const options: ID3DropdownOption[] = [
    EnumTypeOptions.Value,
    EnumTypeOptions.Variable,
    EnumTypeOptions.NoValue
  ];

  let option: ID3DropdownOption;
  if (!value) {
    option = EnumTypeOptions.NoValue;
  } else if (useVariable) {
    option = EnumTypeOptions.Variable;
  } else {
    option = EnumTypeOptions.Value;
  }

  const [editDisabled, setEditDisabled] = useState<boolean>(
    disabled === true || value === undefined
  );

  const onSelect = (selected: ID3DropdownOption) => {
    const use = selected.value.toLowerCase().trim() === "variable";
    setUseVariable(use);

    if (selected.value.toLowerCase().trim() === "novalue") {
      onEnumSubmit({ value: "", label: "NoValue" });
      setEditDisabled(true);
    }

    if (selected.value.toLowerCase().trim() === "value") {
      setEditDisabled(false);
    }

    onTypeSwitch();
  };

  return (
    <div className={classes.grid}>
      <D3Dropdown
        className={className}
        value={option.value}
        options={options}
        onSelect={onSelect}
        disabled={disabled}
      />

      {useVariable ? (
        <MetadataVariableInput
          value={value.toString()}
          variables={[]}
          onSubmit={onVariableSubmit}
        />
      ) : (
        <D3Dropdown
          className={className}
          value={value ?? ""}
          options={enumOptions}
          onSelect={onEnumSubmit}
          disabled={editDisabled}
        />
      )}
    </div>
  );
};

export default EnumMetadataInput;
