import React from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import {
  dashboardRoute,
  siteStructureRoute,
  previewPageRoute,
  permissionsRoute,
  linkRulesRoute
} from "routes/routes";
import { SITE_STRUCTURE_BASE } from "routes/paths";
import AppContainer from "components/appContainer";
import { ProtectedRoute } from "components/shared/protectedRoute";
import ErrorPage from "components/errorPages";
import { PermissionCodes } from "catalogs/permissionCodes";

const AppContent = (View) => (props: RouteComponentProps<any>) => {
  return (
    <AppContainer>
      <View {...props} />
    </AppContainer>
  );
};

const App = () => {
  return (
    <Switch>
      <Route
        exact
        path={dashboardRoute.path}
        component={AppContent(dashboardRoute.component)}
      />

      <Route
        exact
        path={previewPageRoute.path}
        component={previewPageRoute.component}
      />

      <Route
        exact
        path={SITE_STRUCTURE_BASE}
        render={() => <Redirect to={`${SITE_STRUCTURE_BASE}/~`} />}
      />

      <ProtectedRoute
        path={permissionsRoute.path}
        permissions={[PermissionCodes.ViewAuthorizationGroups]}
        component={AppContent(permissionsRoute.component)}
      />

      <ProtectedRoute
        path={linkRulesRoute.path}
        permissions={[PermissionCodes.ViewNavigationRules]}
        component={AppContent(linkRulesRoute.component)}
      />

      {/* Redirect when a path is uppercase */}
      <Route
        sensitive
        path="/:slug1*:slug2([A-Z]):slug3*"
        render={(props) => (
          <Redirect
            to={`${props.location.pathname.toLowerCase()}${
              props.location.search
            }`}
          />
        )}
      />

      <ProtectedRoute
        path={siteStructureRoute.path}
        permissions={[PermissionCodes.ViewSiteStructure]}
        component={AppContent(siteStructureRoute.component)}
      />

      <Route
        exact
        path="*"
        component={AppContent(<ErrorPage error="not found" />)}
      />
    </Switch>
  );
};

export default App;
