import { PermissionCodes } from "catalogs/permissionCodes";
import {
  AssetTypes,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { userService } from "services/userService";

export const canEditSidebarProperties = (
  element: SiteItemElement,
  path: string
) => {
  switch (element.moduleType) {
    case AssetTypes.TEMPLATE:
    case AssetTypes.MENU:
      return (
        userService.hasPermissions(PermissionCodes.EditContent) ||
        userService.hasSiteItemPermissions(path)
      );
    case AssetTypes.MODULE:
    case AssetTypes.LAYOUT:
      return (
        userService.hasSiteItemPermissions(path) &&
        userService.hasElementPermission(element.moduleType, element.key)
      );
    default:
      return false;
  }
};
