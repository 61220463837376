import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "./styles";
import { isEmpty, last } from "lodash";
import { useContext, useMemo, useState } from "react";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import { useUrlsAndAliasesContext } from "components/siteStructure/aliases/context";
import { D3Modal } from "components/shared/d3Components";
import {
  IAliasMetadataForm,
  IPathSegment,
  IURLMetadataForm
} from "components/siteStructure/aliases/types";
import {
  getIsEditTranslationDisabled,
  isPathSegmentTranslated
} from "components/siteStructure/aliases/utils";
import UrlAliasInput from "./UrlAliasInput";
import UrlTranslationInput from "./UrlTranslationInput";
import CultureLabel from "./CultureLabel";
import { useSelector } from "react-redux";
import { selectSelectedPage } from "redux/selectors/siteStructureSelectors";

interface IProps extends IBaseProps {
  urlForm: IURLMetadataForm;
  aliasForm: IAliasMetadataForm;
  permissions: string[];
}

export default function UrlEntryComponent(props: IProps) {
  const { permissions, urlForm, aliasForm } = props;
  const [isRestoreModalOpened, setIsRestoreModalOpened] = useState(false);
  const [isAliasRestore, setIsAliasRestore] = useState(false);
  const { culture, pathSegments: allPathSegments } = urlForm;
  const classes = useStyles();

  const currentPage = useSelector(selectSelectedPage);

  const {
    loaders,
    getIsEntryLoading,
    getHasUrlTranslationUpdateForCulture,
    onUnsetTranslation,
    aliasesManagement,
    canUseAliases,
    onUnsetAlias,
    getIsAliasRestoreLoading
  } = useUrlsAndAliasesContext();

  const {
    urlMetadata,
    aliasValueValidationMap,
    onBlurUiOperation,
    onUpdateAliasValue,
    getHasUpdateForCulture: getHasAliasUpdateForCulture
  } = aliasesManagement;

  const isTranslationLoading = getIsEntryLoading(culture);
  const isAliasRestoreLoading = getIsAliasRestoreLoading(culture);
  const isDefault = culture === "*";
  const isAliasDefined = aliasForm.value !== "";

  const ancestorSegments = allPathSegments.slice(0, -1);
  const leafSegment = last(allPathSegments) as IPathSegment;

  const currentSiteItemPath = useContext(CurrentSiteItemContext);
  const { onUpdateUrlTranslation } = useUrlsAndAliasesContext();

  const hasUrlTranslationUpdateForCulture =
    getHasUrlTranslationUpdateForCulture(culture);

  const hasAliasUpdateForCulture = getHasAliasUpdateForCulture(culture);

  const isEditDisabled = getIsEditTranslationDisabled(
    leafSegment,
    permissions,
    currentSiteItemPath
  );

  const shouldShowUrlRestoreAction =
    !hasUrlTranslationUpdateForCulture &&
    !isTranslationLoading &&
    !isDefault &&
    !isEditDisabled &&
    isPathSegmentTranslated(leafSegment);

  const shouldShowAliasRestoreAction =
    !hasAliasUpdateForCulture && !isAliasRestoreLoading && isAliasDefined;

  const shouldShowEditLeafField = !isDefault && !isEditDisabled;

  const closeRestoreModal = () => {
    setIsAliasRestore(false);
    setIsRestoreModalOpened(false);
  };
  const onConfirmRestore = () => {
    closeRestoreModal();

    if (isAliasRestore) onUnsetAlias(culture);
    else onUnsetTranslation(culture);
  };

  const onCancelRestore = () => closeRestoreModal();

  const validation = aliasValueValidationMap[culture];

  const shouldShowAliasDynamicParameters =
    canUseAliases && isAliasDefined && !isEmpty(urlMetadata.paramNames);

  const onRestore = (isAlias: boolean) => {
    setIsAliasRestore(isAlias);
    setIsRestoreModalOpened(true);
  };

  const restoreConfirmationTextKey = useMemo(() => {
    if (!isAliasRestore) {
      return "urlspage.restoreconfirmationtext";
    }

    const lastSegment = urlForm.pathSegments[urlForm.pathSegments.length - 1];
    const translation = lastSegment.translationValue;
    const hasTranslationFallback =
      translation !== undefined && translation.length !== 0;

    if (!hasTranslationFallback) {
      return `urlspage.alias.restoreconfirmationtext`;
    }

    return `urlspage.alias.reactivateurlconfirmationtext`;
  }, [isAliasRestore, urlForm.pathSegments]);

  return (
    <div className={classes.container}>
      <CultureLabel culture={culture} isDefault={isDefault} />
      <UrlTranslationInput
        editable={shouldShowEditLeafField}
        culture={culture}
        isDefault={isDefault}
        loaders={loaders}
        loading={isTranslationLoading}
        leaf={leafSegment}
        ancestors={ancestorSegments}
        showRestore={shouldShowUrlRestoreAction}
        onRestore={onRestore}
        onUpdate={onUpdateUrlTranslation}
      />

      <UrlAliasInput
        hidden={!canUseAliases}
        culture={culture}
        form={aliasForm}
        loading={isAliasRestoreLoading}
        showRestore={shouldShowAliasRestoreAction}
        showParameters={shouldShowAliasDynamicParameters}
        validation={validation}
        onUpdate={onUpdateAliasValue}
        onRestore={onRestore}
        onBlur={onBlurUiOperation}
      />

      <D3Modal
        open={isRestoreModalOpened}
        modalTitle={translate("urlspage.restoreconfirmationtitle")}
        onConfirm={onConfirmRestore}
        onCancel={onCancelRestore}
        cancelLabel={translate("urlspage.restoreconfirmationcancel")}
        confirmLabel={translate("urlspage.restoreconfirmationconfirm")}
      >
        <Typography>
          {translate(restoreConfirmationTextKey, {
            culture,
            pageName: currentPage?.name ?? ""
          })}
        </Typography>
      </D3Modal>
    </div>
  );
}
