import React from "react";
import classnames from "classnames";
import { makeStyles, TableBody } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ChildrenListRow } from "./childrenListRow";
import { ISiteItem } from "models/siteItem";

interface IProps extends IBaseProps {
  children: ISiteItem[];
  onRowClick?: (node: ISiteItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const ChildrenListBody = (props: IProps) => {
  const { className, children, onRowClick } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <TableBody className={rootClassName}>
      {children.map((childNode) => (
        <ChildrenListRow
          key={childNode.nodeId}
          node={childNode}
          onRowClick={onRowClick}
        />
      ))}
    </TableBody>
  );
};
