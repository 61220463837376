import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch } from "react-redux";
import {
  publishSiteItems,
  unpublishSiteItems
} from "redux/actions/siteStructureActions";
import { SiteItemIdentifier } from "models/siteItem";
import { noop } from "utils/typescriptUtils";
import { StagedSiteItem } from "models/directories";
import { SiteNodeTypes, StageAction } from "models/siteStructure";

export type StagingContextShape = {
  stagedId: string;
  selected: string[];
  toggle: (node: StagedSiteItem) => void;
  selectMany: (nodeIds: StagedSiteItem[]) => void;
  unselectAll: () => void;
  confirm: (stageAction: StageAction) => void;
};

export const StagingContext = createContext<StagingContextShape>({
  stagedId: "",
  selected: [],
  toggle: noop,
  selectMany: noop,
  unselectAll: noop,
  confirm: noop
});

type Props = {
  stagedId: string;
};

export const StagingContextProvider = (props: PropsWithChildren<Props>) => {
  const { children, stagedId } = props;

  const dispatch = useDispatch();

  const stagedItem: SiteItemIdentifier = useMemo(
    () => ({
      nodeId: stagedId,
      nodeType: SiteNodeTypes.DIRECTORY
    }),
    [stagedId]
  );

  const [selected, setSelected] = useState<SiteItemIdentifier[]>([stagedItem]);
  const selectedIds = useMemo(() => selected.map((n) => n.nodeId), [selected]);

  useEffect(() => {
    setSelected([stagedItem]);
  }, [stagedItem]);

  const clear = () => setSelected([stagedItem]);

  const contextValue: StagingContextShape = {
    stagedId,
    selected: selectedIds,
    toggle: (item) => {
      const itemIndex = selectedIds.indexOf(item.nodeId);
      if (itemIndex < 0) {
        setSelected([...selected, item]);
      } else {
        const withoutItem = [
          ...selected.slice(0, itemIndex),
          ...selected.slice(itemIndex + 1)
        ];
        setSelected(withoutItem);
      }
    },
    selectMany: setSelected,
    unselectAll: clear,
    confirm: (stageAction: StageAction) => {
      const action =
        stageAction === "Publish" ? publishSiteItems : unpublishSiteItems;
      dispatch(action({ items: selected }));
      clear();
    }
  };

  return (
    <StagingContext.Provider value={contextValue}>
      {children}
    </StagingContext.Provider>
  );
};
