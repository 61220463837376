import classnames from "classnames";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps, AutocompleteRenderInputParams {
  loading: boolean;
  error: string | boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: theme.palette.custom.mainAccent.red
  }
}));

export const ContentPickerTagsInputTextField = (props: IProps) => {
  const { className, loading, error, ...params } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <TextField
      {...params}
      className={rootClassName}
      variant="outlined"
      placeholder={translate("contentpicker.taginputplaceholder")}
      helperText={error ? <span className={classes.error}>{error}</span> : ""}
      InputProps={{
        ...params.InputProps,
        endAdornment: loading ? <CircularProgress size={24} /> : <></>
      }}
      inputProps={{
        ...params.inputProps,
        pattern: "\\w"
      }}
    />
  );
};
