import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo
} from "react";
import { find, first, throttle } from "lodash";
import { useContextValue } from "components/hooks";
import {
  calculateScrollerOffsetPlaceholderHeight,
  extractEntityKeyFromPrefixedEntityString,
  getOrderedScrollPayloadEntries,
  getPrefixedEntityString,
  getResizableTrackerWrapper,
  getScrollPayloadEntries
} from "components/linkRules/utils";
import { useSearchManagement } from "components/linkRules/context/searchManagement";

export interface IScrollPayload {
  key: string;
  initialPositionY: number;
}

const PAYLOAD_ENTRY_TOP_OFFSET = 50;
const NAV_BAR_ENTRY_SCROLL_OFFSET = 1.5;

export interface LinkRulesScrollingBehaviorContextValue {
  scrollingSectionRef: React.RefObject<HTMLDivElement>;
  sidebarContainerRef: React.RefObject<HTMLDivElement>;
  currentSelectedEntityKey: string;
  scrollerOffsetPlaceholderHeight: number;
  sidebarWidth: number;
  onResizeTrackerContainerChange: (entries: ResizeObserverEntry[]) => void;
  onResizeSidebarContainerChange: (entries: ResizeObserverEntry[]) => void;
  setSidebarWidth: (width: number) => void;

  onSelectEntityEntry: (entityKey: string) => void;
}

export const LinkRulesScrollingBehaviorContext = createContext(
  {} as LinkRulesScrollingBehaviorContextValue
);

export const LinkRulesScrollingBehaviorContextProvider: React.FC = ({
  children
}) => {
  const scrollingSectionRef = useRef<HTMLDivElement>(null);
  const sidebarContainerRef = useRef<HTMLDivElement>(null);

  const { searchValue } = useSearchManagement();
  const [sidebarWidth, setSidebarWidth] = useState<number>(0);
  const [scrollPayload, setScrollPayload] = useState<IScrollPayload[]>([]);

  const [scrollingSectionScrollTop, setScrollingSectionScrollTop] =
    useState<number>(0);

  const orderedScrollPayload = useMemo(
    () =>
      getOrderedScrollPayloadEntries(scrollPayload, PAYLOAD_ENTRY_TOP_OFFSET),
    [scrollPayload]
  );

  const currentSelectedEntityKey = useMemo(() => {
    const currentSelectedEntry = find(
      orderedScrollPayload,
      (entry) => entry.initialPositionY < scrollingSectionScrollTop
    );

    if (currentSelectedEntry === undefined) {
      return "";
    }

    const resultKey = currentSelectedEntry.key;

    return resultKey ?? "";
  }, [orderedScrollPayload, scrollingSectionScrollTop]);

  const onHandleScroll = throttle((e: Event) => {
    const element = e.target as HTMLDivElement;
    const scrollTop = element.scrollTop;

    setScrollingSectionScrollTop(scrollTop);
  }, 300);

  const onHandleScrollEnd = () => {};

  const onSelectEntityEntry = useCallback(
    (prefixedEntityKey: string) => {
      const entityKey =
        extractEntityKeyFromPrefixedEntityString(prefixedEntityKey);

      if (scrollingSectionRef.current === null) {
        return;
      }

      const element = scrollingSectionRef.current;

      const firstElementPositionY = first(scrollPayload)?.initialPositionY ?? 0;
      const scrollPayloadEntry = find(scrollPayload, { key: entityKey });

      if (scrollPayloadEntry === undefined) {
        return;
      }

      element.scrollTo({
        top: scrollPayloadEntry.initialPositionY - firstElementPositionY
      });
    },
    [scrollPayload]
  );

  const scrollerOffsetPlaceholderHeight = useMemo(() => {
    if (scrollPayload.length === 0) {
      return 0;
    }

    return calculateScrollerOffsetPlaceholderHeight(
      scrollingSectionRef.current
    );
  }, [scrollPayload]);

  const focusSelectedEntityKey = useCallback((currentSelectedEntityKey) => {
    const navEntryItemElement = document.getElementById(
      getPrefixedEntityString(currentSelectedEntityKey)
    ) as HTMLDivElement;

    if (navEntryItemElement === null) {
      return;
    }

    const scrollContainerElement =
      navEntryItemElement.parentElement?.parentElement;

    if (!scrollContainerElement) {
      return;
    }

    const entryElementHeight = navEntryItemElement.offsetHeight;

    const scrollContainerHeight =
      scrollContainerElement.offsetHeight -
      entryElementHeight * NAV_BAR_ENTRY_SCROLL_OFFSET;
    const navEntryElementPosition =
      Math.floor(navEntryItemElement.offsetTop / scrollContainerHeight) *
      scrollContainerHeight;

    scrollContainerElement.scrollTo({
      behavior: "smooth",
      top: navEntryElementPosition
    });
  }, []);

  const attachScrollListeners = useCallback(() => {
    const scrollingSectionDOMElement = scrollingSectionRef.current;

    if (scrollingSectionDOMElement === null) {
      return;
    }

    scrollingSectionDOMElement.addEventListener("scroll", onHandleScroll);
    scrollingSectionDOMElement.addEventListener("scrollend", onHandleScrollEnd);

    return () => {
      scrollingSectionDOMElement.removeEventListener("scroll", onHandleScroll);
      scrollingSectionDOMElement.removeEventListener(
        "scrollend",
        onHandleScrollEnd
      );
    };
  }, [scrollingSectionRef, onHandleScroll]);

  useEffect(() => attachScrollListeners(), [attachScrollListeners]);

  useEffect(
    () => focusSelectedEntityKey(currentSelectedEntityKey),
    [focusSelectedEntityKey, currentSelectedEntityKey]
  );

  const resyncScrollPayload = useCallback(
    (resizableTrackerWrapper: Element) => {
      const updatedScrollPayloadEntries = getScrollPayloadEntries(
        resizableTrackerWrapper
      );

      setScrollPayload(updatedScrollPayloadEntries);
    },
    []
  );

  const onResizeTrackerContainerChange = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const [entry] = entries;
      const resizableTrackerWrapper = entry.target;
      resyncScrollPayload(resizableTrackerWrapper);
    },
    [resyncScrollPayload]
  );

  const onResizeSidebarContainerChange = useCallback(
    (entries: ResizeObserverEntry[]) => {
      const [entry] = entries;
      const sidebarContainer = entry.target;

      setSidebarWidth(sidebarContainer.clientWidth);
    },
    []
  );

  useEffect(() => {
    if (scrollingSectionRef.current === null) {
      return;
    }

    const resizableTrackerWrapper = getResizableTrackerWrapper(
      scrollingSectionRef.current
    );

    resyncScrollPayload(resizableTrackerWrapper);
  }, [searchValue, resyncScrollPayload]);

  const contextValue = useContextValue<LinkRulesScrollingBehaviorContextValue>({
    currentSelectedEntityKey,
    scrollingSectionRef,
    sidebarContainerRef,
    scrollerOffsetPlaceholderHeight,
    sidebarWidth,
    onResizeTrackerContainerChange,
    onResizeSidebarContainerChange,
    onSelectEntityEntry,
    setSidebarWidth
  });

  return (
    <LinkRulesScrollingBehaviorContext.Provider value={contextValue}>
      {children}
    </LinkRulesScrollingBehaviorContext.Provider>
  );
};

export const useLinkRulesScrollingManagementContext =
  (): LinkRulesScrollingBehaviorContextValue => {
    const context = useContext(LinkRulesScrollingBehaviorContext);
    return context;
  };
