export type SystemLanguage = {
  code: string;
  name: string;
};

export const NEUTRAL_LANGUAGE_CODE = "nd-nd";
export const NEUTRAL_LANGUAGE_NAME = "Neutral";
export const NEUTRAL_LANGUAGE: SystemLanguage = {
  code: NEUTRAL_LANGUAGE_CODE,
  name: NEUTRAL_LANGUAGE_NAME
};
