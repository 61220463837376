import { IMenuSidebar } from "models/menus";
import { ILayout, IModule, ITemplate } from "models/pages";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";

export type AssetType = "Module" | "Layout" | "Template" | "Menu";
export class AssetTypes {
  static readonly MODULE = "Module";
  static readonly LAYOUT = "Layout";
  static readonly TEMPLATE = "Template";
  static readonly MENU = "Menu";
}

export type SiteItemElement = IModule | ILayout | ITemplate | IMenuSidebar;

export type PageElement = IModule | ILayout | ITemplate;
export type PageElementDefinition =
  | IModuleDefinition
  | ILayoutDefinition
  | ITemplateDefinition;
