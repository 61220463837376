import { put, take } from "redux-saga/effects";
import { Action, createAction } from "redux-actions";
import { AddSitePage } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CreatePagePayload } from "redux/actions/typings/siteStructureActions";

export function* createPage(action: Action<CreatePagePayload>) {
  try {
    const { path, siteAssetsContextName, onSuccess } = action.payload;
    const command = new AddSitePage({ path, siteAssetsContextName });

    const commandSuccess = createAction(
      "command-" + command.bodyObject.commandId
    );

    const commandAction = issueCommand({
      command,
      onSuccessAction: commandSuccess(),
      errorMessage: "notification.createpage.error",
      successMessage: "notification.createpage.success",
      toggleNotification: true
    });

    yield put(commandAction);
    yield take(commandSuccess.toString());

    onSuccess && onSuccess();
  } catch (error) {
    console.error(error);
  }
}
