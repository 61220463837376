import { makeStyles, InputBase, CircularProgress } from "@material-ui/core";
import { useUpdateAuthGroup } from "api/queryHooks";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { IAuthorizationGroup } from "models/authorizationGroup";
import { useState, memo } from "react";
import { batch, useDispatch } from "react-redux";
import { updateAuthorizationGroup } from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { authorizationHeader } from "theme/commonClasses";
import { translate } from "utils/i18n";

const useStyles = makeStyles(() => authorizationHeader);

interface IProps extends IBaseProps {
  authorizationGroup: IAuthorizationGroup;
}

export function AuthorizationHeader(props: IProps) {
  const { authorizationGroup } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const request = useUpdateAuthGroup();
  const [description, setDescription] = useState(
    authorizationGroup.description
  );

  const [descMandatory, setDescMandatory] = useState(false);

  const enterKey = "Enter";

  const handleDescriptionChange = (descValue: string, checkSend: boolean) => {
    const isDescriptionEmpty = descValue === "";
    setDescription(descValue);
    setDescMandatory(isDescriptionEmpty);

    if (checkSend && !isDescriptionEmpty) {
      handleUpdate(description);
    }
  };

  const descClasses = classnames(classes.desc, {
    [classes.mandatory]: descMandatory
  });

  const handleUpdate = (newDescription: string) => {
    if (newDescription !== authorizationGroup.description) {
      const updatedAuthGroup = {
        ...authorizationGroup,
        description: newDescription
      };
      request.mutate(updatedAuthGroup, {
        onSuccess() {
          batch(() => {
            dispatch(updateAuthorizationGroup(updatedAuthGroup));
            dispatch(
              displayNotification({
                message: "notification.editauthgroup.success",
                severity: "success"
              })
            );
          });
        },
        onError() {
          dispatch(
            displayNotification({
              message: "notification.editauthgroup.error",
              severity: "success"
            })
          );
        }
      });
    }
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === enterKey && !descMandatory) {
      handleUpdate(description);
    }
  };

  const InputTitle = memo(({ name }: { name: string }) => (
    <InputBase
      className={classes.name}
      defaultValue={name}
      readOnly
      key={name}
    />
  ));

  return (
    <header className={classes.root}>
      <div className={classes.formWrapper}>
        <InputTitle name={authorizationGroup.name} />
        <InputBase
          key={authorizationGroup.description}
          onBlur={(event) => handleDescriptionChange(event.target.value, true)}
          className={descClasses}
          defaultValue={authorizationGroup.description}
          onKeyDown={handleKeyDown}
          onChange={(event) =>
            handleDescriptionChange(event.target.value, false)
          }
          placeholder={translate(
            "permissions.content.create.descriptionplaceholder"
          )}
        />
      </div>
      {request.isLoading && (
        <CircularProgress className={classes.loading} size={24} />
      )}
    </header>
  );
}
