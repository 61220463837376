import React, { useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { find } from "lodash";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import type { FilterOptionsState } from "@material-ui/lab";

import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";

const useStyles = makeStyles((theme) => ({
  root: {},
  autoCompletePopper: {
    "& li": {
      padding: theme.spacing(1)
    }
  }
}));

/**
 * Key-Value pair representing a suggest-able option
 */
export interface ID3AutoCompleteInputOption {
  /**
   * Underlying value of the option
   */
  value: string;

  /**
   * Displayed value of the option
   */
  label: string;
}

/**
 * Props of `D3AutoCompleteInput`
 */
export interface ID3AutoCompleteInputProps extends IBaseProps {
  /**
   * Initial value of the `D3AutoCompleteInput`
   */
  value?: ID3AutoCompleteInputOption;

  /**
   * List of options of the `ID3AutoCompleteInputOption`
   */
  options: ID3AutoCompleteInputOption[];

  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;

  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * Determine if the input is disabled or not
   */
  disabled?: boolean;
  error?: boolean;
  shouldAutocompleteOption?: boolean;
  autocompletePopperClassname?: string;
  helperText?: string;
  size?: "small" | "medium";
  /**
   * The function being executed on option selection or input focus loss
   */
  onSubmit?: (selected: ID3AutoCompleteInputOption) => void;
  onBlur?: (selected: ID3AutoCompleteInputOption) => void;
  onInputChange?: (selected: ID3AutoCompleteInputOption) => void;
  filterOptions?: (
    options: ID3AutoCompleteInputOption[],
    state: FilterOptionsState<ID3AutoCompleteInputOption>
  ) => ID3AutoCompleteInputOption[];
}

/**
 * Deltatre AutoComplete Input
 *
 * @component
 * @param ID3AutoCompleteInputProps props
 */
export const D3AutoCompleteInput = (props: ID3AutoCompleteInputProps) => {
  const {
    value,
    options,
    autocompletePopperClassname,
    onSubmit,
    onInputChange,
    onBlur,
    filterOptions,
    className,
    placeholder,
    disabled = false,
    fullWidth = false,
    error = false,
    shouldAutocompleteOption = true,
    helperText = "",
    size = "small"
  } = props;

  const classes = useStyles();

  const rootClassName = classnames(classes.root, className);

  const autocompletePopperClassnames = classnames(
    classes.autoCompletePopper,
    autocompletePopperClassname
  );

  const [text, setText] = useState<string>(value?.label ?? "");

  const onHandleBlur = () => {
    const option =
      options.find((o) => o.label === text) ?? toAutoCompleteInputOption(text);

    if (onBlur) {
      onBlur(option);
      return;
    }

    if (onSubmit) {
      onSubmit(option);
    }
  };

  const handleOptionSelection = (
    newValue: NonNullable<string | ID3AutoCompleteInputOption>
  ) => {
    if (typeof newValue === "object") {
      const option =
        options.find((o) => o.value === newValue.value) ??
        toAutoCompleteInputOption(newValue.label);

      if (onSubmit) {
        onSubmit(option);
      }
      setText(newValue.label);
    }
  };

  const onChange = (value: string) => {
    setText(value);

    if (onInputChange) {
      const option =
        find(options, { label: value }) ?? toAutoCompleteInputOption(value);

      onInputChange(option);
    }
  };

  const input = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeholder}
      variant="outlined"
      value={text}
      error={error}
      helperText={helperText}
      size={size}
    />
  );

  const optionalProps = {
    autoComplete: Boolean(shouldAutocompleteOption)
  };

  return (
    <Autocomplete
      {...optionalProps}
      autoHighlight
      clearOnEscape
      freeSolo
      disableClearable
      handleHomeEndKeys
      fullWidth={fullWidth}
      className={rootClassName}
      onChange={(_event, newValue) => handleOptionSelection(newValue)}
      onInputChange={(_event, value) => onChange(value)}
      onBlur={onHandleBlur}
      options={options}
      value={value}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.label}
      renderInput={input}
      disabled={disabled}
      ListboxProps={{
        className: `${autocompletePopperClassnames} MuiAutocomplete-listbox`
      }}
    ></Autocomplete>
  );
};

export const toAutoCompleteInputOption = (
  value: string
): ID3AutoCompleteInputOption => ({
  value,
  label: value
});

export default D3AutoCompleteInput;
