import { SiteItemClipboardContext } from "components/contexts/siteItemClipboardContext";
import { ISiteItem } from "models/siteItem";
import { useContext } from "react";
import { userService } from "services/userService";

type ClipboardHookReturn = [boolean, () => void];
type ClipboardPasteHookReturn = [boolean, boolean, () => void];

export function useCopy(node: ISiteItem): ClipboardHookReturn {
  const clipboardContext = useContext(SiteItemClipboardContext);
  const canCopy = userService.hasSiteItemPermissions(node.path);
  const copy = () => clipboardContext.copy(node);

  return [canCopy, copy];
}

export function useCut(node: ISiteItem): ClipboardHookReturn {
  const clipboardContext = useContext(SiteItemClipboardContext);

  const canCut = userService.hasSiteItemPermissions(node.path);
  const cut = () => clipboardContext.cut(node);

  return [canCut, cut];
}

export function usePaste(node: ISiteItem): ClipboardPasteHookReturn {
  const clipboardContext = useContext(SiteItemClipboardContext);
  const canCopy = userService.hasSiteItemPermissions(node.path);
  const canCut = userService.hasSiteItemPermissions(node.path);
  const canPaste = (canCopy || canCut) && clipboardContext.canPasteOn(node);

  const paste = () => clipboardContext.paste(node.nodeId);
  const showConfirmPasteModal = clipboardContext.showConfirmPasteModal();

  return [canPaste, showConfirmPasteModal, paste];
}
