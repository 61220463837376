import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { StageIcon } from "components/shared/stageIcon";

interface IProps extends IBaseProps {
  text: string;
  publish: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  stage: {
    marginRight: theme.spacing(1)
  },
  text: {
    fontSize: theme.typography.pxToRem(16)
  }
}));

export const HistoryGroupHeader = (props: IProps) => {
  const { className, publish, text } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      {publish && (
        <StageIcon className={classes.stage} stage="published" size="small" />
      )}
      <Typography className={classes.text} variant="h6">
        {text}
      </Typography>
    </div>
  );
};
