import { ICommand } from "@d3-forge/forge-commands";
import { ApiClient } from "api/apiClient";
import { CommandResult } from "models/commandResult";

const BASE_URL = "api/command";

export class CommandApi {
  static readonly sendCommand = async (
    command: ICommand
  ): Promise<CommandResult> => {
    const endpoint = BASE_URL;
    return ApiClient.post(endpoint, command);
  };
}
