import { AssetCatalogApi } from "api/assetCatalog";
import {
  fetchContextsSucceded,
  fetchContextsFailed
} from "redux/actions/assetCatalogActions";
import { put, call } from "redux-saga/effects";

export function* getContexts() {
  try {
    const contexts = yield call(AssetCatalogApi.getContexts);
    yield put(fetchContextsSucceded(contexts));
  } catch (error) {
    yield put(fetchContextsFailed(error));
  }
}
