import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: theme.typography.pxToRem(32)
  },
  header: {
    display: "flex",
    border: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    borderRadius: theme.spacing(0.25),
    height: theme.typography.pxToRem(32),
    backgroundColor: theme.palette.common.white,
    cursor: "pointer",
    "&$expanded": {
      borderRadius: theme.spacing(0.25, 0.25, 0, 0),
      borderBottom: "none"
    }
  },
  headerFixedPart: {
    width: theme.typography.pxToRem(24),
    padding: theme.spacing(0.5),
    "&:active": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr300
    }
  },
  expandIcon: {
    transition: theme.transitions.create("transform"),
    color: theme.palette.custom.ds.grayscales.gr700,
    "&$expanded": {
      transform: "rotate(-180deg)"
    }
  },
  headerPart: {
    borderRight: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    flex: 1,
    color: theme.palette.custom.ds.grayscales.gr700,
    paddingTop: theme.spacing(0.75)
  },
  dropdownPanel: {
    overflow: "hidden",
    transition: theme.transitions.create("max-height"),
    maxHeight: "51vh",
    "&:not($expanded)": {
      maxHeight: 0
    }
  },
  textarea: {
    minHeight: "5vh",
    maxHeight: "48vh",
    resize: "vertical",
    overflow: "auto !important"
  },
  errorMessage: {
    color: theme.palette.custom.ds.error.error700,
    fontStyle: "italic",
    paddingTop: theme.spacing(0.25),
    "&:not($invalid)": {
      paddingTop: 0,
      visibility: "hidden"
    }
  },
  expanded: {},
  invalid: {
    borderColor: theme.palette.custom.ds.error.error500,
    transition: "all 0.0s ease 0.3s"
  }
}));
