import { useAddAuthGroup } from "api/queryHooks";
import { CreateHeader } from "components/permissions/create-view/create-header";
import { IAuthorizationGroup } from "models/authorizationGroup";
import { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addAuthorizationGroup,
  setCreateAuthGroupFlag
} from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { selectCreateAuthorizationGroupFlag } from "redux/selectors/authorizationGroupSelectors";
import { PERMISSIONS } from "routes/paths";

export function CreateView() {
  const dispatch = useDispatch();
  const request = useAddAuthGroup();
  const history = useHistory();
  const addNew = useSelector(selectCreateAuthorizationGroupFlag);

  useEffect(() => {
    if (!addNew) {
      history.replace(PERMISSIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (
    form: Pick<IAuthorizationGroup, "name" | "description">
  ) => {
    request.mutate(form, {
      onSuccess(data) {
        batch(() => {
          dispatch(setCreateAuthGroupFlag(false));
          dispatch(addAuthorizationGroup(data));
          dispatch(
            displayNotification({
              message: "notification.addauthgroup.success",
              severity: "success"
            })
          );
        });

        history.replace(`${PERMISSIONS}/${data.groupId}`);
      },
      onError() {
        dispatch(
          displayNotification({
            message: "notification.addauthgroup.error",
            severity: "error"
          })
        );
      }
    });
  };

  return (
    <main>
      <CreateHeader onSubmit={handleSubmit} loading={request.isLoading} />
      <div></div>
    </main>
  );
}
