import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useStyles } from "./styles";
import InfoTooltip from "components/siteStructure/aliases/components/infoTooltip";

export function UrlAliasSectionHeader(props: {
  onCloseButtonClick: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { onCloseButtonClick } = props;

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <UxdIcon className={classes.settingsIcon} name="settings" />
        <Typography className={classes.titleText}>
          {translate("sitestructure.urlssection.section_title")}
        </Typography>
      </div>
      <div className={classes.headerRight}>
        <Tooltip
          arrow={true}
          title={<InfoTooltip className={classes.infoTooltipContainer} />}
        >
          <div className={classes.infoContainer}>
            <IconButton size="small" className={classes.infoIcon}>
              <UxdIcon name="info" outline={true} />
            </IconButton>
            <Typography className={classes.infoDescription}>
              {translate("sitestructure.urlssection.info_question")}
            </Typography>
          </div>
        </Tooltip>
        <IconButton
          size="small"
          onClick={onCloseButtonClick}
          className={classes.closeIcon}
          disableRipple
        >
          <UxdIcon name="close" />
        </IconButton>
      </div>
    </div>
  );
}

export default UrlAliasSectionHeader;
