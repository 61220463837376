import { LastUpdateDateValue } from "components/contentPicker/contentPickerContext";
import moment from "moment";

export function toQueryStringDateTimeRange(
  lastUpdateDate: LastUpdateDateValue
): string {
  switch (lastUpdateDate) {
    case "(any)":
      throw new Error(
        "Last update date value of '(any)' cannot be used to build a query string datetime range"
      );

    case "past-hour":
      return createDateTimeRange((now) => now.subtract(1, "hours"));

    case "past-day":
      return createDateTimeRange((now) => now.subtract(1, "days"));

    case "past-week":
      return createDateTimeRange((now) => now.subtract(1, "weeks"));

    case "past-month":
      return createDateTimeRange((now) => now.subtract(1, "months"));

    case "past-year":
      return createDateTimeRange((now) => now.subtract(1, "years"));

    default:
      throw new Error(`Unknown value for last update date: ${lastUpdateDate}`);
  }
}

function createDateTimeRange(
  transformCurrentDateTimeFunction: (currentDateTime: moment.Moment) => void
) {
  const now = moment();

  const clone = now.clone();
  transformCurrentDateTimeFunction(clone);

  return `$range(${clone.utc().format()},${now.utc().format()})`;
}
