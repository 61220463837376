import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ILayoutDesign } from "models/siteAssets/layoutDefinition";
import { Rows } from "components/siteStructure/template/preview/rows";

interface IProps extends IBaseProps {
  design: ILayoutDesign;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px"
  }
}));

export const Preview = (props: IProps) => {
  const { className, design } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return <Rows className={rootClassName} rows={design.rows} />;
};
