import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import moment from "moment";
import MetadataVariableInput from "./metadataVariableInput";
import { D3Dropdown } from "components/shared/d3Components";
import {
  ID3DropdownOption,
  toDropdownOption
} from "components/shared/d3Components/d3Dropdown";
import { useStyles } from "./metadataInput";
import { isValidVariableFormat } from "utils/variableUtils";

interface IDateMetadataInputProps extends IBaseProps {
  value: string;
  disabled?: boolean;
  onVariableSubmit: (variable: string) => void;
  onDateSubmit: (date: string) => void;
  onTypeSwitch: () => void;
}
export const DateMetadataInput = (props: IDateMetadataInputProps) => {
  const {
    className,
    value,
    onVariableSubmit,
    onDateSubmit,
    onTypeSwitch,
    disabled = false
  } = props;

  const classes = useStyles();
  const [useVariable, setUseVariable] = useState<boolean>(
    isValidVariableFormat(value)
  );

  const onSelect = (selected: ID3DropdownOption) => {
    const use = selected.value.toLowerCase().trim() === "variable";
    setUseVariable(use);
    onTypeSwitch();
  };

  const datetime = moment(value).format("yyyy-MM-DD");
  const options: ID3DropdownOption[] = [
    toDropdownOption("Date"),
    toDropdownOption("Variable")
  ];

  const option = useVariable
    ? toDropdownOption("Variable")
    : toDropdownOption("Date");

  return (
    <div className={classes.grid}>
      <D3Dropdown
        className={className}
        value={option.value}
        options={options}
        onSelect={onSelect}
        disabled={disabled}
      />
      {useVariable ? (
        <MetadataVariableInput
          value={value.toString()}
          variables={[]}
          onSubmit={onVariableSubmit}
        />
      ) : (
        <TextField
          type="date"
          variant="outlined"
          className={className}
          value={datetime}
          onChange={(e) => onDateSubmit(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default DateMetadataInput;
