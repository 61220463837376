import { IDomainState, IRequestMonitorState } from "models/domainStates";
import { REQUEST_ACTION_SUFFIX } from "redux/actions/constants";
import { RequestInstance } from "models/requestMonitor";
import { ActionFunctionAny } from "redux-actions";
import { Action } from "redux";
import { createSelector } from "reselect";

export const matchRequestSuffixRegex = new RegExp(REQUEST_ACTION_SUFFIX, "g");

export const selectRequestMonitor = (state: IDomainState) =>
  state.requestMonitor;

export const makeSelectRequestInstance = () =>
  createSelector(
    selectRequestMonitor,
    (
      _: IDomainState,
      actionCreator: ActionFunctionAny<Action<any>>,
      requestPayload: any
    ) => ({ actionCreator, requestPayload }),
    (requestMonitor, requestData): RequestInstance => {
      const { actionCreator, requestPayload } = requestData;

      const defaultInstance = {
        isLoading: false
      };
      const requestName = actionCreator
        .toString()
        .replace(matchRequestSuffixRegex, "");

      const requestStates = requestMonitor[requestName];
      if (!requestStates) {
        return defaultInstance;
      }

      const payloadString = JSON.stringify(requestPayload);
      const request = requestStates[payloadString];
      if (!request) {
        return defaultInstance;
      }

      return {
        isLoading: request.status === "REQUEST",
        error: request.error
      };
    }
  );

// TODO: Remove
export const getRequestInstance = (
  state: IRequestMonitorState,
  actionCreator: ActionFunctionAny<Action<any>>,
  requestPayload: any
): RequestInstance => {
  const defaultInstance = {
    isLoading: false
  };
  const requestName = actionCreator
    .toString()
    .replace(matchRequestSuffixRegex, "");

  const requestStates = state[requestName];
  if (!requestStates) {
    return defaultInstance;
  }

  const payloadString = JSON.stringify(requestPayload);
  const request = requestStates[payloadString];
  if (!request) {
    return defaultInstance;
  }

  return {
    isLoading: request.status === "REQUEST",
    error: request.error
  };
};
