import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(0.25)
  },
  overrideCount: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(13),
    cursor: "pointer"
  },
  webIcon: {
    position: "relative",
    top: "2px",
    marginLeft: theme.spacing(0.5),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  container: {
    borderRadius: theme.spacing(0.25),
    padding: "0",
    display: "flex",
    minWidth: "55px"
  },
  selectedItem: {
    color: theme.palette.custom.secondaryAccent.tiffany
  }
}));
