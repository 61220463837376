import { ApiClient } from "api/apiClient";
import { IConfiguration } from "models/configuration";

const BASE_URL = "/api/configuration";

export class ConfigurationApi {
  static readonly getConfiguration = (): Promise<IConfiguration> => {
    const endpoint = BASE_URL;
    return ApiClient.get(endpoint);
  };
}
