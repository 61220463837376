import { ISiteItem } from "models/siteItem";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import { Action } from "redux-actions";
import {
  closeCreateModal,
  openCreateModal
} from "redux/actions/siteItem/createSiteItemActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export interface ICreateSiteItemState {
  open: boolean;
  node: ISiteItem | null;
  type: SiteNodeType;
  index?: boolean;
}

export const initialState: ICreateSiteItemState = {
  open: false,
  node: null,
  type: SiteNodeTypes.DIRECTORY
};

const reactions = {
  [openCreateModal.toString()]: (
    state: ICreateSiteItemState,
    action: Action<{ node: ISiteItem; type: SiteNodeType; index?: boolean }>
  ) => {
    state.open = true;
    state.node = action.payload.node;
    state.type = action.payload.type;
    state.index = action.payload.index;

    return state;
  },

  [closeCreateModal.toString()]: (state: ICreateSiteItemState) => {
    state.open = false;
    state.node = null;
    state.index = false;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
