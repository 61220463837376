import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { fetchPage } from "redux/actions/siteStructureActions";
import {
  ComponentImportance,
  SetModuleImportance
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { SetModuleImportancePayload } from "redux/actions/typings/siteStructureActions";

export function* sendSetModuleImportance(
  action: Action<SetModuleImportancePayload>
) {
  try {
    const { pageId, moduleInstanceId, importance } = action.payload;

    const command = new SetModuleImportance({
      pageId,
      moduleInstanceId,
      importance: importance
        ? ComponentImportance.Important
        : ComponentImportance.Normal
    });

    const commandAction = issueCommand({
      command,
      onFailureAction: fetchPage(pageId),
      errorMessage: "notification.setmoduleimportance.error",
      successMessage: "notification.setmoduleimportance.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
