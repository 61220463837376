import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ILayoutColumn } from "models/siteAssets/layoutDefinition";
import { DesignContent } from "./designContent";
import { Rows } from "components/siteStructure/template/preview/rows";

interface IProps extends IBaseProps {
  column: ILayoutColumn;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.custom.greyscales.backgrounds.grey93
  },
  width: (props: IProps) => ({
    maxWidth: `${props.column.width}%`
  }),
  content: {
    flex: 1
  }
}));

export const DesignColumn = (props: IProps) => {
  const { className, column } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(
    classes.root,
    {
      [classes.width]: Boolean(column.width)
    },
    className
  );

  return (
    <div className={rootClassName}>
      {column.elementType === "Fixed" && (
        <DesignContent className={classes.content} />
      )}
      {column.elementType === "NestedRows" && (
        <Rows className={classes.content} rows={column.rows} />
      )}
    </div>
  );
};
