import { IContextModules } from "models/siteAssets/moduleDefinition";
import { ApiClient } from "api/apiClient";
import { IContextLayouts } from "models/siteAssets/layoutDefinition";
import { IContextTemplates } from "models/siteAssets/templateDefinition";
import { IContextDefinition } from "models/siteAssets/contextDefinition";
import { SiteAssetTypes } from "models/siteAssets/siteAssetTypes";

const BASE_URL = "api/siteassets";

export class AssetCatalogApi {
  static readonly getModules = async (): Promise<IContextModules> => {
    const endpoint = `${BASE_URL}/${SiteAssetTypes.MODULE}`;
    return ApiClient.get(endpoint);
  };

  static readonly getLayouts = async (): Promise<IContextLayouts> => {
    const endpoint = `${BASE_URL}/${SiteAssetTypes.LAYOUT}`;
    return ApiClient.get(endpoint);
  };

  static readonly getTemplates = async (): Promise<IContextTemplates> => {
    const endpoint = `${BASE_URL}/${SiteAssetTypes.TEMPLATE}`;
    return ApiClient.get(endpoint);
  };

  static readonly getContexts = async (): Promise<IContextDefinition> => {
    const endpoint = `${BASE_URL}/${SiteAssetTypes.CONTEXT}`;
    return ApiClient.get(endpoint);
  };
}
