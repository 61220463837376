import { call, put, take } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  CreateCustomEntityTagPayload,
  CreateExternalTagPayload,
  SetTagsPickerPropertyPayload
} from "redux/actions/typings/tagsActions";
import { TagSuggestion } from "models/forgeEntities";
import { TagsApi } from "api/tagsApi";
import {
  createExternalTag,
  createCustomEntityTag
} from "redux/actions/tagsActions";
import uuid from "utils/uuid";

export function* sendSetTagsPickerProperty(
  action: Action<SetTagsPickerPropertyPayload>
) {
  try {
    const {
      dataSourceName,
      dataSourceId,
      dataSourcePath,
      extradata,
      title,
      label,
      slug,
      isExternalTag,
      actionId,
      onSuccess
    } = action.payload;
    let fetchedTag: TagSuggestion | null = yield call(
      isExternalTag ? TagsApi.getExternalTag : TagsApi.getCustomEntityTag,
      dataSourceName,
      dataSourceId
    );

    if (!fetchedTag) {
      if (isExternalTag) {
        const payload: CreateExternalTagPayload = {
          dataSourceName,
          dataSourceId,
          dataSourcePath: dataSourcePath || null,
          extradata,
          title,
          label,
          slug,
          actionId: actionId ?? uuid.generate(),
          translationId: uuid.generate()
        };
        yield put(createExternalTag(payload));

        yield take(`CREATE_EXTERNAL_TAG_SUCCEDED_${payload.actionId}`);
      } else {
        const payload: CreateCustomEntityTagPayload = {
          dataSourceName,
          dataSourceId,
          extradata,
          title,
          label,
          slug,
          actionId: actionId ?? uuid.generate(),
          translationId: uuid.generate()
        };
        yield put(createCustomEntityTag(payload));

        yield take(`CREATE_CUSTOM_ENTITY_TAG_SUCCEDED_${payload.actionId}`);
      }

      fetchedTag = yield call(
        isExternalTag ? TagsApi.getExternalTag : TagsApi.getCustomEntityTag,
        dataSourceName,
        dataSourceId
      );
    }

    if (!fetchedTag) {
      throw new Error(`Expected tag but not found`);
    }

    onSuccess && onSuccess(fetchedTag?.slug);
  } catch (error) {
    console.error(error);
  }
}
