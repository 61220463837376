import React, { useContext, useState, MouseEvent, useEffect } from "react";
import classnames from "classnames";
import { makeStyles, Menu, MenuItem, Zoom } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import SelectPageElementModal from "components/siteStructure/modals/selectPageElementModal";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { D3Button } from "components/shared/d3Components";

interface IProps extends IBaseProps {
  pageContext: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 5, 5, 0),
    height: theme.spacing(7),
    width: theme.spacing(7)
  }
}));

type DialogData = {
  open: boolean;
  pageElementType: "Module" | "Layout";
};

const isAddButtonElement = (element: HTMLElement) =>
  element.id === "add-page-item";

export const AddPageElementFab = (props: IProps) => {
  const { className, pageContext } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogData, setDialogData] = useState<DialogData>({
    open: false,
    pageElementType: "Module"
  });

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openModuleDialog = () => {
    setDialogData({
      open: true,
      pageElementType: "Module"
    });
  };

  const openLayoutDialog = () => {
    setDialogData({
      open: true,
      pageElementType: "Layout"
    });
  };

  const closeDialog = () => {
    setDialogData({ ...dialogData, open: false });
  };

  const selectPageElement = (e: MouseEvent, key: string) => {
    e.stopPropagation();

    dropContext.select("add", { key, type: dialogData.pageElementType });
    closeDialog();
  };

  const hideFab =
    dropContext.dropMode !== "none" && copyContext.copyMode !== "none";

  useEffect(() => {
    if (anchorEl !== null) {
      return;
    }

    const currentFocusedElement = document.activeElement as HTMLElement;

    if (
      currentFocusedElement !== null ||
      isAddButtonElement(currentFocusedElement)
    ) {
      currentFocusedElement.blur();
    }
  }, [anchorEl]);

  return (
    <>
      <Zoom in={!hideFab} timeout={300} unmountOnExit>
        <D3Button
          id="add-page-item"
          icon="add"
          onClick={openMenu}
          className={rootClassName}
        />
      </Zoom>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClick={closeMenu}
        onClose={closeMenu}
      >
        <MenuItem onClick={openModuleDialog}>
          {translate("sitestructure.module")}
        </MenuItem>
        <MenuItem onClick={openLayoutDialog}>
          {translate("sitestructure.layout")}
        </MenuItem>
      </Menu>
      <SelectPageElementModal
        open={dialogData.open}
        pageElementType={dialogData.pageElementType}
        pageContext={pageContext}
        onClose={closeDialog}
        onSelect={selectPageElement}
      />
    </>
  );
};
