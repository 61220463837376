import { Batch } from "@d3-forge/forge-commands";
import { useCommand } from "api/queryHooks";
import { useMemoSelector } from "components/hooks";
import {
  IAliasManagement,
  useAliases
} from "components/siteStructure/aliases/hooks/useAliases";
import {
  IUrlTranslationManagement,
  useUrlTranslations
} from "components/siteStructure/aliases/hooks/useUrlTranslations";
import {
  IURLMetadataForm,
  SegmentTranslationCommandLoaders
} from "components/siteStructure/aliases/types";

import { first, isEmpty, xor } from "lodash";
import { EMPTY_ROOT_ITEM, ISiteItem } from "models/siteItem";
import { SiteNodeTypes } from "models/siteStructure";
import React, {
  useContext,
  useState,
  createContext,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFrontendCultures } from "redux/selectors/frontendSelectors";
import { makeSelectSiteItemByPath } from "redux/selectors/siteStructureSelectors";

export interface UrlsAndAliasesContextValue {
  urlMetadataForms: IURLMetadataForm[];
  currentSiteItem: ISiteItem;
  isOverlayPortalOpened: boolean;
  loaders: SegmentTranslationCommandLoaders;
  isSavePromptOpened: boolean;
  setIsSavePromptOpened: (val: boolean) => void;
  getIsEntryLoading: (culture: string) => boolean;
  getHasUrlTranslationUpdateForCulture: (culture: string) => boolean;
  onUpdateUrlTranslation: (culture: string, value: string) => void;
  toggleOverlayPortal: () => void;
  onCloseUrlsAndAliases: () => void;
  onUnsetTranslation: (culture: string) => void;
  onSaveSettings: () => void;
  onUnsetAlias: (culture: string) => void;
  areThereBatchedUpdates: boolean;
  aliasesManagement: IAliasManagement;
  urlTranslationManagement: IUrlTranslationManagement;
  canUseAliases: boolean;
  hasAliasesSet: boolean;
  getIsAliasRestoreLoading: (culture: string) => boolean;
}

export const UrlsAndAliasesContext = createContext(
  {} as UrlsAndAliasesContextValue
);

export const UrlsAndAliasesContextProvider: React.FC = ({ children }) => {
  const { path } = useParams<{ path: string }>();
  const [allCultures, setAllCultures] = useState<string[]>([]);
  const saveSettings = useCommand("save-settings", {
    errorMessage: "notification.set_urls_and_aliases.error",
    successMessage: "notification.set_urls_and_aliases.success"
  });

  const unsetTranslate = useCommand("unset-translation", {
    errorMessage: "notification.unsetranslation.error",
    successMessage: "notification.unsetranslation.success"
  });

  const unsetAlias = useCommand("unset-alias", {
    errorMessage: "notification.unset_alias.error",
    successMessage: "notification.unset_alias.success"
  });

  const [isOverlayPortalOpened, setIsOverlayPortalOpened] =
    useState<boolean>(false);

  const [isSavePromptOpened, setIsSavePromptOpened] = useState<boolean>(false);

  const toggleOverlayPortal = () => {
    setIsOverlayPortalOpened((current) => !current);
  };

  const onCloseUrlsAndAliases = () => {
    setIsOverlayPortalOpened(false);
  };

  const availableCultures = useSelector(selectFrontendCultures);
  const currentSiteItem =
    useMemoSelector(makeSelectSiteItemByPath, path) || EMPTY_ROOT_ITEM;

  const canUseAliases = useMemo(
    () => currentSiteItem.nodeType === SiteNodeTypes.PAGE,
    [currentSiteItem.nodeType]
  );

  useEffect(() => {
    const diffedCultures = xor(allCultures, availableCultures);
    const hasAllCulturesBeenUpdated =
      diffedCultures.length === 1 && first(diffedCultures) === "*";

    if (hasAllCulturesBeenUpdated) {
      return;
    }

    setAllCultures(["*"].concat(availableCultures));
  }, [availableCultures, allCultures]);

  const [aliasCultureBeingRestored, setAliasCultureBeingRestored] = useState<
    string | undefined
  >(undefined);
  const getIsAliasRestoreLoading = useCallback(
    (culture: string) => {
      return culture === aliasCultureBeingRestored;
    },
    [aliasCultureBeingRestored]
  );

  const urlTranslationManagement = useUrlTranslations({
    path,
    currentSiteItem,
    allCultures,
    saveSettings,
    unsetTranslate,
    isOverlayPortalOpened
  });

  const {
    urlMetadataForms,
    urlMetadataFormUpdates,
    getIsEntryLoading,
    onUnsetTranslation,
    onUpdateUrlTranslation,
    getSaveTranslationCommands,
    getHasUpdateForCulture: getHasUrlTranslationUpdateForCulture
  } = urlTranslationManagement;

  const aliasesManagement = useAliases({
    path,
    currentSiteItem,
    allCultures,
    isOverlayPortalOpened,
    isSavePromptOpened,
    setIsSavePromptOpened,
    canUseAliases
  });

  const loaders: SegmentTranslationCommandLoaders = useMemo(
    () => ({
      isSetTranslationLoading: saveSettings.isLoading,
      isUnsetTranslationLoading: unsetTranslate.isLoading
    }),
    [saveSettings.isLoading, unsetTranslate.isLoading]
  );

  const { aliasFormMetadataUpdates } = aliasesManagement;

  const areThereBatchedUpdates =
    !isEmpty(urlMetadataFormUpdates) || !isEmpty(aliasFormMetadataUpdates);

  const onSaveSettings = useCallback(async () => {
    const saveTranslationCommands = getSaveTranslationCommands();
    const saveAliasesCommands = aliasesManagement.getSaveAliasesCommands();

    const allCommands = [...saveTranslationCommands, ...saveAliasesCommands];

    if (isEmpty(allCommands)) {
      return;
    }

    const batchCommand = new Batch({ commands: allCommands });

    try {
      const result = await saveSettings.mutateAsync(batchCommand);

      if (!result.success) {
        throw new Error(result.message);
      }

      aliasesManagement.onSaveSettingsSuccess();
      setIsOverlayPortalOpened(false);
    } catch (err) {}
  }, [saveSettings, getSaveTranslationCommands, aliasesManagement]);

  const onUnsetAlias = useCallback(
    async (culture: string) => {
      setAliasCultureBeingRestored(culture);
      const unsetAliasCommand = aliasesManagement.getUnsetAliasCommand(culture);

      try {
        const result = await unsetAlias.mutateAsync(unsetAliasCommand);

        if (!result.success) {
          setAliasCultureBeingRestored(undefined);
          throw new Error(result.message);
        }
        aliasesManagement.onUnsetAliasSuccess(unsetAliasCommand);
      } catch (err) {
        console.log(err);
      }

      setAliasCultureBeingRestored(undefined);
    },
    [unsetAlias, aliasesManagement]
  );

  const hasAliasesSet = useMemo(
    () =>
      canUseAliases &&
      aliasesManagement.pageUrlDetails.find((p) => p.alias) !== undefined,
    [canUseAliases, aliasesManagement]
  );

  const contextValue: UrlsAndAliasesContextValue = useMemo(
    () => ({
      urlMetadataForms,
      currentSiteItem,
      isOverlayPortalOpened,
      loaders,
      areThereBatchedUpdates,
      aliasesManagement,
      urlTranslationManagement,
      isSavePromptOpened,
      canUseAliases,
      hasAliasesSet,
      onUpdateUrlTranslation,
      toggleOverlayPortal,
      onCloseUrlsAndAliases,
      onUnsetTranslation,
      getIsEntryLoading,
      getHasUrlTranslationUpdateForCulture,
      setIsSavePromptOpened,
      onSaveSettings,
      onUnsetAlias,
      getIsAliasRestoreLoading
    }),
    [
      urlMetadataForms,
      currentSiteItem,
      isOverlayPortalOpened,
      loaders,
      areThereBatchedUpdates,
      aliasesManagement,
      urlTranslationManagement,
      onUnsetTranslation,
      getIsEntryLoading,
      getHasUrlTranslationUpdateForCulture,
      onSaveSettings,
      onUpdateUrlTranslation,
      isSavePromptOpened,
      setIsSavePromptOpened,
      canUseAliases,
      hasAliasesSet,
      onUnsetAlias,
      getIsAliasRestoreLoading
    ]
  );

  return (
    <UrlsAndAliasesContext.Provider value={contextValue}>
      {children}
    </UrlsAndAliasesContext.Provider>
  );
};

export const useUrlsAndAliasesContext = (): UrlsAndAliasesContextValue => {
  const context = useContext(UrlsAndAliasesContext);
  return context;
};
