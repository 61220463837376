import { useDispatch, useSelector } from "react-redux";
import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useState,
  useEffect
} from "react";

import { isEmpty } from "lodash";

import { selectSelectedMenu } from "redux/selectors/siteStructureSelectors";
import { IMenu, IMenuItem } from "models/menus";

import {
  fetchMenu,
  setSelectedMenuItem,
  unsetSelectedMenuItem
} from "redux/actions/siteStructureActions";

import {
  getMenuItemDescendants,
  getMenuItemsPositionMetadataList,
  getPlacementPositionBySegmentAndItemMetadata
} from "components/siteStructure/menuView/utils";
import {
  IMenuItemPlacementModeMetadata,
  IMenuItemPositionMetadata,
  MenuBuilderLoaders,
  PlacementModePurpose,
  PlacementModeSegment
} from "components/siteStructure/menuView/types";

import { useMenuCommands } from "components/siteStructure/menuView/hooks/useMenuCommands";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import { userService } from "services/userService";
import { useContextValue } from "components/hooks";

export interface MenuBuilderContextValue {
  menu: IMenu | null;
  loaders: MenuBuilderLoaders;
  menuItemsPositionMetadataList: IMenuItemPositionMetadata[];
  placementModeMetadata: IMenuItemPlacementModeMetadata | undefined;
  isPlacementModeActive: boolean;
  hasUserPermissionToEditMenu: boolean;
  onMenuItemPlaced: (
    placementSegment: PlacementModeSegment,
    targetItemId: string
  ) => void;
  onSelectedMenuItemForMoving: (pickedItem: IMenuItem) => void;
  onDeleteMenuItemConfirm: (menuItemId: string) => Promise<void>;
  onAddMenuButtonClick: () => void;
  onCancelPlacementMode: () => void;
  updateMenuItem: (itemId: string, updatedItemInfo: IMenuItem) => Promise<void>;
}

export const MenuBuilderContext = createContext({} as MenuBuilderContextValue);

export const MenuBuilderContextProvider: React.FC = ({ children }) => {
  const menu = useSelector(selectSelectedMenu);
  const dispatch = useDispatch();

  const currentSiteItemPath = useContext(CurrentSiteItemContext);

  const [placementModeMetadata, setPlacementModeMetadata] = useState<
    IMenuItemPlacementModeMetadata | undefined
  >(undefined);

  const menuItems = useMemo(() => menu?.items || [], [menu]);
  const hasMenuItemsDefined = useMemo(() => !isEmpty(menuItems), [menuItems]);
  const isPlacementModeActive = useMemo(
    () => placementModeMetadata !== undefined,
    [placementModeMetadata]
  );

  const hasUserPermissionToEditMenu = useMemo(
    () => userService.getHasUserPermissionToEditMenu(currentSiteItemPath),
    [currentSiteItemPath]
  );

  const menuItemsPositionMetadataList = useMemo(
    () => getMenuItemsPositionMetadataList(menuItems),
    [menuItems]
  );

  const refetchMenu = useCallback(() => {
    if (menu === null) {
      return;
    }

    dispatch(fetchMenu(menu.id));
  }, [menu, dispatch]);

  const {
    loaders,
    onDeleteMenuItem,
    onCreateMenuItem,
    onMoveMenuItem,
    updateMenuItem
  } = useMenuCommands({
    menu,
    refetchMenu
  });

  const onSelectedMenuItemForMoving = useCallback(
    (pickedItem: IMenuItem) => {
      setPlacementModeMetadata({
        purpose: "MOVE_MENU_ITEM",
        pickedItem,
        pickedItemDescendants: getMenuItemDescendants(pickedItem)
      });

      dispatch(unsetSelectedMenuItem());
    },
    [dispatch]
  );

  const onAddMenuButtonClick = useCallback(async () => {
    if (hasMenuItemsDefined) {
      setPlacementModeMetadata({
        purpose: "CREATE_MENU_ITEM",
        pickedItem: undefined,
        pickedItemDescendants: []
      });
      dispatch(unsetSelectedMenuItem());
      return;
    }

    const newMenuItem = await onCreateMenuItem(0);

    if (newMenuItem === undefined) {
      return;
    }

    dispatch(setSelectedMenuItem(newMenuItem));
  }, [hasMenuItemsDefined, onCreateMenuItem, dispatch]);

  const onCancelPlacementMode = useCallback(() => {
    setPlacementModeMetadata(undefined);
  }, []);

  const onMenuItemPlaced = useCallback(
    async (placementSegment: PlacementModeSegment, targetItemId: string) => {
      if (placementModeMetadata === undefined) {
        return;
      }
      if (menu === null) {
        return;
      }

      const { purpose, pickedItem } = placementModeMetadata;

      const calculatedParentIdAndPosition =
        getPlacementPositionBySegmentAndItemMetadata({
          segment: placementSegment,
          placementModeMetadata,
          menuItemsPositionMetadataList,
          targetItemId
        });

      const { parentId, position } = calculatedParentIdAndPosition;

      const purposeCommandHandlerMap: Record<
        PlacementModePurpose,
        () => Promise<IMenuItem | undefined>
      > = {
        CREATE_MENU_ITEM: () =>
          onCreateMenuItem(position, parentId || undefined),
        MOVE_MENU_ITEM: () => {
          if (pickedItem === undefined) {
            return Promise.resolve(undefined);
          }

          return onMoveMenuItem(pickedItem, position, parentId || undefined);
        }
      };

      const commandHandler = purposeCommandHandlerMap[purpose];
      const menuItemToSelect = await commandHandler();

      if (menuItemToSelect !== undefined) {
        dispatch(setSelectedMenuItem(menuItemToSelect));
      }
    },
    [
      placementModeMetadata,
      menu,
      menuItemsPositionMetadataList,
      onMoveMenuItem,
      onCreateMenuItem,
      dispatch
    ]
  );

  const onDeleteMenuItemConfirm = useCallback(
    async (menuItemId: string) => {
      dispatch(unsetSelectedMenuItem());
      return onDeleteMenuItem(menuItemId);
    },
    [onDeleteMenuItem, dispatch]
  );

  useEffect(() => {
    setPlacementModeMetadata(undefined);
  }, [menu]);

  const contextValue = useContextValue<MenuBuilderContextValue>({
    menu,
    loaders,
    menuItemsPositionMetadataList,
    placementModeMetadata,
    isPlacementModeActive,
    hasUserPermissionToEditMenu,
    onMenuItemPlaced,
    onDeleteMenuItemConfirm,
    onSelectedMenuItemForMoving,
    onAddMenuButtonClick,
    onCancelPlacementMode,
    updateMenuItem
  });

  return (
    <MenuBuilderContext.Provider value={contextValue}>
      {children}
    </MenuBuilderContext.Provider>
  );
};

export const useMenuBuilderContext = (): MenuBuilderContextValue => {
  const context = useContext(MenuBuilderContext);
  return context;
};
