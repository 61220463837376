import { ISiteItem } from "../models/siteItem";
import { TranslationPathItem } from "../models/urlTranslation";

export function hasUrlTranslationDuplicate(
  siteItem: ISiteItem,
  siblings: ISiteItem[],
  cultures: string[]
): boolean {
  const paths = siteItem.uriSegmentTranslations ?? [];

  const translationHasDuplicate = (culture: string): boolean => {
    const value = paths[culture.toLowerCase()] || paths["*"];
    const translated = Boolean(value) && culture !== "*";
    const leaf = {
      value,
      translated
    };

    return hasDuplicateSiblingUrlTranslations(leaf, culture, siblings);
  };

  return cultures.some(translationHasDuplicate);
}

export const hasDuplicateSiblingUrlTranslations = (
  leaf: TranslationPathItem,
  culture: string,
  siblings: ISiteItem[]
): boolean => {
  if (leaf.translated) {
    const siblingTranslations = siblings.map((sibling) => {
      if (!sibling.uriSegmentTranslations) return "";

      return sibling.uriSegmentTranslations[culture.toLowerCase()] || "";
    });

    return siblingTranslations.includes(leaf.value);
  }

  return false;
};
