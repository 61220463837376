import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { issueCommand } from "redux/actions/commandActions";
import {
  fetchMenu,
  fetchPage,
  fetchSiteItems
} from "redux/actions/siteStructureActions";
import { RollbackPayload } from "redux/actions/typings/siteStructureActions";
import { IRollbackBody, Rollback } from "@d3-forge/forge-commands";
import { SiteNodeTypes } from "models/siteStructure";

export function* sendRollback(action: Action<RollbackPayload>) {
  try {
    const { aggregateId, revision, aggregateType } = action.payload;

    const body: IRollbackBody = {
      aggregateId: aggregateId,
      aggregateType: aggregateType,
      aggregateRevision: revision
    };

    const command = new Rollback(body);

    const commandAction = issueCommand({
      command,
      onSuccessAction: getSuccessAction(aggregateType, aggregateId),
      errorMessage: `notification.rollback${aggregateType.toLowerCase()}.error`,
      successMessage: `notification.rollback${aggregateType.toLowerCase()}.success`,
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}

const getSuccessAction = (aggregateType: string, aggregateId: string) => {
  switch (aggregateType) {
    case SiteNodeTypes.PAGE:
      return fetchPage(aggregateId);
    case SiteNodeTypes.MENU:
      return fetchMenu(aggregateId);
    default:
      return fetchSiteItems({ id: aggregateId });
  }
};
