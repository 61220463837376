import { Chip, makeStyles, Typography, MenuItem } from "@material-ui/core";
import { translate } from "utils/i18n";
import { VariableType } from "utils/variableUtils";
import { IVariableType } from "models/variables";
import React from "react";

interface IProps {
  type: IVariableType;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2)
  },
  chip: {
    color: theme.palette.custom.ds.grayscales.gr200,
    margin: "auto 0",
    backgroundColor: (props: IProps) => {
      switch (props.type.type) {
        case VariableType.KEY_VALUE.type:
          return theme.palette.custom.ds.variables.keyvalue500;
        case VariableType.DATA_LIST.type:
          return theme.palette.custom.ds.variables.datalist500;
        case VariableType.DATA_ITEM.type:
          return theme.palette.custom.ds.variables.dataitem500;
      }
    }
  },
  label: {
    marginLeft: theme.spacing(1.5),
    lineHeight: theme.typography.pxToRem(24),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.custom.ds.grayscales.gr800
  }
}));

const labels = {
  [VariableType.KEY_VALUE.code]: "sitestructure.variables.add.keyvalue",
  [VariableType.DATA_ITEM.code]: "sitestructure.variables.add.dataitem",
  [VariableType.DATA_LIST.code]: "sitestructure.variables.add.datalist"
};

export const AddVariableMenuItem = React.forwardRef<HTMLLIElement, IProps>(
  (props, ref) => {
    const { type, onClick } = props;
    const classes = useStyles(props);

    const label = translate(labels[type.code]);

    return (
      <MenuItem className={classes.root} onClick={onClick} ref={ref}>
        <Chip label={type.code} size="small" className={classes.chip} />
        <Typography className={classes.label}>{label}</Typography>
      </MenuItem>
    );
  }
);

export default AddVariableMenuItem;
