import classnames from "classnames";
import {
  Breadcrumbs,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  ExternalTagSuggestion,
  isTagDatasource,
  TagsDatasource
} from "models/forgeEntities";
import { getLabel } from "./tagsPickerUtils";
import { ExternalTagHierarchy } from "./tagsPicker";

interface IProps extends IBaseProps {
  hierarchy: ExternalTagHierarchy;
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium
  },
  text: {
    font: "inherit"
  },
  breadcrumbSeparator: {
    margin: theme.spacing(0, 0.5)
  }
}));

export const TagsPickerBreadcrumbs = (props: IProps) => {
  const { className, hierarchy } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Breadcrumbs
      className={rootClassName}
      classes={{
        separator: classes.breadcrumbSeparator
      }}
      maxItems={3}
      itemsAfterCollapse={2}
    >
      {hierarchy.map((s: TagsDatasource | ExternalTagSuggestion) => (
        <Tooltip
          key={`${isTagDatasource(s) ? s.code : s.dataSourceId}`}
          title={getLabel(s)}
        >
          <Typography className={classes.text}>
            {truncateIfTooLong(getLabel(s), hierarchy.length)}
          </Typography>
        </Tooltip>
      ))}
      <span /> {/* DO NOT touch this span, it is needed for the final slash */}
    </Breadcrumbs>
  );
};

function truncateIfTooLong(s: string, length: number = 1) {
  if (length <= 1) {
    return s;
  }

  const keep = length === 2 ? 20 : 10;
  if (s.length > keep) {
    return `…${s.slice(-keep)}`;
  } else {
    return s;
  }
}
