import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  Batch,
  UnPublishMenu,
  UnPublishDirectory,
  UnPublishPage
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { UnpublishSiteItemsPayload } from "redux/actions/typings/siteStructureActions";

const COMMAND_MAP = {
  Page: UnPublishPage,
  Directory: UnPublishDirectory,
  Menu: UnPublishMenu
};

export function* sendUnpublishSiteItems(
  action: Action<UnpublishSiteItemsPayload>
) {
  try {
    const { items } = action.payload;

    const commands = items.map(
      (item) =>
        new COMMAND_MAP[item.nodeType]({
          itemId: item.nodeId
        })
    );

    const command = new Batch({ commands });
    const commandAction = issueCommand({
      command,
      errorMessage: "notification.unpublishitem.error",
      successMessage: "notification.unpublishitem.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
