import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    borderRadius: theme.spacing(0.25),
    width: "48px",
    height: "48px",
    right: theme.spacing(5),
    bottom: theme.spacing(3)
  }
}));
