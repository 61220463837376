import { IContextDefinition } from "models/siteAssets/contextDefinition";
import { IContextLayouts } from "models/siteAssets/layoutDefinition";
import { IContextModules } from "models/siteAssets/moduleDefinition";
import { IContextTemplates } from "models/siteAssets/templateDefinition";

export type SiteAssetType = "modules" | "layouts" | "templates" | "contexts";

export class SiteAssetTypes {
  static readonly MODULE = "modules";
  static readonly LAYOUT = "layouts";
  static readonly TEMPLATE = "templates";
  static readonly CONTEXT = "contexts";
}

export type AssetReturnType<T> = T extends "modules"
  ? IContextModules
  : T extends "layouts"
  ? IContextLayouts
  : T extends "templates"
  ? IContextTemplates
  : T extends "contexts"
  ? IContextDefinition[]
  : unknown;
