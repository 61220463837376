import { DirectoryDetail } from "components/siteStructure/directoryView/directoryDetail";
import PageDetail from "components/siteStructure/pageView";
import MenuDetail from "components/siteStructure/menuView";

import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { SiteNodeType } from "models/siteStructure";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
  isLoading: boolean;
}

const NodeTypeComponentMap: Record<SiteNodeType, React.ElementType> = {
  Directory: DirectoryDetail,
  Page: PageDetail,
  Menu: MenuDetail
};

export function ContentView(props: IProps) {
  const { currentSiteItem, isLoading } = props;

  const { nodeType } = currentSiteItem;
  const nodeDetailProps = { node: currentSiteItem, isLoading };

  const DetailComponent = NodeTypeComponentMap[nodeType];

  return <DetailComponent {...nodeDetailProps} />;
}
