import {
  ICommandBody,
  ISetDirectoryMetadataBody,
  ISetPageMetadataBody,
  SetDirectoryMetadata,
  SetPageMetadata,
  UnsetDirectoryMetadata,
  UnsetPageMetadata
} from "@d3-forge/forge-commands";
import { useCommand, useSiteItemMetadata } from "api/queryHooks";
import { IMetadataCategory, IMetadataPropertyItem } from "models/metadata";
import { ISiteItem } from "models/siteItem";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addMetadataToSiteItem,
  removeMetadataFromSiteItem,
  setMetadataSiteItem
} from "redux/actions/siteStructureActions";
import { SiteMetadataWthID } from "redux/actions/typings/siteStructureActions";

export function useMetadata(currentSiteItem: ISiteItem) {
  const request = useSiteItemMetadata();
  const dispatch = useDispatch();

  const [metadata, setMetadata] = useState<IMetadataCategory[] | undefined>(
    currentSiteItem.metadata
  );

  useEffect(() => {
    if (currentSiteItem.label !== "Loading...") {
      request.mutate(currentSiteItem.nodeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSiteItem.nodeId, currentSiteItem.label]);

  useEffect(() => {
    if (request.data) {
      // Add ID to all items
      request.data.categories = request.data.categories.map((cat) => {
        const properties = cat.properties.map((prop) => {
          const newItems = prop.items.map((item, i) => ({
            ...item,
            id: i.toString(),
            category: cat.id
          }));
          return { ...prop, items: newItems };
        });
        return { ...cat, properties };
      });

      setMetadata(request.data.categories);
      dispatch(setMetadataSiteItem(request.data));
    }
  }, [request.data, dispatch]);

  return {
    metadata,
    isLoading: !metadata || request.isLoading,
    error: request.error,
    refreshMetadata: request.mutate
  };
}

export function generateMetadataForCommand(
  metadataItem: IMetadataPropertyItem,
  property: string,
  category?: string
): SiteMetadataWthID {
  return {
    id: metadataItem.id,
    category: category?.toLowerCase() || metadataItem.category.toLowerCase(),
    name: property.toLowerCase(),
    value: metadataItem.value,
    culture: metadataItem.culture,
    environment: metadataItem.environment,
    platform: "*"
  };
}

export function useMetadataCommand(itemId: string, itemType: SiteNodeType) {
  const commandForSet = useCommand(`Set${itemType}MetadataCommand`, {
    errorMessage: "metadata.update.failure",
    successMessage: "metadata.update.success"
  });

  const commandForUnset = useCommand(`Unset${itemType}MetadataCommand`, {
    errorMessage: "metadata.delete.failure",
    successMessage: "metadata.delete.success"
  });
  const dispatch = useDispatch();

  const setMetadata = async (metadata: SiteMetadataWthID) => {
    const commandBody: ISetPageMetadataBody | ISetDirectoryMetadataBody = {
      itemId,
      metadata: [metadata]
    };

    const forgeCommand =
      itemType === SiteNodeTypes.PAGE ? SetPageMetadata : SetDirectoryMetadata;

    await commandForSet.mutateAsync(new forgeCommand(commandBody), {
      onSuccess: (_, command: ICommandBody) => {
        const {
          bodyObject: { metadata: bodyMetadata }
        } = command;
        // Store the new metadata into the redux store
        dispatch(
          addMetadataToSiteItem({ metadata: bodyMetadata, nodeId: itemId })
        );
      }
    });
  };
  const unsetMetadata = async (metadata: SiteMetadataWthID) => {
    const commandBody: ISetPageMetadataBody | ISetDirectoryMetadataBody = {
      itemId,
      metadata: [{ ...metadata, value: null }]
    };

    const forgeCommand =
      itemType === SiteNodeTypes.PAGE
        ? UnsetPageMetadata
        : UnsetDirectoryMetadata;

    await commandForUnset.mutateAsync(new forgeCommand(commandBody), {
      onSuccess: (_, command: ICommandBody) => {
        const {
          bodyObject: { metadata: bodyMetadata }
        } = command;
        dispatch(
          removeMetadataFromSiteItem({ metadata: bodyMetadata, nodeId: itemId })
        );
      }
    });
  };

  return {
    setMetadata,
    unsetMetadata,
    isLoading: commandForSet.isLoading || commandForUnset.isLoading,
    isError: commandForSet.isError || commandForUnset.isError,
    error: commandForSet.error || commandForUnset.error,
    isSuccess: commandForSet.isSuccess || commandForUnset.isSuccess
  };
}
