import {
  DASHBOARDS,
  SITE_STRUCTURE,
  PREVIEW_PAGE,
  PERMISSIONS,
  LINK_RULES
} from "routes/paths";
import { IRouteItem } from "models/routeItem";
import Dashboard from "components/dashboard";
import SiteStructure from "components/siteStructure";
import PreviewPage from "components/previewPage";
import Permissions from "components/permissions";
import LinkRules from "components/linkRules";

export const dashboardRoute: IRouteItem = {
  name: "routes.dashboards",
  icon: "dashboard",
  path: DASHBOARDS,
  component: Dashboard
};

export const siteStructureRoute: IRouteItem = {
  name: "routes.sitestructure",
  icon: "public",
  path: SITE_STRUCTURE,
  component: SiteStructure
};

export const previewPageRoute: IRouteItem = {
  name: "routes.previewpage",
  icon: "",
  path: PREVIEW_PAGE,
  component: PreviewPage
};

export const permissionsRoute: IRouteItem = {
  name: "routes.permissions",
  icon: "people",
  path: PERMISSIONS,
  component: Permissions
};

export const linkRulesRoute: IRouteItem = {
  name: "routes.navigationrules",
  icon: "link",
  path: LINK_RULES,
  component: LinkRules
};
