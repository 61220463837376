import { IBaseProps } from "components/_baseProps";

import { useMenuBuilderContext } from "components/siteStructure/menuView/context";

import { useStyles } from "./styles";
import { D3Button } from "components/shared/d3Components";

interface IProps extends IBaseProps {}

const AddMenuItemButton = (props: IProps) => {
  const classes = useStyles();

  const { loaders, onAddMenuButtonClick } = useMenuBuilderContext();

  return (
    <D3Button
      icon="playlist_add"
      loading={loaders.isCreatingMenuItem}
      onClick={onAddMenuButtonClick}
      className={classes.root}
    />
  );
};

export default AddMenuItemButton;
