import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import WebIcon from "components/shared/webIcon";
import { useStyles } from "./styles";

export interface ICultureLabelProps {
  culture: string;
  isDefault: boolean;
}
export const CultureLabel = (props: ICultureLabelProps) => {
  const classes = useStyles();
  const { culture, isDefault } = props;

  const label = !isDefault ? culture : translate("urlspage.baseurl");

  return (
    <div className={classes.row}>
      {isDefault && <WebIcon className={classes.webIcon} />}
      <Typography className={classes.title}>{label}</Typography>
    </div>
  );
};

export default CultureLabel;
