import { reducerFactory } from "./coreReducer";

import { Action } from "redux-actions";
import { setCommandStatus } from "redux/actions/commandActions";
import { ICommandState } from "models/domainStates";
import { CommandStatus } from "models/commandStatus";

export const initialState: ICommandState = {
  status: "Idle"
};

const reactions = {
  [setCommandStatus.toString()]: (
    state: ICommandState,
    action: Action<CommandStatus>
  ) => {
    state.status = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
