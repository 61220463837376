import {
  AddVariablesToPage,
  IAddVariablesToPageBody,
  IAddVariablesToDirectoryBody,
  VariableContract,
  AddVariablesToDirectory,
  VariableContractType,
  IUpdateDirectoryVariablesBody,
  UpdateDirectoryVariables,
  UpdatePageVariables,
  IUpdatePageVariablesBody,
  IDeletePageVariablesBody,
  IDeleteDirectoryVariablesBody,
  DeletePageVariables,
  DeleteDirectoryVariables
} from "@d3-forge/forge-commands";

import { useCommand } from "api/queryHooks";
import { ISiteItem } from "models/siteItem";
import { SiteNodeTypes } from "models/siteStructure";
import {
  IDataItemVariableBody,
  IDataListVariableBody,
  IKeyValueVariableBody,
  IVariableType
} from "models/variables";
import { VariableType } from "utils/variableUtils";

const types = {
  [VariableType.KEY_VALUE.type]: VariableContractType.KeyValue,
  [VariableType.DATA_ITEM.type]: VariableContractType.DataItem,
  [VariableType.DATA_LIST.type]: VariableContractType.DataList
};

export const toVariableContract = (
  key: string,
  type: IVariableType,
  body: IKeyValueVariableBody | IDataItemVariableBody | IDataListVariableBody
): VariableContract => ({
  key,
  variableType: types[type.type],
  jsonBody: JSON.stringify(body)
});

export const useVariableCommands = (item: ISiteItem) => {
  const addVariablesCommand = useCommand(`AddVariablesTo${item.nodeType}`, {
    successMessage: "sitestructure.variables.add.success",
    errorMessage: "sitestructure.variables.add.failure"
  });
  const updateVariablesCommand = useCommand(`Update${item.nodeType}Variables`, {
    successMessage: "sitestructure.variables.edit.success",
    errorMessage: "sitestructure.variables.edit.failure"
  });
  const overwriteVariablesCommand = useCommand(
    `AddVariablesTo${item.nodeType}`,
    {
      successMessage: "sitestructure.variables.overwrite.success",
      errorMessage: "sitestructure.variables.overwrite.failure"
    }
  );

  const addCommand = (variable: VariableContract) => {
    const body: IAddVariablesToDirectoryBody | IAddVariablesToPageBody = {
      itemId: item.nodeId,
      variables: [variable]
    };

    const AddCommand =
      item.nodeType === SiteNodeTypes.PAGE
        ? AddVariablesToPage
        : AddVariablesToDirectory;

    return new AddCommand(body);
  };

  const addVariable = (variable: VariableContract) => {
    const command = addCommand(variable);
    addVariablesCommand.mutate(command);
  };

  const overwriteVariable = (variable: VariableContract) => {
    const command = addCommand(variable);
    overwriteVariablesCommand.mutate(command);
  };

  const updateVariable = (variable: VariableContract) => {
    const body: IUpdateDirectoryVariablesBody | IUpdatePageVariablesBody = {
      itemId: item.nodeId,
      variables: [variable]
    };

    const UpdateCommand =
      item.nodeType === SiteNodeTypes.PAGE
        ? UpdatePageVariables
        : UpdateDirectoryVariables;

    const command = new UpdateCommand(body);
    updateVariablesCommand.mutate(command);
  };

  const deleteVariablesCommand = useCommand(`Delete${item.nodeType}Variables`, {
    successMessage: "sitestructure.variables.delete.success",
    errorMessage: "sitestructure.variables.delete.failure"
  });

  const deleteVariable = (variableKey: string) => {
    const body: IDeletePageVariablesBody | IDeleteDirectoryVariablesBody = {
      itemId: item.nodeId,
      variableKeys: [variableKey]
    };

    const DeleteCommand =
      item.nodeType === SiteNodeTypes.PAGE
        ? DeletePageVariables
        : DeleteDirectoryVariables;

    const command = new DeleteCommand(body);
    deleteVariablesCommand.mutate(command);
  };

  return {
    addVariable,
    updateVariable,
    deleteVariable,
    overwriteVariable,
    isLoading:
      addVariablesCommand.isLoading ||
      updateVariablesCommand.isLoading ||
      deleteVariablesCommand.isLoading,
    isError:
      addVariablesCommand.isError ||
      updateVariablesCommand.isError ||
      deleteVariablesCommand.isError,
    error:
      addVariablesCommand.error ||
      updateVariablesCommand.error ||
      deleteVariablesCommand.error,
    isSuccess:
      addVariablesCommand.isSuccess ||
      updateVariablesCommand.isSuccess ||
      deleteVariablesCommand.isSuccess
  };
};
