import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import classNames from "classnames";
import { Device } from "components/previewPage/previewBar/previewBar";
import { PreviewToolTip } from "components/previewPage/previewBar/previewToolTip";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import React, { useEffect, useState } from "react";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.custom.ds.viking.viking500
  },
  arrow: {
    color: theme.palette.custom.ds.grayscales.gr500
  },
  menu: {
    "& .MuiMenu-paper": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr900,
      borderRadius: "0",
      width: "180px"
    },
    "& .MuiList-padding": {
      padding: theme.spacing(1, 0.5)
    }
  },
  menuItem: {
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.25),
    fontSize: theme.typography.pxToRem(14),
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr800
    },
    display: "flex",
    columnGap: theme.spacing(2),
    height: theme.spacing(4.5),
    paddingRight: theme.spacing(0.5),
    justifyContent: "space-between"
  },
  menuItemGroup: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2)
  },
  checkIcon: {
    "&.material-icons": {
      fonSize: "1rem",
      width: theme.spacing(4)
    }
  },
  button: {
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.grayscales.gr800
    }
  }
}));

interface IProps extends IBaseProps {
  device: Device;
  onDeviceChange: (device: Device) => void;
}

const iconMapping = {
  desktop: "desktop_windows",
  tablet: "tablet_mac",
  mobile: "phone_iphone"
};

export const DeviceSelector = (props: IProps) => {
  const { device, onDeviceChange } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    menuClose();
  }, [device]);

  const menuClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  return (
    <>
      <PreviewToolTip title={translate("preview.tooltips.changedevice")}>
        <Button onClick={menuClick} className={classes.button}>
          <UxdIcon
            className={classes.icon}
            name={iconMapping[device]}
            outline={false}
          />
          <UxdIcon
            className={classes.arrow}
            name="keyboard_arrow_down"
            outline={false}
          />
        </Button>
      </PreviewToolTip>
      <Menu
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={menuClose}
        anchorEl={anchorEl}
        className={classes.menu}
        anchorOrigin={{
          vertical: 46,
          horizontal: -120
        }}
      >
        <DeviceMenuItem
          device="desktop"
          selected={device === "desktop"}
          onDeviceChange={onDeviceChange}
        ></DeviceMenuItem>
        <DeviceMenuItem
          device="tablet"
          selected={device === "tablet"}
          onDeviceChange={onDeviceChange}
        ></DeviceMenuItem>
        <DeviceMenuItem
          device="mobile"
          selected={device === "mobile"}
          onDeviceChange={onDeviceChange}
        ></DeviceMenuItem>
      </Menu>
    </>
  );
};

interface IDeviceMenuItemProps extends IBaseProps {
  device: Device;
  selected: boolean;
  onDeviceChange: (device: Device) => void;
}

const DeviceMenuItem = React.forwardRef<HTMLLIElement, IDeviceMenuItemProps>(
  (props, ref) => {
    const classes = useStyles();

    const { device, selected, onDeviceChange } = props;
    const checkIconClasses = classNames(classes.icon, classes.checkIcon);

    return (
      <MenuItem
        className={classes.menuItem}
        onClick={() => onDeviceChange(device)}
        ref={ref}
      >
        <span className={classes.menuItemGroup}>
          <UxdIcon
            className={classes.icon}
            name={iconMapping[device]}
            outline={false}
          />
          <Typography variant="body2" component="span">
            {translate(`preview.device_${device}`)}
          </Typography>
        </span>
        {selected && (
          <UxdIcon className={checkIconClasses} name="check" outline={false} />
        )}
      </MenuItem>
    );
  }
);
