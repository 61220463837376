import { translate } from "utils/i18n";
import { IVariableDetail, VariableStatus } from "models/variables";
import { resolveInheritableVariableDetailFieldByStatus } from "utils/variableUtils";
import { isEmpty } from "lodash";

export const hasURIVariableSampleValueDefined = (
  variableDetail: IVariableDetail,
  status: VariableStatus
) => {
  const isSampleValueDefined =
    status === "inherited"
      ? variableDetail.definedIn?.sampleValue !== null
      : variableDetail.sampleValue !== null;

  return isSampleValueDefined;
};

export const hasURIVariableAllowedValuesDefined = (
  variableDetail: IVariableDetail,
  status: VariableStatus
) => {
  const isSampleValueDefined =
    status === "inherited"
      ? !isEmpty(variableDetail.definedIn?.allowedValues)
      : !isEmpty(variableDetail.allowedValues);

  return isSampleValueDefined;
};

export const getSampleValueConfig = (
  variableDetail: IVariableDetail,
  status: VariableStatus
) => {
  const isSampleValueDefined = hasURIVariableSampleValueDefined(
    variableDetail,
    status
  );

  if (!isSampleValueDefined) {
    const undefinedSampleValueTranslation = translate(
      "sitestructure.variables.sample_value_undefined"
    );

    return {
      isDefined: false,
      value: undefinedSampleValueTranslation
    };
  }

  const sampleValue = resolveInheritableVariableDetailFieldByStatus(
    variableDetail,
    "sampleValue",
    status
  );

  return {
    isDefined: true,
    value: sampleValue
  };
};

export const getAllowedValuesConfig = (
  variableDetail: IVariableDetail,
  status: VariableStatus
) => {
  const isAllowedValuesDefined = hasURIVariableAllowedValuesDefined(
    variableDetail,
    status
  );

  if (!isAllowedValuesDefined) {
    const undefinedAllowedValuesTranslation = translate(
      "sitestructure.variables.any_value_allowed"
    );

    return {
      isDefined: false,
      value: undefinedAllowedValuesTranslation
    };
  }

  const allowedValues: string[] = resolveInheritableVariableDetailFieldByStatus(
    variableDetail,
    "allowedValues",
    status
  );

  const allowedValuesStrinfified = allowedValues.join(", ");

  return {
    isDefined: true,
    value: allowedValuesStrinfified
  };
};
