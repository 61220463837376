import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IMenuItem } from "models/menus";
import MenuItem from "components/siteStructure/menuView/components/menuItem";

interface IProps extends IBaseProps {
  menuItems: IMenuItem[];
  level: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    listStyleType: "none",
    paddingLeft: theme.spacing(3),
    margin: "0px"
  }
}));

const MenuItemsList = (props: IProps) => {
  const { menuItems, level } = props;
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const listClassNames = classnames(rootClassName);

  return (
    <ul className={listClassNames}>
      {menuItems.map((menuItem) => (
        <MenuItem key={menuItem.id} menuItem={menuItem} level={level + 1} />
      ))}
    </ul>
  );
};

export default MenuItemsList;
