import classnames from "classnames";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: theme.spacing(9),
    alignItems: "center",
    padding: theme.spacing(0, 2, 0, 3)
  },
  text: {
    flex: 1,
    color: theme.palette.custom.text.header,
    fontWeight: 300
  },
  button: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    color: theme.palette.custom.mainAccent.red
  }
}));

export const HistoryHeader = (props: IProps) => {
  const { className, onCloseClick } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography className={classes.text} variant="h6">
        {translate("sitestructure.history")}
      </Typography>
      <IconButton className={classes.button} onClick={onCloseClick}>
        <UxdIcon name="close" />
      </IconButton>
    </div>
  );
};
