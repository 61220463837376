import React, { useState } from "react";
import classnames from "classnames";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ActionButton } from "components/shared/actionButton";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { D3Modal } from "components/shared/d3Components";

interface IProps extends IBaseProps {
  label: string;
  onClick?: () => void;
  onRemoveLayout: () => void;
  onMoveLayout: () => void;
  isLayoutSelected: boolean;
  disableMove: boolean;
  isLayoutMoving: boolean;
  orphan: boolean;
  isDisabled: boolean;
  disabledStyle?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: 0,
    justifyContent: "flex-start",
    alignItems: "center",
    color: theme.palette.custom.ds.grayscales.gr700,
    backgroundColor: theme.palette.custom.ds.grayscales.gr300,
    height: theme.spacing(3),
    "&$selected": {
      "& $action": {
        color: theme.palette.custom.ds.viking.viking700
      },
      "&:hover": {
        color: theme.palette.custom.ds.viking.viking700
      },
      "& $lockIcon": {
        color: theme.palette.custom.ds.viking.viking700
      }
    },
    "&:hover": {
      "& $actionsContainer": {
        display: "flex"
      },
      "& $lockIcon": {
        display: "flex"
      }
    }
  },
  label: {
    display: "inline-block",
    textTransform: "uppercase",
    flex: 1,
    fontWeight: 500,
    "&$selected": {
      color: theme.palette.custom.ds.viking.viking700
    }
  },
  actionsContainer: {
    display: "none",
    paddingRight: theme.spacing(1),
    gap: theme.spacing(1.5)
  },
  leftActionsContainer: {
    left: theme.spacing(1)
  },
  rightActionsContainer: {
    right: theme.spacing(1)
  },
  action: {
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    color: theme.palette.custom.ds.grayscales.gr700
  },
  actionIcon: {
    fontSize: theme.typography.pxToRem(16)
  },
  lockIcon: {
    display: "flex",
    color: theme.palette.custom.ds.grayscales.gr700,
    alignSelf: "flex-end",
    paddingBottom: "2px"
  },
  orphan: {
    "&:after": {
      backgroundColor: `${theme.palette.custom.warning.main}`
    },
    "&:not($selected):hover": {
      "& $action": {
        color: theme.palette.custom.ds.warning.warning700
      }
    }
  },
  selected: {}, // pseudoclass for selected state,
  visible: {}, // pseudoclass for visible state,
  highlight: {}, // pseudoclass for highlight state,
  disabled: {} // pseudoclass for disabled state,
}));

export const LayoutHeader = (props: IProps) => {
  const {
    className,
    label,
    onClick = () => {},
    onRemoveLayout,
    onMoveLayout,
    isLayoutSelected,
    disableMove,
    isLayoutMoving,
    orphan,
    isDisabled = false,
    disabledStyle = false
  } = props;
  const classes = useStyles();

  const rootClassName = classnames(classes.root, className, {
    [classes.selected]: isLayoutSelected,
    [classes.visible]: isLayoutMoving,
    [classes.orphan]: orphan,
    [classes.disabled]: disabledStyle
  });

  const [openModal, setOpenModal] = useState(false);

  const actionsContainerClassName = classnames(classes.actionsContainer, {
    [classes.selected]: isLayoutSelected,
    [classes.visible]: isLayoutMoving,
    [classes.orphan]: orphan
  });

  const rightActionContainerClassName = classnames(
    actionsContainerClassName,
    classes.rightActionsContainer
  );

  const labelClassName = classnames(classes.label, {
    [classes.selected]: isLayoutSelected
  });

  const moveButtonClassName = classnames(classes.action, {
    [classes.highlight]: isLayoutMoving
  });

  const deleteLayoutFromPage = () => {
    onRemoveLayout();
    setOpenModal(false);
  };

  const selectForMove = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onMoveLayout();
  };

  return (
    <>
      <div className={rootClassName} onClick={() => onClick()}>
        <Typography className={labelClassName} variant="caption">
          {label}
        </Typography>

        {isDisabled && (
          <div className={classes.lockIcon}>
            <Tooltip
              title={translate("sitestructure.permissions.lockedlayout")}
            >
              <span>
                <UxdIcon name="lock" className={classes.actionIcon} />
              </span>
            </Tooltip>
          </div>
        )}
        <div className={rightActionContainerClassName}>
          {!isDisabled && (
            <ActionButton
              className={moveButtonClassName}
              icon="zoom_out_map"
              tooltip={translate("sitestructure.movelayout")}
              size="small"
              onClick={selectForMove}
              disabled={disableMove}
              iconClassName={classes.actionIcon}
            />
          )}
          {!isDisabled && (
            <ActionButton
              className={classes.action}
              icon="delete_outline"
              tooltip={translate("sitestructure.removelayout.tooltip")}
              size="small"
              onClick={() => setOpenModal(true)}
              iconClassName={classes.actionIcon}
            />
          )}
        </div>
      </div>
      <D3Modal
        open={openModal}
        modalTitle={translate("general.confirmationrequired")}
        onConfirm={deleteLayoutFromPage}
        onCancel={() => setOpenModal(false)}
        confirmLabel={translate("general.ok")}
      >
        <Typography>
          {translate("sitestructure.removelayout.confirmtext")}
        </Typography>
      </D3Modal>
    </>
  );
};
