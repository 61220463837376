import { IPageAliasParameter } from "components/siteStructure/aliases/types";
import { IMetadataCategory } from "models/metadata";
import {
  SiteNodeType,
  UpdateInfo,
  SiteNodeStage,
  SiteNodeTypes
} from "models/siteStructure";

export type SiteItemIdentifier = {
  nodeId: string;
  nodeType: SiteNodeType;
};

export const ROOT_ID = "11111111-1111-1111-1111-111111111111";

export const EMPTY_ROOT_ITEM: ISiteItem = {
  nodeId: ROOT_ID,
  parentNodesIds: [],
  nodeType: SiteNodeTypes.DIRECTORY,
  path: "~/",
  label: "Loading...",
  status: "Unpublished",
  contextName: "",
  children: [],
  updateInfo: {
    lastUpdateDate: "",
    lastUpdateUser: "",
    lastUpdateUserId: ""
  },
  uriSegmentTranslations: null,
  alias: null
};

export interface ISiteItem extends SiteItemIdentifier {
  authorizationGroups?: string[];
  parentNodesIds: string[];
  path: string;
  label: string;
  status: SiteNodeStage;
  contextName: string;
  children: string[];
  updateInfo: UpdateInfo;
  metadata?: IMetadataCategory[];
  uriSegmentTranslations: Record<string, string> | null;
  alias: {
    value: string;
    params: IPageAliasParameter[];
  } | null;
}

export type SiteItems = { [nodeId: string]: ISiteItem };

export const INITIAL_SITE_ITEMS = { [ROOT_ID]: EMPTY_ROOT_ITEM };
