import { createAction } from "redux-actions";
import {
  FAILED_ACTION_SUFFIX,
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX
} from "redux/actions/constants";
import {
  AliasWasSetPayload,
  AliasWasUnsetPayload,
  FetchAliasesSuccededPayload
} from "redux/actions/typings/aliasesActions";

export const fetchAliases = createAction(
  `FETCH_ALIASES${REQUEST_ACTION_SUFFIX}`
);

export const fetchAliasesSucceded = createAction(
  `FETCH_ALIASES${SUCCESS_ACTION_SUFFIX}`,
  (payload: FetchAliasesSuccededPayload) => payload
);

export const fetchAliasesFailed = createAction(
  `FETCH_ALIASES${FAILED_ACTION_SUFFIX}`
);

export const aliasWasSet = createAction(
  "ALIAS_WAS_SET",
  (payload: AliasWasSetPayload) => payload
);

export const aliasWasUnset = createAction(
  "ALIAS_WAS_UNSET",
  (payload: AliasWasUnsetPayload) => payload
);
