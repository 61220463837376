import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#F2F2F2",
    padding: `${theme.typography.pxToRem(12)} 0px`
  },
  header: {
    fontFamily: "Rubik, monospace",
    height: theme.typography.pxToRem(36),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: `${theme.typography.pxToRem(12)}`
  },
  title: {
    lineHeight: theme.typography.pxToRem(20),
    paddingLeft: theme.typography.pxToRem(24),
    fontSize: theme.typography.pxToRem(12),
    display: "inline-block",
    height: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  listContainer: {
    display: "flex",
    flexDirection: "column"
  },
  item: {
    padding: `${theme.typography.pxToRem(12)} 0`,
    display: "flex",
    alignItems: "center",
    minHeight: theme.typography.pxToRem(36),
    transition: "background-color .3s ease-out",
    "& *": {
      fontSize: theme.typography.pxToRem(13)
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    "&:hover $itemActions": {
      display: "inline-flex"
    }
  },
  itemActions: {
    marginLeft: "auto",
    padding: "0px 15px",
    display: "none"
  },
  actionButton: {
    borderRadius: theme.spacing(0.25),
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    margin: theme.spacing(0.25),
    marginRight: theme.spacing(0.6),
    fontSize: theme.typography.pxToRem(14)
  },
  itemLeftSide: {
    paddingLeft: theme.typography.pxToRem(16),
    display: "inherit",
    minWidth: theme.spacing(32),
    color: theme.palette.custom.ds.grayscales.gr700
  },
  itemName: {
    fontSize: theme.typography.pxToRem(13),
    marginTop: "-1px"
  },
  itemCenter: {
    color: theme.palette.custom.ds.grayscales.gr800,
    overflowWrap: "anywhere"
  },
  addLanguageBtn: {
    height: theme.typography.pxToRem(24),
    margin: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(20)}`,
    "& .MuiTypography-root": {
      fontSize: theme.typography.pxToRem(12)
    }
  }
}));
