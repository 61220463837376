import { reducerFactory } from "./coreReducer";

import { Action } from "redux-actions";
import { IForgeState } from "models/domainStates";
import {
  fetchEntitiesDefinitionsSucceded,
  fetchSystemLanguagesSucceded,
  fetchTagsDatasourcesSucceded
} from "redux/actions/forgeActions";
import { SystemLanguage } from "models/systemLanguage";
import { ForgeEntityDefinition, TagsDatasource } from "models/forgeEntities";

export const initialState: IForgeState = {
  systemLanguages: [
    {
      code: "nd-nd",
      name: "Neutral"
    }
  ],
  entitiesDefinitions: [],
  tagsDatasource: []
};

const reactions = {
  [fetchSystemLanguagesSucceded.toString()]: (
    state: IForgeState,
    action: Action<SystemLanguage[]>
  ) => {
    state.systemLanguages = action.payload;
  },
  [fetchEntitiesDefinitionsSucceded.toString()]: (
    state: IForgeState,
    action: Action<ForgeEntityDefinition[]>
  ) => {
    state.entitiesDefinitions = action.payload;
  },
  [fetchTagsDatasourcesSucceded.toString()]: (
    state: IForgeState,
    action: Action<TagsDatasource[]>
  ) => {
    state.tagsDatasource = action.payload;
  }
};

export default reducerFactory(initialState, reactions);
