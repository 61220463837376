import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useStyles } from "./styles";
import classNames from "classnames";

export interface UnpublishedWarningProps {
  isLinkRuleUnpublished: boolean;
  isLinkRulesSidebarOpen: boolean;
}

export function UnpublishedWarning(props: Readonly<UnpublishedWarningProps>) {
  const classes = useStyles();
  const { isLinkRuleUnpublished, isLinkRulesSidebarOpen } = props;

  const warningClassNames = classNames(classes.unpublishedWarningContainer, {
    [classes.shrinkedUnpublishedWarningContainer]: isLinkRulesSidebarOpen
  });

  const label = translate(
    "sitestructure.navigationrules.warning_unpublished_navigation_rule"
  );

  if (!isLinkRuleUnpublished) {
    return <></>;
  }

  return (
    <Typography className={warningClassNames}>
      <UxdIcon className={classes.unpublishedWarningIcon} name="warning" />
      <label>{label}</label>
    </Typography>
  );
}

export default UnpublishedWarning;
