import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import { UxdIcon } from "components/shared/uxdIcon";
import { useSelector } from "react-redux";
import { selectDefaultContext } from "redux/selectors/assetCatalogSelectors";
import { find } from "lodash";

interface IProps extends IBaseProps {
  nodeType: SiteNodeType;
  size?: number;
  contextName?: string;
  isAlias?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: ({ size = 3 }: IProps) => ({
    fontSize: theme.spacing(size)
  })
}));

export const SiteNodeIcon = (props: IProps) => {
  const { className, nodeType, contextName, isAlias } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);
  const defaultContext = useSelector(selectDefaultContext);

  const getNodeTypeIconName = () => {
    const nodeTypeIconMap = [
      {
        condition:
          nodeType === SiteNodeTypes.PAGE &&
          contextName === defaultContext &&
          !isAlias,
        icon: "description"
      },
      {
        condition: nodeType === SiteNodeTypes.PAGE && !isAlias,
        icon: "file_open"
      },
      {
        condition: nodeType === SiteNodeTypes.MENU,
        icon: "menu"
      },
      {
        condition: nodeType === SiteNodeTypes.DIRECTORY,
        icon: "folder"
      },
      {
        condition: nodeType === SiteNodeTypes.PAGE && isAlias,
        icon: "font_download"
      }
    ];

    return find(nodeTypeIconMap, "condition")?.icon ?? "";
  };

  return <UxdIcon className={rootClassName} name={getNodeTypeIconName()} />;
};
