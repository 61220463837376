import {
  Button,
  IconButton,
  makeStyles,
  Slide,
  Snackbar,
  Theme
} from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
  severity: Color;
  message: string;
  btnText?: string;
}

const icons = {
  success: "check_circle",
  error: "report",
  warning: "warning",
  info: "info"
};

type Intensity = 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

const getColor = (severity: Color, intensity: Intensity, theme: Theme) =>
  theme.palette.custom.ds[severity][`${severity}${intensity}`];

const useStyles = makeStyles((theme) => ({
  alert: {
    alignItems: "center",
    border: (props: IProps) =>
      `1px solid ${getColor(props.severity, 500, theme)}`,
    backgroundColor: (props: IProps) => getColor(props.severity, 300, theme)
  },
  button: {
    color: "white",
    borderRadius: "2px",
    backgroundColor: (props: IProps) => getColor(props.severity, 500, theme),
    "&:hover": {
      backgroundColor: (props: IProps) => getColor(props.severity, 700, theme)
    }
  }
}));

export const PreviewSnackbar = (props: IProps) => {
  const { open, onClose, onClick, severity, message, btnText } = props;
  const classes = useStyles(props);

  const hasButton = onClick && btnText && btnText.length > 0;
  const icon = <UxdIcon name={icons[props.severity]} />;
  const action = (
    <>
      {hasButton && (
        <Button
          onClick={onClick}
          variant="contained"
          disableElevation
          className={classes.button}
        >
          {btnText}
        </Button>
      )}
      <IconButton onClick={onClose}>
        <UxdIcon name="close" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={null}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      TransitionComponent={(args) => <Slide {...args} direction="right" />}
    >
      <Alert
        className={classes.alert}
        icon={icon}
        action={action}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default PreviewSnackbar;
