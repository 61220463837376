import { createContext, PropsWithChildren, useCallback, useState } from "react";
import { noop } from "utils/typescriptUtils";
import { ChangeTemplateModal } from "components/siteStructure/modals/changeTemplateModal";

export const ChangeTemplateContext = createContext<() => void>(noop);

type Props = {
  pageId: string;
  pageContext: string;
  currentTemplateKey: string;
};

export const ChangeTemplateContextProvider = (
  props: PropsWithChildren<Props>
) => {
  const { children, pageId, pageContext, currentTemplateKey } = props;

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);

  return (
    <ChangeTemplateContext.Provider value={openModal}>
      {children}
      <ChangeTemplateModal
        pageId={pageId}
        pageContext={pageContext}
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedTemplateKey={currentTemplateKey}
      />
    </ChangeTemplateContext.Provider>
  );
};
