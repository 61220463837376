import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useSiteItemSearch } from "api/queryHooks";
import { SiteStructureSearchResults } from "components/siteStructure/tree/siteStructureSearchResults";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useDebounce } from "components/hooks";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: "flex",
    width: `calc(100% - 32px)` // siteItems container width - padding width - margin width
  },
  searchTextFieldContainer: {
    width: "100%",
    margin: theme.spacing("auto", 0)
  },
  searchTextField: {
    width: "100%",
    color: theme.palette.custom.ds.grayscales.gr800
  },
  button: {
    "&:hover": {
      background: "none",
      color: theme.palette.custom.viking.viking500
    }
  },
  buttonActive: {
    color: theme.palette.custom.viking.viking500
  },
  title: {
    margin: theme.spacing("auto", 0),
    fontSize: "16px",
    borderLeft: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    paddingLeft: theme.spacing(2),
    fontWeight: 400
  },
  closeIconButton: {
    "&:hover": {
      background: "none"
    }
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  linkPickerSearchRoot: {
    margin: 0,
    "& .MuiIconButton-root": {
      padding: theme.spacing(1)
    }
  },
  linkPickerSearchTitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr500,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300,
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: "0.5px",
    cursor: "pointer",
    width: "100%"
  }
}));

interface IProps extends IBaseProps {
  hideSiteStructureTree: (val: boolean) => void;
  isLinkPicker?: boolean;
  onSelectedLinkPickerPage?: (id: string, path: string) => void;
}

export function AutocompleteTree(props: IProps) {
  const {
    hideSiteStructureTree,
    isLinkPicker = false,
    onSelectedLinkPickerPage = () => {}
  } = props;
  const classes = useStyles();
  const autocompleteRequest = useSiteItemSearch();
  const [searchedText, setSearchedText] = useState("");
  const [autocompleteItems, setAutocompleteItems] = useState<ISiteItem[]>([]);
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  const searchInput = useRef<HTMLInputElement>(null);

  const rootClassName = classNames(classes.root, {
    [classes.linkPickerSearchRoot]: isLinkPicker
  });
  const buttonClassName = classNames(classes.button, {
    [classes.buttonActive]: searchExpanded
  });

  const handleSearchToggle = () => {
    const expand = !searchExpanded;
    setSearchExpanded(expand);

    if (searchInput.current) {
      if (expand) {
        setTimeout(() => {
          searchInput.current?.focus();
        }, 100);
      } else {
        searchInput.current.value = "";
        handleAutocomplete("");
      }
    }
  };

  const handleAutocomplete = (newValue: string) => {
    setSearchedText(newValue);

    if (newValue && !newValue.startsWith("~")) {
      autocompleteRequest.mutate(newValue);
      hideSiteStructureTree(true);
    } else if (!newValue) {
      setAutocompleteItems([]);
      hideSiteStructureTree(false);
    }
  };

  useEffect(() => {
    if (autocompleteRequest.isSuccess && autocompleteRequest.data) {
      setAutocompleteItems(autocompleteRequest.data);
    }
  }, [autocompleteRequest.data, autocompleteRequest.isSuccess]);

  const debouncedHandleSearchInputChange = useDebounce(
    handleSearchInputChange,
    400
  );
  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    handleAutocomplete(event.target.value);
  }

  return (
    <div>
      <div className={rootClassName}>
        <IconButton className={buttonClassName} onClick={handleSearchToggle}>
          <UxdIcon name="search" />
        </IconButton>
        {!isLinkPicker && (
          <Typography
            variant="h6"
            className={classes.title}
            hidden={searchExpanded}
          >
            {translate("routes.sitestructure")}
          </Typography>
        )}
        {isLinkPicker && (
          <Typography
            variant="subtitle1"
            className={classes.linkPickerSearchTitle}
            hidden={searchExpanded}
            onClick={handleSearchToggle}
          >
            {translate("sitestructure.search.inputplaceholder")}
          </Typography>
        )}
        <div
          hidden={!searchExpanded}
          className={classes.searchTextFieldContainer}
        >
          <TextField
            className={classes.searchTextField}
            size="small"
            fullWidth
            placeholder={translate("sitestructure.search.inputplaceholder")}
            inputRef={searchInput}
            onChange={debouncedHandleSearchInputChange}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <IconButton
                  size="small"
                  className={classes.closeIconButton}
                  onClick={handleSearchToggle}
                >
                  <UxdIcon name="clear" className={classes.closeIcon} />
                </IconButton>
              )
            }}
          />
        </div>
      </div>
      <div hidden={!searchExpanded}>
        <SiteStructureSearchResults
          nodes={autocompleteItems}
          isLoading={autocompleteRequest.isLoading}
          searchedText={searchedText}
          hideSiteStructureTree={hideSiteStructureTree}
          isLinkPicker={isLinkPicker}
          onSelectedLinkPickerPage={onSelectedLinkPickerPage}
        />
      </div>
    </div>
  );
}
