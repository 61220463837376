import classnames from "classnames";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { IMenuItem } from "models/menus";
import MenuItemsList from "components/siteStructure/menuView/components/menuItemsList";
import { UxdIcon } from "components/shared/uxdIcon";
import { ActionButton } from "components/shared/actionButton";

import { D3Modal } from "components/shared/d3Components";
import { useMenuBuilderContext } from "components/siteStructure/menuView/context";
import { PlacementModeSegmentHoverHandles } from "components/siteStructure/menuView/types";
import PlacementModeMarkers from "components/siteStructure/menuView/components/placementModeMarkers";
import {
  getForbiddenPlacementSegments,
  getHoveredSegmentFromHandle,
  getIsForbiddenSegmentHovered,
  getIsMenuItemMovingItemDescendant,
  getIsMenuItemSelectedForMoving
} from "components/siteStructure/menuView/utils";
import { setSelectedMenuItem } from "redux/actions/siteStructureActions";
import { selectSelectedMenuItem } from "redux/selectors/siteStructureSelectors";

import { useStyles } from "./styles";
import { getIsInputFieldFocused } from "utils/appUtils";

interface IProps extends IBaseProps {
  menuItem: IMenuItem;
  level: number;
}
const placeholdersTranslationKeyPrefix =
  "sitestructure.menu_items.content.placeholders";
const deleteModalTranslationKeyPrefix = "sitestructure.menu_items.modal.delete";

const MenuItem = (props: IProps) => {
  const { menuItem, level } = props;
  const { className } = props;
  const classes = useStyles();

  const menuItemContainerRef = useRef(null);

  const rootClassName = classnames(classes.root, className);
  const selectedItem = useSelector(selectSelectedMenuItem);

  const {
    loaders,
    isPlacementModeActive,
    hasUserPermissionToEditMenu,
    placementModeMetadata,
    menuItemsPositionMetadataList,
    onDeleteMenuItemConfirm,
    onSelectedMenuItemForMoving
  } = useMenuBuilderContext();

  const forbiddenPlacementSegments = useMemo(
    () =>
      getForbiddenPlacementSegments(
        menuItemsPositionMetadataList,
        placementModeMetadata,
        menuItem.id
      ),
    [menuItemsPositionMetadataList, placementModeMetadata, menuItem]
  );

  const isMenuItemSelectedForMoving = getIsMenuItemSelectedForMoving(
    placementModeMetadata,
    menuItem.id
  );

  const isMenuItemMovingItemDescendant = getIsMenuItemMovingItemDescendant(
    placementModeMetadata,
    menuItem.id
  );

  const shouldApplySelectedClassnames =
    !isPlacementModeActive &&
    !isMenuItemSelectedForMoving &&
    selectedItem?.id === menuItem.id;

  const shouldApplyMoveModeClassnames =
    isPlacementModeActive &&
    (isMenuItemSelectedForMoving || isMenuItemMovingItemDescendant);

  const [isChildPlacementHovered, setIsChildPlacementHovered] =
    useState<boolean>(false);

  const [isSiblingBeforePlacementHovered, setIsSiblingBeforePlacementHovered] =
    useState<boolean>(false);

  const [isSiblingAfterPlacementHovered, setIsSiblingAfterPlacementHovered] =
    useState<boolean>(false);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const hoveredSegment = useMemo(
    () =>
      getHoveredSegmentFromHandle(
        isChildPlacementHovered,
        isSiblingBeforePlacementHovered,
        isSiblingAfterPlacementHovered
      ),
    [
      isChildPlacementHovered,
      isSiblingBeforePlacementHovered,
      isSiblingAfterPlacementHovered
    ]
  );

  const isForbiddenSegmentHovered = useMemo(
    () =>
      getIsForbiddenSegmentHovered(forbiddenPlacementSegments, hoveredSegment),
    [forbiddenPlacementSegments, hoveredSegment]
  );

  const menuItemContainerClassnames = classnames(classes.menuItemContainer, {
    [classes.menuItemContainerSelected]: shouldApplySelectedClassnames,
    [classes.menuItemContainerMoveMode]: shouldApplyMoveModeClassnames,
    [classes.menuItemContainerHoverMode]: !isPlacementModeActive,
    [classes.menuItemContainerPlacementMode]:
      isPlacementModeActive && !isMenuItemSelectedForMoving,
    [classes.menuItemContainerPlacementChildMode]:
      !isMenuItemSelectedForMoving && isChildPlacementHovered,
    [classes.forbiddenChildMarker]:
      isForbiddenSegmentHovered && hoveredSegment === "CHILD",
    [classes.menuItemContainerPlacementSiblingBeforeMode]:
      isSiblingBeforePlacementHovered,
    [classes.menuItemContainerPlacementSiblingAfterMode]:
      isSiblingAfterPlacementHovered,
    [classes.movingItemDecendant]: isMenuItemMovingItemDescendant,
    [classes.menuItemContainerReadOnlyMode]: !hasUserPermissionToEditMenu
  });

  const visibilityContainerClassnames = classnames(
    classes.visibilityContainer,
    classes.menuItemRightSection
  );

  const toolboxContainerClassnames = classnames(
    classes.toolboxContainer,
    classes.menuItemRightSection,
    { [classes.toolboxContainerMovingMode]: isMenuItemSelectedForMoving }
  );

  const deleteIconClassnames = classnames(classes.icon, classes.toolboxIcon, {
    [classes.toolboxIconSelected]: false,
    [classes.hiddenEntity]: isMenuItemSelectedForMoving
  });

  const moveIconClassnames = classnames(classes.icon, classes.toolboxIcon, {
    [classes.toolboxIconSelected]: isMenuItemSelectedForMoving
  });

  const visibilityIconClassnames = classnames(
    classes.icon,
    classes.visibilityIcon
  );

  const shouldShowVisibilityIcon = !menuItem.visible && !isPlacementModeActive;

  const shouldRenderForbiddenPlacementMessage =
    isPlacementModeActive && isForbiddenSegmentHovered;

  const nameText =
    menuItem.name ||
    menuItem.label ||
    translate(`${placeholdersTranslationKeyPrefix}.fallback_item_name`);

  const linkText =
    menuItem.link ||
    translate(`${placeholdersTranslationKeyPrefix}.fallback_item_link`);

  const shouldRenderMenuItemsList = !isEmpty(menuItem.items);
  const shouldRenderToolboxContainer =
    hasUserPermissionToEditMenu &&
    (!isPlacementModeActive || isMenuItemSelectedForMoving);

  const deleteModalConfirmLabel = loaders.isDeletingMenuItem
    ? `${deleteModalTranslationKeyPrefix}.deleting`
    : `${deleteModalTranslationKeyPrefix}.confirm`;

  const isDeleteModalConfirmLabelDisabled =
    isDeleteModalOpened && loaders.isDeletingMenuItem;

  const dispatch = useDispatch();

  const onMenuItemClick = () => {
    dispatch(setSelectedMenuItem(menuItem));
  };

  const resetHoverHandles = () => {
    setIsChildPlacementHovered(false);
    setIsSiblingBeforePlacementHovered(false);
    setIsSiblingAfterPlacementHovered(false);
  };

  const placementModeSegmentHoverHandles: PlacementModeSegmentHoverHandles =
    useMemo(
      () => ({
        CHILD: {
          isHovered: isChildPlacementHovered,
          set: setIsChildPlacementHovered
        },
        SIBLING_BEFORE: {
          isHovered: isSiblingBeforePlacementHovered,
          set: setIsSiblingBeforePlacementHovered
        },
        SIBLING_AFTER: {
          isHovered: isSiblingAfterPlacementHovered,
          set: setIsSiblingAfterPlacementHovered
        }
      }),
      [
        isChildPlacementHovered,
        isSiblingBeforePlacementHovered,
        isSiblingAfterPlacementHovered
      ]
    );

  useEffect(() => {
    if (isPlacementModeActive) {
      return;
    }

    resetHoverHandles();
  }, [isPlacementModeActive]);

  useEffect(() => {
    if (menuItemContainerRef.current === null) {
      return;
    }

    if (selectedItem?.id !== menuItem.id && selectedItem !== null) {
      return;
    }

    const isInputFieldFocused = getIsInputFieldFocused();

    // We don't rob the focus when a user is typing cause it is bad user experience
    if (isInputFieldFocused) {
      return;
    }

    const menuItemContainer = menuItemContainerRef.current as HTMLDivElement;

    menuItemContainer.focus();
  }, [selectedItem, menuItem]);

  const handleSelectItemForMoving = useCallback(
    (e: any, pickedItem: IMenuItem) => {
      e.stopPropagation();
      onSelectedMenuItemForMoving(pickedItem);
    },
    [onSelectedMenuItemForMoving]
  );

  return (
    <>
      <li className={rootClassName}>
        <div
          ref={menuItemContainerRef}
          tabIndex={0}
          className={menuItemContainerClassnames}
          onClick={onMenuItemClick}
        >
          <div className={classes.menuItemMetadataSection}>
            <Typography className={classes.itemTitle}>{nameText}</Typography>
            {shouldRenderForbiddenPlacementMessage ? (
              <Typography
                className={classnames(
                  classes.itemSubTitle,
                  classes.forbiddenPlacementContainer
                )}
              >
                {translate("sitestructure.menu_items.move.forbidden_message")}
              </Typography>
            ) : (
              <Typography className={classes.itemSubTitle}>
                {linkText}
              </Typography>
            )}
          </div>
          {shouldShowVisibilityIcon && (
            <div className={visibilityContainerClassnames}>
              <UxdIcon
                className={visibilityIconClassnames}
                name="visibility_off"
              />
            </div>
          )}
          {shouldRenderToolboxContainer && (
            <div className={toolboxContainerClassnames}>
              <ActionButton
                icon="zoom_out_map"
                tooltip={translate("sitestructure.move")}
                size="small"
                iconClassName={moveIconClassnames}
                onClick={(e) => handleSelectItemForMoving(e, menuItem)}
              />
              <ActionButton
                icon="delete_outlined"
                tooltip={translate("sitestructure.delete")}
                size="small"
                iconClassName={deleteIconClassnames}
                onClick={() => setIsDeleteModalOpened(true)}
              />
            </div>
          )}
          {isPlacementModeActive && (
            <PlacementModeMarkers
              menuItem={menuItem}
              forbiddenPlacementSegments={forbiddenPlacementSegments}
              hoverHandles={placementModeSegmentHoverHandles}
            />
          )}
        </div>

        {shouldRenderMenuItemsList && (
          <MenuItemsList menuItems={menuItem.items} level={level + 1} />
        )}
      </li>

      {isDeleteModalOpened && (
        <D3Modal
          open={true}
          modalTitle={translate(`${deleteModalTranslationKeyPrefix}.title`)}
          onConfirm={() => onDeleteMenuItemConfirm(menuItem.id)}
          onCancel={() => setIsDeleteModalOpened(false)}
          disabled={isDeleteModalConfirmLabelDisabled}
          cancelLabel={translate(`${deleteModalTranslationKeyPrefix}.cancel`)}
          confirmLabel={translate(deleteModalConfirmLabel)}
        >
          <Typography>
            {translate(`${deleteModalTranslationKeyPrefix}.subtitle`, {
              itemName: `"${nameText}"`
            })}
          </Typography>
        </D3Modal>
      )}
    </>
  );
};

export default MenuItem;
