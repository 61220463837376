import { useMergeState } from "components/hooks";
import { ForgeEntity, TagInputItem } from "models/forgeEntities";
import { SystemLanguage } from "models/systemLanguage";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from "react";
import { useSelector } from "react-redux";
import { selectSystemLanguages } from "redux/selectors/forgeSelectors";
import { translate } from "utils/i18n";
import { isEqual } from "lodash";

type Props = {
  entityTypeCode: string;
};

export type LastUpdateDateValue =
  | "(any)"
  | "past-hour"
  | "past-day"
  | "past-week"
  | "past-month"
  | "past-year";

export type StageValue = "(any)" | "unpublished" | "published" | "reviewed";

export type ContentPickerQueryFilter = {
  terms: string | null;
  language: string | null;
  sortBy: string | null;
  slug: string | null;
  lastUpdateDate: LastUpdateDateValue | null;
  stage: StageValue | null;
  tags: TagInputItem[];
  context: TagInputItem | null;
};

export type FilterKey = keyof ContentPickerQueryFilter;
export type FilterValue<TKey extends FilterKey> =
  ContentPickerQueryFilter[TKey];

type FilterDefinition = {
  isAdvanced: boolean;
};
type FiltersDefinition<TFilter> = {
  [key in keyof TFilter]: FilterDefinition;
};

const filtersDefinition: FiltersDefinition<ContentPickerQueryFilter> = {
  terms: {
    isAdvanced: false
  },
  language: {
    isAdvanced: false
  },
  sortBy: {
    isAdvanced: false
  },
  slug: {
    isAdvanced: true
  },
  lastUpdateDate: {
    isAdvanced: true
  },
  stage: {
    isAdvanced: true
  },
  tags: {
    isAdvanced: true
  },
  context: {
    isAdvanced: true
  }
};

type AddFilterAction = <
  TKey extends FilterKey,
  TValue extends ContentPickerQueryFilter[TKey]
>(
  key: TKey,
  value: TValue
) => void;

type RemoveFilterAction = <TKey extends FilterKey>(key: TKey) => void;

export type ContentPickerContextShape = {
  entityTypeCode: string;
  languages: SystemLanguage[];
  queryFilter: ContentPickerQueryFilter;
  selected: ForgeEntity | null;
  setSelected: (selected: ForgeEntity | null) => void;
  setQueryFilter: AddFilterAction;
  clearFilter: RemoveFilterAction;
  clearAllFilters: () => void;
  advancedFiltersCount: number;
};

export const ContentPickerContext = createContext<ContentPickerContextShape>({
  entityTypeCode: "",
  languages: [],
  queryFilter: {
    terms: null,
    language: null,
    sortBy: null,
    slug: null,
    lastUpdateDate: null,
    stage: null,
    tags: [],
    context: null
  },
  selected: null,
  setSelected: (selected) => {},
  setQueryFilter: (key, value) => {},
  clearFilter: (key) => {},
  clearAllFilters: () => {},
  advancedFiltersCount: 0
});

const getSelectableLanguages = (
  entityType: string,
  systemLanguages: SystemLanguage[]
) => {
  const selectables = [...systemLanguages];
  const anyLanguage = {
    code: "(any)",
    name: translate("contentpicker.anylanguage")
  };

  let defaultLanguage: SystemLanguage;
  if (entityType === "story") {
    defaultLanguage = { ...systemLanguages[0] };
    selectables.splice(0, 1);
  } else {
    defaultLanguage = {
      ...(systemLanguages.find((l) => l.code === "nd-nd") ?? anyLanguage)
    };
    selectables.splice(-1, 1);
  }

  defaultLanguage.name = `Default: ${defaultLanguage.name}`;

  selectables.unshift(anyLanguage);
  selectables.unshift(defaultLanguage);

  return selectables;
};

export const ContentPickerContextProvider = (
  props: PropsWithChildren<Props>
) => {
  const { entityTypeCode } = props;

  const systemLanguages = useSelector(selectSystemLanguages);
  const selectableLanguages = useMemo(
    () => getSelectableLanguages(entityTypeCode, systemLanguages),
    [entityTypeCode, systemLanguages]
  );

  const defaultFilter: ContentPickerQueryFilter = {
    terms: "",
    language: selectableLanguages[0].code,
    sortBy: "(default)",
    slug: "",
    lastUpdateDate: "(any)",
    stage: "(any)",
    tags: [],
    context: null
  };

  const [selected, setSelected] = useState<ForgeEntity | null>(null);
  const [filter, setFilter] =
    useMergeState<ContentPickerQueryFilter>(defaultFilter);

  // Clear the selected entity when the filter changes
  useEffect(() => {
    setSelected(null);
  }, [filter, setSelected]);

  let advancedFiltersCount = 0;
  for (const key in filter) {
    const filterKey = key as FilterKey;
    const filterEntryDefinition = filtersDefinition[filterKey];
    if (
      filterEntryDefinition.isAdvanced &&
      !isEqual(defaultFilter[filterKey], filter[filterKey])
    ) {
      advancedFiltersCount++;
    }
  }
  // useContextValue()
  const contextValue: ContentPickerContextShape = {
    entityTypeCode: props.entityTypeCode,
    languages: selectableLanguages,
    queryFilter: filter,
    selected,
    setSelected,
    setQueryFilter: (key, value) => setFilter({ [key]: value }),
    clearFilter: (key) => setFilter({ [key]: defaultFilter[key] }),
    clearAllFilters: () => setFilter(defaultFilter),
    advancedFiltersCount
  };

  return (
    <ContentPickerContext.Provider value={contextValue}>
      {props.children}
    </ContentPickerContext.Provider>
  );
};
