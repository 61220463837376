import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { filter, includes, isEmpty, map, orderBy } from "lodash";
import { Checkbox, CircularProgress, TextField } from "@material-ui/core";
import classNames from "classnames";
import { useDebounce } from "components/hooks";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import {
  getAvailableVariableTypesForInput,
  VARIABLE_PREFIX,
  VariableInputs,
  VariableTypeCodeEnum,
  VariableTypeSortOrder
} from "utils/variableUtils";
import {
  selectCommandState,
  selectVariableSuggestions
} from "redux/selectors/variablesSelectors";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import {
  IVariableSuggestion,
  VariableKeyToSuggestionTuple
} from "../../../../../../models/variables";
import { noop } from "../../../../../../utils/typescriptUtils";
import { basicAutocompleteFilter } from "utils/autocompleteUtils";
import { useStyles } from "./styles";
import AutocompleteGroup from "./autocompleteGroup";
import { ICommandState } from "models/domainStates";

interface IProps extends IBaseProps {
  propertyName?: string;
  propertyValue?: string;
  disabled?: boolean;
  onChange?: (name: string, value: string) => void;
  onSwitch?: (checked: boolean) => void;
  mandatory?: {
    className: string;
    message: string;
    showMessage: boolean;
  };
  inputType?: VariableInputs;
}

export const VariableSwitch = (props: IProps) => {
  const {
    propertyName = "",
    propertyValue = "",
    disabled = false,
    onChange = noop,
    onSwitch = noop,
    mandatory = {
      className: "",
      message: "",
      showMessage: false
    },
    inputType
  } = props;
  const classes = useStyles(props);

  const [checked, setChecked] = useState<boolean>(
    propertyValue.startsWith(VARIABLE_PREFIX)
  );

  const [isVariableSwitchCheckBoxLoading, setIsVariableSwitchCheckboxLoading] =
    useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(propertyValue);
  const [autocompleteValue, setAutocompleteValue] =
    useState<string>(propertyValue);

  const debouncedOnChange = useDebounce(onChange, 2500);
  const variableSuggestions: IVariableSuggestion[] = useSelector(
    selectVariableSuggestions
  );

  const commandStatus: ICommandState = useSelector(selectCommandState);

  const searchableTypes = useMemo(
    () => (inputType ? getAvailableVariableTypesForInput(inputType) : []),
    [inputType]
  );

  const switchClassNames = classNames(classes.switch, {
    [classes.checked]: checked,
    [classes.disabled]: disabled
  });

  const spinnerClassNames = classNames(classes.switch, classes.switchSpinning);

  const inputClassNames = classNames(classes.input, {
    [classes.disabled]: disabled,
    [mandatory.className]: mandatory.showMessage
  });

  const checkedClicked = () => {
    setChecked((isCurrentlyChecked) => {
      const isVariableModeChecked = !isCurrentlyChecked;

      onSwitch(isVariableModeChecked);

      return isVariableModeChecked;
    });

    // clear variable input on switch
    setInputValue(VARIABLE_PREFIX);
    setAutocompleteValue(VARIABLE_PREFIX);
  };

  const valueChanged = (newValue: string) => {
    if (checked) {
      setIsVariableSwitchCheckboxLoading(true);
    }

    debouncedOnChange(propertyName, newValue);
    setInputValue(newValue);
  };

  const handleSelect = (event: ChangeEvent<{}>, newValue: string | null) => {
    const variableValue = newValue ? VARIABLE_PREFIX + newValue : "";
    valueChanged(variableValue);
  };

  const variableKeysToVariableSuggestionsHashMap = useMemo(() => {
    const variableSuggestionsByKeyTuples: VariableKeyToSuggestionTuple[] = map(
      variableSuggestions,
      (entry) => [entry.key, entry]
    );

    return new Map(variableSuggestionsByKeyTuples);
  }, [variableSuggestions]);

  const getVariableTypeByVariableKey = useCallback(
    (key: string) => {
      const variableType =
        variableKeysToVariableSuggestionsHashMap.get(key)?.mainType ?? "N/A";
      return VariableTypeCodeEnum[variableType];
    },

    [variableKeysToVariableSuggestionsHashMap]
  );

  const searchableOptions = useMemo(() => {
    const filteredVariables = isEmpty(searchableTypes)
      ? variableSuggestions
      : filter(variableSuggestions, (variable) =>
          searchableTypes.includes(variable.type ?? "")
        );

    const sortedVariables = orderBy(filteredVariables, [
      (variable) => VariableTypeSortOrder[variable.mainType],
      "key"
    ]);

    return map(sortedVariables, "key");
  }, [searchableTypes, variableSuggestions]);

  const filterOptions = useCallback((options, params) => {
    const valueWithoutPrefix = params.inputValue.startsWith(VARIABLE_PREFIX)
      ? params.inputValue.substring(1)
      : params.inputValue;

    return basicAutocompleteFilter(options, valueWithoutPrefix);
  }, []);

  useEffect(() => {
    if (!checked) {
      setInputValue("");
    }
  }, [checked]);

  useEffect(() => {
    const shouldEnableVariableSwitch = includes(
      ["Success", "Failure"],
      commandStatus.status
    );

    if (shouldEnableVariableSwitch) {
      setIsVariableSwitchCheckboxLoading(false);
    }
  }, [commandStatus]);

  return (
    <>
      <Autocomplete
        className={inputClassNames}
        freeSolo
        size="small"
        fullWidth={true}
        value={autocompleteValue}
        inputValue={inputValue}
        hidden={!checked}
        disabled={disabled}
        groupBy={getVariableTypeByVariableKey}
        options={searchableOptions}
        filterOptions={filterOptions}
        onChange={handleSelect}
        onInputChange={(event, newInputValue) => valueChanged(newInputValue)}
        renderGroup={(params) => (
          <AutocompleteGroup key={params.key} params={params} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            helperText={mandatory.showMessage ? mandatory.message : ""}
          />
        )}
      />

      {isVariableSwitchCheckBoxLoading ? (
        <CircularProgress className={spinnerClassNames} size={13} />
      ) : (
        <Checkbox
          disabled={disabled}
          className={switchClassNames}
          icon={<UxdIcon name="alternate_email" />}
          checkedIcon={
            <UxdIcon name="alternate_email" className={classes.checked} />
          }
          onClick={checkedClicked}
          checked={checked}
          disableRipple
        />
      )}
    </>
  );
};
