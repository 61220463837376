import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { ChangePageTemplate } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { ChangePageTemplateActionPayload } from "redux/actions/typings/siteStructureActions";

export function* sendChangePageTemplate(
  action: Action<ChangePageTemplateActionPayload>
) {
  try {
    const pageId = action.payload.pageId;
    const template = action.payload.template;

    const command = new ChangePageTemplate({
      pageId,
      template
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.changetemplate.error",
      successMessage: "notification.changetemplate.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
