import { D3Modal } from "components/shared/d3Components";
import {
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
  useRef
} from "react";
import { useHistory } from "react-router-dom";

interface IProps {
  when: boolean;
  defaultPath: string;
  onConfirm?: () => boolean;
  onCancel?: () => boolean;
  modalTitle: string;
  confirmLabel?: string;
  cancelLabel?: string;
}
export function RouterPrompt(props: PropsWithChildren<IProps>) {
  const {
    when,
    onConfirm = () => true,
    onCancel = () => true,
    modalTitle,
    confirmLabel,
    cancelLabel,
    defaultPath
  } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [closeFlag, setCloseFlag] = useState(false);

  const unblockRef = useRef<() => void>(() => undefined);

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      setCurrentPath(location.pathname);
      if (when) {
        setShowPrompt(true);
        return false;
      }
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when, closeFlag]);

  const handleOK = useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        if (unblockRef) {
          unblockRef.current();
        }
        history.replace(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onConfirm]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        if (unblockRef) {
          unblockRef.current();
        }
        history.replace(defaultPath);
        setCloseFlag((prev) => !prev);
      }
    }
    setShowPrompt(false);
  }, [defaultPath, history, onCancel]);

  return (
    <D3Modal
      modalTitle={modalTitle}
      open={showPrompt}
      onConfirm={handleOK}
      confirmLabel={confirmLabel}
      onCancel={handleCancel}
      cancelLabel={cancelLabel}
    >
      {props.children}
    </D3Modal>
  );
}
