import { call, put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  fetchSiteItemsSucceded,
  fetchSiteItemsFailed
} from "redux/actions/siteStructureActions";
import { SiteItemsApi } from "api/siteItems";
import { FetchSiteItemsPayload } from "redux/actions/typings/siteStructureActions";

export function* getSiteItems(action: Action<FetchSiteItemsPayload>) {
  try {
    const { id, shouldMerge = false } = action.payload;

    const siteItems = yield call(SiteItemsApi.getNodeAndChildren, id);

    yield put(fetchSiteItemsSucceded({ items: siteItems, shouldMerge }));
  } catch (error) {
    yield put(fetchSiteItemsFailed(error));
  }
}
