import { createAction } from "redux-actions";
import { RequestStatus } from "models/domainStates";

export type RequestMonitorActionPayload = {
  requestName: string;
  requestState: {
    requestPayload: string;
    status: RequestStatus;
    error?: string;
  };
};

export const setRequest = createAction(
  "SET_REQUEST_STATE",
  (requestName: string, requestPayload: any): RequestMonitorActionPayload => ({
    requestName,
    requestState: {
      requestPayload: JSON.stringify(requestPayload),
      status: "REQUEST"
    }
  })
);

export const setSuccessRequest = createAction(
  "SET_SUCCESS_REQUEST_STATE",
  (
    requestName: string,
    requestPayload: string
  ): RequestMonitorActionPayload => ({
    requestName,
    requestState: {
      requestPayload,
      status: "SUCCESS"
    }
  })
);

export const setFailedRequest = createAction(
  "SET_FAILED_REQUEST_STATE",
  (
    requestName: string,
    requestPayload: string,
    error: string
  ): RequestMonitorActionPayload => ({
    requestName,
    requestState: {
      requestPayload,
      status: "FAILURE",
      error
    }
  })
);
