import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 0",
    paddingLeft: "2rem",
    borderBottom: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`
  },
  title: {
    display: "inline-flex",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.custom.ds.grayscales.gr800
  },
  webIcon: {
    position: "relative",
    top: 2,
    paddingRight: theme.typography.pxToRem(9)
  },
  restoreButton: {
    borderRadius: "2px",
    width: theme.typography.pxToRem(14),
    height: theme.typography.pxToRem(14),
    padding: theme.spacing(1),
    margin: "auto",
    color: theme.palette.custom.ds.grayscales.gr500,
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  row: {
    display: "flex",
    minHeight: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(16)
  },
  labelKey: {
    minWidth: theme.typography.pxToRem(72),
    display: "inline-block",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  path: {
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(14)
  },
  translatedPathHighlight: {
    color: theme.palette.custom.ds.grayscales.gr800
  },
  cell: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  pathCell: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap"
  },
  restoreCell: {
    width: theme.typography.pxToRem(76),
    display: "flex",
    justifyContent: "center"
  },
  pathSegment: {
    display: "flex",
    alignItems: "center"
  },
  growFactor: {
    flexGrow: 1
  },
  textField: {
    minWidth: "150px",
    width: `calc(100% - ${theme.typography.pxToRem(76)})`,
    marginLeft: theme.typography.pxToRem(4)
  },
  allowedValuesValidationMessage: {
    color: theme.palette.custom.ds.error.error500,
    fontSize: theme.typography.pxToRem(13)
  }
}));
