import { Button, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IOverrideSectionController } from "components/siteStructure/variables/view/variableItem";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import WebIcon from "components/shared/webIcon";

import { useStyles } from "./styles";

interface IVariableItemActionsProps extends IBaseProps {
  overrideSectionController: IOverrideSectionController;
}

export const TranslationsActionButton = (props: IVariableItemActionsProps) => {
  const { overrideSectionController } = props;
  const classes = useStyles();

  const selectedItemClassCondition = {
    [classes.selectedItem]: overrideSectionController.isExpanded
  };

  const iconClassnames = classNames(classes.icon, selectedItemClassCondition);

  const webIconClassnames = classNames(
    classes.webIcon,
    selectedItemClassCondition
  );

  const overrideCountLabelClassnames = classNames(
    classes.overrideCount,
    selectedItemClassCondition
  );

  const shouldShowCount = overrideSectionController.overridesCount > 0;

  return (
    <Tooltip
      title={translate(
        "sitestructure.variables.languages_overrides.tooltip.edit_translations"
      )}
    >
      <Button
        className={classes.container}
        onClick={overrideSectionController.toggle}
      >
        {shouldShowCount && (
          <label className={overrideCountLabelClassnames}>
            {overrideSectionController.overridesCount}
          </label>
        )}
        <WebIcon className={webIconClassnames} />
        <UxdIcon
          className={iconClassnames}
          rotate={overrideSectionController.isExpanded ? 270 : 90}
          name="chevron_right"
        />
      </Button>
    </Tooltip>
  );
};

export default TranslationsActionButton;
