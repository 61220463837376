import { useCallback, useRef, useState } from "react";

import { IconButton, TextField, Typography } from "@material-ui/core";

import { UxdIcon } from "components/shared/uxdIcon";
import classNames from "classnames";

//styles
import { useStyles } from "./styles";
import { useSearchManagement } from "components/linkRules/context/searchManagement";
import { debounce } from "lodash";
import { translate } from "utils/i18n";
import { SEARCH_RESULTS_MIN_LETTERS } from "components/linkRules/utils";

export interface IScrollPayload {
  key: string;
  initialPositionY: number;
}

export default function SearchWidgetComponent() {
  const classes = useStyles();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { searchValue, setSearchValue } = useSearchManagement();

  const [isExpanded, setIsExpanded] = useState(false);

  const buttonClassnames = classNames(classes.button, {
    [classes.buttonActive]: isExpanded
  });

  const searchBarContainerClassnames = classNames(classes.searchBarContainer, {
    [classes.searchBarContainerExpanded]: isExpanded
  });

  const onSearchChange = useCallback(
    (e: any) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );

  const debouncedOnSearchChange = debounce(onSearchChange, 300);

  const shouldShowThreeLettersMessage =
    isExpanded &&
    searchValue !== "" &&
    searchValue.length < SEARCH_RESULTS_MIN_LETTERS;

  const toggleSearch = useCallback(() => {
    setIsExpanded((current) => {
      if (current) {
        setSearchValue("");
        const inputEl = searchInputRef.current as any;
        inputEl.value = "";
      }
      if (!current && searchInputRef.current !== null) {
        searchInputRef.current.focus();
      }
      return !current;
    });
  }, [setSearchValue, searchInputRef]);

  const searchPlaceholder = isExpanded
    ? translate("navigationrules.search.inputplaceholder")
    : "";

  return (
    <div className={classes.searchContainer}>
      <div hidden={!isExpanded} className={searchBarContainerClassnames}>
        <TextField
          className={classes.searchTextField}
          size="medium"
          placeholder={searchPlaceholder}
          inputRef={searchInputRef}
          onChange={debouncedOnSearchChange}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <IconButton className={buttonClassnames} onClick={toggleSearch}>
                <UxdIcon name="search" className={classes.searchIcon} />
              </IconButton>
            ),

            endAdornment: isExpanded ? (
              <IconButton
                size="small"
                className={classes.closeIconButton}
                onClick={toggleSearch}
              >
                <UxdIcon name="clear" className={classes.closeIcon} />
              </IconButton>
            ) : null
          }}
        />
        {shouldShowThreeLettersMessage && (
          <Typography component="span" className={classes.threeLettersMessage}>
            {translate("navigationrules.search.add_three_characters_or_more")}
          </Typography>
        )}
      </div>
    </div>
  );
}
