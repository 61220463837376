import React from "react";
import classnames from "classnames";
import { makeStyles, TableContainer, Table } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ChildrenListHeader } from "./childrenListHeader";
import { ChildrenListBody } from "./childrenListBody";
import { ISiteItem } from "models/siteItem";

interface IProps extends IBaseProps {
  children: ISiteItem[];
  onRowClick?: (node: ISiteItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "unset"
  }
}));

export const ChildrenList = (props: IProps) => {
  const { className, children, onRowClick } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <TableContainer className={rootClassName}>
      <Table>
        <ChildrenListHeader />
        <ChildrenListBody children={children} onRowClick={onRowClick} />
      </Table>
    </TableContainer>
  );
};
