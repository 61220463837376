import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import internalServerErrorImage from "images/500.svg";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "99%",
    width: "99%"
  }
}));

export const ServerUnavailable = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <img
      className={rootClassName}
      src={internalServerErrorImage}
      alt="Server unavailable"
    ></img>
  );
};
