import { ContentPickerContext } from "components/contentPicker/contentPickerContext";
import { IBaseProps } from "components/_baseProps";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { useContext } from "react";
import { translate } from "utils/i18n";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { Textbox } from "components/shared/textbox";

interface IProps extends IBaseProps {
  debounceDelayMilliseconds: number;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  textBoxIcon: {
    color: theme.palette.custom.greyscales.backgrounds.grey60
  }
}));

const ContentPickerSlugTextBox = (props: IProps) => {
  const { className, debounceDelayMilliseconds } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { queryFilter, setQueryFilter } = useContext(ContentPickerContext);

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.slug")} />
      <Textbox
        value={queryFilter.slug || ""}
        debounce={true}
        debounceDelay={debounceDelayMilliseconds}
        onChange={(value) => setQueryFilter("slug", value)}
        startIcon="search"
        startIconClassName={classes.textBoxIcon}
      />
    </div>
  );
};

export default ContentPickerSlugTextBox;
