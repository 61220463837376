import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import image from "images/empty_permissions_userlist.svg";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  image: {
    marginBottom: theme.spacing(8)
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr800,
    marginBottom: theme.spacing(3),
    textAlign: "center"
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr600,
    textAlign: "center"
  }
}));

export function EmptyUsers() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={image}
        alt={translate("permissions.content.detail.users.nousers.title")}
      />
      <Typography className={classes.title}>
        {translate("permissions.content.detail.users.nousers.title")}
      </Typography>
      <Typography className={classes.subtitle}>
        {translate("permissions.content.detail.users.nousers.subtitle")}
      </Typography>
    </div>
  );
}
