import {
  StageValue,
  ContentPickerContext
} from "components/contentPicker/contentPickerContext";
import { IBaseProps } from "components/_baseProps";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { useContext, useMemo } from "react";
import classnames from "classnames";
import { translate } from "utils/i18n";
import { TextFieldLabel } from "components/shared/textFieldLabel";

type StageOption = {
  label: string;
  value: StageValue;
};

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0
  }
}));

const stageOptionsFactory = () => {
  const any = translate("contentpicker.any");
  const unpublished = translate("contentpicker.unpublished");
  const published = translate("contentpicker.published");
  const reviewed = translate("contentpicker.reviewed");

  const options: StageOption[] = [
    {
      label: any,
      value: "(any)"
    },
    {
      label: unpublished,
      value: "unpublished"
    },
    {
      label: published,
      value: "published"
    },
    {
      label: reviewed,
      value: "reviewed"
    }
  ];

  return options;
};

const ContentPickerStageSelect = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { queryFilter, setQueryFilter } = useContext(ContentPickerContext);

  const options = useMemo(() => stageOptionsFactory(), []);

  return (
    <div className={rootClassName}>
      <TextFieldLabel value={translate("contentpicker.stage")} />
      <Select
        fullWidth={true}
        margin="dense"
        variant="outlined"
        value={queryFilter.stage}
        onChange={(e) => setQueryFilter("stage", e.target.value as StageValue)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ContentPickerStageSelect;
