import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import AuthorizationGroupsNav from "components/permissions/authorizationGroupNav";
import { useCreateAuth, CREATE_URL } from "components/permissions/hooks";
import { PermissionMainContentRoutes } from "components/permissions/permissionRoutes";
import { RouterPrompt } from "components/shared/RouterPrompt";
import { IBaseProps } from "components/_baseProps";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "redux/actions/appActions";
import { setCreateAuthGroupFlag } from "redux/actions/authorizationGroupsActions";
import { permissionsRoute } from "routes/routes";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: "100%"
  },
  mainContent: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.custom.ds.grayscales.gr200
  }
}));

export const Permissions = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();
  const { isAddNewStatus } = useCreateAuth();

  useEffect(() => {
    dispatch(
      setCurrentRoute({
        name: permissionsRoute.name,
        path: permissionsRoute.path
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className={rootClassName}>
        <AuthorizationGroupsNav />
        <div className={classes.mainContent}>
          <PermissionMainContentRoutes />
        </div>
      </div>
      <RouterPrompt
        when={isAddNewStatus}
        modalTitle={translate("permissions.list.leave.title")}
        defaultPath={CREATE_URL}
        onConfirm={() => {
          dispatch(setCreateAuthGroupFlag(false));
          return true;
        }}
        confirmLabel={translate("permissions.list.leave.confirm")}
        cancelLabel={translate("permissions.list.leave.cancel")}
      >
        <Typography>{translate("permissions.list.leave.message")}</Typography>
      </RouterPrompt>
    </>
  );
};
