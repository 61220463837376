import { AssetCatalogApi } from "api/assetCatalog";
import {
  fetchLayoutsSucceded,
  fetchLayoutsFailed
} from "redux/actions/assetCatalogActions";
import { put, call } from "redux-saga/effects";

export function* getLayouts() {
  try {
    const modules = yield call(AssetCatalogApi.getLayouts);
    yield put(fetchLayoutsSucceded(modules));
  } catch (error) {
    yield put(fetchLayoutsFailed(error));
  }
}
