import { useCallback, useRef } from "react";
import {
  ListItemProps,
  ListItem,
  ListItemText,
  Typography,
  Tooltip
} from "@material-ui/core";
import classNames from "classnames";
import { useLinkRulesScrollingManagementContext } from "components/linkRules/context/scrollManagement";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { ILinkRuleEntityMetadata } from "models/linkRules";

import { useStyles } from "./styles";
import { translate } from "utils/i18n";
import { getPrefixedEntityString } from "components/linkRules/utils";

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button disableRipple component="a" {...props} />;
}

interface IProps extends IBaseProps {
  entitySlug: string;
  linkRuleEntityMetadata: ILinkRuleEntityMetadata;
  isSelected: boolean;
}

const getNavigationRuleCountTranslationMessage = (
  navigationRulesCount: number
) => {
  const translationKeyPrefix =
    "sitestructure.navigationrules.navigation_rules_count";

  const pluralityKey = navigationRulesCount === 1 ? "singular" : "plural";
  return translate(`${translationKeyPrefix}.${pluralityKey}`, {
    navigationRulesCount
  });
};

export default function LinkRuleEntityItem(props: Readonly<IProps>) {
  const classes = useStyles();
  const { onSelectEntityEntry } = useLinkRulesScrollingManagementContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const { linkRuleEntityMetadata, isSelected, className } = props;

  const {
    displayName,
    name: entityKey,
    hasUnpublishedLinkRule,
    navigationRulesCount
  } = linkRuleEntityMetadata;

  const rootClassName = classNames(classes.root, className, {
    [classes.selected]: isSelected
  });

  const onItemClick = useCallback(
    (entityKey: string) => {
      onSelectEntityEntry(entityKey);
    },
    [onSelectEntityEntry]
  );

  const shouldRenderUnpublishedIndicatorIcon = hasUnpublishedLinkRule;

  return (
    <ListItemLink
      className={rootClassName}
      onClick={() => onItemClick(entityKey)}
      id={getPrefixedEntityString(entityKey)}
    >
      <div className={classes.itemLinkContainer} ref={containerRef}>
        <div className={classes.row}>
          <ListItemText
            primary={displayName}
            className={classNames(classes.label, classes.itemText)}
          />
          {shouldRenderUnpublishedIndicatorIcon && (
            <Tooltip
              title={translate(
                "sitestructure.navigationrules.tooltip_unpublished_info"
              )}
              placement="top"
              arrow
            >
              <span>
                <UxdIcon
                  className={classes.unpublishedWarningIcon}
                  name="warning"
                />
              </span>
            </Tooltip>
          )}
          <Typography className={classNames(classes.label, classes.textRight)}>
            {getNavigationRuleCountTranslationMessage(navigationRulesCount)}
          </Typography>
        </div>
      </div>
    </ListItemLink>
  );
}
