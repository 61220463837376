import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { CommandStatus } from "models/commandStatus";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  status: CommandStatus;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.viking.viking500
  },
  spin: {
    animation: "1s cubic-bezier(.75, .2, .5, .8) infinite $spin"
  },
  // animation stuff
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(-360deg)"
    }
  }
}));

export const StatusIcon = (props: IProps) => {
  const { className, status } = props;
  const classes = useStyles();
  const rootClassName = classnames(
    classes.root,
    { [classes.spin]: status === "InProgress" },
    className
  );

  if (status === "Idle") {
    return <></>;
  }

  const getIconName = () => {
    switch (status) {
      case "Success":
        return "check";
      case "Failure":
        return "sync-alert";
      case "InProgress":
        return "sync";
      default:
        throw new Error(`CommandStatus ${status} not supported`);
    }
  };

  return <UxdIcon className={rootClassName} name={getIconName()} />;
};
