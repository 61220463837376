export class ColorUtils {
  private static cachedColors: { [prop: string]: string } = {};

  private static stringToColor2(str: string) {
    const hash = (word: string) => {
      let h = 0;

      if (word.length === 0) {
        return h;
      }

      for (let i = 0, len = word.length; i < len; i++) {
        h = (h << 5) - h + word.charCodeAt(i);
        h |= 0;
      }

      return h;
    };
    const shade = (color: string, prc: number) => {
      const num = parseInt(color, 16);
      const amt = Math.round(2.55 * prc);
      const R = (num >> 16) + amt;
      const G = ((num >> 8) & 0x00ff) + amt;
      const B = (num & 0x0000ff) + amt;

      return (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1);
    };

    const int_to_rgba = (i: number) => {
      const color =
        ((i >> 24) & 0xff).toString(16) +
        ((i >> 16) & 0xff).toString(16) +
        ((i >> 8) & 0xff).toString(16) +
        (i & 0xff).toString(16);

      return color;
    };

    return shade(int_to_rgba(hash(str)), -10);
  }

  private static stringToColor(inputString: string, cache: boolean) {
    const str = inputString.toLowerCase();

    if (cache) {
      if (ColorUtils.cachedColors[str]) {
        return ColorUtils.cachedColors[str];
      }
    }

    const color = ColorUtils.stringToColor2(str).slice(0, 6);

    if (cache) {
      ColorUtils.cachedColors[str] = color;
    }

    return color;
  }

  private static colorFromGuid(guid: string) {
    return ColorUtils.stringToColor(guid + guid, true);
  }

  static readonly generateColor = (str: string): string => {
    const color = ColorUtils.colorFromGuid(str);
    return `#${color}`;
  };
}
