import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  makeStyles,
  IconButton
} from "@material-ui/core";
import classnames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { PropsWithChildren } from "react";
import { translate } from "utils/i18n";
import { D3Button } from "components/shared/d3Components/d3Button";

/**
 * Deltatre Modal Style Guide available Props
 */
interface IProps extends IBaseProps {
  /**
   * Determine if the modal is open or not
   */
  open: boolean;
  /**
   * The function being executed when the confirm button is pressed
   */
  onConfirm?: (agrs: unknown) => void;
  /**
   * The function being executed when the cancel button is pressed.
   * If it's undefined, onConfirm will be used as the method for closing the modal
   */
  onCancel?: () => void;
  /**
   * The title of the modal. It shows in the header of the modal
   */
  modalTitle: string | JSX.Element;
  /**
   * The label on the cancel button
   */
  cancelLabel?: string;
  /**
   * The label on the confirm button
   */
  confirmLabel?: string;
  /**
   * The icon to show in the confirm button
   */
  confirmIcon?: string;
  /**
   * The icon to show in the cancel button
   */
  cancelIcon?: string;
  /**
   * The position of the icon to show in the confirm button
   */
  confirmIconPosition?: "left" | "right";
  /**
   * The position of the icon to show in the confirm button
   */
  cancelIconPosition?: "left" | "right";
  /**
   * Determine if the confirm button is disabled or not
   */
  disabled?: boolean;
  /**
   * Determinate if show the footer buttons or not
   */
  hideButtons?: boolean;
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  /**
   * Gives the option to add an additional element to the footer.
   */
  additionalFooterElement?: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    justifyContent: "space-between",
    alignItems: "center",
    maxHeight: theme.spacing(3),
    borderBottom: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  },
  title: {
    color: theme.palette.custom.ds.grayscales.gr900,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 400
  },
  headerIcon: {
    color: theme.palette.custom.ds.grayscales.gr600,
    cursor: "pointer",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.custom.ds.grayscales.gr700
    }
  },
  footer: {
    padding: theme.spacing(3, 4),
    borderTop: `${theme.spacing(0.125)}px solid ${
      theme.palette.custom.ds.grayscales.gr300
    }`
  },
  cancelBtn: {},
  content: {
    wordBreak: "break-word",
    padding: theme.spacing(3, 4)
  }
}));

/**
 * Deltatre Modal with Design System.
 *
 * The component takes the content of the modal as children
 *
 * @see [Design System](https://www.figma.com/file/mTyXrouZ9B4cHebcPOltDJ/Product-Design-System?node-id=3281%3A30406)
 * @component
 * @param props IProps with children
 */
export function D3Modal(props: PropsWithChildren<IProps>) {
  const {
    className,
    open,
    onConfirm = () => undefined,
    onCancel,
    modalTitle,
    cancelLabel,
    confirmLabel,
    disabled = false,
    hideButtons = false,
    maxWidth,
    additionalFooterElement
  } = props;

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const onClose = (ev) => (onCancel ? onCancel() : onConfirm(ev));

  return (
    <Dialog
      className={rootClassName}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <header className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {modalTitle}
        </Typography>
        <IconButton
          size="small"
          onClick={onClose}
          className={classes.headerIcon}
          disableRipple
        >
          <UxdIcon name="close" />
        </IconButton>
      </header>
      <DialogContent className={classes.content}>
        {props.children}
      </DialogContent>
      {!hideButtons && (
        <DialogActions className={classes.footer}>
          {additionalFooterElement}
          {onCancel && (
            <D3Button
              onClick={onCancel}
              variant="ghost"
              color="neutral"
              icon={props.cancelIcon}
              iconPosition={props.cancelIconPosition}
              className={classes.cancelBtn}
            >
              {cancelLabel ?? translate("general.cancel")}
            </D3Button>
          )}
          <D3Button
            onClick={onConfirm}
            disabled={Boolean(disabled)}
            variant="filled"
            icon={props.confirmIcon}
            iconPosition={props.confirmIconPosition}
          >
            {confirmLabel ?? translate("general.confirm")}
          </D3Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
