import { useMemo } from "react";
import { every } from "lodash";
import {
  UpdateLinkRuleProperties,
  IUpdateLinkRulePropertiesBody,
  IResetLinkRulePropertiesBody,
  ResetLinkRuleProperties,
  DisableLinkRule,
  IDisableLinkRuleBody,
  IRemoveLinkRuleFromPageBody,
  RemoveLinkRuleFromPage,
  ISetLinkRuleDescriptionBody,
  SetLinkRuleDescription,
  IUnsetLinkRuleDescriptionBody,
  UnsetLinkRuleDescription,
  ICreateLinkRuleForPageBody,
  CreateLinkRuleForPage,
  ICreateAbsoluteLinkRuleBody,
  CreateAbsoluteLinkRule,
  IModifyLinkRuleUrlPatternBody,
  IUpdateLinkRuleLinkedPageBody,
  ModifyLinkRuleUrlPattern,
  UpdateLinkRuleLinkedPage
} from "@d3-forge/forge-commands";
import { useCommand } from "api/queryHooks";
import { ILinkRuleForm } from "components/linkRules/context/crudManagement";
import { ILinkRuleCrudLoaders } from "models/linkRules";
import { CommandResult } from "models/commandResult";

interface Props {
  form: ILinkRuleForm;
}

export const useLinkRuleCommands = ({ form }: Props) => {
  const { id: linkRuleId, entityType, url, pageId, properties } = form;

  const updateLinkRulePropertiesCommand = useCommand(
    "update-navigation-rule-properties",
    {
      errorMessage: "notification.navigationrules.update.error",
      successMessage: "notification.navigationrules.update.success"
    }
  );

  const disableLinkRuleCommand = useCommand("disable-navigation-rule", {
    errorMessage: "notification.navigationrules.delete.error",
    successMessage: "notification.navigationrules.delete.success"
  });

  const setLinkRuleDescriptionCommand = useCommand(
    "set-navigation-rule-description",
    {
      errorMessage: "notification.navigationrules.update_properties.error",
      successMessage: "notification.navigationrules.update_properties.success"
    }
  );

  const createLinkRuleCommand = useCommand("create-navigation-rule", {
    errorMessage: "notification.navigationrules.create.error",
    successMessage: "notification.navigationrules.create.success"
  });

  const updateLinkRulePathCommand = useCommand("update-navigation-rule-path", {
    errorMessage: "notification.navigationrules.update_path.error",
    successMessage: "notification.navigationrules.update_path.success"
  });

  const loaders: ILinkRuleCrudLoaders = useMemo(
    () => ({
      isUpdatingLinkRuleDescription: setLinkRuleDescriptionCommand.isLoading,
      isUpdatingLinkRuleProperties: updateLinkRulePropertiesCommand.isLoading,
      isUpdatingLinkRulePath: updateLinkRulePathCommand.isLoading,
      isDeletingLinkRule: disableLinkRuleCommand.isLoading,
      isCreatingLinkRule: createLinkRuleCommand.isLoading
    }),
    [
      setLinkRuleDescriptionCommand.isLoading,
      updateLinkRulePropertiesCommand.isLoading,
      updateLinkRulePathCommand.isLoading,
      disableLinkRuleCommand.isLoading,
      createLinkRuleCommand.isLoading
    ]
  );

  const resetLinkRuleProperties = async () => {
    const commandBody: IResetLinkRulePropertiesBody = {
      linkRuleId: linkRuleId ?? ""
    };
    const command = new ResetLinkRuleProperties(commandBody);
    try {
      const result = await updateLinkRulePropertiesCommand.mutateAsync(command);
      if (!result.success) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
      return false;
    }
    return true;
  };

  const updateLinkRuleProperties = async () => {
    if (properties.length === 0) {
      return resetLinkRuleProperties();
    }

    const commandBody: IUpdateLinkRulePropertiesBody = {
      linkRuleId: linkRuleId ?? "",
      properties: properties.map((it) => ({
        name: it.name ?? "",
        operator: it.operator ?? 0,
        value: it.value ?? null
      }))
    };

    const command = new UpdateLinkRuleProperties(commandBody);

    try {
      const result = await updateLinkRulePropertiesCommand.mutateAsync(command);

      if (!result.success) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
      return false;
    }

    return true;
  };

  const setLinkRuleDescription = async (): Promise<boolean> => {
    const empty = form.description?.trim().length === 0;

    const setBody: ISetLinkRuleDescriptionBody = {
      linkRuleId: linkRuleId ?? "",
      description: form.description ?? ""
    };

    const unsetBody: IUnsetLinkRuleDescriptionBody = {
      linkRuleId: linkRuleId ?? ""
    };

    const command = empty
      ? new UnsetLinkRuleDescription(unsetBody)
      : new SetLinkRuleDescription(setBody);

    const result = await setLinkRuleDescriptionCommand.mutateAsync(command);
    return result.success;
  };

  const deleteLinkRule = async (id: string, isAbsolute: boolean) => {
    const body: IDisableLinkRuleBody | IRemoveLinkRuleFromPageBody = {
      linkRuleId: id ?? ""
    };

    const command = isAbsolute
      ? new DisableLinkRule(body)
      : new RemoveLinkRuleFromPage(body);

    try {
      await disableLinkRuleCommand.mutateAsync(command);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const updateLinkRulePath = async (
    id: string,
    path: string,
    isAbsolute: boolean
  ) => {
    const modifyBody: IModifyLinkRuleUrlPatternBody = {
      linkRuleId: id,
      urlPattern: path
    };

    const updateBody: IUpdateLinkRuleLinkedPageBody = {
      linkRuleId: id,
      pageId: path
    };

    const command = isAbsolute
      ? new ModifyLinkRuleUrlPattern(modifyBody)
      : new UpdateLinkRuleLinkedPage(updateBody);

    let result: CommandResult | undefined = undefined;
    try {
      result = await updateLinkRulePathCommand.mutateAsync(command);
    } catch (error) {
      console.error(error);
    }

    return result?.success ?? false;
  };

  const getCreateLinkRuleCommandBody = (isAbsoluteUrl: boolean) => {
    if (isAbsoluteUrl) {
      return {
        entityType,
        description: form.description ?? "",
        priority: 0,
        urlPattern: url,
        properties: properties.map((it) => ({
          name: it.name ?? "",
          operator: it.operator ?? 0,
          value: it.value ?? ""
        }))
      } as ICreateAbsoluteLinkRuleBody;
    }

    return {
      entityType,
      pageId: pageId ?? "",
      description: form.description ?? "",
      priority: 0,
      urlPattern: url,
      properties: properties.map((it) => ({
        name: it.name ?? "",
        operator: it.operator ?? 0,
        value: it.value ?? ""
      }))
    } as ICreateLinkRuleForPageBody;
  };

  const editLinkRule = async () => {
    const isAbsoluteUrl = !pageId;
    const id = linkRuleId ?? "";
    const path = isAbsoluteUrl ? url : pageId;

    const commandsResult = await Promise.all([
      updateLinkRulePath(id, path, isAbsoluteUrl),
      updateLinkRuleProperties(),
      setLinkRuleDescription()
    ]);

    const result = every(commandsResult);

    return result;
  };

  const createLinkRule = async () => {
    const isAbsoluteUrl = !pageId;

    const commandBody = getCreateLinkRuleCommandBody(isAbsoluteUrl);
    const command = isAbsoluteUrl
      ? new CreateAbsoluteLinkRule(commandBody)
      : new CreateLinkRuleForPage(commandBody as ICreateLinkRuleForPageBody);

    try {
      const result = await createLinkRuleCommand.mutateAsync(command);

      if (!result.success) {
        return true;
      }
    } catch (err) {
      console.log("Error:", err);
      return false;
    }

    return true;
  };

  return {
    loaders,
    updateLinkRuleProperties,
    createLinkRule,
    editLinkRule,
    deleteLinkRule,
    setLinkRuleDescription
  };
};
