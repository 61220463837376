import { ChangeEvent, useCallback } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IModule, ILayout } from "models/pages";
import { setModuleImportance } from "redux/actions/siteStructureActions";
import { userService } from "services/userService";
import { translate } from "utils/i18n";
import { D3Switch } from "components/shared/d3Components";

interface IProps extends IBaseProps {
  pageId: string;
  pageElement: IModule | ILayout;
  currentSiteItemPath: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(2),
    alignItems: "baseline",
    justifyContent: "space-between",
    gap: theme.spacing(2)
  },
  topRow: {
    display: "flex"
  },
  title: {
    padding: theme.spacing(1, 1, 0, 0)
  },
  caption: {
    color: theme.palette.custom.ds.grayscales.gr600,
    fontStyle: "italic"
  }
}));

export const ImportantModuleSwitch = (props: IProps) => {
  const { className, pageId, pageElement, currentSiteItemPath } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const dispatch = useDispatch();

  const handleSetImportance = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      dispatch(
        setModuleImportance({
          moduleInstanceId: pageElement.instanceId,
          pageId: pageId,
          importance: isChecked
        })
      );
    },
    [dispatch, pageId, pageElement]
  );

  const disabled =
    !userService.hasSiteItemPermissions(currentSiteItemPath) ||
    !userService.hasElementPermission(pageElement.moduleType, pageElement.key);

  return (
    <div className={rootClassName}>
      {pageElement.moduleType === "Module" && (
        <div className={classes.container}>
          <div className={classes.topRow}>
            <label htmlFor="require-switch">
              <Typography variant="body2" className={classes.title}>
                {translate("sitestructure.requiredlabel.title")}
              </Typography>
            </label>
            <D3Switch
              id="require-switch"
              checked={pageElement.importance}
              onChange={handleSetImportance}
              disabled={disabled}
            />
          </div>
          <label htmlFor="require-switch">
            <Typography variant="caption" className={classes.caption}>
              {translate("sitestructure.requiredlabel.caption")}
            </Typography>
          </label>
        </div>
      )}
    </div>
  );
};
