import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  DialogTitle
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  open: boolean;
  onClose?: () => void;
  modalTitle: string;
  modalMessage?: string;
}

export function OkModal(props: IProps) {
  const {
    className,
    open,
    onClose = () => undefined,
    modalTitle,
    modalMessage
  } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      onClose();
    }
  };

  return (
    <Dialog
      className={className}
      open={open}
      onClose={onClose}
      fullWidth
      onKeyUp={handleKeyUp}
    >
      <DialogTitle>{modalTitle}</DialogTitle>

      <DialogContent>
        <Typography gutterBottom={true} variant="body2">
          {modalMessage}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {translate("general.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
