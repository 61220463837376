import { makeStyles } from "@material-ui/core";
import {
  useGetAuthGroupUsersGuishell,
  useUpdateAuthGroupUsers
} from "api/queryHooks";
import { UserAvatar } from "components/permissions/detailView/userList/userAvatar";
import {
  D3Button,
  D3MultiSelectListModal
} from "components/shared/d3Components";
import { IBaseProps } from "components/_baseProps";
import _ from "lodash";
import { IAuthorizationGroup } from "models/authorizationGroup";
import { IAuthorizationGroupUsersUpdateRequest } from "models/authorizationGroupUsers";
import { useEffect, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { setAuthorizationGroupUsersUpdatedFlag } from "redux/actions/authorizationGroupsActions";
import { displayNotification } from "redux/actions/notificationActions";
import { translate } from "utils/i18n";
import { getFullName, getInitials } from "utils/nameUtils";

const useStyles = makeStyles((theme) => ({
  root: {},
  addBtn: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(3),
    zIndex: 1
  }
}));

interface IProps extends IBaseProps {
  authorizationGroup: IAuthorizationGroup;
  usersInAuthorizationGroup?: string[];
}

export function AddAuthorizationGroupUser(props: IProps) {
  const { authorizationGroup, usersInAuthorizationGroup = [] } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const mutation = useUpdateAuthGroupUsers();
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [usersToAdd, setUsersToAdd] = useState<
    {
      key: string;
      label: string;
      icon: JSX.Element;
    }[]
  >([]);

  const request = useGetAuthGroupUsersGuishell();

  useEffect(() => {
    if (request.data) {
      const users = request?.data?.filter(
        (x) => (usersInAuthorizationGroup.indexOf(x.key) ?? -1) < 0
      );

      const toAdd = Array.from(users ?? [], (user) => {
        return {
          key: user.key,
          label: getFullName(user.firstName, user.lastName),
          icon: (
            <UserAvatar initials={getInitials(user.firstName, user.lastName)} />
          )
        };
      });
      setUsersToAdd(_.sortBy(toAdd, "label"));
    }
  }, [request.data, usersInAuthorizationGroup]);

  const onCancel = () => {
    setAddModalOpen(false);
  };

  const onAdd = async (usersToAdd: string[]) => {
    const update: IAuthorizationGroupUsersUpdateRequest = {
      authorizationGroups: [authorizationGroup.groupId],
      actionType: "update",
      userIds: usersToAdd
    };

    mutation.mutate(update, {
      onSuccess: () => {
        batch(() => {
          dispatch(setAuthorizationGroupUsersUpdatedFlag(true));
          dispatch(
            displayNotification({
              message: "permissions.content.detail.users.add.confirm.success",
              severity: "success"
            })
          );
        });
      },
      onError: () => {
        dispatch(
          displayNotification({
            message: "permissions.content.detail.users.add.confirm.failure",
            severity: "error"
          })
        );
      }
    });

    setAddModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <D3Button
        className={classes.addBtn}
        icon="person_add"
        variant="filled"
        onClick={() => setAddModalOpen(true)}
      />
      {addModalOpen && (
        <D3MultiSelectListModal
          title={translate("permissions.content.detail.users.add.title")}
          placeholder={translate("permissions.content.detail.users.add.search")}
          items={usersToAdd}
          showItemSeparator={false}
          onConfirm={onAdd}
          onCancel={onCancel}
          confirmLabel={{
            verb: translate("general.add"),
            plural: translate(
              "permissions.content.detail.users.add.confirm.plural"
            ),
            singular: translate(
              "permissions.content.detail.users.add.confirm.singular"
            )
          }}
        />
      )}
    </div>
  );
}
