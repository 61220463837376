import { useContext } from "react";
import classnames from "classnames";
import { Divider, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IPage } from "models/pages";
import { RowList } from "./pageElements";
import { ModuleLayoutContext } from "components/contexts/moduleLayoutContext";
import { AddPageElementFab } from "./addPageElementFab";
import { userService } from "services/userService";
import { translate } from "utils/i18n";
import { text } from "utils/textUtils";
import { makeSelectAssetsDefinition } from "redux/selectors/assetCatalogSelectors";
import { useRenderModule } from "components/siteStructure/pageView/useRenderModule";
import { OrphanContext } from "components/siteStructure/pageView/orphanContext";
import { useMemoSelector } from "components/hooks";
import { AssetNotFound } from "components/siteStructure/pageView/pageElements/assetNotFound";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { TemplateActionsBar } from "components/siteStructure/pageView/pageElements/templateActionsBar";
import { PermissionCodes } from "catalogs/permissionCodes";
import { CurrentSiteItemContext } from "components/contexts/currentSiteItemContext";
import { AssetTypes } from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";

interface IProps extends IBaseProps {
  page: IPage;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    flex: 1
  },
  content: {
    padding: theme.spacing(0, 3),
    flex: 1
  },
  addGutter: {
    marginBottom: theme.spacing(15)
  },
  addButton: {
    position: "absolute",
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  dividerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(3, 2, 0)
  },
  divider: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  notFound: {
    flex: 1
  },
  assetNotFoundAction: {
    color: theme.palette.custom.icons.lightGrey,
    "&:hover": {
      color: theme.palette.custom.secondaryAccent.tiffany
    }
  }
}));

const OrphanHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.dividerContainer}>
      {text(translate("sitestructure.orphanmodules"), {
        variant: "body2"
      })}
      <Divider className={classes.divider} />
    </div>
  );
};

export const PageLayout = (props: IProps) => {
  const { className, page } = props;
  const classes = useStyles();

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);
  const currentSiteItemPath = useContext(CurrentSiteItemContext);

  const renderOrphan = useRenderModule(page.id, true);

  const templateDefinition = useMemoSelector(
    makeSelectAssetsDefinition,
    AssetTypes.TEMPLATE,
    page.template.key
  );

  const rootClassName = classnames(classes.root, className);

  const contentClass = classnames(classes.content, {
    [classes.addGutter]:
      page.orphans.length === 0 &&
      (dropContext.dropMode !== "none" || copyContext.copyMode !== "none")
  });

  const orphansClass = classnames(classes.content, {
    [classes.addGutter]:
      page.orphans.length > 0 &&
      (dropContext.dropMode !== "none" || copyContext.copyMode !== "none")
  });

  const canAddPageElement =
    userService.hasPermissions(PermissionCodes.EditContent) ||
    userService.hasSiteItemPermissions(currentSiteItemPath);

  return (
    <ModuleLayoutContext.Provider value={[page.template.instanceId]}>
      <div className={rootClassName}>
        <TemplateActionsBar template={templateDefinition}></TemplateActionsBar>
        <OrphanContext.Provider value={false}>
          {templateDefinition ? (
            <RowList className={contentClass} rows={page.template.rows} />
          ) : (
            <AssetNotFound
              className={classes.notFound}
              assetType={translate("sitestructure.template")}
              assetName={page.template.key}
            />
          )}
        </OrphanContext.Provider>
        {page.orphans.length > 0 && (
          <>
            <OrphanHeader />
            <OrphanContext.Provider value={true}>
              <div className={orphansClass}>
                {page.orphans.map(renderOrphan)}
              </div>
            </OrphanContext.Provider>
          </>
        )}
      </div>
      {canAddPageElement && (
        <AddPageElementFab
          className={classes.addButton}
          pageContext={page.contextName}
        />
      )}
    </ModuleLayoutContext.Provider>
  );
};
