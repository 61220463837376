import { MouseEvent, useMemo } from "react";
import classnames from "classnames";
import {
  GridList,
  GridListTile,
  InputAdornment,
  makeStyles,
  TextField
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useSelector } from "react-redux";
import { IDomainState } from "models/domainStates";
import { LayoutTile } from "./layoutTile";
import { useDebouncedState } from "components/hooks";
import { NoResults } from "components/siteStructure/modals/selectPageElementModal/moduleGrid/noResults";
import { UxdIcon } from "components/shared/uxdIcon";
import { userService } from "services/userService";
import { translate } from "utils/i18n";
import { searchInputChooseElement } from "theme/commonClasses";
import { selectLayoutCatalog } from "redux/selectors/assetCatalogSelectors";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { orderBy } from "lodash";

interface IProps extends IBaseProps {
  onSelect: (event: MouseEvent, pageElementKey: string) => void;
  pageContext: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...searchInputChooseElement.root
  },
  tile: {
    ...searchInputChooseElement.tile
  },
  searchInput: {
    ...searchInputChooseElement.searchInput
  },
  searchInputIcon: {
    ...searchInputChooseElement.searchInputIcon
  },
  scrollable: {
    ...searchInputChooseElement.scrollable
  },
  noResults: {
    ...searchInputChooseElement.noResults
  },
  grid: {
    ...searchInputChooseElement.grid
  }
}));

export const LayoutGrid = (props: IProps) => {
  const { className, onSelect, pageContext } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [search, debouncedSearch, setSearch] = useDebouncedState("", 300);

  const layouts: ILayoutDefinition[] = useSelector((state: IDomainState) =>
    Object.values(selectLayoutCatalog(state, pageContext))
  );
  const allowedLayouts = layouts.filter((layout) =>
    userService.hasLayoutPermissions(layout.key)
  );

  const filterRegex = useMemo(
    () =>
      new RegExp(
        `(${debouncedSearch.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
        "i"
      ),
    [debouncedSearch]
  );

  const filteredLayout = useMemo(
    () => allowedLayouts.filter((l) => filterRegex.test(l.label)),
    [allowedLayouts, filterRegex]
  );

  const sortedLayoutList = useMemo(
    () => orderBy(filteredLayout, "label"),
    [filteredLayout]
  );

  return (
    <div className={rootClassName}>
      <TextField
        className={classes.searchInput}
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={translate("sitestructure.searchlayout")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <UxdIcon className={classes.searchInputIcon} name="search" />
            </InputAdornment>
          )
        }}
      />
      <div className={classes.scrollable}>
        {sortedLayoutList.length > 0 ? (
          <GridList
            cellHeight="auto"
            cols={1}
            spacing={0}
            className={classes.grid}
          >
            {sortedLayoutList.map((l) => (
              <GridListTile
                key={l.key}
                className={classes.tile}
                cols={1}
                onClick={(e) => {
                  onSelect(e, l.key);
                }}
              >
                <LayoutTile
                  layoutDefinition={l}
                  showHighlight={false}
                  highlightRegex={filterRegex}
                />
              </GridListTile>
            ))}
          </GridList>
        ) : (
          <NoResults className={classes.noResults} />
        )}
      </div>
    </div>
  );
};
