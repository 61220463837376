import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Chip, Typography, Link, Tooltip } from "@material-ui/core";
import { translate } from "utils/i18n";
import filter from "lodash/filter";
import { UxdIcon } from "components/shared/uxdIcon";
import { ISiteItem } from "models/siteItem";
import { loadVariablesModalAction } from "redux/actions/variablesActions";
import { IVariableContract, IVariableDetail } from "models/variables";
import {
  getDefaultVariableItem,
  getIsURIVariableByVariableType,
  getNonDefaultVariableItems,
  getVariableStatus,
  toVariableKey,
  VariableModalAction,
  VariableTypeKey
} from "utils/variableUtils";
import VariableItemActions from "components/siteStructure/variables/view/variableItemActions";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";

import LanguagesOverridesSection from "components/siteStructure/variables/view/languagesOverridesSection";

import { useStyles } from "./styles";

import {
  getAllowedValuesConfig,
  getSampleValueConfig
} from "components/siteStructure/variables/view/variableItem/utils";
import classNames from "classnames";

const curlifyValue = (value: string) => `{${value}}`;

export interface IOverrideSectionController {
  isExpanded: boolean;
  overridesCount: number;
  toggle: () => void;
}

interface IProps {
  variable: IVariableContract;
  siteItem: ISiteItem;
  onDelete: (variableKey: string) => void;
  onRestore: (variableKey: string) => void;
}

export const VariableItem = (props: IProps) => {
  const {
    variable,
    siteItem,
    onDelete: onVariableDelete,
    onRestore: onVariableRestore
  } = props;

  const classes = useStyles(props);
  const dispatch = useDispatch();

  const isURIVariable = getIsURIVariableByVariableType(
    variable.type as VariableTypeKey
  );

  const shouldWrapVariableNameInCurlyBrackets = isURIVariable;

  const [isLanguagesOverridesExpanded, setIsLanguagesOverridesExpanded] =
    useState<boolean>(false);

  const defaultItemDetail = useMemo(
    () =>
      variable.items.find((v) => v.environment === "*" && v.culture === "*"),
    [variable]
  );

  const variableLabel = useMemo(
    () =>
      shouldWrapVariableNameInCurlyBrackets
        ? curlifyValue(variable.key)
        : `@${variable.key}`,
    [variable.key, shouldWrapVariableNameInCurlyBrackets]
  );

  const onDelete = useCallback(
    (itemDetail: IVariableDetail) => {
      const key = toVariableKey(
        variable.key,
        itemDetail.culture,
        itemDetail.environment
      );

      onVariableDelete(key);
    },
    [onVariableDelete, variable.key]
  );

  // default detail is always present and we do not display anything if is't missing anyway

  const onRestore = useCallback(() => {
    const key = toVariableKey(
      variable.key,
      defaultItemDetail!.culture,
      defaultItemDetail!.environment
    );

    onVariableRestore(key);
  }, [onVariableRestore, defaultItemDetail, variable.key]);

  const status = useMemo(
    () => getVariableStatus(defaultItemDetail!),
    [defaultItemDetail]
  );

  const allowedValuesConfig = useMemo(
    () =>
      !defaultItemDetail || !isURIVariable
        ? undefined
        : getAllowedValuesConfig(defaultItemDetail, status),
    [isURIVariable, defaultItemDetail, status]
  );

  const sampleValueConfig = useMemo(
    () =>
      !defaultItemDetail || !isURIVariable
        ? undefined
        : getSampleValueConfig(defaultItemDetail, status),
    [isURIVariable, defaultItemDetail, status]
  );

  const onNav = useCallback((path: string) => {
    const url = `${window.location.origin}/#/sitestructure/${path}?tab=variables`;
    window.open(url, "_blank", "noopener, noreferrer");
  }, []);

  const local = status === "local";
  const overwritten = status === "overwriting";
  const editable = local || overwritten;

  const icon = editable ? "my_location" : "keyboard_return";
  const path = defaultItemDetail?.definedIn?.path ?? siteItem.path;

  const hasPermissions =
    userService.hasPermissions(PermissionCodes.EditVariables) ||
    userService.hasSiteItemPermissions(siteItem.path);

  const onEdit = useCallback(() => {
    if (hasPermissions) {
      const action = editable
        ? VariableModalAction.EDIT
        : VariableModalAction.OVERWRITE;

      /* For variable editing, we always pick the default variable detail */
      const variableDetail = getDefaultVariableItem(variable);

      dispatch(
        loadVariablesModalAction({
          action,
          type: variable.type,
          variable,
          variableDetail
        })
      );
    }
  }, [dispatch, editable, hasPermissions, variable]);

  const toggleLanguagesOverrides = useCallback(
    () => setIsLanguagesOverridesExpanded((current) => !current),
    []
  );

  const languagesOverridesDetails: IVariableDetail[] = useMemo(() => {
    const nonDefaultVariableDetails = getNonDefaultVariableItems(variable);

    if (!overwritten) {
      return nonDefaultVariableDetails;
    }

    return filter(
      nonDefaultVariableDetails,
      (detail) => getVariableStatus(detail) !== "inherited"
    );
  }, [overwritten, variable]);

  const overrideSectionController: IOverrideSectionController = {
    isExpanded: isLanguagesOverridesExpanded,
    overridesCount: languagesOverridesDetails.length,
    toggle: toggleLanguagesOverrides
  };

  if (!defaultItemDetail) {
    return <></>;
  }

  const allowedValuesClassnames = classNames([
    classes.primaryLabel,
    { [classes.italic]: isURIVariable && !allowedValuesConfig?.isDefined }
  ]);

  const sampleValueClassnames = classNames([
    classes.primaryLabel,
    { [classes.tertiaryLabel]: isURIVariable && !sampleValueConfig?.isDefined },
    { [classes.italic]: isURIVariable && !sampleValueConfig?.isDefined }
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.lineItemContainer}>
        <div className={classes.nameContainer}>
          <UxdIcon className={classes.icon} name={icon} />
          <Tooltip title={variable.key}>
            <Chip className={classes.name} label={variableLabel} />
          </Tooltip>
        </div>
        <div className={classes.infoContainer}>
          <Typography variant="body2" className={classes.value}>
            {defaultItemDetail?.value ?? defaultItemDetail.definedIn?.value}
          </Typography>
          {overwritten && (
            <div className={classes.overrideContainer}>
              <Chip
                className={classes.overrideChip}
                label={translate("sitestructure.variables.status.overwrites")}
              />
              <Typography variant="body2" className={classes.inherited}>
                {defaultItemDetail.definedIn?.value}
              </Typography>
            </div>
          )}

          {isURIVariable && (
            <>
              <div className={classes.row}>
                <Typography
                  variant="caption"
                  className={allowedValuesClassnames}
                >
                  {allowedValuesConfig?.value}
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography
                  variant="caption"
                  className={classes.secondaryLabel}
                >
                  <label>
                    {translate("sitestructure.variables.sample_value")}:
                  </label>
                  <label className={classes.spacer} />
                  <label className={sampleValueClassnames}>
                    {sampleValueConfig?.value}
                  </label>
                </Typography>
              </div>
            </>
          )}

          {!local && (
            <Typography variant="caption" className={classes.inherited}>
              {translate("sitestructure.variables.definedlocation")}
              <label className={classes.spacer} />
              <Link
                component="button"
                className={classes.inheritedPath}
                onClick={() => onNav(defaultItemDetail.definedIn!.path)}
              >
                {path}
              </Link>
            </Typography>
          )}
        </div>

        <VariableItemActions
          className={classes.actions}
          hasEditPermissions={hasPermissions}
          hoverableClassname={classes.hoverableActionButton}
          status={status}
          onEdit={onEdit}
          onDelete={() => onDelete(defaultItemDetail)}
          onRestore={onRestore}
          overrideSectionController={overrideSectionController}
          variable={variable}
          isSampleValueDefined={Boolean(sampleValueConfig?.isDefined)}
        />
      </div>
      {isLanguagesOverridesExpanded && (
        <LanguagesOverridesSection
          variable={variable}
          onDeleteTranslation={onDelete}
          variableDetails={languagesOverridesDetails}
          hasEditPermissions={hasPermissions}
        />
      )}
    </div>
  );
};
