import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { Hint } from "components/shared/hint";

interface IProps extends IBaseProps {
  value: string;
  hint?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16)
  }
}));

export const TextboxLabel = (props: IProps) => {
  const { className, value, hint } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const hintComponent = hint ? <Hint hint={hint} /> : <></>;

  return (
    <div className={rootClassName}>
      <Typography variant="caption">{value}</Typography>
      {hintComponent}
    </div>
  );
};
