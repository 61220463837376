import { makeStyles, Menu, Zoom } from "@material-ui/core";
import classnames from "classnames";
import { D3Button } from "components/shared/d3Components";
import { IBaseProps } from "components/_baseProps";
import React, { PropsWithChildren, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(7),
    width: theme.spacing(7)
  }
}));

/**
 * Props of `D3AddFab`
 */
export interface ID3AddFabProps extends IBaseProps {
  /**
   * Determines if the fab is visible or not
   */
  hidden?: boolean;
}

/**
 * Add Fab following the SXP Product Design System
 * @see [Design System](https://www.figma.com/file/mTyXrouZ9B4cHebcPOltDJ/Product-Design-System?node-id=1014%3A2&t=xPGuAYwNeEyCinbh-4)
 * @component
 * @param props
 */
export const D3AddFab = (props: PropsWithChildren<ID3AddFabProps>) => {
  const { hidden, children, className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClose = React.useCallback(() => setAnchorEl(null), []);
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    []
  );

  return (
    <>
      <Zoom in={!hidden} timeout={300} unmountOnExit>
        <D3Button icon="add" onClick={onClick} className={rootClassName} />
      </Zoom>

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClick={onClose}
        onClose={onClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default D3AddFab;
