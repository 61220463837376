export type IsAny<T, True, False = never> =
  // test if we are going the left AND right path in the condition
  true | false extends (T extends never ? true : false) ? True : False;

export type IsUnknown<T, True, False = never> = unknown extends T
  ? IsAny<T, False, True>
  : False;

export type FallbackIfUnknown<T, Fallback> = IsUnknown<T, Fallback, T>;

export const noop = () => {};
