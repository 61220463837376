import { useSelector, useDispatch } from "react-redux";
import { selectPasteSiteItem } from "redux/selectors/siteItem/pasteSiteItemSelector";
import { closePasteModal } from "redux/actions/siteItem/pasteSiteItemActions";
import { D3Modal } from "components/shared/d3Components";
import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { useContext } from "react";
import { SiteItemClipboardContext } from "components/contexts/siteItemClipboardContext";

export function PasteSiteItemModal() {
  const { open, node } = useSelector(selectPasteSiteItem);
  const dispatch = useDispatch();
  const clipboardContext = useContext(SiteItemClipboardContext);

  if (!node) {
    return null;
  }

  const onCancel = () => {
    dispatch(closePasteModal());
  };

  const onConfirm = () => {
    onPaste();
    onCancel();
  };

  const onPaste = () => {
    try {
      clipboardContext.paste(node.nodeId);
    } catch {}
  };

  return (
    <D3Modal
      open={open}
      modalTitle={translate("general.confirmationrequired")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelLabel={translate("general.confirmation.no_cancel")}
      confirmLabel={translate("general.confirmation.yes_proceed")}
    >
      <Typography>
        {translate("sitestructure.paste_with_alias.confirmtext")}
      </Typography>
    </D3Modal>
  );
}
