import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { fetchPage } from "redux/actions/siteStructureActions";
import { DeleteLayoutFromPage } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { RemoveLayoutPayload } from "redux/actions/typings/siteStructureActions";

export function* deleteLayout(action: Action<RemoveLayoutPayload>) {
  try {
    const { pageId, layoutInstanceId } = action.payload;

    const command = new DeleteLayoutFromPage({
      pageId,
      layoutInstanceId
    });

    const commandAction = issueCommand({
      command,
      onSuccessAction: fetchPage(pageId),
      errorMessage: "notification.deletelayout.error",
      successMessage: "notification.deletelayout.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
