import produce from "immer";
import { Action } from "redux-actions";

interface IReactions<T> {
  [actionName: string]: (state: T, action: Action<any>) => void;
}

export function coreReducer<TState>(
  state: TState,
  action: Action<any>,
  reactions: IReactions<TState>
) {
  return produce<TState, TState>(state, (draft: TState) => {
    const reaction = reactions[action.type];
    if (reaction) {
      return reaction(draft, action);
    }
  });
}

export function reducerFactory<TState>(
  initialState: TState,
  reactions: IReactions<TState>
) {
  return (state = initialState, action: Action<any>) =>
    coreReducer(state, action, reactions);
}
