import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.palette.custom.ds.grayscales.gr800
  },
  list: {
    margin: theme.spacing(1.5, 0),
    width: "75vh"
  },
  listItem: {
    backgroundColor: "white",
    marginBottom: "5px",
    border: "solid 1px transparent",
    height: theme.spacing(7.5),
    paddingLeft: theme.spacing(1),
    transition: "all .3s ease-in-out",
    "&:hover": {
      boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
      backgroundColor: "white"
    },
    "& .MuiTypography-body1": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20)
    },
    "& .MuiTypography-body2": {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16)
    }
  },
  listItemSelected: {
    transition: "all .3s ease-in-out",
    border: `solid 1px ${theme.palette.custom.ds.viking.viking500}`,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  },
  emptyLocal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.custom.ds.grayscales.gr600
  },
  removeModal: {
    "& .MuiDialog-paper": {
      width: theme.spacing(52.5) // 420px
    }
  },
  snackbar: {
    "& .MuiSnackbar-root": {
      width: "95%",
      left: "calc(47.5% + 24px)"
    }
  },
  hideSnackbar: {
    display: "none"
  },
  item: {
    height: theme.spacing(8),
    padding: theme.spacing(5, 1),
    "& .MuiListItemText-primary": {
      lineHeight: 2.0,
      fontSize: theme.typography.pxToRem(16)
    },
    "& .MuiListItemText-secondary": {
      lineHeight: 2.0,
      fontSize: theme.typography.pxToRem(14)
    }
  }
}));
