import classnames from "classnames";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { AutocompleteRenderInputParams } from "@material-ui/lab";
import { TagsPickerBreadcrumbs } from "./tagsPickerBreadcrumbs";
import { ExternalTagHierarchy } from "./tagsPicker";

interface IProps extends IBaseProps, AutocompleteRenderInputParams {
  hierarchy: ExternalTagHierarchy;
  loading: boolean;
  error: string;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    color: theme.palette.custom.mainAccent.red
  }
}));

export const TagsPickerInput = (props: IProps) => {
  const { className, hierarchy, loading, error, mandatory, ...params } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const mandatoryText = mandatory.showMessage ? mandatory.message : "";
  const inputClassNames = `${
    mandatory.showMessage ? mandatory.className : ""
  } ${rootClassName}`;

  return (
    <TextField
      {...params}
      className={inputClassNames}
      variant="outlined"
      placeholder={hierarchy.length ? "…" : translate("tagspicker.placeholder")}
      helperText={error ? error : mandatoryText}
      InputProps={{
        ...params.InputProps,
        startAdornment: <TagsPickerBreadcrumbs hierarchy={hierarchy} />,
        endAdornment: loading ? <CircularProgress size={24} /> : <></>
      }}
      inputProps={{
        ...params.inputProps,
        pattern: "\\w"
      }}
      FormHelperTextProps={{
        className: error ? classes.error : ""
      }}
    />
  );
};
