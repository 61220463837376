import { IBaseProps } from "components/_baseProps";
import { useEffect } from "react";

import { translate } from "utils/i18n";

import { useStyles } from "./styles";

import UrlsAndAliasesList from "components/siteStructure/aliases/components/urlsAndAliasesList";
import { useUrlsAndAliasesContext } from "components/siteStructure/aliases/context";
import { find, isEmpty } from "lodash";
import UrlAliasSectionHeader from "./urlAliasSectionHeader";
import UrlAliasSubmitButtons from "./urlAliasSubmitButtons";
import { PromptValidationMessage, SaveWarningMessage } from "./warningMessages";

const getSaveButtonLabel = (
  isSaveWarningModeActive: boolean,
  isSetTranslationLoading: boolean,
  shouldShowPromptValidationMessage: boolean
) =>
  find(
    [
      {
        condition:
          isSaveWarningModeActive && !shouldShowPromptValidationMessage,
        label: translate(
          "sitestructure.urlssection.prompt_messages.save_confirm"
        )
      },
      {
        condition: isSetTranslationLoading,
        label: translate("sitestructure.urlssection.prompt_messages.saving")
      },
      {
        condition: true,
        label: translate("sitestructure.urlssection.prompt_messages.save")
      }
    ],
    "condition"
  )?.label;

interface IProps extends IBaseProps {
  onClose: () => void;
}

export default function MainSectionComponent(props: IProps) {
  const { onClose } = props;
  const classes = useStyles();

  const {
    loaders,
    onSaveSettings,
    areThereBatchedUpdates,
    aliasesManagement,
    urlTranslationManagement,
    setIsSavePromptOpened,
    isSavePromptOpened
  } = useUrlsAndAliasesContext();

  const { canSaveTranslations } = urlTranslationManagement;

  const { canSaveAliases, promptValidations: aliasesPromptValidations } =
    aliasesManagement;

  const areThereAliasPromptValidations = !isEmpty(aliasesPromptValidations);

  const onSaveButtonClick = () => {
    if (areThereAliasPromptValidations) {
      return;
    }

    if (!isSavePromptOpened) {
      setIsSavePromptOpened(true);
      return;
    }
    setIsSavePromptOpened(false);
    onSaveSettings();
  };

  useEffect(() => {
    if (!areThereBatchedUpdates) {
      setIsSavePromptOpened(false);
    }
  }, [setIsSavePromptOpened, areThereBatchedUpdates]);

  const shouldShowPromptValidation =
    isSavePromptOpened && areThereAliasPromptValidations;

  const shouldShowSaveWarning =
    isSavePromptOpened && !shouldShowPromptValidation;

  const saveButtonLabel = getSaveButtonLabel(
    isSavePromptOpened,
    loaders.isSetTranslationLoading,
    shouldShowPromptValidation
  );

  const isSaveButtonDisabled =
    loaders.isSetTranslationLoading || !canSaveAliases || !canSaveTranslations;

  return (
    <div className={classes.container}>
      <UrlAliasSectionHeader onCloseButtonClick={onClose} />
      <div className={classes.body}>
        <UrlsAndAliasesList onClose={onClose} />
      </div>
      {areThereBatchedUpdates && (
        <div className={classes.footer}>
          <div className={classes.warningSection}>
            <PromptValidationMessage showWarning={shouldShowPromptValidation} />
            <SaveWarningMessage showWarning={shouldShowSaveWarning} />
          </div>

          <UrlAliasSubmitButtons
            disabled={isSaveButtonDisabled}
            label={saveButtonLabel}
            showCancelButton={shouldShowSaveWarning}
            onSave={onSaveButtonClick}
            onCancel={() => setIsSavePromptOpened(false)}
          />
        </div>
      )}
    </div>
  );
}
