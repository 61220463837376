import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { IBaseProps } from "components/_baseProps";

interface IProps extends IBaseProps {}

const useStyles = makeStyles(() => ({
  root: {
    fill: "none"
  },
  path: {
    fill: "white"
  }
}));

export function DefaultModuleIcon(props: IProps) {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const pathClassName = classnames(classes.path, className);

  return (
    <svg
      className={rootClassName}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.091 0.424805L15.682 4.73871V13.2587L8.091 17.5751L0.5 13.2587V4.7387L8.091 0.424805ZM3.57669 5.24411L8.09076 7.72685L12.6051 5.24398L8.091 2.67866L3.57669 5.24411ZM9.07044 9.4244V14.764L13.7225 12.1187V6.86578L9.07044 9.4244ZM2.45954 6.86604L7.1109 9.42429V14.7636L2.45954 12.1187V6.86604Z"
      />
    </svg>
  );
}
