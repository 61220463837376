import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { RemoveSitePage } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";

export function* deletePage(action: Action<string>) {
  try {
    const pageId = action.payload;

    const command = new RemoveSitePage({
      pageId
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.deletepage.error",
      successMessage: "notification.deletepage.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
