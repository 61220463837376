import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginRight: theme.spacing(2)
  },

  searchBarContainer: {
    border: "2px solid transparent",
    borderRadius: theme.spacing(0.25),
    margin: theme.spacing(1),
    alignItems: "center",
    display: "flex",
    width: theme.spacing(5),
    height: theme.spacing(5),
    transition: "width .2s ease-out",
    flexDirection: "column"
  },

  searchBarContainerExpanded: {
    borderColor: theme.palette.custom.viking.viking500,
    width: theme.spacing(46.25),
    "& $searchTextField": {
      width: theme.spacing(46.25)
    }
  },

  searchTextField: {
    width: "100%",

    color: theme.palette.custom.ds.grayscales.gr800,
    "& input": {
      paddingLeft: theme.spacing(1)
    }
  },

  button: {
    borderRadius: 2,
    width: theme.spacing(5),
    height: theme.spacing(5),
    position: "relative",
    top: "0px !important",
    left: "0px !important",

    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    },

    "&:hover $searchIcon": {
      color: `${theme.palette.custom.viking.viking500} !important`
    }
  },
  buttonActive: {
    borderRadius: 2,
    position: "relative",
    right: "10px",
    top: "2px",

    "& $searchIcon": {
      color: `${theme.palette.custom.viking.viking500} !important`
    },
    "& span": {
      fontSize: theme.typography.pxToRem(24),
      color: theme.palette.custom.viking.viking500
    }
  },
  searchIcon: {
    color: `${theme.palette.custom.ds.grayscales.gr800} !important`
  },
  title: {
    margin: theme.spacing("auto", 0),
    fontSize: "16px",
    paddingLeft: theme.spacing(2),
    fontWeight: 400
  },

  closeIconButton: {
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(5),
    height: theme.spacing(5),
    "&:hover": {
      background: "none"
    }
  },
  closeIcon: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr500
  },
  threeLettersMessage: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.ds.grayscales.gr600,
    position: "relative",
    left: "-51px",
    top: "5px"
  }
}));
