import { reduce } from "lodash";
import {
  NEW_NAVIGATION_RULE_ID,
  getLinkRulesEntitiesMetadata
} from "components/linkRules/utils";
import { IDomainState } from "models/domainStates";
import { ILinkRuleEntityMetadata, ILinkRuleEntry } from "models/linkRules";

export const selectLinkRulesEntityList = (state: IDomainState) => {
  const { linkRulesEntityList } = state.linkRules;
  return linkRulesEntityList;
};

export const selectNavigationRulesCount = (state: IDomainState) => {
  const { linkRulesEntityList } = state.linkRules;

  const keys = Object.keys(linkRulesEntityList);

  return reduce(
    keys,
    (count, key) => count + linkRulesEntityList[key].linkRules.length,
    0
  );
};

export const selectLinkRulesEntitiesMetadata = (
  state: IDomainState
): ILinkRuleEntityMetadata[] =>
  getLinkRulesEntitiesMetadata(state.linkRules.linkRulesEntityList);

export const selectSelectedLinkRuleId = (state: IDomainState) =>
  state.linkRules.selectedLinkRuleId;

export const selectSelectedLinkRule = (state: IDomainState) => {
  const selectedId = state.linkRules.selectedLinkRuleId;
  if (!selectedId || selectedId === NEW_NAVIGATION_RULE_ID) {
    return undefined;
  }

  const list = state.linkRules.linkRulesEntityList;
  const keys = Object.keys(list);
  const intialValue: ILinkRuleEntry[] = [];

  const ungroupedLinkRules = keys.reduce(
    (result, key) => [...result, ...list[key].linkRules],
    intialValue
  );

  return ungroupedLinkRules.find((linkRule) => linkRule.id === selectedId);
};

export const selectIsLinkRulesSidebarOpen = (state: IDomainState) =>
  state.linkRules.selectedLinkRuleId !== null;
