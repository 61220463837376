import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";

import { IBaseProps } from "components/_baseProps";

import { useStyles } from "./styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { find } from "lodash";
import { useCallback, useMemo } from "react";
import { useUrlsAndAliasesContext } from "components/siteStructure/aliases/context";
import { IAliasMetadataForm } from "components/siteStructure/aliases/types";
import { D3DropdownTextarea } from "components/shared/d3Components/d3DropdownTextarea";

import {
  getAllowedValuesMap,
  parseDynamicParameterAllowedValues
} from "components/siteStructure/aliases/utils";
import CheckIcon from "components/shared/checkIcon";
import { getAllowedValuesErrorConfigMap } from "components/siteStructure/aliases/components/allowedValuesContainer/utils";

interface IProps extends IBaseProps {
  aliasForm: IAliasMetadataForm;
}

export default function AllowedValuesContainerComponent(props: IProps) {
  const { aliasForm } = props;
  const { culture } = aliasForm;
  const classes = useStyles();

  const { aliasesManagement } = useUrlsAndAliasesContext();

  const {
    urlMetadata,
    paramsValidationMap,
    getPromptValidationForCulture,
    onBlurUiOperation,
    onUpdateAliasParam
  } = aliasesManagement;

  const allowedValuesMap: Record<string, string> = useMemo(
    () => getAllowedValuesMap(aliasForm, urlMetadata),
    [aliasForm, urlMetadata]
  );

  const validation = paramsValidationMap[culture];

  const getAllowedValuesFieldErrorMessage = useCallback(
    (culture: string, paramName: string) => {
      const promptValidationForCulture = getPromptValidationForCulture(
        culture,
        { paramName }
      );

      const errorConfigMap = getAllowedValuesErrorConfigMap({
        paramName,
        promptValidationForCulture,
        validation
      });

      const entry = find(errorConfigMap, "condition");

      if (entry === undefined) {
        return undefined;
      }

      return entry.getMessage();
    },
    [getPromptValidationForCulture, validation]
  );

  const getAllowedValuesFieldLabelsConfig = useCallback(
    (culture: string, paramName: string) => {
      const rawParamValues = allowedValuesMap[paramName];
      const parsedValues = parseDynamicParameterAllowedValues(rawParamValues);

      const translationKeyPrefix =
        "sitestructure.urlssection.allowed_values_insert";

      const insertMode = {
        icon: <UxdIcon className={classes.infoIcon} name="info" />,
        label: translate(`${translationKeyPrefix}.insert`, { paramName })
      };

      const filledMode = {
        icon: <CheckIcon className={classes.checkIcon} checked={true} />,
        label: translate(`${translationKeyPrefix}.filled`, {
          numberOfValues: parsedValues.length,
          paramName
        }) as string
      };

      const invalidMode = {
        ...insertMode,
        icon: <UxdIcon className={classes.errorIcon} name="info" />,
        message: getAllowedValuesFieldErrorMessage(culture, paramName)
      };

      return {
        insertMode,
        filledMode,
        invalidMode
      };
    },
    [
      allowedValuesMap,
      classes.infoIcon,
      classes.checkIcon,
      classes.errorIcon,
      getAllowedValuesFieldErrorMessage
    ]
  );

  return (
    <div className={classes.allowedValuesSectionContainer}>
      <Typography className={classes.allowedValuesHeader} component="h4">
        {translate(
          "sitestructure.urlssection.allowed_values_description.title"
        )}
        <br />
        {translate("sitestructure.urlssection.allowed_values_description.info")}
      </Typography>
      {urlMetadata.paramNames.map((paramName) => (
        <div key={paramName} className={classes.allowedValuesControlContainer}>
          <D3DropdownTextarea
            placeholder={translate(
              "sitestructure.urlssection.allowed_values_insert.placeholder"
            )}
            value={allowedValuesMap[paramName]}
            onBlur={() =>
              onBlurUiOperation("PARAM_INSERT", aliasForm, paramName)
            }
            onChange={(value) => {
              onUpdateAliasParam(culture, paramName, value);
            }}
            {...getAllowedValuesFieldLabelsConfig(culture, paramName)}
            isValid={
              !Boolean(getAllowedValuesFieldErrorMessage(culture, paramName))
            }
          />
        </div>
      ))}
    </div>
  );
}
