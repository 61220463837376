import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      overflowY: "hidden"
    }
  },
  content: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: theme.spacing(4),
    padding: theme.spacing(0, 1),
    "& > div": {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    }
  },
  description: {
    lineHeight: theme.typography.pxToRem(16),
    paddingLeft: theme.spacing(4),
    color: theme.palette.custom.ds.grayscales.gr600
  }
}));
