import { useState } from "react";
import MetadataVariableInput from "./metadataVariableInput";
import { D3Dropdown } from "components/shared/d3Components";
import {
  ID3DropdownOption,
  toDropdownOption
} from "components/shared/d3Components/d3Dropdown";
import { useStyles } from "./metadataInput";
import { IBaseProps } from "components/_baseProps";
import { isValidVariableFormat } from "utils/variableUtils";

interface IBoolMetadataInputProps extends IBaseProps {
  value: string;
  disabled?: boolean;
  onBoolSubmit: (bool: boolean) => void;
  onVariableSubmit: (variable: string) => void;
  onTypeSwitch: () => void;
}
export const BoolMetadataInput = (props: IBoolMetadataInputProps) => {
  const {
    className,
    value,
    onBoolSubmit,
    onVariableSubmit,
    onTypeSwitch,
    disabled = false
  } = props;
  const classes = useStyles();

  const [useVariable, setUseVariable] = useState<boolean>(
    isValidVariableFormat(value)
  );

  const options = {
    false: toDropdownOption("False"),
    true: toDropdownOption("True"),
    variable: toDropdownOption("Variable"),
    noValue: toDropdownOption("No Value", true)
  };

  const getCurrentOption = (value: string) => {
    switch (value) {
      case "true":
        return options.true;

      case "false":
        return options.false;

      case "":
        return options.noValue;

      default:
        return options.variable;
    }
  };

  const onSelect = (selected: ID3DropdownOption) => {
    const value = selected.value.toLowerCase().trim();

    switch (value) {
      case "true":
        setUseVariable(false);
        onBoolSubmit(true);
        break;

      case "false":
        setUseVariable(false);
        onBoolSubmit(false);
        break;

      default:
        setUseVariable(true);
        onTypeSwitch();
        break;
    }
  };

  const option = getCurrentOption(value).value;
  return (
    <div className={classes.grid}>
      <D3Dropdown
        className={className}
        value={option}
        options={Object.values(options)}
        onSelect={onSelect}
        disabled={disabled}
      />
      {useVariable && (
        <MetadataVariableInput
          className={className}
          value={value}
          variables={[]}
          onSubmit={onVariableSubmit}
        />
      )}
    </div>
  );
};

export default BoolMetadataInput;
