import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.custom.ds.error.error500
  },
  collapsed: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    height: "100vh",
    display: "flex",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export const CollapsedSidebarContent = () => {
  const classes = useStyles();

  return <div className={classes.collapsed} />;
};
