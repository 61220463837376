import React from "react";
import store from "redux/store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { HashRouter } from "react-router-dom";
import App from "components/app";
import { theme } from "theme/theme";
import * as serviceWorker from "serviceWorker";
import {
  RegistrationModeError,
  ServerUnavailableError,
  UnauthorizedError,
  UserTokenError
} from "models/errors";
import RegistrationMode from "components/registrationMode";
import { initialize } from "initialization";
import { ServerUnavailable } from "components/errorPages/serverUnavailable";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "components/errorBoundary";
import { redirectToLogin, redirectToLogout } from "./utils/appUtils";
import { UserTokenErrorDialog } from "components/userTokenErrorDialog";

const render = (
  component:
    | React.FunctionComponentElement<any>
    | React.FunctionComponentElement<any>[]
) => ReactDOM.render(component, document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  }
});

const renderApp = () => {
  render(
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools />
            </QueryClientProvider>
          </HashRouter>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
};

const renderRegistrationMode = () => {
  render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <RegistrationMode />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const renderServerUnavailable = () => {
  render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <ServerUnavailable />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const renderUserTokenError = () => {
  render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <UserTokenErrorDialog open={true} onClose={redirectToLogout} />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const handleError = (error: Error) => {
  // TODO: log properly
  console.error(error.message);

  if (error instanceof RegistrationModeError) {
    return renderRegistrationMode();
  }
  if (error instanceof ServerUnavailableError) {
    return renderServerUnavailable();
  }
  if (error instanceof UnauthorizedError) {
    return redirectToLogin();
  }

  if (error instanceof UserTokenError) {
    return renderUserTokenError();
  }

  // TODO: Better error page
  return renderServerUnavailable();
};

initialize().then(renderApp).catch(handleError);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
