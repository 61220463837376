import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  validationContainer: {
    // height: "30px",
    // border: "1px solid black",
    position: "relative",
    top: "-0.7rem",
    marginLeft: "5.7rem",
    marginRight: "4.5rem"
  },
  fieldValidation: {
    color: theme.palette.custom.secondaryAccent.orange,
    fontSize: theme.typography.pxToRem(12),

    "& ul": {
      listStyleType: "none",
      padding: "0px",
      margin: "0px"
    }
  }
}));
