/**
 * Why setting prototype explicitly?
 * https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
 */

export class RegistrationModeError extends Error {
  constructor() {
    const message =
      "Application is in registration mode. Please register it on GUIShell before, then refresh this page.";

    super(message);
    Object.setPrototypeOf(this, RegistrationModeError.prototype);
  }
}

export class ServerUnavailableError extends Error {
  constructor() {
    const message =
      "The server is currently unavailable. Try to refresh the browser in a while";

    super(message);
    Object.setPrototypeOf(this, ServerUnavailableError.prototype);
  }
}

export class UnauthorizedError extends Error {
  constructor() {
    const message = "User unauthorized. Login needed.";

    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class UserTokenError extends Error {
  constructor() {
    const message = "Error while fetching the user access token. Login needed.";

    super(message);
    Object.setPrototypeOf(this, UserTokenError.prototype);
  }
}
