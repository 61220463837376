import { CopyPageElementSnackbar } from "./copyPageElementSnackbar";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch } from "react-redux";

import {
  copyModule,
  unsetSelectedPageElement
} from "redux/actions/siteStructureActions";

const NOTHING_SELECTED_ERROR_MESSAGE =
  "[CopyPageElementContext] Need to select a page element with a specific mode before dropping";
const MISSING_INSTANCEID_ERROR_MESSAGE =
  "[CopyPageElementContext] Missing instance id of the selected element";

export type CopyMode = "none" | "copy";

type ElementIdentifier = {
  instanceId?: string;
  key: string;
  type: "Module";
};

const DEFAULT_IDENTIFIER: ElementIdentifier = {
  key: "",
  type: "Module"
};

type CopyPageElementContextShape = {
  copyMode: CopyMode;
  pageElementIdentifier: ElementIdentifier;
  select: (copyMode: CopyMode, identifier: ElementIdentifier) => void;
  copy: (layoutId: string, slot: string, position: number) => void;
  clear: () => void;
};

export const CopyPageElementContext =
  createContext<CopyPageElementContextShape>({
    copyMode: "copy",
    pageElementIdentifier: DEFAULT_IDENTIFIER,
    select: () => undefined,
    copy: () => undefined,
    clear: () => undefined
  });

interface Props {
  pageId: string;
}

export const CopyPageElementContextProvider = (
  props: PropsWithChildren<Props>
) => {
  const dispatch = useDispatch();

  const { children, pageId } = props;

  const [elementIdentifier, setElementIdentifier] =
    useState<ElementIdentifier>(DEFAULT_IDENTIFIER);
  const [sourcePageId, setSourcePageId] = useState<string | null>(null);

  const [copyMode, setCopyMode] = useState<CopyMode>("none");

  const clear = useCallback(() => {
    setCopyMode("none");
    setElementIdentifier(DEFAULT_IDENTIFIER);
    setSourcePageId(null);
  }, []);
  const select = useCallback(
    (mode: CopyMode, identifier: ElementIdentifier) => {
      setCopyMode(mode);
      setElementIdentifier(identifier);
      setSourcePageId(pageId);
    },
    [pageId]
  );

  const copy = useCallback(
    (layoutId, slot, position) => {
      const { key, type, instanceId } = elementIdentifier;
      if (!instanceId) {
        console.error(MISSING_INSTANCEID_ERROR_MESSAGE);
        return;
      }
      if (!checkSelected(copyMode, key)) {
        return;
      }

      if (type !== "Module") {
        return;
      }

      // unselect source element
      dispatch(unsetSelectedPageElement());

      dispatch(
        copyModule({
          sourcePageId: sourcePageId || pageId,
          sourceInstanceId: instanceId,
          targetPageId: pageId,
          targetInstanceId: layoutId,
          targetSlot: slot,
          targetPosition: position
        })
      );

      clear();
    },
    [clear, dispatch, copyMode, elementIdentifier, pageId, sourcePageId]
  );

  // cleanup on page change
  useEffect(() => {
    if (copyMode !== "none") {
      return;
    }

    // This shoudn't be done for copy module across different page
    return clear();
  }, [copyMode, pageId, clear]);

  const contextValue: CopyPageElementContextShape = useMemo(
    () => ({
      copyMode,
      pageElementIdentifier: elementIdentifier,
      select,
      copy,
      clear
    }),
    [copy, clear, select, elementIdentifier, copyMode]
  );

  return (
    <CopyPageElementContext.Provider value={contextValue}>
      {children}
      <CopyPageElementSnackbar
        mode={copyMode}
        elementType={elementIdentifier.type}
        onCancel={clear}
      />
    </CopyPageElementContext.Provider>
  );
};

function checkSelected(copyMode: CopyMode, key: string) {
  if (copyMode === "none" || !key) {
    console.error(NOTHING_SELECTED_ERROR_MESSAGE);
    return false;
  }
  return true;
}
