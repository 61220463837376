import classnames from "classnames";
import { Button, DialogContent, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { useCallback, useContext, useMemo } from "react";
import { ContentPickerContext } from "components/contentPicker/contentPickerContext";
import { ContentPickerFilters } from "components/contentPicker/contentPickerFilters";
import { ContentPickerContainer } from "components/contentPicker/contentPickerContainer";
import { ForgeEntity } from "models/forgeEntities";
import { DialogHeader } from "components/shared/dialogHeader";
import { DialogFooter } from "components/shared/dialogFooter";

interface IProps extends IBaseProps {
  open: boolean;
  onClose: (selected: ForgeEntity | null) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  filters: {},
  content: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  scrollable: {
    position: "relative",
    flex: 1,
    overflowY: "auto",
    minHeight: 0
  },
  footer: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
    "&$error": {
      color: theme.palette.custom.mainAccent.red
    }
  },
  error: {}
}));

export const ContentPickerBody = (props: IProps) => {
  const { onClose } = props;
  const classes = useStyles();

  const context = useContext(ContentPickerContext);

  const [type, code] = useMemo(
    () => context.entityTypeCode.split("."),
    [context.entityTypeCode]
  );

  const closeWithoutSelection = useCallback(() => onClose(null), [onClose]);
  const closeWithSelection = useCallback(
    () => onClose(context.selected),
    [onClose, context.selected]
  );

  const dialogTitle = translate("contentpicker.dialogtitle", {
    entityType: code || type
  });

  const hasSelected = Boolean(context.selected);
  const selectedHasSlug = hasSelected && context.selected?.slug;

  const footerCaption = context.selected
    ? context.selected.slug
      ? context.selected.title || translate("contentpicker.notitle")
      : translate("contentpicker.missingslugerror")
    : "";

  const footerClassName = classnames(classes.footer, {
    [classes.error]: !selectedHasSlug
  });

  const actions = (
    <Button
      variant="contained"
      color="secondary"
      onClick={closeWithSelection}
      disabled={!context.selected?.slug}
    >
      {translate("contentpicker.select")}
    </Button>
  );

  return (
    <>
      <DialogHeader title={dialogTitle} onClose={closeWithoutSelection} />
      <DialogContent className={classes.content}>
        <ContentPickerFilters className={classes.filters} />
        <div id="content-picker-scrollable" className={classes.scrollable}>
          <ContentPickerContainer />
        </div>
      </DialogContent>
      <DialogFooter
        className={footerClassName}
        caption={footerCaption}
        actions={actions}
      />
    </>
  );
};
