import status from "statuses";
import {
  RegistrationModeError,
  ServerUnavailableError,
  UnauthorizedError
} from "models/errors";
import { translate } from "utils/i18n";

const REGISTRATION_MODE_HEADER = "X-RegistrationMode";

export class ApiClient {
  static readonly get = async (url: string, options: RequestInit = {}) =>
    ApiClient.fetch(url, options);

  static readonly post = async (
    url: string,
    body: any,
    options: RequestInit = {}
  ) => {
    const bodyString = typeof body === "string" ? body : JSON.stringify(body);

    const requestOptions: RequestInit = {
      ...options,
      headers: {
        ...options.headers,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: bodyString
    };

    return ApiClient.fetch(url, requestOptions);
  };

  static readonly put = async (
    url: string,
    body: any,
    options: RequestInit = {}
  ) => {
    const bodyString = typeof body === "string" ? body : JSON.stringify(body);

    const requestOptions: RequestInit = {
      ...options,
      headers: {
        ...options.headers,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PUT",
      body: bodyString
    };

    return ApiClient.fetch(url, requestOptions);
  };

  static readonly delete = async (url: string, options: RequestInit = {}) =>
    ApiClient.fetch(url, { ...options, method: "DELETE" });

  private static readonly fetch = async (
    url: string,
    options: RequestInit = {}
  ) => {
    const response = await fetch(url, {
      ...options,
      credentials: "include"
    });

    if (!response.ok) {
      if (response.headers.has(REGISTRATION_MODE_HEADER)) {
        throw new RegistrationModeError();
      }

      if (response.status === 401) {
        // Fire an event to allow maximum flexibility
        window.dispatchEvent(new Event("session_expired"));
        throw new UnauthorizedError();
      } else if (response.status === 504) {
        throw new ServerUnavailableError();
      } else if (response.status === 403) {
        throw new Error(translate("general.forbidden"));
      }

      // HTTP/2 does not fill the response.statusText property
      let statusText = status.message[response.status];
      throw new Error(statusText);
    }

    return response.json();
  };
}
