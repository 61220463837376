import { IBaseProps } from "components/_baseProps";
import { makeStyles } from "@material-ui/core";

import classnames from "classnames";
import { DrawerEntries } from "./drawerEntries";
import { Logo } from "components/shared/logo";
import { GuishellDrawer } from "components/appContainer/appToolbar/guishellDrawer";
import { StatusIcon } from "components/appContainer/appToolbar/statusIcon";
import { UserAvatar } from "components/appContainer/appToolbar/userAvatar";
import { CommandStatus } from "models/commandStatus";
import { themeSizing } from "theme/deltatreTheme";

interface IProps extends IBaseProps {
  currentPath: string;
  commandStatus: CommandStatus;
  onMenuClick: () => void;
  userData: {
    id: string;
    initials: string;
    fullName: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.custom.ds.fiord.fiord700,
    zIndex: 2,
    height: "100%",
    minWidth: themeSizing.drawer,
    maxWidth: themeSizing.drawer,
    width: themeSizing.drawer,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(3, 0),
    width: "100%"
  },
  logoWrap: {
    display: "flex",
    marginBottom: theme.spacing(3.125),
    cursor: "pointer"
  },
  logo: {
    width: theme.spacing(4)
  },
  subElement: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "48px",
    "&:hover, &:focus": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      "& $text": {
        zIndex: 1,
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.1,
        backgroundColor: theme.palette.common.black,
        height: "100%",
        width: "100%"
      }
    },
    "&:active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      }
    },
    "&$active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.3,
        backgroundColor: theme.palette.common.black
      }
    }
  },
  text: {},
  active: {}
}));

export const MiniDrawer = (props: IProps) => {
  const { className, currentPath, commandStatus, userData, onMenuClick } =
    props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.drawerHeader}>
        <span className={classes.logoWrap} onClick={onMenuClick}>
          <Logo className={classes.logo} variant="icon" />
        </span>
        <DrawerEntries currentPath={currentPath} compact={true} />
      </div>
      <div className={classes.drawerHeader}>
        <StatusIcon status={commandStatus} />
        <div className={classes.subElement}>
          <GuishellDrawer />
        </div>
        <div className={classes.subElement}>
          <UserAvatar
            userId={userData.id}
            initials={userData.initials}
            fullName={userData.fullName}
            compact={true}
          />
        </div>
      </div>
    </div>
  );
};
