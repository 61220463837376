import { makeStyles } from "@material-ui/core";
import { themeSizing } from "theme/deltatreTheme";
import { theme } from "theme/theme";

export const LINK_RULE_ENTITY_SIDE_NAV_WIDTH =
  themeSizing.siteTree - theme.spacing(4);

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    width: LINK_RULE_ENTITY_SIDE_NAV_WIDTH,
    position: "relative"
  },
  title: {
    backgroundColor: "white",
    height: theme.spacing(11),
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(30),
    paddingLeft: theme.spacing(4)
  },
  listContainer: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    overflow: "auto",
    padding: theme.spacing(1, 1, 1, 2)
  },
  list: {
    paddingBottom: theme.spacing(50)
  },
  buttonWrap: {
    left: "0",
    bottom: "0",
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  addButton: {
    margin: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(2)}px - ${theme.spacing(3)}px)`,
    whiteSpace: "nowrap"
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    marginLeft: theme.spacing(1)
  }
}));
