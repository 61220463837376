import { spawn } from "redux-saga/effects";
import { Action } from "redux-actions";
import requestMonitor from "redux/sagas/interceptors/requestMonitorSaga";
import { REQUEST_ACTION_SUFFIX } from "redux/actions/constants";

export function* rootInterceptor(action: Action<any>) {
  if (action.type.endsWith(REQUEST_ACTION_SUFFIX)) {
    yield spawn(requestMonitor, action);
  }
}
