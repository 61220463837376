import { useContext } from "react";
import classnames from "classnames";
import { makeStyles, TableRow, TableCell } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { SiteNodeInfo } from "components/shared/siteNodeInfo";
import { LastUpdatedInfo } from "components/shared/lastUpdatedInfo";
import ContextualMenu from "./contextualMenu";
import { ISiteItem } from "models/siteItem";
import {
  SiteItemClipboardContext,
  SiteItemClipboardContextShape
} from "components/contexts/siteItemClipboardContext";
import { userService } from "services/userService";

interface IProps extends IBaseProps {
  node: ISiteItem;
  onRowClick?: (node: ISiteItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    boxSizing: "border-box",
    "&:hover": {
      boxShadow: `0px ${theme.spacing(0.5)}px ${theme.spacing(
        2
      )}px rgba(0, 0, 0, 0.1)`,
      outline: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
      background: theme.palette.custom.ds.grayscales.gr200
    }
  },
  menuCell: {
    paddingRight: theme.spacing(0)
  },
  rowCut: {
    opacity: "0.6"
  },
  disabled: {
    opacity: 0.4
  },
  parent: {
    opacity: 1
  }
}));

const isCutItemOrParent = (
  clipboardContext: SiteItemClipboardContextShape,
  node: ISiteItem
) => {
  const { sourceNode, operation } = clipboardContext;
  if (!sourceNode || operation !== "Cut") {
    return false;
  }

  const isCut =
    node.nodeId === sourceNode.nodeId ||
    node.parentNodesIds.includes(sourceNode.nodeId);
  return isCut;
};

export const ChildrenListRow = (props: IProps) => {
  const { className, node, onRowClick = () => {} } = props;
  const classes = useStyles();
  const clipboardContext = useContext(SiteItemClipboardContext);

  const hasPermissions = userService.hasSiteItemPermissions(node.path);

  const isParentOfPermissionedItem = userService.hasSiteItemViewPermission(
    node.path
  );

  const rootClassName = classnames(classes.root, className, {
    [classes.rowCut]: isCutItemOrParent(clipboardContext, node),
    [classes.disabled]: !hasPermissions,
    [classes.parent]: isParentOfPermissionedItem
  });

  const onClick = () => {
    onRowClick(node);
  };

  return (
    <TableRow className={rootClassName} role="button">
      <TableCell align="left" onClick={onClick}>
        <SiteNodeInfo
          size="small"
          label={node.label}
          nodeType={node.nodeType}
          stage={node.status}
          contextName={node.contextName}
        />
      </TableCell>
      <TableCell align="left" onClick={onClick}>
        <LastUpdatedInfo updateInfo={node.updateInfo} />
      </TableCell>
      <TableCell onClick={onClick} />
      <TableCell className={classes.menuCell} align="right">
        <ContextualMenu node={node}></ContextualMenu>
      </TableCell>
    </TableRow>
  );
};
