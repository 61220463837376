import { call, put } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  fetchMenuSucceded,
  fetchMenuFailed
} from "redux/actions/siteStructureActions";
import { MenusApi } from "api/menusApi";
import { IMenu } from "models/menus";

export function* getMenu(action: Action<string>) {
  try {
    const menu: IMenu = yield call(MenusApi.getMenu, action.payload);

    yield put(fetchMenuSucceded(menu));
  } catch (error) {
    yield put(fetchMenuFailed(error));
  }
}
