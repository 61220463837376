import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { AddSiteMenu } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CreateMenuPayload } from "redux/actions/typings/siteStructureActions";

export function* createMenu(action: Action<CreateMenuPayload>) {
  try {
    const { path, onSuccess } = action.payload;
    const command = new AddSiteMenu({ path });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.createmenu.error",
      successMessage: "notification.createmenu.success",
      toggleNotification: true
    });

    yield put(commandAction);

    onSuccess && onSuccess();
  } catch (error) {
    console.error(error);
  }
}
