import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import { UxdIcon } from "components/shared/uxdIcon";
import { useStyles } from "./styles";
import classNames from "classnames";

export function SaveWarningMessage(props: { showWarning: boolean }) {
  const classes = useStyles();

  if (!props.showWarning) {
    return <></>;
  }

  const confirmMessage = translate(
    "sitestructure.urlssection.prompt_messages.are_you_sure"
  );

  const livePushMessage = translate(
    "sitestructure.urlssection.prompt_messages.changes_pushed_live"
  );
  return (
    <>
      <div className={classes.warningSectionLeft}>
        <UxdIcon name="warning" className={classes.warningIcon} />
      </div>
      <div className={classes.warningSectionRight}>
        <Typography className={classes.warningSectionDescription} component="p">
          {confirmMessage}
        </Typography>
        <Typography component="small">{livePushMessage}</Typography>
      </div>
    </>
  );
}
export function PromptValidationMessage(props: { showWarning: boolean }) {
  const classes = useStyles();

  if (!props.showWarning) {
    return <></>;
  }

  const missingFieldsMessage = translate(
    "sitestructure.urlssection.prompt_messages.missing_fields"
  );
  const tryAgainMessage = translate(
    "sitestructure.urlssection.prompt_messages.check_try_again"
  );

  return (
    <>
      <div className={classes.warningSectionLeft}>
        <UxdIcon name="info" className={classes.errorTheme} />
      </div>
      <div className={classes.warningSectionRight}>
        <Typography
          className={classNames(
            classes.warningSectionDescription,
            classes.errorTheme
          )}
          component="p"
        >
          {missingFieldsMessage}
        </Typography>
        <Typography component="small" className={classes.errorTheme}>
          {tryAgainMessage}
        </Typography>
      </div>
    </>
  );
}
