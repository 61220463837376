import { ApiClient } from "api/apiClient";
import { StagedSiteItem } from "models/directories";

const BASE_URL = "api/directories";

export class DirectoriesApi {
  static readonly getPendingChanges = async (
    id: string
  ): Promise<StagedSiteItem[]> => {
    const endpoint = `${BASE_URL}/${id}/pendingchanges`;
    return ApiClient.get(endpoint);
  };

  static readonly getPublishedItems = async (
    id: string
  ): Promise<StagedSiteItem[]> => {
    const endpoint = `${BASE_URL}/${id}/published`;
    return ApiClient.get(endpoint);
  };
}
