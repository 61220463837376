import { useContext, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import {
  ContentPickerContext,
  ContentPickerQueryFilter
} from "components/contentPicker/contentPickerContext";
import { getFilterQuery } from "utils/searchEntitiesUtils";
import { ForgeEntitiesApi } from "api/forgeEntitiesApi";
import { DelayedProgress } from "components/shared/delayedProgress";
import { ContentPickerLoadError } from "components/contentPicker/contentPickerLoadError";
import { ContentPickerGrid } from "components/contentPicker/contentPickerGrid";
import { ContentPickerEmptyState } from "components/contentPicker/contentPickerEmptyState";
import { makeCancelablePromise } from "utils/javascriptUtils";
import { ForgeEntity } from "models/forgeEntities";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
  emptyState: {
    height: "100%"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-20px 0 0 -20px"
  }
}));

export const ContentPickerContainer = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { entityTypeCode, queryFilter: filter } =
    useContext(ContentPickerContext);

  const {
    isLoading,
    error,
    data = { pages: [] as ForgeEntity[][] },
    fetchNextPage,
    hasNextPage
  } = useContentPickerQuery(entityTypeCode, filter);

  const totalItems = useMemo(
    () => data.pages.reduce((counter, page) => counter + page.length, 0),
    [data.pages]
  );

  if (isLoading) {
    return <DelayedProgress className={classes.progress} />;
  }

  if (error) {
    return <ContentPickerLoadError />;
  }

  if (!totalItems) {
    return <ContentPickerEmptyState className={classes.emptyState} />;
  }

  return (
    <InfiniteScroll
      dataLength={totalItems}
      next={fetchNextPage}
      hasMore={Boolean(hasNextPage)}
      loader={<LinearProgress />}
      scrollableTarget="content-picker-scrollable"
      style={{ display: "contents" }}
    >
      <ContentPickerGrid className={rootClassName} pages={data.pages} />
    </InfiniteScroll>
  );
};

const useContentPickerQuery = (
  entityTypeCode: string,
  filter: ContentPickerQueryFilter
) => {
  const queryString = getFilterQuery(filter);
  const queryKey = ["searchEntity", entityTypeCode, filter];

  return useInfiniteQuery(
    queryKey,
    ({ pageParam = 0 }) =>
      makeCancelablePromise((controller) =>
        ForgeEntitiesApi.search(entityTypeCode, queryString, pageParam, {
          signal: controller.signal
        })
      ),
    {
      getNextPageParam: (lastPage, allPages) =>
        calculateNextPage(lastPage.length, allPages.length)
    }
  );
};

const calculateNextPage = (lastPageCount: number, pagesCount: number) => {
  const lastPageParam =
    lastPageCount < ForgeEntitiesApi.PAGE_SIZE ? false : pagesCount;

  return lastPageParam;
};
