import {
  createContext,
  useState,
  PropsWithChildren,
  useCallback,
  useMemo
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { copySiteItem, moveSiteItem } from "redux/actions/siteStructureActions";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { translate } from "utils/i18n";
import { ISiteItem } from "models/siteItem";
import { selectAliases } from "redux/selectors/aliasesSelectors";
import { hasAliasesSet } from "components/siteStructure/aliases/utils";
import { ClipboardOperation, ClipboardOperations } from "models/siteStructure";

export type SiteItemClipboardContextShape = {
  sourceNode: ISiteItem | null;
  copy: (nodeId: ISiteItem) => void;
  cut: (nodeId: ISiteItem) => void;
  paste: (destinationId: string) => void;
  showConfirmPasteModal: () => boolean;
  clear: () => void;
  canPasteOn: (destinationNode: ISiteItem) => boolean;
  operation: ClipboardOperation;
};

const actions = {
  Copy: copySiteItem,
  Cut: moveSiteItem
};

export const SiteItemClipboardContext =
  createContext<SiteItemClipboardContextShape>({
    sourceNode: null,
    copy: () => {},
    cut: () => {},
    paste: () => {},
    showConfirmPasteModal: () => false,
    clear: () => {},
    canPasteOn: () => false,
    operation: "None"
  });

const operationMessages: { [key in ClipboardOperation]: string } = {
  None: "",
  Copy: "sitestructure.copied",
  Cut: "sitestructure.cutted",
  Paste: "sitestructure.paste"
};

export const SiteItemClipboardContextProvider = (
  props: PropsWithChildren<{}>
) => {
  const [sourceNode, setSourceNode] = useState<ISiteItem | null>(null);
  const [operation, setOperation] = useState<ClipboardOperation>(
    ClipboardOperations.NONE
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const dispatch = useDispatch();
  const aliases = useSelector(selectAliases);

  const setInClipboard = (
    currentOperation: ClipboardOperation,
    node: ISiteItem
  ) => {
    setOperation(currentOperation);
    setSourceNode(node);
    setSnackbarOpen(true);
  };

  const clear = () => {
    setOperation(ClipboardOperations.NONE);
    setSourceNode(null);
  };

  const contextValue: SiteItemClipboardContextShape = useMemo(
    () => ({
      sourceNode: sourceNode,
      operation,
      copy: (node) => setInClipboard(ClipboardOperations.COPY, node),
      cut: (node) => setInClipboard(ClipboardOperations.CUT, node),
      paste: (destinationId) => {
        const action = actions[operation];
        dispatch(action({ sourceId: sourceNode!.nodeId, destinationId }));

        clear();
      },
      showConfirmPasteModal: () => {
        return (
          operation === ClipboardOperations.CUT &&
          hasAliasesSet(aliases, sourceNode!.path)
        );
      },
      canPasteOn: (destinationNode) => {
        if (operation === ClipboardOperations.NONE || !sourceNode) {
          return false; // Nothing copied or cut
        }

        const isSameLocation = destinationNode.children.includes(
          sourceNode.nodeId
        );

        if (isSameLocation) {
          return canPasteWhen[sourceNode.nodeType][operation].sameLocation;
        }

        const isSameItem = sourceNode.nodeId === destinationNode.nodeId;
        if (isSameItem) {
          return canPasteWhen[sourceNode.nodeType][operation].sameItem;
        }

        const isChild = destinationNode.parentNodesIds.includes(
          sourceNode.nodeId
        );
        if (isChild) {
          return canPasteWhen[sourceNode.nodeType][operation].isChild;
        }

        return true;
      },
      clear
    }),
    [aliases, dispatch, operation, sourceNode]
  );

  const closeSnackbar = useCallback(
    () => setSnackbarOpen(false),
    [setSnackbarOpen]
  );

  const notificationMessage = useMemo(() => {
    if (
      sourceNode &&
      (operation === ClipboardOperations.COPY ||
        operation === ClipboardOperations.CUT)
    )
      return `sitestructure.clipboard${sourceNode.nodeType}${operation}`;
    return "sitestructure.clipboardconfirm";
  }, [operation, sourceNode]);

  return (
    <SiteItemClipboardContext.Provider value={contextValue}>
      {props.children}
      <Snackbar
        key={`${operation}-${sourceNode}`}
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={closeSnackbar}
        ClickAwayListenerProps={{
          mouseEvent: "onMouseDown",
          touchEvent: "onTouchStart"
        }}
      >
        <Alert onClose={closeSnackbar} severity="success">
          {translate(notificationMessage, {
            operation: translate(operationMessages[operation])
          })}
        </Alert>
      </Snackbar>
    </SiteItemClipboardContext.Provider>
  );
};

const canPasteWhen = {
  Directory: {
    Cut: {
      sameLocation: false,
      sameItem: false,
      isChild: false
    },
    Copy: {
      sameLocation: true,
      sameItem: true,
      isChild: true
    }
  },
  Page: {
    Cut: {
      sameLocation: false,
      sameItem: false,
      isChild: false
    },
    Copy: {
      sameLocation: true,
      sameItem: false,
      isChild: false
    }
  },
  Menu: {
    Cut: {
      sameLocation: false,
      sameItem: false,
      isChild: false
    },
    Copy: {
      sameLocation: true,
      sameItem: false,
      isChild: false
    }
  }
};
