import React, { useState, useEffect } from "react";
import classnames from "classnames";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { TreeView } from "@material-ui/lab";
import { IBaseProps } from "components/_baseProps";
import { SiteStructureTreeItem } from "./siteStructureTreeItem";
import { UxdIcon } from "components/shared/uxdIcon";
import { getAllSubPaths } from "utils/pathUtils";
import { makeSelectSiteItemById } from "redux/selectors/siteStructureSelectors";
import { ROOT_ID } from "models/siteItem";
import { useMemoSelector } from "components/hooks";
import { RectangleSkeleton } from "components/skeletons";
import { AutocompleteTree } from "components/siteStructure/tree/autocompleteTree";

interface IProps extends IBaseProps {
  path: string;
  isLoading: boolean;
  isLinkPicker?: boolean;
  onSelectedLinkPickerPage?: (id: string, path: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0)
  },
  spinner: {
    margin: "auto"
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(1)
  },
  treeIcon: {
    fontSize: theme.spacing(2)
  },
  treeWrapper: {
    padding: theme.spacing(1)
  },
  title: {
    margin: theme.spacing(1, 0, 0, 3),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400
  }
}));

export const SiteStructureTree = (props: IProps) => {
  const {
    className,
    path,
    isLinkPicker = false,
    onSelectedLinkPickerPage = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);
  const [expandedNodes, setExpandedNodes] = useState([...getAllSubPaths(path)]);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const expanded = _.union(expandedNodes, getAllSubPaths(path));
    setExpandedNodes(expanded);
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

  const rootNode = useMemoSelector(makeSelectSiteItemById, ROOT_ID);

  const handleToggle = (event: React.ChangeEvent<{}>, paths: string[]) => {
    setExpandedNodes(paths);
  };

  if (props.isLoading) {
    return (
      <div className={classes.skeletonContainer}>
        {_.range(8).map((index) => (
          <RectangleSkeleton key={index} size={10} />
        ))}
      </div>
    );
  }

  return (
    <div className={rootClassName}>
      <AutocompleteTree
        hideSiteStructureTree={setHidden}
        isLinkPicker={isLinkPicker}
        onSelectedLinkPickerPage={onSelectedLinkPickerPage}
      />
      <TreeView
        className={classes.treeWrapper}
        expanded={expandedNodes}
        selected={path}
        onNodeToggle={handleToggle}
        hidden={hidden}
        defaultCollapseIcon={
          <UxdIcon name="expand_more" className={classes.treeIcon} />
        }
        defaultExpandIcon={
          <UxdIcon name="chevron_right" className={classes.treeIcon} />
        }
      >
        <SiteStructureTreeItem
          node={rootNode}
          expandedNodes={expandedNodes}
          isLinkPicker={isLinkPicker}
          onSelectedLinkPickerPage={onSelectedLinkPickerPage}
        />
      </TreeView>
    </div>
  );
};
