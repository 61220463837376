import { all, call, select } from "redux-saga/effects";
import { ISiteItem, SiteItems } from "models/siteItem";
import { fetchSiteItems } from "redux/actions/siteStructureActions";
import { getSiteItems } from "redux/sagas/siteStructure/getSiteItems";
import { OutputParametricSelector } from "reselect";
import { IDomainState } from "models/domainStates";

export function* getDestinationItem(
  itemId: string,
  siteItemSelector: SiteItemSelectorType
) {
  let item: ISiteItem = yield select(siteItemSelector, itemId);

  // having no children could mean also that the node was never requested
  if (item.children.length === 0) {
    // call and wait fetchItems saga
    yield call(getSiteItems, fetchSiteItems({ id: itemId }));
    item = yield select(siteItemSelector, itemId);
  }

  return item;
}

export function* getUniqueName(
  destinationItem: ISiteItem,
  label: string,
  siteItemSelector: SiteItemSelectorType
) {
  // This regex will check for the label and the label followed by _x (x is integer)
  const duplicateRegex = new RegExp(`^${label}(_([0-9]*))?$`);
  const children: ISiteItem[] = yield all(
    destinationItem.children.map((childId) => select(siteItemSelector, childId))
  );

  const hasChildWithSameName = children.some((child) => child.label === label);
  if (hasChildWithSameName) {
    // The idea is to extract all the progressives and calculate Max + 1

    // get all the duplicates labels
    const duplicates = children.filter(
      (child: ISiteItem) =>
        duplicateRegex.test(child.label) && child.label !== label
    );

    // extract just the progressives
    const progressives = duplicates.map((duplicate: ISiteItem) => {
      const segments = duplicate.label.split("_");
      return parseInt(segments[segments.length - 1]);
    });
    const sanitized = progressives.filter((prog) => !isNaN(prog)); // remove NaN entries

    // evaluate next progressive
    const nextProgressive =
      sanitized.length > 0 ? Math.max(...sanitized) + 1 : 1;

    return `${label}_${nextProgressive}`;
  }

  return label;
}

// just to keep the method signature short
type SiteItemSelectorType = OutputParametricSelector<
  IDomainState,
  string,
  ISiteItem,
  (res1: SiteItems, res2: string) => ISiteItem
>;
