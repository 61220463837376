import { put, take } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  setRequest,
  setSuccessRequest,
  setFailedRequest
} from "redux/actions/requestMonitorActions";
import {
  SUCCESS_ACTION_SUFFIX,
  FAILED_ACTION_SUFFIX
} from "redux/actions/constants";
import { matchRequestSuffixRegex } from "redux/selectors/requestMonitorSelectors";

export default function* requestMonitorSaga(action: Action<any>) {
  const { type, payload } = action;
  const requestName = type.replace(matchRequestSuffixRegex, "");
  const setRequestAction = setRequest(requestName, payload);

  yield put(setRequestAction);

  const fulfillAction: Action<any> = yield take([
    `${requestName}${SUCCESS_ACTION_SUFFIX}`,
    `${requestName}${FAILED_ACTION_SUFFIX}`
  ]);

  const requestPayload = setRequestAction.payload.requestState.requestPayload;
  const isSuccess = fulfillAction.type.endsWith(SUCCESS_ACTION_SUFFIX);
  if (isSuccess) {
    yield put(setSuccessRequest(requestName, requestPayload));
  } else {
    yield put(
      setFailedRequest(
        requestName,
        requestPayload,
        fulfillAction.payload?.message
      )
    );
  }
}
