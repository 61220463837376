import {
  AliasValidationKey,
  IAliasValidationEntry
} from "components/siteStructure/aliases/types";
import { isEmpty, map } from "lodash";
import { translate } from "utils/i18n";

interface ErrorConfigParams {
  paramName: string;
  promptValidationForCulture: AliasValidationKey | undefined;
  validation: IAliasValidationEntry | undefined;
}

export const getAllowedValuesErrorConfigMap = ({
  paramName,
  promptValidationForCulture,
  validation
}: ErrorConfigParams) => {
  const { validationKey, payload } = validation || {};

  return [
    {
      condition: promptValidationForCulture === "MISSING_ALIAS_ALLOWED_FIELDS",
      getMessage: () => (
        <span>
          {translate(
            "sitestructure.urlssection.validation.allowed_values_empty"
          )}
        </span>
      )
    },
    {
      condition:
        validationKey === "ALIAS_ALLOWED_FIELDS_INCORRECT_FORMAT" &&
        payload?.paramName === paramName,
      getMessage: () => {
        const invalidValues: string[] = map(
          payload?.invalidValues || [],
          "value"
        );

        const shouldShowInvalidValues = !isEmpty(invalidValues);

        return (
          <span>
            <label>
              {translate(
                "sitestructure.urlssection.validation.allowed_values_format_corrupted"
              )}
            </label>
            <br />

            {shouldShowInvalidValues && (
              <>
                <label style={{ marginRight: "4px" }}>
                  {translate(
                    "sitestructure.urlssection.validation.allowed_values_invalid_format"
                  )}
                </label>
                {invalidValues.map((value, index) => (
                  <label
                    key={value}
                    style={{ marginRight: "4px", opacity: 0.7 }}
                  >
                    {value} {invalidValues.length === index + 1 ? "" : ","}
                  </label>
                ))}
              </>
            )}
          </span>
        );
      }
    }
  ];
};
