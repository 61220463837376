import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { setModuleLabel } from "redux/actions/siteStructureActions";
import { IModule, ILayout } from "models/pages";
import { ImportantModuleSwitch } from "components/siteStructure/pageView/pageSidebar/importantModuleSwitch";
import { Textbox } from "components/shared/textbox";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 5)
  }
}));

interface IProps {
  element: IModule | ILayout;
  pageId: string;
  canEditProperties: boolean;
  currentSiteItemPath: string;
}

const DEFAULT_DEBOUNCE = 1000;

export const PageSidebarModuleExtras = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { element, pageId, canEditProperties, currentSiteItemPath } = props;

  const setLabel = useCallback(
    (label: string) =>
      dispatch(
        setModuleLabel({
          pageId: pageId,
          moduleInstanceId: element.instanceId,
          label
        })
      ),
    [dispatch, pageId, element.instanceId]
  );

  return (
    <div className={classes.root}>
      <Textbox
        value={element.label || ""}
        onChange={setLabel}
        debounce={true}
        debounceDelay={DEFAULT_DEBOUNCE}
        placeholder={translate("sitestructure.label")}
        label={translate("sitestructure.chooselabel")}
        pattern="^[0-9A-Za-z \-_]*$"
        invalidPatternMessage={translate("sitestructure.labelallowedchars")}
        disabled={!canEditProperties}
      />
      <ImportantModuleSwitch
        pageElement={element}
        pageId={pageId}
        currentSiteItemPath={currentSiteItemPath}
      />
    </div>
  );
};

export default PageSidebarModuleExtras;
