import { put, select } from "redux-saga/effects";
import { Action } from "redux-actions";

import { CheckUrlTranslationPayload } from "redux/actions/typings/siteStructureActions";
import { ISiteItem } from "models/siteItem";
import { selectFrontendCultures } from "redux/selectors/frontendSelectors";
import { hasUrlTranslationDuplicate } from "utils/urlTranslationUtils";
import { displayNotification } from "redux/actions/notificationActions";
import { getSiteItemSiblings } from "utils/siteStructureUtils";

export function* checkSiteItemUrlTranslationDuplicates(
  action: Action<CheckUrlTranslationPayload>
) {
  const { siteItems, currentSiteItem: current } = action.payload;

  const cultures: string[] = yield select(selectFrontendCultures);
  const siblings: ISiteItem[] =
    getSiteItemSiblings(siteItems, current.nodeId) ?? [];

  const hasDuplicate = hasUrlTranslationDuplicate(current, siblings, cultures);

  if (hasDuplicate) {
    yield put(
      displayNotification({
        message: "notification.duplicate_url_translation_error",
        severity: "error"
      })
    );
  }
}
