import React, { useEffect, useState } from "react";
import { makeStyles, TextField, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import _ from "lodash";
import { translate } from "utils/i18n";
import classnames from "classnames";
import { ID3DropdownOption } from "components/shared/d3Components/d3Dropdown";
import { MetadataValue } from "models/metadata";
import { BoolMetadataInput } from "./boolMetadataInput";
import { DateMetadataInput } from "./dateMetadataInput";
import { isValidVariableFormat } from "utils/variableUtils";
import EnumMetadataInput from "components/siteStructure/mainDetail/inputs/enumMetadataInput";

type InputValue = string | number | boolean;
type InputType = "string" | "number" | "boolean" | "date-time";

export const useStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(4.5),
    flexGrow: 1
  },
  input: {
    color: theme.palette.custom.ds.grayscales.gr800,
    fontSize: "14px",
    "& label": {
      backgroundColor: theme.palette.common.white
    },
    "& .MuiSelect-root": {
      paddingRight: "24px"
    },
    "& .MuiSelect-root:focus": {
      backgroundColor: "inherit"
    }
  },
  selectIcon: {
    fontSize: "12px",
    color: theme.palette.custom.ds.grayscales.gr500,
    margin: theme.spacing(0, 1)
  },
  stack: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1
  },
  error: {
    margin: theme.spacing(0.5, 0),
    color: theme.palette.custom.ds.valencia.valencia500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    fontStyle: "italic"
  }
}));

const replaceAsterisk = (value: string) =>
  value.toLowerCase() === "*" ? translate("metadata.all") : value;

const getStringifiedValue = (value: InputValue | undefined) =>
  value === undefined ? "" : String(value);

interface IProps extends IBaseProps {
  value: InputValue | undefined;
  enum?: string[];
  placeholder?: string;
  label?: string;
  fullWidth?: boolean;
  type?: InputType;
  onSubmit?: (value: InputValue) => void;
  disabled: boolean;
}

export const MetadataInput = (props: IProps) => {
  const { className, placeholder, label, fullWidth, onSubmit, type, disabled } =
    props;

  const classes = useStyles();

  const [value, setValue] = useState<InputValue | undefined>(props.value);
  const [isNaN, setIsNaN] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const rootClassName = classnames(classes.input, className);

  const isBool = _.isBoolean(props.value) || type === "boolean";
  const isNumber = _.isNumber(props.value) || type === "number";
  const isDate = type === "date-time";
  const isEnum = props.enum && props.enum.length > 0;

  const hasChanged = (current: MetadataValue | undefined) =>
    current !== props.value;

  const toDropdownOption = (value: string): ID3DropdownOption => ({
    value: value,
    label: replaceAsterisk(value)
  });

  // text & number input handlers
  const handleBlur = () => {
    const changed = hasChanged(value);

    if (changed && !isNaN && value !== undefined) {
      onSubmit?.call(this, value);
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const current = event.target.value as MetadataValue as number;
    const nan =
      isNumber &&
      _.isNaN(Number(current)) &&
      !isValidVariableFormat(current.toString());

    setIsNaN(nan);
    setValue(current);
  };

  const handleEnumSubmit = (selected: ID3DropdownOption) => {
    const option = selected.value;
    const changed = hasChanged(option);

    if (changed) {
      onSubmit?.call(this, option);
      setValue(option);
    }
  };

  const handleBoolSubmit = (bool: boolean) => {
    const changed = hasChanged(bool);
    if (changed) {
      onSubmit?.call(this, bool);
      setValue(bool);
    }
  };

  const handleDateSubmit = (date: string) => {
    const changed = hasChanged(date);
    if (changed) {
      onSubmit?.call(this, date);
      setValue(date);
    }
  };

  const handleVariableSubmit = (variable: string) => {
    const changed = hasChanged(variable);
    if (changed) {
      onSubmit?.call(this, variable);
      setValue(variable);
    }
  };

  if (isEnum) {
    const options = props.enum?.map(toDropdownOption) ?? [];
    return (
      <EnumMetadataInput
        className={rootClassName}
        value={value as string}
        options={options}
        onEnumSubmit={handleEnumSubmit}
        onVariableSubmit={handleVariableSubmit}
        onTypeSwitch={() => setValue("")}
        disabled={disabled}
      />
    );
  }

  if (isBool) {
    return (
      <BoolMetadataInput
        className={rootClassName}
        value={getStringifiedValue(value)}
        onBoolSubmit={handleBoolSubmit}
        onVariableSubmit={handleVariableSubmit}
        onTypeSwitch={() => setValue("")}
        disabled={disabled}
      />
    );
  }

  if (isDate) {
    return (
      <DateMetadataInput
        className={rootClassName}
        value={getStringifiedValue(value)}
        onDateSubmit={handleDateSubmit}
        onVariableSubmit={handleVariableSubmit}
        onTypeSwitch={() => setValue("")}
        disabled={disabled}
      />
    );
  }

  // [WIP]: use D3AutoCompleteInput component to allow variable suggestions
  return (
    <div className={classes.stack}>
      <TextField
        multiline={!isNumber}
        rowsMax={5}
        fullWidth={fullWidth}
        variant="outlined"
        type="text"
        value={value || ""}
        placeholder={placeholder}
        label={label}
        className={className}
        onChange={handleTextFieldChange}
        onBlur={handleBlur}
        error={isNaN}
        disabled={disabled}
      />
      {isNaN && (
        <Typography className={classes.error}>
          {translate("metadata.error.number.format")}
        </Typography>
      )}
    </div>
  );
};

export default MetadataInput;
