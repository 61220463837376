import { makeStyles } from "@material-ui/core";
import { RectangleSkeleton } from "components/skeletons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(0, 10),
    height: "calc(100vh - 48px)",
    justifyContent: "space-around",
    overflow: "hidden"
  },
  main: {
    minHeight: "80vh"
  },
  middle: {
    width: "50%",
    minHeight: "75px",
    margin: "0 auto"
  },
  subordinate: {
    display: "flex",
    justifyContent: "space-between",
    rowGap: "1vw"
  },
  subRectangle: {
    minHeight: "30vh",
    width: "18vw"
  }
}));

export function PreviewSkeleton() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RectangleSkeleton size={5} className={classes.main} />
      <RectangleSkeleton className={classes.middle} />
      <div className={classes.subordinate}>
        <RectangleSkeleton className={classes.subRectangle} />
        <RectangleSkeleton className={classes.subRectangle} />
        <RectangleSkeleton className={classes.subRectangle} />
        <RectangleSkeleton className={classes.subRectangle} />
      </div>
    </div>
  );
}

export default PreviewSkeleton;
