import { Typography, List } from "@material-ui/core";
import { PermissionCodes } from "catalogs/permissionCodes";
import classNames from "classnames";
import {
  D3Button,
  D3Modal,
  D3MultiSelectListModal
} from "components/shared/d3Components";
import { useMultiSelect } from "components/shared/multiSelect/hooks";
import MultiSelectListItem from "components/shared/multiSelect/multiselectListItem";
import { useLocalAuthGroups } from "components/siteStructure/permissions/hooks";
import { IBaseProps } from "components/_baseProps";
import {
  IAuthorizationGroup,
  IAuthorizationGroupDetailUpdateRequest
} from "models/authorizationGroup";
import { ISiteItem } from "models/siteItem";
import { useState } from "react";
import { userService } from "services/userService";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";

interface IProps extends IBaseProps {
  allAuthGroups: IAuthorizationGroup[];
  localAuthGroups: IAuthorizationGroup[];
  currentSiteItem: ISiteItem;
  getSelectableAuthGroups: () => {
    key: string;
    label: string;
    description: string;
  }[];
}

export function LocalAuthorizationGroups(props: Readonly<IProps>) {
  const {
    allAuthGroups,
    localAuthGroups,
    currentSiteItem,
    getSelectableAuthGroups
  } = props;
  const classes = useStyles();

  const { checkedList, handleClick, clearChecked, MultiListSnackbar } =
    useMultiSelect();

  const { handleRemove, handleConfirm } = useLocalAuthGroups(
    currentSiteItem.nodeId
  );

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);

  const onClick = () => setAddModalOpen(true);
  const onCancel = () => {
    setRemoveModalOpen(false);
    setAddModalOpen(false);
  };

  const onConfirm = async (authGroupsToAdd: string[]) => {
    const update: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: authGroupsToAdd,
      actionType: "update",
      elementKind: "siteItems",
      values: [currentSiteItem.path]
    };

    handleConfirm(update);
    clearChecked();
    setAddModalOpen(false);
  };

  const onRemove = async () => {
    const update: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: checkedList,
      actionType: "delete",
      elementKind: "siteItems",
      values: [currentSiteItem.path]
    };

    handleRemove(update);
    setRemoveModalOpen(false);
    clearChecked();
  };

  const isSelected = (key: string) => checkedList.includes(key);

  const getConfirmationAuthGroups = () =>
    allAuthGroups
      .filter((group) => checkedList.includes(group.groupId))
      .map((removedGroup) => removedGroup.name)
      .join(", ");

  const isDisabled = !userService.hasPermissions(
    PermissionCodes.EditPermissions
  );

  return (
    <>
      <div className={classes.emptyLocal}>
        <Typography variant="h6" className={classes.text}>
          {translate("sitestructure.permissions.localgroups")}
        </Typography>
        <D3Button
          icon="group_add"
          variant="ghost"
          color="secondary"
          onClick={onClick}
          disabled={isDisabled}
        >
          {translate("sitestructure.permissions.addgroups")}
        </D3Button>
        {addModalOpen && (
          <D3MultiSelectListModal
            title={translate("sitestructure.permissions.selectgroup")}
            items={getSelectableAuthGroups()}
            hideSearch={true}
            noItemsText={
              allAuthGroups.length === 0
                ? translate(
                    "sitestructure.permissions.emptymodalmessagenogroupcreated"
                  )
                : translate(
                    "sitestructure.permissions.emptymodalmessageallgroupassigned"
                  )
            }
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmLabel={{
              verb: translate("general.add"),
              plural: translate("sitestructure.groups"),
              singular: translate("sitestructure.group")
            }}
            itemsClassName={classes.item}
          />
        )}
      </div>
      {localAuthGroups.length > 0 ? (
        <>
          <List className={classes.list} disablePadding>
            {localAuthGroups.map((group, index) => {
              const selected = isSelected(group.groupId);
              const itemClassName = classNames(classes.listItem, {
                [classes.listItemSelected]: selected
              });
              return (
                <MultiSelectListItem
                  key={group.groupId}
                  id={group.groupId}
                  content={group.name}
                  secondaryContent={group.description}
                  handleClick={handleClick}
                  selected={selected}
                  className={itemClassName}
                  disabled={isDisabled}
                />
              );
            })}
          </List>
          {checkedList && (
            <MultiListSnackbar
              singleSelectionText={translate(
                "sitestructure.permissions.groupselected"
              )}
              multiSelectionText={translate(
                "sitestructure.permissions.groupsselected"
              )}
              handleRemove={() => setRemoveModalOpen(true)}
              className={classNames(classes.snackbar, {
                [classes.hideSnackbar]: removeModalOpen
              })}
            />
          )}
          {removeModalOpen && (
            <D3Modal
              className={classes.removeModal}
              open={removeModalOpen}
              modalTitle={translate(
                "sitestructure.permissions.remove_modal_title"
              )}
              onCancel={onCancel}
              onConfirm={onRemove}
              cancelLabel={translate(
                "sitestructure.permissions.remove_cancel_button"
              )}
              confirmLabel={translate(
                "sitestructure.permissions.remove_confirm_button"
              )}
            >
              <Typography>
                {translate(
                  "sitestructure.permissions.removegroupconfirmationmessage",
                  { authGroups: getConfirmationAuthGroups() }
                )}
              </Typography>
            </D3Modal>
          )}
        </>
      ) : (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {translate("sitestructure.permissions.nolocalgroupsdescription")}
        </Typography>
      )}
    </>
  );
}
