import { translate } from "utils/i18n";
import { useStyles } from "./styles";
import { D3Button } from "components/shared/d3Components";

export interface IUrlAliasSubmitButtonProps {
  label?: string;
  disabled: boolean;
  showCancelButton: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export function UrlAliasSubmitButtons(props: IUrlAliasSubmitButtonProps) {
  const classes = useStyles();

  const { label, disabled = false, showCancelButton, onSave, onCancel } = props;

  return (
    <div className={classes.actionsSection}>
      {showCancelButton && (
        <D3Button
          onClick={onCancel}
          className={classes.saveButton}
          variant="ghost"
        >
          {translate("sitestructure.urlssection.prompt_messages.save_cancel")}
        </D3Button>
      )}
      <D3Button
        disabled={disabled}
        onClick={onSave}
        className={classes.saveButton}
        variant="filled"
      >
        {label}
      </D3Button>
    </div>
  );
}

export default UrlAliasSubmitButtons;
