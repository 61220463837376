import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { invoke } from "lodash";

import { useStyles } from "./styles";
import { useStyles as useUrlEntryStyles } from "../urlEntry/styles";
import { IAliasValidationEntry } from "components/siteStructure/aliases/types";
import { translate } from "utils/i18n";
import { useMemo } from "react";

interface IValidationMessageProps {
  validation: IAliasValidationEntry | undefined;
}

const getTemplates = () => {
  const translationUrlCharacters =
    "sitestructure.urlssection.validation.check_url_characters";
  const translationValidations = "sitestructure.urlssection.validation";

  return {
    URL_NOT_VALID: () => (
      <>
        {translate(`${translationUrlCharacters}.slogan`)}
        <ul>
          <li>- {translate(`${translationUrlCharacters}.dash_at_start`)}</li>
          <li>
            - {translate(`${translationUrlCharacters}.dots_are_not_allowed`)}
          </li>
          <li>
            -{" "}
            {translate(`${translationUrlCharacters}.no_whitespace_characters`)}
          </li>
        </ul>
      </>
    ),
    DYNAMIC_PARAMETERS_MISMATCH: () => (
      <>{translate(`${translationValidations}.dynamic_parameters_corrupted`)}</>
    ),
    ALIAS_NOT_UNIQUE: () => (
      <>{translate(`${translationValidations}.alias_not_unique`)}</>
    )
  };
};

const FieldValidationMessage = ({ validation }: IValidationMessageProps) => {
  const classes = useStyles();
  const entryClasses = useUrlEntryStyles();
  const validationKey = validation?.validationKey;

  const templates = useMemo(() => getTemplates(), []);

  if (validationKey === undefined) {
    return null;
  }

  return (
    <div className={classNames(classes.validationContainer)}>
      <div className={entryClasses.cell}>
        <Typography className={classes.fieldValidation} component="div">
          {invoke(templates, validationKey)}
        </Typography>
      </div>
    </div>
  );
};

export default FieldValidationMessage;
