import { IBaseProps } from "components/_baseProps";
import { makeStyles, Drawer } from "@material-ui/core";
import classnames from "classnames";

import { Logo } from "components/shared/logo";
import { DrawerEntries } from "./drawerEntries";
import { GuishellDrawer } from "components/appContainer/appToolbar/guishellDrawer";
import { StatusIcon } from "components/appContainer/appToolbar/statusIcon";
import { UserAvatar } from "components/appContainer/appToolbar/userAvatar";
import { CommandStatus } from "models/commandStatus";

interface IProps extends IBaseProps {
  currentPath: string;
  open: boolean;
  onClose: () => void;
  commandStatus: CommandStatus;
  userData: {
    id: string;
    initials: string;
    fullName: string;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paper": {
      background: theme.palette.custom.ds.fiord.fiord700,
      justifyContent: "space-between"
    }
  },
  drawerHeader: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3, 0),
    width: "100%"
  },
  logo: {
    margin: theme.spacing(0, 3),
    width: "200px",
    height: "32px",
    marginBottom: theme.spacing(3.125)
  },
  appItems: {
    color: theme.palette.custom.ds.viking.viking700,
    padding: theme.spacing(1),
    fontSize: "18px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    opacity: 0.6,
    "&:hover, &:focus": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      "& $text": {
        zIndex: 1,
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.1,
        backgroundColor: theme.palette.common.black
      }
    },
    "&:active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      }
    },
    "&$active": {
      opacity: 1,
      color: theme.palette.custom.ds.viking.viking500,
      zIndex: 1,
      "& $text": {
        color: theme.palette.common.white
      },
      "& .MuiTouchRipple-root": {
        opacity: 0.3,
        backgroundColor: theme.palette.common.black
      }
    }
  },
  user: {
    paddingLeft: "18px"
  },
  userIcon: {
    padding: 0,
    paddingRigth: theme.spacing(1)
  },
  guishell: {
    paddingLeft: "18px"
  },
  text: {
    color: theme.palette.common.white,
    zIndex: 1,
    "&$active": {
      opacity: 1,
      backgroundColor: "unset"
    },
    fontSize: theme.typography.pxToRem(14)
  },
  active: {}
}));

export const AppDrawer = (props: IProps) => {
  const { className, currentPath, open, onClose, commandStatus, userData } =
    props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const guishellStyle = classnames(classes.appItems, classes.guishell);
  const userStyle = classnames(classes.appItems, classes.user);

  return (
    <Drawer
      className={rootClassName}
      variant="temporary"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div className={classes.drawerHeader}>
        <Logo className={classes.logo} variant="singleline-white" />
        <DrawerEntries
          currentPath={currentPath}
          compact={false}
          onItemClick={onClose}
        />
      </div>
      <div className={classes.drawerHeader}>
        <StatusIcon status={commandStatus} />
        <span className={guishellStyle}>
          <GuishellDrawer large textClassName={classes.text} />
          <span className="MuiTouchRipple-root"></span>
        </span>
        <span className={userStyle}>
          <UserAvatar
            className={classes.userIcon}
            userId={userData.id}
            initials={userData.initials}
            fullName={userData.fullName}
            compact={false}
          />
          <span className="MuiTouchRipple-root"></span>
        </span>
      </div>
    </Drawer>
  );
};
