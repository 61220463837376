import { IApiNotificationState } from "models/domainStates";
import { createAction } from "redux-actions";

export const displayNotification = createAction(
  "DISPLAY_NOTIFICATION",
  (message: Pick<IApiNotificationState, "message" | "severity">) => message
);
export const closeApiNotificationSnackbar = createAction(
  "CLOSE_API_NOTIFICATION_SNACKBAR"
);
