import { translate } from "utils/i18n";
import { SiteItemHistoryEntry } from "../models/history";

const USER_KEY = "__key__username__";
const PROPERTY_NAME_KEY = "__key__propertyname__";
const PROPERTY_OWNER_KEY = "__key__propertyowner__";
const OBJECT_NAME_KEY = "__key__propertyowner__";

export function getReadableHistoryEntry(
  entry: SiteItemHistoryEntry,
  highlight: (content: string, key: string) => React.ReactNode
) {
  if (entry.verb === "unknown") {
    return [highlight(entry.userName, USER_KEY), " ", entry.event];
  }

  const parts = [
    highlight(entry.userName, USER_KEY),
    translate("history.has"),
    translate(`history.verbs.${entry.verb}`)
  ];

  if (entry.verb === "rollback") {
    parts.push(
      translate("history.atrevisionx", { revision: entry.revision.toString() })
    );
  }

  parts.push(entry.properties.objectType);
  if (entry.verb === "update" || entry.verb === "clean") {
    if (
      entry.properties.objectType === "module property" ||
      entry.properties.objectType === "filter property" ||
      entry.properties.objectType === "layout property"
    ) {
      parts.push(highlight(entry.properties.propertyName!, PROPERTY_NAME_KEY));
      parts.push(translate("history.of"));
      parts.push(
        highlight(entry.properties.propertyOwner!, PROPERTY_OWNER_KEY)
      );
    } else {
      parts.push(highlight(entry.properties.objectName!, OBJECT_NAME_KEY));
    }
  } else if (entry.verb === "move" || entry.verb === "add") {
    parts.push(highlight(entry.properties.objectName!, OBJECT_NAME_KEY));
  }

  return parts.map((p) => [p, " "]).flat();
}
