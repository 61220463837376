import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { DesignRow } from "components/siteStructure/template/preview/designRow";
import { ILayoutRow } from "models/siteAssets/layoutDefinition";

interface IProps extends IBaseProps {
  rows: ILayoutRow[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    borderBottom: `2px solid ${theme.palette.common.white}`,
    flex: 1,
    "&:last-child": {
      borderBottom: "unset"
    }
  }
}));

export const Rows = (props: IProps) => {
  const { className, rows } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      {rows.map((row, index) => (
        // yes... using the index as the 'key' is something that shouldn't be done, BUT
        // since this will never changes for the whole application lifecycle it's ok :)
        <DesignRow key={index} className={classes.row} row={row} />
      ))}
    </div>
  );
};
