import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PageElementIcon } from "components/siteStructure/pageView/pageElements/pageElementIcon";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { Hint } from "components/shared/hint";

interface IProps extends IBaseProps {
  moduleDefinition: IModuleDefinition;
  showHighlight: boolean;
  highlightRegex: RegExp;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    width: "100%",
    justifyContent: "space-between",
    gap: theme.spacing(1)
  },
  labelText: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    width: "100%",
    textAlign: "left",
    marginLeft: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr800,
    wordBreak: "initial",
    lineHeight: theme.typography.pxToRem(18)
  },
  hint: {
    "&$hide": {
      visibility: "hidden"
    }
  },
  icon: {
    backgroundColor: theme.palette.custom.ds.fiord.fiord500
  },
  hide: {}
}));

export const ModuleTile = (props: IProps) => {
  const { className, moduleDefinition, showHighlight, highlightRegex } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { label, description } = moduleDefinition;
  const labelParts = label.split(highlightRegex);

  const hintClassName = classnames(classes.hint, {
    [classes.hide]: !description
  });

  return (
    <div className={rootClassName}>
      <PageElementIcon
        elementType="Module"
        pageElementDefinition={moduleDefinition}
        size="small"
        className={classes.icon}
      />
      <div className={classes.labelContainer}>
        <Typography variant="subtitle2" className={classes.labelText}>
          {labelParts.map((part, i) =>
            showHighlight && highlightRegex.test(part) ? (
              <mark key={`${part}-${i}`}>{part}</mark>
            ) : (
              part
            )
          )}
        </Typography>
        <Hint
          className={hintClassName}
          hint={description ?? ""}
          noSpacing={true}
        />
      </div>
    </div>
  );
};
