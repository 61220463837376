import { put } from "redux-saga/effects";
import { Action, createAction } from "redux-actions";
import { issueCommand } from "redux/actions/commandActions";
import { CreateExternalTagPayload } from "redux/actions/typings/tagsActions";
import {
  CreateExternalTag,
  ICreateExternalTagBody
} from "@d3-forge/forge-commands";
import uuid from "utils/uuid";

export function* sendCreateExternalTag(
  action: Action<CreateExternalTagPayload>
) {
  try {
    const {
      dataSourceName,
      dataSourceId,
      dataSourcePath,
      extradata,
      title,
      label,
      slug,
      actionId,
      translationId
    } = action.payload;

    const aggregateId = uuid.generate();
    const createExternalTagCommandbody: ICreateExternalTagBody = {
      dataSourceName,
      dataSourceId,
      dataSourcePath: dataSourcePath ?? "",
      extradata: extradata ? extradata : {},
      title,
      label,
      slug,
      tagId: aggregateId,
      translationId
    };

    const createExternalTagCommand = new CreateExternalTag(
      createExternalTagCommandbody
    );

    const commandSuccess = createAction(
      "CREATE_EXTERNAL_TAG_SUCCEDED_" + actionId
    );

    const commandAction = issueCommand({
      command: createExternalTagCommand,
      onSuccessAction: commandSuccess(),
      errorMessage: "notification.createexternaltag.error",
      successMessage: "notification.createexternaltag.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
