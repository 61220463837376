import { put, select } from "redux-saga/effects";
import { Action } from "redux-actions";
import {
  CopySiteDirectory,
  CopySiteMenu,
  CopySitePage
} from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CopySiteItemPayload } from "redux/actions/typings/siteStructureActions";
import { makeSelectSiteItemById } from "redux/selectors/siteStructureSelectors";
import { ISiteItem } from "models/siteItem";
import {
  getDestinationItem,
  getUniqueName
} from "redux/sagas/helpers/siteItemHelpers";

const COMMAND_MAP = {
  Page: CopySitePage,
  Directory: CopySiteDirectory,
  Menu: CopySiteMenu
};

export function* sendCopySiteItem(action: Action<CopySiteItemPayload>) {
  try {
    const { sourceId, destinationId } = action.payload;

    const siteItemSelector = makeSelectSiteItemById();
    const copiedItem: ISiteItem = yield select(siteItemSelector, sourceId);
    const destinationItem: ISiteItem = yield getDestinationItem(
      destinationId,
      siteItemSelector
    );

    const destinationName: string = yield getUniqueName(
      destinationItem,
      copiedItem.label,
      siteItemSelector
    );

    const command = new COMMAND_MAP[copiedItem.nodeType]({
      sourceId,
      destinationName,
      destinationPath: destinationItem.path
    });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.copyitem.error",
      successMessage: "notification.copyitem.success",
      toggleNotification: true
    });

    yield put(commandAction);
  } catch (error) {
    console.error(error);
  }
}
