import { makeStyles } from "@material-ui/core";
import emptyFolder from "images/empty_permissions_tab.svg";

export const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 3),
    fontSize: "24px"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: theme.spacing(16)
  },
  image: {
    width: "340px",
    height: "256px",
    margin: theme.spacing(8, 0),
    display: "block",
    background: `url(${emptyFolder})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    mixBlendMode: "multiply",
    lineHeight: "20em",
    textAlign: "center",
    backgroundRepeat: "no-repeat"
  },
  text: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr800,
    paddingBottom: theme.spacing(3)
  },
  textSubTitle: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.custom.ds.grayscales.gr600,
    width: "337px"
  },
  addBtn: {
    marginTop: theme.spacing(1)
  },
  item: {
    height: theme.spacing(8),
    padding: theme.spacing(5, 1),
    "& .MuiListItemText-primary": {
      lineHeight: 2.0,
      fontSize: theme.typography.pxToRem(16)
    },
    "& .MuiListItemText-secondary": {
      lineHeight: 2.0,
      fontSize: theme.typography.pxToRem(14)
    }
  }
}));
