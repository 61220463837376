import { useEffect } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useDispatch } from "react-redux";
import {
  fetchSiteItems,
  unsetSelectedNode
} from "redux/actions/siteStructureActions";
import ChildrenList from "./childrenList";
import ErrorPage from "components/errorPages";
import { useHistory } from "react-router-dom";
import { EmptyDirectory } from "components/siteStructure/directoryView/emptyDirectory";
import { DirectoryDetailHeader } from "components/siteStructure/directoryView/directoryDetailHeader";
import { useMemoSelector, useRequestStatus } from "components/hooks";
import { ISiteItem } from "models/siteItem";
import { makeSelectSiteItemChildren } from "redux/selectors/siteStructureSelectors";
import { SkeletonDirectoryDetail } from "components/siteStructure/directoryView/skeletonDirectoryDetail";
import { SiteNodeTypes } from "models/siteStructure";

interface IProps extends IBaseProps {
  node: ISiteItem;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  childrenList: {
    flex: 1,
    padding: theme.spacing(2)
  },
  progress: {
    alignSelf: "center",
    margin: "auto"
  }
}));

export const DirectoryDetail = (props: IProps) => {
  const { className, node, isLoading: nodeIsLoading } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const dispatch = useDispatch();
  const request = useRequestStatus(fetchSiteItems, { id: node.nodeId });

  const children = useMemoSelector(makeSelectSiteItemChildren, node.nodeId);

  useEffect(() => {
    // This is a TEMPORARY workaround (for real) to avoid
    // the rendering of the previous selected page
    // when switching between Pages and Directoris details
    // this has TO BE REPLACED by fetchDirectoryDetail when there will be
    dispatch(unsetSelectedNode());
  }, [dispatch, node.nodeId]);

  const history = useHistory();
  const onChildrenListItemClick = (item: ISiteItem) => {
    history.push(`/sitestructure/${item.path}`);
    if (
      item.nodeType === SiteNodeTypes.DIRECTORY &&
      item.children.length === 0
    ) {
      dispatch(fetchSiteItems({ id: item.nodeId }));
    }
  };

  if (request.isLoading || nodeIsLoading) {
    return <SkeletonDirectoryDetail node={node} />;
  }

  if (request.error) {
    return (
      <div className={rootClassName}>
        <ErrorPage error="internal server error" />
      </div>
    );
  }

  const hasIndex = children.some((c) => c.label === "index");

  return (
    <div className={rootClassName}>
      <DirectoryDetailHeader node={node} hasIndex={hasIndex} />
      {node.children.length === 0 && <EmptyDirectory />}
      {node.children.length > 0 && (
        <ChildrenList
          className={classes.childrenList}
          children={children}
          onRowClick={onChildrenListItemClick}
        />
      )}
    </div>
  );
};
