import { call, put } from "redux-saga/effects";
import {
  fetchTagsDatasourcesSucceded,
  fetchTagsDatasourcesFailed
} from "redux/actions/forgeActions";
import { TagsApi } from "api/tagsApi";

export function* getTagsDatasources() {
  try {
    const tagsDatasources = yield call(TagsApi.getDataSources);
    yield put(fetchTagsDatasourcesSucceded(tagsDatasources));
  } catch (error) {
    yield put(fetchTagsDatasourcesFailed(error));
  }
}
