import { useSelector, useDispatch } from "react-redux";
import { selectDeleteSiteItem } from "redux/selectors/siteItem/deleteSiteItemSelector";
import { closeDeleteModal } from "redux/actions/siteItem/deleteSiteItemActions";
import { D3Modal } from "components/shared/d3Components";
import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import {
  removeDirectory,
  removeMenu,
  removePage
} from "redux/actions/siteStructureActions";

export function DeleteSiteItemModal() {
  const { open, node } = useSelector(selectDeleteSiteItem);
  const dispatch = useDispatch();

  if (!node) {
    return null;
  }

  const actionMap = {
    Page: removePage,
    Directory: removeDirectory,
    Menu: removeMenu
  };

  const onCancel = () => {
    dispatch(closeDeleteModal());
  };

  const onConfirm = () => {
    onDelete();
    onCancel();
  };

  const onDelete = () => {
    try {
      const action = actionMap[node.nodeType];
      dispatch(action(node.nodeId));
    } catch {}
  };

  return (
    <D3Modal
      open={open}
      modalTitle={translate("general.confirmationrequired")}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmLabel={translate("general.ok")}
    >
      <Typography>
        {translate(
          `sitestructure.remove${node.nodeType.toLowerCase()}.confirmtext`,
          { path: node.path }
        )}
      </Typography>
    </D3Modal>
  );
}
