import classnames from "classnames";
import { Dialog, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ContentPickerContextProvider } from "components/contentPicker/contentPickerContext";
import { ForgeEntity } from "models/forgeEntities";
import { ContentPickerBody } from "components/contentPicker/contentPickerBody";

interface IProps extends IBaseProps {
  open: boolean;
  onClose: (selected: ForgeEntity | null) => void;
  entityTypeCode: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogPaper: {
    height: theme.spacing(88)
  }
}));

export const ContentPicker = (props: IProps) => {
  const { className, open, onClose, entityTypeCode } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Dialog
      className={rootClassName}
      open={open}
      fullWidth={true}
      maxWidth="lg"
      onClose={() => onClose(null)}
      classes={{ paper: classes.dialogPaper }}
    >
      <ContentPickerContextProvider entityTypeCode={entityTypeCode}>
        <ContentPickerBody open={open} onClose={onClose} />
      </ContentPickerContextProvider>
    </Dialog>
  );
};
