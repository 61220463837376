import { ID3AutoCompleteInputOption } from "components/shared/d3Components/d3AutoCompleteInput";

export const basicAutocompleteFilter = (
  options: string[],
  inputValue: string
) =>
  options.filter((opt) =>
    opt.toLowerCase().includes(inputValue.toLowerCase().trim())
  );

export const filterAutocompleteOptions = (
  options: ID3AutoCompleteInputOption[],
  inputValue: string
) =>
  options.filter((option) =>
    option.label.toLocaleLowerCase().includes(inputValue.toLowerCase())
  );
