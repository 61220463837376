import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { TreeItem } from "@material-ui/lab";
import { fetchSiteItems } from "redux/actions/siteStructureActions";
import { makeSelectRequestInstance } from "redux/selectors/requestMonitorSelectors";
import { SiteStructureTreeItemLabel } from "./siteStructureTreeItemLabel";
import { useHistory, useParams } from "react-router-dom";
import { NodeLoadError } from "components/siteStructure/tree/nodeLoadError";
import { ISiteItem } from "models/siteItem";
import { makeSelectSiteItemChildren } from "redux/selectors/siteStructureSelectors";
import { normalizePath } from "utils/pathUtils";
import { useMemoSelector } from "components/hooks";
import { SiteNodeTypes } from "models/siteStructure";
import { userService } from "services/userService";

interface IProps extends IBaseProps {
  node: ISiteItem;
  expandedNodes: string[];
  isLinkPicker?: boolean;
  onSelectedLinkPickerPage?: (id: string, path: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&.Mui-selected": {
      "& > .nodeTypeIcon": {
        color: theme.palette.custom.ds.viking.viking500
      }
    }
  }
}));

const isExpanded = (
  expandedNodes: string[],
  pathWithoutTrailingSlash: string
) => {
  const expanded = expandedNodes.includes(pathWithoutTrailingSlash);
  return expanded;
};

export const SiteStructureTreeItem = (props: IProps) => {
  const {
    className,
    node,
    expandedNodes,
    isLinkPicker = false,
    onSelectedLinkPickerPage = () => {}
  } = props;
  const { nodeType, path, label, nodeId } = node;
  const normalizedPath = normalizePath(path);

  const { path: currentPath } = useParams<{ path: string }>();

  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const history = useHistory();
  const dispatch = useDispatch();

  const children = useMemoSelector(makeSelectSiteItemChildren, node.nodeId);

  const request = useMemoSelector(makeSelectRequestInstance, fetchSiteItems, {
    id: nodeId
  });

  const isTreeItemSelected = path === currentPath;

  const expanded = isExpanded(expandedNodes, normalizedPath);

  const isDirectory = nodeType === SiteNodeTypes.DIRECTORY;
  const isPage = nodeType === SiteNodeTypes.PAGE;

  const getChildrenNodes = useCallback(() => {
    if (request.error) {
      return <NodeLoadError />;
    }

    if (node.children.length === 0) {
      return <></>; // material-ui treeItem should have at least one element inside to show the expand icon
    }

    const currentChildrenNodes = children.map((childNode) => (
      <SiteStructureTreeItem
        key={normalizePath(childNode.path)}
        node={childNode}
        expandedNodes={expandedNodes}
        isLinkPicker={isLinkPicker}
        onSelectedLinkPickerPage={onSelectedLinkPickerPage}
      />
    ));

    return currentChildrenNodes;
  }, [
    expandedNodes,
    children,
    node.children,
    request.error,
    isLinkPicker,
    onSelectedLinkPickerPage
  ]);

  const loadNode = () => {
    if (
      expanded ||
      isPage ||
      nodeType === SiteNodeTypes.MENU ||
      node.children.length > 0
    ) {
      return;
    }

    dispatch(fetchSiteItems({ id: nodeId }));
  };

  const openDetail = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (expanded) {
      event.preventDefault();
    }

    history.push(`/sitestructure/${node.path}`);

    if (isDirectory) {
      loadNode();
    }
  };
  const itemLabel = (
    <SiteStructureTreeItemLabel
      node={node}
      label={label}
      isLoading={request.isLoading}
      stage={node.status}
      isTreeItemSelected={isTreeItemSelected}
      isLinkPicker={isLinkPicker}
    />
  );

  const childrenNodes = isDirectory ? getChildrenNodes() : [];

  const handleLinkPickerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (isPage) {
      onSelectedLinkPickerPage(node.nodeId, node.path);
      return;
    }

    loadNode();
  };

  const onLabelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLinkPicker) {
      return openDetail(event);
    }
    if (
      userService.getIsUserAllowedToEditNavigationRules(node.path) ||
      !isPage
    ) {
      return handleLinkPickerClick(event);
    }
  };

  return (
    <TreeItem
      className={rootClassName}
      onLabelClick={onLabelClick}
      onIconClick={loadNode}
      nodeId={normalizedPath}
      label={itemLabel}
    >
      {childrenNodes}
    </TreeItem>
  );
};
