import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { makeStyles, TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PickListPropertyInfo } from "models/componentProperty";
import {
  Autocomplete,
  AutocompleteChangeReason,
  createFilterOptions
} from "@material-ui/lab";
import { translate } from "utils/i18n";
import { IPickListValue } from "models/pageElementProperty";
import { VariableSwitch } from "components/siteStructure/pageView/pageSidebar/moduleProperties/variableSwitch";

interface IProps extends IBaseProps {
  onChange?: (name: string, value: string) => void;
  pickList: PickListPropertyInfo;
  name: string;
  value: string | null;
  disabled?: boolean;
  mandatory: {
    className: string;
    message: string;
    showMessage: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  }
}));

export const FreeTextPickListProperty = (props: IProps) => {
  const {
    className,
    pickList,
    name,
    value,
    disabled = false,
    mandatory,
    onChange = () => {}
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [localValue, setLocalValue] = useState(value);

  const [pickListValues, setPickListValues] = useState<IPickListValue[]>([]);

  useEffect(() => {
    const values: IPickListValue[] = pickList.values.map((v) => ({
      value: v.value,
      displayName: v.description ?? v.value,
      inputValue: null
    }));
    setPickListValues(values);
  }, [pickList.values]);

  const filter = createFilterOptions<IPickListValue>();

  const handleSelect = (
    event: React.ChangeEvent<{}>,
    newValue: IPickListValue | string | null,
    reason: AutocompleteChangeReason
  ) => {
    let selectedValue: string | null = null;
    if (typeof newValue === "string") {
      selectedValue = newValue;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      selectedValue = newValue.inputValue;
    } else {
      selectedValue = newValue ? newValue.value : null;
    }

    if (localValue === selectedValue) {
      return;
    }
    setLocalValue(selectedValue);
    onChange(name, selectedValue || "");
  };

  const clearValueOnVariableSwitch = () => {
    setLocalValue(null);
    onChange(name, localValue ?? "");
  };

  return (
    <>
      <Autocomplete
        className={rootClassName}
        options={pickListValues}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            className={mandatory.showMessage ? mandatory.className : ""}
            placeholder={translate("picklist.typeaheadsuggestion")}
            variant="outlined"
            helperText={mandatory.showMessage ? mandatory.message : ""}
          />
        )}
        disabled={disabled}
        value={localValue}
        freeSolo
        onChange={handleSelect}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              displayName: `${translate("picklist.add")} "${
                params.inputValue
              }"`,
              value: params.inputValue
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          //Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.displayName;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        fullWidth
        renderOption={(option) => option.displayName}
      />
      <VariableSwitch
        propertyName={name}
        propertyValue={localValue ?? ""}
        disabled={disabled}
        onChange={onChange}
        onSwitch={clearValueOnVariableSwitch}
        mandatory={mandatory}
      />
    </>
  );
};
