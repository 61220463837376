import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";
import { usePersistedState } from "components/hooks";
import {
  selectFrontendCultures,
  selectFrontendSites
} from "redux/selectors/frontendSelectors";
import { viewPreview } from "redux/actions/siteStructureActions";
import { D3Button } from "components/shared/d3Components";
import { mediaQueryBreakpoints } from "theme/deltatreTheme";

interface IProps extends IBaseProps {
  path: string;
  nodeId: string;
  status?: string;
  context: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2, 0.75, 2),
    [mediaQueryBreakpoints.smaller]: {
      marginTop: theme.spacing(1.5)
    }
  },
  actions: {
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.custom.ds.grayscales.gr800,
    height: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100
    }
  }
}));

export const PreviewButton = (props: IProps) => {
  const { className, nodeId, path, status, context } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const availableCultures = useSelector(selectFrontendCultures);
  const availableSites = useSelector(selectFrontendSites);
  const dispatch = useDispatch();

  const [culture, setCulture] = usePersistedState<string>(
    "sitestructure.previewCulture",
    availableSites[0].culture
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openPreview = () =>
    window.open(`/#/preview/${nodeId}`, "_blank")?.focus();

  const viewPreviewCall = () => {
    const platform =
      availableSites.find((s) => s.culture === culture)?.platform || "*";
    const environment =
      availableSites.find((s) => s.culture === culture)?.environment || "*";

    dispatch(
      viewPreview({
        path,
        culture,
        platform,
        environment,
        context
      })
    );
  };

  const handleMenuClick = (selectedCulture: string) => {
    setCulture(selectedCulture);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={rootClassName}>
      {availableCultures.length > 1 && (
        <>
          <D3Button
            variant="ghost"
            color="secondary"
            icon="expand_more"
            onClick={openMenu}
            children={culture}
            size="small"
            iconPosition="right"
          />
          <Menu
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            keepMounted
            onClick={closeMenu}
            onClose={closeMenu}
          >
            {availableCultures.map((c) => (
              <MenuItem key={c} onClick={() => handleMenuClick(c)}>
                {c}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      <D3Button
        variant="ghost"
        color="secondary"
        icon="wysiwyg"
        onClick={openPreview}
        children={translate("sitestructure.preview")}
        size="small"
      />

      {status && status !== "Unpublished" && (
        <D3Button
          variant="ghost"
          color="secondary"
          icon="visibility"
          onClick={viewPreviewCall}
          children={translate("sitestructure.view")}
          size="small"
        />
      )}
    </div>
  );
};
