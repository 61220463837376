import React from "react";
import classnames from "classnames";
import { IconButton, makeStyles, Paper, Tooltip } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PageInfo } from "./pageInfo";
import { DirectoryInfo } from "./directoryInfo";
import { UxdIcon } from "components/shared/uxdIcon";
import { PreviewButton } from "./previewButton";
import { PageActionsBtn } from "components/siteStructure/header/pageActionsBtn";
import { ISiteItem } from "models/siteItem";
import { DirectorySkeleton, RectangleSkeleton } from "components/skeletons";
import { SiteStructureTabList } from "components/siteStructure/siteStructureTabs";
import { translate } from "utils/i18n";
import { mediaQueryBreakpoints } from "theme/deltatreTheme";
import { AdvancedSettings } from "components/siteStructure/header/advancedSettings";
import { MenuInfo } from "components/siteStructure/header/menuInfo";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";

interface IProps extends IBaseProps {
  node: ISiteItem;
  isLoading: boolean;
  onHistoryClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(0, 1),
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  paper: {
    zIndex: 1
  },
  nodeInfo: {
    flex: 1
  },
  showInfo: {
    margin: "auto"
  },
  actionsContainer: {
    padding: theme.spacing(2.5, 2, 0, 0),
    color: theme.palette.custom.ds.grayscales.gr700,
    display: "inline-flex",
    gap: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(5),
    margin: theme.spacing("auto", 1)
  },
  skeletonAction: {
    flex: "0 0 30%"
  },
  subrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    margin: theme.spacing(1.5, 0, 0, 1),
    [mediaQueryBreakpoints.smaller]: {
      flexDirection: "column",
      alignItems: "normal"
    }
  },
  historyIcon: {
    padding: theme.spacing(1),
    color: theme.palette.custom.ds.grayscales.gr700,
    "&:hover": {
      backgroundColor: theme.palette.custom.ds.viking.viking100,
      borderRadius: "2px",
      color: theme.palette.custom.ds.viking.viking500
    }
  }
}));

const NodeTypeComponentMap: Record<SiteNodeType, React.ElementType> = {
  Directory: DirectoryInfo,
  Page: PageInfo,
  Menu: MenuInfo
};

export const SiteStructureHeader = (props: IProps) => {
  const {
    className,
    node,
    onHistoryClick = () => undefined,
    isLoading = true
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { nodeType, status, path } = node;

  const Component: React.ElementType = NodeTypeComponentMap[nodeType];

  if (isLoading) {
    return (
      <Paper className={rootClassName} elevation={2} square>
        <DirectorySkeleton size={4} className={classes.skeletonAction} />
        <RectangleSkeleton size={4} className={classes.skeletonAction} />
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper} elevation={0} square>
      <div className={rootClassName}>
        <Component
          className={classes.nodeInfo}
          path={path}
          stage={status}
          contextName={node.contextName}
        />
        <div className={classes.actionsContainer}>
          <Tooltip title={translate("sitestructure.viewhistory")} arrow={true}>
            <IconButton
              className={classes.historyIcon}
              onClick={onHistoryClick}
              size="small"
            >
              <UxdIcon name="history" />
            </IconButton>
          </Tooltip>
          <AdvancedSettings />
          <PageActionsBtn node={node} />
        </div>
      </div>
      <div className={classes.subrow}>
        <SiteStructureTabList nodeType={nodeType} />
        {nodeType === SiteNodeTypes.PAGE && (
          <PreviewButton
            path={path}
            nodeId={node.nodeId}
            status={node.status}
            context={node.contextName}
          />
        )}
      </div>
    </Paper>
  );
};
