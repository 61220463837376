import { PagesApi } from "api/pagesApi";
import { Action } from "redux-actions";
import { call, put } from "redux-saga/effects";
import { displayNotification } from "redux/actions/notificationActions";
import { ViewPreviewPayload } from "redux/actions/typings/siteStructureActions";

export function* openPreview(action: Action<ViewPreviewPayload>) {
  try {
    const { path, culture, platform, environment, context } = action.payload;
    const previewUrl = yield call(
      PagesApi.getViewUrl,
      path,
      culture,
      platform,
      environment,
      context
    );
    window.open(previewUrl, "_blank");
  } catch (error) {
    console.error(error);
    yield put(
      displayNotification({
        message: "sitestructure.previewerror",
        severity: "error"
      })
    );
  }
}
