import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    fontSize: theme.typography.pxToRem(24)
  },
  subtext: {
    color: theme.palette.custom.greyscales.backgrounds.grey60,
    fontSize: theme.typography.pxToRem(16)
  }
}));

export const NoResults = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography className={classes.text}>
        {translate("sitestructure.search.noresults.text")}
      </Typography>
      <Typography className={classes.subtext}>
        {translate("sitestructure.search.noresults.subtext")}
      </Typography>
    </div>
  );
};
