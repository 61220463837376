import { ApiClient } from "api/apiClient";
import { ForgeEntity, ForgeEntityDefinition } from "models/forgeEntities";

const BASE_URL = "api/entities";

export class ForgeEntitiesApi {
  static readonly PAGE_SIZE = 24;

  static readonly getDefinitions = async (): Promise<
    ForgeEntityDefinition[]
  > => {
    const endpoint = `${BASE_URL}/definitions`;
    return ApiClient.get(endpoint);
  };

  static readonly search = async (
    entityTypeCode: string,
    query: string,
    page: number = 0,
    options?: RequestInit
  ): Promise<ForgeEntity[]> => {
    const skip = ForgeEntitiesApi.PAGE_SIZE * page;
    const limit = ForgeEntitiesApi.PAGE_SIZE + 1;
    const pagination = `skip=${skip}&limit=${limit}`;

    const endpoint = `${BASE_URL}/${entityTypeCode}/search?${query}&${pagination}`;

    const data = await ApiClient.get(endpoint, options);
    return data && data.length === limit
      ? (data as []).slice(0, ForgeEntitiesApi.PAGE_SIZE)
      : data;
  };

  static readonly getEntityDetailUrl = async (
    entityType: string,
    slug: string
  ): Promise<string> => {
    try {
      const endpoint = `${BASE_URL}/${entityType}/${slug}/detailurl`;
      const data = await ApiClient.get(endpoint);

      return data;
    } catch (error) {
      if ((error as Error).message === "Not Found") {
        return "";
      }
      throw error;
    }
  };
}
