import { IDomainState } from "models/domainStates";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectModuleCatalog } from "redux/selectors/assetCatalogSelectors";
import { userService } from "services/userService";

export const useModuleDefinitons = (pageContext) => {
  const catalog = useSelector((state: IDomainState) =>
    selectModuleCatalog(state, pageContext)
  );
  return useMemo(
    () =>
      Object.values<IModuleDefinition>(catalog).filter((module) =>
        userService.hasModulePermissions(module.key)
      ),
    [catalog]
  );
};
