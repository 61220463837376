import { IBaseProps } from "components/_baseProps";
import { useAbortController, useDebouncedState } from "components/hooks";
import { ContentPickerContextInput } from "./contentPickerContextInput";
import { translate } from "utils/i18n";
import { useTagsTypeaheadQuery } from "../hooks";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  value: TagInputItem | null;
  onChange?: (newValues: TagInputItem | null) => void;
}

export const ContentPickerContextInputContainer = (props: IProps) => {
  const { value, className, onChange = () => {} } = props;

  const [terms, debouncedTerms, setTerms] = useDebouncedState("", 1000);
  const abortController = useAbortController([terms]);
  const {
    data: suggestions = [],
    isLoading: queryLoading,
    error: queryError
  } = useTagsTypeaheadQuery(debouncedTerms, abortController);

  const handleInputChange = (newValue: string) => setTerms(newValue);

  return (
    <ContentPickerContextInput
      className={className}
      inputValue={terms}
      suggestions={suggestions}
      onChange={onChange}
      onInputChange={handleInputChange}
      value={value}
      error={
        queryError
          ? typeof queryError === "string"
            ? queryError
            : translate("contentpicker.tagloaderror")
          : ""
      }
      loading={queryLoading}
    />
  );
};
