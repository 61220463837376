import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import classnames from "classnames";
import { Forbidden } from "components/errorPages/forbidden";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { SiteNodeType } from "models/siteStructure";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectCurrentTab } from "redux/selectors/siteStructureSelectors";
import {
  AvailableTabs,
  PERMISSIONS_VIEW,
  siteItemTabs,
  TabListBySiteItemTypeMap
} from "routes/tabs-routes";
import { userService } from "services/userService";
import { translate } from "utils/i18n";
import { tabButton, tabHeader } from "theme/commonClasses";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import { filter, includes } from "lodash";
import { useMemo } from "react";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 3,
    overflow: "auto",
    display: "flex",
    backgroundColor: theme.palette.custom.ds.grayscales.gr200,
    "&$tabNotSelected": {
      display: "none"
    },
    padding: 0
  },
  tabs: tabHeader,
  tabButton,
  tabNotSelected: {}
}));

export function SiteStructureTabPanels(props: IProps) {
  const classes = useStyles();
  const currentTab = useSelector(selectCurrentTab);
  const rootClassName = classnames(classes.root, props.className);

  const authList = useSelector(selectAuthorizationGroupList);

  const renderTab = (tab: typeof siteItemTabs[0]) => {
    const Component = tab.component;

    if (tab.tab === PERMISSIONS_VIEW && authList.length === 0) {
      return null;
    }

    return (
      <TabPanel
        key={tab.tab}
        value={tab.tab}
        className={classnames(rootClassName, {
          [classes.tabNotSelected]: currentTab !== tab.tab
        })}
      >
        {userService.hasPermissions(tab.permission) ? (
          <Component {...props} />
        ) : (
          <Forbidden />
        )}
      </TabPanel>
    );
  };

  return <>{siteItemTabs.map(renderTab)}</>;
}

interface ITabListProps extends IBaseProps {
  nodeType: SiteNodeType;
}

export function SiteStructureTabList(props: ITabListProps) {
  const classes = useStyles();
  const currentTab = useSelector(selectCurrentTab);
  const { nodeType } = props;
  const history = useHistory();

  const changeTab = (_: unknown, newTab: AvailableTabs) => {
    history.push({
      search: `?tab=${newTab}`
    });
  };

  const authList = useSelector(selectAuthorizationGroupList);

  const filteredTabs = useMemo(
    () =>
      filter(siteItemTabs, (tab) => {
        const hasPermissions =
          !(tab.tab === PERMISSIONS_VIEW && authList.length === 0) &&
          userService.hasPermissions(tab.permission);

        const isTabForThisSiteItemType = includes(
          TabListBySiteItemTypeMap[nodeType],
          tab.tab
        );

        return hasPermissions && isTabForThisSiteItemType;
      }),
    [authList.length, nodeType]
  );

  return (
    <Tabs className={classes.tabs} onChange={changeTab} value={currentTab}>
      {filteredTabs.map((tab) => (
        <Tab
          key={tab.tab}
          label={translate(tab.name)}
          value={tab.tab}
          className={classes.tabButton}
          disableRipple
        />
      ))}
    </Tabs>
  );
}
