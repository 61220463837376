import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ForgeEntityStage } from "models/forgeEntities";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  stage: ForgeEntityStage;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative"
  },
  content: ({ stage }: IProps) => ({
    display: "flex",
    position: "absolute",
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    border: `1px solid transparent`,
    borderRadius: theme.spacing(2),
    zIndex: 1,
    alignItems: "center",
    maxWidth: theme.spacing(21),
    transition: theme.transitions.create(["border-color", "background-color"]),
    padding: theme.spacing(0.25, 0.5),

    "&:hover": {
      border: `1px solid ${theme.palette.custom.stages[stage]}`,
      backgroundColor: theme.palette.common.white,

      "& $text": {
        display: "inline",
        opacity: 100
      }
    }
  }),
  circle: ({ stage }: IProps) => ({
    display: "inline-block",
    backgroundColor: theme.palette.custom.stages[stage],
    borderRadius: "50%",
    height: theme.spacing(1.5),
    width: theme.spacing(1.5),
    zIndex: 2
  }),
  text: ({ stage }: IProps) => ({
    transition: theme.transitions.create("opacity"),
    whiteSpace: "nowrap",
    color: theme.palette.custom.stages[stage],
    opacity: 0,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
    lineHeight: 1.1
  })
}));

export const ContentPickerCardStage = (props: IProps) => {
  const { className, stage } = props;
  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <div className={classes.circle}></div>
        <Typography className={classes.text} component="span">
          {translate(`contentpicker.${stage}`)}
        </Typography>
      </div>
    </div>
  );
};
