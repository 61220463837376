import classnames from "classnames";
import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { PageElementAction } from "models/pages";
import React, { createRef, useContext, useEffect, useState } from "react";
import DropPageElementContext from "components/siteStructure/pageView/pageElements/context/dropPageElementContext";
import CopyPageElementContext from "components/siteStructure/pageView/pageElements/context/copyPageElementContext";
import { UxdIcon } from "components/shared/uxdIcon";

interface IProps extends IBaseProps {
  visible: boolean;
  mode: PageElementAction;
  slot: string;
  containerId: string;
  position: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    height: theme.spacing(1),
    opacity: 0,
    justifyContent: "center",
    alignItems: "center",
    transition: "all ease 300ms",
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    color: theme.palette.custom.ds.viking.viking500,
    "&:hover": {
      opacity: 1,
      padding: theme.spacing(1),
      "& $labelText": {
        display: "flex",
        width: "100%",
        alignItems: "center"
      },
      "& $content": {
        height: theme.spacing(0.25),
        backgroundColor: theme.palette.custom.ds.viking.viking500,
        flex: "1 1 100%",
        borderRadius: "4px"
      }
    }
  },
  hidden: {
    opacity: 0,
    visibility: "hidden",
    height: theme.spacing(0),
    pointerEvents: "none",
    border: "unset",
    margin: 0,
    padding: 0
  },
  content: {},
  labelText: {},
  icon: {
    fontSize: "21px"
  }
}));

const DESTINATION_SLOT_ID = "destination-slot";

export const DestinationSlotArea = (props: IProps) => {
  const { className, visible, slot, containerId, position } = props;
  const classes = useStyles();
  const element = createRef<HTMLDivElement>();
  const rootClassName = classnames(
    classes.root,
    { [classes.hidden]: !visible },
    className
  );

  const [currentMode, setCurrentMode] = useState<string>("none");

  const dropContext = useContext(DropPageElementContext);
  const copyContext = useContext(CopyPageElementContext);

  useEffect(() => {
    const mode =
      dropContext.dropMode !== "none"
        ? dropContext.dropMode
        : copyContext.copyMode;

    setCurrentMode(mode);
  }, [dropContext.dropMode, copyContext.copyMode]);

  useEffect(() => {
    const elem = element.current;

    const eventHandlerAdd = () => {
      elem?.parentElement?.classList.add("hover");
    };
    const eventHandlerRemove = () => {
      elem?.parentElement?.classList.remove("hover");
    };

    if (dropContext.dropMode === "none" || copyContext.copyMode === "none") {
      eventHandlerRemove();
      return () => undefined;
    }

    if (elem) {
      elem.addEventListener("mouseover", eventHandlerAdd);
      elem.addEventListener("mouseleave", eventHandlerRemove);
    }

    return () => {
      elem?.removeEventListener("mouseover", eventHandlerAdd);
      elem?.removeEventListener("mouseleave", eventHandlerRemove);
    };
  }, [element, dropContext.dropMode, copyContext.copyMode]);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (dropContext.dropMode === "add") {
      dropContext.add(containerId, slot, position);
    } else if (dropContext.dropMode === "move") {
      dropContext.move(containerId, slot, position);
    } else if (copyContext.copyMode === "copy") {
      copyContext.copy(containerId, slot, position);
    }
  };

  const clickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
    event.stopPropagation();

    const clickedElementId = event.currentTarget?.activeElement?.id ?? "";
    if (clickedElementId !== DESTINATION_SLOT_ID) {
      dropContext.clear();
      copyContext.clear();
    }
  };

  return (
    <ClickAwayListener
      onClickAway={clickAway}
      mouseEvent={currentMode !== "none" ? "onClick" : false}
      touchEvent={currentMode !== "none" ? "onTouchEnd" : false}
    >
      <div
        className={rootClassName}
        id={DESTINATION_SLOT_ID}
        onClick={onClick}
        ref={element}
      >
        <div className={classes.labelText}>
          <span className={classes.content} />
          <UxdIcon name="add_circle" className={classes.icon} />
          <span className={classes.content} />
        </div>
      </div>
    </ClickAwayListener>
  );
};
