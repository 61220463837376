import { PermissionCodes } from "catalogs/permissionCodes";
import UrlEntry from "components/siteStructure/aliases/components/urlEntry";
import { IBaseProps } from "components/_baseProps";
import { useUrlsAndAliasesContext } from "components/siteStructure/aliases/context";

import { useStyles } from "./styles";

interface IProps extends IBaseProps {
  onClose: () => void;
}

export default function UrlsAndAliasesListComponent(props: IProps) {
  const classes = useStyles();

  const { urlMetadataForms, aliasesManagement } = useUrlsAndAliasesContext();
  const { getAliasMetadataFormByCulture } = aliasesManagement;
  return (
    <div className={classes.container}>
      {urlMetadataForms.map((form) => (
        <UrlEntry
          key={`${form.culture}`}
          permissions={[PermissionCodes.EditUrlTranslations]}
          urlForm={form}
          aliasForm={getAliasMetadataFormByCulture(form.culture)}
        />
      ))}
    </div>
  );
}
