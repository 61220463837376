import { Button, makeStyles } from "@material-ui/core";
import { PreviewToolTip } from "components/previewPage/previewBar/previewToolTip";
import { UxdIcon } from "components/shared/uxdIcon";
import { theme } from "theme/theme";
import { translate } from "utils/i18n";

interface IProps {
  previewUrl?: string;
}

const openInNewTab = (url: string | undefined) => {
  url && window.open(url, "_blank", "noopener,noreferrer");
};

const useStyles = makeStyles<typeof theme>((th) => ({
  root: {},
  button: {
    minWidth: th.spacing(5),
    "&:hover": {
      backgroundColor: th.palette.custom.ds.grayscales.gr800
    }
  },
  icon: {
    color: th.palette.custom.ds.grayscales.gr500
  },
  tooltip: {
    backgroundColor: th.palette.custom.ds.grayscales.gr800
  },
  arrow: {
    color: th.palette.custom.ds.grayscales.gr800
  }
}));

export const OpenViewPage = (props: IProps) => {
  const { previewUrl } = props;
  const classes = useStyles();

  return (
    <PreviewToolTip title={translate("preview.tooltips.viewfullscreen")}>
      <Button
        className={classes.button}
        onClick={() => openInNewTab(previewUrl)}
      >
        <UxdIcon className={classes.icon} name={"launch"} outline={false} />
      </Button>
    </PreviewToolTip>
  );
};
