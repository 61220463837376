import { IBaseProps } from "components/_baseProps";
import {
  dashboardRoute,
  linkRulesRoute,
  permissionsRoute,
  siteStructureRoute
} from "routes/routes";
import { SITE_STRUCTURE_BASE } from "routes/paths";
import { translate } from "utils/i18n";
import { DrawerEntry } from "components/appContainer/appDrawer/drawerEntry";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";

interface IProps extends IBaseProps {
  compact: boolean;
  currentPath: string;
  onItemClick?: () => void;
}

export const DrawerEntries = (props: IProps) => {
  const { compact, currentPath, onItemClick } = props;
  return (
    <>
      <DrawerEntry
        compact={compact}
        icon="dashboard"
        text={translate("routes.dashboards")}
        path={dashboardRoute.path}
        active={dashboardRoute.path === currentPath}
        onClick={onItemClick}
      />
      {userService.hasPermissions(PermissionCodes.ViewSiteStructure) && (
        <DrawerEntry
          compact={compact}
          icon="public"
          text={translate("routes.sitestructure")}
          path={SITE_STRUCTURE_BASE}
          active={siteStructureRoute.path === currentPath}
          onClick={onItemClick}
        />
      )}
      {userService.hasPermissions(PermissionCodes.ViewNavigationRules) && (
        <DrawerEntry
          compact={compact}
          icon={linkRulesRoute.icon}
          text={translate(linkRulesRoute.name)}
          path={linkRulesRoute.path}
          active={linkRulesRoute.path === currentPath}
          onClick={onItemClick}
        />
      )}
      {userService.hasPermissions(PermissionCodes.ViewAuthorizationGroups) && (
        <DrawerEntry
          compact={compact}
          icon={permissionsRoute.icon}
          text={translate(permissionsRoute.name)}
          path={permissionsRoute.path}
          active={permissionsRoute.path === currentPath}
          onClick={onItemClick}
        />
      )}
    </>
  );
};
