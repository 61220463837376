import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    width: "35%",
    backgroundColor: theme.palette.common.white,
    borderLeft: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    transition: "width 0.3s ease-out",
    maxWidth: theme.spacing(100),
    minWidth: theme.spacing(60)
  },
  collapsed: {
    width: 0
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    overflowY: "auto",
    padding: theme.spacing(3, 4, 5)
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(2, 4)
  },
  headerIcon: {
    color: theme.palette.custom.ds.viking.viking500,
    fontSize: theme.typography.pxToRem(24)
  },
  newChip: {
    background: theme.palette.custom.ds.variables.keyvalue500,
    color: theme.palette.custom.ds.grayscales.gr200,
    textTransform: "uppercase"
  },
  title: {
    display: "flex",
    gap: theme.spacing(1)
  },
  hint: {
    fontStyle: "italic",
    color: theme.palette.custom.ds.grayscales.gr700,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19)
  },
  field: {
    paddingTop: theme.spacing(2)
  },
  sectionTitle: {
    letterSpacing: "2px",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr600
  },
  duplicateRuleWarning: {
    borderRadius: theme.spacing(1),
    backgroundColor: "#fff8e8",
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.custom.ds.grayscales.gr700,
    fontWeight: 400,
    display: "flex",
    padding: theme.spacing(1, 1.5),
    "& .warning-title": {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      color: theme.palette.custom.ds.warning.warning500
    },
    "& .warning-message": {
      fontStyle: "italic"
    }
  },
  warningSection: {
    width: "40px"
  },
  warningIcon: {
    color: theme.palette.custom.ds.warning.warning500
  }
}));
