import { put } from "redux-saga/effects";
import { Action } from "redux-actions";
import { AddSiteDirectory } from "@d3-forge/forge-commands";
import { issueCommand } from "redux/actions/commandActions";
import { CreateDirectoryPayload } from "redux/actions/typings/siteStructureActions";

export function* createDirectory(action: Action<CreateDirectoryPayload>) {
  try {
    const { path, onSuccess } = action.payload;
    const command = new AddSiteDirectory({ path });

    const commandAction = issueCommand({
      command,
      errorMessage: "notification.createdirectory.error",
      successMessage: "notification.createdirectory.success",
      toggleNotification: true
    });

    yield put(commandAction);

    onSuccess && onSuccess();
  } catch (error) {
    console.error(error);
  }
}
