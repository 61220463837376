import { IBaseProps } from "components/_baseProps";
import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Textbox } from "../textbox";
import { UxdIcon } from "../uxdIcon";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
  search: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: theme.spacing(4),

    borderRadius: theme.spacing(0.25)
  },
  outline: {
    outline: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
    "&:hover": {
      outline: `2px solid ${theme.palette.custom.ds.viking.viking300}`
    }
  },
  magnifier: {
    color: theme.palette.custom.ds.grayscales.gr500,
    margin: theme.spacing(0, 1, 0, 0),
    "&$collapse": {
      "&$collapse:hover": {
        backgroundColor: "transparent",
        color: theme.palette.custom.ds.viking.viking500
      },

      "&$collapse$active": {
        color: theme.palette.custom.ds.viking.viking500
      }
    }
  },
  textarea: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-input": {
      lineHeight: theme.typography.pxToRem(24),
      fontSize: theme.typography.pxToRem(14),
      padding: "inherit"
    },

    "&$collapse": {
      width: 0
    },
    "&$collapse$active": {
      width: "100%"
    }
  },
  collapse: {},
  active: {}
}));

export interface ID3SearchBoxProps extends IBaseProps {
  placeholder?: string;
  hideBorder?: boolean;
  collapse?: boolean;
  iconClassname?: string;
  onTextChange: (query: string) => void;
}

export const D3SearchBox = (props: ID3SearchBoxProps) => {
  const { iconClassname } = props;
  const classes = useStyles();

  const [query, setQuery] = useState<string>("");
  const [active, setActive] = useState(false);
  const textRef = useRef<HTMLInputElement | null>(null);

  const handleCollapse = () => {
    setActive((prev) => !prev);
  };

  const onChange = (value: string) => {
    setQuery(value);
    props.onTextChange(value);
  };

  useEffect(() => {
    if (active && textRef.current) {
      textRef.current.focus();
    }
  }, [active]);

  const rootClassName = classNames(props.className, classes.search, {
    [classes.outline]: props.collapse ? false : !props.hideBorder,
    [classes.collapse]: props.collapse,
    [classes.active]: active
  });

  const magnifierClassName = classNames(classes.magnifier, {
    [classes.collapse]: props.collapse,
    [classes.active]: active
  });

  const textAreaClassName = classNames(classes.textarea, {
    [classes.collapse]: props.collapse,
    [classes.active]: active
  });

  return (
    <div className={rootClassName}>
      {!props.collapse && (
        <UxdIcon name="search" className={magnifierClassName} />
      )}
      {props.collapse && (
        <IconButton
          onClick={handleCollapse}
          disableRipple
          className={magnifierClassName}
        >
          <UxdIcon name="search" className={iconClassname} />
        </IconButton>
      )}
      <Textbox
        inputRef={textRef}
        className={textAreaClassName}
        value={query}
        placeholder={props.placeholder}
        onChange={onChange}
        onBlur={() => !query && setActive(false)}
      />
    </div>
  );
};

export default D3SearchBox;
