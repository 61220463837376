import React, { useState } from "react";
import classnames from "classnames";
import { Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { D3Modal, D3SearchBox } from "components/shared/d3Components";
import { useMultiSelect } from "components/shared/multiSelect/hooks";
import MultiSelectListItem from "components/shared/multiSelect/multiselectListItem";
import { translate } from "utils/i18n";
import { useStyles } from "./styles";

export interface IMultiSelectListItem {
  key: string;
  label: string;
  icon?: React.ReactNode;
  description?: string | null;
}

export interface ID3MultiSelectListModalProps extends IBaseProps {
  onConfirm: (selectedItems: string[]) => void;
  onCancel?: () => void;
  hideSearch?: boolean;
  items?: IMultiSelectListItem[];
  itemIcon?: React.ReactNode;
  title: string;
  noItemsText?: string;
  placeholder?: string;
  cancelLabel?: string;
  confirmLabel?: {
    verb: string;
    singular: string;
    plural: string;
  };
  showItemSeparator?: boolean;
  itemsClassName?: string;
}

export const D3MultiSelectListModal = (props: ID3MultiSelectListModalProps) => {
  const classes = useStyles();
  const {
    onConfirm,
    title,
    placeholder,
    noItemsText,
    itemIcon,
    items = [],
    hideSearch = false,
    showItemSeparator = true,
    itemsClassName
  } = props;

  const [query, setQuery] = useState<string>("");

  const { checkedList, handleClick, clearChecked } = useMultiSelect();

  const isSelected = (key: string) => checkedList.includes(key);

  const onTextChange = (value: string) => setQuery(value);

  const onCancel = () => {
    props.onCancel?.call(this);
    clearChecked();
  };

  const itemClassNames = classnames(classes.item, itemsClassName);

  const filteredItems = items.filter((x) =>
    x.label.toLowerCase().includes(query.toLowerCase())
  );

  const getConfirmLabel = () => {
    const _default = translate("general.confirm");
    if (!props.confirmLabel) return _default;

    const prefix = props.confirmLabel.verb;
    const suffix =
      checkedList.length > 1
        ? props.confirmLabel.plural
        : props.confirmLabel.singular;

    return checkedList.length > 0
      ? `${prefix} ${checkedList.length} ${suffix}`
      : _default;
  };

  return (
    <D3Modal
      open={true}
      hideButtons={checkedList.length === 0}
      className={classes.modal}
      modalTitle={title}
      onConfirm={() => onConfirm(checkedList)}
      onCancel={onCancel}
      cancelLabel={props.cancelLabel}
      confirmLabel={getConfirmLabel()}
      confirmIcon="add_circle"
      confirmIconPosition="left"
    >
      {!hideSearch && (
        <D3SearchBox
          onTextChange={onTextChange}
          placeholder={placeholder}
          className={classes.search}
        />
      )}

      <div className={classes.list}>
        {filteredItems.length === 0 ? (
          <Typography>{noItemsText}</Typography>
        ) : (
          filteredItems.map((item, index) => {
            const selected = isSelected(item.key);

            return (
              <MultiSelectListItem
                className={classnames(
                  itemClassNames,
                  {
                    [classes.separator]: showItemSeparator && index !== 0
                  },
                  {
                    [classes.itemSelected]: selected
                  }
                )}
                key={item.key}
                id={item.key}
                content={item.label}
                secondaryContent={item.description ?? undefined}
                handleClick={handleClick}
                selected={isSelected(item.key)}
                leftIcon={itemIcon}
              />
            );
          })
        )}
      </div>
    </D3Modal>
  );
};

export default D3MultiSelectListModal;
