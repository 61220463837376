import { makeStyles, Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import noTemplateImage from "images/no_template.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 1),
    margin: "0 auto",
    width: theme.spacing(45) // 360px
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
    marginBottom: theme.spacing(7)
  },
  messageHeading: {
    marginBottom: theme.spacing(3),
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20)
  },
  messageBody: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.custom.ds.grayscales.gr600,
    padding: theme.spacing(0, 5)
  }
}));

export const PageSidebarNoTemplate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={noTemplateImage}
        alt=""
        role="presentation"
      />
      <Typography
        className={classes.messageHeading}
        variant="h6"
        align="center"
      >
        {translate("sitestructure.notemplateheading")}
      </Typography>
      <Typography
        className={classes.messageBody}
        variant="body2"
        align="center"
      >
        {translate("sitestructure.notemplatebody")}
      </Typography>
    </div>
  );
};
