export type ForgeEntity = {
  translationId: string;
  entityId: string;

  entityTypeCode: string;

  title: string;
  slug: string;

  culture: string;

  stage: ForgeEntityStage;
  status: ForgeEntityStatus;

  updateUser: string;
  updateDate: string;

  entityDetailPageAbsoluteUrl: string;
};

export type ForgeEntityStage = "published" | "unpublished" | "reviewed";
export type ForgeEntityStatus = "active" | "archived";

export type ForgeEntityDefinition = {
  typeCode: string;
  label: string | null;
  icon: string | null;
  enabled: boolean;
};

export type ForgeBuiltInEntityType =
  | "story"
  | "photo"
  | "album"
  | "document"
  | "selection";

export type ForgeEntityType = ForgeBuiltInEntityType | "customentity";

export type TagsPickerSuggestion = TagsDatasource | TagSuggestion;

export type TagSuggestion =
  | InternalTagSuggestion
  | ExternalTagSuggestion
  | CustomEntityTagSuggestion;

export type InternalTagSuggestion = {
  entityId: string;
  translationId: string;
  language: string;
  label: string;
  slug: string;
  source: "internal";
  stage: ForgeEntityStage;
};

export type ExternalTagSuggestion = {
  dataSourceName: string;
  dataSourcePath: string | null;
  extradata: object | null;
  dataSourceId: string;
  label: string;
  slug: string;
  title: string;
  type: "directory" | null;
  source: "external";
  stage: ForgeEntityStage;
};

export type CustomEntityTagSuggestion = {
  dataSourceName: string;
  dataSourceId: string;
  extradata: object | null;
  label: string;
  slug: string;
  title: string;
  type: string;
  source: "customentity";
  stage: ForgeEntityStage;
};

export type TagsDatasource = {
  code: string;
  source: "external" | "customentity";
  slug?: string;
};

export type TagSource = "internal" | "external" | "customentity";

export function isExternalTagFolder(x: any) {
  return x.type === "directory";
}

export function isTagDatasource(x: any): x is TagsDatasource {
  return Boolean(x.code);
}

export type TagInputItem = {
  entityId: string;
  translationId: string;
  language: string;
  label: string;
  slug: string;
  source: TagSource;
  stage: ForgeEntityStage;
};
