import { TextField, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { UxdIcon } from "components/shared/uxdIcon";
import { useState } from "react";
import classNames from "classnames";
import { IDropdownTextareaProps } from "components/shared/d3Components/d3DropdownTextarea/interfaces";

export function D3DropdownTextarea(props: IDropdownTextareaProps) {
  const classes = useStyles(props);
  const {
    insertMode,
    filledMode,
    invalidMode,
    value = "",
    placeholder = "",
    isValid = true,
    onChange = () => undefined,
    onBlur = () => undefined
  } = props;

  const [expanded, setExpanded] = useState(false);
  const showInvalidStyle = !expanded && !isValid;

  const headerClass = classNames(classes.header, {
    [classes.expanded]: expanded,
    [classes.invalid]: showInvalidStyle
  });
  const headerPartClass = classNames(classes.headerPart, {
    [classes.invalid]: showInvalidStyle
  });
  const expandIconClass = classNames(classes.expandIcon, {
    [classes.expanded]: expanded
  });
  const dropdownPanelClass = classNames(classes.dropdownPanel, {
    [classes.expanded]: expanded
  });
  const errorMessageClass = classNames(classes.errorMessage, {
    [classes.invalid]: showInvalidStyle
  });

  const onExpandClick = () => setExpanded(!expanded);

  const getCurrentMode = () => {
    if (!isValid) return invalidMode;
    if (!expanded && value) return filledMode;
    return insertMode;
  };

  const onBlurEvent = () => {
    setExpanded(false);
    onBlur();
  };

  return (
    <div className={classes.container}>
      <div className={headerClass} onClick={onExpandClick}>
        <div className={classes.headerFixedPart}>{getCurrentMode()?.icon}</div>
        <div className={headerPartClass}>
          <Typography variant="body2">{getCurrentMode()?.label}</Typography>
        </div>
        <div className={classes.headerFixedPart}>
          <UxdIcon name="keyboard_arrow_down" className={expandIconClass} />
        </div>
      </div>
      <div className={dropdownPanelClass}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          multiline
          inputProps={{ className: classes.textarea }}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlurEvent}
        />
      </div>
      <div>
        <Typography
          component="p"
          variant="caption"
          className={errorMessageClass}
        >
          {invalidMode?.message}
        </Typography>
      </div>
    </div>
  );
}
