import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { IModuleDefinition } from "models/siteAssets/moduleDefinition";
import { ILayoutDefinition } from "models/siteAssets/layoutDefinition";
import { UxdIcon } from "components/shared/uxdIcon";
import { DefaultModuleIcon } from "components/shared/defaultModuleIcon";
import {
  AssetType,
  AssetTypes
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";

interface IProps extends IBaseProps {
  elementType: AssetType;
  pageElementDefinition: IModuleDefinition | ILayoutDefinition;
  size: "small" | "normal" | "large";
}

const useStyles = makeStyles((theme) => ({
  root: (props: IProps) => {
    const sizes = {
      small: 4,
      normal: 5,
      large: 6
    };

    return {
      width: theme.spacing(sizes[props.size]),
      height: theme.spacing(sizes[props.size]),
      minWidth: theme.spacing(sizes[props.size]),
      minHeight: theme.spacing(sizes[props.size])
    };
  },
  noIcon: {
    borderRadius: "50%",
    color: theme.palette.common.white,
    display: "flex"
  },
  fallback: {
    margin: "auto"
  },
  icon: {
    color: theme.palette.custom.ds.viking.viking500,
    border: `1px solid ${theme.palette.custom.ds.viking.viking500}`,
    borderRadius: "50%",
    textAlign: "center",
    fontSize: theme.spacing(3),
    lineHeight: `${theme.spacing(4)}px`
  }
}));

export const SiteItemElementIcon = (props: IProps) => {
  const { className, elementType, pageElementDefinition } = props;
  const classes = useStyles(props);

  const rootClassName = classnames(className, classes.root);
  const noIconClassName = classnames(rootClassName, classes.noIcon);
  const iconClassName = classnames(classes.root, classes.icon);

  switch (elementType) {
    case AssetTypes.TEMPLATE:
      return (
        <UxdIcon className={iconClassName} name="table_chart" outline={false} />
      );
    case AssetTypes.LAYOUT:
      return (
        <UxdIcon className={iconClassName} name="view_quilt" outline={false} />
      );
    case AssetTypes.MENU:
      return <UxdIcon className={iconClassName} name="menu" />;
    case AssetTypes.MODULE: {
      const moduleDefinition = pageElementDefinition as IModuleDefinition;
      if (moduleDefinition.icon.base64) {
        return (
          <img
            className={rootClassName}
            alt={moduleDefinition.id}
            src={`data:image/${moduleDefinition.icon.type};base64, ${moduleDefinition.icon.base64}`}
          />
        );
      }

      let icon;
      switch (moduleDefinition.id) {
        case "RichTextEditor":
          icon = <UxdIcon name="notes" className={classes.fallback} />;
          break;
        case "GraphicAssetDashboardModule":
          icon = <UxdIcon name="landscape" className={classes.fallback} />;
          break;
        default:
          icon = <DefaultModuleIcon className={classes.fallback} />;
          break;
      }

      return <div className={noIconClassName}>{icon}</div>;
    }
    default:
      return <div className={noIconClassName} />;
  }
};
