import { Fragment, useContext } from "react";
import classnames from "classnames";
import { Grid, makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ContentPickerCard } from "components/contentPicker/contentPickerCard";
import { ForgeEntity } from "models/forgeEntities";
import { ContentPickerContext } from "components/contentPicker/contentPickerContext";

interface IProps extends IBaseProps {
  pages: ForgeEntity[][];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {},
  item: {
    margin: theme.spacing(1)
  }
}));

export const ContentPickerGrid = (props: IProps) => {
  const { className, pages } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const { selected, setSelected } = useContext(ContentPickerContext);

  const selectEntity = (entity: ForgeEntity) => {
    if (selected?.translationId === entity.translationId) {
      setSelected(null);
    } else {
      setSelected(entity);
    }
  };

  return (
    <Grid className={rootClassName} container justify="center">
      {pages.map((entities, i) => (
        <Fragment key={i}>
          {entities.map((e) => (
            <Grid
              item
              className={classes.item}
              key={e.translationId}
              onClick={() => selectEntity(e)}
            >
              <ContentPickerCard
                className={classes.card}
                entity={e}
                selected={e.translationId === selected?.translationId}
              />
            </Grid>
          ))}
        </Fragment>
      ))}
    </Grid>
  );
};
