import classnames from "classnames";
import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { TagInputItem } from "models/forgeEntities";

interface IProps extends IBaseProps {
  tag: TagInputItem;
  onRemoveClick?: (tag: TagInputItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    height: theme.spacing(2),
    maxWidth: "100%",
    backgroundColor: theme.palette.custom.secondaryAccent.tiffany,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.5),
    margin: theme.spacing(0.25)
  },
  text: {
    flex: 1,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(0.5)
  },
  button: {
    color: theme.palette.custom.brandBlues.d3Blue
  },
  icon: {
    padding: 0,
    fontSize: theme.spacing(2),
    opacity: 0.8
  }
}));

export const ContentPickerTagsInputChip = (props: IProps) => {
  const { className, tag, onRemoveClick = () => {} } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <Typography className={classes.text} component="span" noWrap={true}>
        {tag.label}
      </Typography>
      <IconButton
        className={classes.button}
        onClick={() => onRemoveClick(tag)}
        size="small"
      >
        <Tooltip title={translate("tagspicker.removetag")}>
          <UxdIcon className={classes.icon} name="close" />
        </Tooltip>
      </IconButton>
    </div>
  );
};
