import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { useSiteItemVariableSuggestions } from "api/queryHooks";
import { loadVariableSuggestions } from "redux/actions/variablesActions";
import {
  PageElement,
  PageElementDefinition,
  SiteItemElement
} from "components/siteStructure/siteItemSidebar/siteItemSidebarTypes";
import { CollapsedSidebarContent } from "components/siteStructure/siteItemSidebar/collapsedSidebarContent";
import PageSidebarContent from "components/siteStructure/pageView/pageSidebar/pageSidebarContent";
import { MenuSidebarContent } from "components/siteStructure/menuView/components/menuSidebar/menuSidebarContent";
import { IMenu } from "models/menus";
import { canEditSidebarProperties } from "components/siteStructure/siteItemSidebar/utils";
import classNames from "classnames";
import { wait } from "utils/javascriptUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: theme.spacing(55), // 440px
    backgroundColor: theme.palette.custom.ds.fiord.fiord100,
    overflowY: "auto"
  },

  scrollbarNotVisible: {
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent"
    }
  }
}));

interface IPageDetailSidebarContentProps extends IBaseProps {
  open: boolean;
  node: ISiteItem;
  element: SiteItemElement;
  definition?: PageElementDefinition;
  isMenu: boolean;
  onScroll?: (scrolled: boolean) => void;
}

export const SiteItemSidebarContent = (
  props: IPageDetailSidebarContentProps
) => {
  const { open, node, element, definition, isMenu, onScroll } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const request = useSiteItemVariableSuggestions();

  const containerRef = useRef(null);
  const targetRef = useRef(null);

  const [isSidebarScrollbarVisible, setIsSidebarScrollbarVisible] =
    useState<boolean>(false);

  const canEditProperties = canEditSidebarProperties(element, node.path);

  const rootClassNames = classNames(classes.root, {
    [classes.scrollbarNotVisible]: !isSidebarScrollbarVisible
  });

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const visible = entries.some((entry) => !entry.isIntersecting);
      if (onScroll) {
        onScroll(visible);
      }
    },
    [onScroll]
  );

  useEffect(() => {
    const scrollableElement = containerRef.current as HTMLDivElement | null;

    if (scrollableElement === null) {
      return;
    }

    const onScroll = () => {
      setIsSidebarScrollbarVisible(true);
    };

    const onScrollEnd = async () => {
      await wait(2000); // We wait for 2 seconds before making the scrollbar to disappear per requirement
      setIsSidebarScrollbarVisible(false);
    };

    scrollableElement.addEventListener("scroll", onScroll);

    scrollableElement.addEventListener("scrollend", onScrollEnd);

    return () => {
      scrollableElement.removeEventListener("scroll", onScroll);
      scrollableElement.removeEventListener("scrollend", onScrollEnd);
    };
  }, []);

  useEffect(() => {
    const target = targetRef.current;
    const container = containerRef.current;

    const options: IntersectionObserverInit = {
      threshold: 0.05,
      root: container
    };

    const observer = new IntersectionObserver(observerCallback, options);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [observerCallback]);

  useEffect(() => {
    request.mutate(node.nodeId);
  }, [node.nodeId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (request.isSuccess)
      dispatch(loadVariableSuggestions({ variables: request.data || [] }));
  }, [request.data, request.isSuccess, dispatch]);

  if (!open) {
    return <CollapsedSidebarContent />;
  }

  return (
    <div ref={containerRef} className={rootClassNames}>
      <div ref={targetRef}></div>
      {!isMenu && definition && (
        <PageSidebarContent
          node={node}
          element={element as PageElement}
          definition={definition}
          canEditProperties={canEditProperties}
        />
      )}
      {isMenu && (
        <MenuSidebarContent
          menu={element as IMenu}
          canEditProperties={canEditProperties}
        />
      )}
    </div>
  );
};

export default SiteItemSidebarContent;
