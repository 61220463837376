import { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { noop, xor } from "lodash";
import { Button, makeStyles, Snackbar, Typography } from "@material-ui/core";
import { PERMISSIONS } from "routes/paths";
import { translate } from "utils/i18n";
import classNames from "classnames";
import { D3Button } from "components/shared/d3Components";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";

const useStyles = makeStyles((theme) => ({
  root: {},
  removeButton: {
    backgroundColor: "inherit",
    textTransform: "none",
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(255,255,255, 0.1)",
      color: theme.palette.custom.ds.viking.viking500,
      "& .MuiTypography-body2": {
        color: "white"
      }
    }
  },
  closeIcon: {
    padding: theme.spacing(1),
    color: "white",
    minWidth: theme.spacing(3),
    "&:hover": {
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  snackbar: {
    position: "absolute",
    width: "100%",
    bottom: theme.spacing(2),
    right: "0",
    "& .MuiSnackbarContent-root": {
      width: "100%"
    }
  },
  snackbarContent: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr800,
    display: "inline-flex",
    alignItems: "center"
  }
}));

interface Props extends IBaseProps {
  singleSelectionText: string;
  multiSelectionText: string;
  handleRemove: () => void;
}

/**
 * @hook
 * This hook provides the functionality of the MultiSelectListItem
 */

export interface IMultiSelectResult {
  checkedList: string[];
  handleClick: (event: React.MouseEvent, id: string) => void;
  clearChecked: () => void;
  MultiListSnackbar: (props: Props) => JSX.Element;
}

export function useMultiSelect(): IMultiSelectResult {
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const classes = useStyles();

  const route = useRouteMatch<{ authGroupId: string }>(
    `${PERMISSIONS}/:authGroupId`
  );

  const handleClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    setCheckedList((current) => xor(current, [id]));
  };

  const clearChecked = useCallback(() => {
    setCheckedList([]);
  }, [setCheckedList]);

  useEffect(() => {
    clearChecked();
  }, [route?.params.authGroupId, clearChecked]);

  /**
   * @component
   * This is the component that renders when there are clicked items in the MultiSelectList
   * @props
   * singleSelectionText: string -  What text should be displayed when a single item is selected.
   *
   * MultiSelectionText: string -  What text should be displayed when multiple items are selected.
   *
   * handleRemove: Function that executes when clicking the remove button
   *
   * @example
   *
   * <MultiListSnackbar singleSelectionText="user selected"  multiSelectionText="users selected" handleRemove={handleRemoveMehtod}/>
   */
  const MultiListSnackbar = (props: Props) => {
    const { singleSelectionText, multiSelectionText, handleRemove, className } =
      props;
    const rootClassName = classNames(classes.root, className);
    return (
      <span className={rootClassName}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={checkedList.length > 0}
          onClose={clearChecked}
          className={classes.snackbar}
          ClickAwayListenerProps={{ onClickAway: () => noop() }}
          message={
            <>
              <div className={classes.snackbarContent}>
                <Button className={classes.closeIcon} onClick={clearChecked}>
                  <UxdIcon name="close" />
                </Button>
                <Typography variant="body2">
                  {checkedList.length === 1
                    ? `1 ${singleSelectionText}`
                    : `${checkedList.length} ${multiSelectionText}`}
                </Typography>
              </div>
            </>
          }
          action={
            <D3Button
              onClick={handleRemove}
              variant="ghost"
              color="secondary"
              className={classes.removeButton}
            >
              <UxdIcon name="delete_outline" />
              <Typography variant="body2">
                {translate("permissions.list.removetooltip")}
              </Typography>
            </D3Button>
          }
        />
      </span>
    );
  };

  return {
    checkedList,
    handleClick,
    clearChecked,
    MultiListSnackbar
  };
}
