import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ILayoutRow } from "models/siteAssets/layoutDefinition";
import { DesignColumn } from "components/siteStructure/template/preview/designColumn";

interface IProps extends IBaseProps {
  row: ILayoutRow;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row"
  },
  fixed: {
    flex: "unset !important",
    height: theme.spacing(4)
  },
  column: {
    flex: 1,
    borderRight: `2px solid ${theme.palette.common.white}`,
    "&:last-child": {
      borderRight: "unset"
    }
  }
}));

export const DesignRow = (props: IProps) => {
  const { className, row } = props;
  const classes = useStyles();

  const rootClassName = classnames(
    classes.root,
    {
      [classes.fixed]: row.isFixed
    },
    className
  );

  return (
    <div className={rootClassName}>
      {row.columns.map((column, index) => (
        <DesignColumn
          key={`${column.name}-${index}`}
          className={classes.column}
          column={column}
        />
      ))}
    </div>
  );
};
