import { IVariableRouteSuggestion } from "models/variables";
import { createAction } from "redux-actions";
import {
  FAILED_ACTION_SUFFIX,
  REQUEST_ACTION_SUFFIX,
  SUCCESS_ACTION_SUFFIX
} from "redux/actions/constants";
import {
  AddPageVariablePayload,
  LoadPageVariablesPayload,
  LoadVariablesModalActionPayload,
  LoadVariableSuggestionsPayload
} from "redux/actions/typings/variableActions";

export const loadVariableSuggestions = createAction(
  "LOAD_VARIABLE_SUGGESTIONS",
  (payload: LoadVariableSuggestionsPayload) => payload
);

export const loadPageVariables = createAction(
  "LOAD_PAGE_VARIABLES",
  (payload: LoadPageVariablesPayload) => payload
);

export const addPageVariable = createAction(
  "ADD_PAGE_VARIABLE",
  (payload: AddPageVariablePayload) => payload
);

export const loadVariablesModalAction = createAction(
  "LOAD_VARIABLES_MODAL_ACTION",
  (payload: LoadVariablesModalActionPayload) => payload
);

// Routes
export const fetchRoutes = createAction(`FETCH_ROUTES${REQUEST_ACTION_SUFFIX}`);

export const fetchRoutesSucceded = createAction(
  `FETCH_ROUTES${SUCCESS_ACTION_SUFFIX}`,
  (routes: IVariableRouteSuggestion[]) => routes
);

export const fetchRoutesFailed = createAction(
  `FETCH_ROUTES${FAILED_ACTION_SUFFIX}`
);
