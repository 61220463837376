import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useQuery, useQueryClient } from "react-query";
import { HistoryApi } from "api/history";
import { HistoryContent } from "components/siteStructure/history/historyContent";
import { ROOT_ID } from "models/siteItem";
import { DelayedProgress } from "components/shared/delayedProgress";
import { HistoryLoadError } from "components/siteStructure/history/historyLoadError";
import { SiteNodeType } from "models/siteStructure";
import { useNotifications } from "components/hooks";
import {
  RolledBackNotification,
  SiteItemModifiedNotification
} from "@d3-forge/forge-notifications";
import { SiteItemHistory } from "models/history";

interface IProps extends IBaseProps {
  nodeId: string;
  nodeType: SiteNodeType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(3)
  },
  progress: {
    margin: "auto"
  }
}));

const EMPTY = { nodeId: ROOT_ID, entries: {} };

export const HistoryContainer = (props: IProps) => {
  const { className, nodeId, nodeType } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const queryKey = ["history", nodeId];
  const queryClient = useQueryClient();

  const { isLoading, error, data, isFetching } = useQuery(queryKey, () =>
    HistoryApi.getHistory(nodeId, nodeType)
  );

  useNotifications(
    ["SiteItemModifiedNotification"],
    (ntf: SiteItemModifiedNotification) => {
      if (ntf.itemId === nodeId) {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );

  useNotifications(
    ["RolledBackNotification"],
    (ntf: RolledBackNotification) => {
      if (ntf.aggregateId === nodeId) {
        queryClient.invalidateQueries(queryKey);
      }
    }
  );

  if (isLoading || isFetching) {
    return <DelayedProgress className={classes.progress} />;
  }

  if (error) {
    return <HistoryLoadError />;
  }

  const history: SiteItemHistory = data || EMPTY;
  return (
    <HistoryContent
      className={rootClassName}
      nodeId={nodeId}
      history={history}
      nodeType={nodeType}
    />
  );
};
