import React from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import gear from "images/gear.svg";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.custom.brandBlues.d3Blue,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    width: theme.spacing(20),
    animation: "15s linear infinite $spin",
    fontSize: theme.spacing(20),
    pointerEvents: "none",
    userSelect: "none"
  },
  text: {
    fontSize: "1.25rem",
    color: theme.palette.custom.mainAccent.red,
    maxWidth: theme.spacing(105),
    margin: theme.spacing(3, 2)
  },
  // animation stuff
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(360deg)"
    }
  }
}));

export const RegistrationMode = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <div className={rootClassName}>
      <img src={gear} className={classes.icon} alt="Registration mode icon" />
      <Typography className={classes.text} align="center" variant="subtitle2">
        Application is currently in registration mode, please register it on
        GUIShell before, then refresh this page
      </Typography>
    </div>
  );
};
