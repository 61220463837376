import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import React from "react";

interface IProps extends IBaseProps {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    minHeight: theme.spacing(11),
    height: "100%",
    backgroundColor: theme.palette.custom.ds.grayscales.gr700,
    color: theme.palette.custom.ds.grayscales.gr500,
    gap: theme.spacing(1.5),
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.custom.ds.grayscales.gr700}`
  },
  circleIcon: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr500,
    borderRadius: "50%",
    display: "flex",
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    height: theme.spacing(4),
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.custom.ds.grayscales.gr700
  },
  slotName: {
    fontSize: theme.typography.pxToRem(10),
    margin: theme.spacing(0, 0, 1, 0.5),
    color: theme.palette.custom.ds.grayscales.gr700
  },
  fontText: {
    fontSize: theme.typography.pxToRem(13)
  },
  fontIcon: {
    fontSize: "1rem"
  }
}));

export const FixedSlot = React.memo((props: IProps) => {
  const { name } = props;
  const classes = useStyles();

  return (
    <>
      <Tooltip title={name} placement="bottom-start">
        <Typography className={classes.slotName}>{name}</Typography>
      </Tooltip>
      <div className={classes.root}>
        <span className={classes.circleIcon}>
          <UxdIcon name="lock" className={classes.fontIcon} />
        </span>
        <Typography className={classes.fontText}>
          {translate("sitestructure.fixedslot")}
        </Typography>
      </div>
    </>
  );
});
