import {
  createStyles,
  Switch,
  SwitchClassKey,
  SwitchProps,
  Theme,
  withStyles
} from "@material-ui/core";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}
/**
 * Deltatre Switch button with Design System
 *
 * The error status is shown by the **color="primary"** prop
 *
 * @see [Design System](https://www.figma.com/file/mTyXrouZ9B4cHebcPOltDJ/Product-Design-System?node-id=1342%3A104)
 * @component
 */
export const D3Switch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 18,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "&:not($disabled) + $track": {
          backgroundColor: theme.palette.custom.ds.viking.viking500,
          opacity: 1,
          border: "none"
        },
        "&:hover + $track": {
          backgroundColor: theme.palette.custom.ds.viking.viking700
        }
      },
      "&:hover": {
        backgroundColor: theme.palette.custom.ds.grayscales.gr500,
        borderColor: theme.palette.custom.ds.grayscales.gr500
      },
      "&:focus + $track": {
        borderColor: theme.palette.custom.ds.viking.viking300,
        borderWidth: "2px"
      },
      "&$colorPrimary + $track": {
        backgroundColor: theme.palette.custom.ds.error.error500 + "!important"
      },
      "&$disabled, &$disabled + $track": {
        opacity: "0.5"
      }
    },
    thumb: {
      width: 16,
      height: 16
    },
    track: {
      borderRadius: 24 / 2,
      border: `1px solid ${theme.palette.custom.ds.grayscales.gr400}`,
      backgroundColor: theme.palette.custom.ds.grayscales.gr400,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
      height: "auto"
    },
    checked: {},
    focusVisible: {},
    colorPrimary: {},
    disabled: {}
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        colorPrimary: classes.colorPrimary,
        disabled: classes.disabled
      }}
      {...props}
    />
  );
});
