import { usePagePreviewUrl } from "api/queryHooks";
import { usePersistedState } from "components/hooks";
import { Device } from "components/previewPage/previewBar/previewBar";
import { IFrontendSitesDetail } from "models/domainStates";
import { ISiteItem } from "models/siteItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectFrontendCultures } from "redux/selectors/frontendSelectors";

export function useSideBarHook() {
  const availableCultures = useSelector(selectFrontendCultures);
  const [culture, setCulture] = usePersistedState<string>(
    "sitestructure.previewCulture",
    availableCultures[0]
  );
  const [device, setDevice] = useState<Device>("desktop");
  const [landscape, setLandscape] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");

  const toggleLandscape = () => {
    setLandscape((prev) => !prev);
  };

  return {
    availableCultures,
    culture,
    setCulture,
    device,
    setDevice,
    landscape,
    setLandscape: toggleLandscape,
    previewUrl,
    setPreviewUrl
  };
}

export function usePreviewUrlRequest(
  item: ISiteItem | undefined,
  culture: string,
  availableSites: IFrontendSitesDetail[],
  shouldReload = false
) {
  const request = usePagePreviewUrl();
  useEffect(() => {
    if (item) {
      const platform =
        availableSites.find((s) => s.culture === culture)?.platform || "*";
      const environment =
        availableSites.find((s) => s.culture === culture)?.environment || "*";
      request.mutate({
        path: item.path,
        culture,
        platform,
        environment,
        context: item.contextName
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, culture, shouldReload]);
  return request;
}
