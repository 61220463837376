import { List, makeStyles, Typography } from "@material-ui/core";
import { useAuthGroupList } from "api/queryHooks";
import { AuthGroupListItem } from "components/permissions/authorizationGroupNav/authGroupsListItems";
import { useEffect } from "react";
import { translate } from "utils/i18n";
import { D3Button, D3Modal } from "components/shared/d3Components";
import { useRouteMatch } from "react-router-dom";
import { PERMISSIONS } from "routes/paths";
import { AuthGroupListLoading } from "components/permissions/authorizationGroupNav/authGroupListLoading";
import { useRemoveAuth, useCreateAuth } from "components/permissions/hooks";
import { useDispatch, useSelector } from "react-redux";
import { loadAuthorizationGroups } from "redux/actions/authorizationGroupsActions";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import { themeSizing } from "theme/deltatreTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: themeSizing.siteTree,
    borderRight: "1px solid #D9D9D9",
    position: "relative"
  },
  title: {
    padding: theme.spacing(3),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24)
  },
  listContainer: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    overflow: "auto",
    padding: theme.spacing(0, 1, 0, 1)
  },
  list: {
    paddingBottom: theme.spacing(15),
    paddingTop: 0
  },
  buttonWrap: {
    left: "0",
    bottom: "0",
    position: "absolute",
    width: "100%",
    backgroundColor: theme.palette.common.white
  },
  addButton: {
    margin: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(3)}px - ${theme.spacing(3)}px)`,
    whiteSpace: "nowrap"
  }
}));

export const AuthorizationGroupsNav = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const request = useAuthGroupList();
  const route = useRouteMatch<{ authGroupId: string }>(
    `${PERMISSIONS}/:authGroupId`
  );

  const authList = useSelector(selectAuthorizationGroupList);
  const { isAddNewStatus, handleAddNew } = useCreateAuth();

  const { removeDialog, handleClose, handleDelete, handleRemove } =
    useRemoveAuth();

  useEffect(() => {
    dispatch(loadAuthorizationGroups(request.data || []));
  }, [request.data, dispatch]);

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          {translate("permissions.list.title")}
        </Typography>

        <div className={classes.listContainer}>
          {request.isLoading && <AuthGroupListLoading />}
          {!request.isLoading && (
            <List component="nav" className={classes.list}>
              {isAddNewStatus && (
                <AuthGroupListItem
                  authId=""
                  name={translate("permissions.list.defaultgroupname")}
                  create={true}
                  canRemove={false}
                />
              )}
              {authList.map((item, index) => (
                <AuthGroupListItem
                  key={index}
                  authId={item.groupId}
                  name={item.name}
                  selected={item.groupId === route?.params.authGroupId}
                  canRemove={true}
                  description={item.description}
                  handleRemove={handleRemove}
                />
              ))}
            </List>
          )}
        </div>
        <div className={classes.buttonWrap}>
          <D3Button
            loading={request.isLoading}
            disabled={isAddNewStatus}
            className={classes.addButton}
            onClick={handleAddNew}
            icon="add_circle"
          >
            {translate("permissions.list.addnewgroup")}
          </D3Button>
        </div>
      </div>
      <D3Modal
        modalTitle={translate("permissions.list.removedialogtitle")}
        onConfirm={handleDelete}
        onCancel={handleClose}
        cancelLabel={translate("permissions.list.removedialogdecline")}
        confirmLabel={translate("permissions.list.removedialogconfirm")}
        open={removeDialog}
      >
        <Typography gutterBottom>
          {translate("permissions.list.removedialogcontent")}
        </Typography>
      </D3Modal>
    </>
  );
};
