import { MouseEvent } from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { DialogBase } from "components/shared/dialogBase";
import { translate } from "utils/i18n";
import ModuleGrid from "./moduleGrid";
import LayoutGrid from "./layoutGrid";

interface IProps extends IBaseProps {
  open: boolean;
  pageElementType: "Module" | "Layout";
  pageContext: string;
  onClose: () => void;
  onSelect: (e: MouseEvent, selectedItemKey: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    height: "70vh",
    display: "flex",
    border: 0,
    overflow: "hidden"
  },
  grid: {
    flex: 1
  }
}));

export const SelectPageElementModal = (props: IProps) => {
  const { className, open, pageElementType, pageContext, onSelect, onClose } =
    props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const title =
    pageElementType === "Module"
      ? "sitestructure.addamodule"
      : "sitestructure.addalayout";

  return (
    <DialogBase
      className={rootClassName}
      ContentClassName={classes.content}
      title={translate(title)}
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      {pageElementType === "Module" && (
        <ModuleGrid
          className={classes.grid}
          pageContext={pageContext}
          onSelect={onSelect}
        />
      )}
      {pageElementType === "Layout" && (
        <LayoutGrid
          className={classes.grid}
          pageContext={pageContext}
          onSelect={onSelect}
        />
      )}
    </DialogBase>
  );
};
