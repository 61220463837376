import { TextField } from "@material-ui/core";
import { useLinkRulesCrudManagementContext } from "components/linkRules/context/crudManagement";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedLinkRule } from "redux/selectors/linkRulesSelectors";
import { translate } from "utils/i18n";

interface LinkRuleDescriptionProps {
  disabled?: boolean;
}

export function LinkRuleDescription({
  disabled = false
}: Readonly<LinkRuleDescriptionProps>) {
  const selectedLinkRule = useSelector(selectSelectedLinkRule);
  const maxLength = 60; // characters
  const placeholder = translate(
    "navigationrules.sidebar.description_max_length",
    { maxLength: maxLength.toString() }
  );

  const { onUpdateFormField } = useLinkRulesCrudManagementContext();

  const [description, setDescription] = useState<string>(
    selectedLinkRule?.description ?? ""
  );

  useEffect(() => {
    setDescription(selectedLinkRule?.description ?? "");
  }, [selectedLinkRule]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDescription(event.target.value);
      onUpdateFormField("description", event.target.value);
    },
    [onUpdateFormField]
  );

  return (
    <>
      <TextFieldLabel
        value={translate("navigationrules.sidebar.description")}
      />
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={3}
        inputProps={{ maxLength }}
        placeholder={placeholder}
        onChange={onChange}
        value={description}
        disabled={disabled}
      />
    </>
  );
}

export default LinkRuleDescription;
