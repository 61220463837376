import { ApiClient } from "api/apiClient";
import {
  CustomEntityTagSuggestion,
  ExternalTagSuggestion,
  InternalTagSuggestion,
  TagInputItem,
  TagsDatasource
} from "models/forgeEntities";

const BASE_URL = "api/tags";

export class TagsApi {
  static readonly getInternalTagSuggestions = async (
    language: string,
    terms: string,
    options?: RequestInit
  ): Promise<InternalTagSuggestion[]> => {
    const endpoint = `${BASE_URL}/internal/${language}?terms=${terms}`;
    return ApiClient.get(endpoint, options);
  };

  static readonly getTagPickerItems = async (
    slug: string,
    language: string
  ): Promise<InternalTagSuggestion> => {
    const endpoint = `${BASE_URL}/pickeritems/${language}/${slug}`;
    return ApiClient.get(endpoint);
  };

  static readonly getDataSources = async (): Promise<TagsDatasource[]> => {
    const endpoint = "api/tagsdatasources";
    return ApiClient.get(endpoint);
  };

  static readonly getExternalTag = async (
    dataSourceName: string,
    dataSourceId: string
  ): Promise<ExternalTagSuggestion | null> => {
    const endpoint = `${BASE_URL}/external/sources/${dataSourceName}/${dataSourceId}`;
    try {
      return await ApiClient.get(endpoint);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static readonly getCustomEntityTag = async (
    dataSourceName: string,
    dataSourceId: string
  ): Promise<CustomEntityTagSuggestion | null> => {
    const endpoint = `${BASE_URL}/customentities/${dataSourceName}/${dataSourceId}`;
    try {
      return await ApiClient.get(endpoint);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  static readonly getExternalTagSuggestion = async (
    dataSource: string,
    ids: string[],
    terms: string,
    options?: RequestInit
  ): Promise<ExternalTagSuggestion[]> => {
    const path = ids.length ? `/${ids.join("/")}` : "";

    const endpoint = `${BASE_URL}/external/search/${dataSource}${path}?terms=${terms}`;
    return ApiClient.get(endpoint, options);
  };

  static readonly getCustomEntityTagSuggestion = async (
    entityCode: string,
    terms: string,
    options?: RequestInit
  ): Promise<CustomEntityTagSuggestion[]> => {
    const endpoint = `${BASE_URL}/customEntities/${entityCode}?terms=${terms}`;
    return ApiClient.get(endpoint, options);
  };

  static readonly getTagTypeaheadSuggestion = async (
    language: string,
    terms: string,
    options?: RequestInit
  ): Promise<TagInputItem[]> => {
    const endpoint = `${BASE_URL}/typeahead/${language}?terms=${terms}`;
    return ApiClient.get(endpoint, options);
  };
}
