import { ISiteItem, SiteItems } from "models/siteItem";

export const getSiteItemSiblings = (
  siteItems: SiteItems,
  nodeId: string
): ISiteItem[] => {
  const currentParentItemId = siteItems[nodeId].parentNodesIds.at(-1);
  if (currentParentItemId && siteItems[currentParentItemId]) {
    return siteItems[currentParentItemId].children
      .filter((id) => id !== nodeId)
      .map((id) => siteItems[id]);
  }

  return [];
};
