import { VariablesApi } from "api/variablesApi";
import { put, call } from "redux-saga/effects";
import {
  fetchRoutesFailed,
  fetchRoutesSucceded
} from "redux/actions/variablesActions";

export function* getRoutes() {
  try {
    const Routes = yield call(VariablesApi.getRoutes);

    yield put(fetchRoutesSucceded(Routes));
  } catch (error) {
    yield put(fetchRoutesFailed(error));
  }
}
