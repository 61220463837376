export const redirectToLogin = () => {
  const loginUrl = window.location.origin + "/account/login";

  const redirectUrl = `${loginUrl}?returnUrl=${encodeURIComponent(
    window.location.pathname
  )}${encodeURIComponent(window.location.hash)}`;

  window.location.href = redirectUrl;
};

export const redirectToLogout = () => {
  const logoutUrl = window.location.origin + "/account/logout";
  window.location.href = logoutUrl;
};

export const getActiveElementTagName = () => document.activeElement?.tagName;
export const getIsInputFieldFocused = () =>
  getActiveElementTagName() === "INPUT";
