import { MouseEvent, useMemo } from "react";
import classnames from "classnames";
import {
  GridList,
  GridListTile,
  InputAdornment,
  makeStyles,
  TextField
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ModuleTile } from "./moduleTile";
import { UxdIcon } from "components/shared/uxdIcon";
import { useDebouncedState } from "components/hooks";
import { useModuleDefinitons } from "redux/hooks/useModuleDefinitions";
import { NoResults } from "components/siteStructure/modals/selectPageElementModal/moduleGrid/noResults";
import { translate } from "utils/i18n";
import { searchInputChooseElement } from "theme/commonClasses";
import { orderBy } from "lodash";

interface IProps extends IBaseProps {
  pageContext: string;
  onSelect: (event: MouseEvent, pageElementKey: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...searchInputChooseElement.root
  },
  tile: {
    ...searchInputChooseElement.tile
  },
  searchInput: {
    ...searchInputChooseElement.searchInput
  },
  searchInputIcon: {
    ...searchInputChooseElement.searchInputIcon
  },
  scrollable: {
    ...searchInputChooseElement.scrollable
  },
  noResults: {
    ...searchInputChooseElement.noResults
  },
  grid: {
    ...searchInputChooseElement.grid
  }
}));

export const ModuleGrid = (props: IProps) => {
  const { className, pageContext, onSelect } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const [search, debouncedSearch, setSearch] = useDebouncedState("", 300);

  const modules = useModuleDefinitons(pageContext);

  const filterRegex = useMemo(
    () =>
      new RegExp(
        `(${debouncedSearch.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
        "i"
      ),
    [debouncedSearch]
  );

  const filteredModules = useMemo(
    () => modules.filter((m) => filterRegex.test(m.label)),
    [modules, filterRegex]
  );

  const sortedModulesList = useMemo(
    () => orderBy(filteredModules, "label"),
    [filteredModules]
  );

  return (
    <div className={rootClassName}>
      <TextField
        className={classes.searchInput}
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={translate("sitestructure.searchmodule")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <UxdIcon className={classes.searchInputIcon} name="search" />
            </InputAdornment>
          )
        }}
      />
      <div className={classes.scrollable}>
        {sortedModulesList.length > 0 ? (
          <GridList
            cellHeight="auto"
            cols={1}
            spacing={0}
            className={classes.grid}
          >
            {sortedModulesList.map((m) => (
              <GridListTile
                key={m.key}
                className={classes.tile}
                cols={1}
                onClick={(e) => {
                  onSelect(e, m.key);
                }}
              >
                <ModuleTile
                  moduleDefinition={m}
                  showHighlight={false}
                  highlightRegex={filterRegex}
                />
              </GridListTile>
            ))}
          </GridList>
        ) : (
          <NoResults className={classes.noResults} />
        )}
      </div>
    </div>
  );
};
