import React from "react";
import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {}

const useStyles = makeStyles((theme) => ({
  root: {}
}));

export const NodeLoadError = (props: IProps) => {
  const { className } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  return (
    <Typography className={rootClassName} variant="caption" color="initial">
      {translate("sitestructure.nodeLoadError")}
    </Typography>
  );
};
