import { ApiClient } from "api/apiClient";
import { IFrontendSitesDetail } from "models/domainStates";

const BASE_URL = "api/frontend";

export class FrontendApi {
  static readonly getCultures = async (): Promise<string[]> => {
    const endpoint = `${BASE_URL}/cultures`;
    return ApiClient.get(endpoint);
  };

  static readonly getEnvironments = async (): Promise<string[]> => {
    const endpoint = `${BASE_URL}/environments`;
    return ApiClient.get(endpoint);
  };

  static readonly getSites = async (): Promise<IFrontendSitesDetail[]> => {
    const endpoint = `${BASE_URL}/sites`;
    return ApiClient.get(endpoint);
  };
}
