import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { differenceBy, isEmpty, map } from "lodash";
import { useMemoSelector } from "components/hooks";
import { EmptyAuthorizationGroup } from "components/siteStructure/permissions/emptyAuthorizationGroup";
import { InheritedAuthorizationGroups } from "components/siteStructure/permissions/inheritedAuthorizationgGroups";
import { LocalAuthorizationGroups } from "components/siteStructure/permissions/localAuthorizationGroups";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import {
  selectSelectedItemLocalAuthGroups,
  selectSelectedItemInheritedAuthGroups
} from "redux/selectors/siteStructureSelectors";
import { translate } from "utils/i18n";
import { useStyles } from "components/siteStructure/permissions/permissionsView/styles";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
}

export function PermissionsView(props: Readonly<IProps>) {
  const { currentSiteItem } = props;
  const classes = useStyles();
  const allAuthGroups = useSelector(selectAuthorizationGroupList);
  const localAuthGroups = useMemoSelector(
    selectSelectedItemLocalAuthGroups,
    currentSiteItem.authorizationGroups ?? []
  );

  const inheritedAuthGroups = useMemoSelector(
    selectSelectedItemInheritedAuthGroups,
    currentSiteItem.parentNodesIds || []
  );

  const selectedItemAuthGroups = useMemo(
    () => [...inheritedAuthGroups, ...localAuthGroups],
    [inheritedAuthGroups, localAuthGroups]
  );

  const getSelectableAuthGroups = useCallback(
    () =>
      map(
        differenceBy(allAuthGroups, selectedItemAuthGroups, "groupId"),
        ({ groupId, name, description }) => ({
          key: groupId,
          label: name,
          description: description
        })
      ),
    [allAuthGroups, selectedItemAuthGroups]
  );

  const hasAuthorizationGroups =
    localAuthGroups.length > 0 || inheritedAuthGroups.length > 0;

  const shouldShowInheritedAuthorizationGroups = !isEmpty(inheritedAuthGroups);

  return (
    <div className={classes.root}>
      {shouldShowInheritedAuthorizationGroups && (
        <Typography variant="h4" className={classes.title}>
          {translate("sitestructure.permissions.title")}
        </Typography>
      )}

      {hasAuthorizationGroups ? (
        <>
          {shouldShowInheritedAuthorizationGroups && (
            <InheritedAuthorizationGroups authGroups={inheritedAuthGroups} />
          )}

          <LocalAuthorizationGroups
            allAuthGroups={allAuthGroups}
            localAuthGroups={localAuthGroups}
            currentSiteItem={currentSiteItem}
            getSelectableAuthGroups={getSelectableAuthGroups}
          />
        </>
      ) : (
        <EmptyAuthorizationGroup currentSiteItem={currentSiteItem} />
      )}
    </div>
  );
}
