import { Link, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { IMetadataPropertyItem, MetadataValue } from "models/metadata";
import { translate } from "utils/i18n";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  definition: {
    marginTop: theme.spacing(0.5)
  },
  definitionLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr700
  },
  definitionValue: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.viking.viking500,
    paddingLeft: theme.spacing(0.5)
  },
  overwriteValue: {
    color: theme.palette.custom.ds.grayscales.gr600
  }
}));

export const MetadataDefinitionDisplay = (props: {
  isOverriding: boolean;
  item: IMetadataPropertyItem;
}) => {
  const { item, isOverriding } = props;
  const classes = useStyles();

  const hasDefinitionPath = (item?.definedIn?.path?.trim().length ?? 0) > 0;

  if (!hasDefinitionPath) {
    return <></>;
  }

  const handleClick = (itemUrl: MetadataValue) => {
    const url = `${window.location.origin}/#/sitestructure/${itemUrl}`;
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const overwrittenValue = String(item.definedIn?.value);

  return (
    <>
      <div className={classnames(classes.row, classes.definition)}>
        <Typography className={classes.definitionLabel}>
          {translate("metadata.defined_in")}
        </Typography>
        <Typography className={classes.definitionValue}>
          {item.definedIn?.path && (
            <Link
              component="button"
              className={classes.definitionValue}
              onClick={() => handleClick(item.definedIn?.path!)}
            >
              {item.definedIn?.path}
            </Link>
          )}
        </Typography>
      </div>
      {isOverriding && (
        <div className={classnames(classes.row, classes.definition)}>
          <Typography className={classes.definitionLabel}>
            {translate("metadata.overwrites")}:
          </Typography>
          <Typography
            className={classnames(
              classes.definitionValue,
              classes.overwriteValue
            )}
          >
            {overwrittenValue}
          </Typography>
        </div>
      )}
    </>
  );
};

export default MetadataDefinitionDisplay;
