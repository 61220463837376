import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import Typography from "@material-ui/core/Typography";
import { Hint } from "components/shared/hint";
import Preview from "./preview";
import { ITemplateDefinition } from "models/siteAssets/templateDefinition";

interface IProps extends IBaseProps {
  template: ITemplateDefinition;
  selected: boolean;
  onSelect: (id: string, namespace: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(25),
    margin: theme.spacing(0, "auto"),
    cursor: "pointer"
  },
  templatePreview: {
    flex: 1,
    height: theme.spacing(25),
    maxWidth: "100%",
    border: `1px solid ${theme.palette.custom.greyscales.bordersAndSeparators.lineDarker}`,
    "&$selected": {
      border: `2px solid ${theme.palette.custom.mainAccent.blue}`,
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.16)"
    }
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    maxWidth: "100%"
  },
  labelText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: theme.typography.pxToRem(13),
    "&$selected": {
      color: theme.palette.custom.mainAccent.blue
    }
  },
  selected: {}
}));

export const TemplateGridItem = (props: IProps) => {
  const { className, template, onSelect, selected } = props;

  const classes = useStyles(props);
  const rootClassName = classnames(classes.root, className);

  const { id, namespace, design, label, description } = template;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    onSelect(id, namespace);
  };

  const previewClassName = classnames(classes.templatePreview, {
    [classes.selected]: selected
  });

  const labelClassName = classnames(classes.labelText, {
    [classes.selected]: selected
  });

  return (
    <div className={rootClassName} role="button" onClick={handleClick}>
      <Preview className={previewClassName} design={design} />
      <div className={classes.labelContainer}>
        <Typography variant="subtitle2" className={labelClassName}>
          {label}
        </Typography>
        {description && <Hint hint={description} noSpacing={true} />}
      </div>
    </div>
  );
};
