import { useMemo } from "react";
import { TextField } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useStyles } from "components/linkRules/linkRuleConditions/linkRuleCondition/styles";
import {
  getFilteredAttributeOptions,
  getOperatorsDropdownList
} from "components/linkRules/utils";
import {
  D3AutoCompleteInput,
  D3Dropdown
} from "components/shared/d3Components";
import { TextFieldLabel } from "components/shared/textFieldLabel";
import { translate } from "utils/i18n";
import {
  ILinkRulePropertyFormEntry,
  useLinkRulesCrudManagementContext
} from "components/linkRules/context/crudManagement";
import { ActionButton } from "components/shared/actionButton";
import { toAutoCompleteInputOption } from "components/shared/d3Components/d3AutoCompleteInput";

interface IProps extends IBaseProps {
  property: ILinkRulePropertyFormEntry;
  disabled: boolean;
}

export default function LinkRuleCondition(props: Readonly<IProps>) {
  const { property, disabled } = props;
  const classes = useStyles();
  const { attributeOptions, onUpdatePropertyField, onRemoveProperty } =
    useLinkRulesCrudManagementContext();

  const filteredAttributeOptions = useMemo(
    () =>
      getFilteredAttributeOptions(attributeOptions, {
        inputValue: property.name
      }),
    [attributeOptions, property.name]
  );

  const operators = useMemo(() => getOperatorsDropdownList(), []);

  return (
    <div className={classes.condition}>
      <div>
        <TextFieldLabel
          value={translate("navigationrules.sidebar.attribute")}
        />
        <D3AutoCompleteInput
          options={filteredAttributeOptions}
          value={toAutoCompleteInputOption(property.name ?? "")}
          className={classes.attribute}
          onSubmit={(option) =>
            onUpdatePropertyField(property.id, "name", option.value)
          }
          onInputChange={(option) => {
            onUpdatePropertyField(property.id, "name", option.value);
          }}
          disabled={disabled}
        />
      </div>
      <div>
        <TextFieldLabel value={translate("navigationrules.sidebar.operator")} />
        <D3Dropdown
          options={operators}
          value={property.operator?.toString() ?? ""}
          size="small"
          className={classes.operator}
          onSelect={(option) =>
            onUpdatePropertyField(property.id, "operator", Number(option.value))
          }
          disabled={disabled}
        />
      </div>
      <div className={classes.rowEndContainer}>
        <div className={classes.valueFieldContainer}>
          <TextFieldLabel value={translate("navigationrules.sidebar.value")} />
          <TextField
            variant="outlined"
            size="small"
            disabled={disabled || property.operator === 3}
            className={classes.value}
            value={property.value ?? ""}
            onChange={(e) =>
              onUpdatePropertyField(property.id, "value", e.target.value)
            }
          />
        </div>
        <ActionButton
          className={classes.removeIconContainer}
          icon="delete_outlined"
          tooltip={"translate_remove condition"}
          size="small"
          iconClassName={classes.removeIcon}
          onClick={() => onRemoveProperty(property.id)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
