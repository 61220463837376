import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { SiteNodeIcon } from "components/shared/siteNodeIcon";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { SiteNodeTypes } from "models/siteStructure";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: ".5em",
    opacity: 0.7,
    padding: theme.spacing(1, 3)
  },
  directoryContainer: {
    alignItems: "center"
  },
  fileContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  nodeTypeIcon: {
    color: theme.palette.custom.icons.inactive
  },
  textSkeleton: {
    flex: "1 1 100%"
  },
  skeletonCircle: (props: IBaseSkeletonProps) => ({
    paddingBottom: theme.spacing(props?.size || 5) / 2,
    width: theme.spacing(props?.size || 5),
    height: theme.spacing(props?.size || 5)
  }),
  moduleWrap: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    width: "50%",
    alignItems: "center",
    "& $skeletonCircle": {
      paddingBottom: 0
    }
  },
  moduleSubwrap: {
    flex: "1 1 90%"
  }
}));

export interface IBaseSkeletonProps {
  size?: number;
  className?: string;
}

export interface RectangleSkeletonProps extends IBaseSkeletonProps {}
export const RectangleSkeleton: React.FC<RectangleSkeletonProps> = React.memo(
  (props) => {
    return (
      <Skeleton
        className={props.className || ""}
        animation="wave"
        height={props.size ? props.size * 8 : "auto"}
      />
    );
  }
);

export interface DirectorySkeletonProps extends IBaseSkeletonProps {}
export const DirectorySkeleton: React.FC<DirectorySkeletonProps> = React.memo(
  (props) => {
    const classes = useStyles(props);
    const containerCSSClass = classNames(
      classes.root,
      classes.directoryContainer,
      props.className
    );
    return (
      <div className={containerCSSClass}>
        <SiteNodeIcon
          nodeType={SiteNodeTypes.DIRECTORY}
          className={classes.nodeTypeIcon}
          size={props.size}
        />
        <Skeleton
          className={classes.textSkeleton}
          width={105 + (props.size || 0)}
          height={props.size ? props.size * 8 : "auto"}
          animation={false}
        />
      </div>
    );
  }
);

export interface ModuleSkeletonProps extends IBaseSkeletonProps {}
export const ModuleSkeleton: React.FC<ModuleSkeletonProps> = React.memo(
  (props) => {
    const classes = useStyles(props);
    const containerCSSClass = classNames(
      classes.root,
      classes.fileContainer,
      props.className
    );
    return (
      <div className={containerCSSClass}>
        <div className={classes.moduleWrap}>
          <Skeleton variant="circle" className={classes.skeletonCircle} />
          <div className={classes.moduleSubwrap}>
            <Skeleton className={classes.textSkeleton} animation={false} />
            <Skeleton className={classes.textSkeleton} animation={false} />
          </div>
        </div>
        <Skeleton
          className={classes.textSkeleton}
          width={"50%"}
          animation={false}
        />
      </div>
    );
  }
);
