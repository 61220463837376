import { Accordion } from "components/shared/accordion";
import { IBaseProps } from "components/_baseProps";
import { IMenu } from "models/menus";
import { translate } from "utils/i18n";
import ModuleProperty from "components/siteStructure/pageView/pageSidebar/moduleProperties";
import { useCallback } from "react";
import { useMenuCommands } from "components/siteStructure/menuView/hooks/useMenuCommands";
import { selectSelectedMenuItem } from "redux/selectors/siteStructureSelectors";
import { useSelector } from "react-redux";
import { MenuItemSidebarContent } from "components/siteStructure/menuView/components/menuSidebar/menuItemSidebarContent";
import { buildComponentProperty } from "components/siteStructure/menuView/components/menuSidebar/componentPropertyBuilder";

interface IMenuSidebarContentProps extends IBaseProps {
  menu: IMenu;
  canEditProperties: boolean;
}

export const MenuSidebarContent = (props: IMenuSidebarContentProps) => {
  const { menu, canEditProperties } = props;

  const { setMenuDescription } = useMenuCommands({ menu });
  const selectedItem = useSelector(selectSelectedMenuItem);

  const property = buildComponentProperty({
    name: "description",
    canUseVariables: false
  });

  const onDescriptionChange = useCallback(
    async (name: string, value: string) => {
      await setMenuDescription(value);
    },
    [setMenuDescription]
  );

  if (selectedItem) {
    return (
      <MenuItemSidebarContent
        key={selectedItem.id}
        menu={menu}
        item={selectedItem}
        canEditProperties={canEditProperties}
      />
    );
  }

  return (
    <Accordion
      key={menu.id}
      label={translate("sitestructure.menu_sidebar.headers.main_properties")}
    >
      <ModuleProperty
        key={`${menu.id}-${property.name}`}
        propertyDefinition={property}
        value={menu.description}
        disabled={!canEditProperties}
        onChange={onDescriptionChange}
      />
    </Accordion>
  );
};
