import { makeStyles, Theme } from "@material-ui/core";
import { VariableTypeKey } from "utils/variableUtils";

interface Props {
  variableType: VariableTypeKey;
}

type StyleProperty = "background" | "color";

function getColorByType(
  styleProperty: StyleProperty,
  type: VariableTypeKey,
  theme: Theme
) {
  const variableColors = theme.palette.custom.ds.variables;

  const themeConfig: Record<VariableTypeKey, Record<StyleProperty, string>> = {
    UriVariable: {
      background: variableColors.urivariable500,
      color: "white"
    },
    KeyValue: {
      background: variableColors.keyvalue500,
      color: variableColors.keyvalue500
    },
    DataItem: {
      background: variableColors.dataitem500,
      color: variableColors.dataitem500
    },
    DataList: {
      background: variableColors.datalist500,
      color: variableColors.datalist500
    }
  };

  return themeConfig[type][styleProperty];
}

export const useStyles = makeStyles((theme) => ({
  name: ({ variableType }: Props) => ({
    "& .MuiInputBase-root::before": {
      content: "'@'",
      margin: theme.spacing(1, 0, 1, 2),
      color: getColorByType("color", variableType, theme)
    },
    "& .MuiInputBase-input": {
      fontFamily: "Menlo, monospace",
      color: getColorByType("color", variableType, theme)
    },
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(0),
      color: getColorByType("color", variableType, theme)
    }
  }),
  input: {
    margin: theme.spacing(0, 0, 3, 0),
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: theme.spacing(5)
    }
  },
  autocompleteInput: {
    "& input": { position: "relative", top: "-6px", left: "-2px" }
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: theme.spacing(5)
  },
  titleText: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(24)
  },
  chip: ({ variableType }: Props) => ({
    color: theme.palette.custom.ds.grayscales.gr200,
    margin: theme.spacing(0, 2, 0, 0),
    backgroundColor: getColorByType("background", variableType, theme)
  }),
  label: {
    margin: theme.spacing(0, 0, 0.5, 0),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  error: {
    fontStyle: "italic",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.custom.ds.error.error500
  },
  checkboxLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: theme.palette.custom.ds.grayscales.gr800
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.custom.ds.viking.viking500
    }
  },
  stack: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2.5)
  },
  errorPositioner: {
    position: "relative",
    top: "-1rem"
  }
}));
