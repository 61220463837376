import classnames from "classnames";
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { UxdIcon } from "components/shared/uxdIcon";
import { translate } from "utils/i18n";
import { useMemo } from "react";

interface IProps extends IBaseProps {
  slug: string;
  label?: string;
  loading?: boolean;
  error?: string | null;
  onRemoveClick?: (slug: string) => void;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    maxWidth: "100%",
    backgroundColor: theme.palette.custom.secondaryAccent.tiffany,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.5)
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(2)
  },
  text: {
    flex: 1,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(0.5)
  },
  button: {
    color: theme.palette.custom.brandBlues.d3Blue
  },
  icon: {
    padding: 0,
    fontSize: theme.spacing(2),
    opacity: 0.8
  },
  spinnerBox: {
    margin: theme.spacing("auto", 0.2)
  },
  warning: {
    color: theme.palette.common.white,
    opacity: 0.9
  }
}));

export const TagsPickerChip = (props: IProps) => {
  const {
    className,
    slug,
    label = "",
    loading = false,
    error = "",
    onRemoveClick = () => {},
    disabled
  } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const memoizedLabel = useMemo(
    () => (label ? `${label} (${slug})` : slug),
    [label, slug]
  );

  return (
    <div className={rootClassName}>
      <div className={classes.content}>
        <Tooltip title={memoizedLabel} arrow>
          <Typography className={classes.text} component="span" noWrap={true}>
            {memoizedLabel}
          </Typography>
        </Tooltip>

        {error && (
          <Tooltip
            title={
              error.toLowerCase() === "not found"
                ? translate("tagspicker.tagnotfound", { slug })
                : translate("tagspicker.tagloaderror", { error, slug })
            }
          >
            <UxdIcon
              className={classnames(classes.icon, classes.warning)}
              name="warning"
            />
          </Tooltip>
        )}

        {loading ? (
          <span className={classes.spinnerBox}>
            <CircularProgress size={16} />
          </span>
        ) : (
          !disabled && (
            <IconButton
              className={classes.button}
              onClick={() => onRemoveClick(slug)}
              size="small"
            >
              <Tooltip title={translate("tagspicker.removetag")}>
                <UxdIcon className={classes.icon} name="close" />
              </Tooltip>
            </IconButton>
          )
        )}
      </div>
    </div>
  );
};
