import { ListItemIcon, makeStyles, MenuItem } from "@material-ui/core";
import { UxdIcon } from "components/shared/uxdIcon";
import { ISiteItem } from "models/siteItem";
import { SiteNodeType, SiteNodeTypes } from "models/siteStructure";
import { useDispatch } from "react-redux";
import { openCreateModal } from "redux/actions/siteItem/createSiteItemActions";
import { userService } from "services/userService";
import { translate } from "utils/i18n";

interface IProps {
  node: ISiteItem;
  onClickCallback?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: theme.spacing(2),
    color: theme.palette.custom.ds.viking.viking500
  }
}));

export const CreateSiteItemMenuItem = (props: IProps) => {
  const { node, onClickCallback } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const openModal = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    type: SiteNodeType
  ) => {
    event.stopPropagation();
    dispatch(openCreateModal({ node, type }));
    onClickCallback?.call(this, event);
  };

  const isDirectory = node.nodeType === SiteNodeTypes.DIRECTORY;

  const canEdit = userService.hasSiteItemPermissions(node.path);

  if (!isDirectory) {
    return <></>;
  }

  return (
    <>
      <MenuItem
        onClick={(event) => openModal(event, SiteNodeTypes.DIRECTORY)}
        disabled={!canEdit}
      >
        <ListItemIcon>
          <UxdIcon className={classes.icon} name="folder" outline={false} />
        </ListItemIcon>
        {translate("sitestructure.adddirectory")}
      </MenuItem>

      <MenuItem
        onClick={(event) => openModal(event, SiteNodeTypes.PAGE)}
        disabled={!canEdit}
      >
        <ListItemIcon>
          <UxdIcon
            className={classes.icon}
            name="description"
            outline={false}
          />
        </ListItemIcon>
        {translate("sitestructure.addpage")}
      </MenuItem>

      <MenuItem
        onClick={(event) => openModal(event, SiteNodeTypes.MENU)}
        disabled={!canEdit}
      >
        <ListItemIcon>
          <UxdIcon className={classes.icon} name="menu" outline={false} />
        </ListItemIcon>
        {translate("sitestructure.addmenu")}
      </MenuItem>
    </>
  );
};
