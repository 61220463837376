import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { IBaseProps } from "components/_baseProps";
import { useRequestStatus } from "components/hooks";
import { useStyles } from "components/linkRules/linkPicker/linkPickerModal/internalLinkContent/styles";
import SiteStructureTree from "components/siteStructure/tree";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSiteItemsFromRoot } from "redux/actions/siteStructureActions";
import { translate } from "utils/i18n";

interface IProps extends IBaseProps {
  hidden: boolean;
  value: string;
  onChange: (id: string, path: string) => void;
}

export function InternalLinkContent(props: Readonly<IProps>) {
  const { hidden, value, onChange } = props;
  const classes = useStyles();
  const siteStructureTreeClassName = classNames({ [classes.hidden]: hidden });

  const dispatch = useDispatch();
  const [shouldFetch, setShouldFetch] = useState(!hidden);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchSiteItemsFromRoot({ path: value }));
      setShouldFetch(false);
    }
  }, [shouldFetch, value, dispatch]);

  const request = useRequestStatus(fetchSiteItemsFromRoot, { path: value });

  return (
    <div className={classes.content}>
      <Typography variant="caption" className={classes.description}>
        {translate("navigationrules.sidebar.link_picker.internal.description")}
      </Typography>
      <div className={classes.siteStructureTreeContainer}>
        <SiteStructureTree
          className={siteStructureTreeClassName}
          isLoading={request.isLoading}
          path={value}
          isLinkPicker={true}
          onSelectedLinkPickerPage={onChange}
        />
      </div>
    </div>
  );
}
