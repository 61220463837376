import { Typography } from "@material-ui/core";
import { translate } from "utils/i18n";
import {
  D3Button,
  D3MultiSelectListModal
} from "components/shared/d3Components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectAuthorizationGroupList } from "redux/selectors/authorizationGroupSelectors";
import { IAuthorizationGroupDetailUpdateRequest } from "models/authorizationGroup";
import { IBaseProps } from "components/_baseProps";
import { ISiteItem } from "models/siteItem";
import { useMultiSelect } from "components/shared/multiSelect/hooks";
import { useLocalAuthGroups } from "components/siteStructure/permissions/hooks";
import { userService } from "services/userService";
import { PermissionCodes } from "catalogs/permissionCodes";
import { useStyles } from "./styles";

interface IProps extends IBaseProps {
  currentSiteItem: ISiteItem;
}

export const EmptyAuthorizationGroup = (props: IProps) => {
  const { currentSiteItem } = props;
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const { clearChecked } = useMultiSelect();
  const { handleConfirm } = useLocalAuthGroups(currentSiteItem.nodeId);

  const authGroups = useSelector(selectAuthorizationGroupList);

  const getSelectableAuthGroups = () =>
    authGroups.map((authGroup) => ({
      key: authGroup.groupId,
      label: authGroup.name,
      description: authGroup.description
    }));

  const onClick = () => setOpen(true);
  const onCancel = () => setOpen(false);

  const onConfirm = async (authGroupsToAdd: string[]) => {
    const update: IAuthorizationGroupDetailUpdateRequest = {
      groupsIds: authGroupsToAdd,
      actionType: "update",
      elementKind: "siteItems",
      values: [currentSiteItem.path]
    };

    handleConfirm(update);
    clearChecked();
    setOpen(false);
  };

  const isDisabled =
    !userService.hasPermissions(PermissionCodes.EditPermissions) ||
    !userService.hasSiteItemPermissions(currentSiteItem.path);

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.image}></div>
        <Typography className={classes.text} variant="h3">
          {translate("sitestructure.permissions.emptyauthorizationgrouptitle")}
        </Typography>
        <Typography className={classes.textSubTitle} variant="subtitle1">
          {translate(
            "sitestructure.permissions.emptyauthorizationgroupsubtitle"
          )}
        </Typography>

        <D3Button
          icon="group_add"
          variant="ghost"
          color="secondary"
          className={classes.addBtn}
          onClick={onClick}
          disabled={isDisabled}
        >
          {translate("sitestructure.permissions.addgroups")}
        </D3Button>
      </div>
      {open && (
        <D3MultiSelectListModal
          title={translate("sitestructure.permissions.selectgroup")}
          items={getSelectableAuthGroups()}
          hideSearch={true}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmLabel={{
            verb: translate("general.add"),
            plural: translate("sitestructure.groups"),
            singular: translate("sitestructure.group")
          }}
          itemsClassName={classes.item}
        />
      )}
    </div>
  );
};
