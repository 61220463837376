import classnames from "classnames";
import { makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { useMemo } from "react";
import { getReadableHistoryEntry } from "utils/historyUtils";
import { SiteItemHistoryEntry } from "models/history";

interface IProps extends IBaseProps {
  entry: SiteItemHistoryEntry;
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.custom.text.content
  },
  highlight: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary
  }
}));

export const HistoryEntryText = (props: IProps) => {
  const { className, entry } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const text = useMemo(
    () =>
      getReadableHistoryEntry(entry, (content, key) => (
        <Typography
          key={key}
          className={classes.highlight}
          component="span"
          variant="body1"
        >
          {content}
        </Typography>
      )),
    [entry, classes.highlight]
  );

  return (
    <Typography className={rootClassName} component="span" variant="body1">
      {text}
    </Typography>
  );
};
