import { makeStyles } from "@material-ui/core";
import { RectangleSkeleton } from "components/skeletons";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 0, 5),
    display: "flex",
    flexDirection: "row"
  },
  left: {
    margin: theme.spacing(1, 9, 0, 0),
    width: "10vw"
  },
  right: {
    width: "50vw"
  }
}));

export function ScrollspyLoading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {_.range(3).map((index) => (
          <RectangleSkeleton key={index} size={6} />
        ))}
      </div>
      <div className={classes.right}>
        {_.range(8).map((index) => (
          <RectangleSkeleton key={index} size={10} />
        ))}
      </div>
    </div>
  );
}
