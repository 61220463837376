import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import classNames from "classnames";
import { useAuthorizationGroupUsersSelectContext } from "components/permissions/detailView/context/useAuthorizationGroupUsersSelect";
import { D3SearchBox } from "components/shared/d3Components";
import { UxdIcon } from "components/shared/uxdIcon";
import { IBaseProps } from "components/_baseProps";
import { useState } from "react";
import { tabMultiSelectList } from "theme/commonClasses";
import { translate } from "utils/i18n";
import { useStyles as useModuleTabStyles } from "./moduleTab";

interface IProps extends IBaseProps {
  siteItems?: string[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.ds.grayscales.gr700,
    opacity: 1,
    height: `calc(100% - ${theme.spacing(7)}px)`,
    ...tabMultiSelectList
  },
  item: {
    padding: `10px 12px`,
    borderTop: `1px solid ${theme.palette.custom.ds.grayscales.gr300}`,
    "& .MuiListItemText-root": {
      margin: 0
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      overflow: "hidden",
      whiteSpace: "normal",
      textOverflow: "unset",
      wordBreak: "break-all",
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      transition: "all .4s ease"
    },
    "&:nth-child(1)": {
      border: "none"
    },
    "&:hover": {
      cursor: "pointer",
      "& .MuiListItemText-root .MuiTypography-root": {
        color: theme.palette.custom.ds.viking.viking700,
        textDecoration: "underline",
        transition: "all .4s ease"
      }
    }
  },
  magnifier: { color: theme.palette.custom.ds.grayscales.gr500 },
  text: {
    color: theme.palette.custom.ds.grayscales.gr700,
    padding: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20)
  },
  icon: {
    color: theme.palette.custom.ds.grayscales.gr500,
    padding: theme.spacing(0, 1.5, 0, 0),
    fontSize: theme.typography.pxToRem(20)
  },
  longPath: {
    alignItems: "flex-start"
  }
}));

const useTooltipStyle = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.custom.ds.grayscales.gr900,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400
  },
  arrow: {
    color: theme.palette.custom.ds.grayscales.gr900
  },
  tooltipPlacementTop: { top: "16px" }
}));

const siteItemTypeChecker = (item: string) =>
  item.slice(-1) === "/" ? "folder" : "description";

export function PageTab(props: IProps) {
  const { siteItems = [] } = props;
  const classes = useStyles();
  const toolTipStyles = useTooltipStyle();
  const moduleTabStyles = useModuleTabStyles();

  const { clearChecked: resetAuthorizationGroupUsersSelected } =
    useAuthorizationGroupUsersSelectContext();
  const [query, setQuery] = useState<string>("");

  const itemClassName = (text: string) =>
    classNames(classes.item, {
      [classes.longPath]: text.length > 50
    });

  const onSearchTextChange = (value: string) => setQuery(value.toLowerCase());
  const filteredSiteItems =
    query.trim().length === 0
      ? siteItems
      : siteItems.filter((x) => x.toLowerCase().includes(query));

  const handleClick = (itemUrl: string) => {
    resetAuthorizationGroupUsersSelected();
    const url = `${window.location.origin}/#/sitestructure/${itemUrl}?tab=content`;
    window.open(url, "_blank", "noopener, noreferrer");
  };

  return (
    <>
      <Typography className={classes.text} variant="body1">
        {translate("permissions.content.detail.pagetab.headertext")}
      </Typography>
      <D3SearchBox
        onTextChange={onSearchTextChange}
        collapse
        iconClassname={moduleTabStyles.searchIcon}
        placeholder={translate("sitestructure.search.inputplaceholder")}
      />
      <List className={classes.root}>
        {filteredSiteItems.map((item, index) => (
          <Tooltip
            key={index}
            title={translate("permissions.content.detail.pagetab.open_newtab")}
            arrow
            placement="top"
            classes={toolTipStyles}
          >
            <ListItem
              id={item}
              className={itemClassName(item)}
              onClick={() => handleClick(item)}
            >
              <UxdIcon
                name={siteItemTypeChecker(item)}
                className={classes.icon}
              />
              <ListItemText>{item}</ListItemText>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </>
  );
}
