import { createAction } from "redux-actions";
import { IUserState } from "models/domainStates";
import { uniqueId } from "lodash";

export const fetchUser = createAction("FETCH_USER");

export const setUser = createAction("SET_USER", (user: IUserState) => user);

export const createExternalTag = createAction(
  "CREATE_EXTERNAL_TAG",
  (payload: any) => ({
    ...payload,
    actionId: uniqueId()
  })
);
