import classnames from "classnames";
import { DialogActions, makeStyles, Typography } from "@material-ui/core";
import { IBaseProps } from "components/_baseProps";
import { ReactNode } from "react";

interface IProps extends IBaseProps {
  caption?: ReactNode;
  actions?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2)
  },
  footer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    minWidth: 0
  }
}));

export const DialogFooter = (props: IProps) => {
  const { className, caption, actions } = props;
  const classes = useStyles();
  const rootClassName = classnames(classes.root, className);

  const startElement = caption ? (
    <div className={classes.footer}>
      <Typography className={classes.footer} noWrap={true}>
        {caption}
      </Typography>
    </div>
  ) : (
    <></>
  );

  const endElement = actions ? actions : <></>;

  return (
    <DialogActions className={rootClassName}>
      {startElement}
      {endElement}
    </DialogActions>
  );
};
