import {
  ButtonBase,
  ButtonBaseProps,
  CircularProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import classNames from "classnames";
import { UxdIcon } from "components/shared/uxdIcon";
import { omit } from "lodash";

/**
 * Deltatre Button Style Guide available Props
 * @see [MUI 4](https://v4.mui.com/api/button-base/#buttonbase-api)
 */
interface IProps extends ButtonBaseProps {
  /**
   * Variant style for the button
   * @enum
   */
  variant?: "filled" | "outlined" | "ghost";
  /**
   * Color variant style for the button
   * @enum
   */
  color?: "primary" | "secondary" | "neutral";
  /**
   * Size style for the button
   * @enum
   */
  size?: "small" | "medium" | "large";
  /**
   * If icon provide it show
   */
  icon?: string;
  /**
   * Loading button status
   */
  loading?: boolean;
  /**
   * Where show the icon
   * @enum
   */
  iconPosition?: "left" | "right";
}

const useStyles = makeStyles((theme) => {
  const defaultVariant = "filled";
  const defaultColor = "primary";

  const paletteCustom = theme.palette.custom.ds;
  const white = theme.palette.common.white;

  const colorMapping = {
    filled: {
      primary: {
        default: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia500,
          icon: white,
          spinner: paletteCustom.valencia.valencia500
        },
        hover: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia700
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia700,
          icon: white
        },
        focus: {
          border: paletteCustom.valencia.valencia300,
          backgroundColor: paletteCustom.valencia.valencia700,
          color: white
        },
        disabled: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia500
        },
        loading: {
          color: paletteCustom.valencia.valencia300,
          backgroundColor: paletteCustom.valencia.valencia100
        }
      },
      secondary: {
        default: {
          color: white,
          backgroundColor: paletteCustom.viking.viking500,
          icon: white,
          spinner: paletteCustom.viking.viking500
        },
        hover: {
          color: white,
          backgroundColor: paletteCustom.viking.viking700
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.viking.viking700,
          icon: white
        },
        focus: {
          border: paletteCustom.viking.viking300,
          backgroundColor: paletteCustom.viking.viking700,
          color: white
        },
        disabled: {
          color: white,
          backgroundColor: paletteCustom.viking.viking500
        },
        loading: {
          color: paletteCustom.viking.viking300,
          backgroundColor: paletteCustom.viking.viking100
        }
      }
    },
    outlined: {
      primary: {
        default: {
          color: paletteCustom.valencia.valencia500,
          backgroundColor: "transparent",
          icon: paletteCustom.valencia.valencia500,
          spinner: paletteCustom.valencia.valencia300,
          border: paletteCustom.valencia.valencia500
        },
        hover: {
          color: paletteCustom.valencia.valencia500,
          backgroundColor: paletteCustom.valencia.valencia100
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia500,
          icon: white
        },
        focus: {
          border: paletteCustom.valencia.valencia300,
          backgroundColor: paletteCustom.valencia.valencia100,
          color: paletteCustom.valencia.valencia500
        },
        disabled: {
          color: paletteCustom.valencia.valencia500,
          backgroundColor: "transparent"
        },
        loading: {
          color: paletteCustom.valencia.valencia500,
          backgroundColor: "transparent"
        }
      },
      secondary: {
        default: {
          color: paletteCustom.viking.viking500,
          backgroundColor: "transparent",
          icon: paletteCustom.viking.viking500,
          spinner: paletteCustom.viking.viking300,
          border: paletteCustom.viking.viking500
        },
        hover: {
          color: paletteCustom.viking.viking500,
          backgroundColor: paletteCustom.viking.viking100
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.viking.viking500,
          icon: white
        },
        focus: {
          border: paletteCustom.viking.viking300,
          backgroundColor: paletteCustom.viking.viking100,
          color: paletteCustom.viking.viking500
        },
        disabled: {
          color: paletteCustom.viking.viking500,
          backgroundColor: "transparent"
        },
        loading: {
          color: paletteCustom.viking.viking500,
          backgroundColor: "transparent"
        }
      }
    },
    ghost: {
      primary: {
        default: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent",
          icon: paletteCustom.valencia.valencia500,
          spinner: paletteCustom.valencia.valencia300
        },
        hover: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: paletteCustom.valencia.valencia100
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.valencia.valencia900,
          icon: white
        },
        focus: {
          border: paletteCustom.valencia.valencia300,
          backgroundColor: paletteCustom.valencia.valencia100,
          color: paletteCustom.grayscales.gr800
        },
        disabled: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent"
        },
        loading: {
          color: paletteCustom.valencia.valencia300,
          backgroundColor: "transparent"
        }
      },
      secondary: {
        default: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent",
          icon: paletteCustom.viking.viking500,
          spinner: paletteCustom.viking.viking300
        },
        hover: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: paletteCustom.viking.viking100
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.viking.viking900,
          icon: white
        },
        focus: {
          border: paletteCustom.viking.viking300,
          backgroundColor: paletteCustom.viking.viking100,
          color: paletteCustom.grayscales.gr800
        },
        disabled: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent"
        },
        loading: {
          color: paletteCustom.grayscales.gr500,
          backgroundColor: "transparent"
        }
      },
      neutral: {
        default: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent",
          icon: paletteCustom.grayscales.gr600,
          spinner: paletteCustom.grayscales.gr500
        },
        hover: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: paletteCustom.grayscales.gr200
        },
        active: {
          color: white,
          backgroundColor: paletteCustom.grayscales.gr800,
          icon: paletteCustom.grayscales.gr300
        },
        focus: {
          border: paletteCustom.grayscales.gr300,
          backgroundColor: paletteCustom.grayscales.gr200,
          color: paletteCustom.grayscales.gr800
        },
        disabled: {
          color: paletteCustom.grayscales.gr800,
          backgroundColor: "transparent"
        },
        loading: {
          color: paletteCustom.grayscales.gr500,
          backgroundColor: "transparent"
        }
      }
    }
  };

  return {
    root: {
      transition: "all .3s ease-in-out",
      // Default style
      borderRadius: "2px",
      boxSizing: "border-box",
      whiteSpace: "nowrap",
      backgroundColor: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].default.backgroundColor,
      color: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].default.color,
      padding: (props: IProps) => {
        switch (props.size) {
          case "small":
            return theme.spacing(0, 1);
          case "medium":
            return theme.spacing(0.5, 1.7);
          case "large":
          default:
            return theme.spacing(1, 2);
        }
      },
      border: "1px solid",
      borderColor: (props: IProps) => {
        if (props.variant === "outlined") {
          return colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].default.border;
        }
        return "transparent";
      },
      // Hover style
      "&:hover": {
        transition: "all .3s ease-in-out",
        backgroundColor: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].hover.backgroundColor,
        color: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].hover.color
      },
      // Active style
      "&:active": {
        backgroundColor: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].active.backgroundColor,
        color: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].active.color,
        "& $icon": {
          color: (props: IProps) =>
            colorMapping[props.variant ?? defaultVariant][
              props.color ?? defaultColor
            ].active.icon
        }
      },
      // Focus style
      "&:not(:active):focus": {
        backgroundColor: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].focus.backgroundColor,
        color: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].focus.color,
        border: "2px solid",
        borderColor: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].focus.border,
        padding: (props: IProps) => {
          switch (props.size) {
            case "small":
              return `0px ${theme.spacing(1) - 1}px`;
            case "medium":
              return `${theme.spacing(0.5) - 1}px ${theme.spacing(1.7) - 1}px`;
            case "large":
            default:
              return `${theme.spacing(1) - 1}px ${theme.spacing(2) - 1}px`;
          }
        }
      },
      // Loading style
      "& span": {
        visibility: "visible",
        display: "flex",
        gap: "5%",
        alignItems: "center"
      },
      "&.loading": {
        pointerEvents: "none",
        backgroundColor: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].loading.backgroundColor,
        color: (props: IProps) =>
          colorMapping[props.variant ?? defaultVariant][
            props.color ?? defaultColor
          ].loading.color
      },
      "&.loading span": {
        visibility: "hidden"
      }
    },
    icon: {
      fontSize: (props: IProps) => {
        switch (props.size) {
          case "small":
            return theme.typography.pxToRem(12);
          case "medium":
          case "large":
          default:
            return theme.typography.pxToRem(20);
        }
      },
      color: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].default.icon
    },
    spinner: {
      fontSize: "12px",
      position: "absolute",
      color: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].default.spinner
    },
    label: {
      fontWeight: 500,
      fontSize: (props: IProps) => {
        switch (props.size) {
          case "small":
            return theme.typography.pxToRem(12);
          case "medium":
          case "large":
          default:
            return theme.typography.pxToRem(14);
        }
      }
    },
    // Disabled style
    disabled: {
      pointerEvents: "none",
      opacity: 0.5,
      backgroundColor: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].disabled.backgroundColor,
      color: (props: IProps) =>
        colorMapping[props.variant ?? defaultVariant][
          props.color ?? defaultColor
        ].disabled.color
    },
    loading: {}
  };
});

/**
 * Deltatre Button inside the Design System
 * @see [Design System](https://www.figma.com/file/mTyXrouZ9B4cHebcPOltDJ/Product-Design-System?node-id=1168%3A6503)
 * @component
 */
export function D3Button(props: Readonly<IProps>) {
  const { icon, iconPosition = "left", loading = false } = props;
  const classes = useStyles(props);

  const rootClassName = classNames(classes.root, props.className, {
    [classes.disabled]: props.disabled,
    loading: props.loading
  });

  return (
    <ButtonBase
      {...omit(props, ["loading", "iconPosition"])}
      className={rootClassName}
      disableRipple={true}
      focusRipple={false}
    >
      {loading && <CircularProgress className={classes.spinner} size={14} />}
      <span>
        {icon && iconPosition === "left" ? (
          <UxdIcon className={classes.icon} name={icon} />
        ) : (
          ""
        )}
        <Typography className={classes.label} component={"span"}>
          {props.children}
        </Typography>
        {icon && iconPosition === "right" ? (
          <UxdIcon className={classes.icon} name={icon} />
        ) : (
          ""
        )}
      </span>
    </ButtonBase>
  );
}
