import { useQuery } from "react-query";
import { TagsApi } from "api/tagsApi";
import { makeCancelablePromise } from "utils/javascriptUtils";

export const useTagsTypeaheadQuery = (
  terms: string,
  abortController: AbortController = new AbortController()
) => {
  const queryKey = ["tagsTypeahead", terms];
  const queryOptions = {};

  const queryFn = () =>
    makeCancelablePromise(
      (controller) =>
        TagsApi.getTagTypeaheadSuggestion("nd-nd", terms, {
          signal: controller.signal
        }),
      abortController
    );

  return useQuery(queryKey, queryFn, queryOptions);
};
