import { isTagDatasource, TagsPickerSuggestion } from "models/forgeEntities";

export function getLabel(suggestion: TagsPickerSuggestion) {
  switch (suggestion.source) {
    case "internal":
      return suggestion.label
        ? `${suggestion.label} (${suggestion.slug})`
        : suggestion.slug;
    case "external":
    case "customentity":
      return isTagDatasource(suggestion)
        ? suggestion.code
        : `${suggestion.label} (${suggestion.slug})` || "";
  }
}
