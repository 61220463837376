import { ISiteItem } from "models/siteItem";
import { Action } from "redux-actions";
import {
  closePasteModal,
  openPasteModal
} from "redux/actions/siteItem/pasteSiteItemActions";
import { reducerFactory } from "redux/reducers/coreReducer";

export interface IPasteSiteItemState {
  open: boolean;
  node: ISiteItem | null;
}

export const initialState: IPasteSiteItemState = {
  open: false,
  node: null
};

const reactions = {
  [openPasteModal.toString()]: (
    state: IPasteSiteItemState,
    action: Action<ISiteItem>
  ) => {
    state.open = true;
    state.node = action.payload;

    return state;
  },

  [closePasteModal.toString()]: (state: IPasteSiteItemState) => {
    state.open = false;
    state.node = null;

    return state;
  }
};

export default reducerFactory(initialState, reactions);
